import { Button, Grid, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function Header({ onDownloadClick }) {
  const { t } = useTranslation(['title', 'button']);
  return (
    <Grid container spacing={2} style={{ marginBotton: '10px' }}>
      <Grid item md={4} xs={12}>
        <Typography variant="h3">
          {t('title:admin_dashboard.dashboard')}
        </Typography>
      </Grid>
      <Grid container item md={8} xs={12} justify="flex-end"style={{ display: 'none' }}>
        <Button color="primary" variant="contained" onClick={onDownloadClick}>
          <CloudDownload style={{ marginRight: '5px' }} />
          {t('button:commons.btn_download')}
        </Button>
      </Grid>
    </Grid>
  );
}
