import React from 'react';

import SubCategoryTable from '../Table';

import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SubCategoryTable />
    </div>
  );
};
