import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  AddToQueueOutlined,
  ArrowBackIos,
  Block,
  CallSplit,
  EditOutlined,
  Visibility,
  VisibilityOff
} from '@material-ui/icons/';
import Save from '@material-ui/icons/Save';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { HandleBlock } from 'components';
import { CpfMaskCustom } from 'components/CpfMaskCustom';
import { PhoneMaskCustom } from 'components/PhoneMaskCustom';
import { statusUser } from 'constants/status';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CustomFieldsData,
  EnrollmentData,
  GroupsData,
  PasswordResetData,
  TagData,
  TrailData,
  UserData
} from 'services/dataService';
import TeamData from 'services/dataService/TeamData';
import theme from 'theme';
import RouteNotYetImplemented from 'views/NotYetImplemented';
import CustomTabComponent from '../../../../components/CustomTab/index';
import enablesCompaniesArrayPresencial from '../../../../helpers/enablesCompaniesPresencial'; 
import { Classroom, Courses, Attendance, NewEnrollment } from './components/index';
import AddTeamDialog from './components/newTeam/addTeamDialog';
import Teams from './components/newTeam/Team';
import { useStyles } from './styles';
import UserTrails from '../Form/user-trails';

export default () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    tags: [],
    avaliable_tags: [],
  });
  const [showEditContainer, setShowEditContainer] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [passwordSwitch, setPasswordSwitch] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [open, setOpen] = useState(false);
  const [trailsOpen, setTrailsOpen] = useState(false);
  const [paginationNumber, setPaginationNumber] = useState(0);
  const [customFields, setCustomFields] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [visible, setVisible] = useState(false);
  const [trails, setTrails] = useState([]);

  const courseRef = useRef();
  const teamRef = useRef();
  const attendanceRef = useRef();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { company } = useSelector((state) => state);
  const { id_company } = useSelector((state) => state.auth);

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);
  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    nativeValidation: true,
  });

  // const canShowPresencialInSidebar = enablesCompaniesArrayPresencial.find(
  //   (element) => element === company?.domain_company
  // );

  const canShowPresencialInSidebar = company?.show_presencial

  const tabComponents = [
    {
      label: t('button:User.btn_profile_courses'),
      component: <Courses ref={courseRef} />,
    },
    ...(canShowPresencialInSidebar ? [{
      label: t('button:User.btn_profile_courses_not_online'),
      component: <Attendance ref={attendanceRef} />,
    }] : []),
    {
       label: t(t('button:User.btn_profile_group_part_of')),
       component: <Teams ref={teamRef} />,
    }, 

    // {
    //   label: t('button:User.btn_profile_contracted_services'),
    //   component: <Classroom />,
    // },
    // {
    //   label: t('button:User.btn_profile_purchases'),
    //   component: <RouteNotYetImplemented routeName="Compras" />,
    // },
    // {
    //   label: t('button:User.btn_profile_feedback_received'),
    //   component: <RouteNotYetImplemented routeName="Feedback" />,
    // },
    // {
    //   label: t('button:User.btn_profile_profile_anaylises'),
    //   component: <RouteNotYetImplemented routeName="analise De Perfil" />,
    // },
  ];

  const handlePasswordChange = () => {
    setPasswordSwitch((prev) => !prev);
  };

  const password = useRef({});
  password.current = watch('password');

  const cpf = useRef({});
  cpf.current = watch('cpf');

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setVisible(!visible);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GroupsData.getGroups();
        setGroupList(response.data.groups.filter((a) => a?.type !== 3));
      } catch (err) {
        history.push('/users');
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };

    fetchData();

    const fetchFields = async () => {
      const { data: customFields } = await CustomFieldsData.getCustomFields();
      if (customFields?.custom_fields) {
        return JSON.parse(customFields?.custom_fields);
      } else {
        return [];
      }
    };

    if (id) {
      const fetchUserData = async () => {
        try {
          const response = await UserData.getUsersById(id);
          if (response.data.custom_field_values) {
            response.data.custom_field_values = JSON.parse(
              response?.data?.custom_field_values
            );
          } else {
            response.data.custom_field_values = [];
          }

          const company_custom_fields = await fetchFields();

          setCustomFields(company_custom_fields);
          setDefaultValues(
            normalizeValues(
              company_custom_fields,
              response.data.custom_field_values
            )
          );

          const responseCourseEnroll =
            await EnrollmentData.countByUserEnrollments(id);
          const responseTeams = await TeamData.getTeamsName(id);
          const responseTags = await TagData.getTags();
          response.data.tags = response.data.tags.map((x) => x.id);

          setData({
            ...response.data,
            last_login_at:
              response.data.last_login_at &&
              format(parseISO(response.data.last_login_at), 'dd/MM/yyyy'),
            avaiable_course: responseCourseEnroll.data.count,
            avaliable_tags: responseTags.data.tags,
            teamsName: responseTeams.data,
          });

          const avatarUrl = response.data.avatar
            ? response.data.avatar?.url
            : '/images/avatars/noUserSbgBig.png';

          setAvatar(avatarUrl);

          const { name, email, phone, cellphone, situation, tags, cpf } =
            response.data;

          setValue([
            { name },
            { email },
            { phone },
            { cellphone },
            { situation },
            { tags },
            { cpf },
          ]);

          setUserStatus(response.data.status);
        } catch (err) {
          history.push('/users');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();

      const fetchTrails = async () => {
        const { data: { trails } } = await TrailData.getTrailsUnique(id_company);
        const { data: trailsUser } = await TrailData.getTrailUser(id);
        
        const selectedTrails = trails.map((trail) => ({ ...trail, selected: trailsUser.some(t => t.id === trail.id) }));

        setTrails(selectedTrails);
      };

      fetchTrails();
    }
  }, [id, showEditContainer]);

  const handleShowEdit = useCallback(() => {
    setShowEditContainer((prevState) => !prevState);
  }, [showEditContainer]);

  const handlePasswordReset = async () => {
    try {
      const domain = window.location.hostname;
      await PasswordResetData.reset({
        ...data,
        domain,
      });
      toast.success(t('toastify:user.toast_send_password_reset'));
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_fail_send_password_reset'));
      }
    }
  };
  
  const updateTrails = useCallback(async (selectedTrails) => {
    try {
      const trailsIds = selectedTrails.filter((t) => t.selected).map((trail) => trail.id);

      const data = {
        id_trails: trailsIds,
        id_user: id,
      };

      await TrailData.enrollTrailBulk(data);

      setTrails(selectedTrails);
      toast.success(t('toastify:Trails.attribuition_success'));
    } catch (error) {
      toast.error(t('toastify:commons.toast_error'));
    }
  });

  const addNewEnrollment = useCallback(async ({ course, courseDueDate }) => {
    try {
      let due_date = '';
      if (courseDueDate?.length) {
        const separatedDate = courseDueDate.split('-');
        due_date = new Date(
          separatedDate[0],
          separatedDate[1] - 1,
          separatedDate[2],
          23,
          59,
          59
        );
      }
      await EnrollmentData.addEnrollment({
        id_company_course: course.id,
        id_user: id,
        due_date,
      });
      courseRef.current.updateTable();
      toast.success(t('toastify:Enrollments.attribuition_success'));
    } catch (error) {
      if (error?.response?.data?.error === 'Enrollment already exists.') {
        toast.error(t('toastify:Enrollments.attribuition_failed'));
      } else {
        toast.error(t('toastify:commons.toast_error'));
      }
    }
  });

  const addTeam = useCallback(async (teamId, updateList) => {
    try {
      await TeamData.addUserToTeam(teamId, id);
      updateList();
      teamRef.current.updateTable();

      toast.success(t('toastify:Teams.attribuition_success'));
    } catch (error) {
      toast.error(t('toastify:commons.toast_error'));
    }
  });

  const handleSendInactiveAlert = async () => {
    try {
      await UserData.sendAlert(id);
      toast.success(t('toastify:user.toast_send_alert'));
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_fail_send_alert'));
      }
    }
  };

  const onSubmit = useCallback(
    async (data) => {
      data.email = data.email.toLowerCase()
      if (data.array_custom_field_values) {
        data.array_custom_field_values = data.array_custom_field_values
          .map((fields) => {
            const fieldName = Object.keys(fields)[0];
            return {
              fieldName: fieldName,
              fieldValue: fields[fieldName],
            };
          })
          .filter((field) => field !== null);
      }

      delete data['password_repeat'];

      try {
        if (id) {
          await UserData.updateUser(id, data);
        } else {
          await UserData.addUser(data);
        }
        toast.success(t('toastify:commons.toast_update'));
        handleShowEdit();
      } catch (error) {
        if (error.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_update_error'));
        }
      }
    },
    [t]
  );

  const getChipsTags = (tagId) => {
    const tag = data.avaliable_tags.find((x) => x.id == tagId);
    if (!tag) return null;
    return <Chip key={tag.id} label={tag.title} className={classes.userTag} />;
  };

  const getTeamsTag = (teamsName) => {
    return teamsName?.length ? (
      teamsName.map((name, index) => (
        <Chip
          key={`${index}_${name}`}
          label={name}
          className={classes.userTag}
        />
      ))
    ) : (
      <Typography variant="body2">
        {t('label:User.label_without_team')}
      </Typography>
    );
  };

  const titlePage = id
    ? t('title:User.title_edit')
    : t('title:User.title_create');

  const buttonTheme = createMuiTheme({
    palette: {
      primary: { contrastText: '#FFFFFF', main: '#2DCE98' },
      secondary: { main: '#F53C56' },
    },
  });

  return (
    <>
      {!loading && (
      <div className='notranslate'>
         <div className={classes.root}>
          <div className={classes.topButtonContainer}>
            {trailsOpen && (
              <UserTrails
                open={trailsOpen}
                setOpen={setTrailsOpen}
                callback={updateTrails}
                trails={trails}
              />
            )}
            {paginationNumber === TabValues.Courses && (
              <NewEnrollment
                open={open}
                setOpen={setOpen}
                callback={addNewEnrollment}
              />
            )}
            {paginationNumber === TabValues.Attendance && (
              <NewEnrollment
                open={open}
                setOpen={setOpen}
                callback={addNewEnrollment}
              />
            )}
            {paginationNumber === TabValues.Teams && (
              <AddTeamDialog
                id={id}
                open={open}
                setOpen={setOpen}
                title={t('form:Teams.team_modal_form_title')}
                description={t('form:Teams.team_modal_form_description')}
                callback={addTeam}
              />
            )}
            <ThemeProvider theme={buttonTheme}>
              <Button
                color={userStatus === 1 ? 'secondary' : 'primary'}
                variant="contained"
                size="medium"
                className={classes.topButton}
                onClick={() => setModalStatus(true)}
              >
                <Block className={classes.icon} />
                {userStatus === 1
                  ? t('button:User.btn_profile_block_user')
                  : t('button:User.btn_profile_unblock_user')}
              </Button>
            </ThemeProvider>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              className={classes.topButton}
              disabled={showEditContainer}
              onClick={handleShowEdit}
            >
              <EditOutlined className={classes.icon} />
              {t('button:commons.btn_edit_information')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              className={classes.topButton}
              onClick={() => setTrailsOpen(true)}
            >
              <CallSplit className={classes.icon} />
              {t('button:User.btn_profile_assign_trail')}
            </Button>
            {paginationNumber === TabValues.Courses && (
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => setOpen(true)}
              >
                <AddToQueueOutlined className={classes.icon} />
                {t('button:User.btn_profile_assign_content')}
              </Button>
            )}
            {/* {paginationNumber === TabValues.Teams && (
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => setOpen(true)}
              >
                {t('button:User.btn_profile_assign_team')}
              </Button>
            )} */}
          </div>
          <Card className={classes.cardContainer}>
            <Grid container className={classes.gridContainer}>
              <Grid item className={classes.avatarContainer}>
                <div>
                  <Avatar
                    variant="rounded"
                    className={classes.avagarImg}
                    alt="Person"
                    src={avatar}
                  />
                </div>
                <div className={classes.userInfo}>
                  <Grid container className={classes.userInfoName}>
                    <Typography variant="h3" component="h3">
                      {data.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.userInfoGroup}
                    >
                      {data.group?.name}
                    </Typography>
                  </Grid>

                  <Typography variant="body2">
                    {`${t('label:User.label_profile_last_access')} ${
                      data.last_login_at || ''
                    }`}
                  </Typography>

                  <Typography variant="body2">
                    {`${t('label:User.label_profile_avaliable_courses')} ${
                      data.avaiable_course
                    }`}
                  </Typography>
                </div>
              </Grid>

              <Grid item className={classes.userTagContainer}>
                {showEditContainer ? (
                  <div style={{display: 'none'}}>
                    <Typography variant="body2">
                      {t('label:User.label_update_tags')}
                    </Typography>
                    <Controller
                      as={
                        <Select
                          label={t(
                            'label:User.label_profile_avaliable_courses'
                          )}
                          className={classes.userTagEdit}
                          multiple
                          fullWidth
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((tag) => getChipsTags(tag))}
                            </div>
                          )}
                        >
                          {data.avaliable_tags.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id}>
                              {tag.title}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="tags"
                      control={control}
                      defaultValue={data.tags}
                    />
                  </div>
                ) : (
                  <div>
                    {/* <Typography variant="body2">
                      {t('label:User.label_manager_view_tags')}
                    </Typography>
                    {data.tags.length ? (
                      data.tags.map((tag) => getChipsTags(tag))
                    ) : (
                      <Typography variant="body2">
                        {t('label:User.label_without_tags')}
                      </Typography>
                    )} */}
                  </div>
                )}

                <div>
                  <Typography variant="body2">
                    {t('label:User.label_manager_view_team')}
                  </Typography>
                  {getTeamsTag(data.teamsName)}
                </div>
              </Grid>

              <Grid item xs={12} sm={5} className={classes.buttonsContainer}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="medium"
                  className={classes.userCardButtonSpace}
                  onClick={handleSendInactiveAlert}
                >
                  {t('button:User.btn_profile_send_alert')}
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  size="medium"
                  onClick={handlePasswordReset}
                >
                  {t('button:User.btn_profile_send_password_reset')}
                </Button>
              </Grid>
            </Grid>
          </Card>

          {!showEditContainer ? (
            <Grid container className={classes.tabContainer}>
              <Grid item xs={12}>
                <CustomTabComponent
                  components={tabComponents}
                  valueReturn={(value) => setPaginationNumber(value)}
                />
              </Grid>
            </Grid>
          ) : (
            <Card className={classes.tabContainer}>
              <CardHeader title={titlePage} />
              <Divider />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_first_name')}
                        helperText={
                          errors.name?.type === 'required' &&
                          t('validation:commons.validation_required', {
                            field: t('form:User.input_first_name'),
                          })
                        }
                        name="name"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ required: true })}
                        error={!!errors.name}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_email')}
                        helperText={
                          (errors.email?.type === 'required' &&
                            t('validation:commons.validation_required', {
                              field: t('form:User.input_email'),
                            })) ||
                          (errors.email?.type === 'pattern' &&
                            t('validation:commons.validation_email', {
                              field: t('form:User.input_email'),
                            })) ||
                          (errors.email?.type === 'validate' &&
                            t('validation:commons.validation_required', {
                              field: t('form:Signin.email_cpf'),
                            }))
                        }
                        name="email"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({
                          required: false,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          },
                          validate: (value) => !!cpf.current || !!value,
                        })}
                        error={!!errors.email}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: 8 }}>
                    <Grid item md={2} xs={12} className={clsx(classes.select)}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="id_group">
                          {t('form:User.input_group')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              {groupList.length > 0 &&
                                groupList.map((group) => (
                                  <MenuItem key={group.id} value={group.id}>
                                    {group.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                          name="id_group"
                          rules={{
                            required: t(
                              'validation:commons.validation_select_required'
                            ),
                          }}
                          control={control}
                          defaultValue={data.id_group}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12} className={clsx(classes.select)}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="status">
                          {t('form:commons.input_status')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={1}>
                                {t(statusUser[1]?.title)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t(statusUser[2]?.title)}
                              </MenuItem>
                              <MenuItem value={3}>
                                {t(statusUser[3]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={data.status}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={4}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_telephone')}
                        name="phone"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: PhoneMaskCustom,
                        }}
                        inputRef={register({ required: false })}
                        error={!!errors.phone}
                      />
                    </Grid>
                    <Grid item md={3} xs={4}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_cellphone')}
                        name="cellphone"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: PhoneMaskCustom,
                        }}
                        inputRef={register({ required: false })}
                        error={!!errors.cellphone}
                      />
                    </Grid>

                    <Grid item md={2} xs={4}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_cpf')}
                        helperText={
                          (errors.cpf?.type === 'pattern' &&
                            t('validation:commons.validation_cpf', {
                              field: t('form:User.input_cpf'),
                            })) ||
                          (errors.cpf?.type === 'validate' &&
                            t('validation:commons.validation_required', {
                              field: t('form:Signin.email_cpf'),
                            }))
                        }
                        name="cpf"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({
                          required: false,
                          pattern: {
                            value: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/i,
                          },
                        })}
                        error={!!errors.cpf}
                        InputProps={{
                          inputComponent: CpfMaskCustom,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: 8 }}>
                    {customFields?.map((field, index) => (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            display: !field?.visibleToEdit ? 'none' : 'unset',
                          }}
                        >
                          <TextField
                            fullWidth
                            // defaultValue={defaultValues[index].fieldValue}
                            defaultValue={
                              defaultValues[index].fieldValue ||
                              (field?.required && !watch(`array_custom_field_values[${index}].${field?.fieldName}`)
                                ? ' '
                                : '')
                            }
                            name={`array_custom_field_values[${index}].${field?.fieldName}`}
                            variant="outlined"
                            label={field?.fieldName || ''}
                            error={
                              !!!errors?.array_custom_field_values
                                ? false
                                : errors?.array_custom_field_values[index]
                                ? errors?.array_custom_field_values[index][
                                    field?.fieldName
                                  ]
                                : false
                            }
                            inputRef={register({
                              required: false,
                            })}
                          />
                          <FormHelperText
                            error={
                              !!!errors?.array_custom_field_values
                                ? false
                                : errors?.array_custom_field_values[index]
                                ? errors?.array_custom_field_values[index][
                                    field?.fieldName
                                  ]
                                : false
                            }
                          >
                            {!errors?.array_custom_field_values
                              ? ''
                              : errors?.array_custom_field_values[index]
                              ? errors?.array_custom_field_values[index][
                                  field?.fieldName
                                ]?.type === 'required' &&
                                t('validation:commons.validation_required', {})
                              : ''}
                          </FormHelperText>
                        </Grid>
                      </>
                    ))}
                  </Grid>

                  <Grid container>
                    <Box width="100%" paddingTop={theme.spacing(0.3)}>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          className={clsx(classes.select)}
                        >
                          <InputLabel id="signable">
                            {t('form:User.switch_password')}
                          </InputLabel>
                          <Switch
                            id="signable"
                            checked={passwordSwitch}
                            onChange={handlePasswordChange}
                            color="primary"
                            name="signable"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>

                        <Grid item md={5} xs={12}>
                          <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                            fullWidth
                            error={!!errors.password}
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t('form:User.input_new_password')}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={visible ? 'text' : 'password'}
                              name="password"
                              inputRef={
                                passwordSwitch &&
                                register({ required: true, minLength: 6 })
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {visible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              labelWidth={230}
                              disabled={!passwordSwitch}
                            />

                            <FormHelperText>
                              {(errors.password?.type === 'required' &&
                                t(
                                  'validation:commons.validation_required',
                                  {}
                                )) ||
                                (errors.password?.type === 'minLength' &&
                                  t('validation:commons.validation_min_pass', {
                                    minLength: 6,
                                  }))}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <FormControl
                            className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                            fullWidth
                            error={!!errors.password_repeat}
                          >
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t('form:User.input_confirm_password')}
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={visible ? 'text' : 'password'}
                              name="password_repeat"
                              inputRef={
                                passwordSwitch &&
                                register({
                                  minLength: 6,
                                  validate: (value) =>
                                    value === password.current,
                                })
                              }
                              labelWidth={230}
                              disabled={!passwordSwitch}
                            />
                            <FormHelperText>
                              {(errors.password_repeat?.type === 'validate' &&
                                t(
                                  'validation:commons.validation_confirm_password',
                                  {}
                                )) ||
                                (errors.password_repeat?.type === 'minLength' &&
                                  t('validation:commons.validation_min_pass', {
                                    minLength: 6,
                                  }))}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </CardContent>

                <Divider />
                <CardActions className={classes.actions}>
                  <Button
                    className={classes.actionButton}
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={handleShowEdit}
                  >
                    <ArrowBackIos />
                    {t('button:commons.btn_back')}
                  </Button>
                  <Button
                    className={classes.actionButton}
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                  >
                    <Save style={{ marginRight: 8 }} />
                    {t('button:commons.btn_save')}
                  </Button>
                </CardActions>
              </form>
            </Card>
          )}
        </div>
      </div>
       
      )}
      <HandleBlock
        route={`users/${id}`}
        isOpen={modalStatus}
        closeModal={setModalStatus}
        data={
          userStatus === 1
            ? { name: data.name, email: data.email, status: 2 }
            : { name: data.name, email: data.email, status: 1 }
        }
        setItem={setUserStatus}
      />
    </>
  );
};

export const normalizeValues = (fields, values) => {
  const newArray = [];
  let valuesIndex = 0;

  fields.forEach((data, i) => {
    if (data?.fieldName !== values[i + valuesIndex]?.fieldName) {
      newArray.push({
        fieldName: data?.fieldName,
        fieldValue: '',
        show: false,
      });
      valuesIndex--;
    } else {
      newArray.push({
        fieldName: data?.fieldName,
        fieldValue: values[i + valuesIndex]?.fieldValue,
        show: true,
      });
    }
  });

  return newArray;
};

class TabValues {
  static Courses = 0;
  static Teams = 1;
  static Attendance = 2;
  static Classroom = 3;
}
