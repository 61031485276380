import React, { useState, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Checkbox,
} from '@material-ui/core';

import { useStyles } from './styles';

import { GroupsData, UserSolicitationData } from 'services/dataService';
import { statusUserSolicitation } from 'constants/status';
import { localizationTable, options } from 'constants/table';
import { Search } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const SolicitationStatus = {
  pending: 1,
  approved: 2,
  reproved: 3,
};

export default () => {
  const [status, setStatus] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [filter, setFilter] = useState(1);
  const [queryStatus, setQueryStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const checkedRows = useRef([]);

  const { id_company } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();
  const classes = useStyles();

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`user-solicitations/${id}/edit`);
    },
    [history]
  );

  const onSubmit = (data) => {
    setIsLoading(true);
    setName(data.name);
    setEmail(data.email);
    setStatus(data.status);
    setQueryStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const acceptAllChecked = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await GroupsData.getGroups();
      const { id } = response.data.groups;
      checkedRows.current = checkedRows.current.map((row) => {
        return { ...row, id_group: id, status: SolicitationStatus.approved };
      });
      await UserSolicitationData.updateMassive(checkedRows.current, id_company);
      tableRef.current.state.query.page = 0;
      tableRef.current.onQueryChange();
      toast.success(t('toastify:commons.toast_update'));
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
      tableRef.current.state.query.page = 0;
      tableRef.current.onQueryChange();
    }
  });

  const setSolicitation = async (user, status) => {
    setIsLoading(true);
    user.status = status;
    if (status === SolicitationStatus.approved) {
      const response = await GroupsData.getGroups();
      const { id } = response.data.groups.filter((a) => a?.type !== 3);

      user.id_company = id_company;
      user.id_group = id;
    }
    try {
      await UserSolicitationData.updateUserSolicitation(user.id, user);
      tableRef.current.state.query.page = 0;
      tableRef.current.onQueryChange();
      toast.success(t('toastify:commons.toast_update'));
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
      tableRef.current.state.query.page = 0;
      tableRef.current.onQueryChange();
    }
  };

  return (
    <div className='notranslate'>
         <MaterialTable
      title={`${t('tables:user_solicitations.title')}`}
      tableRef={tableRef}
      columns={[
        {
          title: `${t('tables:user_solicitations.name_column')}`,
          field: `name`,
          sorting: false,
          render: (rowData) => rowData.name,
        },
        {
          title: `${t('tables:user_solicitations.email_column')}`,
          sorting: false,
          field: 'email',
        },
        {
          title: `${t('tables:user_solicitations.status_column')}`,
          field: 'status',
          sorting: false,
          render: (rowData) => (
            <div style={{ display: 'flex' }}>
              <CircleIcon
                style={statusUserSolicitation[rowData.status]?.style}
                fontSize="small"
              />
              {t(statusUserSolicitation[rowData.status]?.title)}{' '}
            </div>
          ),
        },
        {
          title: `${t('tables:user_solicitations.solicitation_date_column')}`,
          field: 'createdAt',
          sorting: false,
          render: (rowData) =>
            format(parseISO(rowData.createdAt), 'dd/MM/yyyy'),
        },
      ]}
      onSelectionChange={(rows) => {
        checkedRows.current = rows;
      }}
      data={async (query) => {
        try {
          const response = await UserSolicitationData.getUserSolicitations({
            page: query.page + 1,
            limit: query.pageSize,
            status,
            name,
            email,
          });
          setIsLoading(false);
          return {
            data: response.data.userSolicitations,
            page: response.data.currentPage - 1,
            totalCount: response.data.totalCount,
          };
        } catch (err) {
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
          setIsLoading(false);

          return { data: [] };
        }
      }}
      components={{
        search: true,
        title: true,
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Grid container spacing={3}>
                  {queryStatus !== SolicitationStatus.approved && (
                    <Grid item md={2} xs={12} style={{}}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => acceptAllChecked()}
                      >
                        {t('button:User_solictation.btn_accept_all')}
                      </Button>
                    </Grid>
                  )}

                  <Grid
                    item
                    md={queryStatus !== SolicitationStatus.approved ? 2 : 4}
                    xs={12}
                  >
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Filtro
                      </InputLabel>
                      <Controller
                        as={
                          <Select label="Filter">
                            <MenuItem value={1}>
                              {t('tables:commons.name_column')}
                            </MenuItem>
                            <MenuItem value={2}>
                              {t('tables:commons.email_column')}
                            </MenuItem>
                          </Select>
                        }
                        name="filter"
                        control={control}
                        defaultValue={filter ? filter : 1}
                        value={filter ? filter : 1}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label={t('form:commons.input_search')}
                      name="name"
                      type="text"
                      variant="outlined"
                      defaultValue={name || ''}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <FormControl style={{ minWidth: 100 }}>
                      <InputLabel id="status">
                        {t('form:User_solicitation.input_status_select')}
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            <MenuItem value={SolicitationStatus.pending}>
                              {t(statusUserSolicitation[1]?.title)}
                            </MenuItem>
                            <MenuItem value={SolicitationStatus.approved}>
                              {t(statusUserSolicitation[2]?.title)}
                            </MenuItem>
                            <MenuItem value={SolicitationStatus.reproved}>
                              {t(statusUserSolicitation[3]?.title)}
                            </MenuItem>
                          </Select>
                        }
                        name="status"
                        control={control}
                        defaultValue={status ? status : 1}
                        value={status ? status : 1}
                        onChange={(e) => setStatus(e[1].props.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={12} className={classes.actions}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      <Search style={{ marginRight: '5px' }} />

                      {t('button:User_solictation.btn_advanced_filter')}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </div>
        ),
      }}
      actions={[
        {
          icon: 'visibility',
          tooltip: `${t('tables:user_solicitations.actions.show_action')}`,
          position: 'row',
          onClick: (event, rowData) => {
            handleEdit(rowData);
          },
          iconProps: { color: 'primary' },
        },
        (queryStatus === SolicitationStatus.pending ||
          queryStatus === SolicitationStatus.reproved) && {
          // alterar esse para o status 1 e 3
          icon: 'how_to_reg',
          tooltip: `${t(
            'tables:user_solicitations.actions.solicitation_approve'
          )}`,
          position: 'row',
          onClick: (event, rowData) => {
            setSolicitation(rowData, SolicitationStatus.approved);
          },
          iconProps: { color: 'secondary' },
        },
        queryStatus === SolicitationStatus.approved && {
          icon: 'person_outline',
          tooltip: `${t('tables:user_solicitations.actions.view_user_action')}`,
          position: 'row',
          onClick: (event, rowData) => {
            history.push(`/users/${rowData.id_user}/profile`);
          },
          iconProps: { color: 'primary' },
        },
        queryStatus === SolicitationStatus.pending && {
          icon: 'not_interested',
          iconProps: {
            color: 'error',
          },
          tooltip: `${t(
            'tables:user_solicitations.actions.solicitation_reprove'
          )}`,
          position: 'row',
          onClick: (e, rowData) =>
            setSolicitation(rowData, SolicitationStatus.reproved),
        },
      ]}
      isLoading={isLoading}
      localization={localizationTable(t)}
      options={{
        ...options,
        emptyRowsWhenPaging: false,
        selection: queryStatus !== SolicitationStatus.approved,
      }}
    />
    </div>
 
  );
};
