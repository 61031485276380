import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { localizationTable, options } from 'constants/table';
import { format, parseISO } from 'date-fns';
import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useStyles, Card, CourseDescription } from './styles';
import { CourseData } from 'services/dataService';
import { statusCourses } from 'constants/status';
import { Search } from '@material-ui/icons';
import { statusUser } from 'constants/status';

export default () => {
  const [status, setStatus] = useState(2);
  const [name, setName] = useState('');
  const [statusSelected, setStatusSelected] = useState(1);
  const [filter, setFilter] = useState(1);

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const onSubmit = (data) => {
    setName(data.name);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.courseContainer} xs={12}>
        <MaterialTable
          title={`${t('tables:course.title_top_access')}`}
          tableRef={tableRef}
          columns={[
            {
              title: `${t('tables:course.name_column')}`,
              field: `course.name`,
            },
            {
              title: `${t('tables:course.createdAt_column')}`,
              field: 'createdAt',
              render: (rowData) =>
                format(parseISO(rowData.createdAt), 'dd/MM/yyyy'),
            },
            {
              title: `${t('tables:commons.status_column')}`,
              field: 'status',
              render: (rowData) => t(statusCourses[rowData.status]?.title),
            },
            {
              title: `${t('tables:course.enrollment_column')}`,
              field: 'status',
              render: (rowData) => <span>Mock</span>,
            },
            {
              title: `${t('tables:course.teacher_column')}`,
              field: 'status',
              render: (rowData) => <span>Mock</span>,
            },
            {
              title: `${t('tables:course.students_column')}`,
              field: 'status',
              render: (rowData) => <span>Mock</span>,
            },
          ]}
          data={async (query) => {
            try {
              const response = await CourseData.getCourses({
                page: query.page + 1,
                limit: query.pageSize,
                status,
              });

              return {
                data: response.data.courses,
                page: response.data.currentPage - 1,
                totalCount: response.data.totalCount,
              };
            } catch (err) {
              toast.error(t('toastify:commons.toast_error_api'));
              return { data: [] };
            }
          }}
          components={{
            search: false,
            title: true,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={2} xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Filtro
                          </InputLabel>
                          <Controller
                            as={
                              <Select label="Filter">
                                <MenuItem value={1}>
                                  {t('tables:commons.name_column')}
                                </MenuItem>
                                <MenuItem value={2}>
                                  {t('tables:commons.email_column')}
                                </MenuItem>
                              </Select>
                            }
                            name="filter"
                            control={control}
                            defaultValue={filter ? filter : 1}
                            value={filter ? filter : 1}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label={t('form:commons.input_search')}
                          name="name"
                          type="text"
                          variant="outlined"
                          defaultValue={name || ''}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <FormControl style={{ minWidth: 100 }}>
                          <InputLabel id="status">
                            {t('form:commons.input_status')}
                          </InputLabel>

                          <Controller
                            as={
                              <Select>
                                <MenuItem value={1}>
                                  {t(statusUser[1]?.title)}
                                </MenuItem>
                                <MenuItem value={2}>
                                  {t(statusUser[2]?.title)}
                                </MenuItem>
                                <MenuItem value={3}>
                                  {t(statusUser[3]?.title)}
                                </MenuItem>
                              </Select>
                            }
                            name="statusSelected"
                            control={control}
                            defaultValue={statusSelected ? statusSelected : 1}
                            value={statusSelected ? statusSelected : 1}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={2} xs={12} className={classes.actions}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          type="submit"
                        >
                          {t('button:commons.btn_advanced_filter')}
                          <Search />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t(
                'tables:commons.body.emptyDataSourceMessage'
              ),
              filterRow: {
                filterTooltip: t('tables:commons.body.filterRow.filterTooltip'),
              },
            },
            header: {
              actions: t('tables:commons.header.label_action'),
            },
            pagination: {
              labelDisplayedRows: t(
                'tables:commons.pagination.labelDisplayedRows'
              ),
              labelRowsSelect: t('tables:commons.pagination.labelRowsSelect'),
              firstTooltip: t('tables:commons.pagination.firstTooltip'),
              previousTooltip: t('tables:commons.pagination.previousTooltip'),
              nextTooltip: t('tables:commons.pagination.nextTooltip'),
              lastTooltip: t('tables:commons.pagination.lastTooltip'),
            },
            toolbar: {
              exportName: t('tables:commons.toolbar.exportName'),
              exportTitle: t('tables:commons.toolbar.exportTitle'),
              searchPlaceholder: t('tables:commons.toolbar.searchPlaceholder'),
              searchTooltip: t('tables:commons.toolbar.searchTooltip'),
            },
          }}
          options={{
            search: false,
            exportButton: true,
            actionsColumnIndex: -1,
            pageSize: 5,
            rowStyle: (rowData) => ({
              backgroundColor: rowData.tableData.id % 2 === 0 ? '#F6F9FC' : '#FFFF',
            }),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title={`${t('tables:course.topRated_title')}`}
          tableRef={tableRef}
          columns={[
            {
              title: `${t('tables:course.name_column')}`,
              field: `course.name`,
            },
            {
              title: `${t('tables:course.createdAt_column')}`,
              field: 'createdAt',
              render: (rowData) =>
                format(parseISO(rowData.createdAt), 'dd/MM/yyyy'),
            },
            {
              title: `${t('tables:commons.status_column')}`,
              field: 'status',
              render: (rowData) => t(statusCourses[rowData.status]?.title),
            },
            {
              title: `${t('tables:course.enrollment_column')}`,
              field: 'status',
              render: (rowData) => <span>Mock</span>,
            },
            {
              title: `${t('tables:course.teacher_column')}`,
              field: 'status',
              render: (rowData) => <span>Mock</span>,
            },
            {
              title: `${t('tables:course.students_column')}`,
              field: 'status',
              render: (rowData) => <span>Mock</span>,
            },
          ]}
          data={async (query) => {
            try {
              const response = await CourseData.getCourses({
                page: query.page + 1,
                limit: query.pageSize,
                status,
                name,
              });

              return {
                data: response.data.courses,
                page: response.data.currentPage - 1,
                totalCount: response.data.totalCount,
              };
            } catch (err) {
              if (err.response?.status === 403) {
                toast.error(t('toastify:commons.toast_error_403'));
              } else {
                toast.error(t('toastify:commons.toast_error_api'));
              }
              return { data: [] };
            }
          }}
          components={{
            search: false,
            title: true,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={2} xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Filtro
                          </InputLabel>
                          <Controller
                            as={
                              <Select label="Filter">
                                <MenuItem value={1}>
                                  {t('tables:commons.name_column')}
                                </MenuItem>
                                <MenuItem value={2}>
                                  {t('tables:commons.email_column')}
                                </MenuItem>
                              </Select>
                            }
                            name="filter"
                            control={control}
                            defaultValue={filter ? filter : 1}
                            value={filter ? filter : 1}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label={t('form:commons.input_search')}
                          name="name"
                          type="text"
                          variant="outlined"
                          defaultValue={name || ''}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <FormControl style={{ minWidth: 100 }}>
                          <InputLabel id="status">
                            {t('form:commons.input_status')}
                          </InputLabel>

                          <Controller
                            as={
                              <Select>
                                <MenuItem value={1}>
                                  {t(statusUser[1]?.title)}
                                </MenuItem>
                                <MenuItem value={2}>
                                  {t(statusUser[2]?.title)}
                                </MenuItem>
                                <MenuItem value={3}>
                                  {t(statusUser[3]?.title)}
                                </MenuItem>
                              </Select>
                            }
                            name="status"
                            control={control}
                            defaultValue={statusSelected ? statusSelected : 1}
                            value={statusSelected ? statusSelected : 1}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={2} xs={12} className={classes.actions}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          type="submit"
                        >
                          {t('button:commons.btn_advanced_filter')}
                          <Search />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={localizationTable(t)}
          options={options}
        />
      </Grid>
    </Grid>
  );
};
