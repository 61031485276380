import querystring from 'querystring';
import api from '../api';

class DataServiceTags {
  addTags(data) {
    return api.post('tags', data);
  }

  getTags(params) {
    const url = 'tags?' + querystring.stringify(params);
    return api.get(url);
  }

  getTagsById(id) {
    return api.get(`tags/${id}`);
  }

  updateTags(id, data) {
    return api.put(`tags/${id}`, data);
  }
}

export default new DataServiceTags();
