export function signInRequest(email, password, domain, forceIdUser) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password, domain, forceIdUser },
  };
}

export function signInSuccess(token, user, idAdminUser, idStudentUser, shouldChangePassword) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, idAdminUser, idStudentUser, shouldChangePassword },
  };
}

export function signUpRequest(email, name, password, domain) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { email, name, password, domain },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function companyInformation(id_company, exp, company_logo) {
  return {
    type: '@auth/COMPANY',
    payload: { id_company, exp, company_logo },
  };
}

export function userAvatar(avatar) {
  return {
    type: '@user/AVATAR',
    payload: { avatar },
  };
}

export function putPendentAttendance(pendent_attendance) {
  return {
    type: '@user/PENDENT_ATTENDANCE',
    payload: { pendent_attendance },
  };
}

export function changeUserName(user_name) {
  return {
    type: '@user/change_name',
    payload: user_name,
  };
}

export function changePasswordRequest(password, passwordConfirmation, domain){
  return {
    type: '@auth/CHANGE_PASSWORD_REQUEST',
    payload: { password, passwordConfirmation, domain },
  }; 
}

export function changePasswordSuccess(token, user, shouldChangePassword) {
  return {
    type: '@auth/CHANGE_PASSWORD_SUCCESS',
    payload: { token, user, shouldChangePassword },
  };
}
export function changePasswordError(token, user, shouldChangePassword) {
  return {
    type: '@auth/CHANGE_PASSWORD_ERROR',
  };
}
