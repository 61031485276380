import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
  },
  mobileRoot: {
    paddingTop: theme.spacing(2),
  },
  containerDescription: {
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  boxTrailTitle: {
    paddingBottom: theme.spacing(2),
  },
  trailTitle: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '100%',
    fontWeight: theme.typography.h3.fontWeight,
  },
  likeButton: {
    color: theme.palette.error.main,
    padding: 5,
  },
  containerSpacing: {
    marginTop: theme.spacing(1),
  },
  categories: { minWidth: 120 },
  lastChip: {
    marginLeft: theme.spacing(4),
  },
  trailDescriptionBox: {
    maxHeight: 235,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d9d9d9',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '8px',
    },
  },
  gridImage: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
  },
  logo: {
    width: '100%',
    height: '100%',
    maxWidth: 522,
    maxHeight: 310,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      maxHeight: 200,
    },
  },
  [theme.breakpoints.up('md')]: {
    overflowDescription: {
      overflow: 'auto',
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
      marginTop: 11,
      '& p': {
        paddingRight: 5,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    overflowDescription: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
