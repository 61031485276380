import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InputMask from 'react-input-mask';

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CourseData } from 'services/dataService';
import { useDispatch, useSelector } from 'react-redux';
import { JUNO_CHECKOUT } from 'store/modules/junoAPI/actions';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { ShoppingCartOutlined } from '@material-ui/icons';
import ChargeAddress from './components/ChargeAddress';
import { PaymentSteps } from 'views/CourseBuyView';

const SelectValues = {
  MONTHS: 12,
  INSTALLMENTS: 12,
  VALIDATION_RANGE: 8,
};

const CreditCardPayment = ({ callback }) => {
  const classes = useStyles();
  const { t } = useTranslation(['validation', 'commons']);
  const { personalData } = useSelector((state) => state.junoAPI);
  const { id: id_company_course } = useParams();
  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
  } = useForm();
  const [courseValue, setCourseValue] = React.useState(0);
  const dispatch = useDispatch();
  const [cvc, setCVC] = React.useState('');
  const [focus, setFocus] = React.useState();
  const [name, setName] = React.useState('');

  const [number, setNumber] = React.useState('');
  const expiry = watch(['cc-exp-month', 'cc-exp-year']);

  const getCourseValue = async () => {
    const { data: course } = await CourseData.getCompanyCourseById(
      id_company_course
    );
    setCourseValue(course?.price);
  };

  const getSelectValues = (values, installment) => {
    return Array(values)
      .fill()
      .map((_, index) => {
        return (
          <MenuItem value={`${index + 1}`}>
            {installment
              ? `${index + 1}x de ${(
                ((courseValue / (index + 1)) * 100) / 100 || 0
              ).toFixed(2)}`
              : index + 1}
          </MenuItem>
        );
      });
  };

  const getYearValidation = () => {
    return Array(SelectValues.VALIDATION_RANGE)
      .fill()
      .map((_, index) => {
        const value = `${new Date().getFullYear() + index}`;
        return <MenuItem value={value}>{value}</MenuItem>;
      });
  };

  const handleInputFocus = (name) => {
    setFocus(name);
  };

  const checkout = React.useCallback((data) => {
    const cardData = {
      holderName: data['cc-name'],
      cardNumber: data['cc-number']?.replace(/\s/g, ''),
      securityCode: data['cc-csc'],
      expirationMonth: data['cc-exp-month'],
      expirationYear: data['cc-exp-year'],
    };
    // console.log(personalData);

    const billing = {
      address: {
        ...data?.billing,
        postCode: data?.billing?.postCode?.replace('-', ''),
      },
      ...personalData,
    };

    dispatch({
      type: JUNO_CHECKOUT,
      payload: {
        cardData,
        billing,
        course_id: id_company_course,
        installments: data?.installments,
        callback,
      },
    });
  });

  React.useEffect(() => {
    getCourseValue();
    handleInputFocus('name');
  }, []);
  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit(checkout)} method="post">
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Box className={classes.topSpacing}>
              <Grid container spacing={4}>
                <Grid item xs={12} className={classes.topZeroSpacing}>
                  <TextField
                    fullWidth
                    autoFocus
                    name="cc-name"
                    autoComplete="cc-name"
                    variant="outlined"
                    label="Nome impresso no cartão"
                    tabIndex={1}
                    type="text"
                    inputRef={register({ required: true })}
                    helperText={
                      errors['cc-name']?.type === 'required' &&
                      t('validation:commons.validation_required')
                    }
                    error={!!errors['cc-name']}
                    onFocus={() => handleInputFocus('name')}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.topZeroSpacing}>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    maskChar=""
                    onFocus={() => handleInputFocus('number')}
                    onChange={(e) => setNumber(e.target.value)}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        fullWidth
                        variant="outlined"
                        autoComplete="cc-number"
                        label="Número do cartão de crédito"
                        type="tel"
                        name="cc-number"
                        inputRef={register({ required: true })}
                        helperText={
                          errors['cc-number']?.type === 'required' &&
                          t('validation:commons.validation_required')
                        }
                        error={!!errors['cc-number']}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} className={classes.topZeroSpacing}>
                  <FormControl fullWidth>
                    <InputLabel
                      error={!!errors['installments']}
                      id="cc-exp-month-label"
                    >
                      Número parcelas
                    </InputLabel>
                    <Controller
                      fullWidth
                      id="installments-select"
                      name="installments"
                      control={control}
                      rules={{
                        required: t('validation:commons.validation_select_required'),
                      }}
                      as={
                        <Select
                          error={!!errors['installments']}
                          fullWidth
                          tabIndex={6}
                          name="installments"
                        >
                          {getSelectValues(SelectValues.INSTALLMENTS, true)}
                        </Select>
                      }
                    />
                    {errors['installments']?.type === 'required' && (
                      <FormHelperText error={!!errors['installments']}>
                        {t('validation:commons.validation_required')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box>
              <Cards
                focused={focus}
                locale={{ valid: 'vencimento' }}
                name={name}
                number={number}
                cvc={cvc}
                expiry={`${(expiry['cc-exp-month'] || 0) > 9
                    ? '' + (expiry['cc-exp-month'] || '')
                    : (expiry['cc-exp-month'] ? '0' : '') +
                    (expiry['cc-exp-month'] || '')
                  }/${expiry['cc-exp-year']?.slice(-2) || ''}`}
              />
            </Box>
          </Grid>
        </Grid>
        <Typography className={classes.topSpacing} />
        <Grid container spacing={3} className={classes.topSpacing}>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel
                id="cc-exp-month-label"
                error={!!errors['cc-exp-month']}
              >
                Mês de expiração
              </InputLabel>
              <Controller
                fullWidth
                id="cc-exp-month-select"
                name="cc-exp-month"
                control={control}
                rules={{
                  required: t('validation:commons.validation_select_required'),
                }}
                onFocus={() => handleInputFocus('expiry')}
                as={
                  <Select
                    fullWidth
                    id="cc-exp-month-select"
                    error={!!errors['cc-exp-month']}
                    name="cc-exp-month"
                    label=""
                    autoComplete="cc-exp-month"
                    type="tel"
                    tabIndex={3}
                  >
                    {getSelectValues(SelectValues.MONTHS)}
                  </Select>
                }
              />
              {errors['cc-exp-month']?.type === 'required' && (
                <FormHelperText error={!!errors['cc-exp-month']}>
                  {t('validation:commons.validation_required')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel
                error={!!errors['cc-exp-year']}
                id="cc-exp-month-label"
              >
                Ano de expiração
              </InputLabel>
              <Controller
                fullWidth
                id="cc-exp-year-select"
                name="cc-exp-year"
                control={control}
                rules={{
                  required: t('validation:commons.validation_select_required'),
                }}
                onFocus={() => handleInputFocus()}
                as={
                  <Select
                    fullWidth
                    id="cc-exp-year-select"
                    name="cc-exp-year"
                    error={!!errors['cc-exp-year']}
                    name="cc-exp-year"
                    autoComplete="cc-exp-year"
                    type="tel"
                    tabIndex={4}
                  >
                    {getYearValidation()}
                  </Select>
                }
              />
              {errors['cc-exp-year']?.type === 'required' && (
                <FormHelperText error={!!errors['cc-exp-year']}>
                  {t('validation:commons.validation_required')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <InputMask
              mask="999"
              maskChar=""
              onFocus={() => handleInputFocus('cvc')}
              onChange={(e) => setCVC(e.target.value)}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  label="Código de segurança (cvv)"
                  type="tel"
                  name="cc-csc"
                  autoComplete="cc-csc"
                  variant="outlined"
                  tabIndex={5}
                  inputmode="numeric"
                  inputRef={register({ required: true })}
                  helperText={
                    errors['cc-csc']?.type === 'required' &&
                    t('validation:commons.validation_required')
                  }
                  error={!!errors['cc-csc']}
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
        <Typography className={classes.topSpacing} />
        <ChargeAddress
          name="billing"
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
        <Grid container spacing={2} justify="flex-end" alignItems="flex-end">
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6} align="end">
            <Box className={classes.buttonBox}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                <ShoppingCartOutlined className={classes.icon} />
                Finalizar Compra
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  buttonBox: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    }
  },
  topSpacing: {
    paddingTop: theme.spacing(2),
  },
  topZeroSpacing: {
    paddingTop: '0 !important',
  },
  icon: {
    marginRight: theme.spacing(1)
  },
}));

export default CreditCardPayment;
