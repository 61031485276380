import querystring from 'querystring';
import api from '../api';

class DataServiceUserContract {
  getUsersContract(version,params) {      
    const url = `usercontracts/${version}?` + querystring.stringify(params);        
    return api.get(url);
  }

  getPendingAccept(idUser) {    
    const url = `pendingaccept/${idUser}`     
    return api.get(url);
  }  

  updateUsersContract(id, data) {
    return api.put(`usercontracts/${id}`, data);
  }  

  addUserContract(data) {
    return api.post('usercontracts', data);
  }
}

export default new DataServiceUserContract();
