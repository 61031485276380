import querystring from 'querystring';
import api from '../api';

class EmailTemplateService {
  getAllEmailTemplate() {
    return api.get(`email`);
  }

  getQuerySearch(querys) {
    const { date_begin, date_end, name, status } = querys.filters;
    return api.get(`email`, {
      params: {
        date_begin: date_begin ? date_begin.toISOString() : '',
        date_end: date_end ? date_end.toISOString() : '',
        page: querys.page,
        limit: querys.limit,
        name: name,
        status: status,
      },
    });
  }

  getEmailTemplateById(id) {
    return api.get(`email/${id}`);
  }

  createEmailTemplate(data) {
    return api.post(`email`, data);
  }
  updateEmailTemplate(id, data) {
    return api.put(`email/${id}`, data);
  }
  deleteEmailTemplate(id) {
    return api.delete(`email/${id}`);
  }
}

export default new EmailTemplateService();
