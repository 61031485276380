import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import AdminRouter from 'routes/AdminRouter/AdminRouter';
import UserRouter from 'routes/UserRouter/UserRouter';
import AccpetRouter from 'routes/AcceptRouter/AcceptRouter'
import { UserContractData } from './services/dataService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import history from 'services/history';

const ProfileType = {
  ADMIN: 1,
  STUDENT: 2,
  TEACHER: 3,
};


const Routes = () => {
  const [loading, setLoading] = useState(true);
  const [userBlock, setUserBlock] = useState(false);
  const [retPending, setRetPending] = useState('');
  const { t } = useTranslation(['toastify']);

  const userProfile = useSelector((state) => state.auth.type);
  const idUser = useSelector((state) => state.auth.id);
  useEffect(() => {    
    if (idUser) {      
      try {
        (async () => {
          const response = await UserContractData.getPendingAccept(
            idUser
          )
          response.data.ret.idUser = idUser                    
          setUserBlock(response.data.ret.block)
          setRetPending(response.data.ret)         
          setLoading(false);
        })();
      } catch (err) {
        toast.error(t('toastify:commons.toast_error_api'));
        return { data: [] };
      }
    }
    setUserBlock(false);
    setLoading(false);
  }, [idUser]);

  const userIs = (profileType) => {
    return userProfile === profileType;
  };

  if (!loading)
    return (
      <React.Fragment>
        <Router history={history}>
          {userIs(ProfileType.ADMIN) ? <AdminRouter /> :
            (
              userBlock ? <AccpetRouter 
              retPending={retPending} 
               /> : <UserRouter />
            )
          }
        </Router>
      </React.Fragment>
    );
  return <></>
};

export default Routes;
