import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& div': {
      outline: 'none',
    },
    '& .slick-slide': {
      maxWidth: 401
    },
    '& .slick-dots': {
      [theme.breakpoints.down('md')]: {
        display: 'flex !important',
        justifyContent: 'center',
        overflowX: 'hidden'
      }
    }
  },
  arrows: {
    '& svg': {
      '&:hover': {
        color: '#000'
      }
    },
    '& .slick-list': {
      paddingBottom: '1rem'
    }
  }
}));