import React, { useEffect, useState } from 'react';
import { Box, Slide, TextField, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserData, AttendanceData } from 'services/dataService';
import { toast } from 'react-toastify';
import SelectTable from './SelectTable';

export default function AddPeople({ onSave, peopleData, selectedAttendanceData }) {
  const { t } = useTranslation(['form']);
  const [filter, setFilter] = React.useState(null);
  const [summary, setSummary] = useState([]);
  
  const columns = [
    { label: 'Nome', field: 'name' },
    { label: 'E-mail', field: 'email' },
    { label: 'CPF', field: 'cpf' },
  ];

  const handleOnSelect = ({ type, selectedIds }) => {
    const updatedSummary = summary.map((item) => ({
      ...item,
      selected: selectedIds.includes(item.id),
    }));
    setSummary(updatedSummary);
  };

  const handleFilter = (event, value) => {
    setFilter(value);
  };

  const fetchData = async () => {
    try {
      const response = await AttendanceData.getAttendanceFields();
      const updatedSummary = response.data.users.map((item) => {
        const selectedItem = selectedAttendanceData?.idUsers.find(user => user.id_user === item.id);
        return {
          ...item,
          selected: selectedItem ? true : false,
          id_attendance_enrollment: selectedItem ? selectedItem.id_attendance_enrollment : null,
          id_enrollment: selectedItem ? selectedItem.id_enrollment : null,
        };
      });
      setSummary(updatedSummary);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    onSave(summary.filter(item => item.selected));
  }, [summary]);

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box>
        <Box>
          <Typography>{t('form:Team.Users.label')}</Typography>
        </Box>
        <Box>
          <Autocomplete
            fullWidth
            clearOnEscape
            value={filter}
            options={summary}
            isOptionEqualToValue={(opt, value) => opt.id === value.id}
            getOptionLabel={(option) => `${option?.name ? option.name + ' - ' : ''}${option?.cpf || ''}`}
            onChange={handleFilter}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                label={t('form:Teams.search_field')}
                variant="outlined"
              />
            )}
            filterOptions={(options, { inputValue }) =>
              options.filter((option) => {
                const name = option.name || '';
                const cpf = option.cpf || '';
                return (
                  (name && name.toLowerCase().includes(inputValue.toLowerCase())) ||
                  (cpf && cpf.toLowerCase().includes(inputValue.toLowerCase()))
                );
              }).slice(0, 7)
            }
            getOptionSelected={(option, value) => option.id === value.id}
          />
        </Box>
        <Box>
          <SelectTable
            cols={columns}
            rows={summary.filter((student) => !filter || student.id === filter.id || student.selected)}
            onSelect={handleOnSelect}
            length={summary.length}
            totalSelected={summary.filter((s) => s.selected).length}
            rowsPerPage={50}
          />
        </Box>
      </Box>
    </Slide>
  );
}
