import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  status: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));
