import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    // height: '85px',
    width: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 32,
    width: 32,
  },
  icon: {
    height: 20,
    width: 20,
  },
  button: {
    height: 48,
    width: 48,
  },
  difference: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color: theme.palette.success.dark,
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
  infoText: {
    display: 'flex',
  },
  greenText: {
    color: '#2DCE98',
    marginRight: '5px',
  },
  redText: {
    color: '#F53C56',
    marginRight: '5px',
  },
  cardcontent: {
    padding: '15px !important',
    // '&:last-child': {
    //   paddingBottom: 0,
    // },
  },
  cardContainer: {
    flexWrap: 'nowrap',
  },
  titleNumberContent: {
    overflow: 'hidden',
  },
  titleCard: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatarGrid: {
    paddingLeft: theme.spacing(1),
  }
}));
