import querystring from 'querystring';
import api from '../../api';

class DataServiceLooker {

    addAllEnrollments() {
        return api.post('/reports/looker-update-rows');
    }

    addAllComments() {
        return api.post('/reports/looker-update-rows-comment');
    }

    addAllRatings() {
        return api.post('/reports/looker-update-rows-ratings');
    }

}

export default new DataServiceLooker();
