import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_JUNO_API_KEY_SAGA } from 'store/modules/junoAPI/actions';
import theme from 'theme';

export default function PaymentComponent({ children }) {
  const dispatch = useDispatch();
  const { fetching } = useSelector((state) => state.junoAPI);
  React.useEffect(() => {
    dispatch({ type: UPDATE_JUNO_API_KEY_SAGA });
    setInterval(() => {
      dispatch({ type: UPDATE_JUNO_API_KEY_SAGA });
    }, 3600000);
  }, []);

  return (
    <>
      <Backdrop style={{ zIndex: theme.zIndex.drawer + 1 }} open={fetching}>
        <CircularProgress size={60} />
      </Backdrop>
      {children}
    </>
  );
}
