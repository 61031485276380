import React from 'react';
import { Box } from '@material-ui/core';
import TeamHeader from './components/header';
import TeamFormContent from './components/content';
import { makeStyles } from '@material-ui/styles';

export default function TeamForm() {
  const classes = useStyles();
  return (
    <Box>
      <TeamHeader />
      <Box className={classes.spacing} />
      <TeamFormContent />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: theme.spacing(4),
  },
}));
