import produce from 'immer';
import api from 'services/api';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  id_company: null,
  exp: null,
  type: 2,
  group: null,
  name: null,
  email: null,
  id: null,
  avatar: null,
  company_logo: null,
  pendent_attendance: null,
  idAdminUser: null,
  idStudentUser: null,
  shouldChangePassword: null
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.type = action.payload.user.type;
        draft.group = action.payload.user.group;
        draft.name = action.payload.user.name;
        draft.email = action.payload.user.email;
        draft.id = action.payload.user.id;
        draft.idAdminUser = action.payload.idAdminUser;
        draft.idStudentUser = action.payload.idStudentUser;
        draft.shouldChangePassword = action.payload.shouldChangePassword;
        console.log(`action`, action)
        break;
      }
      case '@auth/SIGN_FAILURE': {
        api.defaults.headers.Authorization = '';
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        api.defaults.headers.Authorization = '';
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        draft.exp = null;
        draft.type = 2;
        draft.group = null;
        draft.name = null;
        draft.email = null;
        draft.id = null;
        draft.avatar = null;
        draft.pendent_attendance = null;
        draft.idAdminUser = null;
        draft.idStudentUser = null;
        break;
      }
      case '@auth/COMPANY': {
        draft.loading = false;
        draft.id_company = action.payload.id_company;
        draft.exp = action.payload.exp;
        draft.company_logo = action.payload.company_logo;
        break;
      }
      case '@user/AVATAR': {
        draft.avatar = action.payload.avatar;
        break;
      }
      case '@user/PENDENT_ATTENDANCE': {
        draft.pendent_attendance = action.payload.pendent_attendance;
        break;
      }
      case '@user/change_name': {
        draft.name = action.payload;
        break;
      }
      case '@auth/CHANGE_PASSWORD_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/CHANGE_PASSWORD_ERROR': {
        draft.loading = false;
        break;
      }
      case '@auth/CHANGE_PASSWORD_SUCCESS': {
        draft.loading = false;
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.type = action.payload.user.type;
        draft.group = action.payload.user.group;
        draft.name = action.payload.user.name;
        draft.email = action.payload.user.email;
        draft.id = action.payload.user.id;
        draft.shouldChangePassword = action.payload.shouldChangePassword;
        break;
      }
      default:
    }
  });
}
