import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AuthUserNavigation from 'components/NavAuth';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { PasswordResetData } from 'services/dataService';
import { toast } from 'react-toastify';

export default () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const onSubmit = useCallback(async (data) => {
    try {
      const domain = window.location.hostname;

      await PasswordResetData.reset({
        ...data,
        domain
      });
      toast.success(t('toastify:user.toast_send_password_reset'));
      history.push('/');
    } catch (error) {
      if(error?.response.data.error === 'User do not exists.') {
        toast.error(t('toastify:user.toast_fail_send_alert_request_password'));
      } else {
        toast.error(t('toastify:user.toast_fail_send_alert'));
      }
    }
  }, []);


  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Grid item md={12} xs={12} className={classes.fieldGroup}>
            <AuthUserNavigation />
          </Grid>
          <Typography className={classes.title} variant="h2">
            {t('title:commons.title_solicitation_password')}
          </Typography>

          <TextField
            fullWidth
            autoFocus
            label={t('form:Signin.email')}
            helperText={
              (errors.email?.type === 'required' &&
                t('validation:commons.validation_required', {
                  field: t('form:Company.input_email'),
                })) ||
              (errors.email?.type === 'pattern' &&
                t('validation:commons.validation_email', {
                  field: t('form:Company.input_email'),
                }))
            }
            name="email"
            type="text"
            variant="outlined"
            inputRef={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              },
            })}
            error={!!errors.email}
          />

          <Button
            className={classes.sendButton}
            color="primary"
            fullWidth
            type="submit"
            size="large"
            variant="contained"
          >
            {t('button:commons.btn_request_password')}
          </Button>
        </form>
      </Grid>
    </div>
  );
};
