import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const ModalTitle = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <DialogTitle
      disableTypography
      className={classes.titleContainer}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const InformationModal = ({ children, title, description, handleClose, isOpen }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ModalTitle id="form-dialog-title" onClose={handleClose}>
        {title}
      </ModalTitle>

      <Divider />

      <DialogContent>
        <DialogContentText>{description || ''}</DialogContentText>
        {children}
      </DialogContent>
      
      <Divider />

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    margin: 0,
    padding: theme.spacing(2, 2, 0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default InformationModal;
