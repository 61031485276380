import React from 'react';
import {
    Box,
    Grid,
    Slide,
    TextField,
    Typography,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import { useTranslation } from 'react-i18next';

export default function AddList({ onSave, listData, selectedAttendanceData }) {
    const [attach, setAttach] = React.useState(null);
    const { t } = useTranslation(['form']);
    const classes = useStyles();

    const handleChangeFileList = (files) => {
        onSave(files)
        setAttach(files);
    };

    const fileUrl = selectedAttendanceData?.file_content?.file?.url || ""
    const fileName = selectedAttendanceData?.file_content?.file?.name.split('_')[1] || "";

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography>
                        <Interweave content={t('form:Attendance.Configuration.text2')} />
                    </Typography>
                </Grid>
                <span>
                    <strong>Arquivo atual:</strong>{' '}
                    {fileUrl ? (
                        <a href={fileUrl} target="_blank" download>
                            {fileName ? fileName : ' '}
                        </a>
                    ) : (
                        'Não existe arquivos'
                    )}
                </span>
                <DropzoneArea
                    filesLimit={1}
                    maxFileSize={5368709120}
                    onChange={(files) => handleChangeFileList(files)}
                    dropzoneText={'Arraste e Solte (para modificar o item atual ou adicionar um novo arquivo)'}
                    acceptedFiles={[
                        'image/*',
                        'media_type',
                        'application/msword',
                        '.doc',
                        '.docx',
                        '.ppt',
                        '.pptx',
                        '.csv',
                        '.zip',
                        '.rar',
                        '.odt',
                        '.ods',
                        '.odp',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                        'text/plain',
                        'application/pdf',
                    ]}
                />
            </Grid>
        </Slide>
    );
}
const useStyles = makeStyles((theme) => ({
    spacing: {
        paddingTop: theme.spacing(4),
    },
}));