import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 24),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
    }, 
  },
}));
