import 'dotenv/config';
import axios from 'axios';
import { store } from 'store';
import { signOut } from 'store/modules/auth/actions';
import { toast } from 'react-toastify';
import history from './history';
import i18n from 'i18n';

const api = axios.create({
  baseURL: window._env_.REACT_APP_API_URL,
});

// JWT Token and Time limit interceptors
api.interceptors.response.use(null, (error) => {
  const errorData = error?.response?.data;
  if (errorData?.timeLimitAccessError === true) {
    const reduxState = store.getState();
    if (reduxState.auth.token) {
      store.dispatch(signOut());
      history.push('/');
      toast.error(
        i18n.t('toastify:commons.toast_time_limit_error', {
          start_time_limit: errorData.data.initialAccessTime,
          end_time_limit: errorData.data.endAccessTime,
        })
      );
    } else {
      store.dispatch(signOut());
      toast.error(
        i18n.t('toastify:commons.toast_time_limit_error', {
          start_time_limit: errorData.data.initialAccessTime,
          end_time_limit: errorData.data.endAccessTime,
        })
      );
    }
    return new Promise(() => {});
  } else if (errorData?.tokenExpired === true) {
    store.dispatch(signOut());
    history.push('/');
    toast.info(i18n.t('toastify:commons.toast_invalid_token'));
    // return new Promise(() => {});
    error.errorCallback = true;
    return Promise.reject(error);
  } else if (errorData?.blockedUser) {
    store.dispatch(signOut());
    history.push('/');
    toast.info(i18n.t('toastify:commons.user_blocked'));
    error.errorCallback = true;
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default api;
