import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  '& div': {
    outline: 'none',
  },
  slidesShow: {
    paddingLeft: '6px',
    paddingRight: '6px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex !important',
      justifyContent: 'center',
    },
  },
  card: {
    boxShadow: '0px 8px 20px #2A2E4318',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    borderRadius: 8,
    maxWidth: '350px',
  },
  media: {
    height: '13rem',
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    objectFit: 'cover',
  },
  mediaKnowledgeTrail: {
    minHeight: '155px',
    maxHeight: '100%',
    width: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  blurredMedia: {
    filter: 'blur(2px) brightness(40%)'
  },
  cardBody: {
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '100%',
    fontSize: '1rem',
    fontWeight: 700,
    minHeight: 80,
    maxHeight: 80,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: '2%'
  },
  description: {
    padding: theme.spacing(1),
    paddingTop: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    minHeight: 98,
    maxHeight: 98,
    lineHeight: '1.19rem',
    '& span': {
      color: '#000 !important',
      fontSize: '.9rem !important',
      fontFamily: 'Montserrat !important',
      backgroundColor: 'transparent !important',
    },
    [theme.breakpoints.up('md')]: {
      '&::-webkit-scrollbar': {
        width: '3px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#d9d9d9',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: '8px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    }
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end' // retirar quando os botões de favorito voltarem
  },
  imgCheck: {
    position: 'absolute',
    width: '35%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  likeButton: {
    display: 'flex',
    width: '20%',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
  },
  favoritIcon: {
    color: theme.palette.error.main,
    minWidth: 60,
  },
  accessButton: {
    display: 'flex',
    width: '80%',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
  actionButton: {
    color: theme.palette.info.custom,
  },
}));