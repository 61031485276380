import React, { useState, useEffect, useRef } from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { FiberManualRecord } from '@material-ui/icons/'
import { AnnotationsData } from 'services/dataService/index'
import { toast } from 'react-toastify'
import DeleteIcon from '@material-ui/icons/Delete'
import moment from 'moment'


const PALETTE_COLORS = ['#2DCE98', '#F0AD4E', '#F53C56', '#D1D1D1']

export default ({ learnId, userId, course }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards', 'toastify'])
  const classes = useStyles()
  const text = useRef(null)
  const [changeColor, setChangeColor] = useState(true)
  const [annotations, setAnnotations] = useState([])
  const [annotationColor, setAnnotationColor] = useState('#D1D1D1')

  useEffect(() => {
    loadAnnotations()
  }, [learnId])

  const loadAnnotations = async () => {
    try {
      const { data } = await AnnotationsData.get(learnId, userId)
      setAnnotations(Array.isArray(data) ? data : [])
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'))
    }
  }

  const sendAnnotation = async () => {
    if (text.current.innerText.trim()) {
      try {
        await AnnotationsData.create(learnId, userId, { description: text.current.innerText, color: annotationColor })
        loadAnnotations()
        text.current.innerText = ''
        toast.success(t('toastify:commons.toast_success'))
      } catch (error) {
        toast.error(t('toastify:commons.toast_error'))
      }
    }
  }

  const remove = async id => {
    try {
      await AnnotationsData.delete(id)
      loadAnnotations()
      toast.success(t('toastify:commons.toast_delete'))
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api_remove'))
    }
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Anotações</h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.wrapper}>
          {changeColor && <div className={classes.palette}>
            {PALETTE_COLORS.map((color, i) => <FiberManualRecord key={i} className={classes.dot} style={{ color }} onClick={() => setAnnotationColor(color)} />)}
          </div>}
          <div className={classes.statusBar} style={{ backgroundColor: annotationColor }} onClick={() => setChangeColor(!changeColor)}></div>
          <div ref={text} className={clsx([classes.content, classes.shadow])} contentEditable={true} placeholder={'Faça uma anotação...'}></div>
        </div>
        <Button className={classes.button} onClick={sendAnnotation} variant="contained" color="primary">Enviar</Button>
      </div>

      <div className={classes.boxAnnotations}>
        {annotations.map((_, i) => (
          <div key={i} className={classes.wrapper}>
            <div className={classes.statusBar} style={{ backgroundColor: _.color }}></div>
            <div className={classes.content}>
              <span className={classes.name}>{moment(_.created_at).format('DD/MM/YYYY hh:mm:ss')}</span>
              <span className={classes.comment}>{_.description}</span>
            </div>
            <DeleteIcon onClick={() => remove(_.id)} className={classes.iconRemove} />
          </div>
        ))}
      </div>
    </div>
  )
}
