import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { LessonData } from 'services/dataService';

const ExamComponentToolbar = ({ numberOfAttempts, setNumberOfAttempts }) => {
  const classes = useStyles();
  const { t } = useTranslation(['button', 'toastify', 'label']);
  const history = useHistory();
  const { id_lesson } = useParams();
  const [course, setCourse] = useState('');
  const [state, setState] = useState({
    checkedA: false,
  });

  const { register } = useForm();

  const handleNumberOfAttemptsBlur = (event) => {
    if (Number(event.target.value) === 0) {
      setNumberOfAttempts(1);
    }
  };

  const handleNumberOfAttempts = (event) => {
    const value = Number(event.target.value);
    setNumberOfAttempts(value);
  };

  const handleChange = (event) => {
    if (event.target.name === 'checkedA') {
      LessonData.updateLesson(course.id, {
        ...course,
        status: event.target.checked ? 1 : 2,
      });
    }
    setState({ ...state, [event.target.name]: !state.checkedA });
  };

  const getLesson = async () => {
    const response = await LessonData.getLessonById(id_lesson);
    const testChecked = response.data.status === 1;
    setState({ ...state, checkedA: testChecked });
    setCourse(response.data);
  };

  useEffect(() => {
    getLesson();
  }, []);

  return (
    <>
      <Grid container lg={12} md={12} spacing={4}>
        <Grid item md={5} className={classes.boxBackSwitch}>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            className={classes.btnBack}
            onClick={() => history.goBack()}
          >
            <ArrowBackIos style={{ marginRight: '4px' }} />
            {t('button:commons.btn_back')}
          </Button>
        </Grid>
        <Grid item md={7} spacing={10} className={classes.wrapper}>
          <FormControl component="fieldset" className={classes.switch}>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label={t('button:Lesson.btn_public_test')}
            />
          </FormControl>
          <TextField
            fullWidth
            size="small"
            label={t('label:Exam.number_of_attempts')}
            margin="normal"
            name="numberOfAttempts"
            variant="outlined"
            type="number"
            onChange={handleNumberOfAttempts}
            onBlur={handleNumberOfAttemptsBlur}
            value={numberOfAttempts}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              required: true,
              pattern: {
                value: /^[0-9]\d*(\d+)?$/i,
              },
            })}
          />
          <Button
            color="primary"
            variant="contained"
            size="medium"
            fullWidth
            onClick={() => history.push(`/courses/${course.id_course}/config`)}
          >
            {t('button:Course.btn_config_course')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            size="medium"
            fullWidth
            onClick={() => history.push(`/course/${course.id_course}/learn`)}
          >
            {t('button:Lesson.btn_student')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  switch: {
    minWidth: 180,
    justifyContent: 'center',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    gap: '20px',
  },
  boxBackSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    },
  },
  btnBack: {
    marginLeft: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem',
    },
  },
}));

export default ExamComponentToolbar;
