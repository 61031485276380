import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import { ChartCard } from './ChartCard';
import { MonthChart } from './types/MonthChart';
import { TopUsersChart } from './types/BarChart';
import { PieChart } from './types/PieChart';
import { useHistory } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';

export function Charts({ people, courses, teams, selectedTeamId, indicators, conclusions }) {
  const { t } = useTranslation(['title']);
  const history = useHistory();
  return (
    <Grid
      container
      spacing={2}
      style={{ marginBotton: '10px', marginTop: '20px' }}
    >
      <Grid item md={12} xs={12}>
        <div
          style={{
            display: 'block',
            pageBreakBefore: 'always',
            marginTop: '1px'
          }}
        ></div>
        <ChartCard title={t('title:admin_dashboard.people_conclusion_counter')}>
          <TopUsersChart data={people ?? []} backgroundColor='#2DCE98' />
        </ChartCard>
      </Grid>
      <Grid item md={12} xs={12}>
        <div
          style={{
            display: 'block',
            pageBreakBefore: 'always',
            marginTop: '1px'
          }}
        ></div>
        <ChartCard title={t('title:admin_dashboard.courses_conclusion_counter')}>
          <TopUsersChart data={courses ?? []} backgroundColor='#176AE6' />
        </ChartCard>
      </Grid>
      <Grid item md={12} xs={12}>
        <div
          style={{
            display: 'block',
            pageBreakBefore: 'always',
            marginTop: '1px'
          }}
        ></div>
        <ChartCard title={t('title:admin_dashboard.teams_conclusion_counter')}>
          <TopUsersChart data={teams ?? []} backgroundColor='#176AE6' idTeam={selectedTeamId} type='teams'/>
        </ChartCard>
      </Grid>
    </Grid>
  );
}
