import querystring from 'querystring';
import api from '../api';

class DataServiceGroup {
  getGroups(params) {
    const url = 'groups?' + querystring.stringify(params);
    return api.get(url);
  }

  getGroupById(id) {
    return api.get(`groups/${id}`);
  }

  addGroup(data) {
    return api.post('groups', data);
  }

  updateGroup(id, data) {
    return api.put(`groups/${id}`, data);
  }

  getPermissionsById(params) {
    const url = 'permissions?' + querystring.stringify(params);
    return api.get(url);
  }

  updatePermissions(id, data) {
    return api.put(`permissions/${id}`, data);
  }
}

export default new DataServiceGroup();
