import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { modulesAction, setCourseFinishedStatus } from './actions';
import { ModuleData } from 'services/dataService/';

export function* moduleRequest({ payload }) {
  try {
    const response = yield call( () => ModuleData.getModuleByIdCourseAndUser(payload.id, payload.id_user, payload.search), null );
    const modules = response.data?.modules.map(module => {
      const lessonsPublished = module?.lessons?.filter(lesson => lesson.status === 1 )
      module.lessons = lessonsPublished;
      return module;
    })
    yield put(
      modulesAction( modules || [] )
    );
    yield put(setCourseFinishedStatus(modules || [] ));
  } catch (err) {
    toast.error('Error in modules request');
  }
}

export function* updateMenu({ payload }) {
  try {
    const response = yield call( () => ModuleData.getModuleByIdCourseAndUser(payload.id, payload.id_user, payload.search), null );
    const modules = response.data?.modules.map(module => {
      const lessonsPublished = module?.lessons?.filter(lesson => lesson.status === 1 )
      module.lessons = lessonsPublished;
      return module;
    })
    yield put(setCourseFinishedStatus(modules || [] ));
  } catch (err) {
    toast.error('Error in modules request');
  }
}

export default all([
  takeLatest('@learn/MODULES_REQUEST', moduleRequest),
  takeLatest('@learn/UPDATE_COURSE', updateMenu),
]);
