import React from 'react';
import { Card, CardActions, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const CardSimple = ({ data }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardActions className={classes.space}>
        <Grid container spacing={1} className={classes.cardContainer}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h6" color="textSecondary">
              {data.title}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.counter}>
            <Typography variant="h4" component="h2">
              {data.value}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  space: {
    padding: 16,
  },
  cardContainer: {
    alignItems: 'center',
    flexFlow: 'row',
  },
  counter: {
    textAlign: 'end',
  },
}));

export default CardSimple;
