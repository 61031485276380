import { Grid } from '@material-ui/core';
import { Card } from 'components/Toolbar/';

import { AccessTime, CastForEducationOutlined, DescriptionOutlined, DesktopWindowsOutlined, SchoolOutlined } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CourseToolbarManage, CourseListManage } from 'views/Course/components';
import { useStyles } from './styles';
import { CourseData } from 'services/dataService';

export default () => {
  const classes = useStyles();
  const { t } = useTranslation(['cards']);
  const [cardNumbers, setCardNumbers] = React.useState({
    availableCourses: 0,
    sharedCourses: 0,
    instructors: 0,
    contentHours: 0,
  });

  const cardsData = [
    {
      title: t('cards:course.card_courses_available'),
      number: cardNumbers.availableCourses,
      icon: DescriptionOutlined,
      iconStyle: classes.greenIcon,
    },
    // {
    //   title: t('cards:course.card_courses_shared'),
    //   number: cardNumbers.sharedCourses,
    //   icon: CastForEducationOutlined,
    //   iconStyle: classes.blackIcon,
    // },
    // {
    //   title: t('cards:course.card_courses_contenters'),
    //   number: cardNumbers.instructors,
    //   icon: SchoolOutlined,
    //   iconStyle: classes.blueIcon,
    // },
    {
      title: t('cards:course.card_courses_hours_content'),
      number: cardNumbers.contentHours,
      icon: AccessTime,
      iconStyle: classes.redIcon,
    },
  ];

  const fillCardsData = async () => {
    const coursesCount = await CourseData.getCountCoursesManagement();
    setCardNumbers(coursesCount.data);
  };

  React.useEffect(() => {
    fillCardsData();
  }, []);

  return (
    <div className={classes.root}>
      <CourseToolbarManage />

      <Grid container spacing={2} style={{ marginTop: 20, marginBottom: 20 }}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} md={6} lg={6} key={`${card.title}_${index}`}>
            <Card {...card} />
          </Grid>
        ))}
      </Grid>

      <div>
        <CourseListManage />
      </div>
    </div>
  );
};
