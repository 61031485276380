import React, { useEffect, useState } from 'react';
import {
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItem,
  List,
  Checkbox,
} from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export default function CheckboxList({ header, list, check }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);
  const [search] = useState('');
  const [listItens, setListItens] = useState(list);

  const { t } = useTranslation(['form']);

  const handleToggle = (value, index) => () => {
    const currentIndex = checked.indexOf(index);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(index);
      check(value, true);
    } else {
      newChecked.splice(currentIndex, 1);
      check(value, false);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    const newChecked = [];
    list.map((item, index) => {
      if (item.is_checked) {
        newChecked.push(index);
      }
    });
    setChecked(newChecked);
  }, []);

  const searchList = (value) => {
    const listFiltered = list.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });

    value ? setListItens(listFiltered) : setListItens(list);
  };

  return (
    <List
      subheader={<ListSubheader>{header}</ListSubheader>}
      className={classes.root}
    >
      <SearchBar
        value={search}
        onChange={(newValue) => searchList(newValue)}
        placeholder={t('form:Classroom.placeholder')}
        onCancelSearch={() => setListItens(list)}
      />

      {listItens &&
        listItens.map((item, index) => (
          <ListItem
            key={Object.values(item)[0]}
            role={undefined}
            dense
            button
            onClick={handleToggle(Object.values(item)[0], index)}
          >
            <ListItemIcon>
              <Checkbox
                color="primary"
                edge="start"
                checked={checked.indexOf(index) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': Object.values(item)[0] }}
              />
            </ListItemIcon>
            <ListItemText
              id={Object.values(item)[0]}
              primary={Object.values(item)[1]}
            />
          </ListItem>
        ))}
    </List>
  );
}
