import React from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowBackIos, ImportExport } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import { Poll, AddCircle } from '@material-ui/icons';

export default ({ className, ...rest }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button']);

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1} direction="row">
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <div className={classes.row}>
            <span className={classes.spacer} />
            <Link to="/subcategories/create">
              <Button color="primary" variant="contained" size="medium"
                className={(classes.topButtonSpacing, classes.topButton)}>
                 <span className='notranslate'>{t('button:commons.btn_add')}</span>
                <AddCircle />
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
