import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import MaterialTable from 'material-table';
import { format, parseISO } from 'date-fns';
import { UserContractData } from 'services/dataService';
import { statusUserContracts } from 'constants/status';
import CircleIcon from '@material-ui/icons/Brightness1';
import moment from 'moment';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Typography,
  FormControl,
  Paper,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Input,
  Switch,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { ContractData } from 'services/dataService';

export default ({ className, isModalInsert, idContract, ...rest }) => {
  const [status, setStatus] = useState(true);
  const [createdAt, setCreatedAt] = useState();
  const [version, setVersion] = useState();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState();
  const tableRef = useRef();

  const { id } = useParams();

  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const [use_term, setUseTerm] = useState();
  const [privacy_policy, setPrivacyPolicy] = useState();
  //const [transl, setTransl] = useState('');
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  useEffect(() => {

    setLoading(true);
    (async () => {

      if (use_term) {
        const blocksFromHtml = htmlToDraft(use_term);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setUseTerm(editorState);
      }
      if (privacy_policy) {
        const blocksFromHtml = htmlToDraft(privacy_policy);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setPrivacyPolicy(editorState);
      }
    })();

    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (id && !isModalInsert) {
      const fetchData = async () => {
        try {
          const response = await ContractData.getContractById(id);
          const { use_term, status, privacy_policy, version, id_user_create, id_contract, createdAt } = response.data;

          setVersion(version)
          setCreatedAt(createdAt)
          if (use_term) {
            const blocksFromHtml = htmlToDraft(use_term);
            const { contentBlocks, entityMap } = blocksFromHtml;

            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            setUseTerm(editorState);
          }
          if (privacy_policy) {
            const blocksFromHtml = htmlToDraft(privacy_policy);
            const { contentBlocks, entityMap } = blocksFromHtml;

            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            setPrivacyPolicy(editorState);
          }
          setStatus(status);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          history.push('/settings/lgpd');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };
      fetchData();
    } else {
      const fetchData = async () => {
        try {

          const contractExists = await ContractData.getContracts({
            page: 1,
            limit: 1,
            status: true
          });
          if (contractExists.data.totalCount > 0) {
            setLoading(false);
            history.push('/settings/lgpd');
            toast.error(t('toastify:commons.toast_duplicated_item'));
          } else {

            const response = await ContractData.usertermdefault();            
            if (response.data) {
              const { use_term, status, privacy_policy } = response.data;

              if (use_term) {
                const blocksFromHtml = htmlToDraft(use_term);
                const { contentBlocks, entityMap } = blocksFromHtml;

                const contentState = ContentState.createFromBlockArray(
                  contentBlocks,
                  entityMap
                );
                const editorState = EditorState.createWithContent(contentState);
                setUseTerm(editorState);
              }
              if (privacy_policy) {
                const blocksFromHtml = htmlToDraft(privacy_policy);
                const { contentBlocks, entityMap } = blocksFromHtml;

                const contentState = ContentState.createFromBlockArray(
                  contentBlocks,
                  entityMap
                );
                const editorState = EditorState.createWithContent(contentState);
                setPrivacyPolicy(editorState);
              }
              setStatus(status);
              setLoading(false);
            }
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          history.push('/settings/lgpd');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };
      fetchData();
    }
  }, [id, t]);

  const onSubmit = async (data) => {
    try {

      const use_TermText = use_term
        ? draftToHtml(convertToRaw(use_term.getCurrentContent()))
        : '';

      const privacy_policyText = privacy_policy
        ? draftToHtml(convertToRaw(privacy_policy.getCurrentContent()))
        : '';
      data.use_term = use_TermText;
      data.privacy_policy = privacy_policyText;
      data.status = 1
      if (id) {
        await ContractData.updateContract(idContract ? idContract : id, data);
        toast.success(t('toastify:commons.toast_update'));
      } else {
        data.version = 1
        await ContractData.addContract(data);
        toast.success(t('toastify:commons.toast_success'));
      }
      if (!isModalInsert) history.push('/settings/lgpd');
      if (isModalInsert) isModalInsert(false);
    } catch (error) {
      console.log(error)
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        const toastMessage = id ? 'toast_update_error' : 'toast_error'
        toast.error(t(`toastify:commons.${toastMessage}`));
      }
    }
  };

  const handleStatusChange = () => {
    setStatus((prev) => !prev);
  };

  const titlePage = id
    ? t('title:Contracts.title_edit')
    : t('title:Contracts.title_create');

  if (!loading)
    return (
      <>
        <Paper className={classes.root} style={{ marginBottom: 12 }} >
          <form
            {...rest}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardHeader title={titlePage} />
            <Divider />
            <Grid container style={{ padding: 12 }} >
              <Grid item md={12} xs={12} className={classes.columnGrid}>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  {t('form:Contracts.Setting.term_contract')}
                </Typography>
                <Editor
                  readOnly={!status}
                  editorStyle={{
                    border: '1px solid #C0C0C0',
                    borderRadius: '4px',
                    height: '17rem',
                  }}
                  editorState={use_term}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={(vl) => setUseTerm(vl)}
                  toolbar={{
                    options: ['inline', 'list', 'link', 'remove', 'history'],
                  }}
                />
              </Grid>
            </Grid>

            <Grid style={{ padding: 12 }}>
              <Grid item xs={12} className={classes.columnGrid}>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  {t('form:Contracts.Setting.privacy_policy_contract')}
                </Typography>
                <Editor
                  readOnly={!status}
                  editorStyle={{
                    border: '1px solid #C0C0C0',
                    borderRadius: '4px',
                    height: '17rem',
                  }}
                  editorState={privacy_policy}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={(vl) => setPrivacyPolicy(vl)}
                  toolbar={{
                    options: ['inline', 'list', 'link', 'remove', 'history'],
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ padding: 12 }}>
              <Grid item md={4} xs={12} className={classes.columnGrid} >
                <InputLabel id="status">
                  {t('form:commons.input_status')}
                </InputLabel>
                <Switch
                  id="status"
                  checked={status}
                  readOnly="true"
                  //onChange={handleStatusChange}
                  color="primary"
                  inputRef={register}
                  name="status"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
              <Grid item md={8} xs={12} className={classes.columnGrid}>
                <InputLabel id="version">
                  {t('form:commons.version')}  {version}
                </InputLabel>
              </Grid>

            </Grid>
            <Divider />
            {status && (
              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  {t('button:commons.btn_save')}
                  <Save />
                </Button>
              </CardActions>
            )}
          </form>

        </Paper>
        {id && (
          <MaterialTable style={{ padding: 12 }}
            title={`${t('tables:user_solicitations.title')}`}
            options={{
              search: false
            }}
            columns={[
              {
                title: `${t('tables:commons.name_column')}`,
                field: 'name',
                render: (rowData) => rowData.user.name
              },
              {
                title: `${t('tables:commons.status_column')}`,
                field: 'status',
                render: (rowData) => (
                  <div style={{ display: 'flex' }}>
                    <>
                      <CircleIcon
                        style={statusUserContracts[rowData.status]?.style}
                        fontSize="small"
                      />
                      <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                        {t(statusUserContracts[rowData.status]?.title)}
                      </span>
                    </>
                  </div>
                ),
              },
              {
                title: `${t('tables:commons.email_column')}`,
                field: 'email',
                render: (rowData) => rowData.user.email
              },
              {
                title: `${t('tables:commons.date')}`,
                field: 'updatedAt',
                render: (rowData) => moment(rowData.updatedAt).format('DD/MM/YYYY HH:mm')
              },
              {
                title: 'IP',
                field: 'ip',
                render: (rowData) => rowData.ip
              },

            ]}

            data={async (query) => {
              try {
                const response = await UserContractData.getUsersContract(version, {
                  page: query.page + 1,
                  limit: query.pageSize,
                });
                return {
                  data: response.data.contractuser,
                  page: response.data.currentPage - 1,
                  totalCount: response.data.totalCount,
                };
              } catch (err) {
                toast.error(t('toastify:commons.toast_error_api'));
                return { data: [] };
              }
            }}

          />
        )}

      </>
    );
  return <></>
};
