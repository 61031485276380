import api from '../api';

class DataServiceComments {
  addComment(data) {
    return api.post('comments', data);
  }

  addReplyComment(data) {
    return api.post('comments/replies', data);
  }

  getCommentsAndRepliesByLessonId(lessonId) {
    const url = `/comments/lesson/${lessonId}`;
    return api.get(url);
  }
}

export default new DataServiceComments();
