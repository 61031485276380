import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AddCircleOutline,
  CheckCircleOutline,
  Clear,
  DeleteOutline,
  FilterNone,
  Wallpaper,
  OndemandVideo
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import theme from 'theme';
import Interweave from 'interweave';
import { useTranslation } from 'react-i18next';
import { FilesData, VimeoData } from 'services/dataService';
import parse from 'html-react-parser'

export class QuestionTypeEnum {
  static NONE_QUESTION = 0;
  static CHECKBOX_QUESTION = 1;
  static DISCURSIVE_QUESTION = 2;
  static UPLOAD_QUESTION = 3;
  static RADIO_QUESTION = 4;
}

const ProfileType = {
  ADMIN: 1,
  STUDENT: 2,
  TEACHER: 3,
};

const RadioButtonEvalution = ({
  exam,
  index,
  onTypeChange,
  onCopy,
  onDelete,
  error,
  isReviewMode = false
}) => {
  const { t } = useTranslation(['button']);
  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);
  const [file, setFile] = React.useState();
  const [fileUrl, setFileUrl] = React.useState("");

  const [video, setVideo] = React.useState("");
  const [videoUrl, setVideoUrl] = React.useState("");
  const [videoHTML, setVideoHTML] = React.useState("");

  const [checkedIndex, setCheckedIndex] = React.useState(-1);
  const { type: userType } = useSelector((state) => state.auth);

  //useeffect to get the file
  React.useEffect(() => {
    if (exam.id_file && exam.id_file !== "" && !fileUrl) {
      getFile(exam.id_file).then((response) => {
        setFile(response);
        setFileUrl(response.url);
      });
    } else if (exam.url_vimeo && exam.url_vimeo !== "" && !videoUrl) {
      VimeoData.getVimeo(exam.url_vimeo).then((response) => {
        setVideoHTML(response.data.html);
      })
    }
  });

  const getFile = async (idFile) => {
    try {
      const { data } = await FilesData.getFile(idFile);
      return data;
    } catch (e) {
      console.error(e);
    }
  }

  const onDataChange = (field, value) => {
    exam[field] = value;
    setRefresh(!refresh);
    if (onTypeChange && field === 'type_exam') {
      exam.lesson_content_exam_questions = exam?.lesson_content_exam_questions?.map(
        (ex, i) => {
          const examQuestion = { ...ex };
          examQuestion['is_correct'] = false;
          return {
            ...examQuestion,
          };
        }
      );
      setRefresh(!refresh);
      onTypeChange();
    }
  };

  const onAnswerChange = (field, value, answerIndex) => {
    exam.lesson_content_exam_questions = exam.lesson_content_exam_questions?.map(
      (ex, i) => {
        const examQuestion = { ...ex };
        if (i === answerIndex) {
          examQuestion[field] = value;
        }
        if (field === 'is_correct') {
          examQuestion[field] = answerIndex === i;
        }
        return {
          ...examQuestion,
        };
      }
    );
    setRefresh(!refresh);
  };

  const addAnswer = () => {
    const a = exam?.lesson_content_exam_questions?.push({
      description: '',
      is_correct: false,
    });
    setRefresh(!refresh);
  };

  const removeAnswer = (i) => {
    exam.lesson_content_exam_questions = exam?.lesson_content_exam_questions?.filter(
      (_, answerIndex) => {
        return answerIndex !== i;
      }
    );
    setRefresh(!refresh);
  };

  React.useState(() => {
    exam.lesson_content_exam_questions.forEach((element, i) => {
      if (element?.is_correct) {
        setCheckedIndex(i);
      }
    });
  }, []);

  return (
    <Box className='notranslate'>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={userType === ProfileType.ADMIN ? 7 : 12}
            className={classes.questionField}
          >
            {userType === ProfileType.ADMIN ? (
              <>
                <Typography component="span">{`${index + 1}. `}</Typography>
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  value={exam?.question}
                  placeholder="Pergunta"
                  error={error && !exam?.question?.length}
                  helperText={error && !exam?.question?.length && 'Obrigatório'}
                  onChange={(e) => onDataChange('question', e.target.value)}
                />
              </>
            ) : (
              <Typography className={classes.questionText}>
                <span className={classes.numberOfQuestion}>{isReviewMode ? exam?.order + 1 : index + 1}.</span>
                <Interweave
                  content={` ${exam?.question.replace(
                    /\n/g,
                    '<br/>'
                  )}`}
                />
              </Typography>
            )}
          </Grid>
          {userType === ProfileType.ADMIN && (
            <Grid item sm={6} md={2} className="mb-2">
              <Grid item xs={12} style={{ margin: '5px' }}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id={"contained-question-file-radio" + index}
                  name="file_question"
                  accept="image/png, image/jpeg"
                  onChange={(event) => {
                    setFile(event.target.files[0]);
                    setFileUrl(URL.createObjectURL(event.target.files[0]));
                    exam.image = event.target.files[0];
                  }}
                />
                <label htmlFor={"contained-question-file-radio" + index}>
                  <Button fullWidth variant="contained" component="span">
                    <Wallpaper />
                    &nbsp;
                    {t('button:commons.btn_image')}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12} style={{ margin: '5px' }}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id={"contained-question-video-radio" + index}
                  name="file_question"
                  accept="video/mp4"
                  onChange={(event) => {
                    setVideo(event.target.files[0]);
                    setVideoUrl(URL.createObjectURL(event.target.files[0]));
                    exam.video = event.target.files[0];
                  }}
                />
                <label htmlFor={"contained-question-video-radio" + index}>
                  <Button fullWidth variant="contained" component="span">
                    <OndemandVideo />
                    &nbsp;
                    {t('button:commons.btn_video')}
                  </Button>
                </label>
              </Grid>
            </Grid>
          )}
          {/* {userType === ProfileType.ADMIN && <Grid item xs={2} md={1} />} */}
          {userType === ProfileType.ADMIN && (
            <Grid item sm={6} md={3}>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="asdasd"
                value={exam?.type_exam}
                onChange={(e) => onDataChange('type_exam', e.target.value)}
              >
                <MenuItem value={QuestionTypeEnum.RADIO_QUESTION}>
                  {t('button:Exams.btn_single_selection')}
                </MenuItem>
                <MenuItem value={QuestionTypeEnum.CHECKBOX_QUESTION}>
                  {t('button:Exams.btn_multiple_selection')}
                </MenuItem>
              </Select>
            </Grid>
          )}
        </Grid>
        <Typography className={classes.marginTop} />
        <Grid className={classes.imageContainer} container spacing={2}>
          <img hidden={!fileUrl} className={classes.image} src={fileUrl} />
          <video
            hidden={!videoUrl}
            src={videoUrl}
            controls
            preload="true"
            style={{ width: '100%', margin: 20, marginBottom: 40 }}
          />
        </Grid>

        {
          videoHTML !== "" &&
          <Grid className={classes.videoContainer} container spacing={2}>
            {videoHTML ? parse(videoHTML) : undefined}
          </Grid>
        }

        <Grid container
          style={userType === ProfileType.STUDENT ? {
            justifyContent: 'flex-end'
          } : {}}
        >
          <RadioGroup
            style={{ width: '100%', flexWrap: 'nowrap' }}
            name="questions"
          >
            {exam?.lesson_content_exam_questions?.map((questions, i) => (
              <Grid item xs={12} md={12} key={i}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={userType === ProfileType.ADMIN ? 10 : 12}
                    >
                      {userType === ProfileType.ADMIN ? (
                        <>
                          <FormControlLabel
                            checked={checkedIndex === i}
                            onChange={(e, checked) => {
                              setCheckedIndex(i);
                              onAnswerChange('is_correct', checked, i);
                            }}
                            control={<Radio />}
                          />

                          <TextField
                            style={{ width: '90%' }}
                            value={questions?.description}
                            defaultValue={questions?.description}
                            error={error && !questions?.description?.length}
                            helperText={
                              error &&
                              !questions?.description?.length &&
                              'Obrigatório'
                            }
                            onChange={(e) =>
                              onAnswerChange('description', e.target.value, i)
                            }
                          />
                        </>
                      ) : (
                        <label className={classes.labelAnswer}>
                          <FormControlLabel
                            disabled={isReviewMode ? true : false}
                            checked={isReviewMode ? questions.marked : checkedIndex === i}
                            onChange={(e, checked) => {
                              setCheckedIndex(i);
                              onAnswerChange('is_correct', checked, i);
                            }}
                            control={<Radio />}
                          />

                          <Box
                            style={
                              userType === ProfileType.STUDENT
                              && {
                                alignSelf: 'center',
                                width: '100%',
                              }
                            }
                          >
                            {/* <Typography className={classes.answersText}>{questions?.description}</Typography> */}
                            <Typography
                              className={`${classes.answersText} ${isReviewMode && questions.marked ? classes.markedText : ''
                                }`}
                            >
                              {questions?.description}
                            </Typography>
                          </Box>
                        </label>
                      )}
                    </Grid>

                    {userType === ProfileType.ADMIN && (
                      <Grid item xs={1}>
                        {checkedIndex === i && (
                          <IconButton disabled>
                            <CheckCircleOutline
                              style={{ color: theme.palette.success.main }}
                            />
                          </IconButton>
                        )}
                      </Grid>
                    )}

                    {userType === ProfileType.ADMIN && (
                      <Grid item xs={1}>
                        <IconButton onClick={() => removeAnswer(i)}>
                          <Clear />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            ))}
          </RadioGroup>
          {userType === ProfileType.STUDENT && (
            <Typography className={classes.weightText}>Peso: {Math.round(exam?.weight)}</Typography>
          )}
        </Grid>

        {userType === ProfileType.ADMIN && (
          <>
            <Typography className={classes.marginTop} />
            <Divider />
            <Typography className={classes.marginTop} />
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={6} md={2}>
                <TextField
                  type="number"
                  value={exam?.weight}
                  onChange={(e) => {
                    if (Number.parseFloat(e.target.value) < 1) {
                      return;
                    }
                    else if (Number.parseFloat(e.target.value) > 2) {
                      return;
                    }
                    onDataChange('weight', e.target.value);
                  }}
                  placeholder="peso"
                />
              </Grid>

              <Grid container item xs={6} md={3} alignItems='center'>
                <CheckCircleOutline
                  style={{ color: theme.palette.success.main, marginRight: '8px' }}
                />
                Resposta correta
              </Grid>

              <Grid item xs={12} md={1} />

              <Grid item xs={12} md={6}>
                <Grid container spacing={1} justifyContent='flex-end' alignItems='center'>
                  <Grid item xs={12} sm={6} md={2}>
                    <IconButton onClick={() => addAnswer()}>
                      <AddCircleOutline
                        title="Adicionar nova alternativa"
                        color="secondary"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} lg={4}>
                    <Box>
                      <Grid container justify="flex-end" alignItems='center' spacing={1}>
                        <Grid item xs={6} md={8}>
                          Obrigatório
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Switch
                            checked={exam?.required}
                            onChange={(e, checked) =>
                              onDataChange('required', checked)
                            }
                            color="secondary"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Divider color="text" orientation="vertical" variant="middle" />
                  <Grid item xs={5} md={2}>
                    <IconButton>
                      <FilterNone />
                    </IconButton>
                  </Grid>
                  <Grid item xs={5} md={2}>
                    <IconButton
                      onClick={() => {
                        if (onDelete) onDelete(index);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>

  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    justifyContent: 'center',
    justifyItems: 'center',
    boxShadow: '0px 5px 50px #2A2E4320',
    borderRadius: 8,

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    }
  },
  marginTop: {
    paddingTop: theme.spacing(3),
  },
  questionField: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'baseline',
    gap: '10px',
    marginBottom: 8,
    '& > div': {
      width: '100%',
    },
  },
  numberOfQuestion: {
    fontWeight: 600
  },
  questionText: {
    fontSize: 18,
    lineHeight: 1.3,

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: 1.5,
    }
  },
  eachAnswer: {
    // marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    }
  },
  markedText: {
    color: 'red', 
  },
  labelAnswer: {
    display: 'flex',
    cursor: 'pointer',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderRadius: 6
    },

    '& > label': {
      marginRight: 8
    }
  },
  answersText: {
    fontSize: 16,

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    }
  },
  weightText: {
    fontSize: 13,
    color: theme.palette.default.light
  },
  imageContainer: {
    justifyContent: 'center',
  },

  videoContainer: {
    margin: '16px 0',
    overflow: 'hidden',
    position: 'relative',
    height: '0',
    maxWidth: '100%',
    paddingBottom: '56.25%',

    '& > iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: 'none'
    }
  },

  image: {
    maxHeight: '500px',
    maxWidth: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
  }
}));

export default RadioButtonEvalution;
