import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CertificateDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation(['views']);

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={2} className={classes.mediaContainer}>
          <img className={classes.media} src="/images/CertificateIcon.png" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className={classes.stepContainer}>
            <Box style={{ marginTop: 0 }} className={classes.textContainer}>
              <Typography
                className={classes.number}
                component="span"
              >
                {'1. '}
              </Typography>
              <Typography
                className={classes.text}
                component="span"
                color="textSecondary"
              >
                <Interweave
                  content={t('views:CourseRegistration.certificate_step_1')}
                />
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography
                className={classes.number}
                component="span"
              >
                {'2. '}
              </Typography>
              <Typography
                className={classes.text}
                component="span"
                color="textSecondary"
              >
                <Interweave
                  content={t('views:CourseRegistration.certificate_step_2')}
                />
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography
                className={classes.number}
                component="span"
              >
                {'3. '}
              </Typography>
              <Typography
                className={classes.text}
                component="span"
                color="textSecondary"
              >
                <Interweave
                  content={t('views:CourseRegistration.certificate_step_3')}
                />
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography
                className={classes.number}
                component="span"
              >
                {'4. '}
              </Typography>
              <Typography
                className={classes.text}
                component="span"
                color="textSecondary"
              >
                <Interweave
                  content={t('views:CourseRegistration.certificate_step_4')}
                />
              </Typography>
            </Box>
            <Box className={classes.textContainer}>
              <Typography
                className={classes.number}
                component="span"
              >
                {'5. '}
              </Typography>
              <Typography
                className={classes.text}
                component="span"
                color="textSecondary"
              >
                <Interweave
                  content={t('views:CourseRegistration.certificate_step_5')}
                />
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  number: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#069EDB'
  },
  text: {
    fontSize: 17,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  media: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    maxWidth: 164,
    maxHeight: 164,
    boxShadow: ('0px 20px 40px 0px #2a2e4329')
  },
  stepContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      marginTop: theme.spacing(4),
    },
  },
  textContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  },
}));

export default CertificateDetails;
