import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},

  rowStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },

  greenIcon: {
    backgroundColor: '#2DCE98 !important',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C !important',
  },

  blueIcon: {
    backgroundColor: '#176AE6 !important',
  },

  redIcon: {
    backgroundColor: '#F53C56 !important',
  },

  yellowIcon: {
    backgroundColor: '#f0c24e !important',
  },

  quickAccessContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },

  topButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'space-around',
      width: '100%',
      marginTop: theme.spacing(1)
    },
  },

  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      width: '100%',
    },
  },

  overrideWrap: {
    flexWrap: 'unset',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  }
}));
