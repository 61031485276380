import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CourseData } from 'services/dataService';
import InputMask from 'react-input-mask';
import PlatformAvaliblePayments from './component/PlatformAvaliblePayments';
import { useTranslation } from 'react-i18next';

const PaymentDetails = () => {
  const classes = useStyles();
  const { theme } = useSelector((state) => state.company);
  const { id: company_course_id } = useParams();
  const [course, setCourse] = React.useState();
  const { t } = useTranslation(['cards']);

  const getCourseDetails = async () => {
    const { data: company_course } = await CourseData.getCompanyCourseById(
      company_course_id
    );

    const { data: course } = await CourseData.getCourseById(
      company_course?.id_course
    );
    setCourse(course?.courses[0]);
  };

  const formatValue = (value) => {
    const decimalValue = `${
      ((Number.parseFloat(value) * 100) / 100).toFixed(2) || '0'
    }`;

    const splitString = decimalValue?.split('.');

    return `R$ ${
      splitString[0]?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.') || '00'
    },${splitString[1] || '00'}`;
  };

  const formatInstallment = (value) => {
    return `12 x de R$ ${
      ((Number.parseFloat(value || 0) * 100) / 100 / 12)?.toFixed(2) || '00,00'
    }* no cartão`;
  };

  React.useEffect(() => {
    getCourseDetails();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.card}>
        <Box
          style={{ backgroundColor: theme?.background_color }}
          className={classes.cardHeader}
        >
          <img className={classes.logo} src={theme?.logo?.url} width={150} />
        </Box>
        {/* <Box className={classes.safeBuy}> Compra 100% segura</Box> */}
        <Box className={classes.bodySpacing}>
          <Typography className={classes.title}>
          {t('cards:buyCourse.course_information')}
          </Typography>
        </Box>
        <Box className={classes.bodySpacing}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <img
                className={classes.courseLogo}
                src={course?.card?.url}
                width={180}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Box className={classes.pricesSpacing}>
                <Grid item xs={12}>
                  <Typography className={classes.courseValue}>
                    {formatValue(course?.price)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={classes.courseInstallments}
                    color="textSecondary"
                  >
                    {formatInstallment(course?.price)}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                  <Box className={classes.topSpacing}>
                    <Rating readOnly value={2.5} />
                  </Box>
                </Grid> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.bodySpacing}>
          <Typography className={classes.courseName}>
            {course?.course?.name}
          </Typography>
        </Box>
        {/* <Divider /> */}
        <Box className={classes.bodySpacing}>
          <Grid container spacing={2} justify="flex-start">
            <Grid item xs={1} />
            <Grid item xs={6}>
              <InputMask mask="9999-9999-9999-99" maskChar="">
                {(inputProps) => <TextField {...inputProps} label="Cupom" />}
              </InputMask>
            </Grid>
            <Grid item xs={5} align="bottom">
              <Box height="100%" position="relative">
                <Button
                  style={{ position: 'absolute', bottom: 0, width: 100 }}
                  variant="contained"
                  color="primary"
                >
                  Ok
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <PlatformAvaliblePayments />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  topSpacing: {
    paddingTop: theme.spacing(1),
  },
  card: {
    borderRadius: 10,
    boxShadow: '0px 5px 20px 0px #2A2E4329',
    maxWidth: '500px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset'
    }
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    minHeight: 50
  },
  logo: { 
    objectFit: 'contain',
    marginRight: theme.spacing(3)
  },
  safeBuy: {
    background: '#2674E8 0% 0% no-repeat padding-box',
    border: '1px solid #002484',
    opacity: 1,
    width: 277,
    height: 50,
  },
  bodySpacing: {
    padding: theme.spacing(2),
  },
  title: {
    ...theme.typography.h3,
    fontSize: 25
  },
  pricesSpacing: {
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '8px',
    }
  },
  courseValue: {
    ...theme.typography.h3,
    fontSize: 40,
    [theme.breakpoints.down(1420)]: {
      fontSize: 33,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 27,
    }
  },
  courseLogo: {
    borderRadius: 10,
    width: '100%',
    maxWidth: 252,
    maxHeight: 200,
  },
  courseInstallments: {
    fontSize: '0.9rem',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(1420)]: {
      marginTop: theme.spacing(0),
      fontSize: '0.75rem',
    }
  },
  courseName: {
    ...theme.typography.h4,
    paddingTop: 0,
    paddingBottom: '1rem',
    borderBottom: '1px solid #D0D0D0',
  },
}));

export default PaymentDetails;
