import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    marginTop:30,
    minHeight:0,
    marginLeft: '9%',
    [theme.breakpoints.up(1920)]: {
      width: '100%',
      marginLeft: '0%',
    },
  },
  title:{
    color: '#000000',
    fontSize: 24,
    fontWeight: 'normal',
  },
  boxAnnotations: {
    maxHeight: 625,
    overflowY: 'auto',
    paddingRight: '.4rem',
    paddingBottom: '.1rem',
    [theme.breakpoints.down( 1601 )]: {
      maxHeight: 560,
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 400,
    },

    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#696969',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#c9c9c9',
      borderRadius: '8px',
   }
  },
  wrapper: {
    display:'flex',
    marginTop:10,
    position:"relative",
  },
  statusBar: {
    width: 7,
    height: 65,
    borderRadius: 12,
    marginRight:5,
    cursor:'pointer',
  },
  content:{
    width: '90%',
    // maxWidth:775,
    borderRadius: 5,
    backgroundColor:"#fff",
    color: '#9E9E9E',
    fontSize:15,
    padding:10,
    boxShadow: '0px 0px 3px #8B8B8B',
  },
  shadow:{
    boxShadow: '0px 0px 3px #176AE6',
    border: '1px solid #176AE6',
  },
  comment:{
    color: '#000000',
    fontSize:15,
  },
  name:{
    color: '#000000',
    fontSize:15,
    display:'block',
    fontWeight:600,
  },
  button:{
    marginTop:10,
    marginRight: '7%',
    marginBottom:20,
    alignSelf: 'flex-end',
  },
  dot:{
    fontSize: 15,
    cursor:'pointer',
  },
  palette:{
    display:'flex',
    flexDirection:'column',
    width: 16,
    height: 60,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 6px #9F9F9F',
    borderRadius: 12,
    position:'absolute',
    left:-20,
  },
  iconRemove:{
    position:'absolute',
    right:5,
    top:3,
    color:'#d3d3d3',
    cursor:'pointer',
    fontSize:20,
    '&:hover':{
      color:'#3d3d3d',
    }
  }
}))
