import { Box, Menu, TextField, Typography } from '@material-ui/core';
import ColorPicker, { useColor } from 'react-color-palette';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const CustomColorPicker = ({
  name,
  register,
  title,
  defaultValue,
  onColorChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [color, setColor] = useColor(
    'hex',
    defaultValue ? defaultValue : '#000000'
  );
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeColor = (color) => {
    setColor(color);

    if (onColorChange) {
      onColorChange(color);
    }
  };
  return (
    <>
      <Box>
        <Typography>{title}</Typography>
        <Box className={classes.colorPickerBox}>
          <Box
            className={classes.color}
            style={{ backgroundColor: color.hex }}
            onClick={handleClick}
          ></Box>
          <Box className={classes.colorField}>
            <TextField
              name={name}
              value={color?.hex}
              defaultValue={defaultValue}
              inputRef={register}
              disabled
            />
          </Box>
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ style: { padding: 0 } }}
        PaperProps={{ style: { borderRadius: 12 } }}
      >
        <ColorPicker
          width="250"
          height="100"
          color={color}
          onChange={changeColor}
          dark
        />
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  colorPickerBox: {
    width: '100%',
    display: 'flex',
  },
  color: {
    width: '30%',
    maxWidth: 45,
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    marginRight: theme.spacing(1),
  },
  colorField: {
    width: '70%',
  },
}));

export default CustomColorPicker;
