import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  
  content: {
    marginTop: theme.spacing(2),
  },

  greenIcon: {
    backgroundColor: '#2DCE98',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C',
  },

  blueIcon: {
    backgroundColor: '#176AE6',
  },

  redIcon: {
    backgroundColor: '#F53C56',
  },
}));
