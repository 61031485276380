import api from '../api';
import querystring from 'querystring';

class DataServiceCertificate {
  addCertificate(id_company, data) {
    return api.post(`certificates/${id_company}`, data);
  }

  getCertificate(id_company) {
    return api.get(`certificates/${id_company}`);
  }

  getCourseCertificate(id) {
    return api.get(`/certificates/course/${id}`);
  }

  addCourseCertificate(id, data) {
    return api.post(`/certificates/course/${id}`, data);
  }

  updateCourseCertificate(id, data) {
    return api.put(`/certificates/course/${id}`, data);
  }

  updateCertificate(id_company, data) {
    return api.put(`certificates/${id_company}`, data);
  }

  deleteCertificate(id) {
    return api.delete(`certificates/${id}`);
  }

  getCertificateCourse(id) {
    return api.get(`certificates/course-certificate/${id}`);
  }

  getCertificateCourseAndUser(id, id_user) {
    return api.get(`certificates/course-certificate/${id}/${id_user}`);
  }

  getPublicUserData(id_user) {
    return api.get(`/certificates/course-certificate/user/${id_user}`);
  }

  download(id, id_user, certificate_create_date) {
    const user = { id_user, certificate_create_date };
    return api.get(`/certificates/pdf/${id}?${querystring.stringify(user)}`);
  }
}

export default new DataServiceCertificate();
