import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import api from 'services/api';
import { EnrollmentData } from 'services/dataService';
import { toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';


/**
 * Create course enrollment for student
 * @param {bool} open show the dialog
 * @param {() => void} setOpen handler for open
 * @param {({id:string, name:string}) => any} callback form submit callback
 */

const NewEnrollment = ({ open, setOpen, callback }) => {
  const { t } = useTranslation(['form', 'toastify']);
  const [courseName, setCourseName] = React.useState(null);
  const [courseDueDate, setCourseDueDate] = React.useState(null);
  const [hasDueDate, setHasDueDate] = React.useState(false);
  const [courseList, setCouseList] = React.useState([]);
  const [error, setError] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (!courseName) {
      setError(true);
      return;
    } else {
      callback({course:courseName, courseDueDate: hasDueDate ? courseDueDate : null});
      handleClose();
      setCourseName(null);
      setCourseDueDate(null);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const response = await EnrollmentData.getCompanyCourseCombo();
        const courses = [];
        response.data.courses.forEach((course) => {
          courses.push({
            id: course?.id,
            name: course?.course?.name,
          });
        });
        setCouseList(courses);
      } catch (error) {
        toast.error(t('toastify:Enrollments.failed_fetch'));
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('form:Enrollments.create_title')}
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Typography variant={'span'}>
            {t('form:Enrollments.create_description')}
          </Typography>
          <Autocomplete
            fullWidth
            clearOnEscape
            id="combo-box-demo"
            options={courseList}
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => {
              setCourseName(value);
              setError(false);
            }}
            onInput={() => {
              setError(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={error}
                margin="normal"
                label={t('form:Courses.input_course')}
                variant="outlined"
                helperText={error ? t('form:commons.no_options') : ''}
              />
            )}
          />
          {/* <Typography variant={'span'}>
            {t('form:Enrollments.create_due_date')}
          </Typography>

          <Checkbox
            checked={hasDueDate}
            onChange={ e => setHasDueDate(e.target.checked) }
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          /> */}

          {hasDueDate ? <TextField
            type="date"
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={e => setCourseDueDate(e.target.value) }
            value={courseDueDate}
          />: null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('form:Actions.cancel')}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t('form:Enrollments.create_enrollment')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default NewEnrollment;
