import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider, Box, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import SelectTable from 'views/Teams/components/form/components/content/components/select-table';

const UserTrails = ({ open, setOpen, trails, callback }) => {
  const { t } = useTranslation(['form', 'toastify']);
  const [filterTrails, setFilterTrails] = React.useState(null);
  const [selectedTrails, setSelectedTrails] = React.useState([]);

  const columnsTrails = [
    {
      label: t('tables:Trail.name_column'),
      field: 'name',
      align: 'center',
      style: { whiteSpace: 'nowrap' },
    },
    { 
      label: t('tables:Trail.description_column'), 
      field: 'description',
      style: { fontSize: '12px' }
    },
  ];
  
  React.useEffect(() => {
    setSelectedTrails(trails);
  }, []);

  const updateSelectedTrails = (ids) => {
    return selectedTrails.map((t) => ({ ...t, selected: ids.includes(t.id) ? !t.selected : t.selected }))
  }

  const handleOnSelectTrail = ({ type, ids }) => {
    setSelectedTrails(updateSelectedTrails(ids));
  };

  const handleFilterTrails = (event, value) => {
    setFilterTrails(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    callback(selectedTrails);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('form:Enrollments.create_title')}
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Box>
            <Autocomplete
              fullWidth
              clearOnEscape
              value={filterTrails}
              options={selectedTrails}
              isOptionEqualToValue={(opt, value) => opt.id === value.id}
              getOptionLabel={(option) => option?.name ? option.name : ''}
              onChange={handleFilterTrails}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  label={t('Buscar trilhas')}
                  variant="outlined"
                />
              )}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) => {
                  const name = option.name || '';
                  const description = option.description || '';
                  return (
                    (name && name.toLowerCase().includes(inputValue.toLowerCase()))) ||
                    (description && description.toLowerCase().includes(inputValue.toLowerCase()))

                }).slice(0, 7)
              }
              getOptionSelected={(option, value) => option.id === value.id}
            />
          </Box>
          <Box>
            <SelectTable
              cols={columnsTrails}
              rows={selectedTrails.filter(
                (trail) => !filterTrails || trail.id === filterTrails.id
              )}
              onSelect={handleOnSelectTrail}
              length={selectedTrails.length}
              totalSelected={selectedTrails.filter((t) => t.selected).length}
              rowsPerPage={20}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('form:Actions.cancel')}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t('form:Enrollments.create_enrollment')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default UserTrails;

