import querystring from 'querystring';
import api from '../api';

class DataServiceLessonsContent {
  addLessonContent(data) {
    return api.post('lesson-contents', data);
  }

  getLessonContent(params) {
    const url = 'lesson-contents?' + querystring.stringify(params);
    return api.get(url);
  }

  getLessonContentById(id) {
    return api.get(`lesson-contents/${id}`);
  }

  updateLessonContent(id, data) {
    return api.put(`lesson-contents/${id}`, data);
  }

  deleteLessonContent(id) {
    return api.delete(`lesson-contents/${id}`);
  }

  // Lesson Content Exams
  getCourseExamsWithScore(id_user, id_company_course) {
    return api.get(
      `/lesson-content-exams/student/${id_user}/course/${id_company_course}`
    );
  }

  // Lesson Content Exams Attempts
  checkNewExamAttempt(id_user, id_lesson_content) {
    return api.get(
      `/lesson-content-exams-attempts/student/${id_user}/lesson-content/${id_lesson_content}/check-retry`
    );
  }

  releaseNewExamAttempt(id_user, id_lesson_content) {
    return api.post(
      `/lesson-content-exams-attempts/student/${id_user}/lesson-content/${id_lesson_content}/new-attempt`
    );
  }

  releaseFinishedExamAttempt(id_user, id_lesson_content) {
    return api.post(
      `/lesson-content-exams-attempts/student/${id_user}/lesson-content/${id_lesson_content}/finished-attempt`
    );
  }

  getCourseExams(id_company_course, id_user) {
    return api.get(
      `/finish-lesson-content-exams/course/${id_company_course}/user/${id_user}`
    );
  }  

  releaseFinishedExam(id_user, id_lesson_content, id_course, score){    
    return api.post(
      `finish-lesson-content-exams/student/${id_user}/lesson-content/${id_lesson_content}/course/${id_course}/score/${score}`
    );
  }

  releaseFinishedCourse(id_user, id_company_course){    
    return api.post(
      `finish-lesson-content-course/student/${id_user}/course/${id_company_course}`
    );
  }

}

export default new DataServiceLessonsContent();
