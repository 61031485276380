import {
  Button,
  Card,
  Avatar,
  Typography,
  Grid,
  Stepper,
  Step,
  StepButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Search, AddCircle, Save, ArrowForwardIos, ArrowBackIos, PlaylistAddCheck } from '@material-ui/icons';
import { localizationTable, options } from 'constants/table';
import { useParams, useLocation } from 'react-router-dom';
import { AttendanceData, FilesData } from 'services/dataService';
import { format, parseISO } from 'date-fns';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import CreateAttendance from './CreateAttendance'
import AddPeople from './AddPeople';
import AddList from './AddList';
import { useHistory } from 'react-router-dom';

export default () => {
  const classes = useStyles();
  const tableRef = useRef();
  const [avatar, setAvatar] = useState('');
  const [completed, setCompleted] = React.useState({});
  const [listAttendance, setListAttendance] = useState();
  const [companyCourse, setCompanyCourse] = useState();
  const [countList, setCountList] = useState();
  const [courseName, setCourseName] = useState();
  const [attendanceData, setAttendanceData] = useState({});
  const [route, setRoute] = useState('');
  const [peopleData, setPeopleData] = useState([]);
  const [listData, setListData] = useState([]);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState(null);
  const [selectedAttendanceData, setSelectedAttendanceData] = useState(null);
  const { id_company } = useSelector((state) => state.auth);
  const [fileUrl, setFileUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUploadList, setOpenUploadList] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const history = useHistory();

  const { id } = useParams();
  const location = useLocation();

  const course_name = location.state?.name_course;

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
    'message',
  ]);

  const handleSaveAttendance = (data) => {
    setAttendanceData(data);
  };

  const handleSavePeople = (data) => {
    setPeopleData(data);
  };

  const handleSaveList = (data) => {
    setListData(data);
  };

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const components = [
    {
      component: (
        <CreateAttendance
          onSave={handleSaveAttendance}
          attendanceData={attendanceData}
          selectedAttendanceData={selectedAttendanceData}
        />
      ),
      title: 'Dados Treinamento',
    },
    {
      component: (
        <AddPeople
          onSave={handleSavePeople}
          peopleData={peopleData}
          selectedAttendanceData={selectedAttendanceData}
        />
      ),
      title: 'Adicionar pessoas'
    },
    {
      component: (
        <AddList
          onSave={handleSaveList}
          listData={listData}
          selectedAttendanceData={selectedAttendanceData}
        />
      ),
      title: 'Upload lista de presença'
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const fetchData = async () => {
    try {
      const response = await AttendanceData.getAttendanceByList(id);
      setListAttendance(response.data.result)
      setCompanyCourse(response.data.id_company_course)
      setCountList(response.data.result.length)
      setCourseName(response.data.name_course)
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    const allData = {
      id_company: id_company,
      id_company_course: companyCourse,
      attendance: attendanceData,
      people: peopleData,
    };

    try {
      let response;

      if (selectedAttendanceId) {
        response = await AttendanceData.updateAttendanceList(selectedAttendanceId, allData);
      } else {
        response = await AttendanceData.createAttendanceList(allData);
      }

      const savedAttendanceId = response?.data?.id || selectedAttendanceId;

      if (listData && listData.length > 0) {
        const file = listData[0];

        const responseAttendanceList = await FilesData.addFile(
          file,
          `Attendance/CompanyCourse=${companyCourse}/Training/${savedAttendanceId}`
        );

        setFileUrl(responseAttendanceList.data.url)
        await AttendanceData.updateAttendanceList(savedAttendanceId, {
          ...allData,
          id_file: responseAttendanceList.data.id || null,
        });
      }

      handleClose();
      fetchData();
      toast.success(t('toastify:commons.toast_success'));
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const fetchAttendanceData = async (id) => {
    try {
      const response = await AttendanceData.getAttendanceDataById(id);
      setSelectedAttendanceData(response.data);
      setAttendanceData(response.data.restData);
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const handleEditAttendanceList = async (id) => {
    setSelectedAttendanceId(id);
    await fetchAttendanceData(id);
    setOpen(true);
  };

  const handleDownloadAttendanceList = async (id) => {
    try {
      const response = await AttendanceData.getAttendanceDataById(id);
      const fileUrl = response.data.file_content?.file?.url;
  
      if (fileUrl) {
        const res = await fetch(fileUrl);
        if (!res.ok) throw new Error('Network response was not ok');
  
        const blob = await res.blob(); 
        const url = window.URL.createObjectURL(blob); 
  
        const a = document.createElement('a');
        a.href = url;
        a.download = `${response.data.file_content?.file?.name.split('_')[1]}`; 
        document.body.appendChild(a);
        a.click();
        a.remove();
  
        window.URL.revokeObjectURL(url); 
      } else {
        toast.error(t('toastify:commons.toast_error_file_not_found'));
      }
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const clearState = () => {
    setAttendanceData({});
    setSelectedAttendanceData(null);
    setSelectedAttendanceId(null);
  };

  return (
    <div className={classes.root} >
      <div>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          className={classes.btnBack}
          onClick={() => history.goBack()}
        >
          <ArrowBackIos style={{ marginRight: '5px' }} />
          {t('button:commons.btn_back')}
        </Button>
        <Dialog
          fullWidth
          maxWidth="md"
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <Stepper activeStep={activeStep} alternativeLabel>
              {components.map((component, index) => (
                <Step key={component.title} completed={completed[index]}>
                  <StepButton onClick={handleStep(index)}>{component.title}</StepButton>
                </Step>
              ))}
            </Stepper>
          </DialogTitle>
          <DialogContent>
            {components[activeStep].component}
          </DialogContent>
          <DialogActions>
            {activeStep === 0 && (
              <>
                <Button onClick={handleClose} color="primary">
                  {t('form:Actions.cancel')}
                </Button>
                <Button
                  size="large"
                  onClick={handleNextStep}
                  startIcon={<ArrowForwardIos />}
                  color="primary"
                  variant="outlined"
                >
                  {t('button:commons.btn_foward')}
                </Button>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Button
                  size="large"
                  onClick={handlePrevStep}
                  startIcon={<ArrowBackIos />}
                  color="primary"
                  variant="outlined"
                >
                  {t('button:commons.btn_back')}
                </Button>
                <Button
                  size="large"
                  onClick={handleNextStep}
                  startIcon={<ArrowForwardIos />}
                  color="primary"
                  variant="outlined"
                >
                  {t('button:commons.btn_foward')}
                </Button>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Button
                  size="large"
                  onClick={handlePrevStep}
                  startIcon={<ArrowBackIos />}
                  color="primary"
                  variant="outlined"
                >
                  {t('button:commons.btn_back')}
                </Button>
                <Button
                  size="large"
                  startIcon={<Save />}
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                >
                  {t('button:commons.btn_save')}
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </div>
      <Card className={classes.cardContainer}>
        <Grid container className={classes.gridContainer}>
          <Grid item className={classes.avatarContainer}>
            <div>
              <Avatar
                variant="rounded"
                className={classes.avatarImg}
                alt="Person"
              >
                <PlaylistAddCheck className={classes.avatarImg} />
              </Avatar>
            </div>
            <div className={classes.userInfo}>
              <Grid container className={classes.userInfoName}>
                <Typography variant="h4" component="h4">
                  {courseName}
                </Typography>
              </Grid>
              <Typography variant="body2">
                {`Treinamentos disponíveis: ${countList}`}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.buttonsContainer}>
            <Button
              color="primary" variant="contained"
              style={{ padding: '12px' }}
              onClick={() => {
                clearState();
                handleOpen();
              }}
            >
              <AddCircle style={{ marginRight: '6px' }} />
              {t('Atribuir presença')}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <MaterialTable
        title={`${t('tables:course.title')}`}
        tableRef={tableRef}
        columns={[
          { title: `${t('tables:attendance.name_column')}`, field: 'name' },
          {
            title: `${t('tables:attendance.createdAt_column_register')}`,
            field: 'start_at',
            render: (rowData) => rowData.start_at === null ? 'n/a' : format(parseISO(rowData.start_at), 'dd/MM/yyyy HH:mm'),
          },
          {
            title: `${t('tables:attendance.finishedAt_column_register')}`,
            field: 'finished_at',
            render: (rowData) => format(parseISO(rowData.finished_at), 'dd/MM/yyyy HH:mm'),
          },
          {
            title: `${t('tables:attendance.students_count_column')}`,
            field: 'qtd',
            render: (rowData) => (rowData.qtd),
          },
          {
            title: `${t('tables:attendance.workload')}`,
            field: 'workload',
            render: (rowData) => (rowData.workload),
          },
          {
            title: `${t('tables:attendance.instructor')}`,
            field: 'instructor',
            render: (rowData) => (rowData.instructor),
          },
        ]}
        data={listAttendance}
        actions={[
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.edit_action')}`,
            onClick: (event, rowData) => handleEditAttendanceList(rowData.id_attendance),
            iconProps: { color: 'primary' },
          },
          {
            icon: 'download',
            tooltip: 'Download da lista',
            onClick: (event, rowData) => handleDownloadAttendanceList(rowData.id_attendance),
            iconProps: { color: 'primary' },
          },
          // {
          //   icon: 'delete',
          //   tooltip: `${t('tables:commons.actions.delete_action')}`,
          //   onClick: (event, rowData) => handleModalOpen(rowData),
          //   iconProps: { color: 'error' },
          // },
        ]}
        localization={localizationTable(t)}
        options={options}
      />
    </div>
  );
};
