import React, { useEffect, useState, Fragment } from 'react';
import { useStyles } from './styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHeader from './components/TableHeader/index';
import Row from './components/Row/index';
import { CustomFieldsData } from 'services/dataService/index';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default () => {
  const { t } = useTranslation(['toastify', 'views']);
  const classes = useStyles();
  const [fields, setFields] = useState([]);

  useEffect(() => {
    loadFields();
  }, []);

  const loadFields = async () => {
    try {
      const { data } = await CustomFieldsData.getCustomFields();
      setFields(JSON.parse(data?.custom_fields ?? '[]'));
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const onChangeValue = (newValue) => {
    const index = fields.findIndex((_) => _.id === newValue.id);
    if (index != -1) {
      const nArr = [...fields];
      nArr[index] = { ...newValue };
      setFields(nArr);
    }
  };

  const onDeleteValue = async (row) => {
    const arrayOfFields = [...fields];
    const filteredArrayOfFields = arrayOfFields.filter(
      (element) => element.id !== row
    );
    
    try {
      await CustomFieldsData.deleteCustomFields(filteredArrayOfFields);
      loadFields();
      toast.success(t('toastify:commons.toast_delete'));
    } catch (error) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  return (
    <>
      <h1 className={classes.pageTitle}>
        {t('views:customFields.page_title')}
      </h1>
      <div className={classes.table}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 20,
            }}
          >
            <h2 className={classes.tableTitle}>
              {t('views:customFields.edit_fields')}
            </h2>
            <TableHeader
              fields={fields}
              setFields={setFields}
              loadFields={loadFields}
            />
          </div>

          <Table aria-label="collapsible table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell align="left" className={classes.tableTh}>
                  #
                </TableCell>
                <TableCell align="left" className={classes.tableTh}>
                  {t('views:customFields.field')}
                </TableCell>
                <TableCell align="left" className={classes.tableTh}>
                  {t('views:customFields.visualization')}
                </TableCell>
                <TableCell align="left" className={classes.tableTh}>
                  {t('views:customFields.config')}
                </TableCell>
                <TableCell align="center" className={classes.tableTh}>
                  {t('views:customFields.edit')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {fields.map((row, ind) => (
                <Row
                  key={row.id}
                  opened={!!row.open}
                  row={row}
                  onChangeValue={onChangeValue}
                  onDeleteValue={onDeleteValue}
                  hasBgColor={ind % 2 == 0}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
