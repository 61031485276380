const { default: styled } = require("styled-components");

const SmallLoaderContainer = styled.div`
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: #fff4;
    backdrop-filter: blur(1px);
    top: 0;
    left: 0;
    z-index: 9999;
`

const SmallLoader = styled.div`
    border: 4px solid #39C1EB;
    border-radius: 100px;
    border-top: 8px solid #fff0;
    width: 60px;
    height: 60px;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    animation: spin .45s linear infinite;
`

export { SmallLoaderContainer, SmallLoader }
