import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'space-between',
  },
  addTeacherlabel: {
    fontWeight: 'bold',
  },
  infoInstructor: {
    color: theme.palette.text.secondary,
  },
  topSpacing: {
    paddingTop: theme.spacing(2),
  },
  contentCenter: {
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  switchItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('md')]: {
    contentCenter: {
      paddingTop: '0'
    }
  }
}));
