import querystring from 'querystring';
import api from '../api';

class FeaturedCoursesData {
  getCoursesFields() {
    return api.get('/featured-courses/get-fields');
  }

  getTrailsFields() {
    return api.get('/featured-courses/get-trails-fields');
  }

  getFeaturedCourses(type) {
    return api.get(`/featured-courses/${type}`);
  }

  createFeaturedCourse(type, company_courses) {
    return api.post(`/featured-courses/${type}`, { company_courses });
  }

  updateFeaturedCourse(type, company_courses) {
    return api.put(`/featured-courses/${type}`, { company_courses });
  }
}

export class FeatureCoursesType {
  static internal = 'internal';
  static external = 'external';
}

export default new FeaturedCoursesData();
