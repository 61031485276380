import querystring from 'querystring';
import api from '../api';

class DataServiceCategory {
  addCategory(data) {
    return api.post('categories', data);
  }

  getCategories(params) {
    const url = 'categories?' + querystring.stringify(params);
    return api.get(url);
  }

  getCategoryById(id) {
    return api.get(`categories/${id}`);
  }

  updateCategory(id, data) {
    return api.put(`categories/${id}`, data);
  }
}

export default new DataServiceCategory();
