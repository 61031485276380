import React, { useState } from 'react';
import { useStyles } from './styles';
import { Paper, Button, Grid, Divider, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CourseData, FilesData } from 'services/dataService';
import ButtonsAction from 'components/ButtonsAction';

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const [courseData, setCourseData] = useState({});
  const { id, company_course_id } = useParams();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);
  const [banner, setBanner] = useState();
  const [card, setCard] = useState();
  const [bannerUrl, setBannerUrl] = useState();
  const [cardUrl, setCardUrl] = useState();
  const [loading, setLoading] = useState();

  const onSubmit = async () => {
    try {
      const formValue = {
        ...courseData,
      };

      if (banner) {
        const responseBanner = await FilesData.addFile(
          banner,
          `Course/CompanyCourse=${company_course_id}/Banner`
        );
        formValue.id_file_banner = responseBanner.data.id;
      }

      if (card) {
        const responseCard = await FilesData.addFile(
          card,
          `Course/CompanyCourse=${company_course_id}/Card`
        );
        formValue.id_file_card = responseCard.data.id;
      }

      await CourseData.updateCourse(id, formValue);
      toast.success(t('toastify:commons.toast_update'));
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_update_error'));
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await CourseData.getCourses({ id_course: id });
        const companyCourse = response.data.courses[0];

        if (companyCourse.banner) {
          setBannerUrl(companyCourse.banner.url);
        }

        if (companyCourse.card) {
          setCardUrl(companyCourse.card.url);
        }

        setCourseData({
          name: companyCourse.course.name,
          keywords: companyCourse.course.keywords,
          content: companyCourse.course.content,
          description: companyCourse.course.description,
          id_category: companyCourse.id_category,
          id_subcategory: companyCourse.id_subcategory,
          score: companyCourse.course.score,
          workload: companyCourse.course.workload,
          signable: companyCourse.signable,
          require_classes_approval: companyCourse.require_classes_approval,
          require_classes_completion: companyCourse.require_classes_completion,
        });
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    })();
    setLoading(false);
  }, []);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.containerImageBanner}>
            {banner ? (
              <img
                src={URL.createObjectURL(banner)}
              />
            ) : !banner && bannerUrl ? (
              <img src={bannerUrl} />
            ) : (
              <></>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <input
            style={{ display: 'none' }}
            type="file"
            id="contained-banner-file"
            name="file_banner"
            accept="image/png, image/jpeg"
            onChange={(event) => {
              setBanner(event.target.files[0]);
            }}
          />
          <label 
          style={{display: 'none'}}
          htmlFor="contained-banner-file">
            <Button variant="contained" color="primary" component="span">
              {t('form:Courses.Setting.banner_course')}
            </Button>
          </label>
        </Grid>

        <Grid 
        style={{display: 'none'}}
        item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.containerImageCard}>
            {card ? (
              <img src={URL.createObjectURL(card)}></img>
            ) : !card && cardUrl ? (
              <img src={cardUrl}></img>
            ) : (
              <></>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <input
            style={{ display: 'none' }}
            type="file"
            id="contained-card-file"
            name="file_card"
            accept="image/png, image/jpeg"
            onChange={(event) => {
              setCard(event.target.files[0]);
            }}
          />
          <label htmlFor="contained-card-file">
            <Button variant="contained" color="primary" component="span">
              {t('form:Courses.Setting.card_course')}
            </Button>
          </label>
        </Grid>

        <Grid item xs={12}>
          <ButtonsAction
            isUpdate
            cancelBtn={{
              handleCancelClick: () => history.push(`/courses/${id}/lessons`),
              text: t('button:FeaturedCourses.cancel'),
            }}
            saveBtn={{
              text: t('button:FeaturedCourses.update'),
              color: 'secondary',
              disabled: !(banner || card),
              handleSaveClick: onSubmit
            }}
          />

        </Grid>
      </Grid>
    </Paper>
  );
};
