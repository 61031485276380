import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './styles';
import ButtonsAction from 'components/ButtonsAction';
import { useTranslation } from 'react-i18next';

const SelectFieldsDialog = ({ fields, open, setOpen, onSave, onReset }) => {
  const { t } = useTranslation(['modal', 'button']);
  const classes = useStyles();
  const [selectList, setSelectList] = useState([]);

  useEffect(() => {
    setSelectList(fields || []);
  }, []);

  const handleClose = () => {
    if (setOpen) setOpen(false);
  };

  const onSaveSelection = () => {
    if (onSave)
      onSave(
        selectList.map((field) => {
          return {
            ...field,
            selected: !field?.hidden,
            hidden: undefined,
            type: field?.type || undefined,
          };
        })
      );

    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      scroll="body"
    >
      <DialogTitle disableTypography className={classes.modalHeader}>
        <Typography variant="h4" style={{ color: 'white' }}>
          {t('modal:commons.personal_fields_title')}
        </Typography>
        <Button onClick={handleClose}>
          <Close />
        </Button>
      </DialogTitle>

      <DialogContent>
        <Typography className={classes.instruction}>
          {t('modal:commons.personal_fields_instructions')}
        </Typography>
        <Grid container direction="row">
          {selectList.map(({ title, hidden }) => (
            <Grid item xs={12} md={3}>
              <label className={classes.labelCheckTitle}>
                <Grid container>
                  <Grid item xs={2}>
                    <Checkbox
                      checked={!hidden}
                      onChange={() => {
                        setSelectList(
                          selectList?.map((table_field) => {
                            return {
                              title: table_field?.title,
                              field: table_field?.field,
                              hidden:
                                title === table_field?.title
                                  ? !hidden
                                  : table_field?.hidden,
                              type: table_field?.type || undefined,
                            };
                          })
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Box className={classes.labelBox}>{title}</Box>
                  </Grid>
                </Grid>
              </label>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions className={classes.boxButtons}>
        <ButtonsAction
          cancelBtn={{
            text: t('button:commons.btn_cancel'),
            handleCancelClick: handleClose,
            color: 'primary',
          }}
          saveBtn={{
            text: t('button:commons.btn_save'),
            handleSaveClick: onSaveSelection,
            type: 'button',
            color: 'primary',
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SelectFieldsDialog;
