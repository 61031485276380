import React, { forwardRef } from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ isOpen, closeModal, component }) {
  const handleClose = () => {
    closeModal(false);
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {component}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
