import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { toast } from 'react-toastify';
import { useStyles } from './styles';
import AttendanceData from 'services/dataService/AttendanceData';
import { PhoneMaskCustom } from 'components/PhoneMaskCustom';
import { putPendentAttendance } from 'store/modules/auth/actions';



export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState();
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);
  const classes = useStyles();

  useEffect(() => {
    if (id) {
      const fetchUserData = async () => {
        try {
          const response = await AttendanceData.getAttendanceById(id);

          const { name, email, phone, subject, message } = response.data;

          if (message) {
            const blocksFromHtml = htmlToDraft(message);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            setMessage(editorState);
          }

          response.data.status = 2;
          await AttendanceData.updateAttendance(id, response.data);
          const responsePendentAttendance = await AttendanceData.countAttendance();
          dispatch(
            putPendentAttendance(responsePendentAttendance.data.pendentAttendance)
          );
          setValue([{ name }, { email }, { phone }, { subject }]);
        } catch (err) {
          history.push('/attendance');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };
      fetchUserData();
      setLoading(false);
    }
  }, [id]);

  const titlePage = t('title:Attendance.title_create');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form {...rest} className={clsx(classes.root, className)}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label={t('form:Attendance.input_name')}
                  name="name"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label={t('form:Attendance.input_subject')}
                  name="subject"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label={t('form:Attendance.input_email')}
                  name="email"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    },
                  })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  disabled
                  fullWidth
                  label={t('form:Attendance.input_cellphone')}
                  name="phone"
                  type="text"
                  variant="outlined"
                  InputProps={{
                    inputComponent: PhoneMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.columnGrid}>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  {t('form:Attendance.input_message')}
                </Typography>
                <Editor
                  readOnly={true}
                  editorStyle={{
                    border: '1px solid #C0C0C0',
                    borderRadius: '4px',
                    height: '17rem',
                  }}
                  editorState={message}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  toolbarHidden={true}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
        </form>
      )}
    </Card>
  );
};
