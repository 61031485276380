import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { signInRequest } from 'store/modules/auth/actions';
import { Link as RouterLink } from 'react-router-dom';

import AuthUserNavigation from 'components/NavAuth';

import { Visibility, VisibilityOff } from '@material-ui/icons/';

import {
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const { id_company } = useSelector((state) => state.auth);

  const { t } = useTranslation([
    'title',
    'form',
    'message',
    'button',
    'validation',
  ]);
  const [visible, setVisible] = useState({
    showPassword: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = useCallback(
    ({ email, password }) => {
      const domain = window.location.hostname;
      try {
        let login = email.toLowerCase().trim().replace(/[\/\s]/g, '')

        dispatch(signInRequest(email, password, domain));
      } catch (err) {}
    },
    [dispatch]
  );

  const handleClickShowPassword = () => {
    setVisible({ ...visible, showPassword: !visible.showPassword });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <AuthUserNavigation index={0} />

        <div className={classes.grid}>
          <div className={classes.titleBox}>
            <Typography className={classes.title} variant="h1">
              {t('title:commons.title_sigin')}
            </Typography>
            <Typography className={classes.subtitle} variant="h6">
              {t('title:commons.subtitle_sigin')}
            </Typography>
          </div>

          <div className={classes.formBox}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <TextField
                fullWidth
                autoFocus
                label={t('form:Signin.email_cpf')}
                helperText={
                  (errors.email?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Company.input_email_cpf'),
                    })) ||
                  (errors.email?.type === 'pattern' &&
                    t('validation:commons.validation_email', {
                      field: t('form:Company.input_email_cpf'),
                    }))
                }
                name="email"
                //type="email"
                variant="outlined"
                inputRef={register({
                  required: true,
                  pattern: id_company !== '2a48df24-a717-4934-9513-56c036d2f4d3' && {                    
                    value:  /(^\d{11}$)|(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$)/i,
                  },
                })}
                error={!!errors.email}
                className={classes.inputEmail}
              />
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.password}
              >
                <InputLabel
                  className={classes.input}
                  htmlFor="outlined-adornment-password"
                >
                  {t('form:Signin.password')}
                </InputLabel>
                <OutlinedInput
                  className={classes.input}
                  id="outlined-adornment-password"
                  type={visible.showPassword ? 'text' : 'password'}
                  name="password"
                  inputRef={register({ required: true, minLength: 6 })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {visible.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={t('form:Signin.password').length * 8.5}
                />
                <FormHelperText>
                  {(errors.password?.type === 'required' &&
                    t('validation:commons.validation_required', {})) ||
                    (errors.password?.type === 'minLength' &&
                      t('validation:commons.validation_min_pass', {
                        minLength: 6,
                      }))}
                </FormHelperText>
              </FormControl>
              <Button
                className={classes.sendButton}
                color="primary"
                fullWidth
                type="submit"
                size="large"
                variant="contained"
              >
                {t('button:commons.btn_signin')}
              </Button>
              <Grid item md={12} xs={12} className={classes.footer}>
                <p>
                  <small>
                    <RouterLink to="/solicitation-password">
                      {t('button:commons.btn_forgot_password')}
                    </RouterLink>
                  </small>
                </p>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
