import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, CameraAltOutlined, Videocam } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilesData } from 'services/dataService';
import CustomHelper from '../CustomHelperText';
const PlatformCustomization = ({ company_platform, form }) => {
  const [showCtaButton, setShowCtaButton] = React.useState(true);
  const [showPlataform, setshowPlataform] = React.useState(true);
  const [showPlataformText, setshowPlataformText] = React.useState(true);
  const [showPlataformDescription, setshowPlataformDescription] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const { register, watch } = form;
  const values = watch('company_platform');
  const [selected, setSelected] = React.useState([]);

  const { t } = useTranslation(['views', 'button']);
  const classes = useStyles();
  const fileInput = React.useRef();
  const saveFile = async (file) => {
    if (!file) {
      return;
    }
    const { data } = await FilesData.addFile(
      file,
      'company_customization_platform'
    );
    setFileUrl(data?.url);
    setFile(data?.id);
  };
  const initFile = () => {
    setFileUrl(company_platform?.video?.url);
    setFile(company_platform?.id_file_video);
  };

  React.useEffect(() => {
    initFile();
  }, []);

  React.useEffect(
    () => {
      setShowCtaButton(company_platform?.ctaButtons?.length ? true : false);
    },
    company_platform,
    values
  );

  return (
    <Box>
      <Box style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.header} color="textSecondary">
          {t('views:CompanyCustomization.platform')}
        </Typography>
        <Box style={{ width: '10%' }}>
          <Switch
            checked={showPlataform}
            onChange={(_, checked) => {
              setshowPlataform(checked);
            }}
          />
        </Box>
      </Box>
      <Box className={classes.contentRoot}>
      {showPlataform && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box>
              <Box>
                <TextField
                  fullWidth
                  name="company_platform.title"
                  defaultValue={company_platform?.title}
                  variant="outlined"
                  label={t('views:CompanyCustomization.title')}
                  inputRef={register}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.title_description')}
                />
              </Box>
              <Typography className={classes.spacingTop} />
              <Box>
                <CustomColorPicker
                  name="company_platform.title_color"
                  register={register}
                  title={t(
                    'views:CompanyCustomization.title_color_description'
                  )}
                  defaultValue={company_platform?.title_color}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.title_description')}
                />
              </Box>
              <Typography className={classes.spacingTop2x} />
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  name="company_platform.description"
                  defaultValue={company_platform?.description}
                  variant="outlined"
                  label={t('views:CompanyCustomization.description')}
                  inputRef={register}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.description_helper')}
                />
                <Typography className={classes.spacingTop} />
                <Box>
                  <CustomColorPicker
                    name="company_platform.description_color"
                    register={register}
                    title={t(
                      'views:CompanyCustomization.description_color_description'
                    )}
                    defaultValue={company_platform?.description_color}
                  />
                  <CustomHelper
                    text={t('views:CompanyCustomization.description_helper')}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.videoBox}>
              <video
                className={classes.videoMedia}
                src={fileUrl}
                controls
                preload="true"
              />
            </Box>
            <Typography className={classes.spacingTop} />

            <Box className={classes.videoBox}>
              <input
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
                id="contained-logo-file"
                accept="video/*"
                onChange={(event) => {
                  saveFile(event.target.files[0]);
                }}
              />
              <TextField
                name="company_platform.id_file_video"
                value={file}
                inputRef={register}
                style={{ display: 'none' }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  fileInput.current.click();
                }}
              >
                <Videocam fontSize="small" />
                {t('button:CompanyCustomization.change_video')}
              </Button>
              <CustomHelper
                text={t('views:CompanyCustomization.video_helper')}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      </Box>
      <Box className={classes.topSpacing}>
        <Box style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.header} color="textSecondary">
            {t('views:CompanyCustomization.cta_buttons')}
          </Typography>
        </Box>
        <Box style={{ width: '10%' }}>
          <Switch
            // name="company_platform_validation.buttons"
            checked={showCtaButton}
            // inputRef={register}
            onChange={(_, checked) => {
              setShowCtaButton(checked);
            }}
          />
        </Box>
      </Box>
      {showCtaButton && (
        <Grid container>
          <Grid item xs={12} md={10}>
            <Box>
              {selected.length > 0 && (
                <Box>
                  <Button
                    color="primary"
                    onClick={() => {
                      company_platform.ctaButtons = values?.ctaButtons?.filter(
                        (btn, index) => !selected.includes(index)
                      );
                      setSelected([]);
                      setRefresh(!refresh);
                    }}
                  >
                    {t('button:CompanyCustomization.remove_selected')}
                  </Button>
                </Box>
              )}
              <Grid container spacing={2}>
                {company_platform?.ctaButtons?.map((_, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className={classes.ctaButtonSpacing}
                  >
                    <Box className={selected.includes(index) && classes.select}>
                      <Checkbox
                        checked={selected.includes(index)}
                        onChange={(e, checked) => {
                          if (checked) {
                            selected.push(index);
                          } else {
                            setSelected(selected.filter((i) => i !== index));
                          }
                          setRefresh(!refresh);
                        }}
                      />
                    </Box>
                    <Box
                      className={clsx(
                        selected.includes(index) && classes.selected,
                        classes.colorTopSpacing
                      )}
                    >
                      <Box>
                        <TextField
                          variant="outlined"
                          key={index}
                          name={`company_platform.ctaButtons[${index}].text`}
                          defaultValue={_?.text}
                          label={t(
                            'views:CompanyCustomization.cta_button_text'
                          )}
                          inputRef={register()}
                        />
                        <CustomHelper
                          text={t('views:CompanyCustomization.cta_button_text')}
                        />
                      </Box>
                      <Box>
                        <Grid
                          container
                          spacing={2}
                          className={classes.colorTopSpacing}
                        >
                          <Grid item xs={12} md={4}>
                            <CustomColorPicker
                              name={`company_platform.ctaButtons[${index}].text_color`}
                              register={register}
                              title={t(
                                'views:CompanyCustomization.cta_button_text_color'
                              )}
                              defaultValue={_?.text_color}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomColorPicker
                              name={`company_platform.ctaButtons[${index}].color`}
                              register={register}
                              title={t(
                                'views:CompanyCustomization.cta_button_color'
                              )}
                              defaultValue={_?.color}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <CustomColorPicker
                              name={`company_platform.ctaButtons[${index}].border_color`}
                              register={register}
                              title={t(
                                'views:CompanyCustomization.cta_button_border_color'
                              )}
                              defaultValue={_?.border_color}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className={classes.colorTopSpacing}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name={`company_platform.ctaButtons[${index}].link`}
                          label={t(
                            'views:CompanyCustomization.navigation_link'
                          )}
                          defaultValue={_?.link}
                          inputRef={register}
                        />
                        <CustomHelper
                          text={t(
                            'views:CompanyCustomization.navigation_link_helper'
                          )}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box>
              <IconButton
                color="secondary"
                onClick={() => {
                  company_platform.ctaButtons.push({
                    text: '',
                    color: '#000000',
                    text_color: '#000000',
                    border_color: '#000000',
                    link: '',
                  });
                  setRefresh(!refresh);
                }}
              >
                <Add />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  contentRoot: {
    paddingTop: theme.spacing(3),
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
  spacingTop2x: {
    marginTop: theme.spacing(4),
  },
  videoBox: {
    padding: theme.spacing(2),
    paddingTop: 0,

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    },
  },
  videoMedia: {
    width: '100%',
  },
  topSpacing: {
    display: 'flex',
    paddingTop: theme.spacing(2),
  },
  ctaButtonSpacing: {
    padding: theme.spacing(2),
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  select: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  selected: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
  },
}));

export default PlatformCustomization;
