export function vimeoRequest(data, file) {
  return {
    type: '@content/VIMEO_REQUEST',
    payload: { data, file },
  };
}

export function uploadRequest(
  formData,
  currentlessonContent,
  company_course_id
) {
  return {
    type: '@content/UPLOAD_REQUEST',
    payload: { formData, currentlessonContent, company_course_id },
  };
}

export function vimeoSuccess(url, title, description) {
  return {
    type: '@content/VIMEO_SUCCESS',
    payload: { url, title, description },
  };
}

export function uploadFileSuccess(id, title, description) {
  return {
    type: '@content/UPLOAD_FILE',
    payload: { id, title, description },
  };
}

export function uploadHtmlSuccess(content, title, description) {
  return {
    type: '@content/HTML_SUCCESS',
    payload: { content, title, description },
  };
}

export function uploadEmbedSuccess(content, title, description) {
  return {
    type: '@content/EMBED_SUCCESS',
    payload: { content, title, description },
  };
}

export function uploadEmbedInteractSuccess(content, title, description) {
  return {
    type: '@content/EMBED_SUCCESS',
    payload: { content, title, description },
  };
}

export function uploadFailure() {
  return {
    type: '@content/UPLOAD_FAILURE',
  };
}

export function vimeoClearURL() {
  return {
    type: '@content/VIMEO_CLEAR_URL',
  };
}
