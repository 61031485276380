import React, { forwardRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';

import api from 'services/api';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  route,
  isOpen,
  closeModal,
  tableRef,
  functionDelete,
  propsHandleConfirm,
  customMessage,
}) {
  const { t } = useTranslation(['toastify', 'modal']);
  const handleClose = () => {
    closeModal(false);
  };

  const handleConfirm = async () => {
    try {
      await api.delete(route);
      toast.success(t('toastify:commons.toast_delete'));
      if (tableRef) {
        tableRef.state.query.page = 0;
        tableRef.onQueryChange();
      }
      functionDelete && functionDelete();
      closeModal(false);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
      closeModal(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('modal:commons.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {customMessage ?? t('modal:delete.body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('modal:commons.disagree')}
          </Button>
          <Button
            onClick={() => {
              if (propsHandleConfirm) {
                propsHandleConfirm();
                closeModal(false);
              } else {
                handleConfirm();
              }
            }}
            variant="outlined"
            color="secondary"
          >
            {t('modal:commons.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
