import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { documentMask } from 'common/validators';
export function DocumentMaskCustom(props) {
  const { inputRef, name, value, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      name={name}
      mask={documentMask(value)}
      guide={false}
    />
  );
}

DocumentMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  value: PropTypes.func.isRequired,
};
