import { Box, Button, Grid, Typography } from '@material-ui/core';
import { InfoOutlined, Send, Visibility, Edit } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { format, parseISO, set } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LessonQuestionsData from 'services/dataService/LessonQuestionsData';
import { LessonContentData, RatingData } from 'services/dataService';
import CheckboxEvalution, {
  QuestionTypeEnum,
} from 'views/Exams/components/CheckboxEvalution';
import RadioButtonEvalution from 'views/Exams/components/RadioButtonEvalution';

import ModalRating from 'views/RatingCourses/components/ModalRating/Index';


const ExamLearnContent = ({ finishedCourse, content, courseId, hideAnotation, course, next }) => {

  const classes = useStyles();
  const { id: id_user } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state);

  const [lessonsExams, setLessonsExams] = React.useState([]);
  const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [availableAttempts, setAvailableAttempts] = React.useState(0);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [error, setError] = React.useState(false);
  const [can_redo, setCanRedo] = React.useState(true);
  const [showAnswers, setShowAnswers] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const [buttonShowAnswers, setButtonShowAnswers] = React.useState(true);
  const [finished, setFinished] = React.useState(false);
  const [resultsExam, setResultsExam] = React.useState([]);
  const [pendingClasses, setPendingClasses] = React.useState([]);
  const [checkApprovedExam, setCheckApprovedExam] = useState(false);
  const [disapprovalList, setDisaprovalList] = useState(false);
  const [isFinalLesson, setIsFinalLesson] = useState(false);
  const [lasttGrade, setLasttGrade] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const { t } = useTranslation(['views']);
  const [isSending, setIsSending] = useState(false);

  const [openRatingExams, setOpenRatingExams] = useState(false)
  const [isShould, setIsShould] = useState(false);
  const [sendFinished, setSendFinished] = useState(false);
  const [doRating, setDoRating] = useState(true);

  let comp = [
    {
      comp: 'mant',
      id: 'f6747104-f86a-4365-9aa5-62bf3cb0b317',
      link: 'https://docs.google.com/document/d/1As_pG1Rmdf9d0Y_RnkuL7NV8iREdQBIOHydhAd--Ogc/edit?usp=sharing',
      coin: 'MantiCoins',
      logo: '/images/logos/logo-mant.png'
    },
    {
      comp: 'dream',
      id: '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5',
      link: 'https://docs.google.com/document/d/1g9eeA6x_zNELLss5uv-sbLhEg32J_fme0Mggb1zszgU/edit?usp=sharing',
      coin: 'Dream Coins',
      logo: '/images/logos/logo-dream.png'
    },
  ];

  const history = useHistory();

  async function handleDisapprovalList(id_lesson_content, id_user, id_course) {
    const approvedExam = await LessonQuestionsData.checkApprovedExam(id_lesson_content, id_user, id_course);
    setCheckApprovedExam(approvedExam.data.isApproved);
    setIsFinalLesson(approvedExam.data.isFinalLesson);
    setDisaprovalList(approvedExam.data.disapprovalList);
  };
  async function showLastIncorrectAnswers() {
    try {
      const response = await LessonQuestionsData.getExamDetails(id_user, content.id);
      setButtonShowAnswers(true)
      setAnswers(response.data.lesson_content_exams || []);
    } catch (error) {
      setButtonShowAnswers(false)
      console.error('Avaliação ainda não foi realizada:', error);
    }

  }

  const loadExam = async () => {
    setFlag(true);
    setOpenRatingExams(true)
    try {
      await handleDisapprovalList(content.id, id_user, courseId);
      const { data: pendingClasses } = await LessonQuestionsData.getPendingClasses(
        id_user,
        courseId,
        content.id
      )

      if (pendingClasses.length > 0) {
        setPendingClasses(() => pendingClasses)
        setFinished(() => true);
      }

      const { data: lessonExam } = await LessonQuestionsData.getlessonQuestions(
        id_user,
        content?.id
      );

      try {
        if (pendingClasses.length === 0) {
          const { data: examResult } = await LessonQuestionsData.getExamResult(
            id_user,
            content?.id
          );

          setFinished(true);
          setResultsExam(examResult);

          const {
            data: newExamAttempt,
          } = await LessonContentData.checkNewExamAttempt(id_user, content?.id);

          if (examResult.length > 0) {
            const attemps = examResult.sort((a, b) => {
              return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
            })[0]?.available_exam_attempts || 0;

            setAvailableAttempts(attemps);
          }

          function betterGrade() {
            for (let i = 0; i < examResult.length; i++) {
              if (examResult[i].score == '1.00') {
                return true;
              }
            }
          }

          function openModal() {
            examResult.sort((a, b) => b.score - a.score);
            let x = 0
            x = examResult[0].score * 100;
            if (flag == true) {
              if ((x >= course.course.score) && ((course.id_company == '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5')
                || (course.id_company == 'f6747104-f86a-4365-9aa5-62bf3cb0b317'))) {
                setOpen(true);
                setTimeout(() => {
                  setOpen(false);
                }, 10000);
              }
              else {
                setOpen(false)
              }
            }
          };

          function lastGrade() {
            examResult.sort((a, b) => b.score - a.score);
            let x = 0
            x = examResult[0].score * 100;
            if (x >= course.course.score) {
              setLasttGrade(true)
            }
            else {
              setLasttGrade(false)
            }
          }

          lastGrade()
          openModal()

          if (newExamAttempt && newExamAttempt.can_redo && !betterGrade()) {
            setCanRedo(true);
          } else {
            setCanRedo(false);
          }
        }
      } catch (error) {
        setFinished(false);
      }

      setName(lessonExam?.name || '');
      setDescription(lessonExam?.description || '');
      setNumberOfAttempts(lessonExam?.max_exam_attempts || '');
      setLessonsExams(lessonExam?.lesson_content_exams || []);
    } catch (error) {
      console.log(error);
    }

  };

  const handleSubmitCallback = (sucess) => {
    if (sucess) {
      setSendFinished(true);
      localStorage.setItem(`hasRatedCourse_${id_user}_${course.id_company_course}`, 'true');
    }
  };

  const calculateAvailableAttempts = () => {

    let examAvailableAttempts = availableAttempts > 0 ? availableAttempts : numberOfAttempts

    return Math.max(0, examAvailableAttempts - 1);

  };

  const onExamResultsSend = async () => {
    if (isSending) {
      return;
    }

    setIsSending(true);

    const lessonResult = lessonsExams?.map((exam) => {
      const answers = exam?.lesson_content_exam_questions
        ?.filter((questionsFilter) => questionsFilter?.is_correct === true)
        .map(({ id }) => {
          return { id_lesson_content_exam_questions: id };
        });

      return {
        id_lesson_content_exams: exam?.id,
        answers,
      };
    });

    let availableExamAttempts = calculateAvailableAttempts();

    await LessonQuestionsData.sendExamResult(
      id_user,
      content?.id,
      lessonResult,
      availableExamAttempts
    );

    setFinished(true);
    finishedCourse({
      finished: true,
      video_last_sec: null,
      audio_last_sec: null,
    });

    loadExam();
    showLastIncorrectAnswers();
    setIsSending(false);
  };

  const onHandleRedoExam = () => {
    const hasRatedCourse = localStorage.getItem(`hasRatedCourse_${id_user}_${course.id_company_course}`);
    if (hasRatedCourse) {
      setDoRating(false)
    }
    setButtonShowAnswers(false);
    setAnswers(false);
    setFinished(false);
  };

  const onHandleShowAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  const onShouldOpenModalRating = async () => {
    try {
      const response = await RatingData.hasRating(id_user, course.id_company_course);

      if (response && response.data.shouldOpenModal) {
        setIsShould(true);
      }
    } catch (error) {
      console.error('Erro ao verificar se o modal deve ser aberto:', error);
    }
  };

  const companyId = course?.id_company;
  const coFind = comp.find(item => item.id === companyId)

  const coin = coFind ? (
    <strong>
      10 {coFind.coin}
    </strong>
  ) : null;


  useEffect(() => {
    if (hideAnotation) {
      hideAnotation(true);
    }
  }, []);

  useEffect(() => {
    loadExam();
    onShouldOpenModalRating();
    showLastIncorrectAnswers();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCertificateClick = () => {
    history.push('/certificates');
  };


  return (
    <>
      <div className=''>
        <Dialog className='divModalcentralize-content'
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 15 }
          }}
        >
          <DialogTitle>
            <h4
              style={{
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '40px',
                lineHeight: '61px'
              }}
            >
              Parabéns <span>&#x1F389;&#x1F389;&#x1F389;</span>
            </h4>
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <Typography
                  variant="h6"
                  component={"h6"}
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: '500',
                    fontStyle: 'normal',
                    marginTop: '8px'
                  }}
                >
                  Você acaba de ganhar {coin}
                  <img
                    className="coinImage"
                    src={"/images/Coin/coin.png"}
                    alt="Minha Imagem"
                    style={{
                      width: '20px',
                      height: '20px',
                      margin: '-5px',
                      marginLeft: '1px',
                      marginRight: '-1px',
                      marginBottom: '-5px'
                    }}
                  /> por concluir:
                </Typography>

                <Typography
                  variant="h5"
                  style={{
                    marginTop: '15px',
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    lineHeight: '22px'
                  }}
                >
                  <strong>{course.course.name}</strong>
                </Typography>
              </div>
            </DialogContentText>

            <DialogContentText>
              <Typography
                variant="h6"
                style={{
                  marginTop: "30px",
                  fontSize: "15px"
                }}
              >
                Os pontos podem ser trocados por premiações de acordo com as{" "}
                <a className="textPoliticas" href={comp.some(item => item.id === course.id_company) ? comp.find(item => item.id === course.id_company).link : null}
                  target="_blank"
                  style={{
                    color: "#1D7FF1"
                  }}
                >
                  políticas da empresa.
                </a>
              </Typography>
            </DialogContentText>

            <DialogContentText>
              <div>
                <img
                  className="coinImage"
                  src={comp.some(item => item.id === course.id_company) ? comp.find(item => item.id === course.id_company).logo : null}
                  alt="Minha Imagem"
                  style={{ marginTop: '25px' }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {/* end */}

      {pendingClasses.length > 0 && finished ? (
        <Box className={classes.headerBox}>
          <Typography className={classes.examSubmitted}>{t('views:Exam.pending_classes')}</Typography>
          {pendingClasses
            .map((result, index) => (
              <Typography key={index} className={classes.submissionDate}> <span className={classes.dateNote}>
              </span > - {t('views:Exam.class')}: <span className={classes.dateNote}>{result?.title}</span>
              </Typography>
            ))
          }
        </Box>
      ) : (
        <Box className={`${classes.root} notranslate`} >
          <Box className={classes.headerBox}>
            {resultsExam.length > 0 && finished ? (
              <>
                <Typography className={classes.examSubmitted}>{t('views:Exam.already_submitted')}</Typography>
                <Typography style={{}} />
                <span className={classes.minNote}>{t('views:Exam.average_grade')}{course.course.score}</span>

                <div style={{ margin: '15px 0' }}>
                  <Typography variant="h4" style={{ color: lasttGrade ? 'green' : '#dd6e05' }}>
                    {lasttGrade ? (
                      <div>
                        Parabéns, você foi aprovado!<br /> <br />
                        {(disapprovalList.length > 0 && !checkApprovedExam && isFinalLesson) &&
                          <>
                            <Typography className={classes.examSubmitted}>{t('views:Exam.disapprovalList')}</Typography>
                            <br />
                            <Typography variant="h4" style={{ color: 'red' }}>
                              Lista das provas pendentes:
                            </Typography>
                            {disapprovalList.map((result, index) => (
                              <Typography key={index} className={classes.submissionDate}> <span className={classes.dateNote}>
                              </span > - {t('views:Exam.class')}: <span className={classes.dateNote}>{result?.name}</span>
                              </Typography>
                            ))}
                          </>
                        }
                        {(checkApprovedExam && isFinalLesson) &&
                          <Typography
                            variant='h5'
                          >
                            <a href="" onClick={handleCertificateClick}> Acesse o seu certificado aqui</a>.
                          </Typography>
                        }
                      </div>
                    ) : (
                      <div>
                        Você não obteve nota suficiente, tente novamente.<br />
                      </div>
                    )}
                  </Typography>
                </div>
                {resultsExam
                  .map((result, index) => (
                    <Typography key={index} className={classes.submissionDate}>{t('views:Exam.submission_date')}: <span className={classes.dateNote}>{format(
                      parseISO(result?.createdAt),
                      'dd/MM/yyyy HH:mm'
                    )}</span> - {t('views:Exam.submission_grade')}: <span className={classes.dateNote}>{(parseFloat(result?.score) * 100).toFixed(2)}</span>
                    </Typography>
                  ))
                  .reverse()
                }
                <Typography className={classes.info}>
                  {can_redo
                    ? (
                      <>
                        <InfoOutlined style={{ height: 16, marginTop: 1 }} />
                        {t('views:Exam.info_attempts').replace('<data>', (availableAttempts).toString())}
                        <div className={classes.containerButton}> 
                          <Button className={classes.redoButton} onClick={onHandleRedoExam}>
                           <Edit className={classes.space} />
                            {t('views:Exam.redo_button')}
                          </Button>
                        </div>

                      </>
                    ) : (
                      <>
                        <InfoOutlined style={{ height: 16, marginTop: 1, alignSelf: 'flex-start' }} />
                        {t('views:Exam.info_ask_new_exam')}
                      </>
                    )}

                </Typography>
              </>
            ) : (
              <>
                {(isShould && doRating) && (
                  <>
                    {openRatingExams && next === undefined && (
                      <ModalRating
                        setOpen={setOpenRatingExams}
                        company={course.id_company}
                        user={id_user}
                        course={course}
                        flag={true}
                        handleSubmitCallback={handleSubmitCallback}
                      />
                    )}
                  </>
                )
                }
                <Typography className={classes.nameTitle}>{name}</Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </>
            )}

          </Box>
          {!finished && (
            <>
              {lessonsExams.map((exam, index) => (
                <>
                  <Typography className={classes.marginTop} />
                  {exam?.type_exam === QuestionTypeEnum.RADIO_QUESTION ? (
                    <RadioButtonEvalution
                      exam={exam}
                      index={index}
                      onCopy={() => { }}
                    />
                  ) : (
                    <CheckboxEvalution
                      exam={exam}
                      index={index}
                      onCopy={() => { }}
                      error={error}
                    />
                  )}
                </>
              ))}
              <Typography className={classes.marginTop} />
              <Box className={classes.footer}>
                <Grid container spacing={2} justify="center" alignContent="center">
                  <Grid item xs={12} align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onExamResultsSend}
                      disabled={isSending}
                    >
                      <Send />
                      <Typography className={classes.sendTxt}>Enviar</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      )}
      {(buttonShowAnswers && company?.show_answers == true) && (
        <div className={classes.containerButton}>
          <Button style={{marginRight: '67px'}} className={classes.redoButton} onClick={onHandleShowAnswers}>
            <Visibility className={classes.space} />
            {t('views:Exam.show_answers')}
          </Button>
        </div>
      )}
      {showAnswers && (
        <div className={classes.root}>
          <div className={classes.headerBox}>
            <span className={classes.dateNote}>Segue abaixo a(s) questão(ões) assinaladas como incorreta(s).</span>
            {answers.length > 0 && (
              answers.map((exam, index) => (
                <React.Fragment key={index}>
                  <Typography className={classes.marginTop} />
                  {exam?.type_exam === QuestionTypeEnum.RADIO_QUESTION ? (
                    <RadioButtonEvalution
                      exam={exam}
                      index={index}
                      isReviewMode={true}
                      onCopy={() => { }}
                    />
                  ) : (
                    <CheckboxEvalution
                      exam={exam}
                      index={index}
                      onCopy={() => { }}
                      isReviewMode={true}
                      error={error}
                    />
                  )}
                </React.Fragment>
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  marginTop: {
    paddingTop: theme.spacing(3),
  },
  headerBox: {
    backgroundColor: theme.palette.white,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    boxShadow: '0px 8px 50px #2A2E4320',
    borderRadius: 8,
    borderBottomColor: theme.palette.info.custom,
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  nameTitle: {
    ...theme.typography.h2,
    fontSize: 35,
    paddingBottom: theme.spacing(2),
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: 17,
    paddingBottom: theme.spacing(2),
  },
  space: {
    paddingRight: '5px',
  },
  textInfo: {
    fontWeight: 'bold',
  },
  footer: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    boxShadow: '0px 8px 50px #2A2E4320',
    borderRadius: 8,
  },
  sendTxt: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.white,
  },
  examSubmitted: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(0),
  },
  submissionDate: {
    marginBottom: theme.spacing(1),
  },
  dateNote: {
    fontWeight: 500,
  },
  info: {
    display: 'flex',
    color: '#9E9E9E',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    borderTopColor: theme.palette.divider,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    alignItems: 'center',
    width: '100%'
  },
  minNote: {
    display: 'flex',
    color: '#9E9E9E',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    fontSize: 13,
  },
  containerButton: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%'
  },
  redoButton: {
    marginLeft: theme.spacing(1),
    fontWeight: 300,
    color: theme.palette.white,
    backgroundColor: theme.palette.info.main,
    fontSize: 12,
    width: 'fit-content',
    height: 'fit-content',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  }
}));

export default ExamLearnContent;