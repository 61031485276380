import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { toast } from 'react-toastify';
import { CourseData } from 'services/dataService';
// import Juno from 'juno-api';
import JunoAPIService from 'services/paymentService/JunoAPIService';
import { store } from 'store';
import { PaymentSteps } from 'views/CourseBuyView';
import {
  updateJunoAPIKeys,
  setJunoApiFetching,
  UPDATE_JUNO_API_KEY_SAGA,
  JUNO_CHECKOUT,
  setChargeId,
} from './actions';

export function* loadJunoApiKeys() {
  try {
    yield put(setJunoApiFetching(true));

    const { data: junoApiKeys } = yield call(JunoAPIService.getAccessToken);
    yield put(updateJunoAPIKeys({ ...junoApiKeys }));

    yield put(setJunoApiFetching(false));
  } catch (err) {}
}

export function* sendPayment({ payload }) {
  // step var is used to verify where the exception is throw
  let step = 0;
  try {
    yield put(setJunoApiFetching(true));
    const { billing, cardData } = payload;
    const { id: id_user } = store.getState()?.auth;
    // Get Juno API token and chargeId(For failed checkout atempt)
    const {
      token: junoToken,
      chargeId: storeChargeId,
    } = store.getState()?.junoAPI;

    // Get Course price
    const { data: companyCourse } = yield call(() =>
      CourseData.getCompanyCourseById(payload?.course_id)
    );
    step++;

    // Crete and convert the due date
    const currentDate = new Date();
    const m = currentDate.getMonth() + 1;

    const dueDate = `${currentDate.getFullYear()}-${m > 9 ? '' + m : '0' + m}-${
      currentDate.getDate() > 9
        ? '' + currentDate.getDate()
        : '0' + currentDate.getDate()
    }`;

    // Create API Charge object
    const charge = {
      dueDate,
      description: 'Matricula de curso',
      amount: Number.parseFloat(companyCourse?.price || 0),
      installments: Number.parseInt(payload?.installments || 1),
      maxOverdueDays: 0,
      fine: 0,
      interest: '0.00',
      discountAmount: '0.00',
      discountDays: -1,
      paymentTypes: ['CREDIT_CARD'],
      paymentAdvance: false,
    };

    if (!storeChargeId?.length) {
      // Create charge and make chechout
      const { data: charges } = yield call(() =>
        JunoAPIService.createCharge({ junoToken, charge, billing })
      );
      step++;

      if (charges?.length) {
        yield put(setChargeId(charges[0]?.id));
        yield call(() =>
          JunoAPIService.checkout({
            junoToken,
            cardData,
            id_user,
            id_course: companyCourse?.id,
            billing: {
              email: billing?.email,
              address: {
                ...billing?.address,
              },
            },
            charges,
          })
        );
      }
    } else {
      // new checkout atempt
      step = 2;
      yield call(() =>
        JunoAPIService.checkout({
          junoToken,
          id_user,
          id_course: companyCourse?.id,
          cardData,
          billing: {
            email: billing?.email,
            address: {
              ...billing?.address,
            },
          },
          charges: [{ id: storeChargeId }],
        })
      );
    }

    // Case of success clean the charge id
    yield put(setChargeId(''));

    // call sucess callback
    if (payload?.callback) {
      payload.callback(PaymentSteps.PAYMENT_FINISHED);
    }

    yield put(setJunoApiFetching(false));
  } catch (err) {
    yield put(setJunoApiFetching(false));
    if (step === 0) {
      toast.error('Curso não encontrado');
    } else if (step === 1) {
      toast.error('não foi possível criar a cobrança verifique seus dados');
    } else if (step === 2) {
      toast.error(
        'Houve um erro com o pagamento, verifique os dados do cartã e tente novamente'
      );
    } else {
      toast.error('Houve um erro, tente novamente mais tarde');
    }
  }
}

export default all([
  takeLatest(JUNO_CHECKOUT, sendPayment),
  takeLatest(UPDATE_JUNO_API_KEY_SAGA, loadJunoApiKeys),
]);
