import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';

import { useStyles } from './styles';
import { SettingsData } from 'services/dataService';

export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const [randomCheck, setRandomCheck] = useState(false);
  const [invitedCheck, setInvitedCheck] = useState(false);
  const [enableCheck, setEnableCheck] = useState(false);


  const { id_company } = useSelector((state) => state.auth);
  const { register, handleSubmit, control, errors } = useForm();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const toggleRandomChecked = () => {
    setRandomCheck((prev) => !prev);
  };
  const toggleInvitedChecked = () => {
    setInvitedCheck((prev) => !prev);
  };
   const toggleAccessChecked = () => {
    setEnableCheck((prev) => !prev);
  };

  const handleChange = (event) => {
    setSettings({ ...settings, number_courses_homepage: event.target.value });
  };

  const classes = useStyles();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await SettingsData.updateSettings(id_company, data);
        toast.success(t('toastify:commons.toast_update'));
      } catch (error) {
        toast.error(t('toastify:commons.toast_error'));
      }
    },
    [id_company, t]
  );

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const response = await SettingsData.getSettingsById(id_company);


        setSettings(response.data);
        setRandomCheck(response.data.display_courses_random_order);
        setInvitedCheck(response.data.only_invited_users_register);
        setEnableCheck(response.data.access_request);
        
        setLoading(false);
      } catch (err) {
        toast.error(t('toastify:commons.toast_error'));
      }
    };
    loadSettings();
  }, [id_company, t]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t('title:Settings.title_settings')} />
      <Divider />
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!loading && (
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="display_courses_random_order">
                  {t('form:Settings.input_settings_random_order')}
                </InputLabel>
                <Switch
                  id="display_courses_random_order"
                  checked={randomCheck}
                  onChange={toggleRandomChecked}
                  color="primary"
                  inputRef={register}
                  name="display_courses_random_order"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <FormHelperText>
                  {t('form:Settings.input_settings_random_order_info')}
                </FormHelperText>
              </Grid>

              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="only_invited_users_register">
                  {t('form:Settings.input_settings_only_invited_users')}
                </InputLabel>
                <Switch
                  id="only_invited_users_register"
                  checked={invitedCheck}
                  onChange={toggleInvitedChecked}
                  color="primary"
                  inputRef={register}
                  name="only_invited_users_register"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <FormHelperText>
                  {t('form:Settings.input_settings_only_invited_users_info')}
                </FormHelperText>
              </Grid>
              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="access_request">
                  {t('form:Settings.input_settings_only_access_request')}
                </InputLabel>
                <Switch
                  id="access_request"
                  checked={enableCheck}
                  onChange={toggleAccessChecked}
                  color="primary"
                  inputRef={register}
                  name="access_request"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <FormHelperText>
                  {t('form:Settings.input_settings_access_request_info')}
                </FormHelperText>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Settings.input_settings_courses_amount')}
                  helperText={
                    (errors.number_courses_homepage?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Settings.input_settings_courses_amount'),
                      })) ||
                    (errors.number_courses_homepage?.type === 'min' &&
                      t('validation:commons.validation_min_courses_homepage', {
                        min: 9,
                      }))
                  }
                  value={settings.number_courses_homepage || ''}
                  name="number_courses_homepage"
                  type="number"
                  variant="outlined"
                  onChange={handleChange}
                  inputRef={register({ required: true, min: 9 })}
                  error={!!errors.number_courses_homepage}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl style={{ minWidth: 300 }}>
                  <InputLabel id="currency">
                    {t('form:Settings.input_settings_currency')}
                  </InputLabel>

                  <Controller
                    as={
                      <Select>
                        <MenuItem value="BRL">
                          {t('form:Settings.input_settings_currency_BRL')}
                        </MenuItem>
                        <MenuItem value="USD">
                          {t('form:Settings.input_settings_currency_USD')}
                        </MenuItem>
                      </Select>
                    }
                    name="currency"
                    rules={{
                      required: t(
                        'validation:commons.validation_select_required'
                      ),
                    }}
                    control={control}
                    defaultValue={settings ? settings.currency : 'BRL'}
                  />
                </FormControl>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControl style={{ minWidth: 300 }}>
                  <InputLabel id="default_language">
                    {t('form:Settings.input_settings_default_language')}
                  </InputLabel>

                  <Controller
                    as={
                      <Select>
                        <MenuItem value="pt-BR">
                          {t('form:Settings.input_settings_language_brazil')}
                        </MenuItem>
                        <MenuItem value="en-US">
                          {t('form:Settings.input_settings_language_english')}
                        </MenuItem>
                      </Select>
                    }
                    name="default_language"
                    rules={{
                      required: t(
                        'validation:commons.validation_select_required'
                      ),
                    }}
                    control={control}
                    defaultValue={
                      settings ? settings.default_language : 'pt-BR'
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        )}

        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
          >
            {t('button:commons.btn_save')}
            <Save />
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
