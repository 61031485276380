import { Box, Button, Typography } from '@material-ui/core';
import CustomSlider from 'components/CustomSlider';
import Interweave from 'interweave';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const TrailsPanel = ({
  finished,
  trails,
  onLike,
  onActionClick,
  slidesNumber,
  ...props
}) => {
  const { t } = useTranslation(['button']);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const classes = useStyles();

  return (
    <CustomSlider
      itens={trails}
      slidesNumber={slidesNumber}
      slidesToShow={slidesToShow}
      setSlidesToShow={setSlidesToShow}
      {...props}
    >
      {trails
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        ?.map((trail, index) => {
          return (
            <div className={classes.slidesShow} key={index}>
              <Box
                className={classes.card}
                style={{ width: slidesToShow <= 2 ? 350 : 'unset' }}
              >
                <Box>
                  <Link
                    to={
                      trail?.matriculated
                        ? `trail/${trail?.id}/learn?course=${trail?.id_course}&progress=true`
                        : `/trail/${trail?.id}/enroll`
                    }
                  >
                    <img
                      className={classes.media}
                      src={
                        trail?.file?.url
                          ? trail?.file?.url
                          : 'https://via.placeholder.com/350x208'
                      }
                    />
                  </Link>
                  <Box className={classes.cardBody}>
                    <Typography component="span" className={classes.header}>
                      {trail.name}
                    </Typography>
                    <Box className={classes.description}>
                      <Interweave
                        content={
                          trail?.description?.length ? trail?.description : '-'
                        }
                      />
                    </Box>
                    <Box className={classes.action}>
                      <Box className={classes.accessButton}>
                        <Button
                          size="medium"
                          color="primary"
                          className={classes.actionButton}
                          onClick={() => {
                            if (onActionClick) {
                              if (trail?.finished && trail?.matriculated) {
                                onActionClick(
                                  trail?.id,
                                  'finished',
                                  trail?.id_course
                                );

                                return;
                              }

                              if (!trail?.finished && trail?.matriculated) {
                                onActionClick(
                                  trail?.id,
                                  'progress',
                                  trail?.id_course
                                );

                                return;
                              }

                              onActionClick(trail?.id);
                            }
                          }}
                        >
                          {trail?.finished &&
                            trail?.matriculated &&
                            t(`button:StudentContent.btn_completed`)}
                          {!trail?.finished &&
                            t(
                              `button:StudentContent.${
                                trail?.matriculated ? 'btn_start' : 'btn_enroll'
                              }`
                            )}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          );
        })}
    </CustomSlider>
  );
};
export default TrailsPanel;
