import api from '../api';

class FavorityCourseData {
  favorityCourse(id, id_company_course) {
    return api.post(`/favority-courses/${id}/${id_company_course}`);
  }

  removeCourseFavority(id, id_company_course) {
    return api.delete(`/favority-courses/${id}/${id_company_course}`);
  }
}

export default new FavorityCourseData();
