import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CustomPaymentTabs({ tabs, children }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary">
        {tabs?.map((tab) => (
          <Tab label={tab} />
        ))}
      </Tabs>
      {children?.map((child) => (
        <>
          {child?.props?.index === value && (
            <Box style={{ paddingTop: theme.spacing(2) }}>{child}</Box>
          )}
        </>
      ))}
    </div>
  );
}
