import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  labelBox: {
    cursor: 'pointer'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#2A2E43',
    padding: theme.spacing(1.5, 3),
    '& h3, & button': {
      color: '#FAFAFA',
    },
    '& button:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  instruction: {
    fontSize: 17,
    color: theme.palette.default.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  labelCheckTitle: {
    display: 'flex',
    '& > div': {
      alignItems: 'center'
    }
  },
  boxButtons: {
    display: 'block',
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'end',
      }
    }
  }
}));
