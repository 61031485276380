import { Box } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';
import theme from 'theme';
import WelcomeBanner from './components/Banner';
import CompanySolutions from './components/CompanySolutions';
import EventButtons from './components/Events';
import WelcomePageExternalCourses from './components/FeaturedContent';
import LoginFooter from './components/Footer';
import PlatformApresentation from './components/Platform';

const WelcomeView = () => {
  const [loading, setLoading] = React.useState(true);
  const [customization, setCustomization] = React.useState();
  const [externalCurses, setExternalCourses] = React.useState();

  const { company } = useSelector((state) => state);

  const loadPageData = async () => {
    try {
      const {
        data: externalCourses,
      } = await PublicCoursesViewsData.getExternalFeaturedCourses(
        company?.domain_company
      );
      setExternalCourses(externalCourses.coursesList);
    } catch (error) {}

    try {
      const {
        data: companyCustomization,
      } = await PublicCoursesViewsData.getCompanyCustomization(
        company?.domain_company
      );

      companyCustomization.featured_content.ctaButtons =
        companyCustomization?.featured_content?.ctaButtons?.reverse() || [];

      setCustomization(companyCustomization);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadPageData();
  }, []);

  return (
    <Box>
      {!loading && (
        <Box>
          <WelcomeBanner banner_data={customization?.company_banner} company_data={company?.domain_company} />
          <WelcomePageExternalCourses
            featured_courses={externalCurses}
            customization={customization?.featured_content}
          />
          {customization?.company_platform?.title?.length  > 0 && (
            <PlatformApresentation
            company_platform={customization?.company_platform}
          />
          )}
          {customization?.company_events?.event_buttons?.length  > 0 && (
            <EventButtons event={customization?.company_events} />
          )}
          
          {customization?.company_solutions?.ctaButtons?.length  > 0 && (
            <CompanySolutions solution={customization?.company_solutions} />
          )}
          {customization?.company_footer?.contacts?.length > 0 && (
            <LoginFooter footer={customization?.company_footer} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default WelcomeView;
