import api from '../api';

class CustomFieldsData {
  addCustomFields(data) {
    return api.post('company-user-fields', data);
  }

  getCustomFields() {
    return api.get('company-user-fields');
  }

  getCustomFieldsRegister(companyId) {
    const data = {
      headers: {
        company: companyId,
      },
    };

    return api.get('company-user-fields-register', data);
  }

  deleteCustomFields(data) {
    return api.post('delete-company-user-fields', data)
  }

  getExportFields() {
    const url = 'company-user-export-fields'
    return api.get(url)
  }
}

export default new CustomFieldsData();
