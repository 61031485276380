import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function DialogPreviewLibrary({
    previewName,
    modalStatus,
    closeModal,
    previewUrl,
    previewType,
}) {
    const { t } = useTranslation(['toastify', 'modal']);

    const handleClose = () => {
        closeModal(false);
    };

    return (
        <Dialog
            open={modalStatus}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{previewName}</DialogTitle>
            <DialogContent>
                {previewType && previewType.startsWith('image/') ? (
                    <img src={previewUrl} alt="Preview" style={{ width: '100%' }} />
                ) : previewType === 'application/pdf' ? (
                    <iframe src={previewUrl} width="100%" height="700px"></iframe>
                ) : previewType && previewType.startsWith('video/') ? (
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                        <iframe
                            src={previewUrl}
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            frameBorder="0"
                            allow="accelerometer; encrypted-media;"
                            allowFullScreen
                        ></iframe>
                    </div>
                ) : (
                    <h4>{t('toastify:commons.toast_error_preview_unsupported')}</h4>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('button:commons.btn_close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
