import React, { useState } from 'react';
import { useStyles } from './styles';
import { Paper, InputLabel, Switch, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { CourseData } from 'services/dataService';
import { useHistory, useParams } from 'react-router-dom';
import ButtonsAction from 'components/ButtonsAction';

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [signableSwitch, setSignableSwitch] = useState(false);
  const [visibleSwitch, setVisibleSwitch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState({});
  const { id } = useParams();


  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const handleSignableChange = () => {
    setSignableSwitch((prev) => !prev);
  };

  const handleVisibleChange = () => {
    setVisibleSwitch((prev) => !prev);
  };

  const onSubmit = (data) => {
    const formValue = {
      signable: data.signable,
      visible: !data.visible,
      ...courseData,
    };



    (async () => {
      try {
        await CourseData.updateCourse(id, formValue);
        toast.success(t('toastify:commons.toast_update'));
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_update_error'));
        }
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await CourseData.getCourses({ id_course: id });
        const companyCourse = response.data.courses[0];

        setSignableSwitch(companyCourse.signable);
        setValue([{ signable: companyCourse.signable }]);
        setVisibleSwitch(companyCourse.visible);
        setValue([{visible: companyCourse.visible}])

        setCourseData({
          name: companyCourse.course.name,
          keywords: companyCourse.course.keywords,
          content: companyCourse.course.content,
          description: companyCourse.course.description,
          id_category: companyCourse.id_category,
          id_subcategory: companyCourse.id_subcategory,
          score: companyCourse.course.score,
          workload: companyCourse.course.workload,
          require_classes_completion: companyCourse.require_classes_completion,
          require_classes_approval: companyCourse.require_classes_approval,
          id_file_banner: companyCourse.id_file_banner,
          id_file_card: companyCourse.id_file_card,
        });
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    })();
    setLoading(false);
  }, []);

  if (!loading)
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="signable">
                {t('form:Courses.Setting.sign_course')}
              </InputLabel>
              <Switch
                id="signable"
                checked={signableSwitch}
                onChange={handleSignableChange}
                color="primary"
                inputRef={register}
                name="signable"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="visible">
                {t('form:Courses.Setting.hide_course')}
              </InputLabel>
              <Switch
                id="visible"
                checked={!visibleSwitch}
                onChange={handleVisibleChange}
                color="primary"
                inputRef={register}
                name="visible"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonsAction
                isUpdate
                cancelBtn={{
                  handleCancelClick: () =>
                    history.push(`/courses/${id}/lessons`),
                  text: t('button:FeaturedCourses.cancel'),
                }}
                saveBtn={{
                  text: t('button:FeaturedCourses.update'),
                  color: 'secondary',
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  return <></>;
};
