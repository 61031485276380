import React, { useState, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@material-ui/icons/Brightness1';
import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import { useStyles } from './styles';
import { statusAttendance } from 'constants/status';
import { toast } from 'react-toastify';
import { localizationTable, options } from 'constants/table';
import { Search } from '@material-ui/icons';
import AttendanceData from 'services/dataService/AttendanceData';

export default () => {
  const [status, setStatus] = useState(1);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const { register, handleSubmit, control } = useForm();

  const tableRef = useRef();
  const history = useHistory();

  const { t } = useTranslation([
    'toastify',
    'tables',
    'form',
    'button',
    'label',
  ]);
  const classes = useStyles();

  const handleAttendance = useCallback(({ id }) => {
    history.push(`attendance/${id}/show`);
  }, []);

  const onSubmit = (data) => {
    setEmail(data.email);
    setStatus(data.status);
    setName(data.name);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <MaterialTable
        title={`${t('tables:Attendance.title')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('tables:Attendance.subject_columm')}`,
            field: 'subject',
          },
          { title: `${t('tables:commons.name_column')}`, field: 'name' },
          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            render: (rowData) => (
              <div style={{ display: 'flex' }}>
                <CircleIcon
                  style={statusAttendance[rowData.status]?.style}
                  fontSize="small"
                />
                <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                  {t(statusAttendance[rowData.status]?.title)}
                </span>
              </div>
            ),
          },
          { title: `${t('tables:commons.email_column')}`, field: 'email' },
          {
            title: `${t('tables:user.created_at_column')}`,
            field: 'createdAt',
            render: (rowData) =>
              format(parseISO(rowData.createdAt), 'dd/MM/yyyy'),
          },
        ]}
        data={async (query) => {
          try {
            const response = await AttendanceData.getAttendances({
              page: query.page + 1,
              limit: query.pageSize,
              status,
              email,
              name,
            });
            return {
              data: response.data.contacts,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        actions={[
          {
            icon: 'visibility',
            tooltip: `${t('tables:Attendance.attedance_action')}`,
            onClick: (event, rowData) => {
              handleAttendance(rowData);
            },
            iconProps: { color: 'primary' },
          },
        ]}
        components={{
          search: false,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_first_name')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:commons.input_main_email')}
                        name="email"
                        type="text"
                        variant="outlined"
                        defaultValue={email || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl style={{ minWidth: 100 }}>
                        <InputLabel id="status">
                          {t('form:commons.input_status')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={1}>
                                {t(statusAttendance[1]?.title)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t(statusAttendance[2]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={status ? status : 1}
                          value={status ? status : 1}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:commons.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
      />
    </>
  );
};
