import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { uploadFailure, vimeoSuccess, uploadFileSuccess } from './actions';
import { VimeoData, FilesData } from 'services/dataService/';

export function* upload({payload}) {
  try {
    const { data, file } = payload;
    const response = yield call(VimeoData.addVimeo, data, file);

    const { url } = response.data;
    yield put(
      vimeoSuccess(url, data.name, data.description)
    );
  } catch (err) {
    toast.error('Error in Vimeo');
    yield put(uploadFailure());
  }
}

export function* uploadFile({ payload }) {
  try {
    const { formData, currentlessonContent, company_course_id } = payload;
    let response = null;
    if (formData.get('file') !== null && formData.get('file') !== undefined) {
      response = yield call(
        FilesData.addFile,
        formData.get('file'),
        `Course/CompanyCourse=${company_course_id}/Lesson/${currentlessonContent}`
      );
    }

    const { id } = response?.data || { id: 'not updated' };
    yield put(
      uploadFileSuccess(id, formData.get('name'), formData.get('description'))
    );
  } catch (err) {
    toast.error('Error in Upload File');
    yield put(uploadFailure());
  }
}

export default all([
  takeLatest('@content/VIMEO_REQUEST', upload),
  takeLatest('@content/UPLOAD_REQUEST', uploadFile),
]);
