import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import Interweave from 'interweave';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/styles';
import { Stack } from '@mui/material';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { set } from 'date-fns';

export default function CreateAttendance({ onSave, attendanceData, selectedAttendanceData }) {
  const [selectedDate, setDate] = useState(moment());
  const [finalDate, setFinalDate] = useState(moment());

  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment());
  const [attendance, setAttendance] = useState({
    name: '',
    local: '',
    description: '',
    instructor: '',
    workload: '',
  });

  const handleOnChange = (e, field) => {
    const updatedAttendance = { ...attendance, [field]: e.target.value };
    setAttendance(updatedAttendance);
    onSave(updatedAttendance);
  };

  useEffect(() => {
    if (selectedAttendanceData) {
      setAttendance({
        name: selectedAttendanceData.restData.name || '',
        local: selectedAttendanceData.restData.local || '',
        description: selectedAttendanceData.restData.description || '',
        instructor: selectedAttendanceData.restData.instructor || '',
        workload: selectedAttendanceData.restData.workload || '',
      });
      if (selectedAttendanceData.restData.finished_at) {
        const parsedDate = moment(selectedAttendanceData.restData.finished_at);
        setDate(parsedDate);
        setFinalDate(parsedDate);
      }
      if (selectedAttendanceData.restData.start_at) {
        const parsedStartDate = moment(selectedAttendanceData.restData.start_at);
        setSelectedStartDate(parsedStartDate);
        setStartDate(parsedStartDate);
      }
    }
  }, [selectedAttendanceData]);

  const onDateChange = (date) => {
    setDate(date);
    const updatedAttendance = { ...attendance, date: date };
    setAttendance(updatedAttendance);
    onSave(updatedAttendance);
  };

  const onStartChange = (date) => {
    setSelectedStartDate(date)
    const updatedAttendance = { ...attendance, startDate: date };
    setAttendance(updatedAttendance);
    onSave(updatedAttendance);
  };

  const { t } = useTranslation(['form']);
  const classes = useStyles();

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <Interweave content={t('form:Attendance.Configuration.text1')} />
            </Typography>
          </Grid>
        </Grid>

        <Box className={classes.spacing} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
          <TextField
              required
              fullWidth
              variant="outlined"
              value={attendance.name}
              onChange={(e) => handleOnChange(e, 'name')}
              label={t('Nome do treinamento')}
              helperText={attendance.name === '' ? 'Campo obrigatório' : ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              onChange={(e) => handleOnChange(e, 'local')}
              variant="outlined"
              value={attendance.local}
              label={t('form:Attendance.Configuration.label3')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputMask
              mask="99:99"
              maskChar=""
              value={attendance.workload}
              onChange={(e) => handleOnChange(e, 'workload')}
            >
            {(inputProps) => (
               <TextField
               {...inputProps}
               fullWidth
               variant="outlined"
               placeholder="00:00"
               label={t('Carga horária')}
             />
            )}
            </InputMask>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexFlow: 'wrap' }}
            ></Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              value={attendance.description}
              variant="outlined"
              onChange={(e) => handleOnChange(e, 'description')}
              label={t('form:Attendance.Configuration.label2')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              value={attendance.instructor}
              variant="outlined"
              onChange={(e) => handleOnChange(e, 'instructor')}
              label={t('Instrutor')}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexFlow: 'wrap' }}
            ></Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexFlow: 'wrap' }}
            ></Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  required
                  fullWidth
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  label="Início do Treinamento"
                  value={selectedStartDate}
                  onChange={onStartChange}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
              <KeyboardTimePicker
                required
                fullWidth
                variant="inline"
                name="startAccessTime"
                ampm={false}
                id="time-picker-start"
                label="Horário Inicial"
                value={startDate}
                onChange={(value) => {
                  setStartDate(value);
                  const updatedAttendance = { ...attendance, startTime: value };
                  setAttendance(updatedAttendance);
                  onSave(updatedAttendance);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexFlow: 'wrap' }}
            ></Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  required
                  fullWidth
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  label="Final do Treinamento"
                  value={selectedDate}
                  onChange={onDateChange}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
              <KeyboardTimePicker
                required
                fullWidth
                variant="inline"
                name="endAccessTime"
                ampm={false}
                id="time-picker"
                label="Horário Final"
                value={finalDate}
                onChange={(value) => {
                  setFinalDate(value);
                  const updatedAttendance = { ...attendance, time: value };
                  setAttendance(updatedAttendance);
                  onSave(updatedAttendance);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexFlow: 'wrap' }}
            ></Stack>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: theme.spacing(4),
  },
}));
