import React from 'react';

import ReportLGPDTable from '../Table';

import { useStyles } from './styles';

export default ({ fields }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ReportLGPDTable fields={fields} />
    </div>
  );
};
