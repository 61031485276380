import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AuthUserNavigation from 'components/NavAuth';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { UserSolicitationData } from 'services/dataService';
import { toast } from 'react-toastify';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export default () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [viewMessage, setViewMessage] = useState(false);
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const onSubmit = useCallback(async (data) => {
    try {
      data.email = data.email.toLowerCase();
      const domain = window.location.hostname;
      await UserSolicitationData.addUserSolicitations({
        ...data,
        domain,
      });
      toast.success(t('toastify:commons.toast_success'));
      setViewMessage(true);
    } catch (error) {
      if (error.response.data.code == "SOLICITATION_ALREADY_EXISTS")
        toast.error(t('toastify:user.toast_solicitation_already_exists'))
      else if (error.response.data.code == "USER_ALREADY_EXISTS")
        toast.error(t('toastify:user.toast_user_already_exists'))
      else
        toast.error(t('toastify:commons.toast_error'));


    }
  }, []);

  return (
    <div className={classes.root}>
         <div className={classes.container}>
         <AuthUserNavigation index={2} />

         {!viewMessage ? (
          <div className={classes.grid}>
            <div className={classes.titleBox}>
              <Typography className={classes.title} variant="h1">
                {t('title:commons.title_solicitation_access')}
              </Typography>
              <Typography className={classes.subtitle} variant="h6">
                {t('title:commons.subtitle_solicitation_access')}
              </Typography>
          </div>

          <div className={classes.formBox}>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Grid item md={12} xs={12} className={classes.fieldGroup}>
                  <TextField
                    fullWidth
                    autoFocus
                    label={t('form:User.input_first_name')}
                    name="name"
                    type="text"
                    variant="outlined"
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.name}
                  />
                </Grid>

                <Grid item md={12} xs={12} className={classes.fieldGroup}>
                  <TextField
                    fullWidth
                    label={t('form:Signin.email')}
                    helperText={
                      (errors.email?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.input_email'),
                        })) ||
                      (errors.email?.type === 'pattern' &&
                        t('validation:commons.validation_email', {
                          field: t('form:Company.input_email'),
                        }))
                    }
                    name="email"
                    type="text"
                    variant="outlined"
                    inputRef={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      },
                    })}
                    error={!!errors.email}
                  />
                </Grid>

                   <TextField
                     fullWidth
                     label={t('form:commons.input_message')}
                     name="message"
                     type="text"
                     variant="outlined"
                     inputRef={register({
                       required: true,
                     })}
                     error={!!errors.message}
                   />


               <Button
                       className={classes.sendButton}
                       color="primary"
                       fullWidth
                       type="submit"
                       size="large"
                       variant="contained"
                     >
                       {t('button:User_solictation.btn_solictation')}
                     </Button>
                   </form>
                 </div>
               </div>
             ) : (
               <div className={classes.messageContainer}>
                 <span className={classes.center}>
                   <CheckCircleOutlineIcon
                     style={{ color: '#00C274', fontSize: 60 }}
                   />
                 </span>

                  <Typography className={classes.title} variant="h1">
                    {t('title:commons.title_solicitation_done')}
                  </Typography>
                  <Typography className={classes.subtitle} variant="h6">
                    {t('title:commons.subtitle_solicitation_done')}
                  </Typography>
                  <Typography className={classes.subtitle} variant="h6">
                    {t('title:commons.await_solicitation')}
                  </Typography>
               </div>
               )}
           </div>
    </div>
  );
};
