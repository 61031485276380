import React, { useEffect, useState } from 'react';
import {
  CarouselProvider,
  Image,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { useStyles } from './styles';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CourseData } from 'services/dataService/index';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export default () => {
  const classes = useStyles();
  const size = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(0);
  const [courses, setCourses] = useState([]);
  const theme = useTheme();
  const { t } = useTranslation(['toastify']);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
    
  });

  const { name, id, group, avatar, email, company_logo, id_company } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    CourseData.getFeaturedCourse()
      .then(({ data }) => {
        setCourses(Array.isArray(data.coursesList) ? data.coursesList : []);
        //setCourses( Array(20).fill(1).map((_, i) => ({id: i, name: `Evento ${i}`, index: i, card: { url_thumbnail: '', }, })) );
      })
      .catch((err) => {
        toast.error(t('toastify:commons.toast_error_api'));
      });
  }, []);

  useEffect(() => {
    isDesktop
      ? setVisibleSlides(Math.trunc((size.width - 336) / 200))
      : setVisibleSlides(Math.trunc(size.width / 200));
  }, [size]);

  
  return (
    <div className={classes.root}>
      {courses.length > 0 ? (
        <>
          <Typography className={[classes.title, classes.highlighted]} variant="h4">
            {id_company === '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5'
              ? t('label:StudentContent.label_highlighted_dream')
              : t('label:StudentContent.label_highlighted')}
          </Typography>

          <CarouselProvider
            visibleSlides={visibleSlides}
            totalSlides={courses.length}
            naturalSlideWidth={50}
            naturalSlideHeight={50}
            hasMasterSpinner
            className={classes.carrossel}
          >
            <ButtonBack className={classes.buttons} style={{left: -15}}>
              <ArrowBackIos />
            </ButtonBack>

            <ButtonNext className={classes.buttons} style={{right: -15}}>
              <ArrowForwardIos />
            </ButtonNext>

            <Slider tabIndex={-1} className={classes.slideContainer}>
              {courses
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((featCourse) => {
                  const url = featCourse.id_company_course !== null
                              ? `course/${featCourse.id_company_course}/enroll` 
                              : `trail/${featCourse.id_trail}/learn`              
                  return (
                    <Slide tabIndex={-1} index={featCourse.index}>
                      <Box>
                        <Link tabIndex={0} to={url} className={classes.slideCard}>
                          <Image
                            className={classes.image}
                            src={featCourse?.card?.url_thumbnail || ''}
                          />
                          <Typography className={classes.text}>
                            {featCourse.name}
                          </Typography>
                        </Link>
                      </Box>
                    </Slide>
                  );
                })}
            </Slider>
          </CarouselProvider>
        </>
      ) : null}
    </div>
  );
};
