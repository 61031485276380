import querystring from 'querystring';
import api from '../api';

class DataServiceUserSolicitations {
  addUserSolicitations(data) {
    return api.post('user-solicitations', data);
  }

  getUserSolicitations(params) {
    const url = 'user-solicitations?' + querystring.stringify(params);
    return api.get(url);
  }

  getUserSolicitationById(id) {
    return api.get(`user-solicitations/${id}`);
  }

  updateUserSolicitation(id, data, headers) {
    return api.put(`user-solicitations/${id}`, data, headers);
  }

  updateMassive(solicitations, id_company) {
    return api.put('/user-solicitations/', { solicitations, id_company });
  }
}

export default new DataServiceUserSolicitations();
