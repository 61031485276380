import React, { useState } from 'react';
import { useStyles } from './styles';
import {
  MenuItem,
  Card,
  CardContent,
  IconButton,
  Menu,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HandleDelete } from 'components/';
import { toast } from 'react-toastify';
import {
  OndemandVideo,
  Duo,
  Folder,
  MenuBook,
  HeadsetMic,
  WebAsset,
  AssignmentTurnedIn,
  Assignment,
  MoreVert,
} from '@material-ui/icons';
import history from 'services/history';

function contentIcon(number) {
  switch (number) {
    case 1:
      return <OndemandVideo fontSize="inherit" />;
      break;
    case 2:
      return <Folder fontSize="inherit" />;
      break;
    case 3:
      return <MenuBook fontSize="inherit" />;
      break;
    case 4:
      return <Duo fontSize="inherit" />;
      break;
    case 5:
      return <HeadsetMic fontSize="inherit" />;
      break;
    case 6:
      return <WebAsset fontSize="inherit" />;
      break;
    case 7:
      return <AssignmentTurnedIn fontSize="inherit" />;
      break;
    case 8:
      return <Assignment fontSize="inherit" />;
      break;
    default:
  }
}

export default (props) => {
  const classes = useStyles();
  const { lesson, deleteContent, setModalUpdate, is_owner } = props;
  const [menuModule, setMenuModule] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const { t } = useTranslation(['tables', 'toastify']);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.headerCard}>
        <div className={classes.iconTitle}>
          {contentIcon(lesson.type)}
          <span className={classes.content}>{lesson.name}</span>
        </div>

        <div>
          {is_owner ? (
            <IconButton
              size="small"
              onClick={(event) => setMenuModule(event.currentTarget)}
            >
              <MoreVert fontSize="inherit" />
            </IconButton>
          ) : null }
          <Menu
            id="simple-menu"
            anchorEl={menuModule}
            keepMounted
            open={Boolean(menuModule)}
            onClose={() => setMenuModule(null)}
          >
            <MenuItem
              onClick={() => {
                setMenuModule(null);
                setModalUpdate(lesson);
                if (lesson?.type === 6)
                  history.push(
                    `/lesson-exam/${lesson?.id}/${lesson.id_lesson}`
                  );
              }}
            >
              {t('button:commons.btn_edit')}
            </MenuItem>

            <MenuItem
              onClick={() => {
                setMenuModule(null);
                setModalStatus(true);
              }}
            >
              {t('tables:group.role_delete')}
            </MenuItem>
          </Menu>
        </div>
      </CardContent>

      {is_owner ? (
        <HandleDelete
          closeModal={setModalStatus}
          isOpen={modalStatus}
          propsHandleConfirm={deleteContent}
        />
      ) : null}

    </Card>
  );
};
