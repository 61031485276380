import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { months, years } from './months';

export function Filters({ data, value, onChange }) {
  const { t } = useTranslation(['cards, label', 'form', 'commons']);
  const [selectedMonths, setSelectedMonths] = useState(value?.months || []);
  const [selectedYears, setSelectedYears] = useState(value?.years || []);

  const monthOptions = useMemo(() => {
    return months.map((item) => ({
      ...item,
      name: t(item.name),
    }));
  }, []);

  const yearOptions = useMemo(() => {
    return years.map((item) => ({
      ...item,
      name: t(item.name),
    }));
  }, []);

  useEffect(() => {
    onChange({ ...value, months: selectedMonths, years: selectedYears });
  }, [selectedMonths, selectedYears]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <Typography style={{ fontWeight: 'bolder', fontSize: '1rem' }}>
          {t('label:admin_dashboard.courses')}
        </Typography>
        <Autocomplete
          fullWidth
          clearOnEscape
          multiple
          id="combo-box-courses"
          options={data?.courses ?? []}
          getOptionLabel={(option) =>option.name}
          value={value?.courses}
          onChange={(event, newValue) =>
            onChange({ ...value, courses: newValue })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              margin="normal"
              label={t('form:commons.input_search')}
              variant="outlined"
              name="name"
              style={{ background: 'white' }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Typography style={{ fontWeight: 'bolder', fontSize: '1rem' }}>
          {t('label:admin_dashboard.teams')}
        </Typography>
        <Autocomplete
          fullWidth
          multiple
          clearOnEscape
          id="combo-box-teams"
          options={data?.teams ?? []}
          getOptionLabel={(option) => option.name}
          value={value?.teams}
          onChange={(event, newValue) =>
            onChange({ ...value, teams: newValue }, console.log(newValue))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              margin="normal"
              label={t('form:commons.input_search')}
              variant="outlined"
              name="name"
              style={{ background: 'white' }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography style={{ fontWeight: 'bolder', fontSize: '1.1rem' }}>
              {t('label:admin_dashboard.month')}
            </Typography>
            <Autocomplete
              fullWidth
              clearOnEscape
              multiple
              id="combo-box-months-1"
              options={monthOptions}
              getOptionLabel={(option) => option.name}
              value={value?.months}
              onChange={(event, newValue) => setSelectedMonths(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  label={t('form:commons.input_search')}
                  variant="outlined"
                  name="name"
                  style={{ background: 'white' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography style={{ fontWeight: 'bolder', fontSize: '1.1rem' }}>
              {t('label:admin_dashboard.years')}
            </Typography>
            <Autocomplete
              fullWidth
              clearOnEscape
              multiple
              id="combo-box-months-2"
              options={yearOptions}
              getOptionLabel={(option) => option.name}
              value={value?.years}
              onChange={(event, newValue) => setSelectedYears(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  label={t('form:commons.input_search')}
                  variant="outlined"
                  name="name"
                  style={{ background: 'white' }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}
