import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { MTableToolbar } from 'material-table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export default function EmailDataTableToolbar(props) {
  const { filter, setFilter, register } = props;
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
    'tables',
  ]);

  return (
    <React.Fragment>
      <MTableToolbar {...props} />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item className={classes.grid} md={3} xs={12}>
            <TextField
              className={classes.fullWidth}
              name="name"
              inputRef={register}
              label={t('form:User.input_first_name')}
              variant="outlined"
              defaultValue={filter.name || ''}
            />
          </Grid>
          <Grid item className={classes.grid} md={3} xs={12}>
            <MuiPickersUtilsProvider locale={pt} utils={DateFnsUtils}>
              <Grid container>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  label={t(
                    'form:Email_template.email_template_search.creation_date_start'
                  )}
                  value={filter.date_begin}
                  onChange={(date) =>
                    setFilter({ ...filter, date_begin: date })
                  }
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item className={classes.grid} md={3} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  label={t(
                    'form:Email_template.email_template_search.creation_date_end'
                  )}
                  value={filter.date_end}
                  onChange={(date) => setFilter({ ...filter, date_end: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item className={classes.grid} md={2} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel>
                {t(
                  'form:Email_template.email_template_search.email_template_status'
                )}
              </InputLabel>
              <Select
                fullWidth
                className={classes.Select}
                llabel={t(
                  'form:Email_template.email_template_search.email_template_status'
                )}
                value={filter.status}
                onChange={(e) => {
                  setFilter({ ...filter, status: e.target.value });
                }}
              >
                <MenuItem value={'1'}>
                  {t('label:commons.label_status_active')}
                </MenuItem>
                <MenuItem value={'0'}>
                  {t('label:commons.label_status_inactive')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            className={classes.grid}
            style={{ display: 'flex' }}
            justify="flex-end"
            md={12}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              {t('button:commons.btn_advanced_filter')}
              <Search />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
}
