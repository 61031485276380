import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import theme from 'theme';

const CompanySolutions = ({ solution }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.topSpacing}>
        <Typography
          className={classes.title}
          style={{ color: solution?.title_color }}
        >
          {solution?.title}
        </Typography>
      </Box>
      <Typography className={classes.topSpacing} />
      <Box>
        <Typography
          className={classes.description}
          style={{ color: solution?.description_color }}
        >
          {solution?.description}
        </Typography>
      </Box>
      <Typography className={classes.topSpacing} />
      <Box>
        <Grid
          container
          spacing={2}
          justify="center"
          className={classes.topSpacing}
        >
          {solution?.ctaButtons
            ?.sort((a, b) => {
              return a?.order > b?.order ? 1 : -1;
            })
            ?.map(({ text, text_color, color, border_color, link }) => (
              <Grid item md={3} sm={4} xs={12} align="center">
                <Link
                  target="_blank"
                  href={link}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    className={classes.btn}
                    style={{
                      backgroundColor: color,
                      borderStyle: 'solid',
                      borderColor: border_color,
                      padding: theme.spacing(3),
                    }}
                  >
                    <Typography component="span" style={{ color: text_color, fontSize: '1.3rem' }}>
                      {text}
                    </Typography>
                  </Button>
                </Link>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(7),
  },
  topSpacing: { paddingTop: theme.spacing(4) },
  title: {
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '120%',
  },

  description: {
    fontWeight: 'bold',
    textAlign: 'center',
    ...theme.typography.h3,
    lineHeight: '100%',
  },
  btn: {
    borderRadius: 0,
    '&:hover': {
      boxShadow: '0px 8px 30px 0px #2A2E4329',
    }
  }
}));

export default CompanySolutions;
