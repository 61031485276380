import { Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight, SystemUpdateAlt } from '@material-ui/icons';
import clsx from 'clsx';
import { ProfileType } from 'constants/types';
import FileSaver from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStyles } from './styles';
import { VerticalAlignBottom } from '@material-ui/icons';
import Fullscreen from '@material-ui/icons/Fullscreen';
import ArrowBackIosSharp from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Close from '@material-ui/icons/Close';
import { Grid } from '@mui/material';
import Typography from 'theme/typography';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const downloadMimeTypes = [
  'vnd.openxmlformats-officedocument',
  'vnd.ms-excel',
  'vnd.ms-word',
  'vnd.ms-powerpoint',
  'msword',
  'png',
  'jpeg',
  'jpg',
  'zip'
];

export default ({
  finishedCourse,
  content,
  courseId,
  prev,
  next,
  hideAnotation,
  userType,
}) => {
  const [isDownloadFile, setIsDownloadFiles] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderNavButtons, setRenderNavButtons] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { t } = useTranslation(['label']);
  const classes = useStyles();
  const history = useHistory();
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const toggleFullscreen = () => {
    const element = document.getElementById('pdf-container');

    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      exitFullscreen();
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen || element.webkitEnterFullscreen) {
        if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.webkitEnterFullscreen) {
          element.webkitEnterFullscreen();
        }
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.webkitSupportsFullscreen) {
        element.webkitEnterFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.webkitSupportsFullscreen) {
      document.webkitCancelFullScreen();
    }
  };

  const handleExitFullscreen = () => {
    exitFullscreen();
    setIsFullscreen(false);
  };

  const onSuccess = () => {
    setPageNumber(1);
    setRenderNavButtons(true);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(+1);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(content.file.url);
      const data = await response.blob();

      const url = URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = url;
      link.download = content.file.name;

      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
    }
  };

  useEffect(() => {
    if (hideAnotation) {
      hideAnotation(false);
    }
  }, []);

  const downloadClick = () => {
    try {
      FileSaver.saveAs(content.file.url, content.file.name);
    } catch (err) {
      toast.error('Não foi possível baixar o arquivo no momento.');
    }
  };

  useEffect(() => {
    finishedCourse({
      finished: true,
      video_last_sec: null,
      audio_last_sec: null,
    });

    let isDownloadFile = false;
    for (const mime of downloadMimeTypes) {
      if (content?.file?.file_type.includes(mime)) {
        isDownloadFile = true;
        break;
      }
    }
    setIsDownloadFiles(isDownloadFile);
  }, []);

  const canDownloadFile = useCallback(
    () => (userType === ProfileType.ADMIN ? true : content?.allow_download),
    []
  );

  useEffect(() => {
    // Função de callback para atualizar as dimensões da janela
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // Adicionar um listener de redimensionamento da janela
    window.addEventListener('resize', handleResize);

    // Remover o listener de redimensionamento da janela ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className={classes.root}>
        <h1 className={classes.title}>{content.name}</h1>
        <h1 className={classes.subtitle}>{content.description}</h1>
        {renderNavButtons && (
          <div className={classes.buttonsPdfFile}>
            <div className={classes.container}>
              <div className={classes.btnActionPDF}>
                {window.innerWidth <= 1280 && (
                  <Button onClick={toggleFullscreen} className={classes.button}>
                    <Fullscreen />
                    Tela Cheia
                  </Button>
                )}
                {canDownloadFile() && (
                  <Button onClick={handleDownload} className={classes.button}
                    style={{ marginLeft: '50px' }}>
                    <VerticalAlignBottom />
                    Download
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
        {content?.file?.file_type === 'application/pdf' ? (
          <>
            <div id='pdf-container' className={`${classes.pdfFile} ${isFullscreen ? classes.fullscreen : ''}`}>
              {!isFullscreen && windowSize.width >= 1080 && (
                <div className={classes.previsousBtnPdf}>
                  <Button
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    variant="primary"
                  >
                    <ChevronLeft />
                  </Button>
                </div>
              )}
              <Document
                language={'pt_BR'}
                renderMode={'canvas'}
                className={classes.pdfContainer}
                id="pdf-container"
                file={content.file.url}
                onContextMenu={(e) => e.preventDefault()}
                onLoadSuccess={({ numPages }) => {
                  setNumPages(numPages);
                  setRenderNavButtons(true);
                  onSuccess();
                }}
              >
                <Page size="A4" pageNumber={pageNumber} width={windowSize.width} height={windowSize.height} />
              </Document>
              {!isFullscreen && windowSize.width >= 1080 && (
                <div className={classes.nextBtnPdf}>
                  <Button
                    disabled={pageNumber === numPages}
                    onClick={nextPage}
                    variant="primary"
                  >
                    <ChevronRight />
                  </Button>
                </div>
              )}
              {isFullscreen && (
                <div className={classes.buttonsContainer}>
                  <div className={classes.fullscreenButton}>
                    <Button onClick={handleExitFullscreen}>
                      <Close style={{ backgroundColor: 'gray' }} />
                    </Button>
                  </div>
                  <div className={`${classes.centeredButtons} d-flex justify-content-center align-items-center`}>
                    <Button disabled={pageNumber <= 1} onClick={previousPage} variant="primary">
                      <ArrowBackIosSharp style={{ backgroundColor: 'gray', width: '30', height: '30' }} />
                    </Button>
                    <Button disabled={pageNumber === numPages} onClick={nextPage} variant="primary">
                      <ArrowForwardIos style={{ backgroundColor: 'gray', width: '30', height: '30' }} />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>

        ) : content?.file?.file_type?.includes('audio/') ? (
          <audio controls className={classes.audio}>
            <source src={content.file.url} type="audio/ogg" />
          </audio>
        ) : isDownloadFile ? (
          <div className={classes.downloadContainer}>
            <Button variant="outlined" onClick={downloadClick}>
              <SystemUpdateAlt /> <span>Baixar documento da aula</span>
            </Button>
          </div>
        ) : null}
      </div>
      <div>
        {!isFullscreen && windowSize.width <= 1080 && (
          <div className={classes.buttonsPdfMobile}>
            <Button
              disabled={pageNumber <= 1}
              onClick={previousPage}
              variant="primary"
            >
              <ChevronLeft />
            </Button>
            <Button
              disabled={pageNumber === numPages}
              onClick={nextPage}
              variant="primary"
            >
              <ChevronRight />
            </Button>
          </div>
        )}
      </div>
      <div className={classes.buttons}>
        <button
          onClick={() => history.push(`/course/${courseId}/learn/${prev}`)}
          disabled={prev == null}
        >
          {' '}
          <ChevronLeft /> Anterior
        </button>
        <button
          onClick={() => history.push(`/course/${courseId}/learn/${next}`)}
          disabled={next == null}
          className={clsx([classes.buttonsActive])}
        >
          Próximo <ChevronRight />
        </button>
      </div>
    </div>
  );
};