import { Box, Grid, Typography } from '@material-ui/core';
import useWindowDimensions from 'customHooks/DimensionHook';
import Interweave from 'interweave';
import React from 'react';
import { useStyles } from './styles';

const KnowledgeTrailDescription = ({ data, icon }) => {
  const { width } = useWindowDimensions();
  const classes = useStyles();

  return (
    <Box className={width > 960 ? classes.root : classes.mobileRoot}>
      <Grid container className={classes.containerDescription}>
        <Grid item xs={12} md={6} lg={6} className={classes.gridImage}>
          <img
            className={classes.logo}
            src={icon ? icon : '/images/no-image.png'}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          className={classes.overflowDescription}
        >
          <Box className={classes.boxTrailTitle}>
            <Typography component="span" className={classes.trailTitle}>
              {data?.name}
            </Typography>
          </Box>

          <Box className={classes.trailDescriptionBox}>
            <Typography className={classes.trailDescription}>
              <Interweave content={data?.description} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KnowledgeTrailDescription;
