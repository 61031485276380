import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},

  rowStatus: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    // alignContent: 'center',
    // marginTop: theme.spacing(6),
    // marginBottom: theme.spacing(6),
  },

  searchInput: {
    marginRight: theme.spacing(1),
  },

  overviewUserCard: {
    width: '19%',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  overviewPlatformCard: {
    width: '22%',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  overviewCompanyCard: {
    width: '30%',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  overviewUserSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  overviewPlatformSummary: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  periodChoiceButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },

  choiceButtons: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },

  greenIcon: {
    backgroundColor: '#2DCE98 !important',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C !important',
  },

  blueIcon: {
    backgroundColor: '#176AE6 !important',
  },

  redIcon: {
    backgroundColor: '#F53C56 !important',
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  overviewRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      flexDirection: 'column',
      alignItems: 'normal',
    },
  },

  quickAccessContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },

  topButtons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'space-around',
      width: '100%',
      marginTop: theme.spacing(1)
    },
  },

  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
