import React, {
  forwardRef,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, Slide } from '@material-ui/core';
import { localizationTable } from 'constants/table';
import MaterialTable from 'material-table';
import { LessonContentData } from 'services/dataService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ isOpen, closeModal, enrollment }) {
  const { t } = useTranslation(['toastify', 'modal', 'button', 'tables']);
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const handleClose = () => {
    closeModal(false);
  };

  const releaseNewExamAttempt = useCallback(
    async (exam) => {
      try {
        await LessonContentData.releaseNewExamAttempt(
          enrollment.id_user,
          exam?.id
        );
        toast.success(t('toastify:Exam.new_exam_attempt'));
        closeModal(false);
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
        closeModal(false);
      }
    },
    [enrollment]
  );

  const listExams = useCallback(async () => {
    if (enrollment) {
      const exams = await LessonContentData.getCourseExamsWithScore(
        enrollment.id_user,
        enrollment.id_company_course
      );

      const newExams = exams.data.map((exam) => ({
        id: exam.id,
        name: exam.name,
        score: exam.lessonContentExams[0].score,
      }));

      setData(newExams);
    }
  }, [setData, enrollment]);

  useEffect(() => {
    listExams();
  }, [listExams]);

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <MaterialTable
            title={`${t('modal:course_evaluations.title')}`}
            ref={tableRef}
            data={data}
            columns={[
              {
                title: `${t(
                  'tables:reports.course.course_evaluations_column'
                )}`,
                field: 'name',
              },
              {
                title: `${t('tables:reports.academic.grade_column')}`,
                field: 'score',
                render: (rowData) => (
                  <span>{(parseFloat(rowData?.score) * 100).toFixed(2)} %</span>
                ),
              },
            ]}
            actions={[
              (rowTable) => ({
                icon: 'sync',
                tooltip: `${t('button:Course.btn_new_exam')}`,
                onClick: (event, rowData) => releaseNewExamAttempt(rowData),
                iconProps: {
                  color:
                    parseFloat(rowTable?.score) * 100 === 100
                      ? 'disabled'
                      : 'primary',
                },
                disabled: parseFloat(rowTable?.score) * 100 === 100,
              }),
            ]}
            localization={localizationTable(t)}
            options={{
              actionsColumnIndex: -1,
              search: false,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0 ? '#F6F9FC' : '#FFFF',
              }),
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
