import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import Interweave from 'interweave';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
export default function InformationPanel(props) {
  const { content } = props;
  const classes = useStyles();
  const { t } = useTranslation(['form']);
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography component="p" className={classes.strongText}>
          {t('form:Email_template.email_instructions.text')}:
        </Typography>
        {/* mostra o conteúdo de instruções do email */}
        {content.fields.map((instruction, index) => {
          return (
            <Typography key={index} className={classes.text} component="p">
              <Typography component="span" className={classes.strongText}>
                {instruction.name + " "}
              </Typography>
              - {instruction.description}
            </Typography>
          );
        })}
        <Typography className={clsx(classes.topSpacing)} component="p">
          <Interweave content={content.example} />
        </Typography>
      </div>
    </React.Fragment>
  );
}
