import api from '../api';
import axios from 'axios';

class DataServiceFiles {

  async addFile(file, fileContext) {

    const { name: fileName, type: fileType } = file
    const { data: signS3Response } = await DataServiceFiles.signS3(fileName, fileType, fileContext);

    var options = {
      headers: {
        'Content-Type': fileType
      }
    };

    const responseUploadS3 = await axios.put(signS3Response.signedUrl, file, options)
    if(responseUploadS3.status != 200){
      throw Error('error uploading file to S3');
    }

    if(fileType && fileType.startsWith("image/")){
      DataServiceFiles.generateThumb(signS3Response.path);
    };

    return api.post("files", {
      name: signS3Response.name,
      path: signS3Response.path,
      contentType: fileType
    });
  }

  static signS3(fileName, fileType, fileContext) {
    return api.post(`files/signS3`, { fileContext, fileName, fileType });
  }

  static generateThumb(s3Path) {
    return api.post(`files/generateThumbnail`, { s3Path });
  }

  getFile(idFile) {
    return api.get(`files/${idFile}`);
  }

  deleteFile(idFile) {
    return api.delete(`files/${idFile}`);
  }
  convertFile(data) {
    return api.post('file/convert', data);
  }
}

export default new DataServiceFiles();