import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px 8px 0 0',
    backgroundColor: '#2255FF',
    boxShadow: 'inset 0 0 0em white, 0 0 2em #ccc',
    flexGrow: 1,
    padding: "10px 10px 0 10px",
  },
  btn: {
    width: '13rem',
    marginRight: theme.spacing(1),
  },
}));
