import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AdminDashboardData from 'services/dataService/AdminDashboardData';
import Charts from './components/Charts';
import Filters from './components/Filters';
import Header from './components/Header';
import Table from './components/Table';
import TopIndicators from './components/TopIndicators';
import { useStyles } from './styles';
import Loading from './components/Loading';

import { useReactToPrint } from 'react-to-print';

export function AdminDashboardView() {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    courses: [],
    teams: [],
    months: [],
    years: [],
  });

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  const loadData = async () => {
    const formattedFilters = {
      people: filters?.people?.map((item) => item.id) || [],
      courses: Array.isArray(filters?.courses) ? filters.courses.map((item) => item.id) : [],
      teams: Array.isArray(filters?.teams) ? filters.teams.map((item) => item.id) : [],
      months: Array.isArray(filters?.months) ? filters.months.map((item) => item.id) : [],
      years: Array.isArray(filters?.years) ? filters.years.map((item) => item.id) : [],

    };

    try {
      const filtersData = (
        await AdminDashboardData.getFilters()
      )?.data;
      const indicators = (
        await AdminDashboardData.getIndicators(formattedFilters)
      )?.data;
      const courses = (await AdminDashboardData.getCourses(formattedFilters))
        ?.data;
      const people = (await AdminDashboardData.getPeople(formattedFilters))
        ?.data;
      const teams = (await AdminDashboardData.getTeams(formattedFilters))
        ?.data;
      const conclusions = (
        await AdminDashboardData.getConclusions(formattedFilters)
      )?.data;
      setData({
        filters: filtersData,
        indicators,
        courses,
        people,
        teams,
        conclusions,
      });

    } catch (error) {
      toast.error('Error while trying to loading admin dashboard.');
    }
  };

  useEffect(() => {
    loadData();
  }, [filters]);

  return (
    <div className={classes.root}>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <Header onDownloadClick={handlePrint} />
          <Filters
            filters={filters}
            onChange={(newFilters) => {
              setFilters(newFilters);
              if (newFilters.teams && newFilters.teams.length > 0) {
                setSelectedTeamId(newFilters.teams[0].id);
              } else {
                setSelectedTeamId(null); // Nenhum time selecionado
              }
            }}
            data={data?.filters}
          />
          <div ref={printRef}>
            <TopIndicators classes={classes} data={data?.indicators} />
            <Charts
              courses={data?.courses}
              people={data?.people}
              teams={data?.teams}
              indicators={data?.indicators}
              conclusions={data?.conclusions}
              selectedTeamId={selectedTeamId}
            />
          </div>
          {/* <Table externalFilters={filters} /> */}
        </>
      )}
    </div>
  );
  
}
