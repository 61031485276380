import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titlePag: {
    textAlign: 'center',
    padding: '40px'
  },
  teste: {
    width: '60%', 
    marginBottom: theme.spacing(2), 
    [theme.breakpoints.down('sm')]: {
      width: '90%', 
    },
  },
  categoriesTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '15px',
  },
  coursesTitle: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    margin: '8px 0',
  },
  listAccordion: {
    minHeight: '5%',
    maxHeight: '5%%',
    paddingLeft: theme.spacing(2),
    backgroundColor: '#f5f6f8',
  },
  iconVideo: {
    marginRight: '10px'
  },
  classOutlinedIcon: {
    fontSize: '18px',
    marginRight: '8px'
  },
  iconAndText: {
    display: 'flex',
    alignItems: 'center', 
    marginRight: '8px',  
    marginLeft: '5px'
  },
  boxContent: {
    paddingTop: '25px'
  },
  workloadText: {
    paddingBottom: '15px'
  },
 btnBack: {
    alignSelf: 'flex-start',
    marginTop: '1.6rem',
    marginLeft: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1.2rem',
    },
  },
  buttonTop: {
    marginRight: '5px',
    position: 'fixed',
    bottom: '18%',
    right: '2%',
    zIndex: 999,
    backgroundColor: '#1a67e7',
    borderRadius: '65%',
    '&:hover': {
      overflow: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '25px',
      bottom: '12%',
    },
  },
}));
