import { Button, CardContent, Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { statusCategories } from 'constants/status';
import { localizationTable, options } from 'constants/table';
import useWindowDimensions from 'customHooks/DimensionHook';
import MaterialTable, { MTablePagination, MTableToolbar } from 'material-table';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TeamData from 'services/dataService/TeamData';

export default React.forwardRef((props, ref) => {
  const { t } = useTranslation(['tables', 'form', 'button', 'toastify']);
  const { id } = useParams();
  const { width } = useWindowDimensions();
  const { register, handleSubmit, control } = useForm();
  const [data, setData] = React.useState([]);
  const [filterNames, setFilterNames] = React.useState([]);

  const getData = async (filter) => {
    try {
      const teamFilter = { ...filter };
      const teamData = await TeamData.getStudentTeams(id, teamFilter);
      const filterNames = await TeamData.getTeamsName(id);
      setData(teamData.data.teams);
      setFilterNames(filterNames.data);
    } catch (err) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  React.useImperativeHandle(ref, () => ({
    updateTable() {
      getData();
    },
  }));

  const filterTable = (data) => {
    getData(data);
  };

  return (
    <div>
      <MaterialTable
        title={`${t('tables:Teams.team_name')}`}
        tableRef={ref}
        columns={[
          {
            title: `${t('tables:Teams.team_name')}`,
            field: `name`,
          },

          {
            title: `${t('tables:Teams.courses')}`,
            field: `Courses.length`,
            render: (rowData) => rowData?.Courses?.length,
          },
          {
            title: `${t('tables:Teams.users')}`,
            field: `users`,
            // render: (rowData) => rowData?.Users?.length,
          },
          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            render: (rowData) =>
              t(statusCategories[`${rowData.status}`]?.title),
          },
          {
            title: `${t('tables:Teams.description')}`,
            field: 'description',
          },
        ]}
        data={data}
        actions={
          [
            // {
            //   icon: 'visibility',
            //   tooltip: `${t('button:commons.btn_know_more')}`,
            //   onClick: (event, rowData) => {
            //     console.log('');
            //   },
            //   iconProps: { color: 'primary' },
            // },
          ]
        }
        components={{
          search: false,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <CardContent>
                <form onSubmit={handleSubmit(filterTable)}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        clearOnEscape
                        id="combo-box-demo"
                        options={filterNames}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            margin="normal"
                            label={t('form:Teams.input_name')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            name="name"
                            control={control}
                            inputRef={register}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: 'flex',
                        justifyContent: width > 960 ? 'flex-end' : 'flex-start',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submmit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={{ ...options, emptyRowsWhenPaging: false }}
      />
    </div>
  );
});
