import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10%',
  },
  categoryHeader: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize * 2,
    lineHeight: '100%',
  },
  titleBox: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(0),
    marginTop: theme.spacing(3),
  },
  cardBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
