import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  button: {
    textTransform: 'capitalize',
    width: '100%',
  },
}));
