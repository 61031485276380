import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Grid, Typography, Button } from '@material-ui/core';
import { ViewList, ImportExport } from '@material-ui/icons';
import {
  PeopleOutlined,
  PersonOutline,
  DescriptionOutlined,
  AttachMoney,
  SchoolOutlined,
  AssignmentTurnedInOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { localizationTable, options } from 'constants/table';
import { useStyles } from './styles';
import { Card } from 'components/Toolbar/';
import { CourseData, CompanyData } from 'services/dataService';
import { statusCourses } from 'constants/status';
import { periodFilter } from 'constants/types';
import ModalPlatform from 'components/RatingPlatform/components/ModalPlatform/Index';
import { useSelector } from 'react-redux';
import { RatingData } from 'services/dataService';


export default (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const { company, auth } = useSelector((state) => state);
  const { id: userId } = auth;
  const [period, setPeriod] = useState(periodFilter.all);
  const [openRating, setOpenRating] = React.useState(false);
  const [periodButtons, setPeriodButtons] = useState({
    all: true,
    monthly: false,
    weekly: false,
    daily: false,
  });

  const { type } = props;
  const history = useHistory();
  const tableRef = useRef();
  const classes = useStyles();

  const { t } = useTranslation([
    'toastify',
    'tables',
    'button',
    'title',
    'cards',
  ]);

  const handlePeriodButtonsChoice = useCallback(
    (choice) => {
      setPeriod(periodFilter[choice]);

      setPeriodButtons({
        [choice]: !periodButtons[choice],
      });
    },
    [periodButtons, period]
  );

  const fetchData = async () => {
    try {
      setDisabledButtons(true);

      const response = await CompanyData.getMenuDashboard({
        dashboard_company_type: type,
        period_filter: period,
      });

      setData(response.data);

      if (tableRef && tableRef.current) {
        tableRef.current.state.query.page = 0;
        tableRef.current.onQueryChange();
      }
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    } finally {
      setLoading(false);
      setDisabledButtons(false);
    }
  };

  useEffect(() => {
    fetchData();
    // onShouldOpenModalRating();
  }, [period]);

  // const handleRoutes = (route) => {
  //   history.push(route);
  // };

  // const onShouldOpenModalRating = async () => {
  //   try {
  //     const dateNow = format(new Date(), 'MM-yyyy');
  //     const response = await RatingData.hasRatingPlatform(dateNow, userId );
  //     if (response && response.data.shouldOpenModal) {
  //       setOpenRating(true)
  //     }
  //   } catch (error) {
  //     console.error('Erro ao verificar se o modal deve ser aberto:', error);
  //   }
  // };

  if (!loading)
    return (
      <>
       {/* {openRating && (company?.domain_company !== '2a48df24-a717-4934-9513-56c036d2f4d3') && (
              <ModalPlatform
                setOpen={setOpenRating}
                company={company?.domain_company}
                user={userId}
              />
              )} */}
        {/* <Grid container className={classes.overviewRoot}>
          <div className={classes.overviewPeriod}>
            <Typography variant="h5" component="h5">
              {t('title:Dashboard.title_mother_overview')}
            </Typography>

          <span  style={{display: 'none'}}>
          <span className={[classes.periodChoiceButtons]}>
              <span className={classes.choiceButtons}>
                <Button
                  variant={
                    periodButtons['all'] === true ? 'contained' : 'outlined'
                  }
                  disabled={disabledButtons}
                  onClick={() => handlePeriodButtonsChoice('all')}
                  color="primary"
                  size="small"
                  className={classes.choiceButtons}
                >
                  {t('button:Dashboard.commons.btn_all_info')}
                </Button>
              </span>
              <span className={classes.choiceButtons}>
                <Button
                  variant={
                    periodButtons['monthly'] === true ? 'contained' : 'outlined'
                  }
                  disabled={disabledButtons}
                  onClick={() => handlePeriodButtonsChoice('monthly')}
                  color="primary"
                  size="small"
                  className={classes.choiceButtons}
                >
                  {t('button:Dashboard.commons.btn_monthly_info')}
                </Button>
              </span>
              <span className={classes.choiceButtons}>
                <Button
                  variant={
                    periodButtons['weekly'] === true ? 'contained' : 'outlined'
                  }
                  disabled={disabledButtons}
                  onClick={() => handlePeriodButtonsChoice('weekly')}
                  color="primary"
                  size="small"
                  className={classes.choiceButtons}
                >
                  {t('button:Dashboard.commons.btn_weekly_info')}
                </Button>
              </span>

                <Button
                  variant={
                    periodButtons['daily'] === true ? 'contained' : 'outlined'
                  }
                  disabled={disabledButtons}
                  onClick={() => handlePeriodButtonsChoice('daily')}
                  color="primary"
                  size="small"
                >
                  <span className='notranslate'>{t('button:Dashboard.commons.btn_daily_info')}</span>
                </Button>
              </span>
          </span>

          </div>
          

          <div className={classes.overviewUserSummary}>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_online_students')}
                number={data.user_online}
                icon={PersonOutline}
                iconStyle={classes.greenIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_users')}
                number={data.users}
                infoNumber={3.12}
                icon={PeopleOutlined}
                iconStyle={classes.blackIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_active_users')}
                number={data.user_active}
                infoNumber={-3.12}
                icon={PersonOutline}
                iconStyle={classes.blueIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_blocked_users')}
                number={data.user_disable}
                infoNumber={3.12}
                icon={PersonOutline}
                iconStyle={classes.redIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_sales')}
                number={'-'}
                infoNumber={3.12}
                icon={AttachMoney}
                iconStyle={classes.redIcon}
              />
            </span>
          </div>
          <div className={classes.overviewUserSummary}>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_courses')}
                number={data.courses}
                infoNumber={3.12}
                icon={DescriptionOutlined}
                iconStyle={classes.greenIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_in_production_courses')}
                number={data.courses_production}
                infoNumber={3.12}
                icon={DescriptionOutlined}
                iconStyle={classes.blackIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_enrollment')}
                number={data.enrollments}
                infoNumber={-3.12}
                icon={AssignmentTurnedInOutlined}
                iconStyle={classes.blueIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:course.card_courses_contenters')}
                number={data.teachers}
                infoNumber={3.12}
                icon={SchoolOutlined}
                iconStyle={classes.blueIcon}
              />
            </span>
            <span className={classes.overviewUserCard}>
              <Card
                title={t('cards:commons.card_volume')}
                number={'-'}
                infoNumber={3.12}
                icon={AttachMoney}
                iconStyle={classes.redIcon}
              />
            </span>
          </div>
        </Grid> */}

        <Grid container className={classes.overviewTableContainer}>
          <iframe
            src="https://crescamais.com/atualizacoes-e-novidades/"
            frameborder="0"
          />
        </Grid>
      </>
    );
  return <></>;
};
