import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomHelper from '../CustomHelperText';
import CompanyEventButton from './components';

const CompanyEvents = ({ company_events, form }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views', 'button']);
  const { register, watch } = form;
  const [showContent, setShowContent] = React.useState(true);
  const [showEventButton, setShowEventButton] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [show, setShow] = React.useState(true);
  const [refresh, setRefresh] = React.useState(false);

  const values = watch('company_events');

  React.useEffect(
    () => {
      setShowEventButton(company_events?.event_buttons?.length ? true : false);
    },
    company_events,
    values
  );
  return (
    <Box>
      <Box className={classes.headerRoot}>
        <Box style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.header} color="textSecondary">
            {t('views:CompanyCustomization.event_header_title')}
          </Typography>
        </Box>
        <Box style={{ width: '10%' }}>
          <Switch
            checked={showContent}
            onChange={(_, checked) => {
              setShowContent(checked);
            }}
          />
        </Box>
      </Box>
      {showContent && (
        <Box className={classes.contentRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                variant="outlined"
                name="company_events.title"
                defaultValue={company_events?.title}
                label={t('views:CompanyCustomization.title')}
                inputRef={register}
              />
              <CustomHelper
                text={t('views:CompanyCustomization.event_button_title')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomColorPicker
                name="company_events.title_color"
                defaultValue={company_events?.title_color}
                register={register}
                title={t('views:CompanyCustomization.color')}
              />
              <CustomHelper
                text={t('views:CompanyCustomization.title_color_description')}
              />
            </Grid>
          </Grid>
          <Box className={classes.contentRoot} style={{ display: 'flex' }}>
            <Box style={{ width: '37%' }}>
              <Typography className={classes.spanHeader}>
                {t('views:CompanyCustomization.event_button')}
              </Typography>
              <CustomHelper
                text={t('views:CompanyCustomization.event_button_helper')}
              />
            </Box>
            <Box style={{ width: '10%' }}>
              <Switch
                color="secondary"
                checked={showEventButton}
                onChange={(_, checked) => {
                  setShowEventButton(checked);
                }}
              />
            </Box>
          </Box>
          {showEventButton && (
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box className={classes.colorTopSpacing}>
                  {selected.length > 0 && (
                    <Box>
                      <Button
                        color="primary"
                        onClick={() => {
                          setShow(false);
                          // console.log(company_events.event_buttons);
                          company_events.event_buttons = values?.event_buttons?.filter(
                            (btn, index) => !selected.includes(index)
                          );

                          setSelected([]);
                          setRefresh(!refresh);
                          setTimeout(() => {
                            setShow(true);
                          }, 200);
                        }}
                      >
                        {t('button:CompanyCustomization.remove_selected')}
                      </Button>
                    </Box>
                  )}
                  {show && (
                    <Grid container>
                      {company_events?.event_buttons?.map(
                        (event_button, index) => (
                          <Box
                            width="100%"
                            className={
                              selected.includes(index) && classes.selected
                            }
                          >
                            <Grid container>
                              <Typography className={classes.colorTopSpacing} />
                              <Box width="100%">
                                <Checkbox
                                  checked={selected.includes(index)}
                                  onChange={(e, checked) => {
                                    if (checked) {
                                      selected.push(index);
                                    } else {
                                      setSelected(
                                        selected.filter((i) => i !== index)
                                      );
                                    }
                                    setRefresh(!refresh);
                                  }}
                                />
                              </Box>

                              <CompanyEventButton
                                event_button={event_button}
                                index={index}
                                register={register}
                                selected={selected}
                              />
                            </Grid>
                          </Box>
                        )
                      )}
                    </Grid>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  className={clsx(
                    classes.contentRoot,
                    classes.headerRoot,
                    classes.eventButtonCenter
                  )}
                >
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      company_events.event_buttons.push({
                        text: '',
                        color: '#000000',
                        text_color: '#000000',
                        border_color: '#000000',
                        link: '',
                      });
                      setRefresh(!refresh);
                    }}
                  >
                    <Add fontSize="large" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
  },
  header: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  contentRoot: {
    paddingTop: theme.spacing(3),
  },
  eventButtonSpacing: {
    padding: theme.spacing(2),
  },
  eventButtonCenter: {
    justifyContent: 'center',
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  courseIconButton: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(3),
  },
  courseIcon: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    boxShadow: '0px 1px 15px 1px',
    [theme.breakpoints.down('md')]: {
      width: 80,
      height: 80,
    },
  },
  selected: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.default.light,
    marginBottom: theme.spacing(2),
  },
}));

export default CompanyEvents;
