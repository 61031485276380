import { Button, Grid, Typography } from '@material-ui/core';
import { AddCircle, ImportExport } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserSolicitationList } from 'views/UserSolicitation/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();
  const { t } = useTranslation(['button', 'label']);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          spacing={1}
          direction="row"
          className={classes.gridContainer}
        >
          <Grid item md={4} xs={12}>
            <Typography variant="h4">
              {t('label:commons.label_access_quick')}:
            </Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <div className={classes.row}>
              <span className={classes.spacer} />
              <Link to="/users/create">
                <Button color="primary" variant="contained">
                  <AddCircle style={{ marginRight: '5px' }} />
                   <span className='notranslate'>{t('button:commons.btn_add')}</span>
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <UserSolicitationList />
      </div>
    </div>
  );
};
