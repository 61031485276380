import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import EvaluationList from './components/EvaluationList';
import ExamComponentToolbar from './components/Toolbar';

const ExamComponent = () => {
  const classes = useStyles();
  const [numberOfAttempts, setNumberOfAttempts] = useState(3);

  return (
    <Box className={classes.root}>
      <Box>
        <ExamComponentToolbar
          numberOfAttempts={numberOfAttempts}
          setNumberOfAttempts={setNumberOfAttempts}
        />
        <EvaluationList
          numberOfAttempts={numberOfAttempts}
          setNumberOfAttempts={setNumberOfAttempts}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default ExamComponent;
