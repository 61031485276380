import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import CoursesSlider from 'components/CoursesSlider';
import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CourseData } from 'services/dataService';
import CompanyCustomizationData from 'services/dataService/CompanyCustomizationData';
import FavorityCourseData from 'services/dataService/FavorityCourseData';
import history from 'services/history';
import FilterSelect from './components/FilterSelect';
import SearchTextfield from './SearchTextfield';
import { useStyles } from './styles';

let debounce;
const AvalibleContent = ({ hideSearch }) => {
  const classes = useStyles();
  const { auth } = useSelector((state) => state);
  const [refresh, setRefresh] = React.useState(false);
  const { id } = useSelector((state) => state?.auth);
  const { t } = useTranslation(['form', 'views', 'toastify']);
  const [banner, setBanner] = React.useState('');
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [search, setSearch] = React.useState([]);
  const [searchAttributes, setSearchAttributes] = React.useState({
    text: '',
    category: '',
    subCategory: '',
  });
  const [loading, setLoading] = React.useState(true);
  const categoryRef = React.useRef([]);

  React.useEffect(() => {
    getBanner();
    getContent();
  }, []);

  const getContent = async () => {
    try {
      const { data: content } = await CourseData.getStudentAvalibleContent(
        auth?.id
      );
      setCategories(content.courses);
      categoryRef.current = content.courses;
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (e) {
      setLoading(false);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const getBanner = async () => {
    const {
      data: customization,
    } = await CompanyCustomizationData.getCompanyCustomization();
    setBanner(customization?.company_banner?.banner?.url || '');
  };

  const onChangeTextSearch = (courseName) => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      setSearchAttributes({ ...searchAttributes, text: courseName });
    }, 600);
  };

  const onEnterPress = (courseName) => {
    clearTimeout(debounce);
    setSearchAttributes({ ...searchAttributes, text: courseName });
  };

  const onChangeCategory = (categoryName) => {
    setSubCategories(findSubCategoriesBy(categoryName));
    setSearchAttributes({
      ...searchAttributes,
      category: categoryName,
      subCategory: '',
    });
  };

  const onChangeSubCategory = (subCategoryName) => {
    setSearchAttributes({ ...searchAttributes, subCategory: subCategoryName });
  };

  const findSubCategoriesBy = (categoryName) => {
    const [cat] = categoryRef.current.filter((category) => {
      return categoryName === category.name;
    });

    // return when select all categories in combo box
    if (!cat) return [];

    const subs = cat.subCategories
      .filter((subs) => subs.subCategory)
      .map(({ subCategory }) => subCategory);

    let uniqueSubs = [];

    subs.forEach((sub) => {
      const isRepeatSub = uniqueSubs.some(
        (subRetirada) => subRetirada.id === sub.id
      );

      if (!isRepeatSub) uniqueSubs.push(sub);
    });

    return uniqueSubs;
  };

  const favorityCourse = async (course_id, liked) => {
    try {
      if (liked) {
        await FavorityCourseData.favorityCourse(id, course_id);
      } else {
        await FavorityCourseData.removeCourseFavority(id, course_id);
      }
      categories.forEach((cat) => {
        cat.categoryCourses = cat.categoryCourses.map((course) => {
          if (course_id === course.id_company_course) {
            return {
              ...course,
              liked_course: liked,
            };
          } else {
            return { ...course };
          }
        });
      });
      setRefresh(!refresh);
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const actionClick = async (id_company_course, assigned) => {
    if (assigned) {
      history.replace(`/course/${id_company_course}/learn`);
    } else {
      history.push(`/course/${id_company_course}/enroll`);
    }
  };

  React.useEffect(() => {
    const filterCoursesBySearch = () => {
      const { text, category, subCategory } = searchAttributes;

      const isAllCourses =
        !text && (!category || category === 'all') && !subCategory;

      if (isAllCourses) {
        setSearch([]);
        return;
      }

      let matchCourses = [...categories];

      if (category && category !== 'all')
        matchCourses = filterCategory(category, matchCourses);

      if (subCategory && subCategory !== 'all')
        matchCourses = filterSubCategory(subCategory, matchCourses);

      if (text) matchCourses = filterCourseName(text, matchCourses);

      if (matchCourses.length) {
        setSearch([
          {
            ...matchCourses[0],
            name: matchCourses[0].name || '',
            categoryCourses: matchCourses[0].categoryCourses,
          },
        ]);
      } else {
        setSearch([]);
      }
    };

    const removeAccents = (text) => {
      const accents =
        'ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖØòóôõöøÙÚÛÜùúûüÝýÿÑñÇç';
      const plain =
        'AAAAAAaaaaaaEEEEeeeeIIIIiiiiOOOOOOooooooUUUUuuuuYYyyNnCc';
    
      const removeAccentsMap = new Map();
    
      for (let i = 0; i < accents.length; i++) {
        removeAccentsMap.set(accents.charAt(i), plain.charAt(i));
      }
    
      return text.replace(/[À-ÿ]/g, (match) => removeAccentsMap.get(match) || match);
    };
    

    const filterCategory = (categoryName, categories) => {
      return categories.filter((category) => categoryName === category.name);
    };
    
    const filterCourseName = (courseName, categories) => {
      let coursesList = [];
      const cleanCourseName = removeAccents(courseName || '').toLowerCase();
    
      categories.forEach((cat) => {
        coursesList.push(
          ...cat.categoryCourses.filter((course) => {
            const cleanCourse = removeAccents(course?.name || '').toLowerCase();
            return cleanCourse.includes(cleanCourseName);
          })
        );
      });
    
      if (!coursesList.length) {
        return [];
      }
    
      const categoryName = categories.length === 1 ? categories[0].name : '';
      return [{ name: categoryName, categoryCourses: coursesList }];
    };

    const filterSubCategory = (subCategoryName, categories) => {
      const subCategoryCourses = categories[0].categoryCourses.filter(
        (course) =>
          course?.subCategory?.toLowerCase() === subCategoryName.toLowerCase()
      );
      return [{ ...categories[0], categoryCourses: subCategoryCourses }];
    };

    filterCoursesBySearch();
  }, [categories, searchAttributes]);

  return (
    <>
    <div className='notranslate'>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <Box className={classes.root}>
          {!hideSearch ? (
            <Box
              className={classes.header}
              style={{
                backgroundImage: banner
                  ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${banner})`
                  : '',
              }}
            >
              <Grid
                container
                direction="column"
                justify="center"
                className={classes.searchFields}
              >
                <Grid item xs={12} md={12}>
                  <SearchTextfield
                    placeholder={t('views:AvalibleContent.header_field')}
                    onChange={onChangeTextSearch}
                    onEnterPress={onEnterPress}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  className={classes.selectContainer}
                >
                  <Grid item xs={12}>
                    <FilterSelect
                      text={t('form:Categories.input_all_category')}
                      value={searchAttributes.category}
                      contentList={categories}
                      callback={onChangeCategory}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilterSelect
                      text={t('form:Subcategories.input_all_subcategory')}
                      value={searchAttributes.subCategory}
                      contentList={subCategories}
                      disabled={
                        !searchAttributes.category ||
                        searchAttributes.category === 'all'
                      }
                      callback={onChangeSubCategory}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ) : null}
          <Box className={classes.container}>
            {search.length ? (
              search.map((cat, index) => (
                <Box key={`${cat.name}_${index}`}>
                  <Box className={classes.titleBox}>
                    <Typography
                      component="h2"
                      className={classes.categoryHeader}
                    >
                      {cat?.name}
                    </Typography>
                  </Box>
                  <Box className={classes.cardBox}>
                    <CoursesSlider
                      refresh={refresh}
                      onLike={favorityCourse}
                      onActionClick={actionClick}
                      courses={cat.categoryCourses}
                    />
                  </Box>
                </Box>
              ))
            ) : searchAttributes.text.length && !search.length ? (
              <Box className={classes.titleBox}>
                <Typography component="p" className={classes.searchHeader}>
                  <Interweave
                    content={t('views:AvalibleContent.not_found', {
                      search: searchAttributes.text,
                    })}
                  />
                </Typography>
              </Box>
            ) : (
              categories.map((cat, index) => (
                <Box key={`${cat.name}_${index}`}>
                  <Box className={classes.titleBox}>
                    <Typography
                      component="h2"
                      className={classes.categoryHeader}
                    >
                      {cat?.name}
                    </Typography>
                  </Box>
                  <Box className={classes.cardBox}>
                    <CoursesSlider
                      refresh={refresh}
                      onLike={favorityCourse}
                      onActionClick={actionClick}
                      courses={cat.categoryCourses}
                    />
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      )}
    </div>
    </>
  );
};

export default AvalibleContent;
