import { Box, Typography, Button } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import useQuery from 'customHooks/useQuery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LessonData, TrailData } from 'services/dataService';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';
import theme from 'theme';
import LoginFooter from 'views/Welcome/components/Footer';
import KnowledgeTrailCourses from './components/KnowledgeTrailCourses';
import KnowledgeTrailHeader from './components/KnowledgeTrailHeader';

const KnowledgeTrailRegistration = () => {
  const queryParams = useQuery();
  const trailIsFinished = queryParams.get('finished') === 'true';
  const trailIsInProgress = queryParams.get('progress') === 'true';
  const nextCourseId = queryParams.get('course');
  const { id: id_trail } = useParams();
  const { t } = useTranslation(['toastify', 'button']);
  const history = useHistory();
  const { auth, company } = useSelector((state) => state);
  const userId = auth.id;
  const { id_company, id: id_user } = auth;
  const [trail, setTrail] = useState([]);
  const [customization, setCustomization] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showProgress, setShowProgress] = useState(false);



  const getTrailDetails = async () => {
    try {
      const { data } = await TrailData.getTrailDetailsById(id_trail);

      setTrail(data);
    } catch (e) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };
  
  const getCompanyFooter = async () => {
    try {
      const { data: companyCustomization } =
        await PublicCoursesViewsData.getCompanyCustomization(
          company?.domain_company
        );
      setCustomization(companyCustomization);
    } catch (error) {}
  };

  const handleCourse = async (company_course) => {
    try {
      const { data: lesson } = await LessonData.getNextLesson(
        company_course,
        userId
      );

      let id = '';

      if (lesson?.length) {
        id = lesson[0]?.id;
      }

      history.replace(`/course/${company_course}/learn/${id || ''}`);
    } catch (error) {
      history.replace(`/course/${company_course}/learn`);
    }
  };


  const handleTrailEnroll = async () => {
    if (buttonClicked) {
      return; 
    }

    setButtonClicked(true);
    setShowProgress(true);
    try {
      const payload = {
        id_company,
        id_trail,
        id_user,
      };

      await TrailData.enrollTrail(payload);
      toast.success(t('toastify:commons.toast_success'));
      history.push('/dashboard');
    } catch (e) {
      toast.error(t('toastify:commons.toast_error_api_enroll_true'));
    }
    finally {
      setShowProgress(false);
    };
  };

  useEffect(() => {
    getTrailDetails();
    getCompanyFooter();
  }, []);

return (
    <Box className='notranslate'>
      <Button
          style={{marginTop: '5px'}}
          onClick={()=> history.push('/dashboard')}
          >
            <ArrowBackIos />
            {t('button:commons.btn_back')}
      </Button>
      <KnowledgeTrailHeader
        info={trail}
        icon={trail?.image_url}
        handleTrailEnroll={handleTrailEnroll}
        handleCourse={handleCourse}
        finished={trailIsFinished}
        progress={trailIsInProgress}
        nextCourseId={nextCourseId}
      />

      <KnowledgeTrailCourses
        data={trail}
        finished={trailIsFinished}
        progress={trailIsInProgress}
      />

      <Typography style={{ paddingTop: theme.spacing(6) }} />
      {!auth?.signed && <LoginFooter footer={customization?.company_footer} />}
    </Box>
  );
};

export default KnowledgeTrailRegistration;
