import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  root: {},
  cardContent: {
    paddingBottom: theme.spacing(3)
  },  
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      alignItems: 'flex-end',
    }
  },
  boxBtns: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  btnReset: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1)
    }
  }
}));
