import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  exportButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));
