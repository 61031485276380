import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  gridMargin: {
    marginRight: 100,
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
