import {
  Box,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CameraAltOutlined,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  Web,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilesData } from 'services/dataService';
import CustomHelper from '../CustomHelperText';
import SocialMediaTextfield from '../SocialMediaTextfield';

const CompanyFooterCustomization = ({ company_footer, form }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views']);
  const { register, watch } = form;
  const [showContent, setShowContent] = React.useState(true);
  const [defaultSocialMedias, setDefaultSocialMedias] = React.useState([]);
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');

  // const [contact, setContact] = React.useState([]);
  const fileInput = React.useRef();

  const saveFile = async (file) => {
    if (!file) {
      return;
    }
    const { data } = await FilesData.addFile(
      file,
      'company_customization_footer'
    );
    setFileUrl(data?.url);
    setFile(data?.id);
  };

  const initFile = () => {
    setFileUrl(company_footer?.logo?.url);
    setFile(company_footer?.id_file_logo);
  };

  React.useEffect(() => {
    initFile();
    setDefaultSocialMedias(
      company_footer?.social_networks?.length
        ? company_footer?.social_networks
        : [{ type: 0 }, { type: 1 }, { type: 2 }, { type: 3 }, { type: 4 }]
    );
  }, []);

  // React.useEffect(() => {
  //   const noDataArray = [
  //     { title: '', title_color: '', description: '', description_color: '' },
  //     { title: '', title_color: '', description: '', description_color: '' },
  //   ];
  //   setContact(company_footer?.contacts | noDataArray);
  // }, company_footer);

  const GetIcon = ({ type }) => {
    switch (type) {
      case 0:
        return <Facebook fontSize="large" />;
      case 1:
        return <LinkedIn fontSize="large" />;
      case 2:
        return <Instagram fontSize="large" />;
      case 3:
        return <Twitter fontSize="large" />;
      case 4:
        return <Web fontSize="large" />;
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Box className={classes.headerRoot}>
        <Box style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.header} color="textSecondary">
            {t('views:CompanyCustomization.foot_title')}
          </Typography>
        </Box>
        <Box style={{ width: '10%' }}>
          <Switch
            checked={showContent}
            onChange={(_, checked) => {
              setShowContent(checked);
            }}
          />
        </Box>
      </Box>
      <Typography className={classes.colorTopSpacing} />
      {showContent && (
        <Box>
          <Grid container>
            <Grid item xs={12} md={4}>
              <CustomColorPicker
                name={`company_footer.background_color`}
                register={register}
                title={t('views:CompanyCustomization.background_color')}
                defaultValue={company_footer?.background_color}
              />
              <CustomHelper
                text={t('views:CompanyCustomization.footer_color_helper')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                height="150"
                width="150"
                style={{ maxWidth: '100%' }}
                className={classes.media}
                src={fileUrl}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                className={clsx(classes.headerRoot, classes.eventButtonCenter)}
                style={{ height: '100%' }}
              >
                <input
                  ref={fileInput}
                  style={{ display: 'none' }}
                  type="file"
                  id="contained-logo-file"
                  accept="image/png, image/jpeg"
                  onChange={(event) => {
                    saveFile(event.target.files[0]);
                  }}
                />
                <TextField
                  name="company_footer.id_file_logo"
                  value={file}
                  inputRef={register}
                  style={{ display: 'none' }}
                />
                <Button
                  variant="contained"
                  style={{ width: '60%', height: '60%', alignSelf: 'center' }}
                  onClick={() => {
                    fileInput.current.click();
                  }}
                >
                  <CameraAltOutlined fontSize="small" />
                  {t('views:CompanyCustomization.change_footer_logo')}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Typography className={classes.colorTopSpacing} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <TextField
                  fullWidth
                  name="company_footer.contacts[0].title"
                  defaultValue={company_footer?.contacts[0]?.title}
                  variant="outlined"
                  inputRef={register}
                  label={t('views:CompanyCustomization.title')}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.column_helper_title')}
                />
              </Box>
              <Typography className={classes.colorTopSpacing} />
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  name="company_footer.contacts[0].description"
                  defaultValue={company_footer?.contacts[0]?.description}
                  variant="outlined"
                  label={t('views:CompanyCustomization.description')}
                  inputRef={register}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.description_helper')}
                />
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <CustomColorPicker
                      name={`company_footer.contacts[0].title_color`}
                      defaultValue={company_footer?.contacts[0]?.title_color}
                      register={register}
                      title={t('views:CompanyCustomization.title_color')}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomColorPicker
                      name={`company_footer.contacts[0].description_color`}
                      defaultValue={
                        company_footer?.contacts[0]?.description_color
                      }
                      register={register}
                      title={t('views:CompanyCustomization.subtitle_color')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <TextField
                  fullWidth
                  name="company_footer.contacts[1].title"
                  defaultValue={company_footer?.contacts[1]?.title}
                  variant="outlined"
                  inputRef={register}
                  label={t('views:CompanyCustomization.title')}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.column_helper_title')}
                />
              </Box>
              <Typography className={classes.colorTopSpacing} />
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  name="company_footer.contacts[1].description"
                  defaultValue={company_footer?.contacts[1]?.description}
                  variant="outlined"
                  label={t('views:CompanyCustomization.description')}
                  inputRef={register}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.description_helper')}
                />
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <CustomColorPicker
                      name={`company_footer.contacts[1].title_color`}
                      defaultValue={company_footer?.contacts[1]?.title_color}
                      register={register}
                      title={t('views:CompanyCustomization.title_color')}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CustomColorPicker
                      name={`company_footer.contacts[1].description_color`}
                      defaultValue={
                        company_footer?.contacts[1]?.description_color
                      }
                      register={register}
                      title={t('views:CompanyCustomization.subtitle_color')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <TextField
                  fullWidth
                  name="company_footer.social_network_name"
                  defaultValue={company_footer?.social_network_name}
                  variant="outlined"
                  label={t('views:CompanyCustomization.title')}
                  inputRef={register}
                />
              </Box>
              <Typography className={classes.colorTopSpacing} />

              {defaultSocialMedias
                ?.sort((a, b) => (a.type < b.type ? -1 : 1))
                ?.map(({ link, status, type }, index) => (
                  <>
                    <SocialMediaTextfield
                      name={`company_footer.social_networks[${index}]`}
                      type={type}
                      defaultValue={link}
                      register={register}
                      icon={<GetIcon type={type} />}
                      defaultChecked={status}
                    />
                    <Typography className={classes.colorTopSpacing} />
                  </>
                ))}

              <CustomColorPicker
                name={`company_footer.social_network_icon_color`}
                register={register}
                title={t('views:CompanyCustomization.icon_color')}
                defaultValue={company_footer?.social_network_icon_color}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
// GetIcon
/* 
              <SocialMediaTextfield
                name="social_media.linkedin"
                register={register}
                icon={<GetIcon type=""/>}
                defaultChecked={true}
              />
              <Typography className={classes.colorTopSpacing} />
              <SocialMediaTextfield
                name="social_media.instagram"
                register={register}
                icon={<GetIcon type=""/>}
                defaultChecked={true}
              />
              <Typography className={classes.colorTopSpacing} />
              <SocialMediaTextfield
                name="social_media.twitter"
                register={register}
                icon={<GetIcon type=""/>}
                defaultChecked={true}
              />
              <Typography className={classes.colorTopSpacing} />
              <SocialMediaTextfield
                name="social_media.website"
                register={register}
                icon={<GetIcon type=""/>}
                defaultChecked={true}
              /> */

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
  },
  header: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  contentRoot: {
    paddingTop: theme.spacing(3),
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  eventButtonCenter: {
    justifyContent: 'center',
  },
  media: {
    // width: '100%',
  },
}));

export default CompanyFooterCustomization;
