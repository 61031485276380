import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles( theme => ({
  flex:{
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'space-between',
  },
  box:{
    width:'46%',
    display:'flex',    
    flexDirection:"column",
  },
  boxdelete:{
    display:'flex',
    flexDirection:"column",    
    justifyContent: "center",
  },  
  label:{
    fontSize:15,
    fontWeight:500,
    color: '#000000',
    marginTop:16,
    marginBottom: 8,
    justifyContent:'flex-start',
  },
  tips: {
    display: 'flex',
    alignItems: 'center'
  },
  tiny:{
    color:'#A2AFBD',
    fontSize:10,
  },
  helpOutlineIcon:{
    color:"#BCBCBC",
    fontSize:20,
    marginRight:5,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    width: 230,
    height: 96,
    background: '#F1F1F1',
    boxShadow: '0px 0px 15px #00000080',
    borderRadius: 10,
    padding:9,
    color:'#808080',
    fontSize:16,
  },
}))
