import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
    gap: 48,

    '& main': {
      width: '100%',
      minWidth: 724,
      maxWidth: 918,
    },

    '& h1': {
      fontSize: 32,
      lineHeight: '40px',
      textAlign: 'center',
      margin: 'auto',
      marginBottom: 32,
      paddingInline: 16,
      maxWidth: 700,
    },
  },
  interwave: {
    display: 'block',
    marginBottom: 24,
    fontSize: 16,
    lineHeight: '22px',
    textAlign: 'center',
  },
 interwaveTitle: {
    display: 'block',
    fontSize: 24,
    textAlign: 'center',
  },
  attention: {
    fontSize: 12,
    textAlign: 'left',
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
    lineHeight: 1.5
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
    gap: 24,
    marginTop: 40,
    marginBottom: 64,

    '& div': {
      textAlign: 'center',
    },

    '& img': {
      width: '100%',
      maxWidth: 220,
      maxHeight: 145,
      objectFit: 'cover',
      borderRadius: 10,
      marginBottom: 8,
    },

    '& p': {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '20px',
      maxWidth: 170,
      margin: 'auto',
      whiteSpace: 'pre-wrap',
    },
  },

  prices: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > h2': {
      fontSize: 32,
      fontWeight: 500,
      textAlign: 'center',
    },

    '& > table': {
      width: '100%',
      marginTop: 24,
      boxShadow: '0px 4px 50px rgba(42, 46, 67, 0.1)',
      borderCollapse: 'separate',
      borderRadius: 10,

      '& th, td': {
        padding: 16,
        textAlign: 'center',
      },

      '& th:first-child': {
        borderTopLeftRadius: 10,
      },
      '& th:last-child': {
        borderTopRightRadius: 10,
      },

      '& tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 10,
      },
      '& tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 10,
      },

      '& > thead': {
        backgroundColor: '#D9D9D9',
      },

      '& > tbody': {
        backgroundColor: '#fafafa',
      },
    },

    '& strong': {
      textAlign: 'center',
      marginTop: 40,
    },
  },

  aside: {
    position: 'sticky',
    top: 40,
    marginTop: 8,
    width: '100%',
    height: 'fit-content',
    minWidth: 335,
    maxWidth: 430,
    padding: 24,
    backgroundColor: '#fafafa',
    boxShadow: '0px 8px 50px rgba(42, 46, 67, 0.1)',
    borderRadius: 10,

    '& h2': {
      fontSize: 24,
      lineHeight: '30px',
      marginBottom: 12,
    },

    '& h4': {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 300,
      marginBottom: 32,
    },
  },

  buttonsAside: {
    '& a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      color: '#fff',
      fontSize: 14,
      fontWeight: 600,
      gap: 8,
      padding: 6,
      borderRadius: 8,
      border: 'none',
      backgroundColor: '#176AE6',
      cursor: 'pointer',
      transition: '.1s',

      '&:last-child': {
        marginTop: 16,
        backgroundColor: '#17E645',
      },

      '&:hover': {
        opacity: '.9',
        transform: 'scale(1.02)',
      },
    },
  },

  [theme.breakpoints.up('xl')]: {
    root: {
      gap: 60,
    },

    aside: {
      padding: 32,

      '& h2': {
        fontSize: 32,
        lineHeight: '40px',
      },

      '& h4': {
        fontSize: 28,
        lineHeight: '32px',
        marginBottom: 60,
      },
    },
  },

  [theme.breakpoints.down(1440)]: {
    root: {
      flexDirection: 'column',
      padding: 24,

      '& main': {
        minWidth: 'unset',
        margin: 'auto',
      },
    },

    prices: {
      '& > table': {
        maxWidth: 850,
      },

      '& > strong': {
        maxWidth: 850,
      },
    },

    aside: {
      maxWidth: 850,
      margin: 'auto',
    },

    buttonsAside: {
      display: 'flex',
      gap: 16,

      '& a': {
        '&:last-child': {
          marginTop: 0,
        },
      },
    },
  },

  [theme.breakpoints.down('xs')]: {
    root: {
      paddingTop: 32,

      '& h1': {
        fontSize: 24,
        lineHeight: '28px',
      },
    },

    prices: {
      '& > h2': {
        fontSize: 24,
        lineHeight: '28px',
      },
    },

    grid: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gap: 24,
    },

    buttonsAside: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
  },
}));
