import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { fieldsTypes } from 'helpers/customFields';
import { useStyles } from './styles';

const CustomFields = ({
  type,
  placeholder,
  fieldName,
  required,
  register,
  errors,
}) => {
  const classes = useStyles();

  switch (fieldsTypes[type]) {
    case 'Única escolha':
      return (
        <Grid item md={12} xs={12} className={classes.fieldGroup}>
          <TextField
            fullWidth
            label={placeholder}
            id={fieldName}
            name={fieldName}
            type="text"
            variant="outlined"
            inputRef={register({
              required,
            })}
            error={!!errors[fieldName]}
          />
        </Grid>
      );
    case 'Múltipla escolha':
      return (
        <Grid item md={12} xs={12} className={classes.fieldGroup}>
          <TextField
            fullWidth
            label={placeholder}
            id={fieldName}
            name={fieldName}
            type="text"
            variant="outlined"
            inputRef={register({
              required,
            })}
            error={!!errors[fieldName]}
          />
        </Grid>
      );
    case 'Data':
      return (
        <Grid item md={12} xs={12} className={classes.fieldGroup}>
          <TextField
            fullWidth
            label={placeholder}
            id={fieldName}
            name={fieldName}
            type="text"
            variant="outlined"
            inputRef={register({
              required,
            })}
            error={!!errors[fieldName]}
          />
        </Grid>
      );
    case 'Número inteiro':
      return (
        <Grid item md={12} xs={12} className={classes.fieldGroup}>
          <TextField
            fullWidth
            label={placeholder}
            id={fieldName}
            name={fieldName}
            type="number"
            variant="outlined"
            inputRef={register({
              required,
            })}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            error={!!errors[fieldName]}
          />
        </Grid>
      );
    case 'Número decimal':
      return (
        <Grid item md={12} xs={12} className={classes.fieldGroup}>
          <TextField
            fullWidth
            label={placeholder}
            id={fieldName}
            name={fieldName}
            type="number"
            variant="outlined"
            inputRef={register({
              required,
            })}
            inputProps={{ inputMode: 'numeric', pattern: '[+-]?\d+(?:[.,]\d+)?' }}
            error={!!errors[fieldName]}
          />
        </Grid>
      );
    case 'Texto':
      return (
        <Grid item md={12} xs={12} className={classes.fieldGroup}>
          <TextField
            fullWidth
            label={placeholder}
            id={fieldName}
            name={fieldName}
            type="text"
            variant="outlined"
            inputRef={register({
              required,
            })}
            error={!!errors[fieldName]}
          />
        </Grid>
      );
    default:
      return <></>;
  }
};

export default CustomFields;
