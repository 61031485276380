import querystring from 'querystring';
import api from '../api';

class DataServiceTagsCourse {
  addTagsCourse(data) {
    return api.post('/tag-courses', data);
  }

  getTagsCourse(id) {
    return api.get(`/tag-courses/${id}`);
  }

  deleteTagsCourse(id) {
    return api.delete(`/tag-courses/${id}`);
  }
}

export default new DataServiceTagsCourse();
