import querystring from 'querystring';
import api from '../api';

class DataServiceClassroomUser {
  addClassroomTag(data) {
    return api.post('classrooms-tags', data);
  }

  getClassroomTag(id) {
    return api.get(`classrooms-tags/${id}`);
  }

  deleteClassroomTag(id, id_classroom) {
    return api.delete(`/classrooms/${id_classroom}/tags/${id}`);
  }
}

export default new DataServiceClassroomUser();
