import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

  columnGrid: {
    margin: theme.spacing(1, 0, 1, 0),
  },

  tabEdit:{
    paddingTop: theme.spacing(2),
  }
}));
