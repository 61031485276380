import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  step: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 8px',
    }
  }
}));

export default function CustomStepper({ progressLabel, activeStep, children }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} className={classes.step}>
          {progressLabel.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <Box>
        {children
          ?.map((component) => component)
          ?.filter((_) => {
            return _?.props?.step === activeStep;
          })}
      </Box>
    </>
  );
}
