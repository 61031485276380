import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import CustomSlider from 'components/CustomSlider';
import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

/**
 *
 * @param {[{banner:string name:string description:string description:string liked_course:boolean id_company_course:string assigned: boolean}]} courses
 * @param {(id_company_course: string)=> {}} onLike
 * @param {(id_company_course: string)=> {}} onActionClick
 */
const ProgressCourseSlider = ({
  courses,
  onLike,
  onActionClick,
  slidesNumber,
  finished,
  ...props
}) => {
  const { t } = useTranslation(['button']);
  const [slidesToShow, setSlidesToShow] = React.useState(4);
  const classes = useStyles();

  const toFixed = (num, fixed) => {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  };

  return (
    <CustomSlider
      itens={courses}
      slidesNumber={slidesNumber}
      slidesToShow={slidesToShow}
      setSlidesToShow={setSlidesToShow}
      slideTeste={true}
      {...props}
    >
      {courses?.map((course, index) => {
        return (
          <div className={classes.slidesShow}>
            <Box
              className={classes.card}
              style={{ width: slidesToShow <= 2 ? 350 : 'unset' }}
            >
              <Box>
                <Link
                  onClick={() => {
                    if (onActionClick) {
                      onActionClick(course?.id);
                    }
                  }}
                >
                  <img
                    className={classes.media}
                    src={
                      course.banner
                        ? course.banner
                        : 'https://via.placeholder.com/350x208'
                    }
                  />
                </Link>
                <Box className={classes.cardBody}>
                  <Typography component="span" className={classes.header}>
                    {course.name}
                  </Typography>
                  <Box className={classes.description}>
                    <Interweave
                      content={
                        course?.description?.length ? course?.description : '-'
                      }
                    />
                  </Box>
                  <Box className={classes.progress}>
                    <Box className={classes.progressBar}>
                      <LinearProgress
                        style={{ width: '100%' }}
                        color="secondary"
                        variant="determinate"
                        value={toFixed(course?.course_progress, 2)}
                      />
                    </Box>
                    <Box className={classes.progressText}>
                      <Typography component="span">
                        {course?.course_progress
                          ? course?.course_progress?.toFixed(
                              course?.progress,
                              2
                            )
                          : course?.course_progress}
                        %
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.action}>
                    <Box className={classes.accessButton}>
                      <Button
                        size="medium"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                          if (onActionClick) {
                            onActionClick(course?.id);
                          }
                        }}
                      >
                        {t(
                          `button:StudentContent.${
                            course?.course_progress > 0
                              ? 'btn_continue'
                              : 'btn_start'
                          }`
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        );
      })}
    </CustomSlider>
  );
};
export default ProgressCourseSlider;
