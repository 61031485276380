import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  strongText: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 15,
  },
  topSpacing: { marginTop: theme.spacing(2) },
  container: {
    borderWidth: 1,
    borderStyle: 'solid',
    padding: theme.spacing(2)
  },
}));
