const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

export const phoneMask = (name) => {
  if (name === 'cellphone')
    return [ '(', /[1-9]/, /\d/, ')',' ',/\d/,' ',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,];
  if (name === 'phone')
    return ['(',/[1-9]/,/\d/,')',' ',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,];
};

export const documentMask = (value) => {
  if (value?.length < 15 || !value )
    return [ /[0-9]/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/];
  else
    return [ /[0-9]/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,];
};

export const cpfMask = (value) => {
    return [ /[0-9]/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'-',/\d/,/\d/];
};

export default {
  checked
};
