import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import Interweave from 'interweave';

export default ({
  finishedCourse,
  content,
  courseId,
  learnId,
  prev,
  next,
  hideAnotation,
}) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (hideAnotation) {
      hideAnotation(false);
    }
  }, []);

  useEffect(() => {
    finishedCourse({
      finished: true,
      video_last_sec: null,
      audio_last_sec: null,
    });
  }, []);

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{content.name}</h1>
      <h1 className={classes.subtitle}>{content.description}</h1>
      <div className={classes.wrapper}>
        <Interweave content={content.content} />
      </div>

      <div className={classes.buttons}>
        <button
          onClick={() => history.push(`/course/${courseId}/learn/${prev}`)}
          disabled={prev == null}
        >
          <ChevronLeft /> Anterior
        </button>
        <button
          onClick={() => history.push(`/course/${courseId}/learn/${next}`)}
          disabled={next == null}
          className={clsx([classes.buttonsActive])}
        >
          Próximo <ChevronRight />
        </button>
      </div>
    </div>
  );
};
