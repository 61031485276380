import React from 'react';

import { ClassroomCourseToolbar, ClassroomCourseList } from 'views/ClassroomCourse/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ClassroomCourseToolbar />
      <div className={classes.content}>
        <ClassroomCourseList />
      </div>
    </div>
  );
};
