import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 'auto',
    maxWidth: 1080,
    paddingTop: 15,
    paddingBottom: 100,
    '& > div': {
      padding: '25px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: '100%',
      '& > div': {
        padding: '25px 16px'
      },
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '100%',
      '& > div': {
        padding: '25px 16px'
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      '& > div': {
        padding: '25px 16px'
      },
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        width: '100%',
      },
    },
  },

  content: {
    width: '100%',
    minWidth: '100%',
    marginTop: '10px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.6rem',
      paddingRight: '1rem',
      paddingBottom: 25,
      width: '30%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingLeft: '1.6rem',
      paddingRight: '1rem',
      paddingBottom: 25,
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '3rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }  
}))
