import React, { useState, useEffect } from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { CardStatusUser } from 'views/User/components/';
import { useStyles } from './styles';
import { UserData } from 'services/dataService';
import {
  Poll,
  AddCircle,
  ImportExport,
  PersonOutline,
} from '@material-ui/icons';
import { Card } from 'components/Toolbar/';

export default ({ className, listDataChangeObservable, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const { t } = useTranslation([
    'title',
    'label',
    'form',
    'button',
    'toastify',
  ]);

  const fetchData = async () => {
    try {
      const response = await UserData.getUsers();
      setSummary(response.data.summary);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const subscription = listDataChangeObservable.subscribe(() => {
    fetchData();
  });

  const onListDataChange = () => {
    fetchData();
  }

  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  const classes = useStyles();

  return (
    <>
      {!loading && (
        <div {...rest} className={[clsx(classes.root, className, 'notranslate')]}>
          <Grid container className={classes.quickAccessContainer}>
            <Grid item md={4} xs={12}>
              <MuiTypography variant="h4">
                {t('label:commons.label_access_quick')}:
              </MuiTypography>
            </Grid>
            <Grid item md={8} xs={12} className={classes.topButtons}>
              <Link to="/users/create-massive" className={classes.button}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  <ImportExport style={{ marginRight: '5px' }} />
                  {t('button:commons.btn_add_massive')}
                </Button>
              </Link>
              <Link to="/users/create" className={classes.button}>
                <Button color="primary" variant="contained" className={classes.button}>
                  <AddCircle style={{ marginRight: '6px' }} />
                  {t('button:commons.btn_add')}
                </Button>
              </Link>
            </Grid>
          </Grid>

          <div className={classes.rowStatus}>
            <Grid container spacing={2} className={classes.overrideWrap}>
              <Grid item md={3} xs={12}>
              <Card
                title={t('label:User.label_online_users')}
                number={summary?.user_online}
                icon={PersonOutline}
                iconStyle={classes.greenIcon}
                cardTwo={true}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Card
                  title={t('label:User.label_users')}
                  number={summary?.user_active + summary?.user_disable + summary?.user_test}
                  icon={PersonOutline}
                  iconStyle={classes.blackIcon}
                  cardTwo={true}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Card
                  title={t('label:User.label_active_users')}
                  number={summary?.user_active}
                  icon={PersonOutline}
                  iconStyle={classes.blueIcon}
                  cardTwo={true}
                />
              </Grid>
              
              <Grid item md={3} xs={12}>
                <Card
                  title={t('label:User.label_test_users')}
                  number={summary?.user_test}
                  icon={PersonOutline}
                  iconStyle={classes.yellowIcon}
                  cardTwo={true}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <Card
                  title={t('label:User.label_blocked_users')}
                  number={summary?.user_disable}
                  icon={PersonOutline}
                  iconStyle={classes.redIcon}
                  cardTwo={true}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
