import React from 'react';
import { Grid } from '@material-ui/core';

import { ClassroomCourseForm } from 'views/ClassroomCourse/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xl={12}>
          <ClassroomCourseForm />
        </Grid>
      </Grid>
    </div>
  );
};
