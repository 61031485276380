import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import JunoAPIService from 'services/paymentService/JunoAPIService';

const ChargeAddress = ({ name, register, control, errors, setValue }) => {
  const classes = useStyles();
  const { t } = useTranslation(['validation', 'states']);
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [neighborhood, setNeighborhood] = React.useState('');
  const [federalState, setFederalState] = React.useState('');
  // console.log(federalState);
  const getState = () => {
    const states = t('states:federal_states', {
      returnObjects: true,
      defaultValue: [],
    });

    return states?.map(({ name, value }) => (
      <MenuItem value={value}>{name}</MenuItem>
    ));
  };

  const getAddress = async (postalCode) => {
    const { data: address } = await JunoAPIService.getAddressByPostalCode(
      postalCode
    );

    setValue(`${name}.state`, address?.uf);
    setValue(`${name}.city`, address?.localidade);
    setValue(`${name}.street`, address?.logradouro);
    setValue(`${name}.neighborhood`, address?.bairro);

    setState(address?.uf);
    setCity(address?.localidade);
    setStreet(address?.logradouro);
    setNeighborhood(address?.bairro);
    setFederalState(address?.uf);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.title} color="textSecondary">
          Endereço de cobrança
        </Typography>
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={3}>
            <InputMask
              mask="99999-999"
              maskChar=""
              onChange={(e) => {
                if (e.target.value.length === 9) {
                  getAddress(e.target.value.replace('-', ''));
                }
              }}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  name={`${name}.postCode`}
                  label="CEP"
                  inputRef={register({ required: true })}
                  helperText={
                    errors?.[name]?.postCode?.type === 'required' &&
                    t('validation:commons.validation_required')
                  }
                  error={!!errors?.[name]?.postCode}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              label="Número"
              name={`${name}.number`}
              inputRef={register({ required: true })}
              helperText={
                errors?.[name]?.number?.type === 'required' &&
                t('validation:commons.validation_required')
              }
              error={!!errors?.[name]?.number}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name={`${name}.street`}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              variant="outlined"
              label="Rua"
              inputRef={register({ required: true })}
              helperText={
                errors?.[name]?.street?.type === 'required' &&
                t('validation:commons.validation_required')
              }
              error={!!errors?.[name]?.street}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={3}>
        <Grid item xs={6}>
            <TextField
              fullWidth
              name={`${name}.neighborhood`}
              value={neighborhood}
              onChange={(e) => setNeighborhood(e.target.value)}
              variant="outlined"
              label="Bairro"
              inputRef={register({ required: true })}
              helperText={
                errors?.[name]?.neighborhood?.type === 'required' &&
                t('validation:commons.validation_required')
              }
              error={!!errors?.[name]?.neighborhood}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              name={`${name}.complement`}
              variant="outlined"
              label="complemento"
              inputRef={register({ required: true })}
              helperText={
                errors?.[name]?.complement?.type === 'required' &&
                t('validation:commons.validation_required')
              }
              error={!!errors?.[name]?.complement}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              name={`${name}.city`}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              variant="outlined"
              label="Cidade"
              inputRef={register({ required: true })}
              helperText={
                errors?.[name]?.city?.type === 'required' &&
                t('validation:commons.validation_required')
              }
              error={!!errors?.[name]?.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel error={!!errors?.[name]?.state} id="state-label">
                Estado
              </InputLabel>
              <Controller
                fullWidth
                id="state-select"
                name={`${name}.state`}
                value={federalState}
                control={control}
                rules={{
                  required: t('validation:commons.validation_select_required'),
                }}
                as={(inputProps) => (
                  <Select
                    {...inputProps}
                    fullWidth
                    error={!!errors?.[name]?.state}
                    // onChange={(e) => setFederalState(e.target.value)}
                    tabIndex={6}
                    name={`${name}.state`}
                  >
                    {getState()}
                  </Select>
                )}
              />
              {errors?.[name]?.state?.type === 'required' && (
                <FormHelperText error={!!errors?.[name]?.state}>
                  {t('validation:commons.validation_required')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  title: {
    ...theme.typography.h4,
    paddingTop: '1rem',
    borderTop: '1px solid #D0D0D0',
  },
}));

export default ChargeAddress;
