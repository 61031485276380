import React, { useState, useRef, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';
import { useStyles } from './styles';
import { LibraryData } from 'services/dataService';
import { localizationTable, options } from 'constants/table';
import DialogPreviewLibrary from 'components/DialogPreview';

export default ({ listDataChange }) => {
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewType, setPreviewType] = useState(null);
  const [previewName, setPreviewName] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [categories, setCategories] = useState([]);
  const { id: user_id } = useSelector((state) => state.auth);

  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();
  const { t } = useTranslation(['toastify', 'tables']);
  const classes = useStyles();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const responseCategories = await LibraryData.getCategories();
  //       setCategories(responseCategories.data.categories);
  //     } catch (err) {
  //       if (err.response?.status === 403) {
  //         toast.error(t('toastify:commons.toast_error_403'));
  //       } else {
  //         toast.error(t('toastify:commons.toast_error_api'));
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseLibrary = await LibraryData.getLibrarystud({
          iduser: user_id,
          page:1,
          limit: 100,
          category: '',
          title: '',
        });

        const categoryMap = new Map();
        responseLibrary.data.library.forEach((item)=>{
          if(item.id_category && item.categoryname) {
            categoryMap.set(item.id_category, {id: item.id_category, name: item.categoryname});
          }
        });

        const uniqueCategories = Array.from(categoryMap.values());
        setCategories(uniqueCategories);

      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };
    fetchData();
  }, [user_id, t]);

  const handleDownload = async (rowData) => {
    try {
      toast.info(t('toastify:commons.toast_start_download_library'));
      const formValues = {
        fileName: rowData.urlfile,
        fileType: rowData.file_type,
        iduser: user_id,
        idlibrary: rowData.id,
      };

      const signedurls3 = await LibraryData.signs3geturlstudent(formValues);
      const response = await fetch(signedurls3.data.signedUrl);
      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = rowData.nameorigem;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file', error);
      toast.error(t('toastify:commons.toast_error_download_library'));
    }
  };

  const handlePreview = async (rowData) => {
    try {
      const formValues = {
        fileName: rowData.urlfile,
        fileType: rowData.file_type,
        iduser: user_id,
        idlibrary: rowData.id,
      };
      const signedurls3 = await LibraryData.signs3geturlstudent(formValues);
      const url = signedurls3.data.signedUrl;

      setPreviewUrl(url);
      setPreviewType(rowData.file_type);
      setPreviewName(rowData.title);
      setModalStatus(true);
    } catch (error) {
      console.error('Error fetching file for preview', error);
      toast.error(t('toastify:commons.toast_error_download_library'));
    }
  };


  const onSubmit = (data) => {
    setTitle(data.title);
    setCategory(data.category);
    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <div className="notranslate">
        <MaterialTable
          title={`${t('tables:library.title')}`}
          tableRef={tableRef}
          columns={[
            { title: `${t('tables:library.name_column')}`, field: 'title', sorting: false },
            {
              title: `${t('tables:user.created_at_column')}`,
              field: 'createdAt',
              sorting: false,
              render: (rowData) => format(parseISO(rowData.created_at), 'dd/MM/yyyy'),
            },
            {
              title: `${t('tables:library.category_column')}`,
              field: 'categoryname',
              sorting: false,
              render: (rowData) => rowData.categoryname,
            },
            {
              title: `${t('tables:library.description_column')}`,
              field: 'description',
              sorting: false,
              render: (rowData) => rowData.description,
            },
          ]}
          data={async (query) => {
            try {
              const response = await LibraryData.getLibrarystud({
                iduser: user_id,
                page: query.page + 1,
                limit: query.pageSize,
                category,
                title,
              });
              return {
                data: response.data.library,
                page: response.data.currentPage - 1,
                totalCount: response.data.totalCount,
              };
            } catch (err) {
              if (err.response?.status === 403) {
                toast.error(t('toastify:commons.toast_error_403'));
              } else {
                toast.error(t('toastify:commons.toast_error_api'));
              }
              return { data: [] };
            }
          }}
          actions={[
            {
              icon: 'download',
              tooltip: `${t('tables:commons.actions.link_donwload')}`,
              onClick: (event, rowData) => handleDownload(rowData),
              iconProps: { color: 'primary' },
            },
            {
              icon: 'visibility',
              tooltip: `${t('tables:commons.actions.view_default')}`,
              onClick: (event, rowData) => handlePreview(rowData),
              iconProps: { color: 'primary' },
            },
          ]}
          components={{
            search: false,
            title: true,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label={t('form:commons.input_search')}
                          name="title"
                          type="text"
                          variant="outlined"
                          defaultValue={title || ''}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControl style={{ minWidth: 130 }}>
                          <InputLabel id="category">
                            {t('form:Library.input_category')}
                          </InputLabel>
                          <Controller
                            as={
                              <Select>
                                <MenuItem value="">
                                  Todas
                                </MenuItem>
                                {categories &&
                                  categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                      {category.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            }
                            name="category"
                            control={control}
                            defaultValue={category || ''}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4} xs={12} className={classes.actions}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          type="submit"
                        >
                          <Search style={{ marginRight: '5px' }} />
                          {t('button:commons.btn_advanced_filter')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={localizationTable(t)}
          options={options}
        />
      </div>
      <DialogPreviewLibrary
        previewType={previewType}
        previewUrl={previewUrl}
        previewName={previewName}
        modalStatus={modalStatus}
        closeModal={setModalStatus}
      />
    </>
  );
};
