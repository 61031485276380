import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CustomTabComponent from 'components/CustomTab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CourseContent from './components/CourseContent';
import CourseDetail from './components/CourseDetail';
import CourseGoal from './components/CourseGoal';

const CourseInformation = ({ details, modules }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views']);
  const components = [
    {
      label: t('views:CourseRegistration.course_objectives'),
      component: <CourseGoal objectives={details?.objectives} />,
    },
    {
      label: t('views:CourseRegistration.course_details'),
      component: <CourseDetail details={details?.details} />,
    },
    {
      label: t('views:CourseRegistration.course_content'),
      component: <CourseContent modules={modules} />,
    },
  ];
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={6}>
          <CustomTabComponent
            className={classes.panel}
            outlined
            components={components}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  panel: {
    minHeight: 320,
  },
}));

export default CourseInformation;
