import React, { useState, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import { HandleDelete } from 'components/';
import { localizationTable, options } from 'constants/table';

import { CardContent, Grid, Button, TextField } from '@material-ui/core';

import { useStyles } from './styles';

import { ClassroomData } from 'services/dataService';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';

export default () => {
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [route, setRoute] = useState('');

  const handleModalOpen = useCallback(({ id }) => {
    setModalStatus(true);
    setRoute(`classrooms/${id}`);
  });

  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`classroom/${id}/edit`);
    },
    [history]
  );

  const onSubmit = (data) => {
    setName(data.name);
    setDescription(data.description);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
      <MaterialTable
        title={`${t('tables:Classroom.title')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('tables:Classroom.name_column')}`,
            field: `name`,
            render: (rowData) => rowData.name,
          },
          {
            title: `${t('tables:Classroom.description_column')}`,
            field: `description`,
            render: (rowData) => rowData.description,
          },
        ]}
        data={async (query) => {
          try {
            const response = await ClassroomData.getClassrooms({
              page: query.page + 1,
              limit: query.pageSize,
              name,
            });

            return {
              data: response.data.classrooms,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }      
            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:Classroom.input_name')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:Classroom.input_description')}
                        name="description"
                        type="text"
                        variant="outlined"
                        defaultValue={description || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
        actions={[
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.edit_action')}`,
            onClick: (event, rowData) => {
              handleEdit(rowData);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
          },
        ]}
      />

      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
      />
    </>
  );
};
