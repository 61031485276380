export const SET_COURSE_FINISHED = '@learn/course_finished';

export function modulesAction(payload) {
  return {
    type: '@learn/MODULES',
    payload,
  };
}

// Emit a event to update learn sidebar
export function setCourseFinishedStatus(payload) {
  return {
    type: SET_COURSE_FINISHED,
    payload,
  };
}
