import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '-30px',
    width: '100%',
    padding: 25,
    paddingTop: 0,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0
    },
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      marginLeft: '6%',
      marginRight: '6%',
    },
  },
  title: {
    color: '#000000',
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      marginBottom: 5,
      marginTop: 5,
    },
  },
  subtitle:{
    color: '#000000',
    fontSize:18,
    fontWeight:600,
    marginBottom:10,
  },
  wrapper:{
    boxShadow: '0px 9px 22px #2a2e4318',
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    height: '0',
    maxWidth: '100%',
    paddingBottom: '56.25%',
    
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: 'none'
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
    marginTop: theme.spacing(2),
    '& button': {
      width: 114,
      height: 31,
      backgroundColor: 'transparent',
      color: '#000000',
      fontSize: 14,
      fontWeight: 600,
      border: 'none',
      outline: 'none',
      borderRadius: 4,
      border: '1px solid #2DCE98',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  buttonsActive:{
    backgroundColor:'#2DCE98 !important',
    color: '#fff !important',
  },
}));
