export const SET_TEAM_DATA = '@new-team/SET_TEAM_DATA';
export const SET_TEAM_STEP = '@new-team/SET_TEAM_STEP';
export const SET_EDIT_DATA = '@new-team/SET_EDIT_DATA';
export const SET_TRAILS = '@new-team/SET_TRAILS';
export const SET_COURSES = '@new-team/SET_COURSES';
export const SET_STUDENTS = '@new-team/SET_STUDENTS';
export const START_TEAM_FORM = '@new-team/START_TEAM_FORM';
export const FINISH_TEAM_FORM = '@new-team/FINISH_TEAM_FORM';
export const SET_TEAM_ID = '@new-team/SET_TEAM_DATA_ID';
export const SET_TEAM_MODAL_OPEN = '@new-team/SET_TEAM_MODAL_OPEN';
export const SET_FETCHING = '@new-team/SET_FETCHING';
export const HANDLE_SELECTION = '@new-team/HANDLE_SELECTION';
export const RESET_FORM = '@new-team/RESET_FORM';

export function startTeamForm(payload) {
  return {
    type: START_TEAM_FORM,
    payload,
  };
}

export function finishTeamForm(payload) {
  return {
    type: FINISH_TEAM_FORM,
    payload,
  };
}

export function setTeamId(payload) {
  return {
    type: SET_TEAM_ID,
    payload,
  };
}

export function setTeam(payload) {
  return {
    type: SET_TEAM_DATA,
    payload,
  };
}

export function setTeamStep(payload) {
  return {
    type: SET_TEAM_STEP,
    payload,
  };
}

export function setEditData(payload) {
  return {
    type: SET_EDIT_DATA,
    payload,
  };
}

export function setTrails(payload) {
  return {
    type: SET_TRAILS,
    payload,
  };
}

export function setCourses(payload) {
  return {
    type: SET_COURSES,
    payload,
  };
}

export function setStudents(payload) {
  return {
    type: SET_STUDENTS,
    payload,
  };
}

export function setTeamModal(payload) {
  return {
    type: SET_TEAM_MODAL_OPEN,
    payload,
  };
}

export function setFetching(payload) {
  return {
    type: SET_FETCHING,
    payload,
  };
}

export function handleSelection(payload) {
  return {
    type: HANDLE_SELECTION,
    payload,
  };
}
