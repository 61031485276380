import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    }
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },

  spacer: {
    flexGrow: 1,
  },

  exportButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));