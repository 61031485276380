import { Box, Button, Typography } from '@material-ui/core';
import {
  AssignmentTurnedInOutlined,
  PlayCircleFilledWhiteOutlined,
  QueryBuilderOutlined,
  SchoolOutlined,
  ShoppingCartOutlined
} from '@material-ui/icons';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import theme from 'theme';
import { useStyles } from './styles';

const BuyKnowledgeTrailCard = ({
  data,
  handleTrailEnroll,
  handleCourse,
  finished,
  progress,
  nextCourseId,
  buttonClicked,
  setButtonClicked,
  showProgress
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['views', 'button', 'cards']);
  const { id: user_id } = useSelector((state) => state.auth);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Box className={classes.card}>
        <Typography className={classes.price}>
          {data?.price > 0
            ? `R$ ${data?.price?.replace('.', ',') || '00,00'}`
            : t('cards:buyCourse.card_free_access')}
        </Typography>
        <Typography className={classes.cardPrice} color="textSecondary">
          {data?.price > 0
            ? `${t('views:CourseRegistration.card_installment_value', {
                value: Math.round(
                  (((data?.price || 0) / 12) * 100) / 100
                ).toFixed(2),
              })}`
            : null}
        </Typography>
        {!buttonClicked && (
         <Box className={classes.buttonBox}>
         <Button
           disabled={!data?.signable && buttonClicked}
           onClick={() => {
             if (nextCourseId) {
               handleCourse(nextCourseId);
               return;
             }

             if (handleTrailEnroll) {
               handleTrailEnroll();
             }
           }}
           variant="contained"
           color="primary"
         >
           {(finished || progress) && user_id?.length ? (
             <>
               <SchoolOutlined
                 style={{ margin: theme.spacing(1), marginLeft: 0 }}
               />
               {t('button:commons.btn_access_now')}
             </>
           ) : (
             <>
               <ShoppingCartOutlined
                 style={{ margin: theme.spacing(1), marginLeft: 0 }}
               />
               {t('button:CourseRegistration.enroll_now')}
             </>
           )}
         </Button>
       </Box>
      )}
      {showProgress && <CircularProgress />}
       
        <Box className={classes.buttonBox}>
          <Typography className={classes.contentTitle}>
            {t('views:TrailRegistration.all_trail_content')}:
          </Typography>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <PlayCircleFilledWhiteOutlined fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {data?.quantity_courses ?? 0}{' '}
                {t('views:CourseRegistration.total_course')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <AssignmentTurnedInOutlined fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {data?.quantity_questions ?? 0}{' '}
                {t('views:CourseRegistration.total_course_exams')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <QueryBuilderOutlined fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {data?.workload ? parseInt(Number(data?.workload)) : 0}{' '}
                {t('views:CourseRegistration.total_course_workload')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BuyKnowledgeTrailCard;
