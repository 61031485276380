import React, { useState } from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useStyles } from './styles';
import SelectFieldsDialog from 'components/SelectFieldsDialog';

export default ({ className, fields, onSave, onReset, ...rest }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button']);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <SelectFieldsDialog
        fields={fields}
        open={open}
        setOpen={setOpen}
        onReset={onReset}
        onSave={onSave}
      />
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <MuiTypography variant="h4">
              {t('label:commons.label_access_quick')}:
            </MuiTypography>
          </Grid>
          <Grid item md={6} xs={12} align="end">
            <Button
              className={classes.exportButton}
              color="primary"
              variant="outlined"
              onClick={() => {
                setOpen(true);
              }}
            >
              {t('button:commons.btn_report_fields')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
