import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { CompanyData } from 'services/dataService';
import { CardCompanyInfo } from '../index';
import { toast } from 'react-toastify';
import { formatCurrency } from 'helpers';

export default ({ buttonSelected }) => {
  const classes = useStyles();
  const { t } = useTranslation(['label', 'toastify']);
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        const response = await CompanyData.getCompanyById(id);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    }

    getData();
  }, []);

  if (!loading)
    return (
      <div className={classes.root}>
             <div className={classes.card}>
               <CardCompanyInfo
                 title={t('tables:course.students_registered_column')}
                 number={data.dashboard.users}
               />
             </div>

             <div className={classes.card}>
             <CardCompanyInfo
               title={t('label:User.label_active_users')}
               number={data.dashboard.users_active}
             />
           </div>

           <div className={classes.card}>
             <CardCompanyInfo
               title={t('label:Company.label_count_courses')}
               number={data.dashboard.courses}
             />
           </div>
           {buttonSelected === 'dataOne' && (
           <div className={classes.card}>
             <CardCompanyInfo
               title={t('cards:commons.card_monthy')}
               number={formatCurrency(1000)}
             />
           </div>
         )}
         </div>
   );
  else return <></>;
};
