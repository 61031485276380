import querystring from 'querystring';
import api from '../api';

class DataServiceSettings {
  getSettingsById(id) {
    return api.get(`settings/${id}`);
  }

  updateSettings(id, data) {
    return api.put(`settings/${id}`, data);
  }
}

export default new DataServiceSettings();
