import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { DocumentMaskCustom } from 'components/DocumentMaskCustom';
import { useStyles } from './styles';
import { SettingsData, CompanyData, FilesData } from 'services/dataService';
import { setOnlyInviteUsers, setAccessRequest } from 'store/modules/company/actions';

export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const [randomCheck, setRandomCheck] = useState(false);
  const [invitedCheck, setInvitedCheck] = useState(false);
  const [enableCheck, setEnableCheck] = useState(false);
  const [document, setDocument] = useState('');
  const [logo, setLogo] = useState();
  const dispatch = useDispatch();
  const [logoUrl, setLogoUrl] = useState();
  const { id_company } = useSelector((state) => state.auth);
  const { register, handleSubmit, control, errors, setValue } = useForm();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);

  const toggleRandomChecked = () => {
    setRandomCheck((prev) => !prev);
  };
  const toggleInvitedChecked = () => {
    setInvitedCheck((prev) => !prev);
  };
  const toggleAccessChecked = () => {
    setEnableCheck((prev) => !prev);
  };

  const handleChange = (event) => {
    setSettings({ ...settings, number_courses_homepage: event.target.value });
  };

  const classes = useStyles();

  const onSubmit = useCallback(
    async (data) => {
      try {
        data.cpf_cnpj.length > 14 ? (data.type = 1) : (data.type = 2);

        if (logo) {
          const responseLogo = await FilesData.addFile(logo, 'General');
          data.id_file_logo = responseLogo.data.id;
        }

        await SettingsData.updateSettings(id_company, data);

        dispatch(setOnlyInviteUsers(data?.only_invited_users_register));
        dispatch(setAccessRequest(data?.access_request));

        toast.success(t('toastify:commons.toast_update'));
      } catch (error) {
        toast.error(t('toastify:commons.toast_error'));
      }
    },
    [id_company, t]
  );

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const response = await SettingsData.getSettingsById(id_company);
        const responseCompany = await CompanyData.getCompanyById(id_company);

        const {
          name,
          fantasy_name,
          cpf_cnpj,
          domain,
        } = responseCompany.data.company;

        if (responseCompany.data.company.logo) {
          setLogoUrl(responseCompany.data.company.logo.url);
        }
        setDocument(cpf_cnpj);

        setTimeout(
          () =>
            setValue([
              { name },
              { domain: domain ? domain : t('label:Company.label_no_domain') },
              {
                fantasy_name: fantasy_name
                  ? fantasy_name
                  : t('label:Company.label_no_fantasy_name'),
              },
              { cpf_cnpj },
            ]),
          500
        );


        setSettings(response.data);
        setRandomCheck(response.data.display_courses_random_order);
        setInvitedCheck(response.data.only_invited_users_register);
        setEnableCheck(response.data.access_request);
      } catch (err) {
        toast.error(t('toastify:commons.toast_error'));
      }
    };
    loadSettings();
    setTimeout(() => setLoading(false), 500);
  }, [id_company]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t('title:Settings.title_settings')} />
      <Divider />
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!loading && (
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}
                style={{ display: 'none' }}
              >
                <InputLabel htmlFor="display_courses_random_order">
                  {t('form:Settings.input_settings_random_order')}
                </InputLabel>
                <Switch
                  id="display_courses_random_order"
                  checked={randomCheck}
                  onChange={toggleRandomChecked}
                  color="primary"
                  inputRef={register}
                  name="display_courses_random_order"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <FormHelperText>
                  {t('form:Settings.input_settings_random_order_info')}
                </FormHelperText>
              </Grid>

              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="only_invited_users_register">
                  {t('form:Settings.input_settings_only_invited_users')}
                </InputLabel>
                <Switch
                  id="only_invited_users_register"
                  checked={invitedCheck}
                  onChange={toggleInvitedChecked}
                  color="primary"
                  inputRef={register}
                  name="only_invited_users_register"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <FormHelperText>
                  {t('form:Settings.input_settings_only_invited_users_info')}
                </FormHelperText>
              </Grid>
              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="access_request">
                  {t('form:Settings.input_settings_only_access_request')}
                </InputLabel>
                <Switch
                  id="access_request"
                  checked={enableCheck}
                  onChange={toggleAccessChecked}
                  color="primary"
                  inputRef={register}
                  name="access_request"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <FormHelperText>
                  {t('form:Settings.input_settings_access_request_info')}
                </FormHelperText>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Settings.input_settings_courses_amount')}
                  helperText={
                    (errors.number_courses_homepage?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Settings.input_settings_courses_amount'),
                      })) ||
                    (errors.number_courses_homepage?.type === 'min' &&
                      t('validation:commons.validation_min_courses_homepage', {
                        min: 9,
                      }))
                  }
                  value={settings.number_courses_homepage || ''}
                  name="number_courses_homepage"
                  type="number"
                  variant="outlined"
                  onChange={handleChange}
                  inputRef={register({ required: true, min: 9 })}
                  error={!!errors.number_courses_homepage}
                />
              </Grid>
              <Grid item md={6} xs={12}
                style={{ display: 'none' }}
              >
                <FormControl style={{ minWidth: 300 }}>
                  <InputLabel id="currency">
                    {t('form:Settings.input_settings_currency')}
                  </InputLabel>

                  {settings && settings.currency ? (
                    <Controller
                      as={
                        <Select>
                          <MenuItem value="BRL">
                            {t('form:Settings.input_settings_currency_BRL')}
                          </MenuItem>
                          <MenuItem value="USD">
                            {t('form:Settings.input_settings_currency_USD')}
                          </MenuItem>
                        </Select>
                      }
                      name="currency"
                      rules={{
                        required: t('validation:commons.validation_select_required'),
                      }}
                      control={control}
                      defaultValue={settings.currency}
                    />
                  ) : null}
                </FormControl>
              </Grid>

              <div style={{ display: 'none' }}>
                <Grid item md={6} xs={12}>
                  <FormControl style={{ minWidth: 300 }}>
                    <InputLabel id="default_language">
                      {t('form:Settings.input_settings_default_language')}
                    </InputLabel>
                    {settings && settings.default_language ? (

                      <Controller
                        as={
                          <Select>
                            <MenuItem value="pt-BR">
                              {t('form:Settings.input_settings_language_brazil')}
                            </MenuItem>
                            <MenuItem value="en-US">
                              {t('form:Settings.input_settings_language_english')}
                            </MenuItem>
                          </Select>
                        }
                        name="default_language"
                        rules={{
                          required: t(
                            'validation:commons.validation_select_required'
                          ),
                        }}
                        control={control}
                        defaultValue={settings.default_language}
                      />
                    ) : null}
                  </FormControl>
                </Grid>
              </div>


              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  disabled={id_company !== '5eaa01a3-4506-4ebb-a23f-e2288e874600'}
                  label={t('form:Company.input_name')}
                  helperText={
                    errors.name?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Company.input_name'),
                    })
                  }
                  name="name"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.name}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  disabled={id_company !== '5eaa01a3-4506-4ebb-a23f-e2288e874600'}
                  label={t('form:Company.input_fantasy_name')}
                  name="fantasy_name"
                  type="text"
                  variant="outlined"
                  inputRef={register}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.fantasy_name}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  disabled={id_company !== '5eaa01a3-4506-4ebb-a23f-e2288e874600'}
                  label={t('form:Company.input_cnpj_cpf')}
                  helperText={
                    (errors.cpf_cnpj?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Company.input_cnpj_cpf'),
                      })) ||
                    (errors.cpf_cnpj?.type === 'minLength' &&
                      t('validation:commons.validation_cnpj_cpf'))
                  }
                  name="cpf_cnpj"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(evt) => {
                    setDocument(evt.target.value);
                  }}
                  value={document}
                  InputProps={{
                    inputComponent: DocumentMaskCustom,
                  }}
                  inputRef={register({
                    required: true,
                    minLength:
                      document.length < 15
                        ? 14
                        : document.length >= 15
                          ? 18
                          : 0,
                  })}
                  error={!!errors.cpf_cnpj}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id="contained-logo-file"
                  name="file_logo"
                  accept="image/png, image/jpeg"
                  onChange={(event) => {
                    setLogo(event.target.files[0]);
                  }}
                />
                {/* <label htmlFor="contained-logo-file">
                  <Button variant="contained" color="primary" component="span">
                    {t('form:Courses.Setting.banner_course')}
                  </Button>
                </label> */}
                {logo ? (
                  <img src={URL.createObjectURL(logo)}></img>
                ) : !logo && logoUrl ? (
                  <img src={logoUrl}></img>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </CardContent>
        )}

        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
          >
            {t('button:commons.btn_save')}
            <Save />
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
