import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconStyle: {
    marginRight: '5px',
    fontSize: '25px'
  },
  [theme.breakpoints.down('sm')]: {
    actions: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    buttonSpace: {
      marginTop: '20px'
    }
  }
}));

export default useStyles;