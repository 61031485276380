import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { AddCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);

  const handleClick = useCallback((route) => {
    history.push(`${route}/create`);
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Grid container spacing={4}>
        <Grid item lg={4} sm={4} xl={4} xs={4}>
          <Typography variant="h3">Acesso Rápido:</Typography>
        </Grid>
        <Grid item lg={8} sm={8} xl={8} xs={8}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleClick('users')}
            className={classes.button}
          >
            {t('button:Dashboard.quick_access_buttons.users')}
            <AddCircle />
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => toast.info('MOCK')}
            className={classes.button}
          >
            {t('button:Dashboard.quick_access_buttons.courses')}
            <AddCircle />
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => handleClick('companies')}
            className={classes.button}
          >
            {t('button:Dashboard.quick_access_buttons.companies')}
            <AddCircle />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
