import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
  },
  content: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    background: '#FBFBFB',
    boxShadow: '0px 3px 6px #DCDCDC',
    width: '100%',
    height: '100%',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  topButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  },
  topButtonSpacing: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  topButton: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      width: '100%',
    },
  },
}));
