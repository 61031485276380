import React, { useState, useEffect, useCallback } from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { CardStatusLBS } from 'views/Librarystud/components/';
import { useStyles } from './styles';
import { UserData, LibraryData } from 'services/dataService';
import {
  Poll,
  AddCircle,
  ImportExport,
  PersonOutline,
  CloudDownloadOutlined
} from '@material-ui/icons';
import { Card } from 'components/Toolbar/';
import { daysInWeek } from 'date-fns';
import { useSelector } from 'react-redux';

const AWS = require('aws-sdk');

export default ({ className, listDataChangeObservable, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({});
  const [downloadLinks, setDownloadLinks] = useState([]);
  const { id: user_id } = useSelector((state) => state.auth);
  const { t } = useTranslation([
    'title',
    'label',
    'form',
    'button',
    'toastify',
  ]);
  const [accessKeyId, setAccessKeyId] = useState();
  const [secretKey, setSecretKey] = useState();
  const [bucket, setBucket] = useState();
  const [region, setRegion] = useState();

  const fetchData = async () => {
    try {
      const responseLib = await LibraryData.getLibrarytop10({
        limit: 10,
        iduser: user_id,
      });

      setDownloadLinks(responseLib.data.library);

    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  // const getFileNameFromS3Url = (s3Url) => {
  //   const parts = s3Url.split('/');
  //   return parts[parts.length - 1];
  // };

  const handleDownload = async (rowData) => {
    try {

      toast.info(t('toastify:commons.toast_start_download_library'));

      const formValues = {
        fileName: rowData.urlfile,
        fileType: rowData.file_type,
        iduser: user_id,
        idlibrary: rowData.id,
      };

      const signedurls3 = await LibraryData.signs3geturlstudent(formValues)

      const response = await fetch(signedurls3.data.signedUrl);
      const blob = await response.blob();

      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = rowData.nameorigem; // You can customize the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading file', error);
      toast.error(t('toastify:commons.toast_error_download_library'));
    }

  };

  useEffect(() => {
    fetchData();
    setLoading(false);

  }, []);


  const classes = useStyles();

  return (
    <>
      {!loading && (
        <div {...rest} className={[clsx(classes.root, className, 'notranslate')]}>
          <Grid container className={classes.quickAccessContainer}>
            <Grid item md={4} xs={12}>
              <MuiTypography variant="h4">
                {t('label:librarystud.label_access_quick')}:
              </MuiTypography>
            </Grid>
            <Grid item md={8} xs={12} className={classes.topButtons}>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.contentCenter}>
            {/* <div className={classes.rowStatus}> */}
            {downloadLinks.map((link) => (

              <div className={classes.divtop10} >
                <a href="#" onClick={(e) => { e.preventDefault(); handleDownload(link); }}>
                  <CloudDownloadOutlined
                    style={{ fontSize: 36 }}
                    color="action" />
                  <br />
                  <h6 className={classes.h6trunc}>
                    {link.title}
                  </h6>
                </a>
              </div>
            ))}

          </Grid>
        </div>
      )}
    </>
  );
};
