import { Grid } from '@material-ui/core';
import { AccessTime, Class, AssignmentTurnedInOutlined, SchoolOutlined } from '@material-ui/icons';
import { Card } from 'components/Toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function TopIndicators({ classes, data }) {
  const { t } = useTranslation(['cards']);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        <Card
          title={`${t('cards:commons.card_enrollment')}`}
          number={data?.enrollmentsCount ?? 0}
          icon={AssignmentTurnedInOutlined}
          iconStyle={classes.blueIcon}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Card
          title={`${t('cards:admin_dashboard.workload')}`}
          number={Math.floor(data?.workload ?? 0)}
          icon={AccessTime}
          iconStyle={classes.greenIcon}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Card
          title={`${t('cards:admin_dashboard.classes')}`}
          number={data?.finishedLessons ?? 0}
          icon={Class}
          iconStyle={classes.greenIcon}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Card
          title={`${t('title:admin_dashboard.finished_courses')}`}
          number={data?.finished ?? 0}
          icon={SchoolOutlined}
          iconStyle={classes.yellowIcon}
        />
      </Grid>
    </Grid>
  );
}
