import 'date-fns';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Slider,
  Switch,
  IconButton,
  Collapse,
  Tooltip,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Save,
  ExpandMore,
  Delete,
  Add,
  Help,
  HelpOutline,
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useStyles } from './styles';
import { ModuleData } from 'services/dataService';
import DateList from './DateList';

export default ({ className, isModalInsert, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const { id, courseId } = useParams();
  const [idCourse, setIdCourse] = useState(courseId);
  const [valueSlider, setValueSlider] = useState(10);
  const [checkedClass, setCheckedClass] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    new Date('2014-08-18T21:11:54')
  );
  const [presentDate, setPresentDate] = useState([
    { date: new Date('2014-08-18T21:11:54'), tutor: '10', local: '', id: 0 },
  ]);

  const { register, handleSubmit, errors, setValue, control } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeSlider = (event, newValue) => {
    setValueSlider(newValue);
  };

  const handleChange = (event, index) => {
    let items = [...presentDate];
    let item = { ...items[index] };
    if (event.target.name === 'local') item.local = event.target.value;
    if (event.target.name === 'tutor') item.tutor = event.target.value;
    items[index] = item;

    // console.log(event.target.value);
    setPresentDate(items);
  };

  const handleChangeData = (date, index) => {
    let items = [...presentDate];
    let item = { ...items[index] };
    item.date = date;
    items[index] = item;

    setPresentDate(items);
  };

  const deletePresentDate = (index) => {
    let items = presentDate.filter((elem, ind) => ind !== index);
    // console.log('index', index, items);
    setPresentDate(items);
  };

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 40,
      label: '40',
    },
    {
      value: 60,
      label: '60',
    },
    {
      value: 80,
      label: '80',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await ModuleData.getModuleById(id);
          const { title, description, id_course } = response.data;
          setIdCourse(id_course);
          setValue([{ title }, { description }]);
        } catch (err) {
          history.push('/lessons');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };

      fetchData();
    }

    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
    try {
      if (id) {
        await ModuleData.updateModule(id, data, courseId);
        toast.success(t('toastify:commons.toast_update'));
        history.push(`/courses/${idCourse}/lessons`);
      } else {
        await ModuleData.addModule({ ...data, id_course: courseId });
        toast.success(t('toastify:commons.toast_success'));
        history.push(`/courses/${courseId}/lessons`);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const titlePage = id
    ? t('title:Module.title_edit')
    : t('title:Module.title_create');

  return (
    <Card {...rest} className={classes.root}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <CardContent>
              <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="h4">
                    Geral
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label={t('form:Lessons.input_title')}
                        name="title"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    }
                    name={'title'}
                    control={control}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label={t('form:Lessons.input_description')}
                        name="description"
                        type="text"
                        variant="outlined"
                        multiline
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    }
                    name={'description'}
                    control={control}
                  />
                </Grid>
              </Grid>

              {/*                 <Grid container spacing={3} className={classes.gridContainer}> */}
              {/*                   <Grid item xs={12}> */}
              {/*                     <Typography color="textSecondary" variant="h4"> */}
              {/*                       Progresso */}
              {/*                     </Typography> */}
              {/*                   </Grid> */}

              {/*                   <Grid item md={5} xs={12}> */}
              {/*                     <Typography variant="h6">Nota mínima para aprovação</Typography> */}
              {/*                     <Slider */}
              {/*                       value={valueSlider} */}
              {/*                       getAriaValueText={(value) => value} */}
              {/*                       aria-labelledby="discrete-slider-always" */}
              {/*                       step={10} */}
              {/*                       marks={marks} */}
              {/*                       valueLabelDisplay="auto" */}
              {/*                       onChange={handleChangeSlider} */}
              {/*                     /> */}
              {/*                     <Typography color="textSecondary" variant="body"> */}
              {/*                       Nota mínima pra que o aluno seja aprovado nesta aula */}
              {/*                     </Typography> */}
              {/*                   </Grid> */}

              {/*                   <Grid item md={2} xs={0} /> */}

              {/*                   <Grid item md={5} xs={12}> */}
              {/*                     <div className={classes.textSwitch}> */}
              {/*                       <Typography variant="h6">Aula extra</Typography> */}
              {/*                       <Switch */}
              {/*                         checked={checkedClass} */}
              {/*                         onChange={(event) => setCheckedClass(event.target.checked)} */}
              {/*                         color="primary" */}
              {/*                         name="checkedClass" */}
              {/*                         inputProps={{ 'aria-label': 'primary checkbox' }} */}
              {/*                   /> */}
              {/*                </div> */}
              {/*                 <Typography color="textSecondary" variant="body"> */}
              {/*                   Aulas extras não são constabilizadas no progresso do aluno */}
              {/*                 </Typography> */}
              {/*               </Grid> */}
              {/*             </Grid> */}

              {/*             <Grid container spacing={3} className={classes.gridContainer}> */}
              {/*               <Grid item xs={12}> */}
              {/*                 <Typography color="textSecondary" variant="h4"> */}
              {/*                   Acesso */}
              {/*                 </Typography> */}
              {/*               </Grid> */}

              {/*               <Grid item md={5} xs={12} className={classes.gridItemEnd}> */}
              {/*                 <Typography variant="h6">Data de publicação da aula</Typography> */}
              {/*                 <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/*                   <KeyboardDatePicker */}
              {/*                     autoOk */}
              {/*                     variant="inline" */}
              {/*                     inputVariant="outlined" */}
              {/*                     format="MM/dd/yyyy" */}
              {/*                     InputAdornmentProps={{ position: 'start' }} */}
              {/*                     value={selectedDate} */}
              {/*                     onChange={(date) => setSelectedDate(date)} */}
              {/*                     KeyboardButtonProps={{ */}
              {/*                       'aria-label': 'change date', */}
              {/*                     }} */}
              {/*                   /> */}
              {/*               </MuiPickersUtilsProvider> */}
              {/*               </Grid> */}

              {/*               <Grid item md={2} xs={0} /> */}

              {/*               <Grid item md={5} xs={12}> */}
              {/*                 <div className={classes.textSecondSwitch}> */}
              {/*                   <div className={classes.rowItems}> */}
              {/*                     <Typography variant="h6">Aula extra</Typography> */}
              {/*                     <Tooltip title="Caso selecionado, insira a data que deseja para despublicar" placement="top-end"> */}
              {/*                       <IconButton size="small"> */}
              {/*                         <HelpOutline */}

              {/*                           style={{ */}
              {/*                             fontSize: '0.8rem', */}
              {/*                           }} */}
              {/*                           fontSize="default" */}
              {/*                         /> */}
              {/*                       </IconButton> */}
              {/*                     </Tooltip> */}
              {/*                   </div> */}
              {/*                   <Switch */}
              {/*                     checked={checkedClass} */}
              {/*                     onChange={(event) => setCheckedClass(event.target.checked)} */}
              {/*                     color="primary" */}
              {/*                     name="checkedClass" */}
              {/*                     inputProps={{ 'aria-label': 'primary checkbox' }} */}
              {/*                   /> */}
              {/*                 </div> */}
              {/*                 <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/*                   <KeyboardDatePicker */}
              {/*                     fullWidth */}
              {/*                     autoOk */}
              {/*                     variant="inline" */}
              {/*                     inputVariant="outlined" */}
              {/*                     format="MM/dd/yyyy" */}
              {/*                     InputAdornmentProps={{ position: 'start' }} */}
              {/*                     value={selectedDate} */}
              {/*                     onChange={(date) => setSelectedDate(date)} */}
              {/*                     KeyboardButtonProps={{ */}
              {/*                       'aria-label': 'change date', */}
              {/*                     }} */}
              {/*                   /> */}
              {/*                 </MuiPickersUtilsProvider> */}
              {/*               </Grid> */}
              {/*               <Grid item md={5} xs={12} className={classes.gridItem}> */}
              {/*                 <div className={classes.textSwitch}> */}
              {/*                   <Typography variant="h6">Aula privada</Typography> */}
              {/*                   <Switch */}
              {/*                     checked={checkedClass} */}
              {/*                     onChange={(event) => setCheckedClass(event.target.checked)} */}
              {/*                     color="primary" */}
              {/*                     name="checkedClass" */}
              {/*                     inputProps={{ 'aria-label': 'primary checkbox' }} */}
              {/*                   /> */}
              {/*                 </div> */}
              {/*                 <Typography color="textSecondary" variant="body"> */}
              {/*                   Se esta aula for marcada como privada, apenas alunos */}
              {/*                   escolhidos pedorão acessá-la */}
              {/*                 </Typography> */}
              {/*               </Grid> */}

              {/*               <Grid item md={2} xs={0} /> */}

              {/*               <Grid item md={5} xs={12} className={classes.gridItem}> */}
              {/*                   <div className={classes.textSwitch}> */}
              {/*                     <Typography variant="h6">Aula gratuita</Typography> */}
              {/*                     <Switch */}
              {/*                       checked={checkedClass} */}
              {/*                       onChange={(event) => setCheckedClass(event.target.checked)} */}
              {/*                       color="primary" */}
              {/*                       name="checkedClass" */}
              {/*                       inputProps={{ 'aria-label': 'primary checkbox' }} */}
              {/*                     /> */}
              {/*                   </div> */}
              {/*                   <Typography color="textSecondary" variant="body"> */}
              {/*                     Aulas extras não são constabilizadas no progresso do aluno */}
              {/*                   </Typography> */}
              {/*                 </Grid> */}

              {/*               <Grid item md={5} xs={12}> */}
              {/*                 <div className={classes.textSwitch}> */}
              {/*                   <Typography variant="h6">Agendar liberação</Typography> */}
              {/*                   <Switch */}
              {/*                     checked={checkedClass} */}
              {/*                     onChange={(event) => setCheckedClass(event.target.checked)} */}
              {/*                     color="primary" */}
              {/*                     name="checkedClass" */}
              {/*                     inputProps={{ 'aria-label': 'primary checkbox' }} */}
              {/*                   /> */}
              {/*                 </div> */}
              {/*                 <Typography color="textSecondary" variant="body"> */}
              {/*                      Aulas extras não são constabilizadas no progresso do aluno */}
              {/*                  </Typography> */}
              {/*                </Grid> */}

              {/*                <Grid item md={2} xs={0} /> */}

              {/*                <Grid item md={5} xs={12} className={classes.gridItem}> */}
              {/*                  <Typography variant="h6">Data e hora de liberação</Typography> */}
              {/*                  <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/*                    <KeyboardDatePicker */}
              {/*                      autoOk */}
              {/*                      variant="inline" */}
              {/*                      inputVariant="outlined" */}
              {/*                      format="MM/dd/yyyy" */}
              {/*                      InputAdornmentProps={{ position: 'start' }} */}
              {/*                      value={selectedDate} */}
              {/*                      onChange={(date) => setSelectedDate(date)} */}
              {/*                      KeyboardButtonProps={{ */}
              {/*                        'aria-label': 'change date', */}
              {/*                      }} */}
              {/*                      className={classes.marginBot} */}
              {/*                    /> */}
              {/*                  </MuiPickersUtilsProvider> */}

              {/*                  <Typography variant="h6"> */}
              {/*                    Dias após a matrícula de liberação */}
              {/*                  </Typography> */}
              {/*                  <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              {/*                    <KeyboardDatePicker */}
              {/*                      autoOk */}
              {/*                      variant="inline" */}
              {/*                      inputVariant="outlined" */}
              {/*                      format="MM/dd/yyyy" */}
              {/*                      InputAdornmentProps={{ position: 'start' }} */}
              {/*                      value={selectedDate} */}
              {/*                      onChange={(date) => setSelectedDate(date)} */}
              {/*                      KeyboardButtonProps={{ */}
              {/*                        'aria-label': 'change date', */}
              {/*                      }} */}
              {/*                    /> */}
              {/*                  </MuiPickersUtilsProvider> */}
              {/*                </Grid> */}
              {/*              </Grid> */}
              {/*              <Grid container spacing={3} className={classes.gridContainer}> */}
              {/*                <Grid item xs={12}> */}
              {/*                  <Typography color="textSecondary" variant="h4"> */}
              {/*                    Extra */}
              {/*                  </Typography> */}
              {/*                </Grid> */}

              {/*                <Grid item md={5} xs={12}> */}
              {/*                  <div className={classes.textSwitch}> */}
              {/*                    <Typography variant="h6">Habilitar comentários</Typography> */}
              {/*                    <Switch */}
              {/*                      checked={checkedClass} */}
              {/*                      onChange={(event) => setCheckedClass(event.target.checked)} */}
              {/*                      color="primary" */}
              {/*                      name="checkedClass" */}
              {/*                      inputProps={{ 'aria-label': 'primary checkbox' }} */}
              {/*                    /> */}
              {/*                  </div> */}
              {/*                  <Typography color="textSecondary" variant="body"> */}
              {/*                    Se desmarcado, irá remover o acesso ao fórum dentro de todos */}
              {/*                    os conteúdos desta aula. */}
              {/*                  </Typography> */}
              {/*                </Grid> */}

              {/*                <Grid item xs={12}> */}
              {/*                  <Card> */}
              {/*                    <CardHeader */}
              {/*                      action={ */}
              {/*                        <div> */}
              {/*                          <Button */}
              {/*                            variant="outlined" */}
              {/*                            color="secondary" */}
              {/*                            onClick={() => */}
              {/*                              setPresentDate([ */}
              {/*                                ...presentDate, */}
              {/*                                 { */}
              {/*                                  date: '', */}
              {/*                                  tutor: '10', */}
              {/*                                  local: '', */}
              {/*                                  id: presentDate.length, */}
              {/*                                 }, */}
              {/*                              ]) */}
              {/*                            } */}
              {/*                          > */}
              {/*                            <Add style={{ marginRight: '5px' }} /> */}
              {/*                            Nova data */}
              {/*                          </Button> */}
              {/*                          <IconButton */}
              {/*                            className={clsx(classes.expand, { */}
              {/*                              [classes.expandOpen]: expanded, */}
              {/*                            })} */}
              {/*                            onClick={handleExpandClick} */}
              {/*                            aria-expanded={expanded} */}
              {/*                            aria-label="show more" */}
              {/*                          > */}
              {/*                            <ExpandMore /> */}
              {/*                          </IconButton> */}
              {/*                        </div> */}
              {/*                      } */}
              {/*                      title="Datas presenciais" */}
              {/*                    /> */}
              {/*                    <Divider /> */}
              {/*                    <Collapse in={expanded} timeout="auto" unmountOnExit> */}
              {/*                      <CardContent> */}
              {/*                        {presentDate.map((elem, index) => { */}
              {/*                          return ( */}
              {/*                            <DateList */}
              {/*                              key={elem.id} */}
              {/*                              handleChange={handleChange} */}
              {/*                              deletePresentDate={deletePresentDate} */}
              {/*                              handleChangeData={handleChangeData} */}
              {/*                              elem={elem} */}
              {/*                              index={index} */}
              {/*                            /> */}
              {/*                          ); */}
              {/*                        })} */}
              {/*                      </CardContent> */}
              {/*                    </Collapse> */}
              {/*                  </Card> */}
              {/*                </Grid> */}
              {/*              </Grid> */}
            </CardContent>

            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </div>
        </form>
      )}
    </Card>
  );
};
