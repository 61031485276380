import api from '../api';

class DataServiceMyUser {
  addUser(data) {
    return api.post('myUser', data);
  }

  getUsersById(id) {
    return api.get(`myUser/${id}`);
  }

  updateUser(id, data) {
    return api.put(`myUser/${id}`, data);
  }
}

export default new DataServiceMyUser();
