import { Box, Button, Typography } from '@material-ui/core';
import {
    StarOutlineRounded, StarRounded
} from '@material-ui/icons';
import { toast } from 'react-toastify';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useStyles } from './styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import { RatingData } from 'services/dataService';
import { format, parseISO } from 'date-fns';
import { red } from '@material-ui/core/colors';


const ModalPlatform = ({ setOpen, company, user, handleSubmitCallback }) => {
    const { t } = useTranslation(['title', 'button', 'views']);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = React.useState(0);
    const [hover, setHover] = React.useState(-1);
    const [feedback, setFeedback] = React.useState(false);


    const classes = useStyles();

    const text = useRef(null);
    const textFeedback = useRef(null);

    const labels = {
        1: 'Muito Insatisfeito',
        2: 'Insatisfeito',
        3: 'Razoável',
        4: 'Satisfeito',
        5: 'Muito Satisfeito',
    };


    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = async () => {
        if (value < 1 || text.current.textContent.trim() === '') {
            toast.error(t('toastify:commons.toast_required_comment_plataform_star'));
            return;
        }
        if (value === 1 || value === 2) {
            setFeedback(true);
            if (!textFeedback.current || !textFeedback.current.textContent.trim()) {
                toast.error(t('toastify:commons.toast_required_comment_plataform'));
                setIsSubmitting(false);
                return;
            }
        }
        try {
            setIsSubmitting(true);
            await RatingData.addRatingPlatform({
                company: company,
                user: user,
                rating: value,
                comment_feedback: !textFeedback.current ? null : textFeedback.current.innerText.trim(),
                comment: text.current.innerText.trim(),
                show_modal: true,
                date_now: format(new Date(), 'MM-yyyy'),
            });
            toast.success(t('toastify:commons.toast_success_rating'));
            handleClose();


        } catch (error) {
            if (value === null) {
                toast.error(t('toastify:commons.toast_no_rating'));
            } else {
                toast.error(t('toastify:commons.toast_error', error));
            }
        } finally {
            setIsSubmitting(false);
            if (handleSubmitCallback) {
                handleSubmitCallback(true);
            }
        }
    }


    return (
        <div className=''>
            <Dialog className='divModalcentralize-content'
                open={setOpen}
                onClose={handleClose}
                BackdropClick={false}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <DialogTitle>
                    <span
                        style={{
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                            fontSize: '16px',
                            opacity: 0.7
                        }}
                    >
                        Como está sua experiência com a plataforma?
                    </span>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className={classes.replyText}>
                            <Typography>
                                {value !== null && (
                                    <Box>{labels[hover !== -1 ? hover : value]}</Box>
                                )}
                                <Box sx={{ position: 'relative' }}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -30,
                                            left: 0,
                                            opacity: value !== null ? 1 : 0,
                                            transition: 'opacity 0.3s ease-in-out',
                                        }}
                                    >
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Rating
                                            name="hover-feedback"
                                            icon={<StarRounded className={classes.starFull} />}
                                            value={value}
                                            precision={1}
                                            getLabelText={getLabelText}
                                            onChange={(event, newValue) => {
                                                setValue(newValue === null ? value : newValue);
                                            }}
                                            onChangeActive={(event, newHover) => {
                                                setHover(newHover);
                                            }}
                                            emptyIcon={<StarOutlineRounded className={classes.starEmpty} />}
                                        />
                                    </Box>
                                </Box>
                            </Typography>
                        </div>
                    </DialogContentText>
                    {feedback && (
                        <>
                            <DialogContentText>
                                <Typography
                                    variant="h6"
                                    style={{
                                        marginTop: "25px",
                                        marginBottom: "10px",
                                        fontSize: "15px"
                                    }}
                                >
                                    Qual a sua insatisfação com a plataforma?
                                </Typography>
                                <div
                                    ref={textFeedback}
                                    className={clsx([classes.content, classes.shadow])}
                                    contentEditable={true}
                                ></div>
                            </DialogContentText>
                            <Typography variant='h6' style={{ color: 'red' }}>*Por favor, nos diga qual o motivo de sua insatisfação.</Typography>
                        </>
                    )}
                    <DialogContentText>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: "25px",
                                marginBottom: "10px",
                                fontSize: "15px"
                            }}
                        >
                            Quais conteúdos você gostaria de encontrar aqui?
                        </Typography>
                        <div
                            ref={text}
                            className={clsx([classes.content, classes.shadow])}
                            contentEditable={true}
                        ></div>
                    </DialogContentText>
                    {/* <DialogContentText>
                        <Typography
                            variant="h6"
                            style={{
                                marginBottom: "10px",
                                fontSize: "15px"
                            }}
                        >
                            *Para melhorias contínuas no conteúdo e na plataforma, essa pesquisa será realizada mensalmente.
                        </Typography>
                    </DialogContentText> */}
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        size="large"
                        style={{
                            marginTop: "15px",
                            width: "33%"
                        }}
                        disabled={isSubmitting} // Desabilitar o botão enquanto estiver enviando

                    >
                        {t('button:commons.btn_send')}
                    </Button>
                </DialogContent>
            </Dialog >
        </div >
    );
};

export default ModalPlatform;