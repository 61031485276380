import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export const options = {
  responsive: true,
  legend: {
    display: false,
  },
  title: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export function TopUsersChart({ data, backgroundColor, idTeam, type }) {
  const { t } = useTranslation(['label']);

  const sortedData = data?.sort((a, b) => b.finishedCount - a.finishedCount);

  let topTenUsers;

  if (idTeam?.length > 0 && type === 'teams') {
    topTenUsers = sortedData?.slice(0, 1);
  } else {
    topTenUsers = sortedData?.slice(0, 10);
  }

  const labels = topTenUsers?.map((item) => item.name);
  const values = topTenUsers?.map((item) => item.finishedCount);

  const chartData = {
    labels,
    datasets: [
      {
        hoverBackgroundColor: '#1d4f79',
        data: values,
        backgroundColor,
      },
    ],
  };

  return <HorizontalBar options={options} data={chartData} />;
}
