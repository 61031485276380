import React from 'react';

import { CategoryToolbar, CategoryList } from 'views/Category/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CategoryToolbar />
      <div className={classes.content}>
        <CategoryList />
      </div>
    </div>
  );
};
