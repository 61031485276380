import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

export function ChartCard({ title, showAddButton, children, onAdd }) {
  const classes = useStyles();
  return (
    <Card
      style={{
        minHeight: '10px',
        pageBreakInside: 'avoid',
        position: 'relative',
        pageBreakBefore: 'always'
      }} 
      className={classes.card}>
      <div 
        className={classes.cardHeader}>
        <Typography className={classes.cardTitle}>{title}</Typography>
        {showAddButton && (
          <IconButton className={classes.addButton} onClick={onAdd}>
            <Add />
          </IconButton>
        )}
      </div>
      <CardContent
        style={{ paddingTop: '0px' }}
        className={classes.cardContent}
      >
        {children}
      </CardContent>
    </Card>
  );
}

export const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: 'flex',
    padding: '15px 15px 15px 15px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    fontWeight: '500',
    fontSize: '1rem',
  },
  cardContent: {
    height: '100%'
  },
  addButton: {
    color: 'white',
    background: '#2DCE98',
    padding: 0,
    width: '20px',
    height: '20px',
    '& svg': {
      fontSize: '1.1rem',
    },
    '@media print': {
      display: 'none'
    }
  },
}));
