import { Box, Grid, Switch, TextField } from '@material-ui/core';
import React from 'react';

const SocialMediaTextfield = ({
  name,
  register,
  icon,
  defaultChecked,
  defaultValue,
  type,
}) => {
  const [textFieldEnabled, setTextFieldEnabled] = React.useState(
    defaultChecked
  );
  return (
    <Box>
      <TextField
        name={`${name}.type`}
        value={type}
        style={{ display: 'none' }}
        inputRef={register}
      />
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>{icon}</Grid>
        <Grid item xs={6} md={6} style={{ alignSelf: 'center' }}>
          <TextField
            fullWidth
            disabled={!textFieldEnabled}
            name={`${name}.link`}
            defaultValue={defaultValue}
            color="primary"
            inputRef={register}
          />
        </Grid>
        <Grid item xs={2} md={2} style={{ textAlign: 'end' }}>
          <Switch
            name={`${name}.status`}
            defaultChecked={defaultChecked}
            inputRef={register}
            onChange={(_, checked) => {
              setTextFieldEnabled(checked);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default SocialMediaTextfield;
