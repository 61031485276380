import produce from 'immer';

const INITIAL_STATE = {
  notifications: [],
  latests: [],
  unreaded: 0,
  loading: false,
};

export default function notification(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@notification/NOTIFICATION_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@notification/NOTIFICATION_SUCCESS': {
        draft.notifications = action.payload.notifications;
        draft.unreaded = action.payload.unreaded;
        draft.latests = action.payload.latests;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
