import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import { number } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomHelper from '../CustomHelperText';
const FeaturedContent = ({ form, featured_content }) => {
  const { register, setValue, watch } = form;
  const { t } = useTranslation(['views', 'button']);
  const [showContent, setShowContent] = React.useState(true);
  const [showCtaButton, setShowCtaButton] = React.useState(true);

  const [refresh, setRefresh] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const values = watch('featured_content');

  const classes = useStyles();

  React.useEffect(
    () => {
      setShowCtaButton(featured_content?.ctaButtons?.length ? true : false);
    },
    featured_content,
    values
  );
  return (
    <Box>
      <Box className={classes.headerRoot}>
        <Box style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.header} color="textSecondary">
            {t('views:CompanyCustomization.featured_content')}
          </Typography>
        </Box>
        <Box style={{ width: '10%' }}>
          <Switch
            // name="featured_content_validation.has_featured_content_buttons"
            // inputRef={register}
            checked={showContent}
            onChange={(_, checked) => {
              setShowContent(checked);
            }}
          />
        </Box>
      </Box>
      <Box>
        {showContent && (
          <Box className={classes.contentRoot}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className={classes.headerRoot}>
                  <Box
                    style={{
                      width: '75%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography className={classes.switchLabel}>
                        {t(
                          'views:CompanyCustomization.Show_search_field_label'
                        )}
                      </Typography>
                      <CustomHelper
                        text={t(
                          'views:CompanyCustomization.Show_search_field_helper'
                        )}
                      />
                    </Box>
                  </Box>
                  <Box style={{ width: '25%' }}>
                    <Switch
                      name="featured_content.show_search"
                      defaultChecked={featured_content?.show_search}
                      inputRef={register}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.headerRoot}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} md={8}>
                      <Typography className={classes.switchLabel}>
                        {t(
                          'views:CompanyCustomization.featured_quantity_label'
                        )}
                      </Typography>
                      <CustomHelper
                        text={t(
                          'views:CompanyCustomization.featured_quantity_helper'
                        )}
                      />
                    </Grid>
                    <Grid item xs={2} md={2} />
                    <Grid item xs={2} md={2}>
                      <TextField
                        name="featured_content.quantity"
                        defaultValue={featured_content?.quantity}
                        type="number"
                        variant="outlined"
                        inputRef={register}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.contentRoot} style={{ display: 'flex' }}>
              <Box style={{ width: '37%' }}>
                <Typography className={classes.spanHeader}>
                  {t('views:CompanyCustomization.cta_buttons')}
                </Typography>
                <CustomHelper
                  text={t('views:CompanyCustomization.cta_buttons_description')}
                />
              </Box>
              <Box style={{ width: '10%' }}>
                <Switch
                  // name="featured_content_validation.has_featured_content"
                  // inputRef={register}
                  color="secondary"
                  checked={showCtaButton}
                  onChange={(_, checked) => {
                    setShowCtaButton(checked);
                  }}
                />
              </Box>
            </Box>
            {showCtaButton && (
              <Grid container>
                <Grid item xs={12} md={10}>
                  <Box>
                    {selected.length > 0 && (
                      <Box>
                        <Button
                          color="primary"
                          onClick={() => {
                            featured_content.ctaButtons = values?.ctaButtons?.filter(
                              (btn, index) => !selected.includes(index)
                            );
                            setSelected([]);
                            setRefresh(!refresh);
                          }}
                        >
                          {t('button:CompanyCustomization.remove_selected')}
                        </Button>
                      </Box>
                    )}
                    <Grid container spacing={2}>
                      {featured_content?.ctaButtons?.map((_, index) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={classes.ctaButtonSpacing}
                        >
                          <Box
                            className={
                              selected.includes(index) && classes.select
                            }
                          >
                            <Checkbox
                              checked={selected.includes(index)}
                              onChange={(e, checked) => {
                                if (checked) {
                                  selected.push(index);
                                } else {
                                  setSelected(
                                    selected.filter((i) => i !== index)
                                  );
                                }
                                setRefresh(!refresh);
                              }}
                            />
                          </Box>
                          <Box
                            className={clsx(
                              selected.includes(index) && classes.selected,
                              classes.colorTopSpacing
                            )}
                          >
                            <Box>
                              <TextField
                                variant="outlined"
                                key={index}
                                name={`featured_content.ctaButtons[${index}].text`}
                                label={t(
                                  'views:CompanyCustomization.cta_button_text'
                                )}
                                defaultValue={_?.text}
                                inputRef={register}
                              />
                              <CustomHelper
                                text={t(
                                  'views:CompanyCustomization.cta_button_text'
                                )}
                              />
                            </Box>
                            <Box>
                              <Grid
                                container
                                spacing={2}
                                className={classes.colorTopSpacing}
                              >
                                <Grid item xs={12} md={4}>
                                  <CustomColorPicker
                                    name={`featured_content.ctaButtons[${index}].text_color`}
                                    register={register}
                                    title={t(
                                      'views:CompanyCustomization.cta_button_text_color'
                                    )}
                                    defaultValue={_?.text_color}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <CustomColorPicker
                                    name={`featured_content.ctaButtons[${index}].color`}
                                    register={register}
                                    title={t(
                                      'views:CompanyCustomization.cta_button_color'
                                    )}
                                    defaultValue={_?.color}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <CustomColorPicker
                                    name={`featured_content.ctaButtons[${index}].border_color`}
                                    register={register}
                                    title={t(
                                      'views:CompanyCustomization.cta_button_border_color'
                                    )}
                                    defaultValue={_?.border_color}
                                  />
                                </Grid>
                              </Grid>
                              <Box className={classes.colorTopSpacing}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  name={`featured_content.ctaButtons[${index}].link`}
                                  label={t(
                                    'views:CompanyCustomization.navigation_link'
                                  )}
                                  defaultValue={_?.link}
                                  inputRef={register}
                                />
                                <CustomHelper
                                  text={t(
                                    'views:CompanyCustomization.navigation_link_helper'
                                  )}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        featured_content.ctaButtons.push({
                          text: '',
                          color: '#000000',
                          text_color: '#000000',
                          border_color: '#000000',
                          link: '',
                        });
                        setRefresh(!refresh);
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
  },
  header: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  contentRoot: {
    paddingTop: theme.spacing(3),
  },
  switchLabel: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  ctaButtonSpacing: {
    padding: theme.spacing(2),
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  select: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  selected: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
  },
}));

export default FeaturedContent;
