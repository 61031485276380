import { Box, Button, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomSlider from 'components/CustomSlider';
import Interweave from 'interweave';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const TrailsSlider = ({
  finished,
  trails,
  onActionClickGoToTrail,
  slidesNumber,
  ...props
}) => {
  const { t } = useTranslation(['button', 'views']);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const classes = useStyles();

  return (
    <CustomSlider
      itens={trails}
      slidesNumber={slidesNumber}
      slidesToShow={slidesToShow}
      setSlidesToShow={setSlidesToShow}
      slideTeste={finished}
      {...props}
    >
      {trails
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        ?.map((trail, index) => {
          return (
            <div key={index} className={classes.slidesShow}>
              <Box
                className={classes.card}
                style={{ width: slidesToShow <= 2 ? 350 : 'unset' }}
              >
                <Box>
                  <Link
                    onClick={() => {
                      if (onActionClickGoToTrail) {
                        onActionClickGoToTrail(
                          trail?.id,
                          'progress',
                          trail?.id_course
                        );
                      }
                    }}
                  >
                  <img
                    className={classes.media}
                    src={
                      trail.banner
                        ? trail.banner
                        : 'https://via.placeholder.com/350x208'
                    }
                  />
                  </Link>
                  <Box className={classes.cardBody}>
                    <Typography component="span" className={classes.header}>
                      {trail.name}
                    </Typography>
                    <Box className={classes.description}>
                      <Interweave
                        content={
                          trail?.description?.length ? trail?.description : '-'
                        }
                      />
                    </Box>
                    <Box className={classes.action}>
                      <Button
                        onClick={() => {
                          if (onActionClickGoToTrail) {
                            onActionClickGoToTrail(
                              trail?.id,
                              'finished',
                              trail?.id_course
                            );
                          }
                        }}
                        className={classes.actionButtonColor}
                        size="medium"
                      >
                        <InfoOutlinedIcon className={classes.actionIcon} />
                        {t('views:TrailRegistration.go_to_trail')}
                      </Button>

                      <Button
                        size="medium"
                        color="primary"
                        className={classes.actionButton}
                        disabled={!finished}
                        onClick={() => {
                          if (onActionClickGoToTrail) {
                            onActionClickGoToTrail(
                              trail?.id,
                              'finished',
                              trail?.id_course
                            );
                          }
                        }}
                      >
                        {finished && t(`button:StudentContent.btn_completed`)}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          );
        })}
    </CustomSlider>
  );
};
export default TrailsSlider;
