import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  EnrollmentData,
  LessonContentData,
  LessonData,
  ModuleData,
} from 'services/dataService';
import CourseRegistrationData from 'services/dataService/CourseRegistrationData';
import FavorityCourseData from 'services/dataService/FavorityCourseData';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';
import history from 'services/history';
import theme from 'theme';
import LoginFooter from 'views/Welcome/components/Footer';
import CertificateDetails from './components/Certificate';
import CourseHeader from './components/CourseHeader';
import CourseInformation from './components/CourseInformation';
import InformationFooter from './components/InformationFooter';
import RelatedCourses from './components/RelatedCourses';

const CourseRegistration = () => {
  const { id: id_user } = useSelector((state) => state?.auth);
  const { id: id_company_course } = useParams();
  const [courseData, setCourseData] = React.useState();
  const [customization, setCustomization] = React.useState();
  const [modules, setModules] = React.useState([]);
  const { t } = useTranslation(['toastify']);
  const { auth, company } = useSelector((state) => state);
  const [courseBuy, setCourseBuy] = React.useState(false);
  const [courseActive, setCourseActive] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(false);


  const getCourseInfo = async () => {
    const domain = window.location.hostname;

    const response = await CourseRegistrationData.getCourseInfo(
      id_company_course,
      { id_user, domain }
    );
    setCourseData(response.data);
    getCourseLessons(response.data?.id_course);
  };

  const getCourseLessons = async (id_course) => {
    const modules = (await ModuleData.getModuleByIdCourse(id_course)).data
      ?.modules;

    for (const course_module of modules) {
      course_module.lessons = await Promise.all(
        course_module?.lessons?.map(async (lesson) => {
          const course_lesson = (await LessonData.getLessonById(lesson?.id))
            .data;
          const course_lesson_content = (
            await LessonContentData.getLessonContent(lesson?.id)
          ).data.contents?.filter(
            (content) => content?.id_lesson === lesson?.id
          );

          return {
            ...course_lesson,
            course: course_lesson?.course?.name,
            content: course_lesson_content,
          };
        })
      );
    }
    setModules(modules);
  };

  const checkEnrollment = async () => {
    try {
      const resp = await EnrollmentData.getEnrollmentByUserAndCourse(id_user, id_company_course);
      if (resp?.data?.active === true) {
        setCourseActive(true)
      }
    } catch (error) {
      setCourseBuy(true);
    }
  }

  const onCourseBuy = async () => {
    if (buttonClicked) {
      return; 
    };
    setButtonClicked(true);

    try {
      if (!auth?.signed) {
        // redirect to login
        history.push('/');
      }
      else if (courseBuy === false && courseActive === true) {
        const { data: response } = await CourseRegistrationData.getCourseInfo(
          id_company_course,
          { id_user }
        );

        history.push(`/course/${response.id_course}/learn`);
      }
      else if (
        courseData?.financial?.free ||
        !Number.parseFloat(courseData?.financial?.price || 0) > 0
      ) {
        // enroll the free course
        const { data: enrollment } = await EnrollmentData.addEnrollment(
          {
            id_company_course,
            id_user,
            user_buy: true,
          },
          { return_id_course: 'true' }
        );
        toast.success(t('toastify:CourseRegistration.registration_sucess'));
        history.push(`/course/${enrollment.id_course}/learn`);
      } else {
        // redirect to buy course
        history.push(`/course/${id_company_course}/buy`);
      }
    } catch (error) {
      if (error?.response?.data?.notSignableCourse) {
        toast.error(t('toastify:CourseRegistration.not_not_signable_course'));
      } else {
        toast.error(t('toastify:CourseRegistration.registration_error'));
      }
    }
  };

  const onCourseLiked = async (liked) => {
    try {
      if (!id_user) {
        return;
      }
      if (liked) {
        await FavorityCourseData.favorityCourse(id_user, id_company_course);
      } else {
        await FavorityCourseData.removeCourseFavority(
          id_user,
          id_company_course
        );
      }

      setCourseData({
        ...courseData,
        information: { ...courseData.information, liked_course: liked },
      });
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const onRelatedCoursefavority = async (course_id, liked) => {
    try {
      if (!id_user) {
        return;
      }
      if (liked) {
        await FavorityCourseData.favorityCourse(id_user, course_id);
      } else {
        await FavorityCourseData.removeCourseFavority(id_user, course_id);
      }

      setCourseData({
        ...courseData,
        relatedCourses: courseData.relatedCourses.map((course) => {
          if (course_id === course.id_company_course) {
            return {
              ...course,
              liked_course: liked,
            };
          } else {
            return { ...course };
          }
        }),
      });
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const getCompanyFooter = async () => {
    try {
      const {
        data: companyCustomization,
      } = await PublicCoursesViewsData.getCompanyCustomization(
        company?.domain_company
      );
      setCustomization(companyCustomization);
    } catch (error) { }
  };

  React.useEffect(() => {
    getCourseInfo();
    getCompanyFooter();
    checkEnrollment();

    if (document.querySelector('#root')) {
      document.querySelector('#root > div:nth-child(2)').scrollTop = 0;
    }
  }, []);

  return (
    <Box>
      <CourseHeader
        info={courseData?.information}
        icon={courseData?.cardUrl}
        financial={courseData?.financial}
        onLike={onCourseLiked}
        onCourseBuy={onCourseBuy}
        signable={courseData?.signable}
        courseBlocked={courseActive}
        ableBuy={courseBuy}
      />
      <Box>
        <CourseInformation
          modules={modules}
          details={courseData?.courseDetails}
        />
      </Box>
      <Box>
        <CertificateDetails />
      </Box>

      {courseData?.relatedCourses.length > 0 ? (
        <Box>
          <RelatedCourses
            onLike={onRelatedCoursefavority}
            relatedCourses={courseData?.relatedCourses}
          />
        </Box>
      ) : null}

      <Box>
        <InformationFooter />
      </Box>
      <Typography style={{ paddingTop: theme.spacing(6) }} />
      {!auth?.signed && <LoginFooter footer={customization?.company_footer} />}
    </Box>
  );
};

export default CourseRegistration;
