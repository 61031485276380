import useWindowDimensions from 'customHooks/DimensionHook';
import { useStyles } from './styles';
import React from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CustomSlider = ({
  children,
  itens,
  slidesNumber,
  setSlidesToShow,
  slidesToShow,
  slideTeste,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const [slidesToScroll, setSlidesToScroll] = React.useState(4);
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: width <= 600 ? 1 : slidesToShow,  
    slidesToScroll: width <= 600 ? 1 : slidesToScroll,
  };

  React.useEffect(() => {
    if (slidesNumber) {
      setSlidesToShow(slidesNumber);
      setSlidesToScroll(slidesNumber);
      return;
    }
    if (slideTeste) {
      if (itens?.length === 3) {
        setSlidesToShow(3);
        setSlidesToScroll(3);
      } else if (itens?.length < 5 && width > 1500) {
        setSlidesToShow(Math.trunc(itens?.length));
        setSlidesToScroll(Math.trunc((itens?.length + 2) / 2));
      } else if (itens?.length < 3 && width > 960) {
        setSlidesToShow(Math.trunc((itens?.length + 2) / 2));
        setSlidesToScroll(Math.trunc((itens?.length + 2) / 2));
      } else {
        setSlidesToShow(
          width > 1920
            ? 6
            : width <= 1920 && width > 1500
              ? 4
              : width <= 1500 && width > 1150
                ? 3
                : width <= 1150 && width > 960
                  ? 2
                  : 1
        );
        setSlidesToScroll(
          width > 1920
            ? 6
            : width <= 1920 && width > 1500
              ? 4
              : width <= 1500 && width > 1150
                ? 3
                : width <= 1150 && width > 960
                  ? 2
                  : 1
        );
      }
    } else if (itens?.length === 3) {
      setSlidesToShow(3);
      setSlidesToScroll(3);
    } else if (itens?.length < 5 && width > 1500) {
      setSlidesToShow(Math.trunc(itens?.length));
      setSlidesToScroll(Math.trunc((itens?.length + 2) / 2));
    } else if (itens?.length < 3 && width > 960) {
      setSlidesToShow(Math.trunc((itens?.length + 2) / 2));
      setSlidesToScroll(Math.trunc((itens?.length + 2) / 2));
    } else {
      setSlidesToShow(
        width > 1920
          ? 6
          : width <= 1920 && width > 1500
            ? 4
            : width <= 1500 && width > 1150
              ? 4
              : width <= 1150 && width > 960
                ? 2
                : 1
      );
      setSlidesToScroll(
        width > 1920
          ? 6
          : width <= 1920 && width > 1500
            ? 4
            : width <= 1500 && width > 1150
              ? 4
              : width <= 1150 && width > 960
                ? 2
                : 1
      );
    }

    if (width <= 600) {
      setSlidesToShow(1);
      setSlidesToScroll(1);
    }
  }, [width, itens]);


  return (
    <div className={classes.root} {...props}>
      {itens && itens.length > 0 && (
        <Slider
          {...settings}
          prevArrow={<ArrowBackIos color="action" />}
          nextArrow={<ArrowForwardIos color="action" />}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          variableWidth={false}
          className={classes.arrows}
        >
          {children}
        </Slider>
      )}
    </div>
  );
};

export default CustomSlider;