import api from '../api';

class PublicCoursesViewsData {
  getCompanyCustomization(company) {
    return api.get('/welcome/customization', {
      headers: { company, display_empty_button: true },
    });
  }

  getExternalFeaturedCourses(company) {
    return api.get('/welcome/external-courses', {
      headers: { company, curse_details: true },
    });
  }
}

export default new PublicCoursesViewsData();
