import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Editor } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import EmailEditorComponent from 'views/Setting/Email/components/editor/index';
import React from 'react';
import { FilesData } from 'services/dataService';

const NewTeacher = ({
  isSelect,
  selectFields,
  register,
  name,
  image_id,
  image_url,
  teacher_id,
  teacher_description,
  read_only,
  readOnlyOnTeacherSelected,
}) => {
  const fileInputRef = React.useRef();
  const [imageId, setImageId] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [comboTeacherId, setComboTeacherId] = React.useState('');
  const [teacher_user_id, set_teacher_user_id] = React.useState('');

  const loadImage = () => {
    setImageId(image_id);
    setImageUrl(image_url);
  };

  const saveImage = async (file) => {
    if (!file) {
      return;
    }
    const { data } = await FilesData.addFile(file, 'Profile');
    setImageUrl(data?.url);
    setImageId(data?.id);
  };
  const loadData = () => {
    setComboTeacherId(teacher_id || null);
    setDescription(teacher_description || '');
  };

  const updateAuthor = async (id) => {
    if (read_only && readOnlyOnTeacherSelected) {
      const teacher = await readOnlyOnTeacherSelected(id);
      setDescription(teacher?.User?.teacher_description);
      setImageId(teacher?.User?.avatar?.id);
      setImageUrl(teacher?.User?.avatar?.url_thumbnail);
      setComboTeacherId({ id: teacher?.id, name: teacher?.User?.name });
      set_teacher_user_id(teacher?.User?.id);
    }
  };

  React.useEffect(() => {
    loadImage();
    loadData();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={2} style={{maxWidth: '90px'}}>
          <img
            width={80}
            height={80}
            src={imageUrl || '/images/no-image.png'}
            onClick={() => {
              if (!read_only) fileInputRef.current.click();
            }}
          />
          <input
            style={{ display: 'none', cursor: '' }}
            type="file"
            ref={fileInputRef}
            accept="image/png, image/jpeg"
            onChange={(event) => {
              saveImage(event.target.files[0]);
            }}
          />
          <TextField
            style={{ display: 'none' }}
            value={imageId}
            name={`${name}.id_avatar`}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <TextField
            style={{ display: 'none' }}
            name={`${name}.teacher_id`}
            value={comboTeacherId?.id}
            inputRef={register}
          />
          {isSelect ? (
            <Autocomplete
              fullWidth
              clearOnEscape
              value={comboTeacherId}
              options={selectFields || []}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setComboTeacherId(value);
                updateAuthor(value?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  label={'instrutor'}
                  variant="outlined"
                />
              )}
            />
          ) : (
            <TextField
              fullWidth
              variant="outlined"
              label="nome"
              inputRef={register}
              name={`${name}.name`}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>Descriçao</Typography>
        </Grid>
        <Grid item xs={12}>
          <EmailEditorComponent
            style={{ minHeight: 200 }}
            value={description}
            read_only={read_only}
            onChange={(text) => {
              setDescription(text);
            }}
          />
          <TextField
            style={{ display: 'none' }}
            name={`${name}.description`}
            value={description}
            inputRef={register}
          />
          <TextField
            style={{ display: 'none' }}
            name={`${name}.teacher_user_id`}
            value={teacher_user_id}
            inputRef={register}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewTeacher;
