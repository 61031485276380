import React from 'react';
import { useStyles } from './styles';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Access,
  ClassroomFiles,
  ClassroomGeneral,
  Progress,
  Certificate,
} from 'views/ClassroomCourse/Setting/components';
import CustomTabComponent from 'components/CustomTab';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export default () => {
  const classes = useStyles();
  const { t } = useTranslation(['tables', 'button']);

  const history = useHistory();

  const componentsTab = [
    {label: t('tables:Tabs.general_settings'), component: <ClassroomGeneral />},
  ]

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={6} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            className={classes.btnBack}
            onClick={() => history.goBack()}
          >
            <ArrowBackIos style={{ marginRight: '5px' }} />
            {t('button:commons.btn_back')}
          </Button>
        </Grid>
        
        <Grid item xs={12}>
          <CustomTabComponent components={componentsTab}/>
        </Grid>
      </Grid>
    </div>
  );
};
