import React from 'react';

import {
  ReportLGPDToolbar,
  ReportLGPDList,
} from 'views/Reports/LGPD/components';
import { useStyles } from './styles';
import LGPDReportJSON from 'constants/JSONConstants/LGPD-report-table-columns.json';
import ReportData, { AdminReportType } from 'services/dataService/ReportData';
import { useTranslation } from 'react-i18next';

export default () => {
  const classes = useStyles();
  const [fields, setFields] = React.useState([]);
  const { t } = useTranslation(['tables']);
  const [loading, setLoading] = React.useState(true);

  // Load table columns
  const loadFields = async () => {
    try {      
      const { data: reportFields } = await ReportData.getReportFields(
        AdminReportType.LGPD
      );
      
      let reportData = [];
      let serverText = false;

      if (reportFields?.table_titles?.length) {
        // API user JSON        
        reportData = reportFields?.table_titles;
        serverText = true;
      } else {
        // use Local JSON        
        reportData = LGPDReportJSON;
      }
      setFields(
        reportData.map(({ title, field, selected }) => {
          return {
            title: serverText ? title : t(title),
            field,
            hidden: !selected,
            type: field?.type,
          };
        })
      );
    } catch (error) {
      
      setFields(
        LGPDReportJSON.map(({ title, field, selected, type }) => {          
          return { title: t(title), field, hidden: !selected, type };
        })
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadFields();
  }, []);

  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <ReportLGPDToolbar
            fields={fields}
            onSave={async (selectedList) => {
              await ReportData.updateFields({
                type: AdminReportType.LGPD,
                table_titles: selectedList,
              });

              loadFields();
            }}
          />
          <div className={classes.content}>
            <ReportLGPDList fields={fields} />
          </div>
        </>
      )}
    </div>
  );
};
