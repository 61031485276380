import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AuthUserNavigation from 'components/NavAuth';
import { Visibility, VisibilityOff } from '@material-ui/icons/';
import { useParams, useHistory } from 'react-router-dom';

import {
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { PasswordResetData } from 'services/dataService';
import { toast } from 'react-toastify';

export default () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { token } = useParams();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  useEffect(() => {
    (async () => {
      try {
        const domain = window.location.hostname;

        await PasswordResetData.validate({
          token,
          domain,
        });
        toast.success(t('toastify:commons.toast_success'));
        setLoading(false);
      } catch (error) {
        toast.error(t('toastify:commons.toast_error'));
        history.push('/');
      }
    })();
  }, []);

  const [visible, setVisible] = useState({
    showPassword: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setVisible({ ...visible, showPassword: !visible.showPassword });
  };
  const onSubmit = useCallback(async (data) => {
    try {
      const domain = window.location.hostname;

      await PasswordResetData.update({
        ...data,
        token,
        domain,
      });
      toast.success(t('toastify:commons.toast_success'));
      history.push('/');
    } catch (error) {
      toast.error(t('toastify:commons.toast_error'));
    }
  }, []);

  if (!loading)
    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid item md={12} xs={12} className={classes.fieldGroup}>
              <AuthUserNavigation />
            </Grid>
            <Typography className={classes.title} variant="h2">
              {t('title:commons.title_redefine_password')}
            </Typography>

            <TextField
              fullWidth
              autoFocus
              label={t('form:Signin.email')}
              helperText={
                (errors.email?.type === 'required' &&
                  t('validation:commons.validation_required', {
                    field: t('form:Company.input_email'),
                  })) ||
                (errors.email?.type === 'pattern' &&
                  t('validation:commons.validation_email', {
                    field: t('form:Company.input_email'),
                  }))
              }
              name="email"
              type="text"
              variant="outlined"
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                },
              })}
              error={!!errors.email}
            />

            <FormControl variant="outlined" fullWidth error={!!errors.password}>
              <InputLabel
                className={classes.input}
                htmlFor="outlined-adornment-password"
              >
                {t('form:Signin.password')}
              </InputLabel>
              <OutlinedInput
                className={classes.input}
                id="outlined-adornment-password"
                type={visible.showPassword ? 'text' : 'password'}
                name="password"
                inputRef={register({ required: true, minLength: 6 })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {visible.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={50}
              />
              <FormHelperText>
                {(errors.password?.type === 'required' &&
                  t('validation:commons.validation_required', {})) ||
                  (errors.password?.type === 'minLength' &&
                    t('validation:commons.validation_min_pass', {
                      minLength: 6,
                    }))}
              </FormHelperText>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.confirmPassword}
            >
              <InputLabel
                className={classes.input}
                htmlFor="outlined-adornment-password"
              >
                {t('form:Signin.password_repeat')}
              </InputLabel>
              <OutlinedInput
                className={classes.input}
                id="outlined-adornment-password"
                type={visible.showPassword ? 'text' : 'password'}
                name="confirmPassword"
                inputRef={register({ required: true, minLength: 6 })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {visible.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={50}
              />
              <FormHelperText>
                {(errors.confirmPassword?.type === 'required' &&
                  t('validation:commons.validation_required', {})) ||
                  (errors.confirmPassword?.type === 'minLength' &&
                    t('validation:commons.validation_min_pass', {
                      minLength: 6,
                    }))}
              </FormHelperText>
            </FormControl>

            <Button
              className={classes.sendButton}
              color="primary"
              fullWidth
              type="submit"
              size="large"
              variant="contained"
            >
              {t('button:commons.btn_redefine_password')}
            </Button>
          </form>
        </Grid>
      </div>
    );
  return <div></div>;
};
