import React from 'react';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
} from '@material-ui/core';
import { useStyles } from './styles';

export default ({
  title,
  number,
  icon: Icon,
  functionButton,
  buttonText,
  iconStyle,
  infoNumber,
}) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root)}>
      <CardContent className={classes.cardcontent}>
        <Grid container justify="space-between" className={ classes.cardContainer }>
          <Grid item className={ classes.titleNumberContent }>
            <Typography color="textSecondary" variant="body2" className={ classes.titleCard } title={ title }>
              {title}
            </Typography>
            <Typography variant="h6">{number}</Typography>
             {/* {infoNumber && (
               <div className={classes.infoText}>
                 <Typography
                   className={
                     infoNumber >= 0 ? classes.greenText : classes.redText
                   }
                   variant="body2"
                 >
                   {infoNumber >= 0 ? '+' : '-'}
                   {infoNumber}%
                 </Typography>
                 <Typography variant="body2">último mês</Typography>
               </div>
             )} */}
          </Grid>
          <Grid item className={ classes.avatarGrid }>
            {functionButton ? (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={functionButton}
              >
                {buttonText}
              </Button>
            ) : (
              <Avatar className={classes.avatar, iconStyle}>
                <Icon className={classes.icon} />
              </Avatar>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
