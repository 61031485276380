import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomSlider from 'components/CustomSlider';
import Interweave from 'interweave';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';

const ProgressTrailSlider = ({
  trails,
  onActionClickGoToTrail,
  onActionClick,
  slidesNumber,
  ...props
}) => {
  const { t } = useTranslation(['button', 'views']);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const classes = useStyles();

  const toFixed = (num, fixed) => {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
  };

  return (
    <CustomSlider
      itens={trails}
      slidesNumber={slidesNumber}
      slidesToShow={slidesToShow}
      setSlidesToShow={setSlidesToShow}
      slideTeste={true}
      {...props}
    >
      {trails?.map((trail) => {
        return (
          <div key={trail?.id} className={classes.slidesShow} >
            <Box
              className={classes.card}
              style={{ width: slidesToShow <= 2 ? 350 : 'unset' }}
            >
              <Box>
                <Link
                  onClick={() => {
                    if (onActionClickGoToTrail) {
                      onActionClickGoToTrail(
                        trail?.id,
                        'progress',
                        trail?.id_course
                      );
                    }
                  }}
                >
                  <img
                    className={classes.media}
                    src={
                      trail?.banner
                        ? trail?.banner
                        : 'https://via.placeholder.com/350x208'
                    }
                  />
                </Link>
                <Box className={classes.cardBody}>
                  <Typography component="span" className={classes.header}>
                    {trail.name}
                  </Typography>
                  <Box className={classes.description}>
                    <Interweave
                      content={
                        trail?.description?.length ? trail?.description : '-'
                      }
                    />
                  </Box>
                  <Box className={classes.progress}>
                    <Box className={classes.progressBar}>
                      <LinearProgress
                        style={{ width: '100%' }}
                        color="secondary"
                        variant="determinate"
                        value={toFixed(trail?.progress, 2)}
                      />
                    </Box>
                    <Box className={classes.progressText}>
                      <Typography component="span">
                        {trail?.progress && toFixed(trail?.progress, 2)}%
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.action}>
                    <Button
                      onClick={() => {
                        if (onActionClickGoToTrail) {
                          onActionClickGoToTrail(
                            trail?.id,
                            'progress',
                            trail?.id_course
                          );
                        }
                      }}
                      className={classes.actionButtonColor}
                      size="medium"
                    >
                      <InfoOutlinedIcon className={classes.actionIcon} />
                      {t('views:TrailRegistration.go_to_trail')}
                    </Button>
                    <Button
                      size="medium"
                      color="primary"
                      className={classes.actionButton}
                      onClick={() => {
                        if (onActionClick) {
                          onActionClick(trail?.id_course);
                        }
                      }}
                    >
                      {t(
                        `button:StudentContent.${
                          trail?.progress > 0 ? 'btn_continue' : 'btn_start'
                        }`
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        );
      })}
    </CustomSlider>
  );
};
export default ProgressTrailSlider;
