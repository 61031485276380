import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '70vh', 
  },
  wrapper: {
    width: '100%',
    backgroundColor: '#f9f9f9',
    borderRadius: 10,
    overflowY: 'auto',
    marginBottom: theme.spacing(2),
    boxShadow: '0px 9px 22px #d3d3d3',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      maxHeight: 663,
    },
    [theme.breakpoints.down('md')]: {
      height: '70vh', 
      maxHeight: 'none',
    },
  },
  title: {
    color: '#000000',
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 10,
  },
  subtitle: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
    '& button': {
      width: 114,
      height: 31,
      backgroundColor: 'transparent',
      color: '#000000',
      fontSize: 14,
      fontWeight: 600,
      border: 'none',
      outline: 'none',
      borderRadius: 4,
      border: '1px solid #2DCE98',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
  },
  buttonsActive: {
    backgroundColor: '#2DCE98 !important',
    color: '#fff !important',
  },
  videoContainer: {
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '100%',
    paddingBottom: '56.25%',
    '& > iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: 'none'
    },
    '& > embed': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '70vh',
    },
  },
  
}));
