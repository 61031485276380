import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, CardContent, Grid, TextField } from '@material-ui/core';
import { useStyles } from './styles';
import Search from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const FormSearch = ({ onSubmit }) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();

  const { t } = useTranslation(['form', 'button']);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container spacing={3} alignItems="center" justify="space-between">
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('form:commons.input_search')}
              name="name"
              type="text"
              variant="outlined"
              defaultValue={''}
              inputRef={register}
            />
          </Grid>
          <Grid item md={2} xs={12} className={classes.buttonSearch}>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              type="submit"
            >
              <Search spacing={2} />
              {t('button:commons.btn_advanced_filter')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </form>
  );
};

export default FormSearch;
