import { Box, Button, Typography } from '@material-ui/core';
import {
  AssignmentTurnedInOutlined,
  PlayCircleFilledWhiteOutlined,
  QueryBuilderOutlined,
  ShoppingCartOutlined,
  Subject,
  SchoolOutlined,
  Block
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import JunoAPIService from 'services/paymentService/JunoAPIService';
import theme from 'theme';

const BuyCourseCard = ({ data, onCourseBuy, info, signable, courseBlocked, ableBuy, buttonClicked }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views', 'button', 'cards']);
  const { id } = useParams();
  const { id: user_id } = useSelector((state) => state.auth);
  const [courseBuy, setCourseBuy] = React.useState(false);
  const checkEnrollment = async () => {
    try {
      await JunoAPIService.checkEnrollment(user_id, id);
    } catch (e) {
      setCourseBuy(true);
    }
  };

  React.useEffect(() => {
    checkEnrollment();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.card}>
        <Typography className={classes.price}>
          {
            data?.price > 0 ? `R$ ${data?.price?.replace('.', ',') || '00,00'}`
              : !courseBlocked && !ableBuy
              ? t('cards:buyCourse.card_blocked')
              : t('cards:buyCourse.card_free_access')
          }
        </Typography>
        <Typography className={classes.cardPrice} color="textSecondary">
          {
            data?.price > 0 ? `${t('views:CourseRegistration.card_installment_value', {
              value: Math.round((((data?.price || 0) / 12) * 100) / 100).toFixed(
                2
              ),
            })}`
              :
              null
          }
        </Typography>
        <Box className={classes.buttonBox}>

          <Button
            disabled={!signable || (!courseBlocked && !ableBuy)}
            onClick={() => {
              if (onCourseBuy) {
                onCourseBuy();
              }
            }}
            variant="contained"
            color="primary"
          >
            {courseBuy && user_id?.length
              ?
              <>
                {(!courseBlocked && !ableBuy) ?
                  <>
                    <Block style={{ margin: theme.spacing(1), marginLeft: 0 }} />
                    {t('button:StudentContent.btn_block')}
                  </>
                  :
                  <>
                    <SchoolOutlined style={{ margin: theme.spacing(1), marginLeft: 0 }} />
                    {t('button:commons.btn_access_now')}
                  </>
                }
              </>
              :
              <>
                <ShoppingCartOutlined style={{ margin: theme.spacing(1), marginLeft: 0 }} />
                {t(
                  `button:CourseRegistration.${data?.free || !Number.parseFloat(data?.price || 0) > 0
                    ? 'enroll_now'
                    : 'buy_now'
                  }`
                )}
              </>
            }
          </Button>
        </Box>
        <Box className={classes.buttonBox}>
          <Typography className={classes.contentTitle}>
            {t('views:CourseRegistration.all_course_content')}:
          </Typography>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <PlayCircleFilledWhiteOutlined fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {info?.count_videos ?? 0}{' '}
                {t('views:CourseRegistration.total_course_videos')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <Subject fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {info?.count_texts ?? 0}{' '}
                {t('views:CourseRegistration.total_course_texts')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <AssignmentTurnedInOutlined fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {info?.count_exams ?? 0}{' '}
                {t('views:CourseRegistration.total_course_exams')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contentBox} display="flex">
            <Box>
              <QueryBuilderOutlined fontSize="small" />
            </Box>
            <Box>
              <Typography component="span" className={classes.contentNumber}>
                {info?.workload ?? 0}{' '}
                {t('views:CourseRegistration.total_course_workload')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: '#f4f6f8',
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
    borderRadius: 8,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 350,
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 350,
      MarginLeft: '70%'
    },
  },
  price: {
    fontSize: 'xx-large',
    lineHeight: '100%',
  },
  cardPrice: {
    fontSize: 'small',
  },
  buttonBox: {
    paddingTop: theme.spacing(2),
  },
  contentTitle: {
    fontSize: 'large',
    fontWeight: 700,
  },
  contentNumber: { paddingLeft: theme.spacing(1) },
  contentBox: {
    paddingTop: theme.spacing(1),
  },
}));

export default BuyCourseCard;
