import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CertificateData } from 'services/dataService';
import CertificatePdfPreview from 'views/Setting/Certificate/components/CertificatePdfPreview';

export default () => {
  const classes = useStyles();

  const { t } = useTranslation(['toastify']);

  const [values, setValues] = React.useState({
    top_footer_spacing: '',
    top_header_spacing: '',
    top_message_spacing: '',
    top_name_spacing: '',
    top_document_spacing: '',
    top_expiration_spacing: '',
    footer_message: '',
    expiration_date: '',
  });

  const [preview, setPreview] = React.useState('');

  const [showFields, setShowFields] = React.useState({
    show_doc_student: false,
    show_period: false,
  });

  const [loading, setLoading] = React.useState(true);

  const id_company = useSelector((state) => state.auth.id_company);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await CertificateData.getCertificate(id_company);
        const {
          top_footer_spacing,
          top_header_spacing,
          top_message_spacing,
          top_name_spacing,
          top_document_spacing,
          top_expiration_spacing,
          footer_message,
          expiration_date,
        } = response.data;

        setValues({
          top_footer_spacing,
          top_header_spacing,
          top_message_spacing,
          top_name_spacing,
          top_document_spacing,
          top_expiration_spacing,
          footer_message,
          expiration_date,
        });

        const { show_doc_student, show_period } = response.data;

        setShowFields({ show_doc_student, show_period });
        setPreview(response.data?.file?.base64);
      } catch (e) {
        if (e.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:Course.certificate.default_not_found'));
        }  
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <Grid
          container
          xs={12}
          justify={'center'}
          alignItems={'center'}
          className={clsx(classes.marginTop, classes.bottomPadding)}
        >
          <CircularProgress size={48} color="secondary" />
        </Grid>
      ) : (
        <Grid
          container
          xs={12}
          spacing={1}
          justify={'center'}
          className={classes.root}
        >
          <Grid
            item
            md={12}
            xs={12}
            align={'center'}
            style={{ overflow: 'auto', lineHeight: 1.1 }}
          >
            <CertificatePdfPreview
              showPeriod={showFields.show_period}
              showDocStudent={showFields.show_doc_student}
              formValues={values}
              background={preview}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  bottomPadding: { paddingBottom: theme.spacing(2) },
}));
