import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
  overviewRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  overviewTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  periodChoiceButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },

  choiceButtons: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },

  overviewCompanySummary: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  overviewCompanyCard: {
    width: '30%',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  overviewUserSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  overviewUserCard: {
    width: '19%',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },

  overviewTableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    '& > iframe': {
      width: '100%',
      minHeight: '70vh',
      borderRadius: 10,
      boxShadow:
        '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    }
  },

  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardInfo: {
    marginBottom: theme.spacing(2),
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  greenIcon: {
    backgroundColor: '#2DCE98',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C',
  },

  blueIcon: {
    backgroundColor: '#176AE6',
  },

  redIcon: {
    backgroundColor: '#F53C56',
  },

  overviewPeriod: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'normal',
    },
  }
}));
