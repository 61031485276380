import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import { notificationRequest } from 'store/modules/notification/actions';
const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
    //width: '30%'
  },
  contentBox: {
    display: 'block',
    height: 35,
    width: 250,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  contentList: {
    width: '380px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  notificationAll: {
    marginTop: 20,
    textAlign: 'center',
    width: '100%',
    color: 'blue'
  },
}));

export default function Notification(props) {
  
  const dispatch = useDispatch(); 
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const {latests: notifications} = useSelector((state) => state.notification);
  const handleClickListItem = (event) => {
    history.push('/notification');
    setAnchorEl(event.currentTarget);
  };
  const fetchNotifications = async () => {
    dispatch(notificationRequest());
  };


  return (
    <div className={classes.root} >
      <List component="nav">
        { notifications.map( notification => (
          <>
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="lock-menu"
              onClick={handleClickListItem}
            >
              <ListItemText primary={
                  <Box fontWeight="fontWeightMedium" className={classes.contentBox} textAlign="left" fontSize={12}>
                   {notification.title}
                  </Box>
              }/>
              <ListItemText primary={
                <div>
                  <Box fontWeight="fontWeightBold" textAlign="right" fontSize={12}>
                  {new Intl.DateTimeFormat('pt-BR').format(
                    new Date(notification.available_at)
                  )}
                  </Box>
                    <Box fontWeight="fontWeightBold" textAlign="right" fontSize={12}>
                    {new Intl.DateTimeFormat('default', {
                        hour: 'numeric',
                        minute: 'numeric',
                      }).format(new Date(notification.available_at))}
                    </Box>
                </div>
              } />
            </ListItem>
            <Divider />
          </>
        ) ).reverse()}
      </List>
      <Button className={classes.notificationAll}>
        Ver todas
      </Button>
    </div>
  )
}
