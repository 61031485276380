import { Height } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { FiVolumeX } from 'react-icons/fi';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'space-between',
  },
  addTeacherlabel: {
    fontWeight: 'bold',
  },
  infoInstructor: {
    color: theme.palette.text.secondary,
  },
  topSpacing: {
    paddingTop: theme.spacing(2),
  },
  contentCenter: {
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  switchItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('md')]: {
    contentCenter: {
      paddingTop: '0'
    }
  },
  labelAvatar:{
    width: '100%',
    Height: '200px',
    borderRadius: '0.3rem',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    
  },
  inputAvatar:{
    display: 'none !important'
  },
  span:{
    zIndex: 99,
    position: 'absolute',
    opacity: 0.7,
    transition: 'all .7s ',
    '&:hover': {
      opacity: 1,
    transform: 'scale(1.2)'
    }
  }, 
  labelMultiTimes:{
     marginBottom: '5px',
  },
  spanerrorimput:{
    fontSize: '11px',
    color:'#E53935',
    marginLeft: '1rem',
  },
  diverrorimput:{
    borderWidth: '1px',
    borderStyle: 'solid',  
    borderRadius: '10px',
  }
  
}));
