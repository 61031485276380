import { Box, Typography, Grid } from '@material-ui/core';
import CoursesSlider from 'components/CoursesSlider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';

const KnowledgeTrailCourses = ({ data, progress }) => {
  const classes = useStyles();
  const { t } = useTranslation('views');
  const history = useHistory();

  const onActionClick = (id) => {
    history.push(`/course/${id}/learn`);
  };

  return (
    <Box className='notranslate'>
      <Box className={classes.container}>
        <Box className={classes.titleBox}>
          <Typography component="h2" className={classes.categoryHeader}>
            {t('views:TrailDetails.content_composition_title')}
          </Typography>
        </Box>
        <Grid>
          <Box className={classes.cardBox}>
            <CoursesSlider
              renderOnTrail={true}
              courses={data.trail_courses}
              onActionClick={onActionClick}
              progress={progress}
              isKnowledgeTrailPage={true}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default KnowledgeTrailCourses;
