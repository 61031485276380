import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  btnActive: {
    color: theme.palette.success.main,
  },
  btnInactive: {
    color: theme.palette.error.main,
  },
  btnDefault: {
    color: theme.palette.default.main,
  },
}));
