import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import TrailsPanel from 'components/TrailsPanel';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TrailData } from 'services/dataService';
import CompanyCustomizationData from 'services/dataService/CompanyCustomizationData';
import history from 'services/history';
import SearchTextfield from './SearchTextfield';
import { useStyles } from './styles';

let debounce;
const AvailableTrails = ({ hideSearch }) => {
  const classes = useStyles();
  const { company } = useSelector((state) => state);
  const { t } = useTranslation(['form', 'views', 'toastify']);
  const [banner, setBanner] = useState('');
  const [search, setSearch] = useState([]);
  const [trails, setTrails] = useState([]);
  const [searchAttributes, setSearchAttributes] = useState({
    text: '',
    category: '',
    subCategory: '',
  });
  const [loading, setLoading] = useState(true);
  const trailsRef = useRef([]);

  useEffect(() => {
    getBanner();
    getContent();
  }, []);

  const getContent = async () => {
    try {
      const { data: content } = await TrailData.getStudentAvailableTrails(
        company?.domain_company
      );
      setTrails(content.trails);
      trailsRef.current = content.trails;
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (e) {
      setLoading(false);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const getBanner = async () => {
    const { data: customization } =
      await CompanyCustomizationData.getCompanyCustomization();
    setBanner(customization?.company_banner?.banner?.url || '');
  };

  const onChangeTextSearch = (trailName) => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      setSearchAttributes({ ...searchAttributes, text: trailName });
    }, 600);
  };

  const onEnterPress = (trailName) => {
    clearTimeout(debounce);
    setSearchAttributes({ ...searchAttributes, text: trailName });
  };

  const actionClick = async (trailId, type, courseId = null) => {
    switch (type) {
      case 'progress':
        history.replace(
          `trail/${trailId}/learn?course=${courseId}&progress=true`
        );
        break;
      case 'finished':
        history.replace(
          `trail/${trailId}/learn?course=${courseId}&finished=true`
        );
        break;
      default:
        history.replace(`trail/${trailId}/learn`);
        break;
    }
  };

  useEffect(() => {
    const filterTrailsBySearch = () => {
      const { text } = searchAttributes;

      let matchTrails = [...trails];

      if (text) matchTrails = filterTrailName(text, matchTrails);

      if (matchTrails.length) {
        setSearch(matchTrails);
      } else {
        setSearch([]);
      }
    };

    const filterTrailName = (trailName, trails) => {
      return trails.filter((trail) => {
        return trail?.name.toLowerCase().includes(trailName?.toLowerCase());
      });
    };

    filterTrailsBySearch();
  }, [trails, searchAttributes]);

  return (
    <>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <Box className={classes.root}>
          {!hideSearch ? (
            <Box
              className={classes.header}
              style={{
                backgroundImage: banner
                  ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${banner})`
                  : '',
              }}
            >
              <Grid
                container
                direction="column"
                justify="center"
                className={classes.searchFields}
              >
                <Grid item xs={12} md={12}>
                  <SearchTextfield
                    placeholder={t('views:AvailableTrails.header_field')}
                    onChange={onChangeTextSearch}
                    onEnterPress={onEnterPress}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}
          {search.length > 0 && (
            <Box className={classes.container}>
              <Box>
                <Box className={classes.titleBox}>
                  <Typography component="h2" className={classes.categoryHeader}>
                    {t('views:TrailRegistration.available_trails')}
                  </Typography>
                </Box>
                <Box className={classes.cardBox}>
                  <TrailsPanel
                    refresh={false}
                    onActionClick={actionClick}
                    trails={search}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default AvailableTrails;
