import api from 'services/api';

class ReportData {
  getReportFields(type) {
    return api.get(`admin-report/${type}`);
  }

  updateFields(body) {
    return api.put(`admin-report/`, body);
  }
}

export default new ReportData();

export class AdminReportType {
  static ACADEMIC = 0;
  static PLATFORM = 1;
  static USERS = 2;
  static TEAMS = 3;
  static CATEGORIES = 4;
  static COURSES = 5;
  static LGPD = 6;
  static TRAIL = 7;
  static ATTENDANCE = 8;
}
