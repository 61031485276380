import querystring from 'querystring';
import api from '../api';

class DataServiceUser {
  addUser(data) {
    return api.post('users', data);
  }

  getUserAuthor(id) {
    return api.get(`/course/author-id/${id}`);
  }

  addMassiveUser(data, id) {
    return api.post(`users/${id}/massive`, data);
  }

  getUsers(params) {
    const url = 'users?' + querystring.stringify(params);
    return api.get(url);
  }

  getUsersById(id) {
    return api.get(`users/${id}`);
  }

  updateUser(id, data) {
    return api.put(`users/${id}`, data);
  }

  sendAlert(id) {
    return api.post(`users/${id}/sendAlert`);
  }

  countByGroupType(params) {
    const url = 'users/countByGroupType?' + querystring.stringify(params);
    return api.get(url);
  }
}

export default new DataServiceUser();
