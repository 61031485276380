import React, { useState, useEffect, useRef } from 'react';
import { useStyles } from './styles';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Switch,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { HandleDelete } from 'components/';
import { LessonCard } from '../../components';
import { Draggable } from 'react-beautiful-dnd';
import { ModuleData, LessonData } from 'services/dataService';
import { toast } from 'react-toastify';

export default (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const {
    provided,
    lessons,
    item,
    modules,
    snapshot,
    ind,
    state,
    setState,
    setModules,
    setReload,
    is_owner,
  } = props;
  const [menuModule, setMenuModule] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const firstUpdate = useRef(true);
  const [check, setCheck] = useState(true);

  const updatePublishModuleStatus = (status) => {
    ModuleData.updateModule(modules[ind].id, { status }, id).then(() => {
      setCheck(status);
      setReload(true);
    });
  };

  const { t } = useTranslation(['tables', 'toastify']);

  const functionDelete = async () => {
    try {
      await ModuleData.deleteModule(modules[ind].id);
      toast.success(t('toastify:commons.toast_delete'));

      setReload(true);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      setTimeout(() => (firstUpdate.current = false), 600);
      return;
    }
    async function getTodos() {
      const promises = lessons.map(
        async (elem, index) =>
          await LessonData.updateLesson(elem.id_lesson, {
            order: index,
          })
      );

      await Promise.all(promises);
    }
    try {
      getTodos();
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }, [lessons]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.droppableProps}
      className={classes.card}
    >
      {modules[ind]?.id && (
        <div className={classes.headerCard}>
          <span className={classes.title}>{modules[ind].title}</span>
          {is_owner ? (
            <div className={classes.actions}>
              <>
                <FormControl component="fieldset">
                  <FormControlLabel
                    labelPlacement="bottom"
                    control={
                      <Switch
                        checked={modules[ind].status}
                        onChange={(event) =>
                          updatePublishModuleStatus(event.target.checked)
                        }
                        name="check"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={t('button:Lesson.btn_module_public')}
                  />
                </FormControl>

                <IconButton
                  size="small"
                  onClick={(event) => setMenuModule(event.currentTarget)}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={menuModule}
                  keepMounted
                  open={Boolean(menuModule)}
                  onClose={() => setMenuModule(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setMenuModule(null);
                      setModalStatus(true);
                    }}
                  >
                    {t('tables:group.role_delete')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push(
                        `/course/${id}/module/${modules[ind].id}/edit`
                      );
                    }}
                  >
                    {t('tables:group.role_put')}
                  </MenuItem>
                </Menu>
              </>
            </div>
          ) : null}
        </div>
      )}

      <div>
        {lessons
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
          .map((lesson, index) => (
            <Draggable
              key={lesson.id}
              isDragDisabled={!is_owner}
              draggableId={lesson.id}
              index={index}
            >
              {(provided, snapshot) => (
                <LessonCard
                  provided={provided}
                  snapshot={snapshot}
                  is_owner={is_owner}
                  lesson={lesson}
                  modules={state}
                  setModules={setState}
                  index={index}
                />
              )}
            </Draggable>
          ))}
        {provided.placeholder}
      </div>

      {is_owner ? (
        <HandleDelete
          closeModal={setModalStatus}
          isOpen={modalStatus}
          propsHandleConfirm={functionDelete}
        />
      ) : null}
    </div>
  );
};
