import React, { useRef } from 'react';
import { Subject } from 'rxjs';

import { UserList, UserToolbar } from 'views/User/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  const listDataChangeSubject = new Subject();

  const onListDataChanged = () => {
    listDataChangeSubject.next();
  }

  return (
    <div className={classes.root}>
      <UserToolbar
        listDataChangeObservable={listDataChangeSubject.asObservable()}
      />
      <div className={classes.content}>
        <UserList
          listDataChange={onListDataChanged}
        />
      </div>
    </div>
  );
};
