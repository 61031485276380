import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapperTable: {
    margin: theme.spacing(3)
  },
  buttonStyle: {
    border: "none",
    width: 68,
    height: 28,
    color: "#FFFFFF",
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  colorUnread: {
    backgroundColor: "#F0AD4E",
    '&:hover': {
      backgroundColor: "#F0AD4EAA",
    }
  },
  colorRead: {
    backgroundColor: "#2DCE98",
    '&:hover': {
      backgroundColor: "#2DCE98AA",
    }
  },
  colorReportUnread: {
    backgroundColor: "#44acac",
    '&:hover': {
      backgroundColor: "#53ADCB",
    }
  },
  colorReportRead: {
    backgroundColor: "#2DCE98",
    '&:hover': {
      backgroundColor: "#2DCE98AA",
    }
  },
  readNotification: {
    width: "100%",
    backgroundColor: "#ffffff",
    display: "grid",
    padding: 33,
    alignContent: "center",
  }
}));
