import React, { useState, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';
import { HandleDelete } from 'components/';
import { localizationTable, options } from 'constants/table';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from './styles';

import { CompanyData } from 'services/dataService';
import { statusCompany } from 'constants/status';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';

export default () => {
  const [status, setStatus] = useState(1);
  const [name, setName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [route, setRoute] = useState('');

  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const openNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const handleEdit = useCallback(
    ({ id }) => {
      window.open(`companies/${id}/edit`, '_blank');
    },
    []
  );

  const onSubmit = (data) => {
    setName(data.name);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const handleModalOpen = useCallback(({ id }) => {
    setModalStatus(true);
    setRoute(`companies/${id}`);
  });

  const handleLink = useCallback(({domain}) => {
    // If the domain doesn't have http or https, add it
    if(!domain?.startsWith(('http://') || ('https://'))) domain = `http://${domain}`;
    // Opens the link in a new tab
    openNewTab(domain);
  });

  return (
    <>
    <div className="notranslate">
    <MaterialTable
        title={`${t('tables:company.title')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('tables:company.name_column')}`,
            field: `name`,
            render: (rowData) => rowData.name,
          },

          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            render: (rowData) => (
              <div style={{ display: 'flex' }}>
                <CircleIcon
                  style={statusCompany[rowData.status]?.style}
                  fontSize="small"
                />
                <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                  {t(statusCompany[rowData.status]?.title)}
                </span>
              </div>
            ),
          },
          {
            title: `${t('tables:company.courses_column')}`,
            field: `courses`,
            render: (rowData) => rowData.courses,
          },
          {
            title: `${t('tables:company.user_active_column')}`,
            field: `users_active`,
            render: (rowData) =>
              rowData.users_active > 0 ? rowData.users_active : 0,
          },
          {
            title: `${t('tables:company.user_inactive_column')}`,
            field: `users_inactive`,
            render: (rowData) =>
              rowData.users_inactive > 0 ? rowData.users_inactive : 0,
          },
        ]}
        data={async (query) => {
          try {
            const response = await CompanyData.getSubplataforms({
              page: query.page + 1,
              limit: query.pageSize,
              status,
              name,
            });

            return {
              data: response.data.companies,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        actions={[
          {
            icon: 'link',
            tooltip: `${t('tables:commons.actions.link_action')}`,
            onClick: (event, rowData) => handleLink(rowData),
            iconProps: { color: 'black' },
          },
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.edit_action')}`,
            onClick: (event, rowData) => handleEdit(rowData),
            iconProps: { color: 'primary' },
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
          },

        ]}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:commons.input_search')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <FormControl style={{ minWidth: 100 }}>
                        <InputLabel id="status">
                          {t('form:User_solicitation.input_status_select')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={1}>
                                {t(statusCompany[1]?.title)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t(statusCompany[2]?.title)}
                              </MenuItem>
                              <MenuItem value={3}>
                                {t(statusCompany[3]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={status ? status : 1}
                          value={status ? status : 1}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        <Search style={{ marginRight: '5px' }} />
                        {t('button:User_solictation.btn_advanced_filter')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
      />

    </div>
      

      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
      />
    </>
  );
};
