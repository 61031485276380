import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FeaturedCoursesData, {
  FeatureCoursesType,
} from 'services/dataService/FeaturedCoursesData';
import FeaturedCourseSorter from '../FeaturedCourseSorter';

const InternCourses = () => {
  const { t } = useTranslation(['views', 'toastify']);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const edit = React.useRef(false);

  const getFeaturedCourses = async () => {
    try {
      setLoading(true);
      const response = await FeaturedCoursesData.getFeaturedCourses(
        FeatureCoursesType.internal
      );

      const courses = response.data.coursesList.map(({ id_company_course, id_trail, name, index }) => {
        const tag = id_company_course !== null ? 'course' : 'trail';
        return { id: id_company_course || id_trail, name, index, tag };
      });
      
      setCourses(courses.filter((a, b) => (a?.index < b?.index ? 1 : -1)));
      
      if (response.data.hasFeaturedCourse) {
        edit.current = true;
      } else {
        edit.current = false;
      }
    } catch (error) {
      if (error.response.status === 404) {
        edit.current = false;
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    } finally {
      setLoading(false);
    }
  };

  const save = async (courses) => {
    try {
      if (!edit.current) {
        await FeaturedCoursesData.createFeaturedCourse(
          FeatureCoursesType.internal,
          courses
        );
        toast.success(t('toastify:commons.toast_success'));
      } else {
        await FeaturedCoursesData.updateFeaturedCourse(
          FeatureCoursesType.internal,
          courses
        );
        toast.success(t('toastify:commons.toast_update'));
      }
      edit.current = courses.length ? true : false;
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  React.useEffect(() => {
    getFeaturedCourses();
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <FeaturedCourseSorter
          headerTitle={t('views:FeturedCourses.name_course')}
          name={t('views:FeturedCourses.name_course')}
          description={t(`views:FeturedCourses.Intern.description`)}
          initialValue={courses}
          onCancel={() => {}}
          onSave={save}
        />
      )}
    </>
  );
};

export default InternCourses;
