import querystring from 'querystring';
import api from '../api';

class DataServiceEnrollment {
  getEnrollmentByUserAndCourse(id_user, id_company_course) {
    return api.get(`/enrollments/${id_user}/${id_company_course}`);
  }
  addEnrollment(data, query) {
    return api.post(`enrollments?${querystring.stringify(query)}`, data);
  }

  getCompanyCourseCombo() {
    return api.get('/list-company-course');
  }

  getEnrollment(id, querys) {
    return api.get(`enrollments/${id}?${querystring.stringify(querys)}`);
  }

  deleteEnrollment(id) {
    return api.delete(`enrollments/${id}`);
  }

  getUserCompletedCourses(id_user, id_course) {
    const filter = { filter_course_id: id_course };
    return api.get(
      `certificates/student-fineshed-courses/${id_user}?${querystring.stringify(
        filter
      )}`
    );
  }

  countByUserEnrollments(id_user) {
    return api.get(`/enrollments/${id_user}/countEnrollments`);
  }

  lockEnrollment(id_user, id_company, id_company_course) {
    return api.patch(
      `enrollments/${id_company_course}/${id_user}/${id_company}/lock`
    );
  }

  unlockEnrollment(id_user, id_company, id_company_course) {
    return api.patch(
      `enrollments/${id_company_course}/${id_user}/${id_company}/unlock`
    );
  }
}

export default new DataServiceEnrollment();
