import {
  Backdrop,
  Box,
  CircularProgress,
  Fab,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';
import { useParams } from 'react-router';
import { CertificateData } from 'services/dataService';
import theme from 'theme';
import CertificatePdfPreview from 'views/Setting/Certificate/components/CertificatePdfPreview';

const PublicCertificateView = () => {
  const { id_course, id_user } = useParams();
  const classes = useStyles();
  const [certificateData, setCertificateData] = React.useState(null);
  const [courseData, setCourseData] = React.useState(null);
  const [userData, setUserData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [cpf, setCpf] = React.useState();

  const getCertificate = async () => {
    const { data } = await CertificateData.getCertificateCourse(id_course);
    const { certificate, course } = data;

    setCourseData(course);
    setCertificateData(certificate);

    setLoading(false);
  };

  const getUserData = async () => {
    const { data: certificate } = await CertificateData.getPublicUserData(
      id_user
    );
    setUserData(certificate?.user);
    setCpf(certificate?.user);
  };
  
  React.useEffect(() => {
    getCertificate();
    getUserData();
  }, []);

  return (
    <Box className={classes.root}>
      <Backdrop style={{ zIndex: theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress size={60} />
      </Backdrop>
      {!loading && (
        <Box>
          {/* <Box display="flex" justifyContent="flex-end">
          <Box maxWidth={80}>
            <IconButton>
              <img
                src="/icons/facebook.jpg"
                width={50}
                height={50}
                className={classes.image}
              />
            </IconButton>
          </Box>
          <Box maxWidth={80}>
            <IconButton>
              <img
                src="/icons/linkedin.png"
                width={50}
                height={50}
                className={classes.image}
              />
            </IconButton>
          </Box>
          <Box maxWidth={80}>
            <IconButton>
              <img
                src="/icons/instagram.png"
                width={50}
                height={50}
                className={classes.image}
              />
            </IconButton>
          </Box>
          <Box maxWidth={80}>
            <IconButton>
              <img
                src="/icons/twitter.png"
                width={50}
                height={50}
                className={classes.image}
              />
            </IconButton>
          </Box>
        </Box> */}
          <Typography className={classes.topSpacing} />
          <Box className={classes.card}>
            {!loading && (
              <CertificatePdfPreview
                courseName={courseData?.name}
                idCourse={courseData?.id}
                formValues={certificateData}
                userName={userData?.name}
                showPeriod={certificateData?.show_period}
                showDocStudent={certificateData?.show_doc_student}
                userDoc={cpf?.cpf}
                background={certificateData?.file?.base64}
                courseHours={courseData?.workload}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  topSpacing: {
    paddingTop: theme.spacing(2),
  },

  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: '0px 1px 12px 1px',
    padding: theme.spacing(1),
    overflowY: 'hidden',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        width: '100% !important',
        height: '100% !important',
      }
    }
  },
}));

export default PublicCertificateView;
