import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    main: '#176AE6',
  },
  default: {
    contrastText: white,
    dark: colors.grey[900],
    main: colors.grey[600],
    light: colors.grey[400],
  },
  secondary: {
    contrastText: white,
    main: '#11CDEF',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
    custom: '#176AE6',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#F4F6F8',
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  bannerColor: '#2A2E43',
  courseBannerGradient: {
    gradient0: '#2A2E43D9',
    gradient1: '#31354AD1',
    gradient2: '#525669E8',
    gradient3: '#757989F7',
    gradient4: '#F1F3F9',
  },
};
