import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportData, { AdminReportType } from 'services/dataService/ReportData';
import companiesReportJSON from 'constants/JSONConstants/platform-report-table-columns';

import {
  ReportCompaniesToolbar,
  ReportCompaniesList,
} from 'views/Reports/Companies/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();
  const [fields, setFields] = React.useState([]);
  const { t } = useTranslation(['tables']);
  const [loading, setLoading] = React.useState(true);

  // Load table columns
  const loadFields = async () => {
    try {
      const { data: reportFields } = await ReportData.getReportFields(
        AdminReportType.PLATFORM
      );

      let reportData = [];
      let serverText = false;

      if (reportFields?.table_titles?.length) {
        // API user JSON
        reportData = reportFields?.table_titles;
        serverText = true;
      } else {
        // use Local JSON
        reportData = companiesReportJSON;
      }
      setFields(
        reportData.map(({ title, field, selected, type }) => {
          return {
            title: serverText ? title : t(title),
            field,
            hidden: !selected,
            type,
          };
        })
      );
    } catch (error) {
      setFields(
        companiesReportJSON.map(({ title, field, selected, type }) => {
          return { title: t(title), field, hidden: !selected, type };
        })
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadFields();
  }, []);
  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <ReportCompaniesToolbar
            fields={fields}
            onSave={async (selectedList) => {
              await ReportData.updateFields({
                type: AdminReportType.PLATFORM,
                table_titles: selectedList,
              });

              loadFields();
            }}
          />
          <div className={classes.content}>
            <ReportCompaniesList fields={fields} />
          </div>
        </>
      )}
    </div>
  );
};
