import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, Divider, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { UserContractData } from '../../services/dataService';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function UseTermModal(retPending) {
  const [loading, setLoading] = useState(true);
  const [useterm, setUseTerm] = useState();
  useEffect(() => {
    setLoading(true);
    (async () => {
      if (retPending.retPending.useterm) {
        setUseTerm(retPending.retPending.useterm)
        const blocksFromHtml = htmlToDraft(retPending.retPending.useterm);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setUseTerm(editorState);

      }
    })();
    setLoading(false);
  }, []);


  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'modal',
  ]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!loading)
    return (
      <React.Fragment>
        <Button variant="text" size="small" color="primary" onClick={handleOpen}>{t('form:Contracts.Setting.term_contract')}</Button>
        <Modal
          hideBackdrop
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 600 }}>
            <h2 id="child-modal-title">{t('form:Contracts.Setting.term_contract')}</h2>
            <Grid >
              <Grid item xs={12} >
                <Editor
                  readOnly={true}
                  editorStyle={{
                    border: '1px solid #C0C0C0',
                    borderRadius: '4px',
                    height: '17rem',
                  }}
                  editorState={useterm}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={(vl) => setUseTerm(vl)}
                  toolbar={{
                    options: [],
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="text" color="primary" onClick={handleClose}>{t('button:commons.btn_back')}</Button>
          </Box>
        </Modal>
      </React.Fragment>
    );
  return <></>
}


function PrivacyPolicyModal(retPending) {
  const [loading, setLoading] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState();
  useEffect(() => {
    setLoading(true);
    (async () => {
      if (retPending.retPending.privacypolicy) {
        setPrivacyPolicy(retPending.retPending.privacypolicy)
        const blocksFromHtml = htmlToDraft(retPending.retPending.privacypolicy);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setPrivacyPolicy(editorState);

      }
    })();
    setLoading(false);
  }, []);

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'modal',
  ]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!loading)
    return (
      <React.Fragment>
        <Button variant="text" size="small" color="primary" onClick={handleOpen}>{t('form:Contracts.Setting.privacy_policy_contract')}</Button>
        <Modal
          hideBackdrop
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 600 }}>
            <h2 id="child-modal-title">{t('form:Contracts.Setting.privacy_policy_contract')}</h2>
            <Grid >
              <Grid item xs={12} >
                <Editor
                  readOnly={true}
                  editorStyle={{
                    border: '1px solid #C0C0C0',
                    borderRadius: '4px',
                    height: '17rem',
                  }}
                  editorState={privacyPolicy}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={(vl) => setPrivacyPolicy(vl)}
                  toolbar={{
                    options: [],
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="text" color="primary" onClick={handleClose}>{t('button:commons.btn_back')}</Button>
          </Box>
        </Modal>
      </React.Fragment>
    );
  return <></>
}

export default function AcceptRouter(retPending) {
  const handleClick = async () => {    
    try {
      const data = {}      
      data.id_user = retPending.retPending.idUser;
      data.id_contract = retPending.retPending.idcontract;
      data.status = true;
      if (retPending.retPending.id){
        await UserContractData.updateUsersContract(retPending.retPending.id, data);
        toast.success(t('toastify:commons.toast_update'));            
      } else {
        await UserContractData.addUserContract(data);
        toast.success(t('toastify:commons.toast_success'));
      }    
      window.location.reload();                     
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        const toastMessage = 'toast_update_error'
        toast.error(t(`toastify:commons.${toastMessage}`));
      }
    }
  };

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'modal',
  ]);
  const [open, setOpen] = React.useState(true);
  const [enabledButton, setEnabledButton] = useState(true)
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = () => {
    setEnabledButton(!enabledButton);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h4 id="parent-modal-title">{t('modal:commons.term_policy')}</h4>
          <p id="parent-modal-description">
            {t('modal:commons.text_term')} <UseTermModal retPending={retPending.retPending} />
            <PrivacyPolicyModal retPending={retPending.retPending} />
          </p>

          <FormControlLabel
            label={t('modal:commons.declaration_accepted')}
            control={
              <Checkbox
                onChange={handleChange}
              />
            }
          />
          <h6 id="parent-modal-title">{t('form:commons.version')}  {retPending.retPending.version}</h6>
          <Divider />
          <br />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="small"
            disabled={enabledButton}
            onClick={handleClick}
          //className={{actions:{justifyContent: 'flex-end'}}}
          >
            {t('button:commons.btn_accept_term')}

          </Button>


        </Box>
      </Modal>
    </div>
  );
}
