import React from "react";
import { SmallLoader, SmallLoaderContainer } from "./styles";

export const Loader = () => (
    <div className="app__loader">
        <div className="loader">Loading...</div>
    </div>
);

export const LoaderSmall = () => (
    <SmallLoaderContainer >
        <SmallLoader />
    </SmallLoaderContainer>
);