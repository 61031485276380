import produce from 'immer';
import { SET_COURSE_FINISHED } from './actions';

const INITIAL_STATE = {
  modules: [],
  course: {},
  sidebarModules: [],
};

export default function vimeo(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@learn/MODULES': {
        draft.modules = action.payload;
        break;
      }
      case '@learn/COURSE': {
        draft.course = action.payload;
        break;
      }
      case SET_COURSE_FINISHED: {
        draft.sidebarModules = action.payload;
      }
      default:
    }
  });
}
