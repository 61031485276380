import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const FilterSelect = ({
  callback,
  value,
  contentList,
  disabled = false,
  text,
}) => {
  const classes = useStyle();

  const onChangeHandle = (e) => {
    if (callback) {
      callback(e.target.value);
    }
  };

  return (
    <FormControl variant="outlined" className={classes.select}>
      <Select
        value={value || 'all'}
        disabled={disabled}
        onChange={onChangeHandle}
      >
        <MenuItem selected value="all">
          {text}
        </MenuItem>
        {contentList.length > 0 &&
          contentList
            .sort((a, b) => (a.name < b.name ? -1 : 1))   // para ordenar alfabeticamente
            .map((item) => (
              <MenuItem key={item?.name} value={item?.name}>
                {item?.name}
              </MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
};

const useStyle = makeStyles((theme) => ({
  select: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 30px 0px #2A2E4329',
    borderRadius: 6
  },
}));

export default FilterSelect;
