import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  chartContentainer: {
    '@media print': {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  legendList: {
    listStyle: 'none',
    maxHeight: '100px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
      borderRadius: '5px',
      background: '#F1f1f1',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      height: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      background: '#176AE6',
      maxHeight: '5px'
    },
    '@media print': {
      maxHeight: 'none'
    }
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  legendDot: {
    content: '',
    display: 'inline-block',
    minHeight: '10px',
    minWidth: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  accumulated: {
    position: 'absolute',
    marginRight: '20px',
    marginBottom: '5px',
    right: 0,
    bottom: 0,
    '& > p': {
      fontWeight: 'bold'
    }
  }
}));
