import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { changePasswordRequest, signInRequest } from 'store/modules/auth/actions';
import { Link as RouterLink } from 'react-router-dom';
import { Topbar } from 'layouts/Minimal/components';

import AuthUserNavigation from 'components/NavAuth';

import { Visibility, VisibilityOff } from '@material-ui/icons/';

import {
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();

  const { t } = useTranslation([
    'title',
    'form',
    'message',
    'button',
    'validation',
  ]);
  const [visible, setVisible] = useState({
    showPassword: false,
    showPasswordConfirmation: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = useCallback(
    ({ password, passwordConfirmation }) => {
      const domain = window.location.hostname;
      try {
        dispatch(changePasswordRequest(password, passwordConfirmation, domain));
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch]
  );

  const handleClickShowPassword = () => {
    setVisible({ ...visible, showPassword: !visible.showPassword });
  };

  const handleClickShowPasswordConfirmation = () => {
    setVisible({ ...visible, showPasswordConfirmation: !visible.showPasswordConfirmation });
  };

  return (
    <div className={classes.root}>
      <Topbar />
      <div className={classes.container}>
        <div className={classes.grid}>
          <div className={classes.titleBox}>
            <Typography className={classes.title} variant="h1">
              {t('title:ChangePassword.title')}
            </Typography>
            <Typography className={classes.subtitle} variant="h6">
              {t('title:ChangePassword.subtitle')}
            </Typography>
          </div>
          <div className={classes.formBox}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.password}
              >
                <InputLabel
                  className={classes.input}
                  htmlFor="outlined-adornment-password"
                >
                  {t('title:ChangePassword.password')}
                </InputLabel>
                <OutlinedInput
                  className={classes.input}
                  id="outlined-adornment-password"
                  type={visible.showPassword ? 'text' : 'password'}
                  name="password"
                  inputRef={register({ required: true, minLength: 6 })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {visible.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={t('form:Signin.password').length * 8.5}
                />
                <FormHelperText>
                  {(errors.password?.type === 'required' &&
                    t('validation:commons.validation_required', {})) ||
                    (errors.password?.type === 'minLength' &&
                      t('validation:commons.validation_min_pass', {
                        minLength: 6,
                      }))}
                </FormHelperText>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.password}
              >
                <InputLabel
                  className={classes.input}
                  htmlFor="title:ChangePassword.passwordConfirmation"
                >
                  {t('title:ChangePassword.passwordConfirmation')}
                </InputLabel>
                <OutlinedInput
                  className={classes.input}
                  id="outlined-adornment-password-confirmation"
                  type={visible.showPasswordConfirmation ? 'text' : 'password'}
                  name="passwordConfirmation"
                  inputRef={register({ required: true, minLength: 6 })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirmation}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {visible.showPasswordConfirmation ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={t('title:ChangePassword.passwordConfirmation').length * 8.5}
                />
                <FormHelperText>
                  {(errors.password?.type === 'required' &&
                    t('validation:commons.validation_required', {})) ||
                    (errors.password?.type === 'minLength' &&
                      t('validation:commons.validation_min_pass', {
                        minLength: 6,
                      }))}
                </FormHelperText>
              </FormControl>
              <Button
                className={classes.sendButton}
                color="primary"
                fullWidth
                type="submit"
                size="large"
                variant="contained"
              >
                {t('title:ChangePassword.change')}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
