import {
  Box,
  Button,
  CardContent,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { BurstMode, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { localizationTable, options } from 'constants/table';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CertificateData, EnrollmentData } from 'services/dataService';
import theme from 'theme';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { format, parseISO, isValid } from 'date-fns';
import { CardMembership } from '@material-ui/icons';

const StudentCertificates = () => {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { searchData: '123' },
  });
  const { t } = useTranslation(['form', 'tables', 'button']);
  const { id, id_company } = useSelector((state) => state.auth);
  const classes = useStyles();

  const [searchField, setSearchField] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const tableRef = React.useRef();

  const onSubmit = React.useCallback(({ searchData }) => {
    setSearchValue(searchData?.id_company_course);
  });

  const handleClick = () => {
    window.open('https://crescamais.tawk.help/article/validade-de-certificados', '_blank');
  }

  const getData = async (filter) => {
    setLoading(true);
    const { data: courses } = await EnrollmentData.getUserCompletedCourses(id, searchValue);
    const { fields, results, enrollmentsPresencial } = courses;
  
    const combinedData = [...results, ...enrollmentsPresencial];
  
    setSearchField(fields || []);
    setData(combinedData);
    setLoading(false);
  }
  useEffect(() => {
    getData();
  }, [searchValue]);


  async function downloadCertificate(event, rowData) {
    const { data: base64Certificate } = await CertificateData.download(
      rowData?.id_company_course,
      id,
      rowData.certificateCreation || rowData.finished_at
    );

    const res = await fetch(base64Certificate);
    const data = await res.blob();
    const secondPageBlob = new Blob([data.slice()], { type: 'application/pdf' });

    let blob = new Blob([data, secondPageBlob], { type: 'application/pdf' });

    FileSaver.saveAs(blob, `${rowData?.name || rowData?.company_course?.course?.name}.pdf`);
  }

  console.log('data', data)

  return (
    <Box className={classes.root}>
      {id_company !== '4b44b51e-c4d0-4209-9fcb-1db56f09b036' && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<CardMembership />}
            target="_blank"
            onClick={handleClick}
          >
            {t('button:User.btn_certificate_validity')}
          </Button>
        </div>
      )}
     <MaterialTable
  isLoading={loading}
  style={{ marginTop: theme.spacing(3) }}
  title={`${t('tables:Certificate.title')}`}
  tableRef={tableRef}
  columns={[
    {
      title: t('tables:Certificate.name_column'),
      field: 'name',
      render: (rowData) => (
        <p
          style={{ color: '#176AE6', cursor: 'pointer' }}
          onClick={(event) => downloadCertificate(event, rowData)}
        >
          {rowData?.name || rowData?.company_course?.course?.name}
        </p>
      ),
    },
    {
      title: t('tables:Certificate.date_emission_column'),
      field: 'certificateCreation',
      type: 'date',
      render: (rowData) => {
        const certificateDate = rowData?.certificateCreation ? parseISO(rowData.certificateCreation) : rowData?.finished_at ? parseISO(rowData.finished_at) : null;
    
        return certificateDate && isValid(certificateDate)
          ? format(certificateDate, 'dd/MM/yyyy - HH:mm')
          : 'N/A';
      }
    },
    {
      title: t('tables:Certificate.grade_column'),
      field: 'score',
      render: (rowData) => (
        <span>{parseFloat(rowData?.score).toFixed(2)} %</span>
      ),
    },
  ]}
        data={data}
        components={{
          search: false,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item md={7} xs={6}>
                      <Controller
                        as={
                          <Autocomplete
                            fullWidth
                            clearOnEscape
                            id="combo-box-demo"
                            options={searchField || []}
                            getOptionLabel={(option) => option?.name || ''}
                            onChange={(_, value) => {
                              setValue('searchData', value?.id)
                            }
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                margin="normal"
                                label={t('form:commons.input_search')}
                                variant="outlined"
                              />
                            )}
                          />
                        }
                        onChange={(data) => data[1]}
                        control={control}
                        name="searchData"
                      />
                    </Grid>
                    <Grid item md={3} xs={1} />
                    <Grid item md={2} xs={5} className={classes.actions}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        <Search style={{ marginRight: 8 }} />
                        {t('button:User_solictation.btn_advanced_filter')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t, {
          emptyDataSourceMessage: 'tables:Certificate.empty_data',
          actions: 'button:commons.btn_download',
        })}
        options={{ ...options, emptyRowsWhenPaging: false }}
        actions={[
          {
            hidden: true,
          },
          {
            icon: 'download',
            tooltip: `Baixar certificado`,
            onClick: (event, rowData) => downloadCertificate(event, rowData),
          },
        ]}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export default StudentCertificates;
