export const localizationTable = function (t, customInfo = {}) {
  return {
    body: {
      emptyDataSourceMessage: t(
        customInfo.emptyDataSourceMessage ||
          'tables:commons.body.emptyDataSourceMessage'
      ),
      filterRow: {
        filterTooltip: t('tables:commons.body.filterRow.filterTooltip'),
      },
    },
    header: {
      actions: t(
        customInfo.actions ||
        'tables:commons.header.label_action'
      ),
    },
    pagination: {
      labelDisplayedRows: t('tables:commons.pagination.labelDisplayedRows'),
      labelRowsSelect: t('tables:commons.pagination.labelRowsSelect'),
      firstTooltip: t('tables:commons.pagination.firstTooltip'),
      previousTooltip: t('tables:commons.pagination.previousTooltip'),
      nextTooltip: t('tables:commons.pagination.nextTooltip'),
      lastTooltip: t('tables:commons.pagination.lastTooltip'),
    },
    toolbar: {
      exportName: t('tables:commons.toolbar.exportName'),
      exportPdf: t('tables:commons.toolbar.exportPdf'),
      exportTitle: t('tables:commons.toolbar.exportTitle'),
      searchPlaceholder: t('tables:commons.toolbar.searchPlaceholder'),
      searchTooltip: t('tables:commons.toolbar.searchTooltip'),
    },
  };
};

export const options = {
  search: false,
  exportButton: true,
  actionsColumnIndex: -1,
  pageSize: 20,
  pageSizeOptions: [5, 10, 20, 50, 100, 200],
  emptyRowsWhenPaging: false,
  rowStyle: (rowData) => ({
    backgroundColor: rowData.tableData.id % 2 === 0 ? '#F6F9FC' : '#FFFF',
  }),
};

export const optionsNoPagination = {
  search: false,
  exportButton: true,
  paging: false,
};
