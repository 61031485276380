import {
  Button,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { localizationTable, options } from 'constants/table';
import { useParams } from 'react-router-dom';
import { EnrollmentData, LessonContentData } from 'services/dataService';
import { format, parseISO } from 'date-fns';
import FileSaver from 'file-saver';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CertificateData } from 'services/dataService';
import { useStyles } from './styles';

export default forwardRef((_, ref) => {
  const classes = useStyles();
  const tableRef = useRef();
  const [modalStatus, setModalStatus] = useState(false);
  const [modalExam, setModalExam] = useState(false);
  const [modalFinishedExam, setModalFinishedExam] = useState(false);
  const [modalFinishedCourse, setModalFinishedCourse] = useState(false);
  const [enrollment, setEnrollment] = useState(null);
  const [data, setData] = useState();
  const [filter, setFilter] = useState({
    name: '',
    status: 2,
  });
  const { id } = useParams();
  const { register, handleSubmit, control } = useForm();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
    'message',
  ]);

  const filterTable = useCallback((filter) => {
    getData(filter);
  });

  const getData = async (filter) => {
    const enrollment = await EnrollmentData.getEnrollment(id, filter);

    const newEnrollment = enrollment.data.enrollments
    .map((elem) => ({
        id_user: elem.id_user,
        id_company: elem.id_company,
        id_company_course: elem.id_company_course,
        date: elem.createdAt,
        name: elem.company_course.course.name,
        teacher: elem.company_course.course?.course_author?.User?.name,
        progress: elem.company_course.course?.percent_progress,
        status: elem.company_course.status,
        active: elem.active,
        finished_at: elem.finished_at,
        score_course: elem.company_course.course?.score,
        score_exam: elem.company_course.course?.score_exam,
        online_classroom: elem.company_course.online_classroom
    }))
    .filter((elem) => !elem.online_classroom);

    setData(newEnrollment);
  };

  const handleDownloadCertificate = useCallback(async (rowData) => {
    const { data: base64CertificateFile } = await CertificateData.download(
      rowData?.id_company_course,
      rowData?.id_user,
      rowData?.finished_at
    );

    const response = await fetch(base64CertificateFile);
    const data = await response.blob();
    const blob = new Blob([data], { type: 'application/pdf' });

    FileSaver.saveAs(blob, `${rowData?.name}.pdf`);
  }, []);

  const finishCourse = useCallback(async () => {
    if (enrollment) {      
      const { id_user, id_company_course } = enrollment;
      await LessonContentData.releaseFinishedCourse(
        id_user,
        id_company_course,
      );
      setModalFinishedExam(true);
      setEnrollment(enrollment);      
    }
  }, [filterTable, enrollment]);

  useEffect(() => {
    getData();
  }, []);

  useImperativeHandle(ref, () => ({
    updateTable() {
      getData();
    },
  }));

  const getStatus = useCallback(
    (value) => {
      if (value)
        return (
          <div className={classes.status}>
            <Brightness1Icon
              fontSize="small"
              color="primary"
              style={{ marginRight: 5 }}
            />
            {t('label:commons.label_status_active')}
          </div>
        );
      else
        return (
          <div className={classes.status}>
            <Brightness1Icon
              color="secondary"
              fontSize="small"
              style={{ marginRight: 5 }}
            />
            {t('label:commons.label_status_inactive')}
          </div>
        );
    },
    [t]
  );

  return (
    <div>
      <MaterialTable
        title={`${t('tables:course.title')}`}
        tableRef={tableRef}
        columns={[
          { title: `${t('tables:commons.name_column')}`, field: 'name' },
          {
            title: `${t('tables:course.createdAt_column_register')}`,
            field: 'date',
            render: (rowData) => format(parseISO(rowData.date), 'dd/MM/yyyy'),
          },
          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            render: (rowData) => getStatus(rowData.status),
          },
          {
            title: `${t('tables:attendance.createdAt_column_register')}`,
            field: 'finished_at',
            render: (rowData) => rowData.finished_at ? (format(parseISO(rowData.finished_at), 'dd/MM/yyyy HH:mm'))  : 'n/a',
          },
          {
            title: `${t('tables:Tabs.progress_settings')}`,
            field: 'progress',
            render: (rowData) =>
              `${((rowData.progress + 100)).toFixed(2)}%`,
          },

          {
            title: `${t('tables:course.teacher_column')}`,
            field: 'teacher',
          },
        ]}
        data={data}
        actions={[
          (rowTable) => {
            const isDisabled = rowTable?.progress < 100 || !rowTable?.active || rowTable.score_exam < rowTable.score_course;
            const shouldDisableDownloadButton = rowTable?.online_classroom === true ? isDisabled : false;
        
            return {
              icon: 'download',
              tooltip: `${t('button:Course.btn_download_certificate')}`,
              onClick: (_, rowData) => handleDownloadCertificate(rowData),
              iconProps: {
                color: shouldDisableDownloadButton ? 'disabled' : 'primary',
              },
              disabled: shouldDisableDownloadButton,
            };
          },
        ]}
        components={{
          search: false,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <CardContent>
                <form onSubmit={handleSubmit(filterTable)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        defaultValue={''}
                        variant="outlined"
                        name="name"
                        inputRef={register({ required: false })}
                        label={t('tables:user_solicitations.name_column')}
                      />
                    </Grid>
                    <Grid item xs={12} md={1} />
                    <Grid item xs={12} md={5} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        <Search style={{ marginRight: '5px' }} />
                        {t('button:commons.btn_advanced_filter')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
      />          
    </div>
  );
});
