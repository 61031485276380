import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Box, Grid, Toolbar, Typography } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import { useSelector } from 'react-redux';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    // alignItems: 'center',
  },
  boxRoot: {
    // width: '100%',
    justifyContent: 'center'
  },
  boxLogo: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
    }
  },
  logo: { 
    maxWidth: 166, 
    objectFit: 'contain', 
    maxHeight: 40,
   },
  menu: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    alignSelf: 'center',
    textDecoration: 'none',
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& span': {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem'
      }
    }
  },
}));

export const tobBar = createMuiTheme({
  palette: {
    primary: {
      main: '#2A2E43',
    },
  },
});

const PublicTopbar = (props) => {
  const { className, ...rest } = props;
  const { t } = useTranslation(['title']);
  const { company } = useSelector((state) => state);
  const [headerCustomization, setHeaderCustomization] = React.useState({
    background_color: '#2A2E43',
    text_color: theme.palette.text.primary,
  });

  const classes = useStyles();

  const loadTopbarStyles = async () => {
    try {
      const {
        data: customization,
      } = await PublicCoursesViewsData.getCompanyCustomization(
        company?.domain_company
      );
      setHeaderCustomization(customization?.company_header);
    } catch (error) {}
  };

  React.useEffect(() => {
    loadTopbarStyles();
  }, []);

  return (
    <ThemeProvider theme={tobBar}>
      <AppBar
        {...rest}
        className={clsx(classes.root, className)}
        style={{ backgroundColor: headerCustomization?.background_color }}
        position="fixed"
      >
        <Toolbar className={classes.boxRoot}>
          <Grid container md={10} alignItems="center" justify="space-between">
            <Grid item xs={6} >
              <Box display="flex" justifyContent="flex-start" className={classes.boxLogo}>
                {headerCustomization?.logo?.url && (
                  <img
                    alt="Logo"
                    src={headerCustomization?.logo?.url}
                    className={classes.logo}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6} >
              <Box display="flex" justifyContent="flex-end" >
                <RouterLink className={classes.menu} to="/login">
                  <Box>
                    <Typography
                      component="span"
                      style={{ color: headerCustomization?.text_color }}
                    >
                      {t('title:PublicTopbar.title_signup')}
                    </Typography>
                  </Box>
                </RouterLink>
              </Box>
              </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

PublicTopbar.propTypes = {
  className: PropTypes.string,
};

export default PublicTopbar;
