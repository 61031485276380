import React, { useCallback, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { VerticalAlignBottom } from '@material-ui/icons';
import { exportToCsv, exportToPdf, exportToXlsx } from 'helpers/reportExport';
import { toast } from 'react-toastify';

const exportActionType = {
  csv: (col, responseData, title) => {
    exportToCsv(col, responseData, title);
  },
  pdf: (col, responseData, title) => {
    exportToPdf(col, responseData, title);
  },
  excel: (col, responseData, title) => {
    exportToXlsx(col, responseData, title);
  },
};

const MenuDownloadReport = ({ 
                              handleFetch, 
                              tableRef, 
                              hasExcel = false,
                              hasPDF = false,
                              hasCSV = false,
                            }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const customExport = useCallback(async (type) => {
    try {
      const columns = tableRef.current.props.columns;
      const responseData = await handleFetch();

      exportActionType[type](
        columns,
        responseData.dataToExport,
        tableRef.current.props.title
      );
    } catch (err) {
      toast.error(`No momento não foi possível baixar o arquivo ${type}`);
    } finally {
      handleClose();
    }
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        aria-controls="export-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <VerticalAlignBottom />
      </Button>
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {hasCSV && (
          <MenuItem onClick={() => customExport('csv')}>
            Exportar CSV
          </MenuItem>
         )}
        {hasPDF && (
          <MenuItem onClick={() => customExport('pdf')}>
            Exportar PDF
          </MenuItem>
          )}
        {hasExcel && (
          <MenuItem onClick={() => customExport('excel')}>
            Exportar Excel
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MenuDownloadReport;
