import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import BoasVindas from '../../assets/contentProduction/Boas-Vindas.jpg';
import Integracao from '../../assets/contentProduction/Integracao.jpg';
import TutoriaisSistema from '../../assets/contentProduction/Tutoriais-sistem.jpg';
import ProcedOperac from '../../assets/contentProduction/Proced-Operac.jpg';
import ConteudosTecnicos from '../../assets/contentProduction/Conteudos-Tecnicos.jpg';
import MuitoMais from '../../assets/contentProduction/Muito-mais.jpg';

function ContentProduction() {
  const classes = useStyles();
  const { t } = useTranslation(['views']);

  return (
    <div className={classes.root}>
      <main>
        <h1>{t('views:ContentProduction.title')}</h1>
        <Interweave
          className={classes.interwave}
          content={t('views:ContentProduction.ourServices')}
        />
        <Interweave
          className={classes.interwave}
          content={t('views:ContentProduction.newSolucions')}
        />

        <section>
          <div className={classes.grid}>
            <div>
              <img src={BoasVindas} alt="dog" />
              <p>{t('views:ContentProduction.welcome')}</p>
            </div>
            <div>
              <img src={Integracao} alt="dog" />
              <p>{t('views:ContentProduction.integration')}</p>
            </div>
            <div>
              <img src={TutoriaisSistema} alt="dog" />
              <p>{t('views:ContentProduction.sistemTutorial')}</p>
            </div>
            <div>
              <img src={ProcedOperac} alt="dog" />
              <p>{t('views:ContentProduction.so')}</p>
            </div>
            <div>
              <img src={ConteudosTecnicos} alt="dog" />
              <p>{t('views:ContentProduction.tecSpecific')}</p>
            </div>
            <div>
              <img src={MuitoMais} alt="dog" />
              <p>{t('views:ContentProduction.more')}</p>
            </div>
          </div>
        </section>

        <section className={classes.prices}>
        <Interweave
          className={classes.interwaveTitle}
          content={t('views:ContentProduction.howMuch')}
        />

          <table>
            <thead>
              <tr>
                <th>{t('views:ContentProduction.videoTime')}</th>
                <th>{t('views:ContentProduction.videoValue')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>0 a 10 {t('views:ContentProduction.minutes')}</td>
                <td>R$ 150,00</td>
              </tr>
              <tr>
                <td>11 a 25 {t('views:ContentProduction.minutes')}</td>
                <td>R$ 200,00</td>
              </tr>
              <tr>
                <td>26 minutos a 1h </td>
                <td>R$ 275,00</td>
              </tr>
              <tr>
                <td>1h a 1h30 </td>
                <td>R$ 325,00</td>
              </tr>
              <tr>
                <td>1h30 a 2h </td>
                <td>R$ 425,00</td>
              </tr>

            </tbody>
          </table>
          <p className={classes.attention}>{t('views:ContentProduction.atention')}</p>
        </section>
      </main>

      <aside className={classes.aside}>
        <h2>{t('views:ContentProduction.needContentProd')}</h2>
        <h4>{t('views:ContentProduction.talkTeam')}</h4>
        <div className={classes.buttonsAside}>
          <a
            href="https://forms.gle/vdKj3zpDeYiPcwTb6"
            rel="noopener noreferrer"
            target="_blank"
          >
            <VideoCallIcon />
            {t('views:ContentProduction.wantMyContent')}
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=35998057344"
            rel="noopener noreferrer"
            target="_blank"
          >
            <WhatsAppIcon />
            {t('views:ContentProduction.talkToExpert')}
          </a>
        </div>
      </aside>
    </div>
  );
}

export default ContentProduction;
