import {
  ButtonBase,
  Grid,
  Icon,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

export default (props) => {
  const { title, text, icon, onClick, active } = props;
  const classes = getStyles(active)();
  return (
    <React.Fragment>
      <ButtonBase className={classes.buttonBase} onClick={onClick}>
        <Grid container spacing={1} xs={12}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex' }}
            justify={'center'}
            alignItems={'center'}
          >
              {icon}
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12}>
              <Typography
                component={'span'}
                style={{ fontWeight: 'bold', fontSize: 20 }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                component={'span'}
                style={{ fontSize: 12 }}
                color="textSecondary"
              >
                {text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ButtonBase>
    </React.Fragment>
  );
};

const getStyles = (active) => {
  return makeStyles((theme) => ({
    buttonBase: {
      width: '100%',
      height: "100%",
      padding: theme.spacing(1),
      borderStyle: "solid",
      borderWidth: active ? 2 : 0,
      borderColor: theme.palette.primary.main,
      borderRadius: 10
    }
  }));
}
