import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },

  gridContainer: {
    paddingBottom: theme.spacing(2),
  },

  rowStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },

  spacer: {
    flexGrow: 1,
  },

  exportButton: {
    marginRight: theme.spacing(2),
  },

  greenIcon: {
    backgroundColor: '#2DCE98 !important',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C !important',
  },

  blueIcon: {
    backgroundColor: '#176AE6 !important',
  },

  redIcon: {
    backgroundColor: '#F53C56 !important',
  },
}));
