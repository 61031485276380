import React from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import { Poll, AddCircle } from '@material-ui/icons';
import SelectFieldsDialog from 'components/SelectFieldsDialog';

export default ({ className, fields, onSave, onReset, ...rest }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button']);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <SelectFieldsDialog
        fields={fields}
        open={open}
        setOpen={setOpen}
        onReset={onReset}
        onSave={onSave}
      />
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid container spacing={1} direction="row">
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <MuiTypography variant="h4">
              {t('label:commons.label_access_quick')}:
            </MuiTypography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <div className={classes.row}>
              <span className={classes.spacer} />
               <Button
                className={classes.exportButton}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setOpen(true);
                }}
              > 
                {t('button:commons.btn_report_fields')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
