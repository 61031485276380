import querystring from 'querystring';
import api from '../api';

class DataServiceCompany {

  getCompanyByHostname() {
    return api.get('public/company?' + querystring.stringify({ domain: window.location.hostname }))
  }
  addCompany(data) {
    return api.post('companies', data);
  }

  updateCompany(id, data) {
    return api.put(`companies/${id}`, data);
  }

  getDashboard(params) {
    const url = 'dashboard?' + querystring.stringify(params);
    return api.get(url);
  }

  getSubplataforms(params) {
    const url = 'dashboard/subplataforms?' + querystring.stringify(params);
    return api.get(url);
  }

  getMenuDashboard(params) {
    return api.get('dashboard/menu?' + querystring.stringify(params));
  }

  getCompanies(params) {
    const url = 'companies?' + querystring.stringify(params);
    return api.get(url);
  }

  getCompanyById(id) {
    return api.get(`companies/${id}`);
  }
}

export default new DataServiceCompany();
