import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import FeaturedCourseIndexHeader from './components/Header';
import CustomTabComponent from 'components/CustomTab';
import InternCourses from './components/InternCourses';
import ExternCourses from './components/ExternCourses';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const FeaturedCourseView = ({}) => {
  const { t } = useTranslation(['views']);
  const classes = useStyles();
  const components = [
    { label: t('views:FeturedCourses.intern'), component: <InternCourses /> },
    { label: t('views:FeturedCourses.extern'), component: <ExternCourses /> },
  ];
  return (
    <Container>
      <FeaturedCourseIndexHeader />
      <Box>
        <CustomTabComponent className={classes.tab} components={components} />
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  tab: {
    marginTop: theme.spacing(6),
  },
}));

export default FeaturedCourseView;
