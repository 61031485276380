import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { LessonData, ModuleData } from 'services/dataService';

export default ({ className, isModalInsert, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const { id, courseId } = useParams();
  const [idCourse, setIdCourse] = useState(courseId);
  const [modules, setModules] = useState([]);
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const values = getValues();

  useEffect(() => {
    if (idCourse) {
      ModuleData.getModuleByIdCourse(idCourse).then(({ data }) => {
        setModules(data?.modules || []);
      });
    }
  }, [idCourse]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await LessonData.getLessonById(id);
          const { title, description, id_course, id_module } = response.data;
          setIdCourse(id_course);

          setValue([{ title }, { description }, { id_module }]);
        } catch (err) {
          history.push('/lessons');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };

      fetchData();
    }

    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
    try {
      if (id) {
        await LessonData.updateLesson(id, data);
        toast.success(t('toastify:commons.toast_update'));
        history.push(`/courses/${idCourse}/lessons`);
      } else {
        await LessonData.addLesson({
          ...data,
          id_course: courseId,
        });
        toast.success(t('toastify:commons.toast_success'));
        history.push(`/courses/${courseId}/lessons`);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const titlePage = id
    ? t('title:Lessons.title_edit')
    : t('title:Lessons.title_create');

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={titlePage} />
        <Divider />
        {!loading && (
          <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Lessons.input_title')}
                    helperText={
                      errors.title?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Lessons.input_title'),
                      })
                    }
                    name="title"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.title}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Lessons.input_description')}
                    name="description"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register}
                    error={!!errors.description}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    as={
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          {t('title:Module.module_label')}
                        </InputLabel>
                        <Select
                          labelId="title:Module.module_label"
                          label="Escolha um módulo"
                          value={values['id_module']}
                          onChange={(e) =>
                            setValue('id_module', e.target.value, true)
                          }
                        >
                          {modules.map((_, i) => (
                            <MenuItem key={i} value={_.id}>
                              {_.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                    name={'id_module'}
                    control={control}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
    </>
  );
};
