import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import TeamData from 'services/dataService/TeamData';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function AddTeamDialog({
  id,
  callback,
  open,
  setOpen,
  title,
  description,
}) {
  const { t } = useTranslation(['toastify', 'button', 'form']);
  const [teamsList, setTeamsList] = React.useState([]);
  const [selectedTeam, setSelectedteam] = React.useState('');

  const getTeams = async () => {
    try {
      const response = await TeamData.getUserUnassigned(id);
      const teams = response.data;
      setTeamsList(teams);
    } catch (error) {
      console.error(error);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };
  React.useState(() => {
    getTeams();
  });

  const handleSave = () => {
    callback(selectedTeam, getTeams);
    handleClose();
  };

  const handleClose = () => {
    setTeamsList([]);
    setSelectedteam('');

    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <Select
          fullWidth
          value={selectedTeam}
          onChange={(e, value) => {
            setSelectedteam(e.target.value);
          }}
        >
          {teamsList.map(({ id, name }, index) => (
            <MenuItem key={id + index} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('form:Actions.cancel')}
        </Button>
        <Button onClick={handleSave} color="primary">
          {t('button:commons.btn_add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
