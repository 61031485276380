import React from 'react';

import TagTable from '../Table';

import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TagTable />
    </div>
  );
};
