import querystring from 'querystring';
import api from '../api';

class DataServiceSubCategory {
  addSubCategory(data) {
    return api.post('subcategories', data);
  }

  getSubCategories(params) {
    const url = 'subcategories?' + querystring.stringify(params);
    return api.get(url);
  }

  getSubCategoryById(id) {
    return api.get(`subcategories/${id}`);
  }

  updateSubCategory(id, data) {
    return api.put(`subcategories/${id}`, data);
  }
}

export default new DataServiceSubCategory();
