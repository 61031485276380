import React, { useState } from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { Switch } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { HandleDelete } from 'components/';

export default function TableForm({ row, onChangeValue, onDeleteValue }) {
  const { t } = useTranslation(['toastify', 'views', 'tables']);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <div className={classes.flex}>
      <div className={clsx([classes.box])}>
        <label className={classes.label}>
          * {t('views:customFields.field_name_label')}
        </label>
        <TextField
          id="outlined-basic"
          label=""
          value={row.fieldName}
          onChange={(e) => onChangeValue({ ...row, fieldName: e.target.value })}
          variant="outlined"
          placeholder="Nome"
          InputLabelProps={{ shrink: false }}
        />

        <div className={clsx([classes.flex])}>
          <label className={classes.label}>
            {t('views:customFields.required_field')}
          </label>
          <Switch
            checked={row.required}
            onChange={() => onChangeValue({ ...row, required: !row.required })}
            color="primary"
            name="signable"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <span className={classes.tiny}>
          {t('views:customFields.hint_required')}
        </span>
      </div>

      <div className={clsx([classes.box])}>
        <label
          className={clsx([classes.flex, classes.label])}
        >
          <div className={classes.tips}>
            <HelpOutlineIcon
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className={classes.helpOutlineIcon}
            />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{ paper: classes.paper }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div>{t('views:customFields.hint')}</div>
            </Popover>
          {t('views:customFields.tips')}
          </div>
        </label>
        <TextField
          id="outlined-basic"
          label=""
          value={row.tips}
          onChange={(e) => onChangeValue({ ...row, tips: e.target.value })}
          placeholder={t('views:customFields.hint')}
          variant="outlined"
          InputLabelProps={{ shrink: false }}
        />

        <label className={classes.label}>
          {t('views:customFields.displayOn')} &nbsp;
          <FormControlLabel
            control={
              <Checkbox
                checked={row.visibleToCreate}
                onChange={() =>
                  onChangeValue({
                    ...row,
                    visibleToCreate: !row.visibleToCreate,
                  })
                }
              />
            }
            label={t('views:customFields.insert')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={row.visibleToEdit}
                onChange={() =>
                  onChangeValue({
                    ...row,
                    visibleToEdit: !row.visibleToEdit,
                  })
                }
              />
            }
            label={t('views:customFields.update')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={row.visibleToRegister}
                onChange={() =>
                  onChangeValue({
                    ...row,
                    visibleToRegister: !row.visibleToRegister,
                  })
                }
              />
            }
            label={t('views:customFields.register')}
          />
        </label>
      </div>
      <div className={clsx([classes.boxdelete])}>
        <Tooltip title={t('tables:commons.actions.delete_action')} >
          <DeleteIcon
            color='error'
            cursor='pointer'
            onClick={() => setDeleteModalOpen(true)}
          />
        </Tooltip>
      </div>
      <HandleDelete
        closeModal={setDeleteModalOpen}
        isOpen={deleteModalOpen}
        propsHandleConfirm={() => onDeleteValue(row.id)}
      />

    </div>
  );
}
