import { Box, Grid } from '@material-ui/core';
import React from 'react';
import BuyKnowledgeTrailCard from './components/BuyKnowledgeTrailCard';
import KnowledgeTrailDescription from './components/KnowledgeTrailDescription';

const KnowledgeTrailHeader = ({
  info,
  icon,
  handleTrailEnroll,
  handleCourse,
  finished,
  progress,
  nextCourseId,
}) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={8} lg={9}>
          <KnowledgeTrailDescription data={info} icon={icon} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <BuyKnowledgeTrailCard
            data={info}
            handleTrailEnroll={handleTrailEnroll}
            handleCourse={handleCourse}
            finished={finished}
            progress={progress}
            nextCourseId={nextCourseId}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default KnowledgeTrailHeader;
