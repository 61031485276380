const { makeStyles } = require("@material-ui/styles");

export const useStyles = makeStyles((theme) => ({
  cardUpload: {
    display: 'flex',
  },
  deleteAvatar: {
    position: 'absolute',
    top: -5,
    right: 5,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  infoGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  userInfo: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(0),
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  cardcontent: {
    width: '100%',
    '&:last-child': {
      paddingBottom: 16,
    },
  },
}));
