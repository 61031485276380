import { Button } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { ReportLookerData } from 'services/dataService';
import { localizationTable } from 'constants/table';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';


const ModalLooker = ({ setOpen }) => {
    const tableRef = useRef();
    const { t } = useTranslation(['title', 'button', 'views']);
    const [data, setData] = useState({
        commentsLooker: [],
        enrollmentsLooker: [
            { item: 'Comentários', update: '07/12/2023' },
            { item: 'Feedback Cursos', update: '07/12/2023' },
            { item: 'Todas matrículas', update: '07/12/2023' },
        ],
        ratingsLooker: [],
    });

    const handleIconClick = async (rowData) => {
        try {
            let response = null;
            if (rowData.item === 'Comentários') {
                response = await ReportLookerData.addAllComments();
            } else if (rowData.item === 'Feedback Cursos') {
                response = await ReportLookerData.addAllRatings();
            } else if (rowData.item === 'Todas matrículas') {
                response = await ReportLookerData.addAllEnrollments();
            }

            console.log('resposta', response.data.last_update)
            console.log('resposta', response)

            if (response && response.last_update) {
                setData((prevData) => ({
                    ...prevData,
                    enrollmentsLooker: prevData.enrollmentsLooker.map((item) =>
                        item.item === rowData.item ? { ...item, update: response.last_update } : item
                    ),
                }));
            }
        } catch (err) {
            if (err.response?.status === 403) {
                toast.error(t('toastify:commons.toast_error_403'));
            } else {
                toast.error(t('toastify:commons.toast_error_api'));
            }
        }
    };

    return (
        <Dialog open={setOpen}>
            <DialogTitle style={{ fontSize: '22px', textAlign: 'center' }}>LookerStudio</DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <MaterialTable
                    ref={tableRef}
                    columns={[
                        {
                            title: 'Planilha',
                            field: 'item',
                        }, {
                            title: 'Atualizado em',
                            field: 'update',
                        },
                    ]}
                    data={data.enrollmentsLooker}
                    actions={[
                        (rowData) => ({
                            icon: 'sync',
                            tooltip: 'Executar Requisição',
                            onClick: (event, rowData) => handleIconClick(rowData),
                            iconProps: {
                                color: 'primary',
                            },
                        }),
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        paging: false,
                        emptyRowsWhenPaging: false,
                        rowStyle: (rowData) => ({
                            backgroundColor:
                                rowData.tableData.id % 2 === 0 ? '#F6F9FC' : '#FFFF',
                        }),
                    }}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(false)}
                    style={{ padding: '10px 50px' }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalLooker;