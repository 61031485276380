import React from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


export function Loading({loading}) {

  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards'])
  const classes = useStyles()

  return (
    <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
    </div>
  )
}
