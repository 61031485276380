import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PublicTopbar from './components/Topbar/Topbar';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 64,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
    },
  },
  content: {
    height: '100%',
  },
}));

const PublicView = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PublicTopbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

PublicView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PublicView;
