import React from 'react';
import { dashboardCompanyType } from 'constants/types';
import { Dashboard } from 'views/Dashboard/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dashboard type={dashboardCompanyType.only_current_company} />
    </div>
  );
};
