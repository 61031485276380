import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { signInRequest } from 'store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@material-ui/icons/';
import AuthUserNavigation from 'components/NavAuth';
import InputMask from 'react-input-mask';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CustomFieldsData, MyUserData } from 'services/dataService';
import CustomFields from './components/CustomFields';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();
  const [customFieldsRegister, setCustomFieldsRegister] = useState([]);
  const [value, setValue] = React.useState('');
  const [valueEmail, setValueEmail] = React.useState('');
  const { register, handleSubmit, errors, watch } = useForm();
  const { company } = useSelector((state) => state);
  const password = useRef({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState({
    showPassword: false,
    showPasswordConfirmation: false,
  });

  password.current = watch('password', '');

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'message',
    'toastify',
  ]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (company?.only_invited_users) {
      history.push('/');
    }

    try {
      const fetchCustomFieldsRegister = async () => {
        const companyId = company?.domain_company;
        const { data } = await CustomFieldsData.getCustomFieldsRegister(
          companyId
        );

        if (data) {
          const response = JSON.parse(data?.custom_fields);
          const formatedResponse = response.filter(
            (customField) => customField.visibleToRegister
          );

          setCustomFieldsRegister(formatedResponse);
        }
      };

      fetchCustomFieldsRegister();
    } catch (error) {
      toast.error(t('toastify:commons.toast_error'));
    }
  }, []);

  const formatCustomFieldsRequest = (data) => {
    return customFieldsRegister
      .map((customField) => {
        if (data[customField.fieldName]) {
          return {
            ...customField,
            fieldValue: data[customField.fieldName],
          };
        }
      })
      .filter((customField) => customField !== undefined);
  };

  const onSubmit = async (data) => {
    try {
      data.email = data.email.toLowerCase()
      if (customFieldsRegister.length > 0) {
        const response = formatCustomFieldsRequest(data);

        data.array_custom_field_values = response;
      }

      delete data.confirmPassword;
      const domain = window.location.hostname;

      const isEmailFilled = !!valueEmail;
      const isCpfFilled = !!value;

      if (!isEmailFilled && !isCpfFilled) {
        toast.error(t('toastify:commons.required_field_email_cpf'));
        return;
      }


      await MyUserData.addUser({
        ...data,
        domain,
        id_group: '4a51b764-ef12-42f4-b236-8ad615348039', //ainda não tem devops para retornar (por enquanto ficará assim) - tem uma tarefa para pegar o id com base no dominio
        self: true,
      });

  
      dispatch(signInRequest(data.email ? data.email : data.cpf , data.password, domain));

      toast.success(t('toastify:commons.toast_success'));
      toast.info(t('toastify:commons.toast_direct'), {
        autoClose: 9000,
      })

    } catch (error) {
      if (error?.response?.data?.code === 'USER_ALREADY_EXISTS') {
        toast.error(t('toastify:user.toast_user_already_exists'));
      } else {
        toast.error(t('toastify:commons.toast_error'));
      }
    }
  };

  const handleClickShowPassword = () => {
    setVisible({ ...visible, showPassword: !visible.showPassword });
  };

  const handleClickShowPasswordConfirmation = () => {
    setVisible({
      ...visible,
      showPasswordConfirmation: !visible.showPasswordConfirmation,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === ' ' || event.key === '/' || event.key === '\\') {
      event.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      {!company?.only_invited_users && (
        <div className={classes.container}>
          <AuthUserNavigation index={1} />
          <div className={classes.containerBox}>
            <div className={classes.titleBox}>
              <Typography className={classes.title} variant="h1">
                {t('title:commons.title_sigup')}
              </Typography>
              <Typography className={classes.subtitle} variant="h6">
                {t('title:commons.subtitle_signup')}
              </Typography>
            </div>
            <div className={classes.formBox}>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Grid item md={12} xs={12} className={classes.fieldGroup}>
                  <TextField
                    fullWidth
                    autoFocus
                    label={t('form:User.input_first_name')}
                    name="name"
                    type="text"
                    variant="outlined"
                    inputRef={register({
                      required: true,
                    })}
                    error={!!errors.name}
                  />
                </Grid>

                <Grid item md={12} xs={12} className={classes.fieldGroup}>
                  <TextField
                    fullWidth
                    onKeyPress={handleKeyPress}
                    label={t('form:Signin.email')}
                    value={valueEmail}
                    onChange={(e) => setValueEmail(e.target.value)}
                    helperText={
                      (errors.email?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.input_email'),
                        })) ||
                      (errors.email?.type === 'pattern' &&
                        t('validation:commons.validation_email', {
                          field: t('form:Company.input_email'),
                        }))
                    }
                    name="email"
                    type="text"
                    variant="outlined"
                    inputRef={register({
                      required: false,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      },
                    })}
                    error={!!errors.email}
                  />
                </Grid>
                <Grid item md={12} xs={12} className={classes.fieldGroup}>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar=""
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  >
                    {() => (
                      <TextField
                        fullWidth
                        onKeyPress={handleKeyPress}
                        label={t('form:Signin.only_cpf')}
                        name="cpf"
                        helperText={
                          (errors.cpf?.type === 'required' &&
                            t('validation:commons.validation_required', {
                              field: t('form:Company.input_cpf'),
                            })) ||
                          (errors.cpf?.type === 'pattern' &&
                            t('validation:commons.validation_email', {
                              field: t('form:Company.input_cpf'),
                            }))
                        }
                        type="text"
                        variant="outlined"
                        inputRef={register({
                          required: false,
                          pattern: {
                            value: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                          },
                        })}
                        error={!!errors.cpf}
                      />
                    )}
                  </InputMask>
                </Grid>

                {customFieldsRegister?.map((customField) => {
                  if (!customField?.visibleToRegister) {
                    return <Fragment key={customField?.id} />;
                  }
                  return (
                    <CustomFields
                      key={customField?.id}
                      type={customField?.fieldType}
                      placeholder={customField?.fieldName}
                      fieldName={customField?.fieldName}
                      required={customField?.required}
                      register={register}
                      errors={errors}
                    />
                  );
                })}

                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.password}
                  className={classes.passwordField}
                >
                  <InputLabel
                    className={classes.input}
                    htmlFor="outlined-adornment-password"
                  >
                    {t('form:Signin.password')}
                  </InputLabel>
                  <OutlinedInput
                    className={classes.input}
                    id="outlined-adornment-password"
                    type={visible.showPassword ? 'text' : 'password'}
                    name="password"
                    inputRef={register({ required: true, minLength: 6 })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {visible.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={t('form:Signin.password').length * 8.5}
                  />
                  <FormHelperText>
                    {(errors.password?.type === 'required' &&
                      t('validation:commons.validation_required', {})) ||
                      (errors.password?.type === 'minLength' &&
                        t('validation:commons.validation_min_pass', {
                          minLength: 6,
                        }))}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.confirmPassword}
                >
                  <InputLabel
                    className={classes.input}
                    htmlFor="outlined-adornment-password-confirmation"
                  >
                    {t('form:Signin.password_repeat')}
                  </InputLabel>
                  <OutlinedInput
                    className={classes.input}
                    id="outlined-adornment-password-confirmation"
                    type={
                      visible.showPasswordConfirmation ? 'text' : 'password'
                    }
                    name="confirmPassword"
                    inputRef={register({
                      required: true,
                      minLength: 6,
                      validate: (value) => value === password.current,
                    })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirmation}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {visible.showPasswordConfirmation ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={t('form:Signin.password_repeat').length * 8.5}
                  />
                  <FormHelperText>
                    {(errors.confirmPassword?.type === 'required' &&
                      t('validation:commons.validation_required', {})) ||
                      (errors.confirmPassword?.type === 'minLength' &&
                        t('validation:commons.validation_min_pass', {
                          minLength: 6,
                        })) ||
                      (errors.confirmPassword?.type === 'validate' &&
                        t('validation:commons.validation_password_match', {}))}
                  </FormHelperText>
                </FormControl>

                <Button
                  className={classes.sendButton}
                  color="primary"
                  fullWidth
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  {t('button:Login.btn_signup')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
