import React, { useState, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';
import { HandleDelete } from 'components/';
import { localizationTable, options } from 'constants/table';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from './styles';

import { CategoryData } from 'services/dataService';
import { statusCategories } from 'constants/status';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';

export default () => {
  const [status, setStatus] = useState('true');
  const [name, setName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [route, setRoute] = useState('');

  const handleModalOpen = useCallback(({ id }) => {
    setModalStatus(true);
    setRoute(`categories/${id}`);
  });

  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`categories/${id}/edit`);
    },
    [history]
  );

  const onSubmit = (data) => {
    setName(data.name);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
    <div className='notranslate'>
    <MaterialTable
        title={`${t('tables:category.title')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('tables:category.name_column')}`,
            field: `name`,
            sorting: false,
            render: (rowData) => rowData.name,
          },
          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            sorting: false,
            render: (rowData) => (
              <div style={{ display: 'flex' }}>
                <CircleIcon
                  style={statusCategories[rowData.status]?.style}
                  fontSize="small"
                />
                <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                  {t(statusCategories[rowData.status]?.title)}
                </span>
              </div>
            ),
          },
        ]}
        data={async (query) => {
          try {
            const response = await CategoryData.getCategories({
              page: query.page + 1,
              limit: query.pageSize,
              status,
              name,
            });

            return {
              data: response.data.categories,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:Categories.input_category')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      {/* <FormControl style={{ minWidth: 100 }}>
                        <InputLabel id="status">
                          {t('form:User_solicitation.input_status_select')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value="true">
                                {t(statusCategories['true']?.title)}
                              </MenuItem>
                              <MenuItem value="false">
                                {t(statusCategories['false']?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={status ? status : 'true'}
                          value={status ? status : 'true'}
                        />
                      </FormControl> */}
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
        actions={[
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.edit_action')}`,
            onClick: (event, rowData) => {
              handleEdit(rowData);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
          },
        ]}
      />
    </div>
      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
      />
    </>
  );
};
