import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';

const FeaturedTrailsCard = ({
  nameTrails,
  title,
  fieldsTrails,
  onSelectedTrails,
  checkedListTrails,
  setCheckedListTrails,
}) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [value, update] = React.useState(false);
  const { t } = useTranslation(['form']);

  return (
    <React.Fragment>
      <Typography className={classes.fullWidthSpan}>{title}</Typography>
      <Autocomplete
        fullWidth
        clearOnEscape
        options={fieldsTrails ? fieldsTrails : []}
        getOptionLabel={(option) => option?.name}
        onChange={(e, value) => {
          setSearch(value?.id ? value?.id : '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="normal"
            label={t('form:Teams.search_field', { nameTrails })}
            variant="outlined"
          />
        )}
      />
      <Container className={classes.selectField}>
        {fieldsTrails?.map((fieldTrails) => {
          return (
            <div key={fieldTrails?.id} hidden={search.length && search !== fieldTrails?.id}>
              <FormControlLabel
                style={{ width: '100%' }}
                control={
                  <Checkbox
                    checked={checkedListTrails
                      ?.map(({ id }) => id)
                      ?.includes(fieldTrails.id)}
                    onChange={(e, checkedTrails) => {
                      if (checkedTrails) {
                        checkedListTrails.push({ id: fieldTrails.id, name: fieldTrails.name });
                      } else {
                        setCheckedListTrails(
                            checkedListTrails.filter(({ id }) => id !== fieldTrails.id)
                        );
                      }
                      update(!value);
                      if (onSelectedTrails) {
                        onSelectedTrails({ id: fieldTrails.id, name: fieldTrails.name }, checkedTrails);
                      }
                    }}
                  />
                }
                label={fieldTrails?.name}
              />
            </div>
          );
        })}
      </Container>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  fullWidthSpan: {
    display: 'block',
    lineHeight: '100%',
    width: '100%',
    fontWeight: '600',
    fontSize: 'medium',
    marginTop: theme.spacing(4),
  },

  selectField: {
    height: 250,
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginTop: theme.spacing(1),
    padding: 0,
  },
}));

export default FeaturedTrailsCard;
