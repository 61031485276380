import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import content from 'views/Learn/content';

const EventButtons = ({ event }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.title} color={event?.title_color}>
          {event?.title}
        </Typography>
      </Box>
      <Box className={classes.boxEvents}>
        <Grid container md={10} sm={12} justify="space-evenly">
          {event?.event_buttons
            ?.sort((a, b) => {
              return a?.order > b?.order ? 1 : -1;
            })
            ?.map(({ icon, title, title_color, subtitle, subtitle_color }) => (
              <Grid item xs={6} sm={4} md={3} lg={2} align="center" className={classes.eachEvent}>
                <Typography className={classes.topSpacing} />
                <Box>
                  <Box
                    style={{ backgroundImage: `url(${icon?.url})` }}
                    className={classes.buttonIcon}
                  />
                  <Typography className={classes.topSpacing1} />
                  <Box>
                    <Typography
                      className={classes.buttonTitle}
                      style={{ color: title_color }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  <Typography className={classes.topSpacing1} />
                  <Box>
                    <Typography
                      className={classes.buttonSubtitle}
                      style={{ color: subtitle_color }}
                    >
                      {subtitle?.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          {index < subtitle.split('\n').length - 1 && <br />} 
                        </span>
                      ))}                 
                     </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '104px 32px',
    marginTop: theme.spacing(11),
    backgroundColor: '#fff'
  },
  boxEvents: {
    display: 'flex',
    justifyContent: 'center',
  },
  eachEvent: {
    minWidth: 245,
    [theme.breakpoints.down('sm')]: {
      padding: '0px !important'
    }
  },
  title: {
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '120%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0),
    }
  },
  topSpacing: {
    paddingTop: theme.spacing(4),
  },
  topSpacing1: {
    paddingTop: theme.spacing(2),
  },
  buttonIcon: {
    width: 120,
    height: 120,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  buttonTitle: {
    textAlign: 'center',
    ...theme.typography.h4,
    fontWeight: 700,
    lineHeight: '100%',
  },
  buttonSubtitle: {
    textAlign: 'center',
  },
}));

export default EventButtons;
