import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    '& img': {
      objectFit: 'contain',
    }
  },
  containerImageBanner: {
    '& img': {
      display: 'none',
      maxWidth: '700px',
      maxHeight: '350px',
    }
  },
  containerImageCard: {
    '& img': {
      maxWidth: '350px',
      maxHeight: '175px',
    }
  },  

}));
