import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import {
  AddCircleOutline,
  CheckBoxOutlined,
  RadioButtonChecked,
  Save
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FilesData, LessonContentData, VimeoData } from 'services/dataService';
import LessonQuestionsData from 'services/dataService/LessonQuestionsData';
import CheckboxEvalution from 'views/Exams/components/CheckboxEvalution';
import RadioButtonEvalution from 'views/Exams/components/RadioButtonEvalution';
export class QuestionTypeEnum {
  static NONE_QUESTION = 0;
  static CHECKBOX_QUESTION = 1;
  static DISCURSIVE_QUESTION = 2;
  static UPLOAD_QUESTION = 3;
  static RADIO_QUESTION = 4;
}

class LessonType {
  static ExamType = 6;
}

const EvaluationList = ({ numberOfAttempts, setNumberOfAttempts }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['button']);
  const [lessonsExams, setLessonsExams] = useState([]);
  const [selectedButton, setSelectedButton] = useState(
    QuestionTypeEnum.NONE_QUESTION
  );
  const [refresh, setRefresh] = useState(false);
  const { id: id_user } = useSelector((state) => state.auth);
  const { id_lesson, id_lesson_content } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(false);
  const [btnCancelText, setBtnCancelText] = useState(
    t('button:commons.btn_cancel')
  );

  const update = useRef(false);
  const formatNumberOfAttemptsWithoutLeadingZero = parseInt(
    numberOfAttempts,
    10
  );

  const addLessonExam = () => {
    lessonsExams.push({
      type_exam: selectedButton,
      lesson_content_exam_questions: Array(4).fill({
        description: '',
        is_correct: false,
      }),
      question: '',
      weight: 1,
      required: true,
    });
    setLessonsExams(lessonsExams);
    setRefresh(!refresh);
  };

  const onTypeChange = () => {
    setRefresh(!refresh);
  };

  const onDelete = (index) => {
    setLessonsExams(lessonsExams.filter((_, i) => index !== i));
  };

  const validateQuestionfields = () => {
    if (!name.length) {
      setError(true);
      return false;
    }

    if (!lessonsExams.length) {
      toast.error('Avaliação sem perguntas');
      setError(true);
      return false;
    }

    for (const questions of lessonsExams) {
      if (
        !questions?.question?.length ||
        !questions?.lesson_content_exam_questions.length
      ) {
        if (!questions?.lesson_content_exam_questions.length) {
          toast.error('Questão sem respostas');
        }
        setError(true);
        return false;
      }
      let correct_answers_sum = 0;
      for (const exam_questions of questions?.lesson_content_exam_questions) {
        if (!exam_questions?.description.length) {
          setError(true);
          return false;
        }
        if (exam_questions?.is_correct) {
          correct_answers_sum++;
        }
      }
      if (!correct_answers_sum > 0) {
        toast.error('Questão sem resposta correta');
        setError(true);
        return false;
      }
    }

    return true;
  };

  const saveQuestions = async () => {
    try {
      if (!validateQuestionfields()) {
        return;
      }
      let lessons = lessonsExams?.map((lesson) => {
        let weight = lesson?.weight;
        if (!weight?.length) {
          weight = 1;
        }
        return {
          ...lesson,
          weight: Number.parseFloat(weight || 0),
        };
      });

      lessons = await Promise.all(
        lessons.map(async (lesson) => {
          if (lesson.image) {
            const response = await FilesData.addFile(lesson.image, `Exam`);

            delete lesson.image;
            return {
              ...lesson,
              id_file: response.data.id,
            };
          } else if (lesson.video) {
            const response = await VimeoData.addVimeo(
              { name: '', description: '' },
              lesson.video
            );

            delete lesson.video;
            if (response.data.url)
              return {
                ...lesson,
                url_vimeo: response.data.url,
              };
          }
          return lesson;
        })
      );

      if (update.current) {
        const { data: lesson } = await LessonContentData.updateLessonContent(
          id_lesson_content,
          {
            id_lesson,
            type: LessonType.ExamType,
            max_exam_attempts: formatNumberOfAttemptsWithoutLeadingZero,
            url_vimeo: null,
            id_file: null,
            name: name,
            description: description,
          }
        );
        const { data } = await LessonQuestionsData.updateLessonQuestions(
          id_user,
          lesson[0]?.id,
          {
            lesson_content_exams: lessons,
          }
        );
        setLessonsExams(data);

        history.replace(`/lesson-exam/${lesson[0]?.id}/${id_lesson}`);
      } else {
        const { data: lesson } = await LessonContentData.addLessonContent({
          id_lesson,
          type: LessonType.ExamType,
          url_vimeo: null,
          id_file: null,
          name: name,
          max_exam_attempts: formatNumberOfAttemptsWithoutLeadingZero,
          description: description,
        });
        const { data } = await LessonQuestionsData.updateLessonQuestions(
          id_user,
          lesson?.id,
          {
            lesson_content_exams: lessons,
          }
        );
        setLessonsExams(data);

        update.current = true;
        history.replace(`/lesson-exam/${lesson?.id}/${id_lesson}`);
      }
      toast.success('Sucesso!');
      setBtnCancelText(t('button:commons.btn_back'));
    } catch (error) {
      toast.error('Erro');
      console.error(error);
    }
  };

  const loadQuestions = async () => {
    try {
      if (id_lesson_content !== 'new') {
        const { data: lessonContent } =
          await LessonQuestionsData.getlessonQuestions(
            id_user,
            id_lesson_content
          );
        setName(lessonContent?.name || '');
        setDescription(lessonContent?.description || '');
        setLessonsExams(lessonContent?.lesson_content_exams || []);
        setNumberOfAttempts(lessonContent?.max_exam_attempts || 3);
        update.current = true;
      }
    } catch (error) {
      toast.error('Erro na API');
    }
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.headerBox}>
        <TextField
          fullWidth
          label="Nome da avaliação"
          value={name}
          error={error && !name.length}
          helperText={error && !name.length && 'Obrigatório'}
          onChange={(e) => setName(e.target.value)}
          className={classes.nameTitle}
        />
        <TextField
          fullWidth
          label="Descrição"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={classes.description}
        />
      </Box>
      <Typography className={classes.marginTop} />
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={2} md={8}>
            <IconButton
              size="medium"
              disabled={selectedButton === QuestionTypeEnum.NONE_QUESTION}
              onClick={addLessonExam}
              color="primary"
              title="Adicionar questão"
            >
              <AddCircleOutline fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={5} md={2} className={classes.gridBtnsQuestions}>
            <Button
              variant="contained"
              onClick={() => setSelectedButton(QuestionTypeEnum.RADIO_QUESTION)}
              color={
                selectedButton === QuestionTypeEnum.RADIO_QUESTION
                  ? 'primary'
                  : ''
              }
            >
              <RadioButtonChecked className={classes.icon} />
              {t('button:Exams.btn_single_selection')}
            </Button>
          </Grid>
          <Grid item xs={5} md={2} className={classes.gridBtnsQuestions}>
            <Button
              variant="contained"
              onClick={() => {
                setSelectedButton(QuestionTypeEnum.CHECKBOX_QUESTION);
              }}
              color={
                selectedButton === QuestionTypeEnum.CHECKBOX_QUESTION
                  ? 'primary'
                  : ''
              }
            >
              <CheckBoxOutlined className={classes.icon} />
              {t('button:Exams.btn_multiple_selection')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Typography className={classes.marginTop} />
      {lessonsExams.map((exam, index) => (
        <>
          <Typography className={classes.marginTop} />
          {exam?.type_exam === QuestionTypeEnum.RADIO_QUESTION ? (
            <RadioButtonEvalution
              exam={exam}
              index={index}
              onTypeChange={onTypeChange}
              onCopy={() => {
                console.log('onCopy');
              }}
              onDelete={onDelete}
              error={error}
            />
          ) : (
            <CheckboxEvalution
              exam={exam}
              index={index}
              onTypeChange={onTypeChange}
              onCopy={() => {
                console.log('onCopy');
              }}
              onDelete={onDelete}
              error={error}
            />
          )}
        </>
      ))}
      <Typography className={classes.marginTop} />
      <Box className={classes.footer}>
        <Grid container spacing={2} justify="center" alignContent="center">
          <Grid item xs={6} align="left">
            <Button
              variant="text"
              color="primary"
              onClick={() => history.goBack()}
            >
              {btnCancelText}
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <Button variant="contained" color="primary" onClick={saveQuestions}>
              <Save /> {t('button:commons.btn_save')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  marginTop: {
    paddingTop: theme.spacing(3),
  },
  headerBox: {
    backgroundColor: theme.palette.white,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    boxShadow: '0px 8px 50px #2A2E4320',
    borderRadius: 8,
    borderBottomColor: theme.palette.info.custom,
    borderBottomWidth: 10,
    borderBottomStyle: 'solid',
    marginBottom: theme.spacing(1),
  },
  nameTitle: {
    marginBottom: theme.spacing(3),
    '& input': {
      ...theme.typography.h2,
      fontSize: 30,
    },
  },
  description: {
    '& input': {
      fontSize: 17,
    },
  },
  gridBtnsQuestions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  footer: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    boxShadow: '0px 8px 50px #2A2E4320',
    borderRadius: 5,
  },
}));

export default EvaluationList;
