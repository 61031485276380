import React from 'react';
import { useStyles } from './styles';
import { LessonToolbar, ModuleList } from '../components';
import {
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import history from 'services/history';
import { AddCircle } from '@material-ui/icons';
import useWindowDimensions from 'customHooks/DimensionHook';
import { CourseData } from 'services/dataService';

export default () => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const [is_owner, setIsOwner] = React.useState(false);

  const history = useHistory();
  const { id } = useParams();

  const getCourseData = async () => {
    const { data: course } = await CourseData.getCourses({ id_course: id });
    const { is_owner } = course.courses[0];
    setIsOwner(is_owner);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const classes = useStyles();

  const { width } = useWindowDimensions();

  React.useEffect(() => {
    getCourseData();
  }, []);

  return (
    <div className={classes.root}>
      <LessonToolbar />
      <div className={classes.content}>
        {is_owner ? (
          <div
            className={classes.toolbar}
            style={{ display: width > 959 ? 'flex' : 'block' }}
          >
            <Button
              color="secondary"
              variant="contained"
              size="small"
              className={clsx(classes.topButtonSpacing, classes.topButton)}
              onClick={() => history.push(`/module/${id}/create`)}
            >
              <AddCircle style={{ marginRight: '5px' }} />

              {t('button:Lesson.btn_create_module')}
            </Button>

            <Button
              color="secondary"
              variant="contained"
              size="small"
              className={classes.topButton}
              onClick={() => history.push(`/lessons/${id}/create`)}
            >
              <AddCircle style={{ marginRight: '5px' }} />

              {t('button:Lesson.btn_create_lesson')}
            </Button>
          </div>
        ) : null}

        <ModuleList is_owner={is_owner} />
      </div>
    </div>
  );
};
