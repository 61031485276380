import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},

  // buttontop10: {
  //   width: '10%',
  //   height: '50px',
  //   border: '0',
  //   cursor: 'pointer',
  //   background:'#4169E1',
  //   borderradius: '20px',
  //   color: 'white',
  //   display: 'grid',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   marginRight: '20px',

  // },
  // buttonsa: { 
  //   textDecoration: 'none',    
  // },
  // buttons:{
  //   display:'flex',
  // },
  // buttonhover:{
  //     background: '#6495ED',
  // },

  divtop10: {
    textAlign: 'center',
    display: 'inline-block',
    alignItems: 'center',
    width: '110px',
    height: '90px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    transition: 'background-color 0.5s',
    backgroundColor: 'transparent',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#c6c6c6',
    },
  },
  h6trunc: {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    color: 'gray', 
  },
  rowStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },

  greenIcon: {
    backgroundColor: '#2DCE98 !important',
  },

  blackIcon: {
    backgroundColor: '#4D4F5C !important',
  },

  blueIcon: {
    backgroundColor: '#176AE6 !important',
  },

  redIcon: {
    backgroundColor: '#F53C56 !important',
  },

  yellowIcon: {
    backgroundColor: '#f0c24e !important',
  },

  quickAccessContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },

  topButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'space-around',
      width: '100%',
      marginTop: theme.spacing(1)
    },
  },

  button: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
      width: '100%',
    },
  },

  overrideWrap: {
    flexWrap: 'unset',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  buttontop10: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentCenter: {
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}));
