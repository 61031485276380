import React, { useState } from 'react';
import { useStyles } from './styles';
import { Paper, InputLabel, Switch, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { CourseData } from 'services/dataService';
import { useHistory, useParams } from 'react-router-dom';
import ButtonsAction from 'components/ButtonsAction';

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const [completion, setCompletion] = useState(false);
  const [approval, setApproval] = useState(false);
  const [loading, setLoading] = useState(true);
  const [courseData, setCourseData] = useState({});
  const { id } = useParams();

  const { register, handleSubmit, errors, setValue, control } = useForm();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const handleCompletionChange = () => {
    setCompletion((prev) => !prev);
  };

  const handleApprovalChange = () => {
    setApproval((prev) => !prev);
  };

  const onSubmit = async (data) => {
    const formValue = {
      require_classes_completion: data.require_classes_completion,
      require_classes_approval: data.require_classes_approval,

      ...courseData,
    };

    (async () => {
      try {
        await CourseData.updateCourse(id, formValue);
        toast.success(t('toastify:commons.toast_update'));
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_update_error'));
        }
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await CourseData.getCourses({ id_course: id });
        const companyCourse = response.data.courses[0];

        setCompletion(companyCourse.require_classes_completion);
        setApproval(companyCourse.require_classes_approval);
        setValue([
          {
            require_classes_completion:
              companyCourse.require_classes_completion,
            require_classes_approval: companyCourse.require_classes_approval,
          },
        ]);

        setCourseData({
          name: companyCourse.course.name,
          keywords: companyCourse.course.keywords,
          content: companyCourse.course.content,
          description: companyCourse.course.description,
          id_category: companyCourse.id_category,
          id_subcategory: companyCourse.id_subcategory,
          score: companyCourse.course.score,
          workload: companyCourse.course.workload,
          signable: companyCourse.signable,
          id_file_banner: companyCourse.id_file_banner,
          id_file_card: companyCourse.id_file_card,
        });
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    })();
    setLoading(false);
  }, []);

  if (!loading)
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel id="completion">
                {t('form:Courses.Setting.require_classes_completion')}
              </InputLabel>
              <Switch
                id="completion"
                checked={completion}
                onChange={handleCompletionChange}
                color="primary"
                inputRef={register}
                name="require_classes_completion"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="completion">
                {t('form:Courses.Setting.require_classes_approval')}
              </InputLabel>
              <Switch
                id="approval"
                checked={approval}
                onChange={handleApprovalChange}
                color="primary"
                inputRef={register}
                name="require_classes_approval"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonsAction
                isUpdate
                cancelBtn={{
                  handleCancelClick: () =>
                    history.push(`/courses/${id}/lessons`),
                  text: t('button:FeaturedCourses.cancel'),
                }}
                saveBtn={{
                  text: t('button:FeaturedCourses.update'),
                  color: 'secondary',
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  return <></>;
};
