import {
  OndemandVideo,
  LibraryBooks,
  AssignmentOutlined,
} from '@material-ui/icons';

import React from 'react';

const LessonIcon = ({ content }) => {
  return (
    <>
      {content?.url_vimeo ? (
        <OndemandVideo fontSize="small" />
      ) : content?.id_file ? (
        <LibraryBooks fontSize="small" />
      ) : content?.content ? (
        <AssignmentOutlined fontSize="small" />
      ) : (
        <></>
      )}
    </>
  );
};

export default LessonIcon;
