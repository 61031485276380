import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AttendanceCreate as AttendanceCreateView, ChangePassword as ChangePasswordView, LearnContent as LearnContentView, LearnIndex as LearnIndexView, NotFound as NotFoundView, NotificationList as NotificationListView, RedefinePassword as RedefinePasswordView,
  RequestAccess as RequestAccessView,
  RequestPassword as RequestPasswordView,
  Signin as SigninView,
  Signup as SignupView,
  StudentContentIndex as StudentContentIndexView,
  UserEditProfile as UserEditProfileView,
  LibraryStudIndex as LibraryStudIndexView
} from 'views';
import AvailableTrails from 'views/AvailableTrails';
import AvalibleContent from 'views/AvalibleContent';
import PublicCertificateView from 'views/Certificate/PublicCertificateView';
import StudentCertificates from 'views/Certificate/StudentCertificates';
import CourseBuyView from 'views/CourseBuyView';
import CourseRegistration from 'views/CourseRegistration';
import { HelpView } from 'views/help';
import KnowledgeTrailRegistration from 'views/KnowledgeTrailRegistration';
import { DiagnosisView } from 'views/MyServices/Diagnosis';
import { MentoringView } from 'views/MyServices/Mentoring';
import { MyBenefits } from 'views/MyServices/MyBenefits';
import { MyBookmarksView } from 'views/MyServices/MyBookmarks';
import { ShoppingView } from 'views/Shopping';
import TeamsView from 'views/Teams';
import WelcomeView from 'views/Welcome';
import { RouteWithLayout } from '../../components';
import {
  Learn as LearnLayout, Main as MainLayout,
  Minimal as MinimalLayout, PublicView
} from '../../layouts';


export default function UserRouter() {
  const { auth, company } = useSelector((state) => state);
  const { shouldChangePassword } = auth;

  if (shouldChangePassword) {
    return (
      <Switch>
        <Route path="/change-password">
          <ChangePasswordView />
        </Route>
        <Route path="*">
          <Redirect to="/change-password" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <RouteWithLayout
        component={SigninView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={WelcomeView}
        exact
        layout={PublicView}
        path="/"
      />
      {company?.only_invited_users ? (
        <RouteWithLayout
          component={SignupView}
          exact
          layout={MinimalLayout}
          path="/register"
        />
      ) : (
        <RouteWithLayout
          component={SignupView}
          exact
          layout={MinimalLayout}
          path="/register"
        />
      )}

      <RouteWithLayout
        component={RequestAccessView}
        exact
        layout={MinimalLayout}
        path="/solicitation-access"
      />
      <RouteWithLayout
        component={RequestPasswordView}
        exact
        layout={MinimalLayout}
        path="/solicitation-password"
      />
      <RouteWithLayout
        component={RedefinePasswordView}
        exact
        layout={MinimalLayout}
        path="/password/:token"
      />
      <RouteWithLayout
        component={UserEditProfileView}
        exact
        isPrivate
        layout={MainLayout}
        path="/users/edit-profile"
      />
      <RouteWithLayout
        component={StudentContentIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={AttendanceCreateView}
        exact
        isPrivate
        layout={MainLayout}
        path="/attendance/create"
      />
      <RouteWithLayout
        component={NotificationListView}
        exact
        isPrivate
        layout={MainLayout}
        path="/notification"
      />
      <RouteWithLayout
        component={AvalibleContent}
        exact
        isPrivate
        layout={MainLayout}
        path="/available-content"
      />
      {/* <RouteWithLayout
        component={AvailableTrails}
        exact
        isPrivate
        layout={MainLayout}
        path="/available-trails"
      /> */}
      <RouteWithLayout
        component={AvailableTrails}
        exact
        isPrivate
        layout={MainLayout}
        path="/available-trails"
      />
      <RouteWithLayout
        component={MyBookmarksView}
        exact
        isPrivate
        layout={MainLayout}
        path="/my-services/my-bookmarks"
      />
      <RouteWithLayout
        component={MyBenefits}
        exact
        isPrivate
        layout={MainLayout}
        path="/my-services/mentoring"
      />
      <RouteWithLayout
        component={MentoringView}
        exact
        isPrivate
        layout={MainLayout}
        path="/my-services/my-benefits"
      />
      <RouteWithLayout
        component={DiagnosisView}
        exact
        isPrivate
        layout={MainLayout}
        path="/my-services/diagnosis"
      />
      <RouteWithLayout
        component={ShoppingView}
        exact
        isPrivate
        layout={MainLayout}
        path="/shopping"
      />
      {/* Route with Login */}
      {auth?.signed && (
        <RouteWithLayout
          component={CourseRegistration}
          exact
          isPrivate
          layout={MainLayout}
          path="/course/:id/enroll"
        />
      )}

      {auth?.signed && (
        <RouteWithLayout
          component={KnowledgeTrailRegistration}
          exact
          isPrivate
          layout={MainLayout}
          path="/trail/:id/(enroll|learn)"
        />
      )}

      {auth?.signed && (
        <RouteWithLayout
          component={PublicCertificateView}
          exact
          isPrivate
          layout={MainLayout}
          path="/certificates/:id_course/:id_user/public"
        />
      )}

      {/* Route without Login */}
      <RouteWithLayout
        component={CourseRegistration}
        exact
        layout={PublicView}
        path="/course/:id/enroll"
      />

      <RouteWithLayout
        component={StudentCertificates}
        exact
        isPrivate
        layout={MainLayout}
        path="/certificates"
      />
      
      <RouteWithLayout
        component={PublicCertificateView}
        exact
        layout={MainLayout}
        path="/certificates/:id_course/:id_user/public"
      />

      <RouteWithLayout
        component={CourseBuyView}
        exact
        isPrivate
        layout={MainLayout}
        path="/course/:id/buy"
      />
      <RouteWithLayout
        component={LearnIndexView}
        exact
        isPrivate
        layout={LearnLayout}
        path="/course/:id/learn"
      />
      <RouteWithLayout
        component={LibraryStudIndexView}
        exact
        isPrivate
        layout={MainLayout}
        path="/librarystud"
      />
      <RouteWithLayout
        component={LearnContentView}
        exact
        isPrivate
        layout={LearnLayout}
        path="/course/:id/learn/:learnId"
      />
      {/* Not Found View */}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={HelpView}
        exact
        isPrivate
        layout={MainLayout}
        path="/help"
      />
      <RouteWithLayout
        component={TeamsView}
        exact
        isPrivate
        layout={MainLayout}
        path="/teams"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
}
