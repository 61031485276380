import React, { useState, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import { localizationTable, options } from 'constants/table';

import Tooltip from "@material-ui/core/Tooltip";


import {
  CardContent,
  Grid,
  Button,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Paper
} from '@material-ui/core';

import { useStyles } from './styles';

import { ReportCompanyData } from 'services/dataService';
import { statusCompany } from 'constants/status';
import { toast } from 'react-toastify';
import { Search, VerticalAlignBottom } from '@material-ui/icons';
import MenuDownloadReport from 'components/MenuDownloadReport';
import { statusUser } from 'constants/status';

export default ({ fields }) => {
  const [status, setStatus] = useState(1);
  const [trail, setTrail] = useState();
  const [name, setName] = useState('');
  const [disable, setDisable] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  const [anchorEl, setAnchorEl] = useState(null);


  const handleChange = (value) => {
    setStatus(value);
    handleFetch(value);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);


  const columnsField = fields.map(field => ({
    ...field,
    sorting: false
  }));


  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const onSubmit = (data) => {
    setName(data.name);
    setTrail(data.trail)
    setStatus(data.status);

    setDisableButton(true);


    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const handleFetch = async (status) => {
    try {
      const response = await ReportCompanyData.getTrailsReport({
        name,
        status,
        trail,
        isExport: true,
      });
      return response.data;
    } catch (err) {
      toast.error(
        'Não foi possível buscar os dados do relatório, tente novamente mais tarde.'
      );
      return [];
    }
  };

  const handleFetchToXlsx = async (status, col) => {
    const response = await ReportCompanyData.getTrailToXlsx({
      name,
      status,
      trail,
      isExport: true,
      columns: JSON.stringify(col),
    });

    return [];
  };

  const existeRelatorioParaBaixar = async () => {

    const res = localStorage.getItem("RelatorioTrilhaDownload");

    if (res == "1") {
      setDisable(true);
    }
  };


  const customExport = useCallback(async (type) => {
    try {
      setDisable(true);

      localStorage.setItem("RelatorioTrilhaDownload", "1");

      toast.info(t('toastify:commons.toast_generatereport'));
      const filteredColumns = tableRef.current.props.columns.map(column => {
        const { tableData, ...rest } = column;
        return rest;
      });
      const responseData = handleFetchToXlsx(status, filteredColumns);

      setShowConfirmation(true);

    } finally {
      handleClose();
    }
  }, [status]);

  return (
    <>
      <div className='notranslate'>
        <MaterialTable
          title={`${t('tables:reports.title_reports_trails')}`}
          tableRef={tableRef}
          columns={
            columnsField ||
            [
              {
                title: `${t('tables:reports.trails.name_column')}`,
                field: `name_column`,
                render: (rowData) => rowData.name_column
              },
              {
                title: `${t('tables:reports.trails.cpf')}`,
                field: `cpf`,
                render: (rowData) => rowData.cpf
              },
              {
                title: `${t('tables:reports.trails.email_column')}`,
                field: `email_column`,
                render: (rowData) => rowData.email_column
              },
              {
                title: `${t('tables:reports.trails.trail')}`,
                field: `trail`,
                render: (rowData) => rowData.trail
              },
              {
                title: `${t('tables:reports.trails.team')}`,
                field: `team`,
                render: (rowData) => rowData.team
              },
              {
                title: `${t('tables:reports.trails.progress')}`,
                field: 'progress',
                render: (rowData) => rowData.progress
              },
              {
                title: `${t('tables:reports.trails.finished')}`,
                field: 'finished',
                render: (rowData) => rowData.finished
              },
              {
                title: `${t('tables:reports.trails.quantity_courses')}`,
                field: 'quantity_courses',
                render: (rowData) => rowData.quantity_courses
              },
              {
                title: `${t('tables:reports.trails.workload')}`,
                field: 'workload',
                render: (rowData) => rowData.workload
              },
              {
                title: `${t('tables:reports.trails.created_at')}`,
                field: 'created_at',
                render: (rowData) => rowData.created_at
              },
              {
                title: `${t('tables:reports.trails.last_finished_date')}`,
                field: 'last_finished_date',
                render: (rowData) => rowData.last_finished_date
              },
              {
                title: `${t('tables:reports.trails.status')}`,
                field: 'status',
                render: (rowData) => rowData.status
              }
            ]
          }
          data={async (query) => {
            try {
              const response = await ReportCompanyData.getTrailsReport({
                page: query.page + 1,
                limit: query.pageSize,
                name,
                status,
                trail
              });

              existeRelatorioParaBaixar();


              let { report, currentPage, totalCount } = response.data;

              report = report?.map((trail) => {
                let customFields = {};

                if (trail?.custom_field_values) {
                  const formattedCustomFieldValue = JSON.parse(
                    trail?.custom_field_values
                  );

                  for (const field of formattedCustomFieldValue) {
                    customFields[field?.fieldName] = field?.fieldValue;
                  }
                }

                return { ...trail, ...customFields };
              });

              return {
                data: report,
                page: currentPage - 1,
                totalCount: totalCount,
              };

            } catch (err) {
              toast.error(t('toastify:commons.toast_error_api'));
              return { data: [] };
            }
          }}
          components={{
            search: true,
            title: true,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label={t('form:User.input_first_name')}
                          name="name"
                          type="text"
                          variant="outlined"
                          defaultValue={name || ''}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          fullWidth
                          label={t('form:Trail.input_name_trail')}
                          name="trail"
                          type="text"
                          variant="outlined"
                          defaultValue={trail || ''}
                          inputRef={register}
                        />
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <FormControl style={{ minWidth: 130 }}>
                          <InputLabel id="status">
                            {t("form:commons.input_status")}
                          </InputLabel>
                          <Controller
                            as={
                              <Select>
                                <MenuItem value={1}>{t(statusUser[1]?.title)}</MenuItem>
                                <MenuItem value={2}>{t(statusUser[4]?.title)}</MenuItem>
                              </Select>
                            }
                            name="status"
                            control={control}
                            onChange={([event]) => handleChange(event.target.value)}
                            defaultValue={status}
                            value={status}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={2} xs={12} className={classes.actions}>
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="submit"
                        >
                          <Search />
                          {t('button:User_solictation.btn_advanced_filter')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={localizationTable(t)}
          actions={[
            {
              icon: () => (
                disableButton && (
                  <>
                    <Tooltip title={"Somente após o download de um relatório solicitado, é possível solicitar novamente."}>
                      <span>
                        <Button disabled={disable}>
                          <VerticalAlignBottom
                            handleFetch={() => handleFetch(status)}
                            onClick={() => customExport("excel")}
                            tableRef={tableRef}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )
              ),
              isFreeAction: true,
            },
          ]}
          options={{
            ...options,
            exportButton: false,
          }}
        />
      </div>

    </>
  );
};
