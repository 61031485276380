import { Box, Button, Grid, Typography } from '@material-ui/core';
import { StarOutlineRounded, StarRounded, Tune } from '@material-ui/icons';
import { toast } from 'react-toastify';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useStyles } from './styles';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import { RatingData } from 'services/dataService';

const ModalRating = ({ setOpen, company, user, course, flag, handleSubmitCallback }) => {

    const { t } = useTranslation(['title', 'button', 'views']);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [value, setValue] = React.useState(5);
    const [hover, setHover] = React.useState(-1);
    const [activeBtn, setBtnActive] = useState({
        relevant_content: false,
        experienced_instructor: false,
        courseware: false,
        learning_environment: false,
        current_theme: false
    });

    const classes = useStyles();

    const text = useRef(null);


    const labels = {
        1: 'Ruim',
        2: 'Abaixo do esperado',
        3: 'Razoável',
        4: 'Bom',
        5: 'Excelente',
    };


    function getLabelText(value) {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (btn) => {
        setBtnActive({ ...activeBtn, [btn]: !activeBtn[btn] });
    };

    const handleSubmit = async () => {
        if ((value === 1 || value === 2) && text.current.innerText.trim() === '') {
            toast.error(t('toastify:commons.toast_required_comment'));
            setIsSubmitting(false);
            return; 
        }
        try {
            setIsSubmitting(true);
            await RatingData.addRating({
                company: company,
                user: user,
                course: course.id_company_course,
                rating_course: value,
                activeBtn: activeBtn,
                comment: text.current.innerText.trim()
            });
            toast.success(t('toastify:commons.toast_success_rating'));
            handleClose();
            if(!flag) {
                window.location.reload();
            }


        } catch (error) {
            if (value === null) {
                toast.error(t('toastify:commons.toast_no_rating'));
            } else {
                toast.error(t('toastify:commons.toast_error', error));
            }
        } finally {
            setIsSubmitting(false);
            if (handleSubmitCallback) {
                handleSubmitCallback(true);
              }
        }
    }


    return (
        <div className=''>
            <Dialog className='divModalcentralize-content'
                open={setOpen}
                onClose={handleClose}
                BackdropClick={false}
                disableEscapeKeyDown={true} 
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { borderRadius: 15 }
                }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <DialogTitle>
                    <span
                        style={{
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            fontSize: '14px',
                        }}
                    >
                        Deixe a sua opinião sobre o conteúdo:
                    </span>
                    <p
                        style={{
                            fontFamily: 'Montserrat',
                            fontWeight: '500',
                            fontSize: '16px',
                        }}
                    >
                        {course.course.name}
                    </p>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className={classes.replyText}>
                            <Typography>
                                {value !== null && (
                                    <Box>{labels[hover !== -1 ? hover : value]}</Box>
                                )}
                                <Box sx={{ position: 'relative' }}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -30,
                                            left: 0,
                                            opacity: value !== null ? 1 : 0,
                                            transition: 'opacity 0.3s ease-in-out',
                                        }}
                                    >
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Rating
                                            name="hover-feedback"
                                            icon={<StarRounded className={classes.starFull} />}
                                            value={value}
                                            precision={1}
                                            getLabelText={getLabelText}
                                            onChange={(event, newValue) => {
                                                setValue(newValue === null ? value : newValue);
                                            }}
                                            onChangeActive={(event, newHover) => {
                                                setHover(newHover);
                                            }}
                                            emptyIcon={<StarOutlineRounded className={classes.starEmpty} />}
                                        />
                                    </Box>
                                </Box>
                            </Typography>
                        </div>
                    </DialogContentText>
                    <DialogContentText>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: "25px",
                                fontSize: "15px",
                                marginBottom: "10px",
                            }}
                        >
                            Quais motivos te levaram atribuir essa nota?
                        </Typography>
                    </DialogContentText>
                    <div className={classes.container}>
                        <Button
                            className={classes.buttonStyle}
                            color="primary"
                            disabled={false}
                            size="small"
                            variant={activeBtn.relevant_content ? 'contained' : 'outlined'}
                            onClick={() => handleClick('relevant_content')}
                        >
                            Conteúdo relevante
                        </Button>
                        <Button
                            className={classes.buttonStyle}
                            color="primary"
                            disabled={false}
                            size="small"
                            variant={activeBtn.experienced_instructor ? 'contained' : 'outlined'}
                            onClick={() => handleClick('experienced_instructor')}
                        >
                            Instrutor experiente
                        </Button>
                        <Button
                            className={classes.buttonStyle}
                            color="primary"
                            disabled={false}
                            variant={activeBtn.courseware ? 'contained' : 'outlined'}
                            onClick={() => handleClick('courseware')}
                        >
                            Material didático
                        </Button>
                    </div>
                    <div className={classes.container}>
                        <Button
                            className={classes.buttonStyle}
                            color="primary"
                            disabled={false}
                            size="small"
                            variant={activeBtn.learning_environment ? 'contained' : 'outlined'}
                            onClick={() => handleClick('learning_environment')}
                        >
                            Ambiente de aprendizagem
                        </Button>
                        <Button
                            className={classes.buttonStyle}
                            color="primary"
                            disabled={false}
                            size="small"
                            variant={activeBtn.current_theme ? 'contained' : 'outlined'}
                            onClick={() => handleClick('current_theme')}
                        >
                            Tema atual
                        </Button>
                    </div>
                    <DialogContentText>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: "25px",
                                marginBottom: "10px",
                                fontSize: "15px"
                            }}
                        >
                            Deseja acrescentar algo mais?
                        </Typography>
                        <div
                            ref={text}
                            className={clsx([classes.content, classes.shadow])}
                            contentEditable={true}
                        ></div>
                    </DialogContentText>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        size="large"
                        style={{
                            marginTop: "15px",
                            width: "33%"
                        }}
                        disabled={isSubmitting} // Desabilitar o botão enquanto estiver enviando

                    >
                        {t('button:commons.btn_send')}
                    </Button>
                </DialogContent>
            </Dialog >
        </div >
    );
};

export default ModalRating;