import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import XLSX from 'xlsx';

const path = require('path');

const exportToCsv = (columns, data, fileName) => {
  const workSheet = formatWorkSheet(columns, data)
  /* write workbook (use type 'binary') the "\ufeff" use UTF-8 to csv file */
  const csv = '\ufeff' + XLSX.utils.sheet_to_csv(workSheet);

  FileSaver.saveAs(
    new Blob([csv], { type: 'application/octet-stream' }),
    `${fileName}.csv`
  );
};

const exportToXlsx = (columns, data, fileName) => {
  const workSheet = formatWorkSheet(columns, data);

  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, fileName)
  //buffer
  XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
  // binary string
  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
  // download
  XLSX.writeFile(workBook, `${fileName}.xlsx`)

}

const exportToXlsxDisk = (columns, data, fileName) => {
  const workSheet = formatWorkSheet(columns, data);

  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, fileName)
  //buffer
  XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })
  // binary string
  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })
  // download
  const filePath = path.join(__dirname, 'assets', `${fileName}.xlsx`)
  XLSX.writeFile(workBook,  filePath);

}

const exportToPdf = (columns, data, fileName) => {
  const visibleCol = columns.filter((col) => !col.hidden);
  const colHeader = visibleCol.map((col) => col.title);
  const colFields = visibleCol.map((col) => col.field);

  const tableData = data.map((pdfRowData) => {
    const customFields = {};
    if (pdfRowData.custom_field_values) {
      JSON.parse(pdfRowData.custom_field_values).forEach((custField) => {
        customFields[custField.fieldName] = custField.fieldValue;
      });
    }

    const rowFormated = { ...pdfRowData, ...customFields };
    const rowData = [];
    for (const field of colFields) {
      rowData.push(rowFormated[field]);
    }

    return rowData;
  });

  // generate pdf
  const doc = new jsPDF({ orientation: 'landscape' });
  autoTable(doc, {
    head: [colHeader],
    body: tableData,
  });

  // pagination on pdf footer
  const pages = doc.internal.getNumberOfPages();
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(10);
  for (let j = 1; j < pages + 1; j++) {
    let verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`Pg. ${j} / ${pages}`, 10, verticalPos);
  }

  doc.save(`${fileName}.pdf`);
};

const formatWorkSheet = (columns, data) => {
  const col2Delete = columns
    .filter((col) => col.hidden)
    .map((col) => col.field);

  const colTitle = {};
  columns
    .filter((col) => !col.hidden)
    .forEach((col) => (colTitle[col.field] = col.title));

  const tableData = data.map((csvRowData) => {
    const customFields = {};

    if (csvRowData.custom_field_values) {
      JSON.parse(csvRowData.custom_field_values).forEach((custField) => {
        customFields[custField.fieldName] = custField.fieldValue;
      });
    }

    const rowFormated = { ...csvRowData, ...customFields };
    delete rowFormated.custom_field_values;

    for (let col of col2Delete) {
      delete rowFormated[col];
    }

    return rowFormated;
  });

  // it convert json to object that will be possible convert to csv.
  const ws = XLSX.utils.json_to_sheet(tableData);

  // loop to modify the column name
  const range = XLSX.utils.decode_range(ws['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const address = XLSX.utils.encode_col(C) + '1';
    if (!ws[address]) continue;

    ws[address].v = colTitle[ws[address].v] || ws[address].v; // update or no the name column
  }

  return ws;
}

export { exportToCsv, exportToXlsx, exportToXlsxDisk, exportToPdf };
