import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { CompanyList, CompanyToolbar } from 'views/Company/components';
import { CompanyData } from 'services/dataService';

export default () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const { t } = useTranslation(['toastify']);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CompanyData.getDashboard();

        setData(response.data);
        setLoading(false);
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }  
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      {!loading && (
        <>
          <CompanyToolbar data={data.summary} />
          <div className={classes.content}>
            <CompanyList />
          </div>
        </>
      )}
    </div>
  );
};
