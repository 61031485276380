export const dashboardCompanyType = {
    only_current_company: 1,
    only_sub_companies: 2,
    current_and_sub_companies: 3
}

export const periodFilter = {
    all: 1,
    monthly: 2,
    weekly: 3,
    daily: 4
}

export const ProfileType = {
  ADMIN: 1,
  STUDENT: 2,
  TEACHER: 3
}
