import { makeStyles } from '@material-ui/styles';
import index from 'views/Setting/Email/index';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '-30px',
    padding: '0',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',

    },
  },
  audio: {
    width: '100%',
    margin: 'auto',
    display: 'block',
  },
  title: {
    color: '#000000',
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 10,
  },
  subtitle: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  viewer: {
    outline: 'none',
    border: 'none',
    borderRadius: 10,
    boxShadow: '0px 9px 22px #d3d3d3',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
    '& button': {
      width: 114,
      height: 31,
      backgroundColor: 'transparent',
      color: '#000000',
      fontSize: 14,
      fontWeight: 600,
      border: 'none',
      outline: 'none',
      borderRadius: 4,
      border: '1px solid #2DCE98',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
      marginLeft: 40,
      marginRight: 40,
    },
    '@media (max-width: 768px)': {
      '& button': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  btnActionPDF: {
    display: 'flex',
    justifyContent: 'center',
    padding: 3,
    '& button': {
      width: 150,
      margin: '0 5px',
      height: 31,
      backgroundColor: 'transparent',
      color: '#000000',
      fontSize: 14,
      border: 'none',
      outline: 'none',
      borderRadius: 4,
      border: '1px solid #ECECEC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
      flex: '1',
    },
    '@media(max-width: 600px)': {
      '& button': {
        width: '100%',
        fontSize: 10,
        padding: '5px 10px',
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  '@media (max-width: 1200px)': {
    btnActionPDF: {
      flexDirection: 'row',
      '& button': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '5px 0',
        width: '100%',
      },
    },
  },
  previsousBtnPdf: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '4%',
    maxHeight: '10%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      maxWidth: '10%',
      marginTop: '1rem',
    },
  },
  nextBtnPdf: {
    display: 'flex',
    justifyContent: 'flex-start',
    maxWidth: '4%',
    maxHeight: '10%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      maxWidth: '10%',
      marginBottom: '1rem',
    },
  },
  buttonsPdfMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: '14px'
  },
  buttonsActive: {
    backgroundColor: '#2DCE98 !important',
    color: '#fff !important',
  },
  downloadContainer: {
    width: 'auto',
    padding: '20px',
    '& button': {
      border: '1px solid #2DCE98',
      textTransform: 'none',
      '& span': {
        marginLeft: '10px',
      },
    },
  },
  '@media print': {
    pdfContainer: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  buttonsPdfFile: {
    marginTop: '10px',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
    '& > button': {
      padding: '6px',
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      '& > button': {
        marginBottom: theme.spacing(1),
      },
      '& > button:nth-child(1)': {
        marginRight: theme.spacing(1),
      },
    },
  },
  pdfFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& > div': {
      width: '100%',
      height: '100%',
    },
    '&.fullscreen': {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'unset',
      overflowX: 'auto',
      flexDirection: 'column', // Adicionado para mobile
    },
  },
  buttonsContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: 20,
  },
  fullscreenButton: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  centeredButtons: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  pdfContainer: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    fontSize: '10px',
    overflow: 'hidden',
    '& span': {
      display: 'none',
    },
    '& > div > div:last-child': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      '& > div > canvas': {
        width: '100% !important',
        height: '100% !important',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        width: '100%',
        height: 'auto',
        maxHeight: 'calc(100vh - 50px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& > div > canvas': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
  },
}));