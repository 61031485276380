import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { CameraAltOutlined, HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilesData } from 'services/dataService';
import CustomHelper from '../CustomHelperText';

const CompanyCustomizationHeader = ({ form, header_data }) => {
  const { register, setValue, watch } = form;
  const values = watch('company_header');
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const { t } = useTranslation(['views', 'button']);
  const fileInput = React.useRef();
  const classes = useStyles();

  const saveFile = async (file) => {
    if (!file) {
      return;
    }
    const { data } = await FilesData.addFile(
      file,
      'company_customization_header'
    );
    setFileUrl(data?.url);
    setFile(data?.id);
  };

  const initFile = () => {
    setFileUrl(header_data?.logo?.url);
    setFile(header_data?.id_file_logo);
  };

  React.useEffect(() => {
    initFile();
  }, []);

  return (
    <Box>
      <Box>
        <Typography className={classes.header} color="textSecondary">
          {t('views:CompanyCustomization.header')}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box className={classes.item}>
            <img
              width={260}
              height={80}
              style={{ maxWidth: '100%' }}
              src={fileUrl}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.item}>
            <input
              ref={fileInput}
              style={{ display: 'none' }}
              type="file"
              id="contained-logo-file"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                saveFile(event.target.files[0]);
              }}
            />
            <TextField
              name="header_logo_url"
              value={fileUrl}
              inputRef={register}
              style={{ display: 'none' }}
            />
            <TextField
              name="company_header.id_file_logo"
              value={file}
              inputRef={register}
              style={{ display: 'none' }}
            />
            <Button
              variant="contained"
              onClick={() => {
                fileInput.current.click();
              }}
            >
              <CameraAltOutlined fontSize="small" />
              {t('button:CompanyCustomization.change_logo')}
            </Button>
            <CustomHelper
              text={t('views:CompanyCustomization.change_logo_description')}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.item}>
            <CustomColorPicker
              name="company_header.background_color"
              defaultValue={header_data?.background_color}
              register={register}
              title={t('views:CompanyCustomization.background_color')}
            />
            <CustomHelper
              text={t(
                'views:CompanyCustomization.background_color_description'
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.item}>
            <CustomColorPicker
              name="company_header.text_color"
              defaultValue={header_data?.text_color}
              register={register}
              title={t('views:CompanyCustomization.text_color')}
            />
            <CustomHelper
              text={t('views:CompanyCustomization.text_color_description')}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(2),
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  item: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingTop: theme.spacing(2),
    },
  },
}));

export default CompanyCustomizationHeader;
