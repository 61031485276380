import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end',
  },
  card: {
    border: '1px dashed red',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  },
  trailImage: {
    objectFit: 'contain',
    width: '100%',
  }
}));
