import { Box, Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import theme from 'theme';
import BannerCustomization from './components/banner';
import CompanyEvents from './components/events';
import FeaturedContent from './components/FeaturedContent';
import CompanyFooterCustomization from './components/Footer';
import CompanyCustomizationHeader from './components/header';
import PlatformCustomization from './components/Platform';
import CustomSeparator from './components/Separator';
import CompanySolutions from './components/Solutions';

const CooperativeCompanyCustomization = ({ form, customizationData }) => {
  const classes = useStyles();
  const { t } = useTranslation(['button']);

  return (
    <Box className={classes.root}>
      <CompanyCustomizationHeader
        header_data={customizationData?.company_header}
        form={form}
      />
      <CustomSeparator />
      <BannerCustomization
        banner_data={customizationData?.company_banner}
        form={form}
      />
      <CustomSeparator />
      <FeaturedContent
        featured_content={customizationData?.featured_content}
        form={form}
      />
      <CustomSeparator />
      <PlatformCustomization
        company_platform={customizationData?.company_platform}
        form={form}
      />
      <CustomSeparator />
      <CompanyEvents
        company_events={customizationData?.company_events}
        form={form}
      />
      <CustomSeparator />
      <CompanySolutions
        company_solutions={customizationData?.company_solutions}
        form={form}
      />
      <CustomSeparator />
      <CompanyFooterCustomization
        company_footer={customizationData?.company_footer}
        form={form}
      />
      <CustomSeparator />

      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={5} md={3}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            {t('button:CompanyCustomization.cancel')}
          </Button>
        </Grid>
        <Grid item xs={2} md={6}></Grid>
        <Grid item xs={5} md={3}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: theme?.palette?.success?.main }}
            type="submit"
          >
            <Add fontSize="small" />
            {t('button:CompanyCustomization.update')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));
export default CooperativeCompanyCustomization;
