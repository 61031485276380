import React from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { AddCircle, ViewList, ImportExport } from '@material-ui/icons';
import useWindowDimensions from 'customHooks/DimensionHook';


export default ({ className, ...rest }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button']);
  const history = useHistory();
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1} direction="row">
        <Grid item md={5} xs={12}>
          <MuiTypography variant="h4">
            {t('label:commons.label_access_quick')}:
          </MuiTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sm={12}
          style={{
            display: width < 750 ? 'block' : 'flex',
            justifyContent: 'flex-end',
          }}
        >
          
          <Link to="/classroomcourses/create">
            <Button
              color="primary"
              fullWidth={width < 750}
              variant="contained"
              style={{ marginTop: width < 750 ? 10 : 'unset' }}
            >
              <AddCircle style={{ marginRight: '5px' }} />
              {t('button:Course.btn_create_course')}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
