import React, { useState } from 'react';

import GroupGroupsTable from '../Table';

import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GroupGroupsTable />
    </div>
  );
};
