import React, { useState, useEffect, useRef } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Grid, Avatar, Paper, Tooltip, IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutlined';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import ptBR from 'date-fns/locale/pt-BR';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommentsData } from 'services/dataService';
import { HandleDelete } from 'components/';
import { useSelector } from 'react-redux';

const ADMIN = 1;

export default () => {
  const { learnId } = useParams();
  const { id: userId, type: userType } = useSelector((state) => state.auth);
  const { t } = useTranslation(['title', 'button', 'views']);
  const classes = useStyles();
  const text = useRef(null);
  const textReply = useRef(null);
  const [showCommentFieldArray, setShowCommentFieldArray] = useState([]);
  const [showCloseCommentFieldArray, setShowCloseCommentFieldArray] = useState(
    []
  );
  const [openedReplyCommentsArray, setOpenedReplyCommentsArray] = useState([]);
  const [commentsData, setCommentsData] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [route, setRoute] = useState('');

  const loadComments = async () => {
    if (learnId) {
      try {
        const response = await CommentsData.getCommentsAndRepliesByLessonId(
          learnId
        );

        setCommentsData(response.data);
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    }
  };

  useEffect(() => {
    loadComments();
  }, [learnId]);

  const handleOpenReplyComments = (commentId) => {
    if (openedReplyCommentsArray.includes(commentId)) {
      const removedCommentIds = openedReplyCommentsArray.filter(
        (comment) => comment !== commentId
      );

      setOpenedReplyCommentsArray([...removedCommentIds]);
    } else {
      setOpenedReplyCommentsArray((oldArray) => [...oldArray, commentId]);
    }
  };

  const handleOpenReplyCommentField = (commentId) => {
    setShowCommentFieldArray((oldArray) => [...oldArray, commentId]);
    setShowCloseCommentFieldArray((oldArray) => [...oldArray, commentId]);
  };

  const handleCloseComment = (commentId) => {
    const removedShowCommentFieldArray = showCommentFieldArray.filter(
      (comment) => comment !== commentId
    );

    const removedshowCloseCommentFieldArray = showCloseCommentFieldArray.filter(
      (comment) => comment !== commentId
    );

    setShowCommentFieldArray([...removedShowCommentFieldArray]);
    setShowCloseCommentFieldArray([...removedshowCloseCommentFieldArray]);
  };

  const handleComment = async () => {
    if (text.current.innerText.trim()) {
      try {
        await CommentsData.addComment({
          content: text.current.innerText,
          id_lesson: learnId,
        });

        loadComments();
        text.current.innerText = '';
        toast.success(t('toastify:commons.toast_success'));
      } catch (error) {
        toast.error(t('toastify:commons.toast_error'));
      }
    }
  };

  const handleReplyComment = async (commentId) => {
    if (textReply.current.innerText.trim()) {
      try {
        await CommentsData.addReplyComment({
          content: textReply.current.innerText,
          id_lesson: learnId,
          id_parent_comment: commentId,
        });

        handleCloseComment(commentId);
        loadComments();
        text.current.innerText = '';
        toast.success(t('toastify:commons.toast_success'));
      } catch (error) {
        toast.error(t('toastify:commons.toast_error'));
      }
    }
  };

  const canDeleteComment = (commentUserId) => {
    return (
      (userType && userType === ADMIN) || (userId && userId === commentUserId)
    );
  };

  return (
    <div className={classes.root}>
      <h1 className={classes.title}> {t('title:Lessons.comments')}</h1>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          ref={text}
          className={clsx([classes.content, classes.shadow])}
          contentEditable={true}
        ></div>
        <div className={classes.wrapper}>
          <span className={classes.comment}>
            <InfoOutlinedIcon className={classes.icon} />
            {t('views:Comment.comment_info')}
          </span>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleComment}
          >
            {t('button:commons.btn_send')}
          </Button>
        </div>
      </div>

      {commentsData?.comments?.map((comment) => (
        <div key={comment.commentId}>
          <Paper className={classes.commentsWrapper}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Avatar
                  className={classes.commentsAvatar}
                  alt={comment.userName}
                  src={comment.userAvatar}
                />
              </Grid>
              <Grid justifyContent="left" item xs zeroMinWidth>
                <Grid justifyContent="space-between" container item>
                  <h4 className={classes.commentsNamePerson}>
                    {comment.userName}
                  </h4>
                  <p className={classes.commentsDate}>
                    {formatDistanceToNow(new Date(comment.createdAt), {
                      locale: ptBR,
                      addSuffix: true,
                    })}
                  </p>
                </Grid>
                <p className={classes.commentsText}>{comment.content}</p>
                <Grid justifyContent="space-between" container item>
                  <Grid className={classes.replyText} item>
                    <p
                      className={classes.commentsReply}
                      onClick={() =>
                        handleOpenReplyCommentField(comment.commentId)
                      }
                    >
                      {t('views:Comment.comment_reply')}
                    </p>
                    {showCloseCommentFieldArray.includes(comment.commentId) && (
                      <CloseOutlinedIcon
                        className={classes.closeCommentIcon}
                        onClick={() => handleCloseComment(comment.commentId)}
                      />
                    )}
                  </Grid>
                  <Grid className={classes.replyCountAndDelete}>
                    {comment.replyCount > 0 && (
                      <Grid
                        item
                        className={classes.commentsIconWrapper}
                        onClick={() => handleOpenReplyComments(comment.commentId)}
                      >
                        <Tooltip
                          title={t('views:Comment.comment_tooltip_replies')}
                        >
                          <ForumOutlinedIcon className={classes.commentsIcon} />
                        </Tooltip>
                        <span className={classes.commentsNumber}>
                          {comment.replyCount}
                        </span>
                      </Grid>
                    )}
                    {canDeleteComment(comment.userId) && (
                      <Grid className={classes.deleteCommentWrapper} item>
                        <Tooltip title={t('views:Comment.comment_tooltip_delete')}>
                          <IconButton>
                            <DeleteOutlineIcon
                              className={classes.closeCommentIcon}
                              onClick={() => {
                                setRoute(`/comments/${comment.commentId}`);
                                setDeleteModalOpen(true);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {showCommentFieldArray.includes(comment.commentId) && (
                  <Grid item className={classes.replyTextField}>
                    <div
                      ref={textReply}
                      className={clsx([classes.content, classes.shadow])}
                      contentEditable={true}
                    ></div>
                    <Button
                      className={classes.replyButton}
                      variant="contained"
                      color="primary"
                      onClick={() => handleReplyComment(comment.commentId)}
                    >
                      {t('button:commons.btn_send')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>

          {openedReplyCommentsArray.includes(comment.commentId) &&
            comment.replies.length > 0 &&
            comment.replies.map((reply) => (
              <Paper
                key={reply.commentId}
                className={classes.replyCommentsWrapper}
              >
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar
                      className={classes.commentsAvatar}
                      alt="Remy Sharp"
                      src={reply.userAvatar}
                    />
                  </Grid>
                  <Grid justifyContent="left" item xs zeroMinWidth>
                    <Grid justifyContent="space-between" container item>
                      <h4 className={classes.commentsNamePerson}>
                        {reply.userName}
                      </h4>
                      <p className={classes.commentsDate}>
                        {formatDistanceToNow(new Date(reply.createdAt), {
                          locale: ptBR,
                          addSuffix: true,
                        })}
                      </p>
                    </Grid>
                    <p className={classes.commentsText}>{reply.content}</p>
                    <Grid>
                      {canDeleteComment(reply.userId) && (
                        <Grid className={classes.deleteCommentWrapper} item>
                          <IconButton>
                            <DeleteOutlineIcon
                              className={classes.closeCommentIcon}
                              onClick={() => {
                                setRoute(`/comments/${reply.commentId}`);
                                setDeleteModalOpen(true);
                              }}
                            />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </div>
      ))}

      <HandleDelete
        closeModal={setDeleteModalOpen}
        isOpen={deleteModalOpen}
        route={route}
        functionDelete={loadComments}
      />
    </div>
  );
};
