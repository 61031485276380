import {
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  Paper,
} from '@material-ui/core';
import {
  CallToAction,
  CardMembership,
  NotInterested,
  Save,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CertificateData, FilesData, CourseData } from 'services/dataService';
import {
  CertificateButton,
  CompanyCertificatePreview,
  CourseCertificateForm,
} from '..';
const Certificate = {
  DEFAULT: 1,
  CUSTOM: 2,
  NO_CERTIFICATE: 3,
};

export default ({ className, ...rest }) => {
  const classes = useStyles();

  const { id } = useParams();

  const [active, setActive] = React.useState(0);

  const { t } = useTranslation(['toastify', 'label']);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await CourseData.getCompanyCourseCertificateType(id);
        setActive(response.data.type);
      } catch (error) {
        setActive(Certificate.NO_CERTIFICATE);
      }
    })();
  }, []);

  const setCourseCertificate = async (type) => {
    try {
      setActive(type);
      await CourseData.setcompanyCertificateType(id, type);
    } catch (e) {
      if (e.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {active === 0 ? (
          <Grid
            container
            xs={12}
            justify={'center'}
            alignItems={'center'}
            className={clsx(classes.marginTop, classes.bottomPadding)}
          >
            <CircularProgress size={48} color="secondary" />
          </Grid>
        ) : (
          <Grid
            container
            xs={12}
            spacing={2}
            className={classes.courseCertificateType}
          >
            <Grid item md={4} xs={12}>
              <CertificateButton
                title={t('label:Certificate.default_title')}
                text={t('label:Certificate.default_text')}
                icon={
                  <CallToAction
                    color={active === 1 ? 'secondary' : 'inherit'}
                  />
                }
                active={active === 1}
                onClick={() => setCourseCertificate(Certificate.DEFAULT)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CertificateButton
                title={t('label:Certificate.customized_title')}
                text={t('label:Certificate.customized_text')}
                icon={
                  <CardMembership
                    fontWeight="bold"
                    color={active === 2 ? 'secondary' : 'inherit'}
                  />
                }
                active={active === 2}
                onClick={() => setCourseCertificate(Certificate.CUSTOM)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CertificateButton
                title={t('label:Certificate.no_certificate_title')}
                text={t('label:Certificate.no_certificate_text')}
                icon={
                  <NotInterested
                    color={active === 3 ? 'secondary' : 'inherit'}
                  />
                }
                active={active === 3}
                onClick={() => setCourseCertificate(Certificate.NO_CERTIFICATE)}
              />
            </Grid>
          </Grid>
        )}
        {active === 1 ? (
          <CompanyCertificatePreview />
        ) : active === 2 ? (
          <CourseCertificateForm />
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },

  bottomPadding: { paddingBottom: theme.spacing(2) },
}));
