import api from '../api';

class AdminDashboardData {
  async getFilters() {
    return api.get(`/admin_dashboard/filters`);
  }
  async getIndicators({ people, courses, teams, months, years }) {
    return api.post(`/admin_dashboard/indicators`, {
      people,
      courses,
      teams,
      months,
      years,
    });
  }
  async getCourses({ people, courses, teams, months, years }) {
    return api.post(`/admin_dashboard/courses`, {
      people,
      courses,
      teams,
      months,
      years,
    });
  }
  async getPeople({ people, courses, teams, months, years }) {
    return api.post(`/admin_dashboard/people`, {
      people,
      courses,
      teams,
      months,
      years,
    });
  }
  async getTeams({ people, courses, teams, months, years }) {
    return api.post(`/admin_dashboard/teams`, {
      people,
      courses,
      teams,
      months,
      years,
    });
  }
  async getConclusions({ people, courses, teams, months, years }) {
    return api.post(`/admin_dashboard/conclusions`, {
      people,
      courses,
      teams,
      months,
      years,
    });
  }
  async getList({ people, courses, teams, months, years, name, status, page, pageSize, limit, isExport }) {
    return api.post(`/admin_dashboard/list`, {
      people,
      courses,
      teams,
      months,
      years,
      status,
      page,
      pageSize,
      limit,
      isExport,
      name
    });
  }
}

export default new AdminDashboardData();
