import * as React from 'react';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import EmailTemplate from 'services/dataService/EmailTemplate';
import { toast } from 'react-toastify';
import { localizationTable } from 'constants/table';
import EmailDataTableToolbar from '../Toolbar';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default () => {
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
    'tables',
  ]);
  const history = useHistory();
  const tableRef = React.useRef();
  const [filters, setFilters] = React.useState({
    date_begin: null,
    date_end: null,
    name: '',
    status: '1',
  });
  const { register, handleSubmit } = useForm();

  const submit = ({ name }) => {
    setFilters({ ...filters, name: name });
    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };
  return (
    <React.Fragment>
      <div className="notranslate">
      <MaterialTable
        tableRef={tableRef}
        title={t('tables:commons.email_column')}
        columns={[
          {
            title: `${t('tables:Email.name')}`,
            field: `name`,
          },
          {
            title: `${t('tables:Email.title')}`,
            field: 'title',
          },
          // {
          //   title: `${t('tables:Email.customized')}`,
          //   field: 'customized',
          // },
          {
            title: `${t('tables:Email.active')}`,
            field: 'status',
          },
        ]}
        actions={[
          {
            icon: 'visibility',
            tooltip: `${t('tables:commons.actions.label_action')}`,
            onClick: (event, rowData) => {
              history.push(`/settings/email/${rowData.id}/edit`);
            },
            iconProps: { color: 'primary' },
          },
          // {
          //   icon: 'restore',
          //   tooltip: `${t('tables:commons.actions.restore')}`,
          //   onClick: (event, rowData) => {
          //     console.log('no action');
          //   },
          //   iconProps: { color: 'primary' },
          // },
        ]}
        data={async (query) => {
          try {
            const response = await EmailTemplate.getQuerySearch({
              page: query.page + 1,
              limit: query.pageSize,
              filters,
            });

            return {
              data: response.data.emailTemplates.map((email) => {
                // console.log(email.status);
                return {
                  ...email,
                  customized: email.customized
                    ? t('label:commons.label_yes')
                    : t('label:commons.label_no'),
                  status: email.active
                    ? t('label:commons.label_yes')
                    : t('label:commons.label_no'),
                };
              }),
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            toast.error(t('toastify:commons.toast_error_api'));
            return { data: [] };
          }
        }}
        localization={localizationTable}
        options={{
          search: false,
          actionsColumnIndex: -1,
          pageSize: 5,
          emptyRowsWhenPaging: false,
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <form onSubmit={handleSubmit(submit)}>
              <EmailDataTableToolbar
                {...props}
                filter={filters}
                setFilter={setFilters}
                register={register}
              />
            </form>
          ),
        }}
      />
      </div>
    </React.Fragment>
  );
};
