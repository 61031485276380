import querystring from 'querystring';
import api from '../api';

class DataServiceModules {
  addModule(data) {
    return api.post('modules', data);
  }

  getModules(params) {
    const url = 'modules?' + querystring.stringify(params);
    return api.get(url);
  }

  getModuleById(id) {
    return api.get(`modules/${id}`);
  }

  getModuleByIdCourse(id, search = null) {
    return api.get(`modules/${id}/lessons${search ? `?title=${search}` : ''}`);
  }

  getModuleByIdCourseAndUser(id, id_user, search = null) {
    return api.get(
      `modules/${id}/lessons?id_user=${id_user}${
        search ? `&title=${search}` : ''
      }`
    );
  }

  updateModule(id, data, id_course) {
    const query = { id_course };
    return api.put(`modules/${id}?${querystring.stringify(query)}`, data);
  }

  deleteModule(id) {
    return api.delete(`/modules/${id}`);
  }
}

export default new DataServiceModules();
