import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { Visibility, VisibilityOff } from '@material-ui/icons/';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  Box,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { UserData, GroupsData, CustomFieldsData } from 'services/dataService';
import { statusUser } from 'constants/status';
import { PhoneMaskCustom } from 'components/PhoneMaskCustom';
import { CpfMaskCustom } from 'components/CpfMaskCustom';

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export default ({ className, ...rest }) => {
  const [groups, setGroups] = useState([]);
  const [userGroup, setUserGroup] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState([]);


  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  const { id_company } = useSelector((state) => state.auth);
  const { id } = useParams();
  const history = useHistory();
  const cpf = useRef({});
  cpf.current = watch('cpf');  

  const { t } = useTranslation([
    'title',
    'form',
    'label',
    'button',
    'validation',
    'toastify',
  ]);
  const classes = useStyles();

  const onSubmit = useCallback(
    async (data) => {
      data.email = data.email.toLowerCase()
      data.id_company = id_company;
      try {
        data.array_custom_field_values = data?.array_custom_field_values
          ?.map((fields) => {
            const fieldName = Object.keys(fields)[0];
            return {
              fieldName: fieldName,
              fieldValue: fields[fieldName],
            };
          })
          ?.filter((field) => field !== null);
        if (id) {
          await UserData.updateUser(id, data);
        } else {
          await UserData.addUser(data);
        }

        toast.success(t('toastify:commons.toast_success'));
        history.push('/users');
      } catch (error) {
        if (error.response.data.code == "SOLICITATION_ALREADY_EXISTS")
          toast.error(t('toastify:user.toast_solicitation_already_exists_admin'))
        else if (error.response.data.code == "USER_ALREADY_EXISTS")
          toast.error(t('toastify:user.toast_user_already_exists'))
        else if (error.response?.status === 403)
          toast.error(t('toastify:commons.toast_error_403'))
        else
          toast.error(t('toastify:commons.toast_error'));
      }
    },
    [t]
  );

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };

  const fetchFields = async () => {
    const { data: customFields } = await CustomFieldsData.getCustomFields();
    setCustomFields(JSON.parse(customFields?.custom_fields || "[{}]"));
  };

  React.useEffect(() => {
    fetchFields();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GroupsData.getGroups();

      setGroups(response.data.groups.filter((a) => a?.type !== 3));
    };

    fetchData();

    if (id) {
      const fetchDataUser = async () => {
        const response = await UserData.getUsersById(id);

        const { name, email, id_group, status } = response.data;
        setUserStatus(status);
        setUserGroup(id_group);

        setValue([{ name }, { email }, { id_group: id_group }, { status }]);
      };

      fetchDataUser();
    }

    setLoading(false);
  }, [id]);

  const titlePage = id
    ? t('title:User.title_edit')
    : t('title:User.title_create');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent className={classes.cardContent}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box style={{ padding: '8px' }}>
                  <TextField
                    fullWidth
                    label={t('form:User.input_first_name')}
                    helperText={
                      errors.name?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:User.input_first_name'),
                      })
                    }
                    name="name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.name}
                  />
                </Box>

                <Box style={{ padding: '8px' }}>
                  <TextField
                    fullWidth
                    label={t('form:User.input_email')}
                    helperText={
                      (errors.email?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:User.input_email'),
                        })) ||
                      (errors.email?.type === 'pattern' &&
                        t('validation:commons.validation_email', {
                          field: t('form:User.input_email'),
                        })) ||
                      (errors.email?.type === 'validate' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Signin.email_cpf'),
                        }))                                                                    
                    }                
                    name="email"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({
                      required: false,
                      pattern: {
                        value:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 
                      },
                      validate: (value) =>                        
                        !!cpf.current || !!value
                    })}
                    error={!!errors.email}                    
                  />
                </Box>

                <Box style={{ padding: '8px' }}>
                  <Grid container spacing={3} >
                    <Grid item md={12} xs={12} className={clsx(classes.select)}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_cpf')}
                        helperText={
                          (errors.cpf?.type === 'pattern' &&
                            t('validation:commons.validation_cpf', {
                              field: t('form:User.input_cpf'),
                            })) ||
                          (errors.cpf?.type === 'validate' &&
                            t('validation:commons.validation_required', {
                              field: t('form:Signin.email_cpf'),
                            }))                                                                    
                        }                          
                        name="cpf"
                        type="text"                        
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ 
                          required: false,
                          pattern: {
                            value:  /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/i, 
                          },                                                   
                        })}
                        error={!!errors.cpf}
                        InputProps={{
                          inputComponent: CpfMaskCustom,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box style={{ padding: '8px' }}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={6} className={clsx(classes.select)}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="status">
                          {t('form:commons.input_status')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={1}>{t(statusUser[1]?.title)}</MenuItem>
                              <MenuItem value={2}>{t(statusUser[2]?.title)}</MenuItem>
                              <MenuItem value={3}>{t(statusUser[3]?.title)}</MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={{ userStatus }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel id="id_group">
                          {t('form:User.input_group')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              {groups &&
                                groups.map((group) => (
                                  <MenuItem key={group.id} value={group.id}>
                                    {group.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                          name="id_group"
                          rules={{
                            required: t(
                              'validation:commons.validation_select_required'
                            ),
                          }}
                          control={control}
                          defaultValue={{ userGroup }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

              </Grid>

              <Grid item md={6} xs={12} className={clsx(classes.select)}>
                <Box style={{ padding: '8px' }}>

                  <TextField
                    fullWidth
                    label={t('form:User.input_cellphone')}
                    name="cellphone"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: false })}
                    error={!!errors.cellphone}
                    InputProps={{
                      inputComponent: PhoneMaskCustom,
                    }}
                  />
                </Box>

                <Box style={{ padding: '8px' }}>
                  <TextField
                    fullWidth
                    label={t('form:User.input_telephone')}
                    name="phone"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: PhoneMaskCustom,
                    }}
                    inputRef={register({ required: false })}
                    error={!!errors.phone}
                  />
                </Box>

                <Box style={{ padding: '8px' }}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={!!errors.password}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      {t('form:User.input_password_for_user')}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={visible ? 'text' : 'password'}
                      name="password"
                      inputRef={register({ required: true, minLength: 6 })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visible ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={t('form:User.input_password').length * 8}
                    />
                    <FormHelperText>
                      {(errors.password?.type === 'required' &&
                        t('validation:commons.validation_required', {})) ||
                        (errors.password?.type === 'minLength' &&
                          t('validation:commons.validation_min_pass', {
                            minLength: 6,
                          }))}
                    </FormHelperText>
                  </FormControl>
                </Box>

              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {customFields?.map((field, index) => (
                <>
                  {field?.visibleToCreate && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name={`array_custom_field_values[${index}].${field?.fieldName}`}
                        variant="outlined"
                        label={field?.fieldName || ''}
                        error={
                          !!!errors?.array_custom_field_values
                            ? false
                            : errors?.array_custom_field_values[index]
                              ? errors?.array_custom_field_values[index][
                              field?.fieldName
                              ]
                              : false
                        }
                        inputRef={register({
                          required: field?.required || false,
                        })}
                      />
                      <FormHelperText
                        error={
                          !!!errors?.array_custom_field_values
                            ? false
                            : errors?.array_custom_field_values[index]
                              ? errors?.array_custom_field_values[index][
                              field?.fieldName
                              ]
                              : false
                        }
                      >
                        {!errors?.array_custom_field_values
                          ? ''
                          : errors?.array_custom_field_values[index]
                            ? errors?.array_custom_field_values[index][
                              field?.fieldName
                            ]?.type === 'required' &&
                            t('validation:commons.validation_required', {})
                            : ''}
                      </FormHelperText>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              size="large"
              variant="outlined"
              onClick={() => history.goBack()}
              className={classes.btnBack}
            >
              {t('button:commons.btn_back')}
            </Button>
            <Box className={classes.boxBtns}>
              <Button color="primary" size="large" type="reset" onClick={reset} className={classes.btnReset}>
                {t('button:commons.btn_clear')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </Box>
          </CardActions>
        </form>
      )}
    </Card>
  );
};
