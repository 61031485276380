import { Box, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const SearchTextfield = ({ onEnterPress, onChange, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <TextField
        {...props}
        fullWidth
        id="standard-name"
        className={classes.textField}
        onKeyPress={(e) => {
          if (e.key.includes('Enter') && onEnterPress) {
            onEnterPress(e.target.value);
          }
        }}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        margin="normal"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          classes: {
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputMode: 'numeric',
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    borderRadius: 6,
    boxShadow: '0px 5px 30px 0px #2A2E4329',
    height: '65px',
    alignItems: 'center',
  },
  textField: {
    margin: 0,
    width: '100%',
    borderWidth: 0,
    borderStyle: 'none',
    padding: '0px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 5px',
    }
  },

  cssLabel: {
    borderWidth: 0,
    borderStyle: 'none',
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderWidth: 0,
    },
    borderWidth: 0,
    borderStyle: 'none',
    width: '100%',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: 0,
  },
}));

export default SearchTextfield;
