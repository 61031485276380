import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  InputLabel,
  Switch
} from '@material-ui/core';

import { Delete, Save, AddCircle } from '@material-ui/icons';

import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { TrailData, TrailCoursesData, CourseData, EnrollmentData, FilesData } from 'services/dataService';
import { dragginColor } from '../../../../constants/drag';

export default ({ buttonClicked, setButtonClicked, className, isModalInsert, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(null);
  const [course, setCourse] = useState('');
  const [courses, setCourses] = useState('');
  const [imgTrail, setImgTrail] = useState('');
  const [imgTrailURL, setImgTrailURL] = useState('');
  const [sequential_course, setSequentiaCourse] = useState(true);
  const [visible, setVisible] = useState(false);
  const [signable, setSignable] = useState(false);

  const { id } = useParams();

  const { register, handleSubmit, errors, setValue, control } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 8px 0`,

    // change background colour if dragging
    background: isDragging ? dragginColor.color : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await TrailData.getTrailById(id);
          if (response.data.id_file) {
            setImgTrailURL(response.data.file.url);
          }

          const { name, description, completion_days, price, id_file, sequential_course, visible, signable } = response.data;

          setValue([
            { name },
            { description },
            { completion_days },
            {
              price: Intl.NumberFormat('pt-br', {
                minimumFractionDigits: 2,
              }).format(price),
            },
            { id_file },
            { sequential_course },
            { visible }, { signable }
          ]);

          setSequentiaCourse(sequential_course);
          setVisible(visible);
          setSignable(signable);
          const courseResponse = await EnrollmentData.getCompanyCourseCombo();

          const coursesFormat = courseResponse.data.courses.map((course) => {
            return { id: course?.id, name: course?.course?.name };
          });

          const trailCourseFormat = response?.data?.trail_courses?.map(
            (trail_course) => {
              return {
                id: trail_course?.id,
                name: trail_course?.company_course?.course?.name,
              };
            }
          );

          setCourses(coursesFormat);
          setItems(trailCourseFormat);
        } catch (err) {
          console.error('!ERROR ===> ', err);
          history.push('/trails');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      }

      fetchData();
    }
    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
    try {
      if (id) {
        let priceFormated = data?.price?.replace(',', '.');
        if (priceFormated === '') priceFormated = "0.00"
        data.price = priceFormated;


        if (imgTrail) {
          const responseImgFile = await FilesData.addFile(
            imgTrail,
            `Trail/TrailId=${id}/id_file`
          );
          data.id_file = responseImgFile.data.id;
        }

        await TrailData.updateTrail(id, data);
        toast.success(t('toastify:commons.toast_update'));
      } else {
        let priceFormated = data?.price?.replace(',', '.');
        if (priceFormated === '') priceFormated = "0.00"
        data.price = priceFormated;
        const responseTrail = await TrailData.addTrail(data);
        if (imgTrail) {
          const respImgFile = await FilesData.addFile(
            imgTrail,
            `Trail/TrailId=${responseTrail.data.id}/id_file`
          );
          const requestTrail = {
            id: responseTrail.data.id,
            id_file: respImgFile.data.id
          }
          await TrailData.updateTrail(responseTrail.data.id, requestTrail);
        }
        toast.success(t('toastify:commons.toast_success'));
      }

      history.push('/trails');
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        const toastMsg = id ? 'toast_update_error' : 'toast_error';
        toast.error(t(`toastify:commons.${toastMsg}`));
      }
    }
  };

  const handleSequentialCourseChange = () => {
    setSequentiaCourse((prev) => !prev);
  };

  const handleVisibleChange = () => {
    setVisible((prev) => !prev);
  };

  const handleSignableChange = () => {
    setSignable((prev) => !prev);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  const handleCourse = async () => {
    try {
      const data = new Set(items);
      const idx = courses.indexOf(course);
  
      const response = await TrailCoursesData.addTrailCourses({
        id_trail: id,
        id_company_course: courses[idx].id,
      });
  
      data.add({ id: response.data.id, name: courses[idx].name });
      setItems(Array.from(data));
      setCourse('');
    } catch (err) {
      console.error('!ERRO ===> ', err);
      if ((err.response?.data.error === 'Trail x Course exist.')) {
        toast.error(t('toastify:commons.toast_error_api_add_course'));
        setCourse('');
      }
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      }
    } 
  };
  

  const handleCourseOrder = async () => {
    try {
      await TrailCoursesData.orderTrailCourses(id, {
        items: items
      });
      toast.success(t('toastify:commons.toast_update'));
    } catch (err) {
      console.error('!ERROR ===> ', err);
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const handleRemove = async (id) => {
    try {
      await TrailCoursesData.delete(id);
      setItems(items.filter((item) => item.id != id));
    } catch (err) {
      console.error('!ERROR ===> ', err);
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const titlePage = id
    ? t('title:Trail.title_edit')
    : t('title:Trail.title_create');

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={titlePage} />
        <Divider />
        {!loading && (
          <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Trail.input_name')}
                    helperText={
                      errors.name?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Trail.input_name'),
                      })
                    }
                    name="name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.name}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Trail.input_description')}
                    name="description"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: false })}
                    error={!!errors.description}
                  />
                </Grid>
              </Grid>

              <Grid>
                <Grid item md={6} xs={12} className={classes.columnGrid} style={{ display: 'none' }}>
                  <InputMask
                    pattern="^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$"
                    maskChar=""
                  >
                    {(inputProps) => (
                      <TextField
                        fullWidth
                        {...inputProps}
                        label={t('Preço')}
                        name="price"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ required: false })}
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                  <Box >
                    {imgTrail ? (
                      <img
                        src={URL.createObjectURL(imgTrail)}
                        className={classes.trailImage}
                      />
                    ) : !imgTrail && imgTrailURL ? (
                      <img className={classes.trailImage} src={imgTrailURL} />
                    ) : (
                      <></>
                    )}
                  </Box>


                  <Grid item md={8} xs={12}>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      id="contained-img-file"
                      name="file_img"
                      accept="image/png, image/jpeg"
                      onChange={(event) => {
                        setImgTrail(event.target.files[0]);
                      }}
                    />
                    <label htmlFor="contained-img-file">
                      <Button variant="contained" color="primary" component="span">
                        {t('form:Trail.Setting.img_trail')}
                      </Button>
                    </label>
                  </Grid>

                </Grid>


                <Grid item md={4} xs={12}>
                  <Grid container spacing={3}>

                    <Grid item md={12} xs={12} className={classes.columnGrid} >
                      <InputLabel id="sequential_course">
                        {t('form:Trail.input_sequential_course')}
                      </InputLabel>
                      <Switch
                        id="sequential_course"
                        checked={sequential_course}
                        readOnly="true"
                        onChange={handleSequentialCourseChange}
                        color="primary"
                        inputRef={register}
                        name="sequential_course"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>

                    {/* <Grid item md={12} xs={12} className={classes.columnGrid} >
                      <InputLabel id="visible">
                        {t('form:Trail.input_visible')}
                      </InputLabel>
                      <Switch
                        id="visible"
                        checked={visible}
                        readOnly="true"
                        onChange={handleVisibleChange}
                        color="primary"
                        inputRef={register}
                        name="visible"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid> */}

                    <Grid item md={12} xs={12} className={classes.columnGrid} >
                      <InputLabel id="signable">
                        {t('form:Trail.input_signable')}
                      </InputLabel>
                      <Switch
                        id="signable"
                        checked={signable}
                        readOnly="true"
                        onChange={handleSignableChange}
                        color="primary"
                        inputRef={register}
                        name="signable"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
      {id && (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title="Cursos" />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <Autocomplete
                  id="comboBoxCourses"
                  options={courses}
                  getOptionLabel={(option) => option.name}
                  value={course}
                  onChange={(event, newValue) => {
                    setCourse(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('form:Trail.input_name')}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  size="large"
                  onClick={handleCourse}
                >
                  {t('button:Trail.btn_courses')}
                  <AddCircle />
                </Button>
              </CardActions>
            </Grid>
          </CardContent>
          <Divider />

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div className={classes.demo}>
                    <List>
                      {items &&
                        items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <ListItem>
                                  <ListItemText primary={item.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete">
                                      <Delete
                                        color="error"
                                        onClick={() => handleRemove(item.id)}
                                      />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </List>
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              type="button"
              size="large"
              onClick={handleCourseOrder}
            >
              {t('button:Trail.btn_saveorder')}
              <Save />
            </Button>
          </CardActions>

        </Card>
      )}
    </>
  );
};
