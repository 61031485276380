import {
  AttachMoneyOutlined,
  BookmarksOutlined,
  CallSplit,
  DashboardOutlined,
  EmailOutlined,
  FavoriteBorder,
  Gavel,
  Group,
  HomeOutlined,
  LibraryBooksOutlined,
  PeopleOutline,
  PersonOutline,
  SchoolOutlined,
  SettingsOutlined,
  Star,
  CastForEducation,
  NewReleasesOutlined,
  BookOutlined
} from '@material-ui/icons';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import CastForEducationOutlinedIcon from '@material-ui/icons/CastForEducationOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Translate = ({ text }) => {
  const { t } = useTranslation(['menu']);
  return <React.Fragment><span className='notranslate'>{t(text)}</span></React.Fragment>;
};

export default [
  {
    title: <Translate text={'menu:sidebar.admin.dashboard_menu.dashboard'} />,
    href: '#',
    icon: <HomeOutlined />,
    subitems: [
      {
        title: (
          <Translate text={'menu:sidebar.admin.dashboard_menu.news'} />
        ),
        href: '/dashboard',
        icon: < NewReleasesOutlined />,
      },
      {
        title: (
          <Translate text={'menu:sidebar.admin.dashboard_menu.subplatform'} />
        ),
        href: '/companies',
        icon: <DashboardOutlined />,
      },
    ],
  },
  {
    title: <Translate text={'menu:sidebar.admin.users_menu.users'} />,
    href: '#',
    icon: <PeopleOutline />,
    subitems: [
      {
        title: (
          <Translate text={'menu:sidebar.admin.users_menu.users_manager'} />
        ),
        href: '/users',
        icon: <PersonOutline />,
      },
      // {
      //   title: <Translate text={'menu:sidebar.admin.users_menu.users_group'} />,
      //   href: '/groups',
      //   icon: <PeopleAltOutlinedIcon />,
      // },
      {
        title: <Translate text={'menu:sidebar.admin.users_menu.requests'} />,
        href: '/user-solicitations',
        icon: <GroupAddOutlinedIcon />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.users_menu.teams'} />,
        href: '/teams',
        icon: <Group />,
      },
    ],
  },
  {
    title: <Translate text={'menu:sidebar.admin.courses_menu.education'} />,
    href: '#',
    icon: <SchoolOutlined />,
    subitems: [
      {
        title: <Translate text={'menu:sidebar.admin.courses_menu.courses'} />,
        href: '/courses/manage',
        icon: <CastForEducationOutlinedIcon />,
      },
      {
        title: (
          <Translate text={'menu:sidebar.admin.courses_menu.categories'} />
        ),
        href: '/categories',
        icon: <CategoryOutlinedIcon />,
      },
      {
        title: (
          <Translate text={'menu:sidebar.admin.courses_menu.subcategories'} />
        ),
        href: '/subcategories',
        icon: <BookmarksOutlined />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.courses_menu.trails'} />,
        href: '/trails',
        icon: <CallSplit />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.courses_menu.library'} />,
        href: '/library',
        icon: <LocalOfferOutlinedIcon />,
     },
     {
      title: <Translate text={'menu:sidebar.admin.courses_menu.classroom'} />,
      href: '/classroomcourses/manage',
      icon: <BookOutlined />,
     },
      // {
      //   title: <Translate text={'menu:sidebar.admin.courses_menu.tags'} />,
      //   href: '/tags',
      //   icon: <LocalOfferOutlinedIcon />,
      // },
    ],
  },
  {
    title: <Translate text={'menu:sidebar.admin.reports_menu.reports'} />,
    href: '#',
    icon: <LibraryBooksOutlined />,
    subitems: [
      {
        title: <Translate text={'menu:sidebar.admin.reports_menu.dashboard'} />,
        href: '/admin-dashboard',
        icon: <DashboardOutlined />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.reports_menu.academic'} />,
        href: '/reports/academic',
        icon: <SchoolOutlined />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.reports_menu.users'} />,
        href: '/reports/companies-users',
        icon: <PersonOutline />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.reports_menu.trails'} />,
        href: '/reports/companies-trails',
        icon: <CallSplit />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.reports_menu.attendance'} />,
        href: '/reports/companies-attendance',
        icon: <BookOutlined />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.settings_menu.lgpd'} />,
        href: '/reports/lgpd',
        icon: <Gavel />,
      },
    ],
  },

  // {
  //   title: <Translate text={'menu:sidebar.admin.custom_menu'} />,
  //   href: '/settings/company-customization',
  //   icon: <FavoriteBorder />,
  // },
  {
    title: <Translate text={'menu:sidebar.admin.settings_menu.settings'} />,
    href: '#',
    icon: <SettingsOutlined />,
    subitems: [
      {
        title: <Translate text={'menu:sidebar.admin.settings_menu.general'} />,
        href: '/settings/general',
        icon: <BuildOutlinedIcon />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.settings_menu.email'} />,
        href: '/settings/email',
        icon: <EmailOutlined />,
      },
      {
        title: (
          <Translate text={'menu:sidebar.admin.settings_menu.certificate'} />
        ),
        href: '/settings/certificate',
        icon: <VerifiedUserOutlinedIcon />,
      },
      {
        title: (
          <Translate text={'menu:sidebar.admin.settings_menu.edit_fields'} />
        ),
        href: '/settings/custom-fields',
        icon: <CreateOutlinedIcon />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.settings_menu.featured'} />,
        href: '/settings/featured-courses',
        icon: <Star />,
      },
      {
        title: <Translate text={'menu:sidebar.admin.settings_menu.lgpd'} />,
        href: '/settings/lgpd',
        icon: <Gavel />,
      },
    ],
  },
  {
    title: 'Produção de conteúdos',
    href: '/production',
    icon: <CastForEducation />,
  },
];
