import api from '../api';

class AnnotationsData {
  get( learnId, id_user )
  {
    return api.get(`/lesson-annotations/${learnId}/${id_user}`);
  }

  create( learnId, id_user, {description, color} )
  {
    return api.post(`/lesson-annotations/${learnId}/${id_user}`, {description, color});
  }

  delete( id_annotation )
  {
    return api.delete(`/lesson-annotations/${id_annotation}`);
  }
}

export default new AnnotationsData();
