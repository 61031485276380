import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  url_vimeo: null,
  titleUpload: '',
  descriptionUpload: '',
  id_file: null,
  html_content: null,
  embed_content: null,
};

export default function vimeo(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@content/VIMEO_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@content/UPLOAD_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@content/VIMEO_SUCCESS': {
        draft.url_vimeo = action.payload.url;
        draft.titleUpload = action.payload.title;
        draft.descriptionUpload = action.payload.description;
        draft.id_file = null;
        draft.html_content = null;
        draft.embed_content = null;
        draft.loading = false;
        break;
      }
      case '@content/HTML_SUCCESS': {
        draft.url_vimeo = null;
        draft.titleUpload = action.payload.title;
        draft.descriptionUpload = action.payload.description;
        draft.id_file = null;
        draft.html_content = action.payload.content;
        draft.embed_content = null;
        draft.loading = false;
        break;
      }
      case '@content/EMBED_SUCCESS': {
        draft.url_vimeo = null;
        draft.titleUpload = action.payload.title;
        draft.descriptionUpload = action.payload.description;
        draft.id_file = null;
        draft.html_content = null;
        draft.embed_content = action.payload.content;
        draft.loading = false;
        break;
      }
      case '@content/UPLOAD_FILE': {
        draft.url_vimeo = null;
        draft.titleUpload = action.payload.title;
        draft.descriptionUpload = action.payload.description;
        draft.id_file = action.payload.id;
        draft.html_content = null;
        draft.embed_content = null;
        draft.loading = false;
        break;
      }
      case '@content/UPLOAD_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@content/CLEAR': {
        draft.url_vimeo = null;
        draft.titleUpload = '';
        draft.descriptionUpload = '';
        draft.id_file = null;
        break;
      }
      case '@content/VIMEO_CLEAR_URL': {
        draft.url_vimeo = undefined;
        break;
      }
      default:
    }
  });
}
