import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@material-ui/icons/Brightness1';
import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from './styles';
import { GroupsData } from 'services/dataService';
import { statusGroups } from 'constants/status';
import { HandleDelete } from 'components/';
import { toast } from 'react-toastify';
import { localizationTable, options } from 'constants/table';
import { Search } from '@material-ui/icons';

export default () => {
  const [status, setStatus] = useState(0);
  const [name, setName] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [route, setRoute] = useState('');

  const { register, handleSubmit, control } = useForm();

  const { id_company } = useSelector((state) => state.auth);

  const tableRef = useRef();
  const history = useHistory();

  const { t } = useTranslation([
    'toastify',
    'tables',
    'label',
    'form',
    'button',
  ]);
  const classes = useStyles();

  const handleModalOpen = useCallback(({ id }) => {
    setModalStatus(true);
    setRoute(`groups/${id}`);
  });

  const handleEdit = useCallback(({ id }) => {
    history.push(`groups/${id}/edit`);
  }, []);

  const onSubmit = (data) => {
    setName(data.name);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
    <div className='notranslate'>
    <MaterialTable
        title={`${t('tables:group.title')}`}
        tableRef={tableRef}
        columns={[
          { title: `${t('tables:commons.name_column')}`, field: 'name' },
          {
            title: `${t('tables:commons.type_column')}`,
            field: 'type',
            render: (rowData) => (
              <div style={{ display: 'flex' }}>
                <CircleIcon
                  style={statusGroups[rowData.type]?.style}
                  fontSize="small"
                />
                <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                  {t(statusGroups[rowData.type]?.title)}
                </span>
              </div>
            ),
          },
        ]}
        data={async (query) => {
          try {
            const response = await GroupsData.getGroups({
              page: query.page + 1,
              limit: query.pageSize,
              status: status === 0 ? undefined : status,
              name,
            });

            console.log('response', response.data)
            return {
              data: response.data.groups.filter((a) => a?.type !== 3),
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.edit_action')}`,
            onClick: (event, rowData) => {
              handleEdit(rowData);
            },
            iconProps: { color: 'primary' },
            hidden: false,
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
            hidden: false,
          },
        ]}
        components={{
          search: false,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={5} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:Group.input_name')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <FormControl style={{ minWidth: 100 }}>
                        <InputLabel id="status">
                          {t('form:commons.input_type')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={0}>
                                {t('label:Group.label_all_option')}
                              </MenuItem>
                              <MenuItem value={1}>
                                {t(statusGroups[1]?.title)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t(statusGroups[2]?.title)}
                              </MenuItem>
                              <MenuItem value={3}>
                                {t(statusGroups[3]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={status ? status : 0}
                          value={status ? status : 0}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        type="submit"
                      >
                        <Search style={{ marginRight: '5px' }} />
                        {t('button:commons.btn_advanced_filter')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
      />

    </div>
     
      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
      />
    </>
  );
};
