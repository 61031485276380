import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography as MuiTypography,
  Grid,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import { CourseData } from 'services/dataService/index';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useStyles } from './styles';
import clsx from 'clsx';
import { statusCourses } from '../../../../constants/status';
import InformationModal from 'components/InformationModal';
import { ArrowBackIos } from '@material-ui/icons';

export default () => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);
  const [courseSelected, setCourseSelected] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [publicLink, setPublicLink] = useState('');

  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const [state, setState] = useState({
    checkedA: true,
    checkedB: false,
  });

  const updateCourse = (status) => {
    CourseData.updateCourse(id, { status: status ? 2 : 1 }).then(getCourseById);
  };

  const handleChange = (event, checked) => {
    if (event.target.name === 'checkedB') {
      updateCourse(checked);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getPublicLink = () => {
    setOpenModal(true);
    const host = window.location.host;
    setPublicLink(`${host}/course/${courseSelected.idCompanyCourse}/enroll`);
  };

  const getCourseById = () => {
    CourseData.getCourseById(id).then(({ data }) => {
      if (Array.isArray(data.courses) && data.courses.length > 0) {
        const { id: idCompanyCourse, course, status } = data.courses[0];
        setCourseSelected({ ...course, status, idCompanyCourse });
        setState({ ...state, checkedB: !!(status === 2) });
      }
    });
  };

  useEffect(() => {
    getCourseById();
  }, []);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item md={1} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            className={(classes.topButtonSpacing, classes.topButton)}
            onClick={() => history.goBack()}
          >
            <ArrowBackIos style={{ marginRight: '4px' }} />
            {t('button:commons.btn_back')}
          </Button>
        </Grid>
        <Grid item md={4} xs={12}>
          {courseSelected && (
            <MuiTypography variant="h4" className={classes.courseSelected}>
              {courseSelected.name}
              <FiberManualRecordIcon
                style={statusCourses[courseSelected.status].style}
                fontSize="inherit"
              ></FiberManualRecordIcon>
            </MuiTypography>
          )}
        </Grid>

        <Grid item md={6} xs={12} className={classes.topButtonContainer}>
          <FormControl
            component="fieldset"
            className={classes.topButtonSpacing}
          >
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={state.checkedB}
                  onChange={handleChange}
                  name="checkedB"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label={t('button:Lesson.btn_public_course')}
            />
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            size="small"
            className={clsx(classes.topButtonSpacing, classes.topButton)}
            onClick={() => history.push(`/courses/${id}/config`)}
          >
            {t('button:Course.btn_config_course')}
          </Button>
{/* 
          <Button
            color="primary"
            variant="contained"
            size="small"
            className={clsx(classes.topButtonSpacing, classes.topButton)}
            onClick={() => history.push(`/course/${id}/learn`)}
          >
            {t('button:Lesson.btn_student')}
          </Button> */}

          <Button
            color="primary"
            variant="contained"
            size="small"
            className={classes.topButton}
            onClick={getPublicLink}
          >
            {t('button:Lesson.btn_public')}
          </Button>
        </Grid>
      </Grid>
      <InformationModal
        title={t('label:Lesson.label_public_link_title')}
        description={t('label:Lesson.label_public_link_description')}
        handleClose={handleCloseModal}
        isOpen={openModal}
      >
        <TextField
          variant="outlined"
          style={{ width: '100%' }}
          value={publicLink}
          readOnly
        />
      </InformationModal>
    </>
  );
};
