import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '89vh',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.white,
      overflowY: 'scroll',
      overflowX: 'hidden',
      justifyContent: 'flex-start',
    },
  },

  grid: {
    display: 'grid',
    gridTemplateRows: '4fr 8fr',
    height: '100%',
    padding: '0 20px',
  },

  titleBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  formBox: {
    display: 'flex',
    flexDirection: 'column',
  },

  input: {
    marginTop: theme.spacing(3),
  },

  container: {
    boxShadow: 'inset 0 0 0em white, 0 0 2em #ccc;',
    width: '50%',
    maxWidth: '532px',
    height: '600px',
    borderRadius: '0.8rem',
    backgroundColor: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: '600px',
      marginTop: '15px',
    },
  },

  form: {
    backgroundColor: theme.palette.white,
    padding: '0 40px 40px 40px',
  },

  fieldGroup: {
    paddingBottom: theme.spacing(3),
  },

  footer: {
    textAlign: 'center',
  },

  title: {
    textAlign: 'center',
    color: '#707070',
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },

  subtitle: {
    textAlign: 'center',
    color: '#707070',
    fontSize: '18px',
  },

  sendButton: {
    margin: theme.spacing(2, 0),
  },

  backDiv: {
    width: '40%',
    margin: '20px 0px 0px 30px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  inputEmail: {
    '& input': {
      textTransform: 'lowercase'
    }
  },
}));