import React, { useState } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import { EditFormCompany } from 'views/Company/components';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import {
  CompanyInfobar,
  CompanyCourses,
  CompanyService,
  CompanyData,
} from '../components/index';

export default () => {
  const classes = useStyles();
  const [buttonSelected, setButtonSelected] = useState('courses');

  const { t } = useTranslation(['button']);
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CompanyInfobar buttonSelected={buttonSelected} />
        </Grid>

        <Grid item container xs={12} spacing={1}>
          {/*
          <Grid item xs={12} md={4}>
            <CompanySearchbar />
          </Grid>
          */}

          <Grid item container xs={12} >
            <Grid item container xs={12}>
              <Grid item xs={3}>
                <Button
                  color="secondary"
                  variant={buttonSelected === 'courses' ? 'contained' : 'outlined'}
                  size="medium"
                  className={classes.button}
                  onClick={() => setButtonSelected('courses')}
                >
                  {t('button:Company.btn_courses')}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="secondary"
                  variant={buttonSelected === 'edit' ? 'contained' : 'outlined'}
                  size="medium"
                  className={classes.button}
                  onClick={() => setButtonSelected('edit')}
                >
                  {t('button:Company.btn_edit')}
                </Button>
              </Grid>
              {/*<Grid item xs={3}>
                <Button
                  color="secondary"
                  variant={
                    buttonSelected === 'service' ? 'contained' : 'outlined'
                  }
                  size="medium"
                  className={classes.button}
                  onClick={() => setButtonSelected('service')}
                >
                  {t('button:Company.btn_service')}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="secondary"
                  variant={
                    buttonSelected === 'dataOne' ? 'contained' : 'outlined'
                  }
                  size="medium"
                  className={classes.button}
                  onClick={() => setButtonSelected('dataOne')}
                >
                  {t('button:Company.btn_data')}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  color="secondary"
                  variant={
                    buttonSelected === 'dataTwo' ? 'contained' : 'outlined'
                  }
                  size="medium"
                  className={classes.button}
                  onClick={() => setButtonSelected('dataTwo')}
                >
                  {t('button:Company.btn_spe_edit')}
                </Button>
              </Grid>
              */}
            </Grid>

            <Grid item xs={12}>
              {
                buttonSelected === 'edit' ? (
                  <EditFormCompany />
                ) : (
                  <CompanyCourses />
                )/* : buttonSelected === 'service' ? (
                  <CompanyService />
                ) : (
                  <CompanyData />
                )*/
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
