import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
  },

  title: {
    fontSize: 14,
  },

  col: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  statusTitle: {
    textAlign: 'center',
  },
}));
