import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
   display: 'flex',
   width: '100%',
   justifyContent: 'space-between',
   marginBottom: theme.spacing(2),
   [theme.breakpoints.down('sm')]: {
     flexDirection: 'column',
   },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  signal: {
    width: 30,
    height: 30,
    borderRadius: 30,
    background: theme.palette.success.main,
  },

  card: {
    width: '24%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
