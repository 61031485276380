import React from 'react';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { localizationTable } from 'constants/table';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '400px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em',

    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '1em',
    },
  },
}));

export default function SelectTable({
  cols = [],
  rows = [],
  onSelect,
  length = 0,
  totalSelected = 0,
  rowsPerPage = 1,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);

  const handleClick = (e, rowId) => {
    if (onSelect) {
      const selectedIds = rows.filter(row => row.selected).map(row => row.id);
      const newSelectedIds = selectedIds.includes(rowId)
        ? selectedIds.filter(id => id !== rowId)
        : [...selectedIds, rowId];
      onSelect({ type: 'single', selectedIds: newSelectedIds });
    }
  };

  const onSelectAllClick = (e) => {
    if (onSelect) {
      const selectedIds = e.target.checked ? rows.map(row => row.id) : [];
      onSelect({ type: 'all', selectedIds });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, length - page * rowsPerPage);

  const selectedRows = rows.filter((row) => row.selected);
  const unselectedRows = rows.filter((row) => !row.selected);

  const sortedRows = [...selectedRows, ...unselectedRows];

  return (
    <Box>
      <TableContainer className={classes.container}>
        <Table
          style={{ maxHeight: '400px' }}
          stickyHeader
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  style={{display: 'none'}}
                  color="primary"
                  indeterminate={totalSelected > 0 && totalSelected < length}
                  checked={length > 0 && totalSelected === length}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all',
                  }}
                />
              </TableCell>
              {cols.map((headCell, index) => (
                <TableCell
                  key={`${headCell.label}-${index}`}
                  align="center"
                  padding="normal"
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={row.selected || false}
                    tabIndex={-1}
                    key={row.id}
                    selected={row.selected || false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={row.selected || false}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    {cols.map(({ field, align, style }, index) => (
                      <TableCell
                        style={style || {}}
                        key={`row-${field}-${index}`}
                        align={align || 'center'}
                      >
                        {row[field]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {rows.length < 1 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell align="center" colSpan={6}>
                  {'não há registros para exibir'}
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {rows.length > 0 && (
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[]} 
            labelRowsPerPage={''} 
          />
        )}
      </TableContainer>
    </Box>
  );
}
