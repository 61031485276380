import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  Typography as MuiTypography,
  FormHelperText,
} from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import { Save } from '@material-ui/icons';
import { useStyles } from './styles';
import { CertificateData, FilesData } from 'services/dataService';
import CertificateForm from './components/Form';

export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const [showPeriod, setShowPeriod] = useState(false);
  const [showDocStudent, setShowDocStudent] = useState(false);
  const [file, setFile] = useState();
  const [filePath, setFilePath] = useState();
  const { id_company } = useSelector((state) => state.auth);
  const form = useForm();
  const { handleSubmit, setValue } = form;
  const [previewFile, setPreviewFile] = React.useState('');

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);

  const toggleShowPeriod = () => {
    setShowPeriod((prev) => !prev);
  };
  const toggleShowDocStudent = () => {
    setShowDocStudent((prev) => !prev);
  };

  const handleChange = (event) => {
    setSettings({ ...settings, number_courses_homepage: event.target.value });
  };

  const classes = useStyles();

  const onSubmit = useCallback(
    async (data) => {
      try {
        const formValue = {
          ...data,
        };

        if (file) {
          const responseCard = await FilesData.addFile(file, "Certificate");
          formValue.id_file = responseCard.data.id;
        }

        if (editRef.current) {
          await CertificateData.updateCertificate(id_company, formValue);
        } else {
          await CertificateData.addCertificate(id_company, formValue);
        }

        toast.success(t('toastify:commons.toast_update'));
      } catch (error) {
        console.error(error)
        toast.error(t('toastify:commons.toast_error'));
      }
    },
    [id_company, t, file]
  );

  const editRef = useRef(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await CertificateData.getCertificate(id_company);

        const {
          location,
          show_doc_student,
          show_period,
          top_footer_spacing,
          top_header_spacing,
          top_message_spacing,
          top_name_spacing,
          footer_message,
          expiration_date,
          top_document_spacing,
          top_expiration_spacing,
          top_hours_spacing,
        } = response.data;

        setPreviewFile(response.data.file?.base64);
        setFilePath(response.data.file);
        setShowPeriod(show_period);
        setShowDocStudent(show_doc_student);

        setValue([
          { location },
          { top_footer_spacing },
          { top_header_spacing },
          { top_message_spacing },
          { top_name_spacing },
          { footer_message },
          { expiration_date },
          { top_document_spacing },
          { top_expiration_spacing },
          { top_hours_spacing },
        ]);
      } catch (err) {
        if (err.response?.data.error === 'Certificate not found') {
          setLoading(false);
          editRef.current = false;
        } else toast.error(t('toastify:commons.toast_error'));
      }
    })();
    setLoading(false);
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={t('title:Settings.title_settings_certificate')} />
      <Divider />
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!loading && (
          <CardContent>
            <CertificateForm
              showPeriod={showPeriod}
              showDocStudent={showDocStudent}
              setFile={setFile}
              filePath={filePath}
              toggleShowPeriod={toggleShowPeriod}
              toggleShowDocStudent={toggleShowDocStudent}
              file={file}
              form={form}
              previewFile={previewFile}
              setPreviewFile={setPreviewFile}
            />
          </CardContent>
        )}

        <Divider />
        <CardActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
          >
            {t('button:commons.btn_save')}
            <Save />
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
