import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    marginLeft: theme.spacing(2),
  },
  card: {
    userSelect: 'none',
    margin: theme.spacing(0, 0, 1, 0),
  },
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '20%',
  },
  iconTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%',
    alignItems: 'center',
  },
  box: {
    width: 'auto',
  },
  gridIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
