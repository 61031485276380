import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, Typography as MuiTypography,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import {
  PeopleOutlined,
  PersonOutline,
  DescriptionOutlined,
  AttachMoney,
  SchoolOutlined,
  AssignmentTurnedInOutlined,
  Update,
  AddCircle
} from '@material-ui/icons';
import { useStyles } from './styles';
import { Card } from 'components/Toolbar/';
import { periodFilter } from 'constants/types';
import { toast } from 'react-toastify';
import { CompanyData } from 'services/dataService';
import ModalLooker from 'views/Reports/Looker/components/ModalLooker/Index';

export default ({ className, ...rest }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const [period, setPeriod] = useState(periodFilter.all);
  const [openLooker, setOpenLooker] = React.useState(false);
  const [periodButtons, setPeriodButtons] = useState({
    all: true,
    monthly: false,
    weekly: false,
    daily: false,
  });

  const tableRef = useRef();
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);

  const classes = useStyles();
  const history = useHistory();

  const handleClick = useCallback((route) => {
    history.push(`${route}/create`);
  }, []);

  const handlePeriodButtonsChoice = useCallback(
    (choice) => {
      setPeriod(periodFilter[choice]);

      setPeriodButtons({
        [choice]: !periodButtons[choice],
      });
    },
    [periodButtons, period]
  );

  const fetchData = async () => {
    try {
      setDisabledButtons(true);

      const response = await CompanyData.getMenuDashboard({
        period_filter: period,
      });

      setData(response.data);

      if (tableRef && tableRef.current) {
        tableRef.current.state.query.page = 0;
        tableRef.current.onQueryChange();
      }
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    } finally {
      setLoading(false);
      setDisabledButtons(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [period]);

  const handleRoutes = (route) => {
    history.push(route);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/* {openLooker && (
          <ModalLooker setOpen={setOpenLooker} />
        )
      } */}
      <Grid container className={classes.quickAccessContainer}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <MuiTypography variant="h4">
            {t('label:commons.label_access_quick')}:
          </MuiTypography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} className={classes.topButtons}>
          {/* <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => setOpenLooker(true)}
            className={classes.button}
          >
            <Update className={classes.icon} />
            {t('button:Dashboard.quick_access_buttons.btn_companies_looker')}
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleClick('companies')}
            className={classes.button}
          >
            <AddCircle className={classes.icon} />
            {t('button:Dashboard.quick_access_buttons.btn_companies')}
          </Button>
        </Grid>
      </Grid>

      <div className={classes.overviewRoot}>
        <Typography variant="h5" component="h5">
          {t('title:Dashboard.title_overview')}
        </Typography>

        <span className={classes.periodChoiceButtons}>
          <span className={classes.choiceButtons}>
            <Button
              variant={
                periodButtons['all'] === true ? 'contained' : 'outlined'
              }
              disabled={disabledButtons}
              onClick={() => handlePeriodButtonsChoice('all')}
              color="primary"
              size="small"
              className={classes.choiceButtons}
            >
              {t('button:Dashboard.commons.btn_all_info')}
            </Button>
          </span>
          <span className={classes.choiceButtons}>
            <Button
              variant={
                periodButtons['monthly'] === true ? 'contained' : 'outlined'
              }
              disabled={disabledButtons}
              onClick={() => handlePeriodButtonsChoice('monthly')}
              color="primary"
              size="small"
              className={classes.choiceButtons}
            >
              {t('button:Dashboard.commons.btn_monthly_info')}
            </Button>
          </span>
          <span className={classes.choiceButtons}>
            <Button
              variant={
                periodButtons['weekly'] === true ? 'contained' : 'outlined'
              }
              disabled={disabledButtons}
              onClick={() => handlePeriodButtonsChoice('weekly')}
              color="primary"
              size="small"
              className={classes.choiceButtons}
            >
              {t('button:Dashboard.commons.btn_weekly_info')}
            </Button>
          </span>
          <span className='notranslate'>
            <Button
              variant={
                periodButtons['daily'] === true ? 'contained' : 'outlined'
              }
              disabled={disabledButtons}
              onClick={() => handlePeriodButtonsChoice('daily')}
              color="primary"
              size="small"
            >
              {t('button:Dashboard.commons.btn_daily_info')}
            </Button>
          </span>
        </span>
      </div>

      <div className={classes.rowStatus}>
        <div className={classes.overviewPlatformSummary}>
          <span className={classes.overviewPlatformCard}>
            <Card
              title={t('cards:dashboard.card_active_companies')}
              number={data.company_active}
              functionButton={() => handleRoutes('companies')}
              buttonText={t('button:commons.btn_access')}
            />
          </span>
          <span className={classes.overviewPlatformCard}>
            <Card
              title={t('cards:dashboard.card_solicitation_companies')}
              number={data.user_solicitations}
              functionButton={() => handleRoutes('user-solicitations')}
              buttonText={t('button:commons.btn_access')}
            />
          </span>
          <span className={classes.overviewPlatformCard}>
            <Card
              title={t('cards:dashboard.card_test_companies')}
              number={data.company_test}
              functionButton={() => handleRoutes('companies')}
              buttonText={t('button:commons.btn_access')}
            />
          </span>
        </div>

        <div className={classes.overviewUserSummary}>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_online_students')}
              number={data.user_online}
              icon={PersonOutline}
              iconStyle={classes.greenIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_users')}
              number={data.users}
              infoNumber={3.12}
              icon={PeopleOutlined}
              iconStyle={classes.blackIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_active_users')}
              number={data.user_active}
              infoNumber={3.12}
              icon={PersonOutline}
              iconStyle={classes.blueIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_blocked_users')}
              number={data.user_disable}
              infoNumber={-3.12}
              icon={PersonOutline}
              iconStyle={classes.redIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_sales')}
              number={'-'}
              infoNumber={3.12}
              icon={AttachMoney}
              iconStyle={classes.redIcon}
            />
          </span>
        </div>

        <div className={classes.overviewUserSummary}>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_courses')}
              number={data.courses}
              infoNumber={3.12}
              icon={DescriptionOutlined}
              iconStyle={classes.greenIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_in_production_courses')}
              number={data.courses_production}
              infoNumber={3.12}
              icon={DescriptionOutlined}
              iconStyle={classes.blackIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_enrollment')}
              number={data.enrollments}
              infoNumber={-3.12}
              icon={AssignmentTurnedInOutlined}
              iconStyle={classes.blueIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:course.card_courses_contenters')}
              number={data.teachers}
              infoNumber={3.12}
              icon={SchoolOutlined}
              iconStyle={classes.blueIcon}
            />
          </span>
          <span className={classes.overviewUserCard}>
            <Card
              title={t('cards:commons.card_volume')}
              number={'-'}
              infoNumber={3.12}
              icon={AttachMoney}
              iconStyle={classes.redIcon}
            />
          </span>
        </div>
      </div>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
      ></Grid>
    </div>
  );
};
