import { combineReducers } from 'redux';

import auth from './auth/reducer';
import vimeo from './content/reducer';
import notification from './notification/reducer';
import learn from './learn/reducer';
import company from './company/reducer';
import junoAPI from './junoAPI/reducer';
import teams from './teams/reducer';

export default combineReducers({
  auth,
  vimeo,
  notification,
  learn,
  company,
  junoAPI,
  teams,
});
