import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, CameraAltOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilesData } from 'services/dataService';
import CustomHelper from '../CustomHelperText';

const BannerCustomization = ({ form, banner_data }) => {
  const classes = useStyles();
  const { register, watch, setValue, errors } = form;
  const { t } = useTranslation(['views', 'button']);
  const values = watch('company_banner');
  const fileInput = React.useRef();
  const [showCtaButton, setShowCtaButton] = React.useState(true);
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [selected, setSelected] = React.useState([]);

  const saveFile = async (file) => {
    if (!file) {
      return;
    }
    const { data } = await FilesData.addFile(
      file,
      'company_customization_banner'
    );
    setFileUrl(data?.url);
    setFile(data?.id);
  };
  const initFile = () => {
    setFileUrl(banner_data?.banner?.url);
    setFile(banner_data?.id_file_banner);
  };

  React.useEffect(() => {
    initFile();
  }, []);

  React.useEffect(
    () => {
      setShowCtaButton(banner_data?.ctaButtons?.length ? true : false);
    },
    banner_data,
    values
  );

  return (
    <Box>
      <Box>
        <Typography className={classes.header} color="textSecondary">
          {t('views:CompanyCustomization.banner')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              name="company_banner.title"
              defaultValue={banner_data?.title}
              variant="outlined"
              label={t('views:CompanyCustomization.title')}
              inputRef={register}
              error={!!errors?.company_banner?.title}
            />
            <CustomHelper
              text={t('views:CompanyCustomization.title_description')}
            />
            <Typography className={classes.spacingTop} />
            <CustomColorPicker
              name="company_banner.title_color"
              defaultValue={banner_data?.title_color}
              register={register}
              title={t('views:CompanyCustomization.title_color_description')}
            />
            <CustomHelper
              text={t('views:CompanyCustomization.title_description')}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              name="company_banner.description"
              defaultValue={banner_data?.description}
              variant="outlined"
              label={t('views:CompanyCustomization.description')}
              inputRef={register}
              error={!!errors?.company_banner?.description}
            />
            <CustomHelper
              text={t('views:CompanyCustomization.description_helper')}
            />
            <Typography className={classes.spacingTop} />
            <CustomColorPicker
              name="company_banner.description_color"
              title={t(
                'views:CompanyCustomization.description_color_description'
              )}
              register={register}
              defaultValue={banner_data?.description_color}
            />
            <CustomHelper
              text={t('views:CompanyCustomization.description_helper')}
            />
          </Grid>
        </Grid>

        <Typography className={classes.spacingTop2x} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img
              width={500}
              height={80}
              style={{ maxWidth: '100%' }}
              src={fileUrl}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.item}>
              <input
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
                id="contained-logo-file"
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  saveFile(event.target.files[0]);
                }}
              />
              <TextField
                name="company_banner.id_file_banner"
                value={file}
                inputRef={register}
                style={{ display: 'none' }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  fileInput.current.click();
                }}
              >
                <CameraAltOutlined fontSize="small" />
                {t('button:CompanyCustomization.change_logo')}
              </Button>
              <CustomHelper
                text={t('views:CompanyCustomization.change_banner_helper')}
              />
            </Box>
          </Grid>
        </Grid>

        <Typography className={classes.spacingTop2x} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography className={classes.spanHeader}>
              {t('views:CompanyCustomization.cta_buttons')}
            </Typography>
            <CustomHelper
              text={t('views:CompanyCustomization.cta_buttons_description')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Switch
              // inputRef={register}
              color="secondary"
              // name="company_banner_validations.buttons"
              checked={showCtaButton}
              onChange={(_, checked) => {
                setShowCtaButton(checked);
              }}
              inputRef={register}
            />
          </Grid>
        </Grid>

        <Typography className={classes.colorTopSpacing} />

        {showCtaButton && (
          <Grid container>
            <Grid item xs={12} md={10}>
              <Box>
                {selected.length > 0 && (
                  <Box>
                    <Button
                      color="primary"
                      onClick={() => {
                        banner_data.ctaButtons = values?.ctaButtons?.filter(
                          (btn, index) => !selected.includes(index)
                        );
                        setSelected([]);
                        setRefresh(!refresh);
                      }}
                    >
                      {t('button:CompanyCustomization.remove_selected')}
                    </Button>
                  </Box>
                )}
                <Grid container spacing={2}>
                  {banner_data?.ctaButtons?.map((_, index) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.ctaButtonSpacing}
                    >
                      <Box
                        className={selected.includes(index) && classes.select}
                      >
                        <Checkbox
                          checked={selected.includes(index)}
                          onChange={(e, checked) => {
                            if (checked) {
                              selected.push(index);
                            } else {
                              setSelected(selected.filter((i) => i !== index));
                            }
                            setRefresh(!refresh);
                          }}
                        />
                      </Box>
                      <Box
                        className={clsx(
                          selected.includes(index) && classes.selected,
                          classes.colorTopSpacing
                        )}
                      >
                        <Box>
                          <TextField
                            variant="outlined"
                            key={index}
                            name={`company_banner.ctaButtons[${index}].text`}
                            label={t(
                              'views:CompanyCustomization.cta_button_text'
                            )}
                            defaultValue={_?.text}
                            inputRef={register}
                          />
                          <CustomHelper
                            text={t(
                              'views:CompanyCustomization.cta_button_text'
                            )}
                          />
                        </Box>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className={classes.colorTopSpacing}
                          >
                            <Grid item xs={12} md={4}>
                              <CustomColorPicker
                                name={`company_banner.ctaButtons[${index}].text_color`}
                                defaultValue={_?.text_color}
                                register={register}
                                title={t(
                                  'views:CompanyCustomization.cta_button_text_color'
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <CustomColorPicker
                                name={`company_banner.ctaButtons[${index}].color`}
                                defaultValue={_?.color}
                                register={register}
                                title={t(
                                  'views:CompanyCustomization.cta_button_color'
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <CustomColorPicker
                                name={`company_banner.ctaButtons[${index}].border_color`}
                                defaultValue={_?.border_color}
                                register={register}
                                title={t(
                                  'views:CompanyCustomization.cta_button_border_color'
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className={classes.colorTopSpacing}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name={`company_banner.ctaButtons[${index}].link`}
                            defaultValue={_?.link}
                            label={t(
                              'views:CompanyCustomization.navigation_link'
                            )}
                            inputRef={register}
                          />
                          <CustomHelper
                            text={t(
                              'views:CompanyCustomization.navigation_link_helper'
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    banner_data.ctaButtons.push({
                      text: '',
                      color: '#000000',
                      text_color: '#000000',
                      border_color: '#000000',
                      link: '',
                    });
                    setRefresh(!refresh);
                  }}
                >
                  <Add />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(2),
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  spanHeader: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
  spacingTop2x: {
    marginTop: theme.spacing(4),
  },
  ctaButtonSpacing: {
    padding: theme.spacing(2),
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  select: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  selected: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
  },
}));

export default BannerCustomization;
