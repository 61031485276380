import React, { useState, useCallback } from 'react';
import {
  Grid,
  Card,
  Typography as MuiTypography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useStyles } from './styles';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GroupsData, CompanyData, UserData } from 'services/dataService';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ArrowBackIos, FormatIndentDecreaseSharp } from '@material-ui/icons';

import { exportToCsv, exportToPdf, exportToXlsx } from 'helpers/reportExport';
import { CustomFieldsData } from 'services/dataService';

const exportActionType = {
  csv: (col, responseData, title) => {
    exportToCsv(col, responseData, title);
  },
  pdf: (col, responseData, title) => {
    exportToPdf(col, responseData, title);
  },
  excel: (col, responseData, title) => {
    exportToXlsx(col, responseData, title);
  },
};

export default () => {
  const classes = useStyles();
  const { t } = useTranslation(['label', 'button', 'form', 'files']);
  const [file, setFile] = useState();
  const [group, setGroup] = useState('');
  const [checkEmail, setCheckEmail] = useState(false);
  const [listGroup, setListGroup] = useState([]);
  const [myCompany, setMyCompany] = useState('');

  const history = useHistory();

  async function getExportFields() {
    try {
      const response = await await CustomFieldsData.getExportFields();
      return response;
    } catch (e) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  }

  const customExport = useCallback(async (type) => {
    try {
      const columns = await getExportFields();
      let responseData = [];
      let responseDataObj = {};
      for (let i in columns.data) {
        responseDataObj[columns.data[i].title] = columns.data[i].example;
      }
      responseData.push(responseDataObj);
      exportActionType[type](columns.data, responseData, 'csvExample.ac858279');
    } catch (err) {
      toast.error(`No momento não foi possível baixar o arquivo ${type}`);
    }
  }, []);

  async function getPlataform() {
    try {
      const responseCompany = await CompanyData.getCompanyByHostname();
      setMyCompany(responseCompany.data.company);
    } catch (e) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  }

  async function getGroup() {
    try {
      const responseGroup = await GroupsData.getGroups();
      setListGroup(responseGroup.data.groups.filter((a) => a?.type !== 3));
    } catch (e) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  }

  const formatCSVResponse = (r) =>
    r.map((f) => ({
      ...f,
      reject_reason: f.reject_reason
        .map((r) => {
          const [value, field] = r.split(',');
          return t(`files:${value}`, { field });
        })
        .join('; '),
    }));

  async function postMassiveUser() {
    try {
      if (!file) {
        toast.error(t('toastify:user.toast_fail_file'));
        return;
      }
      if (!group) {
        toast.error(t('toastify:user.toast_fail_id_group'));
        return;
      }

      let formDataUser = new FormData();
      formDataUser.append('file', file);
      formDataUser.append('id_group', group);
      formDataUser.append('send_welcome_email', checkEmail);

      const { data } = await UserData.addMassiveUser(
        formDataUser,
        myCompany.id
      );

      if (data.invalidUsers?.length) {
        const cols = new Set(data.invalidUsers.flatMap((i) => Object.keys(i)));

        const extension =
          data?.extension?.toLocaleLowerCase() === 'csv' ? 'csv' : 'excel';

        exportActionType[extension](
          [...cols].map((c) => ({
            field: c,
            title: c,
          })),
          formatCSVResponse(data.invalidUsers),
          t('files:Csv_fields.invalid_users_filename')
        );
      }

      toast.success(t('toastify:user.toast_send_massive'));

      formDataUser.delete('file');
      formDataUser.delete('id_group');
      formDataUser.delete('send_welcome_email');
    } catch (e) {
      toast.error(t('toastify:commons.toast_error_same'));
    }
  }

  useEffect(() => {
    getPlataform();
    getGroup();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          <Card className={classes.card}>
            <Grid
              direction="column"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <div className={classes.item}>
                  <MuiTypography variant="subtitle1">
                    {t('label:User.label_download_example')}
                  </MuiTypography>
                  <Grid container spacing={1} xs={12}>
                    <Grid item md={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => customExport('excel')}
                      >
                        {t('button:User.btn_profile_example_xlsx')}
                      </Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => customExport('csv')}
                      >
                        {t('button:User.btn_profile_example_csv')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.item}>
                  <MuiTypography variant="subtitle1">
                    {t('label:User.label_upload_archive')}
                  </MuiTypography>

                  <Grid container spacing={1}>
                    <Grid item md={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        component="label"
                      >
                        {t('button:User.btn_profile_choose_archive')}

                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={(event) => {
                            setFile(event.target.files[0]);
                          }}
                          accept=".csv,.xlsx"
                        />
                      </Button>
                    </Grid>
                    <Grid item md={3}>
                      <MuiTypography variant="subtitle1">
                        {file?.name}
                      </MuiTypography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.item}>
                  <MuiTypography variant="subtitle1">
                    {t('label:User.label_select')}
                  </MuiTypography>

                  <Grid container spacing={2}>
                    <Grid item md={3} xs={6}>
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-simple-select-label">
                          {t('form:User.select_plataform')}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={myCompany.id ? myCompany.id : ''}
                          onChange={(e) => setMyCompany(e.target.value)}
                          disabled
                        >
                          <MenuItem key={myCompany?.id} value={myCompany?.id}>
                            {myCompany?.name}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <FormControl className={classes.select}>
                        <InputLabel id="demo-simple-select-label">
                          {t('form:User.select_group')}
                        </InputLabel>
                        <Select
                          className={classes.select}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={group}
                          onChange={(event) => setGroup(event.target.value)}
                        >
                          {listGroup.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                              {group.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkEmail}
                            onChange={() => {
                              setCheckEmail(!checkEmail);
                            }}
                            name="checkEmail"
                          />
                        }
                        label={t('form:User.switch_email')}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item container>
                <Grid item md={3} xs={4}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    onClick={() => history.goBack()}
                  >
                    <ArrowBackIos style={{ marginRight: '4px' }} />
                    {t('button:commons.btn_back')}
                  </Button>
                </Grid>
                <Grid imte md={6} xs={4} />
                <Grid item md={3} xs={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={() => postMassiveUser()}
                    disabled={!(myCompany.id && group)}
                  >
                    {t('button:commons.btn_import')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
