import produce from 'immer';
import {
  JUNO_CHARGE_ID,
  SET_JUNO_API_FETCHING,
  SET_PERSONAL_DATA,
  UPDATE_JUNO_API_KEY,
} from './actions';

const INITIAL_STATE = {
  token: '',
  tokenType: '',
  user: '',
  chargeId: '',
  fetching: false,
  personalData: {
    name: '',
    document: '',
    email: '',
    phone: '',
    birthDate: '',
  },
};

export default function junoAPI(state = INITIAL_STATE, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case UPDATE_JUNO_API_KEY: {
        draft.token = payload?.access_token || '';
        draft.tokenType = payload?.token_type || '';
        draft.user = payload?.user_name || '';
        break;
      }
      case SET_JUNO_API_FETCHING: {
        draft.fetching = payload;
        break;
      }
      case JUNO_CHARGE_ID: {
        draft.chargeId = payload;
        break;
      }
      case SET_PERSONAL_DATA: {
        draft.personalData = { ...payload };
        break;
      }
      default:
    }
  });
}
