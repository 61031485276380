import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { phoneMask } from 'common/validators';
export function PhoneMaskCustom(props) {
  const { inputRef, name, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      name={name}
      mask={phoneMask(name)}
      guide={false}
    />
  );
}

PhoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
