import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';
import SelectList from '../SelectList';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import {
  ClassroomData,
  ClassroomTagData,
  ClassroomUserData,
} from 'services/dataService';

export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);

  const { id } = useParams();

  const { register, handleSubmit, errors, setValue } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  const includeTag = async (id_tag, checked) => {
    try {
      if (checked) {
        await ClassroomTagData.addClassroomTag({ id_classroom: id, id_tag });
      } else {
        await ClassroomTagData.deleteClassroomTag(id_tag, id);
      }
    } catch(err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    } 
  };

  const includeUser = async (id_user, checked) => {
    try {
    if (checked) {
      await ClassroomUserData.addClassroomUser({ id_classroom: id, id_user });
    } else {
      await ClassroomUserData.deleteClassroomUser(id_user, id);
    }
    } catch(err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await ClassroomData.getClassroomById(id);

          setValue([
            { name: response.data.name },
            { description: response.data.description },
          ]);

          const responseUser = await ClassroomUserData.getClassroomUser(id);
          setUsers(responseUser.data.classroomsUsers);

          const responseTag = await ClassroomTagData.getClassroomTag(id);
          setTags(responseTag.data.classroomsTags);

          setLoadingEdit(false);
        } catch (err) {
          history.push('/classroom');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }    
        }
      };

      fetchData();
    }
    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
    try {
      if (id) {
        await ClassroomData.updateClassroom(id, data);
        toast.success(t('toastify:commons.toast_update'));
      } else {
        await ClassroomData.addClassroom(data);
        toast.success(t('toastify:commons.toast_success'));
      }

      history.push('/classroom');
      setValue([{ name: '' }, { description: '' }]);
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const titlePage = id
    ? t('title:Classroom.title_edit')
    : t('title:Classroom.title_create');

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={titlePage} />
        <Divider />
        {!loading && (
          <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Classroom.input_name')}
                    helperText={
                      errors.name?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Classroom.input_name'),
                      })
                    }
                    name="name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.name}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Classroom.input_description')}
                    name="description"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: false })}
                    error={!!errors.description}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
      {!loadingEdit && id && (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title={t('form:Classroom.subtitle')} />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12} lg={6}>
                <SelectList
                  header={t('form:Classroom.headerStudent')}
                  list={users}
                  check={includeUser}
                />
              </Grid>
              <Grid item md={6} xs={12} lg={6}>
                <SelectList
                  header={t('form:Classroom.headerTags')}
                  list={tags}
                  check={includeTag}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      )}
    </>
  );
};
