import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { ShoppingCartOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { PaymentSteps } from 'views/CourseBuyView';
import InputMask from 'react-input-mask';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import validateFederalTax from 'helpers/FederalTaxValidator';
import { useDispatch } from 'react-redux';
import { setPersonalData } from 'store/modules/junoAPI/actions';
import moment from 'moment';

const PersonalData = ({ callback }) => {
  const classes = useStyles();

  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [emailConfirm, setEmailConfirm] = React.useState('');
  const [emailConfirmError, setEmailConfirmError] = React.useState(false);
  const [federalTax, setFederalTax] = React.useState('');
  const [federalTaxError, setFederalTaxError] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedDateError, setSelectedDateError] = React.useState();

  const dispatch = useDispatch();

  const validaEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onlyNumbers = (n) => {
    return n?.replace(/[^0-9\\.]+/g, '');
  };

  const validateFields = () => {
    let valid = true;
    if (!name?.length) {
      setNameError(true);
      valid = false;
    }

    if (!validaEmail(email) || email !== emailConfirm) {
      setEmailError(true);
      valid = false;
    }
    if (!validaEmail(emailConfirm) || email !== emailConfirm) {
      setEmailConfirmError(true);
      valid = false;
    }

    if (!validateFederalTax(federalTax)) {
      setFederalTaxError(true);
      valid = false;
    }

    if (!(onlyNumbers(phoneNumber)?.length > 9)) {
      setPhoneNumberError(true);
      valid = false;
    }

    if (!selectedDate) {
      setSelectedDateError(true);
      valid = false;
    }

    if (valid) {
      const date = new Date(selectedDate);
      const m = date.getMonth() + 1;
      dispatch(
        setPersonalData({
          name,
          email,
          phone: onlyNumbers(phoneNumber),
          document: federalTax.replace(/[\s.-]*/gim, ''),
          birthDate: `${date.getFullYear()}-${m > 9 ? '' + m : '0' + m}-${date.getDate() > 9 ? '' + date.getDate() : '0' + date.getDate()
            }`,
        })
      );
      callback(PaymentSteps.USER_PAYMENT);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.field}>
        <TextField
          fullWidth
          value={name}
          error={nameError}
          onChange={(e) => {
            setNameError(false);
            setName(e.target.value);
          }}
          helperText={nameError && 'Campo obrigatório'}
          variant="outlined"
          label="Nome completo"
        />
      </Box>
      <Box className={classes.field}>
        <TextField
          fullWidth
          value={email}
          error={emailError}
          onChange={(e) => {
            setEmailError(false);
            setEmail(e.target.value);
          }}
          helperText={
            emailError &&
            (!email?.length
              ? 'Campo obrigatório'
              : email !== emailConfirm
                ? 'E-mail não são iguais'
                : 'E-mail inválido')
          }
          variant="outlined"
          label="E-mail"
        />
      </Box>
      <Box className={classes.field}>
        <TextField
          fullWidth
          value={emailConfirm}
          error={emailConfirmError}
          onChange={(e) => {
            setEmailConfirmError(false);
            setEmailConfirm(e.target.value);
          }}
          helperText={
            emailConfirmError &&
            (!emailConfirm?.length
              ? 'Campo obrigatório'
              : emailConfirm !== email
                ? 'E-mail não são iguais'
                : 'E-mail inválido')
          }
          variant="outlined"
          label="Confirme seu e-mail"
        />
      </Box>
      <Box className={classes.field}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="flex-end"
        >
          <Grid item xs={12} md={4}>
            <Box>
              <InputMask
                mask="999.999.999-99"
                maskChar=""
                value={federalTax}
                onChange={(e) => {
                  setFederalTaxError(false);
                  setFederalTax(e.target.value);
                }}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    fullWidth
                    error={federalTaxError}
                    variant="outlined"
                    helperText={
                      federalTaxError &&
                      (federalTax?.length ? 'CPF inválido' : 'Campo obrigatório')
                    }
                    label="CPF"
                  />
                )}
              </InputMask>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.cellNumber}>
            <Box>
              <InputMask
                mask="(99) 99999-9999"
                maskChar=""
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumberError(false);
                  setPhoneNumber(e.target.value);
                }}
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    fullWidth
                    error={phoneNumberError}
                    helperText={phoneNumberError && 'Campo obrigatório'}
                    variant="outlined"
                    label="Número de celular"
                  />
                )}
              </InputMask>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datePicker}
                  fullWidth
                  error={selectedDateError}
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Data de nascimento"
                  value={selectedDate ? moment(selectedDate) : null}
                  onChange={(date) => {
                    setSelectedDateError(false);
                    setSelectedDate(date ? moment(date) : null);
                  }}
                  helperText={selectedDateError && 'Campo obrigatório'}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={clsx(classes.field, classes.button)}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => validateFields()}
        >
          <ShoppingCartOutlined className={classes.icon} />
          Ir para compra
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    borderRadius: 10,
    boxShadow: '0px 20px 40px 0px #2A2E4329'
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 100,
  },
  successTitle: {
    ...theme.typography.h1,
  },
  topSpacing: { paddingTop: theme.spacing(2) },
  field: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(4),
    '& button': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  cellNumber: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    }
  },
  datePicker : {
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  }
}));
export default PersonalData;
