import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import useWindowDimensions from 'customHooks/DimensionHook';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';

export default function RouteNotYetImplemented({ routeName }) {
  const theme = useTheme();
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { t } = useTranslation(['views']);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <img
            className={classes.image}
            alt="Under development"
            src="/images/route-not-yet-implemented.png"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className={classes.boxContainer}
            component="div"
            m={width < 960 ? 8 : 10}
            style={{
              marginTop: width < 960 && theme.spacing(2),
            }}
          >
            <Typography
              className={classes.titleTextContainer}
              component="span"
              style={{ fontSize: width > 960 ? '4.8vw' : '8vw' }}
            >
              {t('views:RouteNotYetImplemented.title1')}
            </Typography>
          </Box>
          <Box
            className={classes.boxContainer}
            component="div"
            m={width < 960 ? 8 : 10}
            style={{ marginTop: 0 }}
          >
            <Typography
              color="primary"
              className={classes.titleTextContainer}
              style={{ fontSize: width > 960 ? '4.8vw' : '8vw' }}
              component="span"
            >
              {t('views:RouteNotYetImplemented.title2')}
            </Typography>
          </Box>
          <Box
            component="div"
            m={width < 960 ? 8 : 10}
            style={{ marginTop: theme.spacing(6) }}
          >
            <Typography
              color="textSecondary"
              className={classes.descriptionText}
              style={{
                fontSize: width > 960 ? '1.6vw' : '4vw',
              }}
            >
              {t('views:RouteNotYetImplemented.description', {
                page: routeName,
              })}
            </Typography>
          </Box>
          <Box
            style={{
              marginLeft: width < 960 ? theme.spacing(8) : theme.spacing(10),
              marginRight: theme.spacing(2),
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={history.goBack}
            >
              <ArrowBack
                fontSize={'default'}
                style={{ lineHeight: '120%', paddingRight: '2.5%' }}
              />
              <Typography
                component="span"
                color="inherit"
                style={{
                  fontSize: width > 960 ? 'large' : 'medium',
                  lineHeight: '120%',
                }}
              >
                {t('views:RouteNotYetImplemented.back_screen')}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingbottom: theme.spacing(2),
  },
  titleTextContainer: {
    width: '100%',
    display: 'block',
    lineHeight: '100%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  boxContainer: {
    marginBottom: 0,
    marginRight: theme.spacing(2),
  },
  descriptionText: {
    lineHeight: '120%',
  },
  buttonRow: {},
}));
