import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
    minHeight: 'calc(100vh - 64px)',
  },
  content: {
    position: 'relative',
    top: -50,
    textAlign: 'center',

    '& h1': {
      margin: 'auto',
      maxWidth: 680,
      marginBottom: 16,
    },
    '& h6': {
      marginBottom: 48,
    },
  },
  image: {
    marginBottom: 32,
    display: 'inline-block',
    width: '100%',
    maxWidth: 560,
  },
  link: {
    color: '#176AE6',
    fontSize: 20,
    border: '1px solid #176ae680',
    padding: '8px 24px',
    borderRadius: 4,
    fontWeight: 500,
    transition: '250ms',

    '&:hover': {
      border: '1px solid #176ae6',
      backgroundColor: '#176AE60a',
    },
  },
}));

const NotFound = () => {
  const { t } = useTranslation(['message', 'button']);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <img
              alt="Not found"
              className={classes.image}
              src="/images/undraw_page_not_found_su7k.svg"
            />
            <Typography variant="h1">{t('message:404.not_found')}</Typography>
            <Typography variant="subtitle2">
              {t('message:404.mistake')}
            </Typography>
            <Link className={classes.link} to="/dashboard">
              {t('button:commons.btn_back')}
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
