import { Box, makeStyles, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { CourseData } from 'services/dataService';
import { setJunoApiFetching } from 'store/modules/junoAPI/actions';
const PaymentFinished = () => {
  const { id: company_course_id } = useParams();
  const [route, setRoute] = React.useState('');
  const dispatch = useDispatch();

  const navigateToCourse = async () => {
    dispatch(setJunoApiFetching(true));

    const { data: company_course } = await CourseData.getCompanyCourseById(
      company_course_id
    );

    const { data: course } = await CourseData.getCourseById(
      company_course?.id_course
    );

    setRoute(`/course/${course?.courses[0]?.id_course}/learn`);

    dispatch(setJunoApiFetching(false));
  };

  React.useEffect(() => {
    navigateToCourse();
  }, []);

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CheckCircleOutline className={classes.successIcon} />

      <Typography className={classes.successTitle} color="textSecondary">
        Curso comprado com SUCESSO!
      </Typography>

      <Typography className={classes.successDescription} color="textSecondary">
        Basta clicar <Link to={route}>aqui</Link> para ter acesso :)
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(10),
    marginTop: theme.spacing(1),
    borderRadius: 10,
    boxShadow: '0px 20px 40px 0px #2A2E4329'
  },
  successIcon: {
    color: '#00C274',
    fontSize: 100,
    marginBottom: theme.spacing(2)
  },
  successTitle: {
    ...theme.typography.h1,
    color: '#565656',
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '45px',
    maxWidth: 420,
    margin: 'auto',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 32
    }
  },
  successDescription: {
    color: '#707070',
    fontSize: 20,
    lineHeight: '25px',
    padding: '0 30px'
  },
}));
export default PaymentFinished;
