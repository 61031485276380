import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
  },
  wrapper:{
    width: '100%',
    backgroundColor:'#f9f9f9',
    borderRadius: 10,
    padding:30,
    height:250,
    overflowY:'auto',
    [theme.breakpoints.up('md')]: {
      height: 663,
    },
    boxShadow: '0px 9px 22px #d3d3d3',
  },
  title:{
    color: '#000000',
    fontSize:25,
    fontWeight:600,
    marginBottom:10,
  },
  subtitle:{
    color: '#000000',
    fontSize:18,
    fontWeight:600,
    marginBottom:10,
  },
  buttons:{
    display:'flex',
    justifyContent:'space-between',
    padding:5,
    '& button':{
      width:114,
      height:31,
      backgroundColor:'transparent',
      color: '#000000',
      fontSize:14,
      fontWeight:600,
      border:'none',
      outline:'none',
      borderRadius: 4,
      border: '1px solid #2DCE98',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      textTransform: 'uppercase',
      cursor:'pointer',
    }
  },
  buttonsActive:{
    backgroundColor:'#2DCE98 !important',
    color: '#fff !important',
  },
}));
