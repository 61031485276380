import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },

  grid: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  input: {
    marginTop: theme.spacing(3),
  },

  form: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(3),
    borderRadius: '0.8rem',
  },

  fieldGroup: {
    paddingBottom: theme.spacing(3),
  },
  footer: {
    textAlign: 'center',
  },

  title: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  sendButton: {
    margin: theme.spacing(2, 0),
  },
}));
