import React, { forwardRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';

import { signOut } from 'store/modules/auth/actions';
import history from 'services/history';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ isOpen, closeModal }) {
  const { t } = useTranslation(['toastify', 'modal']);
  const dispatch = useDispatch();

  const handleClose = () => {
    closeModal(false);
  };

  const handleConfirm = async () => {
    dispatch(signOut());
    setTimeout(() => {
      history.push('/');
    }, 100);
    toast.info(t('toastify:logout.toast_logout'));
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('modal:commons.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('modal:logout.body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('modal:commons.disagree')}
          </Button>
          <Button onClick={handleConfirm} variant="outlined" color="secondary">
            {t('modal:commons.agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
