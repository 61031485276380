import querystring from 'querystring';
import api from '../api';

class DataServiceClassroomUser {
  addClassroomUser(data) {
    return api.post('classrooms-users', data);
  }

  getClassroomUser(id) {
    return api.get(`classrooms-users/${id}`);
  }

  getClassroomUserByUser(id, params) {
    return api.get(
      `classrooms-users/${id}/user?${querystring.stringify(params)}`
    );
  }

  deleteClassroomUser(id, id_classroom) {
    return api.delete(`/classrooms/${id_classroom}/users/${id}`);
  }
}

export default new DataServiceClassroomUser();
