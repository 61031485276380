import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';
import moment from 'moment';
import { localizationTable, options } from 'constants/table';

import { CardContent, Grid, Button, TextField } from '@material-ui/core';

import { useStyles } from './styles';

import { ReportCompanyData } from 'services/dataService';
import { statusCompany } from 'constants/status';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';
import MenuDownloadReport from 'components/MenuDownloadReport';
import { statusUserContracts } from 'constants/status';

export default ({ fields }) => {
  const [version, setVersion] = useState();
  const [name, setName] = useState('');

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const onSubmit = (data) => {    
    setName(data.name);
    setVersion(data.version)

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const handleFetch = async () => {
    try {
      const response = await ReportCompanyData.getLGPD({
        name,
        version,
        isExport: true,
      });
      return response.data;
    } catch (err) {
      toast.error(
        'Não foi possível buscar os dados do relatório, tente novamente mais tarde.'
      );
      return [];
    }
  };

  return (
    <>
    <div className='notranslate'>
    <MaterialTable
        title={`${t('tables:reports.title_reports_lgpd')}`}
        tableRef={tableRef}
        columns={
          [
            {
              title: `${t('tables:reports.lgpd.name_column')}`,
              field: `name`,
              sorting: false,
              render: (rowData) => rowData.user.name
            },
            {
              title: `${t('tables:reports.lgpd.date')}`,
              field: `createdAt`,
              sorting: false,
              render: (rowData) => moment(rowData.updatedAt).format('DD/MM/YYYY HH:mm')
            },
            {
              title: `${t('tables:commons.status_column')}`,
              field: 'status',
              sorting: false,
              render: (rowData) => (
                <div style={{ display: 'flex' }}>
                  <>
                    <CircleIcon
                      style={statusUserContracts[rowData.status]?.style}
                      fontSize="small"
                    />
                    <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                      {t(statusUserContracts[rowData.status]?.title)}
                    </span>
                  </>
                </div>
              ),
            },
            {
              title: `${t('tables:commons.email_column')}`,
              field: 'email',
              sorting: false,
              render: (rowData) => rowData.user.email
            },
            {
              title: `${t('tables:reports.lgpd.version')}`,
              field: 'version',
              sorting: false,
              render: (rowData) => rowData.contract.version
            },
            // {
            //   title: 'IP',
            //   field: 'ip',
            //   render: (rowData) => rowData.ip
            // },
          ]
        }
        data={async (query) => {
          try {
            const response = await ReportCompanyData.getLGPD({
              page: query.page + 1,
              limit: query.pageSize,
              name,
              version
            });
            return {
              data: response.data.contractuser,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            toast.error(t('toastify:commons.toast_error_api'));
            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_first_name')}
                        name="name"
                        type="text"
                        variant="outlined"
                        defaultValue={name || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:commons.version')}
                        name="version"
                        type="text"
                        variant="outlined"
                        defaultValue={version || ''}
                        inputRef={register}
                      />
                    </Grid>

                    <Grid item md={2} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={{
          ...options,
          exportButton: false,
        }}
      />
    </div>
  
    </>
  );
};
