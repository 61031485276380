import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ArrowBackIos, ImportExport } from '@material-ui/icons';
import { useStyles } from './styles';

export default () => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);

  const history = useHistory();

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item md={6} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            className={(classes.topButtonSpacing, classes.topButton)}
            onClick={() => history.goBack()}
          >
            <ArrowBackIos style={{ marginRight: '5px' }} />
            {t('button:commons.btn_back')}
          </Button>
        </Grid>

        <Grid item md={6} xs={12} className={classes.topButtonContainer}>
          <Button color="primary" variant="contained">
            <ImportExport style={{ marginRight: '5px' }} />
            {t('button:Course.btn_filter')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
