import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { Add, HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import useWindowDimensions from 'customHooks/DimensionHook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FeaturedCoursesData from 'services/dataService/FeaturedCoursesData';
import FeaturedCourseCard from '../Card/FeaturedCoursesCard';
import FeaturedTrailsCard from '../Card/FeaturedTrailsCard';
import FeturedCourseDraggableSort from '../DraggableSort';
import { TrailData } from 'services/dataService';

const FeaturedCourseSorter = ({
  description,
  onCancel,
  onSave,
  initialValue,
  hideTrailsCard
}) => {
  const [allCourses, setCourses] = React.useState([]);
  const [allTrails, setTrails] = React.useState([]);
  const [refresh, setRefresh] = React.useState(true);
  const [refreshTrails, setRefreshTrails] = React.useState(true);
  const [selectedCourses, setSelectedCourses] = React.useState([]);
  const [selectedTrails, setSelectedTrails] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [checkedListTrails, setCheckedListTrails] = React.useState([]);
  const { width } = useWindowDimensions();
  const { t } = useTranslation(['button', 'views', 'toastify']);


  const getCourses = async () => {
    const response = await FeaturedCoursesData.getCoursesFields();
    setCourses(response.data);
  };

  const getTrails = async () => {
    const response = await FeaturedCoursesData.getTrailsFields();
    setTrails(response.data);
  };

  const setValues = () => {
    if (initialValue?.length) {
      const courses = initialValue.filter(item => item.tag === 'course');
      const trails = initialValue.filter(item => item.tag === 'trail');

      setSelectedCourses([...courses]);
      setSelectedTrails([...trails]);
      setCheckedList([...courses]);
      setCheckedListTrails([...trails]);
      setRefresh(!refresh);
      setRefreshTrails(!refreshTrails);
    }
  };
  React.useState(() => {
    getCourses();
    getTrails();
    setValues();
  }, []);

  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={!hideTrailsCard ? 4 : 8} className={classes.itemGrid}>
          <FeaturedCourseCard
            fields={allCourses}
            refresh={(refresh, setRefresh)}
            title={t('views:FeturedCourses.name_course')}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            onSelected={(value, selected) => {
              if (selected) {
                const courses = selectedCourses;
                courses.push({
                  ...value,
                  index: selectedCourses.length,
                  tag: 'course'
                });

                setSelectedCourses(courses);
                setRefresh(!refresh);
              } else {
                setSelectedCourses(
                  selectedCourses
                    .filter(({ id }) => id !== value?.id)
                    .map((sc, index) => {
                      return { ...sc, index };
                    })
                );
              }
            }}
          />
        </Grid>
        {!hideTrailsCard && (
          <Grid item xs={12} md={4} className={classes.itemGrid}>
            <FeaturedTrailsCard className={'2'}
              fieldsTrails={allTrails}
              refreshTrails={(refreshTrails, setRefreshTrails)}
              title={t('views:FeturedCourses.name_trails')}
              checkedListTrails={checkedListTrails}
              setCheckedListTrails={setCheckedListTrails}
              onSelectedTrails={(value, selected) => {
                if (selected) {
                  const trails = selectedTrails;
                  trails.push({
                    ...value,
                    index: selectedTrails.length,
                    tag: 'trail'
                  });
                  setSelectedTrails(trails);
                  setRefreshTrails(!refreshTrails);
                } else {
                  setSelectedTrails(
                    selectedTrails
                      .filter(({ id }) => id !== value?.id)
                      .map((sc, index) => {
                        return { ...sc, index };
                      })
                  );
                }
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4} className={classes.itemGrid}>
          <FeturedCourseDraggableSort
            refresh={(refresh, setRefresh)}
            coursesList={selectedCourses}
            trailsList={selectedTrails}
            onOrderChange={setSelectedCourses}
            onOrderChangeTrails={setSelectedTrails}
            onDelete={(id) => {
              setCheckedList(checkedList.filter((c) => c.id !== id));
            }}
            onDeleteTrails={(id) => {
              setCheckedListTrails(checkedListTrails.filter((c) => c.id !== id));
            }}
          />
        </Grid>
      </Grid>

      <Typography className={classes.divider} />
      <Box className={classes.hint}>
        <HelpOutline style={{ fontSize: 12 }} />
        <Typography component="span">{description}</Typography>
      </Box>
      <Divider variant="fullWidth" />
      <Typography className={classes.divider} />

      <Grid container>
        <Grid className={classes.container} item xs={12} md={6}>
          <Button
            color="primary"
            onClick={() => {
              if (onCancel) onCancel();
            }}
          >
            {t('button:FeaturedCourses.cancel')}
          </Button>
        </Grid>
        <Grid
          className={classes.container}
          item
          xs={12}
          md={6}
          style={{
            display: 'flex',
            justifyContent: width > 960 ? 'flex-end' : 'flex-start',
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.updateButton}
            onClick={() => {
              if (onSave) onSave([...selectedCourses, ...selectedTrails]);
            }}
          >
            <Add />
            {t('button:FeaturedCourses.update')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    display: 'block',
    fontWeight: 800,
    fontSize: 22,
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  itemGrid: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  hint: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
  },
  container: {
    padding: theme.spacing(4),
  },
}));

export default FeaturedCourseSorter;
