import produce from 'immer';
import {
  SET_EDIT_DATA,
  SET_COURSES,
  SET_FETCHING,
  SET_STUDENTS,
  SET_TEAM_DATA,
  SET_TEAM_ID,
  SET_TEAM_MODAL_OPEN,
  SET_TEAM_STEP,
  SET_TRAILS,
} from './actions';

const INITIAL_STATE = {
  teamId: null,
  data: {
    name: '',
    description: '',
    admins: [],
    students: [],
    courses: [],
    trails: [],
  },
  step: 0,
  students: [],
  courses: [],
  trails: [],
  editData: null,
  open: false,
  fetching: false,
  updateFlag: false,
};

export default function junoAPI(state = INITIAL_STATE, { type, payload }) {
  return produce(state, (draft) => {
    switch (type) {
      case SET_TEAM_ID: {
        draft.teamId = payload;
        break;
      }
      case SET_TEAM_DATA: {
        draft.data = payload;
        break;
      }
      case SET_TEAM_STEP: {
        draft.step = payload;
        break;
      }
      case SET_EDIT_DATA: {
        draft.editData = payload;
        break;
      }
      case SET_TRAILS: {
        draft.trails = payload;
        break;
      }
      case SET_COURSES: {
        draft.courses = payload;
        break;
      }
      case SET_STUDENTS: {
        draft.students = payload;
        break;
      }
      case SET_TEAM_MODAL_OPEN: {
        draft.open = payload;
      }
      case SET_FETCHING: {
        draft.fetching = payload;
      }
      default:
    }
  });
}
