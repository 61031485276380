import React from 'react';
import { Box, Grid, Slide, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import SelectTable from '../select-table';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { handleSelection } from 'store/modules/teams/actions';

const columns = [
  {
    label: 'Cursos',
    field: 'name',
    align: 'center',
    style: { whiteSpace: 'nowrap' },
  },

];

export default function TeamCourses() {
  const { courses, trails } = useSelector((state) => state.teams);
  const { t } = useTranslation(['form']);
  const [filterTrails, setFilterTrails] = React.useState(null);
  const [filterCourses, setFilterCourses] = React.useState(null);
  const dispatch = useDispatch();

  const columnsTrails = [
    {
      label: t('tables:Trail.name_column'),
      field: 'name',
      align: 'center',
      style: { whiteSpace: 'nowrap' },
    },
    { 
      label: t('tables:Trail.description_column'), 
      field: 'description',
      style: { fontSize: '12px' }
    },
  ];

  const handleOnSelectTrail = ({ type, ids }) => {
    console.log('handleOnSelectTrail')

    console.log("Selected trails:", trails.filter((t) => t.selected));

    if (type === 'single') {
      dispatch(handleSelection({ type, ids, field: 'trails' }));
    } else {
      dispatch(handleSelection({ type, field: 'trails' }));
    }
  };
  

  const handleOnSelectCourse = ({ type, ids }) => {
    console.log('handleOnSelectCourse')
    if (type === 'single') {
      dispatch(handleSelection({ type, ids, field: 'courses' }));
      return;
    }
    dispatch(handleSelection({ type, field: 'courses' }));
  };

  const handleFilterTrails = (event, value) => {
    console.log('handleFilterTrails')
    setFilterTrails(value);
  };

  const handleFilterCourses = (event, value) => {
    console.log('handleFilterCourses')
    setFilterCourses(value);
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box>
        <Box>
          <Typography>{t('form:Team.Courses.label')}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Autocomplete
                fullWidth
                clearOnEscape
                value={filterCourses}
                options={courses}
                isOptionEqualToValue={(opt, value) => opt.id === value.id}
                getOptionLabel={(option) => `${option?.name || ''}`}
                onChange={handleFilterCourses}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    label={t('Buscar cursos')}
                    variant="outlined"
                  />
                )}
                filterOptions={(options, { inputValue }) =>
                options.filter((option) => {
                  const name = option.name || '';
                  return (
                    (name && name.toLowerCase().includes(inputValue.toLowerCase())) );
                }).slice(0, 7)
              }
              getOptionSelected={(option, value) => option.id === value.id}
            />
            </Box>
            <Box>
              <SelectTable
                cols={columns}
                rows={courses.filter(
                  (course) => !filterCourses || course.id === filterCourses.id
                )}
                onSelect={handleOnSelectCourse}
                length={courses.length}
                totalSelected={courses.filter((c) => c.selected).length}
                rowsPerPage={50}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Autocomplete
                fullWidth
                clearOnEscape
                value={filterTrails}
                options={trails}
                isOptionEqualToValue={(opt, value) => opt.id === value.id}
                getOptionLabel={(option) => `${option?.name ? option.name + ' - ' : ''}${option?.description || ''}`}
                onChange={handleFilterTrails}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    label={t('Buscar trilhas')}
                    variant="outlined"
                  />
                )}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) => {
                    const name = option.name || '';
                    const description = option.description || '';
                    return (
                      (name && name.toLowerCase().includes(inputValue.toLowerCase())) ) ||
                      (description && description.toLowerCase().includes(inputValue.toLowerCase()))

                  }).slice(0, 7)
                }
                getOptionSelected={(option, value) => option.id === value.id}
              />
            </Box>
            <Box>
              <SelectTable
                cols={columnsTrails}
                rows={trails.filter(
                  (trail) => !filterTrails || trail.id === filterTrails.id
                )}
                onSelect={handleOnSelectTrail}
                length={trails.length}
                totalSelected={trails.filter((t) => t.selected).length}
                rowsPerPage={10}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
}
