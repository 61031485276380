import api from '../api';
import querystring from 'querystring';

class CourseRegistrationData {
  getCourseInfo(id, query) {
    return api.get(
      `course-registration-fields/${id}?${querystring.stringify(query)}`
    );
  }
}

export default new CourseRegistrationData();
