export const UPDATE_JUNO_API_KEY = '@juno_api/update_api_key';
export const UPDATE_JUNO_API_KEY_SAGA = '@juno_api/update_api_key_saga';
export const SET_JUNO_API_FETCHING = '@juno_api/update_api_fetching';
export const JUNO_CHARGE_ID = '@juno_api/charge_id';
export const JUNO_CHECKOUT = '@juno_api/checkout';
export const SET_PERSONAL_DATA = '@juno_api/set_personal_data';

export function callUpdateJunoAPIKeys() {
  return {
    type: UPDATE_JUNO_API_KEY_SAGA,
  };
}

export function setJunoApiFetching(payload) {
  return {
    type: SET_JUNO_API_FETCHING,
    payload,
  };
}

export function updateJunoAPIKeys(payload) {
  return {
    type: UPDATE_JUNO_API_KEY,
    payload,
  };
}

export function setPersonalData(payload) {
  return {
    type: SET_PERSONAL_DATA,
    payload,
  };
}

export function setChargeId(payload) {
  return {
    type: JUNO_CHARGE_ID,
    payload,
  };
}
