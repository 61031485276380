import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilesData } from 'services/dataService';
import CustomHelper from '../../CustomHelperText';

const CompanyEventButton = ({ event_button, index, register }) => {
  const classes = useStyles();
  const [file, setFile] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const { t } = useTranslation(['views']);
  const fileInput = React.useRef();
  const saveFile = async (file) => {
    if (!file) {
      return;
    }
    const { data } = await FilesData.addFile(
      file,
      'company_customization_event_button' + index
    );
    setFileUrl(data?.url);
    setFile(data?.id);
  };
  const initFile = () => {
    setFileUrl(event_button?.icon?.url);
    setFile(event_button?.id_file_logo);
  };

  React.useEffect(() => {
    initFile();
  }, []);

  return (
    <>
      <Typography className={classes.colorTopSpacing} />
      <Grid item xs={12} md={4} className={classes.eventButtonSpacing}>
        <Box className={classes.colorTopSpacing}>
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              key={index}
              name={`company_events.event_buttons[${index}].title`}
              defaultValue={event_button?.title}
              label={t('views:CompanyCustomization.title')}
              inputRef={register}
            />
            <CustomHelper text={t('views:CompanyCustomization.event_title')} />
          </Box>
          <Box>
            <Grid container spacing={2} className={classes.colorTopSpacing}>
              <Grid item xs={12} md={6}>
                <CustomColorPicker
                  name={`company_events.event_buttons[${index}].title_color`}
                  register={register}
                  title={t('views:CompanyCustomization.title_color')}
                  defaultValue={event_button?.title_color}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.text_color')}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4} className={classes.eventButtonSpacing}>
        <Box className={classes.colorTopSpacing}>
          <Box>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              key={index}
              name={`company_events.event_buttons[${index}].subtitle`}
              defaultValue={event_button?.subtitle}
              label={t('views:CompanyCustomization.description')}
              inputRef={register}
            />
            <CustomHelper
              text={t('views:CompanyCustomization.event_description')}
            />
          </Box>
          <Box>
            <Grid container spacing={2} className={classes.colorTopSpacing}>
              <Grid item xs={12} md={6}>
                <CustomColorPicker
                  name={`company_events.event_buttons[${index}].subtitle_color`}
                  register={register}
                  title={t('views:CompanyCustomization.subtitle_color')}
                  defaultValue={event_button?.subtitle_color}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.text_color')}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box
          className={clsx(
            classes.headerRoot,
            classes.colorTopSpacing,
            classes.eventButtonCenter
          )}
        >
          <Box>
            <Box
              className={classes.courseIcon}
              style={{
                backgroundImage: `url(${fileUrl})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </Box>
          <Box className={classes.courseIconButton}>
            <input
              ref={fileInput}
              style={{ display: 'none' }}
              type="file"
              id="contained-logo-file"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                saveFile(event.target.files[0]);
              }}
            />
            <TextField
              name={`company_events.event_buttons[${index}].id_file_logo`}
              value={file}
              inputRef={register}
              style={{ display: 'none' }}
            />
            <TextField
              name={`company_events.event_buttons[${index}].icon.url`}
              value={fileUrl}
              inputRef={register}
              style={{ display: 'none' }}
            />
            <Button
              variant="contained"
              onClick={() => {
                fileInput.current.click();
              }}
            >
              {t('views:CompanyCustomization.change_event_button_image')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
  },

  eventButtonSpacing: {
    padding: theme.spacing(2),
  },
  eventButtonCenter: {
    justifyContent: 'center',
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  courseIconButton: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(3),
  },
  courseIcon: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    boxShadow: '0px 1px 15px 1px',
    [theme.breakpoints.down('md')]: {
      width: 80,
      height: 80,
    },
  },
}));

export default CompanyEventButton;
