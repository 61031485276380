import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 'auto',
    padding: theme.spacing(2),
  },

  graphBox: {
    height: 300,
    margin: theme.spacing(1),
  },

  marginCard: {
    marginTop: theme.spacing(1),
  },

  greenIcon: {
    backgroundColor: '#2DCE98',
  },

  redIcon: {
    backgroundColor: '#F53C56',
  },

  blueIcon: {
    backgroundColor: '#176AE6',
  },
}));
