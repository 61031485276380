import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles( theme => ({
  icons:{
    color:'#AFAFAF',
    '&:hover':{
      color:'#000000',
    }
  },
  moveIcon:{
    fontSize:20,
  },
  expandIcon:{
    fontSize:26,
  },
  label:{
    color:'#FFF',
    fontSize:15,
    borderRadius:3,
    paddingTop:2,
    paddingLeft:8,
    paddingRight:8,
    textAlign:'center',
    display:'inline-block',
    marginLeft:10,
    minHeight: 28,
  },
}))
