import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@material-ui/icons/Brightness1';
import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Paper,
} from '@material-ui/core';

import { useStyles } from './styles';
import { GroupsData } from 'services/dataService';
import { statusGroups } from 'constants/status';
import { toast } from 'react-toastify';

export default (prop) => {
  const [status, setStatus] = useState(1);
  const [name, setName] = useState('');
  const [route, setRoute] = useState('');
  const { id } = useParams();
  const { data, setData } = prop;

  const { register, handleSubmit, control } = useForm();

  const tableRef = useRef();
  const history = useHistory();

  const { t } = useTranslation([
    'toastify',
    'tables',
    'label',
    'form',
    'button',
  ]);

  return (
    <>
      <MaterialTable
        title={`${t('tables:group.role_title')}`}
        tableRef={tableRef}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        columns={[
          {
            title: `${t('tables:group.role_name')}`,
            field: 'name',
          },
          {
            title: `${t('tables:group.role_get')}`,
            field: 'method_get',
            render: (rowData, type) => (
              <Checkbox
                checked={rowData.method_get}
                onChange={() => {
                  const newPermission = data.map((elem) => {
                    if (elem.name === rowData.name) {
                      return { ...elem, method_get: !elem.method_get };
                    }

                    return elem;
                  });

                  setData(newPermission);
                }}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            ),
          },
          {
            title: `${t('tables:group.role_post')}`,
            field: 'method_post',
            render: (rowData) => (
              <Checkbox
                checked={rowData.method_post}
                onChange={() => {
                  const newPermission = data.map((elem) => {
                    if (elem.name === rowData.name) {
                      return { ...elem, method_post: !elem.method_post };
                    }

                    return elem;
                  });

                  setData(newPermission);
                }}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            ),
          },
          {
            title: `${t('tables:group.role_put')}`,
            field: 'method_put',
            render: (rowData) => (
              <Checkbox
                checked={rowData.method_put}
                onChange={() => {
                  const newPermission = data.map((elem) => {
                    if (elem.name === rowData.name) {
                      return { ...elem, method_put: !elem.method_put };
                    }

                    return elem;
                  });

                  setData(newPermission);
                }}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            ),
          },
          {
            title: `${t('tables:group.role_delete')}`,
            field: 'method_delete',
            render: (rowData) => (
              <Checkbox
                checked={rowData.method_delete}
                onChange={() => {
                  const newPermission = data.map((elem) => {
                    if (elem.name === rowData.name) {
                      return { ...elem, method_delete: !elem.method_delete };
                    }

                    return elem;
                  });

                  setData(newPermission);
                }}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            ),
          },
        ]}
        data={data}
        options={{
          search: false,
          paging: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t(
              'tables:commons.body.emptyDataSourceMessage'
            ),
            filterRow: {
              filterTooltip: t('tables:commons.body.filterRow.filterTooltip'),
            },
          },
        }}
      />
    </>
  );
};
