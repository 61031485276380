import {
  CallSplitOutlined,
  CardMembership,
  Group,
  HomeOutlined,
  TocOutlined,
  MenuBook,
  AutoStoriesIcon,
} from '@material-ui/icons';
import DvrIcon from '@material-ui/icons/Dvr';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const Translate = ({ text }) => {
  const { t } = useTranslation(['menu']);
  return <Fragment>{t(text)}</Fragment>;
};

export default [
  {
    title: 'Meus conteúdos',
    href: '/dashboard',
    icon: <HomeOutlined />,
  },
  {
    title: 'Conteúdos disponíveis',
    href: '/available-content',
    icon: <TocOutlined />,
  },
  {
    title: <span className='notranslate'><Translate text={'menu:sidebar.admin.courses_menu.library'} /></span>,
    href: '/librarystud',
    icon: <MenuBook />,
  },
  {
    title: 
    <span className='notranslate'>
      <Translate text="title:Attendance.title_create" />
    </span>,
    href: 'https://tawk.to/chat/5fc7ae13920fc91564cccdf3/1esl3c4j8',
    icon: <DvrIcon />,
  },
  {
    title: <span className='notranslate'>{'Certificados'}</span>,
    href: '/certificates',
    icon: <CardMembership />,
  },
];
