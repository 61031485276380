import { Box, Button, Grid, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FilesData } from 'services/dataService';
import CompanyCustomizationData from 'services/dataService/CompanyCustomizationData';
import history from 'services/history';
import { changeCompanyTheme } from 'store/modules/company/actions';
import CooperativeCompanyCustomization from './Cooperative';

const CompanyCustomization = () => {
  const form = useForm();
  const { handleSubmit, setValue } = form;
  const [customization, setCustomization] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const { t } = useTranslation(['views', 'toastify']);
  const edit = React.useRef(true);
  const dispatch = useDispatch();

  const getCustomization = async () => {
    try {
      const {
        company_header,
        company_banner,
        featured_content,
        company_platform,
        company_events,
        company_solutions,
        company_footer,
      } = (await CompanyCustomizationData.getCompanyCustomization()).data;

      setValue('company_header', company_header);
      setValue('company_banner', company_banner);
      setValue('featured_content', featured_content);
      setValue('company_platform', company_platform);
      setValue('company_events', company_events);
      setValue('company_solutions', company_solutions);
      setValue('company_footer', company_footer);

      setCustomization({
        company_header,
        company_banner,
        featured_content,
        company_platform,
        company_events,
        company_solutions,
        company_footer,
      });
    } catch (error) {
      if (error?.response?.status === 404) {
        edit.current = false;
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
        history.push('/dashboard');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = React.useCallback(async (data) => {
    try {
      const company = { ...data };
      if (edit.current) {
        await CompanyCustomizationData.updateCompanyCustomization(data);
        dispatch(
          changeCompanyTheme({
            ...data?.company_header,
            logo: { url: data?.header_logo_url || '' },
          })
        );

        if (!company?.company_footer) {
          if (customization?.company_footer?.id_file_logo) {
            await FilesData.deleteFile(
              customization?.company_footer?.id_file_logo || ''
            );
          }
        }
        if (
          !company?.company_events ||
          !company?.company_events?.event_buttons
        ) {
          for (const event_buttons of customization?.company_events
            ?.event_buttons) {
            if (event_buttons?.id_file_logo) {
              await FilesData.deleteFile(event_buttons?.id_file_logo || '');
            }
          }
        }
        toast.success(t('toastify:commons.toast_update'));
      } else {
        await CompanyCustomizationData.createCompanyCustomization(data);
        dispatch(
          changeCompanyTheme({
            ...data?.company_header,
            logo: { url: data?.header_logo_url || '' },
          })
        );
        toast.success(t('toastify:commons.toast_success'));
      }
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  });

  React.useEffect(() => {
    getCustomization();
  }, []);

  return (
    <Box className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!loading && (
          <Box className={classes.header}>
            <Grid container>
              <Grid item xs={7} md={9}>
                <Typography component="h2" className={classes.header}>
                  {t('views:CompanyCustomization.header_title')}
                </Typography>
              </Grid>
              <Grid item xs={5} md={3}>
                <Box className={classes.buttonContainer}>
                  <Button variant="contained" color="primary">
                    <HelpOutline fontSize="small" />
                    {t('views:CompanyCustomization.information')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box className={classes.content}>
              <CooperativeCompanyCustomization
                customizationData={customization}
                form={form}
              />
            </Box>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default CompanyCustomization;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      padding: theme.spacing(2),
    },
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    lineHeight: '100%',
    fontWeight: 500,
  },
  content: {
    paddingTop: theme.spacing(3),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
}));
