import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    minWidth: 120,
  },
  grid: {
    padding: theme.spacing(1),
  },
  fullWidth: { width: '100%' },
  Select: {
  }
}));
