import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
  },

  buttonIcon: {
    backgroundColor: theme.palette.secondary.light,
    height: 40,
    width: 40,
  },
}));
