import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Button,
  TextField,
  Typography,
  IconButton,
  Avatar,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useStyles } from './styles';

export default ({ title, number }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <Grid item container justify="space-between">
        <Grid>
          <Typography
            className={classes.title}
            color="textSecondary"
            variant="body2"
          >
            {title}
          </Typography>
          <Typography
            className={classes.info}
            color="textPrimary"
            variant="body2"
          >
            {number}
          </Typography>
        </Grid>

        <Grid>
          <Avatar className={classes.buttonIcon}>
            <AttachMoneyIcon />
          </Avatar>
        </Grid>
      </Grid>
    </Paper>
  );
};
