export default class TawkTo {
  static MountWidget()
  {
    window.Tawk_API = window.Tawk_API || {};
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.id = "TawkToWidget";
    s1.src = `${window._env_.REACT_APP_URL_TAWKTO}/${window._env_.REACT_APP_PROPERTY_ID_TAWKTO}/${window._env_.REACT_APP_KEY_TAWKTO}`;
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  }
  static HideWidget()
  {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }  }
}
