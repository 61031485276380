import querystring from 'querystring';
import { store } from 'store';
import api from '../api';

class DataServiceLessons {
  addLesson(data) {
    return api.post('lessons', data);
  }

  getLessons(params) {
    const url = 'lessons?' + querystring.stringify(params);
    return api.get(url);
  }

  setLessonsProgress(id_lesson, id_user, id_course, payload) {
    return api.put(
      `lesson-progress/${id_lesson}/${id_user}/${id_course}`,
      payload
    );
  }

  getLessonById(id) {
    const { auth, company } = store.getState();
    const data = {};
    if (!auth?.signed) {
      data.headers = {
        company: company?.domain_company,
      };
    }
    return api.get(`lessons/${id}`, data);
  }

  getNextLesson(id_course, id_user) {
    return api.get(
      `lessons/next-lesson/${id_course}?${querystring.stringify({ id_user })}`
    );
  }

  getLessonProgress(id_lesson, id_user, id_course) {
    return api.get(`lesson-progress/${id_lesson}/${id_user}/${id_course}`);
  }

  updateLesson(id, data) {
    return api.put(`lessons/${id}`, data);
  }
}

export default new DataServiceLessons();
