import api from '../api';
import FormData from 'form-data';


class DataServiceVimeo {
  async addVimeo(data, file) {
    console.log(file)
    console.log(data)

    const form = new FormData();
    form.append('file', file);
    form.append('data', data);

    return api.post('vimeo', form, { headers: {'Content-Type': 'multipart/form-data'} })
  }

  getVimeo(url) {
    return api.get('vimeo', { params: { url }, headers: {'Content-Type': 'application/json'} })
  }
}

export default new DataServiceVimeo();
