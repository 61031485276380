import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    userSelect: 'none',
    margin: theme.spacing(1, 0, 1, 0),
        background: ' #FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #9A9A9A29',
  },
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
        padding: theme.spacing(2),
      },
      actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '30%',
        minWidth: 132,
  },
  title:{
    color: '#4D4F5C',
    fontSize: '30px',
    fontWeight: 600,
  }
}));
