import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import TeamData from 'services/dataService/TeamData';
import {
  FINISH_TEAM_FORM,
  HANDLE_SELECTION,
  RESET_FORM,
  setCourses,
  setEditData,
  setFetching,
  setStudents,
  setTeam,
  setTeamId,
  setTeamModal,
  setTeamStep,
  setTrails,
  START_TEAM_FORM,
  updateTableFlag,
} from './actions';

const cleanData = () => ({
  name: '',
  description: '',
  admins: [],
  students: [],
  courses: [],
  trails: [],
});

const formatPayload = (payload) => ({
  name: payload.name,
  description: payload.description,
  admins: payload.TeamAdmins.map((adm) => ({
    id: adm.id_user,
    name: adm.User.name,
  })),
  students: payload.Users.map((u) => u.id),
  courses: payload.Courses.map((c) => c.id),
  trails: payload.Trails.map((t) => t.id),
});

function* loadTeams({ payload }) {
  yield put(setFetching(true));
  yield put(setTeamStep(0));

  try {
    const { data: fields } = yield call(TeamData.getFields);

    if (payload) {
      yield put(setTeamId(payload));
      const { data } = yield call(TeamData.getTeamById, payload);
      yield put(setEditData(data));
      const team = formatPayload(data);
      yield put(setTeam(team));
    }

    const { data } = yield select((store) => store.teams);

    const validatedTrails = fields.trails.map((t) => ({
      ...t,
      selected: data.trails.includes(t.id),
    }));

    const validatedCourses = fields.courses.map((c) => ({
      ...c,
      selected: data.courses.includes(c.id),
    }));

    const validatedUsers = fields.users.map((u) => ({
      ...u,
      selected: data.students.includes(u.id),
    }));

    yield put(setTrails(validatedTrails));
    yield put(setCourses(validatedCourses));
    yield put(setStudents(validatedUsers));

    yield put(setTeamModal(true));
  } catch (e) {
  } finally {
    yield put(setFetching(false));
  }
}

function* finishForm({ payload }) {
  const store = yield select((state) => state.teams);

  if (payload) {
    if (!store.teamId) {
      try {
        const body = createPostBody(store.data);
        yield call(TeamData.createTeam, body);
        toast.success(i18n.t('toastify:commons.toast_success'));
        yield put({ type: RESET_FORM });
        return;
      } catch (error) {
        console.error(error);
        toast.error(i18n.t('toastify:commons.toast_error'));
        return;
      }
    }

    try {
      const body = createPutBody(store.editData, store.data);
      const response = yield call(TeamData.updateTeam, store.teamId, body);
      if (response.data?.error_stack?.length <= 0) {
        toast.success(i18n.t('toastify:commons.toast_update'));
      } else {
        toast.warn(i18n.t('toastify:commons.toast_warn_update'));
      }
      yield put({ type: RESET_FORM });
    } catch (error) {
      console.log(error);
      toast.error(i18n.t('toastify:commons.toast_error'));
    }
    return;
  }
  yield put({ type: RESET_FORM });
}

function* handleSelection({ payload }) {
  const { type, ids, field } = payload;
  const store = yield select((state) => state.teams);
  const { data } = store;

  // Busca os ids dos campos selecionados
  let fieldsDataId = JSON.parse(JSON.stringify(data[field] || []));

  // verifica se foi escolhido selecionar apenas 1 ou vários
  if (type === 'single') {
    // remove ou adiciona o registro
    if (fieldsDataId.filter((s) => s.includes(ids))?.length > 0) {
      fieldsDataId = fieldsDataId.filter((s) => !ids.includes(s));
    } else {
      fieldsDataId.push(...ids);
    }

    // formata a informação a atualiza os campos de acordo com o campo escolhido
    const newStoreData = store[field].map((s) => ({
      ...s,
      selected: fieldsDataId.includes(s.id),
    }));

    switch (field) {
      case 'students':
        yield put(setStudents(newStoreData));
        break;
      case 'trails':
        yield put(setTrails(newStoreData));
        break;
      case 'courses':
        yield put(setCourses(newStoreData));
        break;
    }
    yield put(
      setTeam({
        ...data,
        [field]: newStoreData.filter((s) => s.selected).map((s) => s.id),
      })
    );
    return;
  }

  // seleciona ou remove a seleção de todos
  const selectAll = !(fieldsDataId.length === store[field].length);

  switch (field) {
    case 'students':
      yield put(
        setStudents(store[field].map((s) => ({ ...s, selected: selectAll })))
      );
      break;
    case 'trails':
      yield put(
        setTrails(store[field].map((s) => ({ ...s, selected: selectAll })))
      );
      break;
    case 'courses':
      yield put(
        setCourses(store[field].map((s) => ({ ...s, selected: selectAll })))
      );
      break;
  }

  yield put(
    setTeam({
      ...data,
      [field]: selectAll ? store[field].map((s) => s.id) : [],
    })
  );
  return;
}

const createPostBody = (data) => ({
  id_team: '',
  name: data.name,
  description: data.description,
  status: true,
  tags: [],
  admins: data.admins.map((a) => a.id),
  users: data.students.map((u) => u),
  trails: data.trails.map((t) => t),
  courses: data.courses.map((c) => c),
});

const createPutBody = (oldTeam, data) => ({
  add_users: data.students.filter(
    (it) => !oldTeam.Users.map((u) => u.id).includes(it)
  ),
  add_courses: data.courses.filter(
    (it) => !oldTeam.Courses.map((c) => c.id).includes(it)
  ),
  add_trails: data.trails.filter(
    (it) => !oldTeam.Trails.map((t) => t.id).includes(it)
  ),
  add_admins: data.admins
    .map((a) => a.id)
    .filter((it) => !oldTeam.TeamAdmins.map((t) => t.id_user).includes(it)),
  remove_users: oldTeam.Users.map((u) => u.id).filter(
    (it) => !data.students.includes(it)
  ),
  remove_courses: oldTeam.Courses.map((c) => c.id).filter(
    (it) => !data.courses.includes(it)
  ),
  remove_trails: oldTeam.Trails.map((t) => t.id).filter(
    (it) => !data.trails.includes(it)
  ),
  remove_admins: oldTeam.TeamAdmins.map((t) => t.id_user).filter(
    (it) => !data.admins.map((a) => a.id).includes(it)
  ),
  add_tags: [],
  remove_tags: [],
  update_team_data: {
    name: data.name,
    description: data.description,
  },
});

function* resetForm() {
  yield put(setTeamId(null));
  yield put(setTeamStep(0));
  yield put(setEditData(null));
  yield put(setTrails([]));
  yield put(setCourses([]));
  yield put(setStudents([]));
  yield put(setTeam(cleanData()));
  yield put(setTeamModal(false));
}

export default all([
  takeLatest(FINISH_TEAM_FORM, finishForm),
  takeLatest(START_TEAM_FORM, loadTeams),
  takeLatest(HANDLE_SELECTION, handleSelection),
  takeLatest(RESET_FORM, resetForm),
]);

/* 
const getFields = async () => {
    try {
      const response = await TeamData.getFields();
      setFields(response.data);
    } catch (error) {
      console.error(error);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };
*/
