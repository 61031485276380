import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTeamStep } from 'store/modules/teams/actions';


export default function TeamHeader() {
  const { step } = useSelector((state) => state.teams);
  const { t } = useTranslation([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const titles = [
    {
      id: 'teamConfig',
      title: t('form:Team.Titles.step1')
    },
    {
      id: 'teamUsers',
      title: t('form:Team.Titles.step2')
    },
    {
      id: 'teamCourses',
      title: t('form:Team.Titles.step3')
    },
  ];
  return (
    <Box className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {titles.map((title, index) => (
          <Grid key={`${title.id}.${index}`} item xs={12} md={4}>
            <Typography
               onClick={() => {
                dispatch(setTeamStep(titles.findIndex((c) => c.id === title.id)));
              }}
              style={{ cursor: 'pointer', pointerEvents: 'auto' }}
              className={step !== index ? classes.title : classes.selectedTitle}
            >
              {title.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(1) },
  title: { textAlign: 'center' },
  selectedTitle: { textAlign: 'center', fontWeight: 'bold' },
}));
