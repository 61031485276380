import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import React from 'react';
import theme from 'theme';

const CourseGoal = ({ objectives }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxTextGoal} >
      <Interweave content={objectives} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  boxTextGoal: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
    '& span': {
      fontSize: '15px !important',
      fontFamily: 'Montserrat !important',
      color: '#000 !important',
      backgroundColor: 'transparent !important',
      fontWeight: 400
    }
  }
}));

export default CourseGoal;
