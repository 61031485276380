import api from '../api';

class DataServiceRating {
  addRating(data) {
    return api.post('/rating', data);
  }; 
  
  hasRating(id_user, courseId) {
    return api.get(`/rating-open-modal/${id_user}/${courseId}`);
  };

  addRatingPlatform(data) {
    return api.post('/rating-platform', data);
  } 
  
  hasRatingPlatform(id_user, dateNow) {
    return api.get(`/rating-open-modal-platform/${id_user}/${dateNow}`);
  }
}

export default new DataServiceRating();
