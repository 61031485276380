import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@material-ui/icons/Brightness1';
import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useStyles } from './styles';
import { UserData } from 'services/dataService';
import { statusUser } from 'constants/status';
import { HandleDelete } from 'components/';
import { toast } from 'react-toastify';
import { localizationTable, options } from 'constants/table';
import { Search } from '@material-ui/icons';

export default ({
  listDataChange
}) => {
  const [status, setStatus] = useState(1);
  const [filter, setFilter] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [route, setRoute] = useState('');

  const { register, handleSubmit, control } = useForm();

  const tableRef = useRef();
  const history = useHistory();

  const { t } = useTranslation(['toastify', 'tables']);
  const classes = useStyles();

  const handleModalOpen = useCallback(({ id }) => {
    setModalStatus(true);
    setRoute(`users/${id}`);
  });

  const handleProfile = useCallback(({ id }) => {
    history.push(`users/${id}/profile`);
  }, []);

  const onSubmit = (data) => {
    setFilter(data.filter);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  return (
    <>
    <div className="notranslate">
    <MaterialTable
        title={`${t('tables:user.title')}`}
        tableRef={tableRef}
        columns={[
          { title: `${t('tables:commons.name_column')}`,
            sorting: false,
            field: 'name'
          },
          {
            title: `${t('tables:user.created_at_column')}`,
            field: 'createdAt',
            sorting: false,
            render: (rowData) =>
              format(parseISO(rowData.createdAt), 'dd/MM/yyyy'),
          },
          {
            title: `${t('tables:commons.status_column')}`,
            field: 'status',
            sorting: false,
            render: (rowData) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CircleIcon
                  style={{
                    ...statusUser[rowData.status]?.style,
                    fontSize: '0.8rem',
                  }}
                  fontSize="default"
                />
                <span
                  style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}
                >
                  {t(statusUser[rowData.status]?.title)}
                </span>
              </div>
            ),
          },
          {
            title: `${t('tables:user.group_column')}`,
            field: 'group.name',
            sorting: false,
            render: (rowData) => rowData.group.name,
          },
          {
            title: `${t('tables:user.telephone_column')}`,
            field: 'cellphone',
            sorting: false,
            render: (rowData) =>
              rowData.cellphone ? rowData.cellphone : 'Sem contato',
          },
          { title: `${t('tables:commons.email_column')}`,
            field: 'email',
            sorting: false,
          },
        ]}
        data={async (query) => {
          try {
            const response = await UserData.getUsers({
              page: query.page + 1,
              limit: query.pageSize,
              status,
              filter,
            });
            return {
              data: response.data.users,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        actions={[
          {
            icon: 'visibility',
            tooltip: `${t('tables:commons.actions.profile_action')}`,
            onClick: (event, rowData) => {
              handleProfile(rowData);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
          },
        ]}
        components={{
          search: false,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:commons.input_search')}
                        name="filter"
                        type="text"
                        variant="outlined"
                        defaultValue={filter || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <FormControl style={{ minWidth: 130 }}>
                        <InputLabel id="status">
                          {t('form:commons.input_status')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={1}>
                                {t(statusUser[1]?.title)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t('form:Status.status_blocked')}
                              </MenuItem>
                              <MenuItem value={3}>
                                {t(statusUser[3]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={status ? status : 1}
                          value={status ? status : 1}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        type="submit"
                      >
                        <Search style={{ marginRight: '5px' }} />
                        {t('button:commons.btn_advanced_filter')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
      />
    </div>
   
      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
        functionDelete={listDataChange}
      />
    </>
  );
};
