import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  grid: {
    [theme.breakpoints.between(1280, 1400)]: {
      maxWidth: '91.66%',
      flexBasis: '91.66%',
    }
  }
}));
