import React from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import Alert from '@material-ui/lab/Alert'


export default () => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards'])
  const classes = useStyles()

  return (
    <Alert className={classes.root} severity="error">Não foi possível carregar conteúdo</Alert>
  )
}
