import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: theme.spacing(3),
  },

  textGrid: { paddingTop: 0 },
  TextField: { width: '100%', marginTop: theme.spacing(2) },

  SelectField: { width: '100%' },
}));
