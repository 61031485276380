import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  media: {
    height: 146,
  },
  title: {
    textAlign: 'left',
    fontSize: 13,
    color: '#000000',
    fontWeight: 500,
  },
  text: {
    textAlign: 'left',
    fontSize: 10,
    color: '#000000',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  card: {
    width: 290,
    height: 270,
    border: '1px solid #707070',
    borderRadius: 8,
    boxShadow: '-2px 4px 18px #cac3c3',
  },
  cardContent: {
    padding: 9,
    height: 146,
    position: 'relative',
  },
  text: {
    height: '100%',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  carrossel: {
    position: 'relative',
  },
  arrowLeft: {
    position: 'absolute',
    top: 85,
    left: -15,
    border: 'none',
    background: 'transparent',
    outline: 'none',
    cursor: 'pointer',
  },
  arrowRight: {
    position: 'absolute',
    top: 85,
    right: -15,
    border: 'none',
    background: 'transparent',
    outline: 'none',
    cursor: 'pointer',
  },
  dotGroup: {
    position: 'absolute',
    top: 300,
    '& button': {
      width: 20,
      height: 20,
      borderRadius: 20,
      marginRight: 10,
    },
  },
  favoritIcon: {
    position: 'absolute',
    bottom: 25,
    left: -10,
    color: '#FF0000',
  },
  continue: {
    position: 'absolute',
    bottom: 30,
    right: 10,
    backgroundColor: '#2DCE98',
    width: 80,
    height: 20,
    borderRadius: 3,
    textTransform: 'capitalize',
    fontSize: 12,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2DCE98',
      color: '#fff',
    },
  },
  progress: {
    position: 'absolute',
    bottom: 37,
    left: 60,
    width: 90,
    height: 5,
    border: '1px solid gray',
    borderRadius: 5,
    backgroundColor: '#eee',
    // '& > div':{
    //   backgroundColor:'green',
    //   borderRadius:5,
    //   height: '100%',
    // }
  },
  progressLevel: {
    position: 'absolute',
    bottom: 34,
    right: 112,
    fontSize: 10,
    color: '#000000',
  },
  slide: {
    width: '320px  !important',
    height: '270px  !important',
  },
}));
