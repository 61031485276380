import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useState , useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import ListItem from '@material-ui/core/ListItem';
import pdfPreviewStyles from './styles';
import {
  LessonContentData,
  LessonData,
  ModuleData,
} from 'services/dataService';

export default function CertificatePdfPreview(props) {
  const { formValues, showPeriod, showDocStudent, background, idCourse } = props;
  const classes = pdfPreviewStyles(formValues);

  const { t } = useTranslation(['form', 'toastify']);

  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [arrayLesson, setArrayLesson] = useState([]);
  const [offset, setOffset] = useState(0);
  const [reload, setReload] = useState(false);


  const getStringDate = () => {
    const months = t('form:commons.month_names', { returnObjects: true });
    const date = new Date();

    return `${
      formValues.location?.length > 0 ? formValues.location + ', ' : ''
    }${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`;
  };

  const getExpirationDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + +formValues.expiration_date);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const getCourseHours = () => {
    const hours = parseFloat(props?.courseHours).toFixed(1);
    return `Carga horária de ${hours || `X`} hora(s)`
  }

  const getCpfUser = () => {
    const cpf = props?.userDoc;
    return !cpf ? '' : `CPF ${cpf}`
  };

  //  ID_Course = '46a37e86-ba1a-4f43-8d6e-25b4cb8c07f8'
  useEffect(() => {
    (async () => {
      try {
        const response = await ModuleData.getModuleByIdCourse(props?.idCourse);
        const arrayModule = response.data.modules
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
          .map((module, index) => {
            return {
              index,
              id: module?.id || null,
              title: module.title,
            };
          });

        setModules(arrayModule);
        // console.log('Dados Modules: ', arrayModule);


        const arrayLes = response.data.modules.map((module, index) => {
          return module.lessons.map((lesson, ind) => {
            return {
              id_lesson: lesson.id,
              id_course: lesson.id_course,
              id_module: lesson.id_module,
              title: lesson.title,
            };
          });
        });
  
        setArrayLesson(arrayLes.flat());
  
        setOffset(offset + arrayLes.length);
      } catch (err) {
        console.log('Erro ', err);
      }
    })();
  
    setLoading(false);
    setReload(false);
  }, [reload]);



  return (
    <React.Fragment>
      <div
        style={{
          overflow: 'hidden',
          height: '600px',
          width: '850px',
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          backgroundImage: `url(${background})`,
        }}
      >
        <p className={classes.topHeader}>
          {props?.courseName ||
            'Gestão com Pessoas e Produtividade nas Organizações Contemporâneas'}
        </p>
        {showPeriod && (
          <p style={{ width: '100%', textAlign: 'center' }}>
            Período de 17/10/2020 a 16/11/2020
          </p>
        )}
        <p className={classes.topName}>
          {props?.userName || 'Nome do usuário'}
        </p>
        {showDocStudent && (
          <p className={classes.topDocument}>
            { getCpfUser() }
          </p>
        )}
        {formValues.footer_message?.length ? (
          <p className={classes.topMessage}>{formValues.footer_message}</p>
        ) : (
          ''
        )}
        <p className={classes.topFooterMessage}>{getStringDate()}</p>
        {formValues.expiration_date?.length > 0 && (
          <p className={classes.topExpirationDate}>
            Certificado válido até {getExpirationDate()}
          </p>
        )}
        <p className={classes.topHoursSpacing}>
          {getCourseHours()}
        </p>
      </div>
       {/* TODO - Verso Certificado */}
       <div
        style={{
          padding: '20px',
          marginTop: '20px',
          overflow: 'hidden',
          height: '600px',
          width: '850px',
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          display: 'none'
        }}
      >
        <p style={{ margin: '6px 0' }}>
          {props?.courseName ||
            'Conteúdo do Curso'}
        </p>

        {modules.map(module => (
          <div key={module.id}>
            <h6 style={{ margin: '2px 0' }}>{module.title}</h6>
            <Grid container spacing={0} >
              {arrayLesson
                .filter(lesson => lesson.id_module === module.id)
                .map((lesson, index) => (
                  <Grid item xs={12} sm={6} md={3} direction="column" key={index}>
                    <div>
                      <p style={{ fontSize: '9px' }}>{lesson.title}</p>
                    </div>
                  </Grid>
                ))}
            </Grid>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
