import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  Box,
  Typography,
} from '@material-ui/core';

import { Save } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { CourseData, CategoryData, UserData } from 'services/dataService';
import { Autocomplete } from '@material-ui/lab';
import NewTeacher from 'components/NewTeacher';

export default ({ className, isModalInsert, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showTeacherContent, setShowTeacher] = React.useState(false);
  const [idTeacher, setIdTeacher] = React.useState('');
  const [teacherCombo, setTeacherCombo] = React.useState([]);
  const [owner, setOwner] = useState(false);
  const [text, setText] = useState('');
  const { id } = useParams();

  const { register, handleSubmit, errors, setValue, control } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);


  const handleKeyPress = (event) => {
    if (event.key === '/' || event.key === '\\') {
      event.preventDefault();
    }
  }

  const getCourseTeacher = async () => {
    const { users: teachers } = (
      await UserData.getUsers({
        id_group: '7c656a2d-862f-46d7-928c-b84599faee61',
      })
    ).data;
    setTeacherCombo(
      teachers?.map(({ id, name }) => {
        return { id, name };
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCategories = await CategoryData.getCategories();
        setCategories(responseCategories.data.categories);
      } catch (err) {
        history.push('/classroomcourses');
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };

    fetchData();
    getCourseTeacher();
    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
    const formValues = { ...data, teacher_id: idTeacher, online_classroom: false, workload: 0, status: 2 };
    console.log('data', formValues)


    try {
      if (id) {
        await CourseData.updateCourse(id, formValues);
        toast.success(t('toastify:commons.toast_update'));
        history.push('/classroomcourses/manage');
      } else {
        const course = await CourseData.addCourse(formValues);
        toast.success(t('toastify:commons.toast_success'));
        history.push(`/classroomcourses/manage`);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error'));
      }
    }
  };

  const titlePage = t('title:Courses.title_create');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:Courses.input_name')}
                  helperText={
                    errors.name?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Courses.input_name'),
                    })
                  }
                  onChange={(e) => setText(e.target.value)}
                  onKeyPress={handleKeyPress}
                  name="name"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.name}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="id_category">
                    {t('form:Categories.input_category')}
                  </InputLabel>

                  <Controller
                    as={
                      <Select fullWidth labelWidth={t('form:Categories.input_category').length * 8.5}>
                        {categories &&
                          categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                      </Select>
                    }
                    name="id_category"
                    helperText={
                      errors.id_category?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Courses.input_category'),
                      })
                    }
                    rules={{ required: 'Campo obrigatório' }}
                    control={control}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.id_category}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {showTeacherContent && (
              <NewTeacher register={register} name="course_teacher" />
            )}
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              size="large"
              onClick={() => history.goBack()}
            >
              {t('button:commons.btn_back')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              <Save style={{ marginRight: '10px' }} />
              {t('button:commons.btn_save')}
            </Button>
          </CardActions>
        </form>
      )}
    </Card>
  );
};
