import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';

import { Autocomplete } from '@material-ui/lab';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Save, Delete, AddCircle } from '@material-ui/icons';

import { toast } from 'react-toastify';

import {
  TagData,
  TagsCourseData,
  CourseData,
  CategoryData,
  TrailData,
} from 'services/dataService';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { TabPanel, a11yProps } from '../../../../components/TabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default ({ className, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [radio, setRadio] = useState(null);
  const [tab, setTab] = useState(0);
  const [trail, setTrail] = useState([]);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [autoCompleList, setAutoCompleList] = useState([]);
  const [autoCompleValue, setAutoCompleValue] = useState([]);
  const [tagsCourse, setTagsCourse] = useState([]);

  const { id } = useParams();

  const handleValueAutoCompleteList = {
    0: () => setAutoCompleList(courses),
    1: () => setAutoCompleList(categories),
    2: () => setAutoCompleList(trail),
  };

  const handleRadioChange = (event) => {
    setRadio(event.target.value);
    setTab(Number(event.target.value));
    setAutoCompleValue(null);
    handleValueAutoCompleteList[event.target.value]();
  };

  const { register, handleSubmit, errors, setValue, control } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'tables',
  ]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await TagData.getTagsById(id);

          const { title, description } = response.data;

          setValue([{ title }, { description }]);

          const responseTagCourse = await TagsCourseData.getTagsCourse(id);

          setTagsCourse(responseTagCourse.data);

          const resposneCourse = await CourseData.getCourses();
          await resposneCourse.data.courses.map((element) => {
            setCourses((courses) => [
              {
                id: element.id,
                name: element.course.name,
              },
              ...courses,
            ]);
          });

          const resposneCategory = await CategoryData.getCategories();
          await resposneCategory.data.categories.map((element) => {
            setCategories((categories) => [
              {
                id: element.id,
                name: element.name,
              },
              ...categories,
            ]);
          });

          const resposneTrails = await TrailData.getTrails();
          await resposneTrails.data.trails.map((element) => {
            setTrail((trail) => [
              {
                id: element.id,
                name: element.name,
              },
              ...trail,
            ]);
          });
        } catch (err) {
          history.push('/tags');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }    
        }
      };
      fetchData();
    }

    setLoading(false);
  }, [id, t]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setRadio(newValue.toString());
    handleValueAutoCompleteList[newValue]();
  };

  const handleTagCourse = async () => {
    try {
      const response = await TagsCourseData.addTagsCourse({
        id_tag: id,
        type: Number(radio) + 1,
        id_relation: autoCompleValue.id,
      });
      setTagsCourse([
        {
          id: response.data.id,
          type: response.data.type,
          name: autoCompleValue.name,
        },
        ...tagsCourse,
      ]);
      toast.success(t('toastify:commons.toast_success'));
      setAutoCompleValue(null);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
      // toast.warning(t('toastify:commons.toast_duplicated_item'));
    }
  };

  const handleRemove = (id) => {
    try {
      TagsCourseData.deleteTagsCourse(id);
      setTagsCourse(tagsCourse.filter((item) => item.id != id));
      toast.success(t('toastify:commons.toast_success'));
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      if (id) {
        await TagData.updateTags(id, data);
        toast.success(t('toastify:commons.toast_update'));
      } else {
        await TagData.addTags(data);
        toast.success(t('toastify:commons.toast_success'));
      }

      history.push('/tags');
      setValue([{ title: '' }, { description: '' }]);
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        const toastMsg = id ? 'toast_update_error' : 'toast_error';
        toast.error(t(`toastify:commons.${toastMsg}`));
      }
    }
  };

  const titlePage = id
    ? t('title:Tags.title_edit')
    : t('title:Tags.title_create');

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={titlePage} />
        <Divider />
        {!loading && (
          <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Tags.input_title')}
                    helperText={
                      errors.title?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Tags.input_title'),
                      })
                    }
                    name="title"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.title}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Tags.input_description')}
                    name="description"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: false })}
                    error={!!errors.description}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
      {!loading && id && (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title={t('form:Tags.link_tag_title')} />
          <Divider />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item md={12} xs={12} lg={12}>
                <div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {t('form:Tags.type_title')}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="0"
                      value={radio}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label={t('form:Tags.type_course')}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label={t('form:Tags.type_category')}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label={t('form:Tags.type_trail')}
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8} lg={8}>
                <Autocomplete
                  id="comboBoxCourses"
                  options={autoCompleList}
                  getOptionLabel={(option) => option.name}
                  value={autoCompleValue}
                  onChange={(event, newValue) => {
                    setAutoCompleValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('form:Tags.input_title')}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <CardActions className={classes.actions}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    size="large"
                    onClick={handleTagCourse}
                  >
                    {t('button:Trail.btn_courses')}
                    <AddCircle />
                  </Button>
                </CardActions>
              </Grid>
            </Grid>
            <Paper square>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label={t('tables:commons.tab')}
              >
                <Tab label={t('form:Tags.tab_course')} />
                <Tab label={t('form:Tags.tab_category')} />
                <Tab label={t('form:Tags.tab_trail')} />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <div>
                  <List>
                    {tagsCourse &&
                      tagsCourse
                        .filter((element) => element.type === 1)
                        .map((course) => (
                          <>
                            <ListItem key={course.id}>
                              <ListItemText primary={course.name} />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete">
                                  <Delete
                                    color="error"
                                    onClick={() => handleRemove(course.id)}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                  </List>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div>
                  <List>
                    {tagsCourse &&
                      tagsCourse
                        .filter((element) => element.type === 2)
                        .map((category) => (
                          <>
                            <ListItem key={category.id}>
                              <ListItemText primary={category.name} />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete">
                                  <Delete
                                    color="error"
                                    onClick={() => handleRemove(category.id)}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                  </List>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <div>
                  <List>
                    {tagsCourse &&
                      tagsCourse
                        .filter((element) => element.type === 3)
                        .map((trail) => (
                          <>
                            <ListItem key={trail.id}>
                              <ListItemText primary={trail.name} />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete">
                                  <Delete
                                    color="error"
                                    onClick={() => handleRemove(trail.id)}
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                  </List>
                </div>
              </TabPanel>
            </Paper>
          </CardContent>
          <Divider />
        </Card>
      )}
    </>
  );
};
