import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(1, 2, 1, 2),
    userSelect: 'none',
    boxShadow: ' 0px 3px 6px #9A9A9A4D',
  },
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '30%',
    alignItems: 'center',
  },
  box: {
    width: 'auto',
  },
  gridIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paddingClass: {
    padding: theme.spacing(1),
  },
  title:{
    color: '#4D4F5C',
    fontSize:'20px',
    fontWeight:600,
  },
  contents:{
    color: '#000000',
    fontSize:'16px',
    fontWeight:'normal',
    marginLeft:5,
  },
  notAllowed: {
    cursor: 'not-allowed',
  },
  info: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#9E9E9E',
    padding: theme.spacing(1),
    '& p': {
      color: '#9E9E9E',
    }
  },
}));
