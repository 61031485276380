import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
const WelcomePageExternalCourses = ({ featured_courses, customization }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views', 'form']);

  const [search, setSearch] = React.useState('');
  const [ctaButtons, setCtaButtons] = React.useState([]);
  const setButtons = () => {
    setCtaButtons(customization?.ctaButtons || []);
  };
  React.useEffect(() => {
    setButtons();
  }, []);

  return (
    <>
      {featured_courses?.length > 0 && customization?.quantity > 0 && (
        <Grid container justify="center" className={classes.root}>
          <Typography className={classes.title}>
            {t('views:Welcome.featured_content')}
          </Typography>
          {customization?.show_search && (
            <Grid container justify="center">
              <Grid item xs={12} md={3}>
                <TextField
                  className={classes.inputSearch}
                  fullWidth
                  placeholder={t('form:commons.input_search')}
                  variant="outlined"
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSearch(e.target.value);
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: 18,
                      backgroundColor: theme.palette.white,
                      borderRadius: 10,
                      boxShadow: '0 8px 16px 0 rgba(42, 46, 67, 7%)',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className={classes.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" md={10} sm={12} justify="center" className={classes.boxCourses}>
            {featured_courses
              ?.slice(0, customization?.quantity)
              ?.filter((course) => {
                if (search === '') {
                  return true;
                }
                return course?.name
                  ?.toLowerCase()
                  ?.includes(search.toLowerCase());
              })
              ?.sort((a, b) => (a?.index > b?.index ? 1 : -1))
              ?.map(({ name, description, id_company_course, thumbnail }) => (
                <Grid item xs={12} sm={6} md={5} lg={5} xl={5} className={classes.eachCard}>
                  <Box className={classes.card}>
                    <Box
                      className={classes.mediaBox}
                      style={{ backgroundImage: `url(${thumbnail})` }}
                    >
                    </Box>
                    <Divider />
                    <Box className={classes.boxTitleDescription}>
                      <Box className={classes.titleBox}>{name}</Box>
                      <Box className={classes.descriptionBox}>
                        <Interweave content={description} />
                      </Box>
                    </Box>
                    <Box className={classes.buttonBox}>
                      <Link
                        target="_blank"
                        href={`/course/${id_company_course}/enroll`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: theme.palette.info.custom,
                            color: theme.palette.white,
                          }}
                        >
                          {t('views:Welcome.card_start')}
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Grid container justify="center">
          {customization?.ctaButtons
            ?.reverse()
            ?.map(({ text, text_color, color, border_color, link }) => (
              <Grid item lg={2} md={3} sm={4} xs={12} align="center" className={classes.gridBtn}>
                <Link
                  target="_blank"
                  href={link}
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{
                      backgroundColor: color,
                      borderStyle: 'solid',
                      borderColor: border_color,
                      padding: theme.spacing(2),
                    }}
                    className={classes.buttons}
                  >
                    <Typography
                      component="span"
                      style={{ color: text_color }}
                    >
                      {text}
                    </Typography>
                  </Button>
                </Link>
              </Grid>
            ))}
            </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  boxCourses: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  topSpacing: {
    paddingTop: theme.spacing(4),
  },
  cardTopSpacing: {
    paddingTop: theme.spacing(1),
  },
  title: {
    textAlign: 'center',
    fontSize: 35,
    fontWeight: 300,
    letterSpacing: 1.1,
    lineHeight: '120%',
    marginTop: theme.spacing(4)
  },
  inputSearch: {
    marginTop: theme.spacing(3),
    '& fieldset': {
      borderColor: '#DCDCDC'
    },
  },
  icon: {
    '& path': {
      color: '#9E9E9E !important'
    }
  },
  eachCard: {
    maxWidth: 350,
    margin: '16px 8px !important',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 385
    }
  },
  card: {
    borderRadius: 8,
    boxShadow: '0px 10px 40px 0px #2A2E4316',
    width: '100%',
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 350,
    }
  },
  mediaBox: {
    height: '13rem',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  boxTitleDescription: {
    height: '100%',
    maxHeight: 150,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1),
      minHeight: 150
    }
  },
  titleBox: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    textAlign: 'start',
    padding: theme.spacing(1),
    fontWeight: 800,
    fontSize: '1rem',
    padding: '8px 24px 8px 16px',
  },
  descriptionBox: {
    flex: 1,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 111,
    lineHeight: '1.19rem',
    '& span': {
      color: '#000 !important',
      fontSize: '.9rem !important',
      fontFamily: 'Montserrat !important',
      backgroundColor: 'transparent !important',
    },
    [theme.breakpoints.up('md')]: {
      '&::-webkit-scrollbar': {
        width: '3px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#d9d9d9',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: '8px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
    }
  },
  buttons: {
    maxWidth: 250,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    },
    '& span': {
      fontSize: 18,
    },
    '&:hover': {
      boxShadow: '0px 8px 25px 0px #2A2E4329',
    }
  },
  gridBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonBox: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default WelcomePageExternalCourses;
