import React from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  finishTeamForm,
  setTeamModal,
  setTeamStep,
} from 'store/modules/teams/actions';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos, Save } from '@material-ui/icons';
import TeamForm from './form/index';

export default function TeamModal() {
  const { open, step, fetching } = useSelector((store) => store.teams);
  const { t } = useTranslation(['button', 'form']);
  const classes = useStyles();

  const dispatch = useDispatch();

  const [showConfirmation, setShowConfirmation] = React.useState(false);


  const handleClickConfirmSave = () => {
   
    dispatch(finishTeamForm(true));
    setShowConfirmation(false);
  };

  const handleClickSave = () => {
    if (step < 2) {
        dispatch(setTeamStep(step + 1));
      return;
    }
  
    setShowConfirmation(true);
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    if (step === 0) {
      dispatch(finishTeamForm(false));
      return;
    }

    dispatch(setTeamStep(step - 1));
  };
  return (
    <Fragment>
      <Backdrop open={fetching} style={{ zIndex: 99999 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        fullWidth
        maxWidth="lg"
        scroll="body"
        open={open}
        onClose={handleClose}
      >
        <DialogContent className={classes.modalContent}>
          <TeamForm />
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            {t(step === 0 ? 'form:Actions.cancel' : 'button:commons.btn_back')}
          </Button>
          <Button
            size="large"
            startIcon={step < 2 ? <ArrowForwardIos /> : <Save />}
            onClick={handleClickSave}
            variant="contained"
            color="primary"
          >
            {t(
              step < 2 ? 'button:commons.btn_foward' : 'button:commons.btn_save'
            )}
          </Button>
        </DialogActions>
            <Dialog
            open={showConfirmation}
            onClose={() => setShowConfirmation(false)}
          >
            <DialogTitle>Confirmação</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deseja realmente salvar os dados?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowConfirmation(false)}>Cancelar</Button>
              <Button onClick={handleClickConfirmSave} color="primary">
                Salvar
              </Button>
            </DialogActions>
          </Dialog>
        </Dialog>              
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  modalContent: {
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: '1em',
    },
  },
  fullWidthSpan: {
    display: 'block',
    lineHeight: '100%',
    width: '100%',
  },
  titlespan: {
    fontSize: 'larger',
    fontWeight: 700,
  },
  adminContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
