import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles( theme => ({
  table: {
    width:'80%',
    margin:'32px auto',
    boxShadow: '0px 3px 6px #AAAAAA80',
    borderRadius: 10,
  },
  tableContainer:{
    boxShadow: '0px 3px 6px #AAAAAA80',
    borderRadius: 10,
  },
  pageTitle:{
    marginTop:32,
    marginLeft:50,
    fontSize:35,
    fontWeight:'normal',
    color:'#4D4F5C',
  },
  tableTitle:{
    color: '#000000',
    fontSize:24,
    marginLeft:24,
    marginTop:20,
    marginBottom:23,
    fontWeight:500,
  },
  tableHeader:{
    backgroundColor:'transparent'
  },
  tableTh:{
    color:"#000000",
    fontSize:14,
    fontWeight:500,
  },
}))
