import {
  Box,
  Chip,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import useWindowDimensions from 'customHooks/DimensionHook';
import { Rating } from '@material-ui/lab';
import clsx from 'clsx';
import Interweave from 'interweave';
import { useParams } from 'react-router-dom';

const CourseDescription = ({ data, icon, onLike }) => {
  const { width } = useWindowDimensions();
  const classes = useStyles();

  return (
    <Box className={width > 960 ? classes.root : classes.mobileRoot}>
      <Grid container direction="row" alignContent="center" alignItems="center">
        <Grid item xs={10} md={10}>
          <Typography component="span" className={classes.courseTitle}>
            {data?.name}
          </Typography>
        </Grid>
        {/* <Grid item xs={2} md={2}>
          <IconButton
            onClick={() => {
              if (onLike) {
                onLike(!data?.liked_course);
              }
            }}
            className={classes.likeButton}
          >
            {data?.liked_course ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </Grid> */}
      </Grid>

      <Grid container>
        {/* <Grid item xs={12}>
          <Rating
            className={classes.containerSpacing}
            name="read-only"
            precision={0.5}
            value={2.5}
            readOnly
          />
        </Grid> */}
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box className={classes.containerSpacing}>
            <Chip
              className={classes.categories}
              label={data?.category}
              color="secondary"
            />
            {data?.subcategory && (
              <Chip
                className={clsx(classes.categories, classes.lastChip)}
                label={data?.subcategory}
                color="secondary"
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.detailsContainerSpacing}>
        <Grid item xs={12} md={4} className={classes.gridImage}>
          <img
            className={classes.logo}
            src={icon ? icon : '/images/no-image.png'}
          />
        </Grid>
        <Grid item xs={12} md={7} className={classes.overflowDescription}>
          <Typography className={classes.courseDescription}>
            <Interweave content={data?.description} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
    position: 'relative',
  },
  mobileRoot: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(2),
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  courseTitle: {
    color: theme.palette.white,
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '100%',
  },
  likeButton: {
    color: theme.palette.error.main,
    padding: 5,
  },
  containerSpacing: {
    marginTop: theme.spacing(2),
  },
  categories: { minWidth: 120 },
  lastChip: {
    marginLeft: theme.spacing(4),
  },
  overflowDescription: {
    '& p': {
      paddingRight: 10,
    },
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#696969',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#b0b0b0',
      borderRadius: '8px',
   }
  },
  detailsContainerSpacing: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  courseDescription: {
    color: theme.palette.default.contrastText,
    '& span': {
      '& p': {
        marginBottom: theme.spacing(1),
        '& span': {
          color: '#fff !important',
          fontSize: '.9rem !important',
          fontFamily: 'Montserrat !important',
          backgroundColor: 'transparent !important',
        }
      }
    }
  },
  gridImage: {
      
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
      marginRight: '18%',
    },
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    maxWidth: 262,
    maxHeight: 152,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      maxHeight: 150,
      paddingRight: theme.spacing(2)
    }
  },
  [theme.breakpoints.up('md')]: {
    overflowDescription: {
      overflow: 'auto',
      height: 135,
      marginTop: 11,
      padding: '0 10px !important',
      '& p': {
        paddingRight: 5,
      }
    },
  },
}));

export default CourseDescription;
