import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import { useStyles, StyledMenu, StyledMenuItem } from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SaveIcon from '@material-ui/icons/Save';
import { CustomFieldsData } from 'services/dataService/index';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { fieldsTypes } from 'helpers/customFields';

const TYPES = fieldsTypes.map((label, id) => ({ label, id }));

export default function CustomizedMenus({ fields, setFields, loadFields }) {
  const { t } = useTranslation(['toastify', 'views']);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleNewField = (item) => {
    setFields([
      {
        id: new Date().getTime().toString(),
        fieldType: item.id,
        fieldName: '',
        required: true,
        visibleToCreate: true,
        visibleToEdit: true,
        visibleToRegister: true,
        options: [],
        open: true,
        tips: '',
      },
      ...fields,
    ]);
    handleClose();
    toast.info(t('views:customFields.toast'));
  };

  const checkIfHasEmptyFieldName = () => {
    const hasEmptyFieldName = fields.find((field) => field.fieldName === '');
    return hasEmptyFieldName ?? false;
  };

  const handleSave = async () => {
    try {
      if (checkIfHasEmptyFieldName()) {
        toast.error(t('toastify:commons.required_field'));
        return;
      }

      await CustomFieldsData.addCustomFields(fields);
      loadFields();
      toast.success(t('toastify:commons.toast_success'));
    } catch (error) {
      toast.error(t('toastify:commons.toast_error'));
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ marginRight: 20 }}>
        <Button
          className={classes.buttons}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
        >
          {t('views:customFields.add_field')}
          <KeyboardArrowDownIcon />
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {TYPES.map((_, i) => {
            if (_.label === 'Texto') {
              return (
                <StyledMenuItem onClick={() => handleNewField(_)} key={i}>
                  <ListItemText primary={_.label} />
                </StyledMenuItem>
              );
            }
          })}
        </StyledMenu>
      </div>

      <Button
        className={classes.buttons}
        style={{ width: 100 }}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleSave}
      >
        {t('views:customFields.save')}
        <SaveIcon style={{ marginLeft: 8 }} />
      </Button>
    </div>
  );
}
