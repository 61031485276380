import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { format, parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { ThumbDown, ThumbUp } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { GroupsData, UserSolicitationData } from 'services/dataService';

export default ({ className, ...rest }) => {
  const [groups, setGroups] = useState([]);
  const [userSolicitation, setUserSolictation] = useState({});
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const { register, handleSubmit, errors, setValue } = useForm();
  const { id_company } = useSelector((state) => state.auth);
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GroupsData.getGroups();
      setGroups(response.data.groups.filter((a) => a?.type !== 3));
    };

    const fetchDataUserSolicitation = async () => {
      try {
        const response = await UserSolicitationData.getUserSolicitationById(id);

        const {
          id: id_solicitation,
          name,
          email,
          message,
          createdAt,
          status,
          id_user,
        } = response.data;

        const solicitation = {
          id_solicitation,
          name,
          email,
          message,
          createdAt,
          status,
          id_company,
          id_user,
        };

        setUserSolictation(solicitation);

        setValue([
          { name },
          { email },
          { createdAt: format(parseISO(createdAt), 'dd/MM/yyyy') },
          { message },
        ]);
      } catch (err) {
        history.push('/user-solicitations');
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };

    fetchData();
    fetchDataUserSolicitation();
    setLoading(false);
  }, [id]);

  const onSubmit = async (data) => {
    data.id_company = id_company;
    data.status = 2;
    data.id_group = groups.id;
    try {
      await UserSolicitationData.updateUserSolicitation(
        userSolicitation.id_solicitation,
        data,
        { company: id_company }
      );
      toast.success(t('toastify:commons.toast_update'));
      history.push('/user-solicitations');
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const handleRefuseSolicitation = async () => {
    setUserSolictation((userSolicitation.status = 3));
    try {
      await UserSolicitationData.updateUserSolicitation(
        userSolicitation.id_solicitation,
        userSolicitation,
        { company: id_company }
      );
      toast.success(t('toastify:commons.toast_update'));

      history.push('/user-solicitations');
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  };

  const titlePage = t('title:User_solicitation.title_view_user_solictation');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:User.input_first_name')}
                  helperText={
                    errors.name?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:User.input_first_name'),
                    })
                  }
                  name="name"
                  type="text"
                  variant="outlined"
                  disabled={userSolicitation.id_user !== null ? true : false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.name}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:User.input_email')}
                  helperText={
                    (errors.email?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:User.input_email'),
                      })) ||
                    (errors.email?.type === 'pattern' &&
                      t('validation:commons.validation_email', {
                        field: t('form:User.input_email'),
                      }))
                  }
                  disabled={userSolicitation.id_user !== null ? true : false}
                  name="email"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    },
                  })}
                  error={!!errors.email}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:User_solicitation.input_solicitation_date')}
                  disabled={true}
                  name="createdAt"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label={t('form:User_solicitation.input_solicitation_message')}
                  disabled={true}
                  name="message"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="secondary"
              size="large"
              variant="outlined"
              disabled={userSolicitation.id_user !== null ? true : false}
              onClick={handleRefuseSolicitation}
            >
              {t('button:User_solictation.btn_refuse_solictation')}
              <ThumbDown />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disabled={userSolicitation.id_user !== null ? true : false}
            >
              {userSolicitation.id_user !== null
                ? t('button:User_solictation.btn_already_accepted_solictation')
                : t('button:User_solictation.btn_accept_solictation')}
              <ThumbUp />
            </Button>
          </CardActions>
        </form>
      )}
    </Card>
  );
};
