import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export const useStyles = makeStyles( theme => ({
  buttons: {
    width: 152,
    height: 45,
    background: '#11CDEF 0% 0% no-repeat padding-box',
    border: '1px solid #11CDEF',
    borderRadius: 4,
    textTransform:'capitalize',
    fontSize: 14,
    fontWeight:'normal',
    color: '#FFFFFF',
    boxShadow:'none',
    padding:0,
    '&:hover':{
      background: '#11CDEF 0% 0% no-repeat padding-box',
      boxShadow:'none',
    }
  },
}))


export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width:155,
  },
})( props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center',}}
    {...props}
  />
))


export const StyledMenuItem = withStyles( theme => ({
  root: {
    '&:focus': {
      backgroundColor: '#EAF3FC',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)
