import { makeStyles } from '@material-ui/styles';
import { width } from '@mui/system';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  tabs: {
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        overflowX: 'scroll !important',
        paddingBottom: 8,

        '& > div > button': {
          flex: 1,
          minHeight: 40,
          padding: 6,
          fontSize: 12,
          fontWeight: 'bold',
          textRendering: 'optimizeLegibility',
          lineHeight: '16px',

          '& svg': {
            width: '20px !important',
            height: '20px !important',
          },

          '&:last-child': {
            marginRight: '0 !important',
          }
        }
      },
    }
  },
  tabItemsTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  content: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize * 2,
    lineHeight: '100%',
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(4),
  },

  withoutContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginInline: 'auto',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
    maxWidth: 1200,
    gap: 32,

    '& > img': {
      maxWidth: 500,
      width: '100%',
      height: '100%',
    },

    '& > div': {
      maxWidth: 500,
    },

    [theme.breakpoints.down(1330)]: {
      flexDirection: 'column'
    },
  },
  withoutContentText: {
    fontSize: 38,
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '32px'
    },
  },
  withoutContentDescription: {
    fontSize: 24,
    lineHeight: '28px',
    color: '#A8A8B3',
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20px',
      marginTop: '12px',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 333,
    backgroundColor: '#176AE6',
    fontSize: 20,
    fontWeight: 'bold',
    color: "#fff",
    padding: '16px 32px',
    borderRadius: 8,
    width: 'fit-content',
    gap: 8,
    marginTop: theme.spacing(6),
    transition: '.2s all',

    '&:hover': {
      backgroundColor: '#217aff',
      transform: 'scale(1.02)'
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset',
      fontSize: 18,
      marginTop: theme.spacing(4),
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIcon: {
    marginBottom: theme.spacing(1),
  },
  blink: {
    animation: '$blinkAnimation 1s infinite',
  },
  '@keyframes blinkAnimation': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
