import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  infoTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%"
  },

  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    width: "100%"
  },

  hr: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderStyle: 'inset',
    borderWidth: '1px',
    width: '100%',
  },

  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },

  coursesBox: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 2, 4, 2),
    overflowY: 'auto',
    width: '90%',
    flexDirection: 'column',
    maxHeight: '330px'
  },

  courseCard: {
    width: '90%',
    padding: theme.spacing(2, 2, 1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgb(84,110,122,.4)',
  },

  courseName: {
    justifySelf: 'flex-start',
  }
}));
