import querystring from 'querystring';
import api from '../api';

class DataServiceSharedCourses {
  addSharedCourse(id, data) {
    return api.post(`company-course/${id}/shared`, data);
  }

  // TODO: Melhorar este cara
  deleteSharedCourse(id_sub, id_company_course) {
    return api.delete(`company-course/${id_sub}/shared/${id_company_course}`, {params: {id: id_company_course}});
  }

  getSharedCourses(id, params) {
    const url = `company-course/${id}/shared?` + querystring.stringify(params);
    return api.get(url);
  }

  shareAllCourses(id_subplatform, courses) {
    const url = `company-course/shared/all`;
    return api.post(url, {}, {data: {id_subplatform, courses}});
  }

  unshareAllCourses(id_subplatform, courses) {
    const url = `company-course/shared/all`;
    return api.delete(url, {data: {id_subplatform, courses}});
  }
}

export default new DataServiceSharedCourses();
