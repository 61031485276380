import React from 'react';

import { List } from 'views/Dashboard/components';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { ViewList, ImportExport } from '@material-ui/icons';
import useWindowDimensions from 'customHooks/DimensionHook';
import { useHistory } from 'react-router-dom';

export default () => {
  const classes = useStyles();
  const { t } = useTranslation(['label', 'button']);
  const { width } = useWindowDimensions();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/all-courses`);
  };
  
  return (
    <div className={classes.root}>

      <Typography
        variant="h4"
      >
        <div
          style={{
            'display': 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div>
            {t('label:commons.news_releases')}

          </div>
          <div>
            <Button
              className={classes.exportButton}
              color="primary"
              fullWidth={width < 750}
              variant="outlined"
              style={{ marginTop: width < 750 ? 10 : 'unset' }}
              onClick={handleClick}
            >
              <ViewList style={{ marginRight: '5px' }} />
              {t('button:commons.btn_new_courses')}
            </Button>
          </div>
        </div>

      </Typography>

      <div className={classes.content}>

        <List />
      </div>

    </div>
  );
};
