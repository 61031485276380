import * as React from 'react';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CertificateData, FilesData, CourseData } from 'services/dataService';
import CertificateForm from 'views/Setting/Certificate/components/Form';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

export default (className, ...rest) => {
  const { id } = useParams();

  const classes = useStyles();

  const form = useForm();

  const [showPeriod, setShowPeriod] = React.useState(false);
  const [loading, setloading] = React.useState(true);
  const [showDocStudent, setShowDocStudent] = React.useState(false);
  const [file, setFile] = React.useState();
  const [filePath, setFilePath] = React.useState('');
  const [previewFile, setPreviewFile] = React.useState('');

  const { t } = useTranslation(['toastify', 'label']);

  const { handleSubmit, setValue } = form;

  const edit = React.useRef(true);
  const id_company_course = React.useRef('');

  const id_company = useSelector((state) => state.auth.id_company);

  const getCertificate = async () => {
    try {
      const response = await CertificateData.getCourseCertificate(id);
      return response;
    } catch (e) {
      // Fetch course certificate
      if (e.response.status === 404) {
        edit.current = false;
        try {
          const response = await CertificateData.getCertificate(id_company);
          return response;
        } catch (error) {
          return { success: false, error: e };
        }
      } else {
        return { success: false, error: e };
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      // fetch API and set form fields
      try {
        const responseCompanyCourse = await CourseData.getCompanyCourseByCourse(
          id
        );
        id_company_course.current = responseCompanyCourse.data.id;

        const response = await getCertificate();

        if (response?.success === false) throw response?.error;

        const {
          location,
          show_doc_student,
          show_period,
          top_footer_spacing,
          top_header_spacing,
          top_message_spacing,
          top_name_spacing,
          footer_message,
          expiration_date,
          top_document_spacing,
          top_expiration_spacing,
        } = response.data;

        setPreviewFile(response.data.file?.base64);
        setFilePath(response.data.file);
        setShowPeriod(show_period);
        setShowDocStudent(show_doc_student);

        setValue([
          { location },
          { top_footer_spacing },
          { top_header_spacing },
          { top_message_spacing },
          { top_name_spacing },
          { footer_message },
          { expiration_date },
          { top_document_spacing },
          { top_expiration_spacing },
        ]);
      } catch (e) {
        if (e.response?.status === 404) {
          edit.current = false;
          toast.error(t('toastify:Course.certificate.custom_not_found'), {autoClose: 7 * 1000});
        }
        if (e.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:Course.certificate.default_not_found'), {autoClose: 12 * 1000});
        }
      } finally {
        setTimeout(() => {
          setloading(false);
        }, 200);
      }
    })();
  }, []);

  const onSubmit = React.useCallback(
    async (data) => {
      try {
        const formValue = {
          ...data,
        };

        if (file) {
          const responseCard = await FilesData.addFile(
            file,
            `Course/CompanyCourse=${id_company_course.current}/Lessons`
          );
          formValue.id_file = responseCard.data.id;
        }
        if (edit.current) {
          await CertificateData.updateCourseCertificate(id, formValue);
        } else {
          await CertificateData.addCourseCertificate(id, formValue);
        }

        toast.success(t('toastify:commons.toast_update'));
      } catch (error) {
        if (error.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_update_error'));
        }
      }
    },
    [t, file]
  );

  const toggleShowPeriod = () => {
    setShowPeriod((prev) => !prev);
  };
  const toggleShowDocStudent = () => {
    setShowDocStudent((prev) => !prev);
  };

  return (
    <React.Fragment>
      {loading && (
        <Grid
          container
          xs={12}
          justify={'center'}
          alignItems={'center'}
          className={clsx(classes.marginTop, classes.bottomPadding)}
        >
          <CircularProgress size={48} color="secondary" />
        </Grid>
      )}
      <form
        {...rest}
        style={{ marginTop: 25 }}
        className={className}
        onSubmit={handleSubmit(onSubmit)}
        hidden={loading}
      >
        <CertificateForm
          showPeriod={showPeriod}
          showDocStudent={showDocStudent}
          setFile={setFile}
          filePath={filePath}
          toggleShowPeriod={toggleShowPeriod}
          toggleShowDocStudent={toggleShowDocStudent}
          file={file}
          form={form}
          previewFile={previewFile}
          setPreviewFile={setPreviewFile}
        />
        <Button
          className={classes.marginTop}
          color="primary"
          variant="contained"
          type="submit"
          size="large"
        >
          {t('button:commons.btn_save')}
          <Save />
        </Button>
      </form>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(4),
  },
  bottomPadding: { paddingBottom: theme.spacing(2) },
}));
