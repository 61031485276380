import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

import { Save, AddCircle } from '@material-ui/icons';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import { CategoryData, SubCategoryData } from 'services/dataService';
import HandleCreate from 'components/HandleCreate';
import CategoryForm from 'views/Category/components/Form';

export default ({ className, ...rest }) => {
  const [categories, setCategories] = useState([]);
  const [idCategory, setIdCategory] = useState('');
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modalStatus, setModalStatus] = useState(false);

  const { id } = useParams();

  const { register, handleSubmit, errors, setValue, getValues, control } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await SubCategoryData.getSubCategoryById(id);

          const { name, id_category, status } = response.data;

          setStatus(status);
          setIdCategory(id_category);

          setValue([{ name }, { id_category }]);
        } catch (err) {
          history.push('/subcategories');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }    
        }
      };

      fetchData();
    }

    setLoading(false);
  }, [id, t]);

  useEffect(() => {
    const fetchCategoriesData = async () => {
      try {
        const response = await CategoryData.getCategories({
          limit: 50,
          status: true,
          name: '',
        });

        setCategories(response.data.categories);
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }  
      }
    };

    fetchCategoriesData();
    setLoading(false);
  }, [modalStatus]);

  const handleModalOpen = useCallback(() => {
    setModalStatus(true);
  });

  const onSubmit = async (data) => {
    try {
      if (id) {
        await SubCategoryData.updateSubCategory(id, data);
        toast.success(t('toastify:commons.toast_update'));
      } else {
        await SubCategoryData.addSubCategory(data);
        toast.success(t('toastify:commons.toast_success'));
      }

      history.push('/subcategories');
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        const toastMsg = id ? 'toast_update_error' : 'toast_error';
        toast.error(t(`toastify:commons.${toastMsg}`));
      }
    }
  };

  const handleStatusChange = () => {
    setStatus((prev) => !prev);
  };

  const titlePage = id
    ? t('title:Subcategories.title_edit')
    : t('title:Subcategories.title_create');

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={titlePage} />
        <Divider />
        {!loading && (
          <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Subcategories.input_subcategory')}
                    helperText={
                      errors.name?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Subcategories.input_subcategory'),
                      })
                    }
                    name="name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.name}
                  />
                </Grid>
                <Grid item md={4} xs={12} className={clsx(classes.select)}>
                  <FormControl style={{ minWidth: 100, width: 200 }}>
                    <InputLabel id="id_category">
                      {t('form:Categories.input_category')}
                    </InputLabel>

                    <Controller
                      as={
                        <Select>
                          {categories.length > 0 &&
                            categories.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                      name="id_category"
                      control={control}
                      defaultValue={{ idCategory }}                  
                    />
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12} className={clsx(classes.switch)}  style={{ display: 'none' }}>
                  <InputLabel id="status">
                    {t('form:commons.input_status')}
                  </InputLabel>
                  <Switch
                    id="status"
                    checked={status}
                    onChange={handleStatusChange}
                    color="primary"
                    inputRef={register}
                    name="status"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions className={classes.actions}>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                onClick={handleModalOpen}
              >
                {id
                  ? t('button:commons:btn_edit_category')
                  : t('button:commons:btn_add_category')}
                <AddCircle />
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </form>
        )}
      </Card>
      <HandleCreate
        closeModal={setModalStatus}
        isOpen={modalStatus}
        component={
          <CategoryForm
            isModalInsert={setModalStatus}
            idCategory={getValues().id_category}
          />
        }
      />
    </>
  );
};
