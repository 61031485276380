import {
  Button,
  FormHelperText,
  Grid,
  Icon,
  InputLabel,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { CheckCircleOutline, CloudUploadRounded } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FilesData } from 'services/dataService';
import { useStyles } from '../../styles';
import CertificatePdfPreview from '../CertificatePdfPreview';

export default function CertificateForm(props) {
  const {
    showPeriod,
    showDocStudent,
    setFile,
    toggleShowPeriod,
    toggleShowDocStudent,
    file,
    form,
    previewFile,
    setPreviewFile,
  } = props;

  const defaultSlidersValues = {
    top_footer_spacing: 78,
    top_header_spacing: 80,
    top_message_spacing: 90,
    top_name_spacing: 100,
    top_document_spacing: 70,
    top_expiration_spacing: 50,
    top_hours_spacing: 30,
  };

  const classes = useStyles();

  const { register, errors, control, watch, setValue } = form;

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);

  const formValues = watch([
    'location',
    'footer_message',
    'expiration_date',
    'top_footer_spacing',
    'top_header_spacing',
    'top_message_spacing',
    'top_name_spacing',
    'top_document_spacing',
    'top_expiration_spacing',
    'top_hours_spacing',
  ]);

  const setDefault = React.useCallback(() => {
    const {
      top_footer_spacing,
      top_header_spacing,
      top_message_spacing,
      top_name_spacing,
      top_document_spacing,
      top_expiration_spacing,
      top_hours_spacing,
    } = defaultSlidersValues;

    setValue([
      { top_footer_spacing },
      { top_header_spacing },
      { top_message_spacing },
      { top_name_spacing },
      { top_document_spacing },
      { top_expiration_spacing },
      { top_hours_spacing },
    ]);
  }, [form, setValue]);

  const setFileChange = async (file) => {
    if (file.length > 0) {
      setFile(file[0]);

      let formDataUser = new FormData();
      formDataUser.append('file', file[0]);

      const response = await FilesData.convertFile(formDataUser);
      setPreviewFile(response.data.base64Png);
    }
  };

  return (
    <React.Fragment>
      <Grid container justify="space-between">
        <Grid container spacing={2} md={12} xs={12}>
          {/* <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label={t('form:Settings.Certificate.input_local')}
              helperText={
                errors.location?.type === 'required' &&
                t('validation:commons.validation_required', {
                  field: t('form:Settings.Certificate.input_local'),
                })
              }
              name="location"
              type="text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputRef={register({ required: false })}
              error={!!errors.location}
            />
          </Grid> */}
          {/* <Grid item md={6} xs={12}>
            <div className={classes.rowSwitch}>
              <InputLabel className={classes.switchLabel} htmlFor="showPeriod">
                {t('form:Settings.Certificate.show_period')}
              </InputLabel>
              <Switch
                id="showPeriod"
                checked={showPeriod}
                onChange={toggleShowPeriod}
                color="primary"
                inputRef={register}
                name="show_period"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <FormHelperText>
              {t('form:Settings.Certificate.show_period_help')}
            </FormHelperText>
          </Grid> */}
          {/* <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              label={t('form:Settings.Certificate.input_expiration_date')}
              name="expiration_date"
              inputRef={register({ required: false })}
              error={!!errors.expiration_date}
              helperText={t(
                'form:Settings.Certificate.input_expiration_date_help'
              )}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid> */}
          <Grid item md={6} xs={12}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label={t('form:Settings.Certificate.input_message')}
                name="footer_message"
                type="text"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register({ required: false })}
                error={!!errors.footer_message}
                helperText={t('form:Settings.Certificate.input_message_help')}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
              <div className={classes.rowSwitch}>
                <InputLabel
                  className={classes.switchLabel}
                  htmlFor="showDocStudent"
                >
                  {t('form:Settings.Certificate.show_document')}
                </InputLabel>
                <Switch
                  id="showDocStudent"
                  checked={showDocStudent}
                  onChange={toggleShowDocStudent}
                  color="primary"
                  inputRef={register}
                  name="show_doc_student"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
              <FormHelperText>
                {t('form:Settings.Certificate.show_document_help')}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid className={classes.bottomSpacing} item md={6} xs={12}>
            <DropzoneArea
              clearOnUnmount
              dropzoneClass={classes.dropZone}
              filesLimit={1}
              getDropRejectMessage={(files, e) =>
                t('toastify:FileDrop.file_extension_rejected_pdf')
              }
              getFileAddedMessage={(file_name) =>
                t('toastify:FileDrop.file_extension_accepted', {
                  file_name,
                })
              }
              getFileLimitExceedMessage={() =>
                t('toastify:FileDrop.file_extension_rejected_pdf')
              }
              Icon={file ? CheckCircleOutline : CloudUploadRounded}
              showPreviewsInDropzone={false}
              showPreviews={false}
              showFileNames={false}
              maxFileSize={524288000}
              acceptedFiles={['.pdf']}
              dropzoneText={t('form:Settings.Certificate.dropzone_message')}
              onChange={(file) => setFileChange(file)}
              onDelete={() => {
                setPreviewFile('');
                setFile(null);
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container xs={12} spacing={4} className={classes.topSpacingGrid}>
        <Grid container item md={3} xs={12}>
          <Grid item md={12} xs={12}>
            <InputLabel>
              {t('form:Settings.Certificate.input_top_header')}
            </InputLabel>
            <Controller
              name="top_header_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_header_spacing' || defaultSlidersValues.top_header_spacing
              }
              as={
                <Slider
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid>
          <Grid item md={12} xs={12} className={classes.topSpacingGrid}>
            <InputLabel>
              {t('form:Settings.Certificate.input_top_name')}
            </InputLabel>
            <Controller
              name="top_name_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_name_spacing' || defaultSlidersValues.top_name_spacing
              }
              as={
                <Slider
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid>
          <Grid item md={12} xs={12} className={classes.topSpacingGrid}>
            <InputLabel>
              {t('form:Settings.Certificate.top_document_spacing')}
            </InputLabel>
            <Controller
              name="top_document_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_document_spacing' ||
                defaultSlidersValues.top_document_spacing
              }
              as={
                <Slider
                  disabled={!showDocStudent}
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid>

          <Grid className={classes.topSpacingGrid} item md={12} xs={12}>
            <InputLabel>
              {t('form:Settings.Certificate.input_top_message')}
            </InputLabel>
            <Controller
              name="top_message_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_message_spacing' ||
                defaultSlidersValues.top_message_spacing
              }
              as={
                <Slider
                  disabled={formValues.footer_message?.length === 0}
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid>
          <Grid className={classes.topSpacingGrid} item md={12} xs={12}>
            <InputLabel>
              {t('form:Settings.Certificate.input_top_footer')}
            </InputLabel>
            <Controller
              name="top_footer_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_footer_spacing' || defaultSlidersValues.top_footer_spacing
              }
              as={
                <Slider
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid>
          {/* <Grid className={classes.topSpacingGrid} item md={12} xs={12}>
            <InputLabel>
              {t('form:Settings.Certificate.top_expiration_spacing')}
            </InputLabel>
            <Controller
              name="top_expiration_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_expiration_spacing' ||
                defaultSlidersValues.top_expiration_spacing
              }
              as={
                <Slider
                  disabled={formValues.expiration_date?.length === 0}
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid> */}
          <Grid className={classes.topSpacingGrid} item md={12} xs={12}>
            <InputLabel>
              {t('form:Settings.Certificate.input_top_hours')}
            </InputLabel>
            <Controller
              name="top_hours_spacing"
              control={control}
              onChange={([, value]) => value}
              defaultValue={
                +'top_hours_spacing' || defaultSlidersValues.top_hours_spacing
              }
              as={
                <Slider
                  min={-500}
                  step={0.5}
                  max={500}
                  valueLabelDisplay={'auto'}
                />
              }
            />
          </Grid>
          <Grid
            style={{ display: 'flex' }}
            className={classes.topSpacingGrid}
            item
            md={12}
            xs={12}
            justify="center"
          >
            <Button variant="text" color="primary" onClick={setDefault}>
              {t('form:Settings.Certificate.reset_button')}
            </Button>
          </Grid>
        </Grid>
        <Grid container item justify={'center'} md={9}>
          <Grid
            style={{ overflow: 'auto' }}
            item
            align={'center'}
            spacing={1}
            md={12}
            xs={12}
          >
            <CertificatePdfPreview
              showPeriod={showPeriod}
              showDocStudent={showDocStudent}
              userDoc={'123.456.789-10'}
              formValues={formValues}
              background={previewFile}
            />
          </Grid>
          <Grid item align={'center'} spacing={1} md={12} xs={12}>
            <Typography
              component={'p'}
              style={{ maxWidth: '860px', textAlign: 'center' }}
            >
              {t('form:Settings.Certificate.preview_message')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* <input
            style={{ display: 'none' }}
            type="file"
            id="contained-card-file"
            name="file_card"
            accept=".pdf"
            onChange={(event) => {
              setFile(event.target.files[0]);
            }}
          />
          <label htmlFor="contained-card-file">
            <Button variant="contained" color="primary" component="span">
              {t('button:User.btn_profile_choose_archive')}
            </Button>
          </label> */}
    </React.Fragment>
  );
}
