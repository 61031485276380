import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import FileSaver from 'file-saver';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  CertificateData,
  CourseData,
  EnrollmentData,
  UserData,
} from 'services/dataService';
import history from 'services/history';
import CertificatePdfPreview from 'views/Setting/Certificate/components/CertificatePdfPreview';
const AdminCertificates = () => {
  const classes = useStyles();

  const [fetching, setFetching] = React.useState(true);
  const [courseData, setCourseData] = React.useState(null);
  const [userData, setUserData] = React.useState(null);
  const [enrollmentData, setEnrollmentData] = React.useState(null);
  const [certificateData, setCertificateData] = React.useState(null);
  const { id_user, id_course } = useParams();

  const downloadCertificate = async () => {
    const { data: base64Certificate } = await CertificateData.download(
      courseData?.course[0]?.id,
      id_user,
      new Date()
    );
    const res = await fetch(base64Certificate);
    const data = await res.blob();

    let blob = new Blob([data], { type: 'application/pdf' });
    FileSaver.saveAs(blob, `${courseData?.name}.pdf`);
  };

  const getCourseAndCertificate = async () => {
    try {
      const { data } = await CertificateData.getCertificateCourseAndUser(
        id_course,
        id_user
      );

      const { certificate, user, course } = data;

      setUserData(user);
      setCourseData(course);
      setCertificateData(certificate);

      const {
        data: enrollment,
      } = await EnrollmentData.getEnrollmentByUserAndCourse(
        id_user,
        course?.course[0]?.id
      );

      setEnrollmentData(enrollment);

      setFetching(false);
    } catch (error) {}
  };

  React.useEffect(() => {
    getCourseAndCertificate();
  }, []);
  return (
    <Box className={classes.root}>
      {!fetching && (
        <>
          <Box>
            <Grid container spacing={2} direction="row" justify="flex-end">
              <Grid item xs={12} md={2}>
                <Link
                  to={`/certificates/public/${courseData?.id}/${id_user}`}
                  color="primary"
                  style={{ textDecoration: 'none' }}
                >
                  <Button fullWidth variant="outlined" color="primary">
                    Link público
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={downloadCertificate}
                >
                  Baixar
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Typography className={classes.topSpacing} />
          <Box className={classes.card}>
            <Grid container>
              <Grid item xs={12} md={2} style={{ maxWidth: 100 }}>
                <Box className={classes.avatarBox}>
                  <img
                    src={userData?.avatar?.url}
                    width={80}
                    className={classes.avatar}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box className={classes.dataBox}>
                  <Box>
                    <Typography>
                      <b>{'Nome: '}</b>
                      {userData?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <b>{'Curso: '}</b>
                      {courseData?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <b>{'Data da matrícula: '}</b>
                      {`${new Date(
                        enrollmentData?.createdAt
                      ).toLocaleDateString()}`}
                    </Typography>
                  </Box>
                  {/* <Box>
                    <Typography>
                      <b>{'Permanência total: '}</b> {'0 horas e 0 minutos'}
                    </Typography>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Typography className={classes.topSpacing} />
          <Box className={classes.certificate}>
            <Box className={classes.certificateBox}>
              <CertificatePdfPreview
                formValues={certificateData}
                courseName={courseData?.name}
                idCourse={courseData?.id}
                userName={userData?.name}
                userDoc={null}
                showPeriod={certificateData?.show_period}
                showDocStudent={certificateData?.show_doc_student}
                background={certificateData?.file?.base64}
                courseHours={courseData?.workload}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: '0px 1px 12px 1px',
    padding: theme.spacing(2),
  },
  dataBox: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  topSpacing: {
    paddingTop: theme.spacing(4),
  },
  avatarBox: { padding: theme.spacing(1) },
  avatar: { width: '100%', maxWidth: 100, borderRadius: 4 },
  certificate: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: '0px 1px 12px 1px',
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'hidden',
    overflowX: 'auto',
  },
  certificateBox: {
    width: 850,
  },
}));

export default AdminCertificates;
