import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },

  rowStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },

  spacer: {
    flexGrow: 1,
  },

  exportButton: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));
