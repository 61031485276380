import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'space-between',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '200px',
  },
}));
