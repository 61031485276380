import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },

  button: {
    textTransform: 'capitalize',
    width: '100%',
  },

  card: {
    padding: theme.spacing(2),
  },

  item: {
    marginBottom: theme.spacing(2),
  },

  select: {
    width: '100%',
  },
}));
