import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import vimeo from './content/sagas';
import notification from './notification/sagas';
import learn from './learn/sagas';
import junoAPI from './junoAPI/sagas';
import teams from './teams/sagas';

export default function* rootSaga() {
  return yield all([auth, vimeo, notification, learn, junoAPI, teams]);
}
