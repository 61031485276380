import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const RouteWithLayout = ({
  layout: Layout,
  component: Component,
  isPrivate,
  ...rest
}) => {
  const data = useSelector((state) => state.auth);
  const { path } = rest;
  let signed = false;
  if (data.token && data.signed === true) {
    signed = true;
  } else {
    signed = false;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }
  if (signed && !isPrivate && path !== '/not-found') {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  isPrivate: PropTypes.bool,
};

RouteWithLayout.defaultProps = {
  isPrivate: false,
};

export default RouteWithLayout;
