import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '87%',
    marginTop: 5,
    minHeight: 500,
    marginTop: 35,
    [theme.breakpoints.up(1920)]: {
      width: '95%',
    },
  },
  title: {
    color: '#263238',
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 14,
  },
  replyCommentsWrapper: {
    padding: theme.spacing(2),
    marginTop: 10,
    marginLeft: 60,

    [theme.breakpoints.down('md')]: {
      marginLeft: 30,
    }
  },
  commentsWrapper: {
    padding: theme.spacing(2),
    marginTop: 10,
  },
  deleteCommentWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  commentsAvatar: {
    width: 50,
    height: 50,
  },
  commentsNamePerson: {
    margin: 0,
    textAlign: 'left',
    marginBottom: 8,

    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    }
  },
  commentsDate: {
    color: '#778594',
    fontSize: 14,
  },
  commentsText: {
    textAlign: 'left',
    marginBottom: '4px',
    fontSize: 14,
    lineHeight: '22px',

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    }
  },
  replyText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    marginBottom: 8,
  },
  commentsReply: {
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'filter 0.2s',
    '&:hover': {
      filter: 'brightness(2)',
    },
  },
  closeCommentIcon: {
    height: 16,
    width: 16,
    cursor: 'pointer',
    marginLeft: 2,
    transition: 'filter 0.2s',
  },
  replyCountAndDelete: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
  commentsIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'filter 0.2s',
    '&:hover': {
      filter: 'brightness(2)',
    },
  },
  commentsIcon: {
    height: 16,
    width: 16,
  },
  commentsNumber: {
    fontSize: 14,
    marginLeft: 3,
  },
  replyTextField: {
    display: 'flex',
    flexDirection: 'column',
  },
  replyButton: {
    marginTop: 14,
    alignSelf: 'flex-end',
  },
  contentMessage: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    // border: 'solid blue',
    display: 'flex',
    height: 65,
    borderRadius: 5,
    backgroundColor: '#fff',
    fontSize: 15,
    padding: 10,
    boxShadow: '0px 0px 3px #8B8B8B',
   
  },
  shadow: {
    boxShadow: '0px 0px 3px #176AE6',
    border: '1px solid #176AE6',
  },
  comment: {
    color: '#546e7a',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'fit-content',
  },
  icon: {
    height: 14,
    width: 14,
    marginRight: 5,
    display: 'block',
    margin: '0 auto',
  },
  name: {
    color: '#000000',
    fontSize: 15,
    display: 'block',
    fontWeight: 600,
  },
  button: {
    marginBottom: 20,
    alignSelf: 'flex-end',
  },
}));
