import React, { useRef, useEffect } from 'react';
import { useStyles } from './styles';
// import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight, BorderColorRounded } from '@material-ui/icons';
import clsx from 'clsx';
import Player from '@vimeo/player';
import { useHistory } from 'react-router-dom';

var sync = null;

function VimeoPlayer({ onTimeupdate, onEnded, hideAnotation, ...attrs }) {
  const ref = useRef(null);

  React.useEffect(() => {
    if (hideAnotation) {
      hideAnotation(false);
    }
  }, []);

  useEffect(() => {
    /**
     * The events to Player are in Vimeo Docs.
     * https://developer.vimeo.com/player/sdk/reference#events-for-playback-controls
     */
    const player = new Player(ref.current);
    player.on('timeupdate', onTimeupdate);
    player.on('ended', onEnded);
    return () => player.off('ended', onEnded);
  }, []);
  return <iframe ref={ref} {...attrs} allow="autoplay; encrypted-media" />;
}

export default ({
  finishedCourse,
  content,
  video_time,
  courseFinished,
  courseId,
  learnId,
  prev,
  next,
}) => {
  // const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    return () => clearTimeout(sync);
  }, []);

  const timeUpdate = (timePlayback) => {
    clearTimeout(sync);
    const isEndPlayback = timePlayback?.percent === 1;
    const finished = courseFinished ? true : isEndPlayback;

    sync = setTimeout(() => {
      const videoLastSec = parseInt(
        timePlayback?.seconds - (finished ? 0 : 10)
      );

      finishedCourse({
        finished: finished,
        video_last_sec: videoLastSec > 0 ? videoLastSec : 0,
        audio_last_sec: null,
        isEndPlayback,
      });
    }, 400);
  };

  // const scrollToContent = () => {
  //   const scrollOffset = 500;
  //   const targetElement = document.getElementById('elementId'); // Substitua 'elementId' pelo ID real do elemento
  
  //   if (targetElement) {
  //     targetElement.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'end', // ou 'end' para rolar até a parte inferior do elemento
  //     });
  //   }
  // };

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{content.name}</h1>
      <div className={classes.wrapper}>
        <VimeoPlayer
          title="vimeo-player"
          src={`${window._env_.REACT_APP_VIMEO_DOMAIN
            }${content.url_vimeo.replace(
              '/videos',
              '/video'
            )}#t=${video_time}s?portrait=0&byline=0&title=0`}
          t
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          onTimeupdate={timeUpdate}
          onEnded={timeUpdate}
        />
      </div>

      <div className={classes.buttons}>
        <button
          onClick={() => history.push(`/course/${courseId}/learn/${prev}`)}
          disabled={prev == null}
        >
          {' '}
          <ChevronLeft /> Anterior
        </button>
        {/* <button onClick={scrollToContent}>
          <BorderColorRounded>
          </BorderColorRounded>
        </button> */}
        <button
          style={{ right: 10 }}
          onClick={() => history.push(`/course/${courseId}/learn/${next}`)}
          disabled={next == null}
          className={clsx([classes.buttonsActive])}
        >
          Próximo <ChevronRight />
        </button>
      </div>
    </div>
  );
};
