import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    userSelect: 'none',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 1, 0),
  },
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
