import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from 'services/api';
import {
  signInSuccess,
  signFailure,
  companyInformation,
  userAvatar,
  putPendentAttendance,
  changePasswordSuccess,
  changePasswordError,
} from './actions';
import history from 'services/history';
import jwt from 'jwt-decode';
import socketIOClient from 'socket.io-client';
import { useDispatch } from 'react-redux';

export function* signIn({ payload }) {
  try {
    //const dispatch = useDispatch();
    const { email, password, domain, forceIdUser } = payload;

    const response = yield call(api.post, 'sessions', {
      email,
      password,
      domain,
      forceIdUser,
    });

    const { token, user, idAdminUser, idStudentUser } = response.data;

    // console.log(response.data);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const {
      id_company,
      exp,
      group,
      type,
      name,
      id,
      avatar,
      company_logo,
      shouldChangePassword,
    } = jwt(token);

    const responsePendentAttendanec = yield call(
      api.get,
      'form-contact/status'
    );

    const { pendentAttendance } = responsePendentAttendanec.data;

    yield put(
      signInSuccess(
        token,
        { type, group, name, id, email },
        idAdminUser,
        idStudentUser,
        shouldChangePassword
      )
    );
    yield put(companyInformation(id_company, exp, company_logo));
    yield put(userAvatar(avatar));
    yield put(putPendentAttendance(pendentAttendance));
    history.push('/dashboard');
    window.location.reload()
  } catch (err) {
    console.error(err);
    if (!err?.errorCallback === true) {
      toast.error('Falha na autenticação, verifique seus dados');
    }
    yield put(signFailure());
  }
}

export function* changePassword({ payload }) {
  try {
    const { password, passwordConfirmation, domain } = payload;

    const response = yield call(api.put, 'user/change-password', {
      password,
      passwordConfirmation,
      domain,
    });

    const { token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const { group, type, name, id, email, shouldChangePassword } = jwt(token);

    yield put(
      changePasswordSuccess(
        token,
        { type, group, name, id, email },
        shouldChangePassword
      )
    );
    toast.success('Senha alterada com sucesso.');
    history.push('/dashboard');
  } catch (err) {
    if (!err?.errorCallback === true) {
      if (
        err?.response?.data?.message ===
        'Password and confirmation are not equals.'
      ) {
        toast.error('Senha e confirmação não conferem.');
      } else if (
        err?.response?.data?.message === 'You should change password.'
      ) {
        toast.error(
          'A nova senha deve ser diferente da atual.'
        );
      } else {
        toast.error(
          'Falha ao alterar senha, confirme os dados e tente novamente.'
        );
      }
    }
    yield put(changePasswordError());
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/CHANGE_PASSWORD_REQUEST', changePassword),
]);
