import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
  card: {
    border: '1px dashed red',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: theme.palette.white,
    cursor: 'move',
  },
  gridContainer: {
    padding: theme.spacing(3),
  },
  textSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  textSecondSwitch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridItemEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  gridItemColumn: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  marginBot: {
    marginBottom: '15px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  dateList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRig: {
    marginRight: '10px',
  },
  rowItems: {
    display: 'flex',
  },
}));
