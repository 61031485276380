import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff, Save } from '@material-ui/icons/';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useStyles } from './styles';
import { CompanyData, FilesData } from 'services/dataService';
import { statusCompany } from 'constants/status';
import { DocumentMaskCustom } from 'components/DocumentMaskCustom';

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [logo, setLogo] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  } = useForm();
  const classes = useStyles();
  const [document, setDocument] = useState('');

  const { id_company } = useSelector((state) => state.auth);
  const history = useHistory();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);

  const onSubmit = useCallback(
    async (data) => {
      data.cpf_cnpj.length > 14 ? (data.type = 1) : (data.type = 2);
      data.id_owner = id_company;

      try {
        if (logo) {
          const responseLogo = await FilesData.addFile(logo, 'Company');
          data.id_file_logo = responseLogo.data.id;
        }

        await CompanyData.addCompany(data);
        toast.success(t('toastify:commons.toast_success'));
        history.push('/companies');
      } catch (error) {
        if (error.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    },
    [logo]
  );

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await CompanyData.getCompanyById(id);
          setData(response.data.company);
          const {
            name,
            fantasy_name,
            type,
            cpf_cnpj,
            platform_type,
            domain,
          } = response.data.company;

          if (response.data.company.logo) {
            setLogoUrl(response.data.company.logo.url);
          }
          setDocument(cpf_cnpj);

          setValue([
            { name },
            { domain: domain ? domain : t('label:Company.label_no_domain') },
            {
              fantasy_name: fantasy_name
                ? fantasy_name
                : t('label:Company.label_no_fantasy_name'),
            },
            { cpf_cnpj },
          ]);
        } catch (err) {
          history.push('/companies');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };
      fetchData();
    }
    setLoading(false);
  }, [id]);

  const titlePage = id
    ? t('title:Company.title_edit')
    : t('title:Company.title_create');

  return (
    <>
      {!loading && (
        <Card className={clsx(classes.root)}>
          <CardHeader title={titlePage} />
          <Divider />
          <form
            className={clsx(classes.root)}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Company.input_name')}
                    helperText={
                      errors.name?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Company.input_name'),
                      })
                    }
                    name="name"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.name}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Company.input_domain')}
                    helperText={
                      errors.domain?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Company.input_domain'),
                      })
                    }
                    name="domain"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.domain}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Company.input_email')}
                    helperText={
                      (errors.email?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.input_email'),
                        })) ||
                      (errors.email?.type === 'pattern' &&
                        t('validation:commons.validation_email', {
                          field: t('form:Company.input_email'),
                        }))
                    }
                    name="email"
                    type="text"
                    variant="outlined"
                    inputRef={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      },
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.email}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Company.input_cnpj_cpf')}
                    helperText={
                      (errors.cpf_cnpj?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.input_cnpj_cpf'),
                        })) ||
                      (errors.cpf_cnpj?.type === 'minLength' &&
                        t('validation:commons.validation_cnpj_cpf'))
                    }
                    name="cpf_cnpj"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(evt) => {
                      setDocument(evt.target.value);
                    }}
                    value={document}
                    InputProps={{
                      inputComponent: DocumentMaskCustom,
                    }}
                    inputRef={register({
                      required: true,
                      minLength:
                        document.length < 15
                          ? 14
                          : document.length >= 15
                          ? 18
                          : 0,
                    })}
                    error={!!errors.cpf_cnpj}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Company.input_fantasy_name')}
                    name="fantasy_name"
                    type="text"
                    variant="outlined"
                    inputRef={register}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.fantasy_name}
                  />
                </Grid>

                {data !== null && (
                  <Grid item md={6} xs={12}>
                    <FormControl style={{ minWidth: 300 }}>
                      <InputLabel id="status">
                        {t('form:commons.input_status')}
                      </InputLabel>

                      <Controller
                        as={
                          <Select>
                            <MenuItem value={1}>
                              {t(statusCompany[1]?.title)}
                            </MenuItem>
                            <MenuItem value={2}>
                              {t(statusCompany[2]?.title)}
                            </MenuItem>
                            <MenuItem value={3}>
                              {t(statusCompany[3]?.title)}
                            </MenuItem>
                          </Select>
                        }
                        name="status"
                        control={control}
                        defaultValue={data.status}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item md={6} xs={12}></Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    fullWidth
                    error={!!errors.password}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      {t('form:Company.input_password')}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={visible ? 'text' : 'password'}
                      name="password"
                      inputRef={register({ required: true, minLength: 6 })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visible ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={t('form:Company.input_password').length * 8}
                    />
                    <FormHelperText>
                      {(errors.password?.type === 'required' &&
                        t('validation:commons.validation_required', {})) ||
                        (errors.password?.type === 'minLength' &&
                          t('validation:commons.validation_min_pass', {
                            minLength: 6,
                          }))}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="contained-logo-file"
                    name="file_logo"
                    accept="image/png, image/jpeg"
                    onChange={(event) => {
                      setLogo(event.target.files[0]);
                    }}
                  />
                  <label htmlFor="contained-logo-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      {t('button:Company.btn_upload_logo')}
                    </Button>
                  </label>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {logo ? (
                    <img
                      className={classes.logo}
                      src={URL.createObjectURL(logo)}
                    ></img>
                  ) : !logo && logoUrl ? (
                    <img className={classes.logo} src={logoUrl}></img>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </CardContent>

            <Divider />
            <CardActions className={classes.actions}>
              <Button
                className={classes.actionButton}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => history.push('/companies')}
              >
                {t('button:commons.btn_back')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="large"
              >
                {t('button:commons.btn_save')}
                <Save />
              </Button>
            </CardActions>
          </form>
        </Card>
      )}
    </>
  );
};
