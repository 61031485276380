import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FeaturedCourseIndexHeader = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation(['button', 'views']);

  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            {t('views:FeturedCourses.page_title')}:
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export default FeaturedCourseIndexHeader;
