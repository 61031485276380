import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10%',
  },
  header: {
    minHeight: 400,
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
  },
  searchFields: {
    maxWidth: '600px',
    margin: '0 auto',
    gap: theme.spacing(4),
  },
  selectContainer: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: '30px',
  },
  categoryHeader: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize * 2,
    lineHeight: '100%',
  },
  searchHeader: {
    fontSize: theme.typography.fontSize * 2,
  },
  titleBox: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(0),
  },
  cardBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    selectContainer: {
      flexWrap: 'wrap',
    },
  },
}));
