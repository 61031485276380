import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CoursesSlider from 'components/CoursesSlider';
import useWindowDimensions from 'customHooks/DimensionHook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';

const RelatedCourses = ({ onLike, relatedCourses }) => {
  const [slidesNumber, setSlidesNumber] = React.useState();
  const { width } = useWindowDimensions();

  const { t } = useTranslation(['views']);

  const classes = useStyles();

  const actionClick = async (id_company_course, assigned) => {
    if (assigned) {
      history.replace(`/course/${id_company_course}/learn`);
    } else {
      history.push(`/course/${id_company_course}/enroll`);
      window.location.reload();
    }
  };

  React.useEffect(() => {
    let slides = null;

    if (relatedCourses?.length < 3 && width > 1050) {
      slides = relatedCourses?.length;
    } else if (width > 1650) {
      slides = null;
    } else if (width < 1650 && width >= 1400) {
      slides = 3;
    } else if (width > 1050 && width <= 1400) {
      slides = 2;
    } else {
      slides = 1;
    }
    setSlidesNumber(slides);
  }, [width]);

  return (
     <div className='notranslate'>
      <Box className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={10}>
            <Typography className={classes.title}>
              {t('views:CourseRegistration.related_courses')}
            </Typography>
            <CoursesSlider
              onLike={onLike}
              courses={relatedCourses}
              slidesNumber={slidesNumber}
              onActionClick={actionClick}
            />
          </Grid>
        </Grid>
      </Box>
    </div> 
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600,
  },
}));

export default RelatedCourses;
