import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import useWindowDimensions from 'customHooks/DimensionHook';
import React, { useState } from 'react';
import theme from 'theme';
import BuyCourseCard from './components/BuyCourseCard';
import CourseDescription from './components/CourseDescription';
import { blue } from '@material-ui/core/colors';

const CourseHeader = ({
  info,
  financial,
  icon,
  onCourseBuy,
  onLike,
  signable,
  courseBlocked,
  ableBuy,
}) => {
  const { width } = useWindowDimensions();
  const classes = useStyle();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          className={classes.backgroundDescription}
          item
          container
          xs={12}
          md={12}
        >
          <img
            src={icon}
            alt="Background"
            className={clsx(classes.contentCourse, {
              [classes.imageLoaded]: isImageLoaded,
            })}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
          <Grid item xs={12} md={6}>
            <div className={classes.CourseDescriptionStyle}>
              <CourseDescription
                onLike={onLike}
                data={info}
                icon={icon}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={8}>
            <div className={classes.buyCard}>
              <BuyCourseCard
                data={financial}
                onCourseBuy={onCourseBuy}
                info={info}
                signable={signable}
                courseBlocked={courseBlocked}
                ableBuy={ableBuy}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyle = makeStyles((theme) => ({
  backgroundDescription: {
    position: 'relative',
    overflow: 'hidden',
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      filter: 'blur(10px) brightness(0.9)',
      zIndex: -1,
    },
  },
  buyCard: {
    marginLeft: '30%',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      width: '80%',
    },
  },
  contentCourse: {
    display: 'none', 
  },
  CourseDescriptionStyle: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '16%',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16%',
      width: '70%',
    },
  },
  imageLoaded: {
    display: 'block', 
  },
}));

export default CourseHeader;