import React from 'react';
import { Button, Typography as MuiTypography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styles';
import { Poll, AddCircle } from '@material-ui/icons';

export default ({ className, ...rest }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button']);

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1} direction="row">
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <MuiTypography variant="h4">
            {t('label:commons.label_access_quick')}:
          </MuiTypography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <div className={classes.row}>
            <span className={classes.spacer} />
            <Button
              className={classes.exportButton}
              color="primary"
              variant="outlined"
            >
              {t('button:commons.btn_display_report')}
              <Poll />
            </Button>
            <Link to="/classroom/create">
              <Button color="primary" variant="contained">
                {t('button:commons.btn_add')}
                <AddCircle />
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
