import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,

} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailTemplate from 'services/dataService/EmailTemplate';
import { useStyles } from './styles';
import EmailEditorComponent from '../components/editor';
import InformationPanel from '../components/informationPanel';
import clsx from 'clsx';
export default () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);

  const [emailTitle, setEmailTitle] = React.useState('');
  const [emailContent, setEmailContent] = React.useState('');
  const [activeEmail, setActiveEmail] = React.useState('');
  const [emailChannels, setEmailChannels] = React.useState([]);
  const [channelList, setChannelList] = React.useState([]);
  const [emailFields, setEmailFields] = React.useState({
    example: '',
    fields: [],
  });

  React.useEffect(() => {
    const loadTemplate = async () => {
      if (emailTitle.length === 0 && emailContent.length === 0)
        try {
          const response = await EmailTemplate.getEmailTemplateById(id);
          const template = response.data;
          setValue('title', template.title);
          setEmailTitle(template.title);
          setEmailContent(template.content);
          setEmailChannels(template.channels.map(x => x.id_channel) || []);
          setActiveEmail(template.active);
          setChannelList([{
            uuid: '745ea8c0-f284-479f-87a9-6a8285dbb0ba',
            name: 'E-Mail'
          },
          {
            uuid: '8aea87e6-c1c0-4623-ba0a-a69ebec32556',
            name: 'Notificação'
          }
          ])
          setEmailFields({
            fields: template.emailFields,
            example: template.instruction_example,
          });
        } catch (err) {
          history.push('/settings/email');
          toast.error(t('toastify:commons.toast_error_api'));
        }
    };
    loadTemplate();
  }, [emailTitle, emailContent, activeEmail, emailFields]);

  const saveEmailConfiguration = React.useCallback(
    async ({ title }) => {
      try {
        await EmailTemplate.updateEmailTemplate(id, {
          title: title,
          content: emailContent,
          channels: emailChannels.map(x => {
            return {
              id_email: id,
              id_channel: x,
            }
          }),
          active: activeEmail,
        });
        toast.success(t('toastify:commons.toast_success'));
        history.push('/settings/email');
      } catch (e) {
        toast.error(t('toastify:commons.toast_error'));
      }
    },
    [emailTitle, emailContent, activeEmail, emailChannels, t]
  );

  const handleChannelChanged = (event) => {
    var item = event.target.name;
    var index = emailChannels.indexOf(item);
    if(index > -1){
      emailChannels.splice(index,1);
    }
    else{
      emailChannels.push(item);
    }
    var novoArr = [];
    novoArr.push(...emailChannels);
    setEmailChannels(novoArr);
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader title={t('form:Email_template.create_template_title')} />
        <Divider />
        <form onSubmit={handleSubmit(saveEmailConfiguration)}>
          <CardContent>
            <Grid
              container
              wrap="wrap-reverse"
              className={classes.gridContainer}
              spacing={4}
            >
              <Grid
                className={classes.grid}
                item
                md={emailFields.fields.length > 0 ? 8 : 12}
                xs={12}
              >
                <Grid className={classes.grid} item md={12} xs={12}>
                  <div style={{ width: '100%' }}>
                    <Typography component={'h3'} color="textPrimary">
                      {t('form:Email_template.title')}
                    </Typography>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Typography component={'span'} color="textSecondary">
                      {t('form:Email_template.title_description')}
                    </Typography>
                  </div>
                </Grid>
                <TextField
                  style={{ marginTop: 10 }}
                  value={emailTitle}
                  className={classes.TextField}
                  variant={'outlined'}
                  label={t('form:Email_template.title')}
                  helperText={
                    errors.title?.type === 'required' &&
                    t('validation:commons.validation_required', {
                      field: t('form:Email_template.title'),
                    })
                  }
                  name="title"
                  inputRef={register({ required: true })}
                  error={!!errors.title}
                  onChange={(e) => {
                    setEmailTitle(e.target.value);
                  }}
                />


                <Grid className={classes.grid} item md={12} xs={12}>
                  <div style={{ width: '100%' }}>
                    <Typography component={'h3'} color="textPrimary">
                      {t('form:Email_template.channel')}
                    </Typography>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Typography component={'span'} color="textSecondary">
                      {t('form:Email_template.channel_description')}
                    </Typography>
                  </div>
                </Grid>

                {channelList.length > 0 &&
                  channelList.map((channel) => (
                      <FormControlLabel
                      control={<Checkbox checked={emailChannels.indexOf(channel.uuid) > -1}
                        name={channel.uuid}
                        onChange={handleChannelChanged} />}
                      label={channel.name}
                    />
                  ))}

                <Grid
                  className={clsx(classes.textGrid, classes.gridContainer)}
                  item
                  md={8}
                  xs={12}
                >
                  <div style={{ width: '100%' }}>
                    <Typography component={'h3'} color="textPrimary">
                      {t('form:Email_template.email_body')}
                    </Typography>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Typography component={'span'} color="textSecondary">
                      {t('form:Email_template.email_body_description')}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  style={{ height: 300 }}
                  className={classes.grid}
                  item
                  md={12}
                  xs={12}
                >
                  <EmailEditorComponent
                    style={{ height: 200 }}
                    value={emailContent}
                    onChange={(value) => {
                      setEmailContent(value);
                    }}
                  />
                </Grid>
              </Grid>
              {emailFields.fields.length > 0 && (
                <Grid className={classes.grid} item md={4} xs={12}>
                  <InformationPanel content={emailFields} />
                </Grid>
              )}
            </Grid>

            <Grid
              container
              className={classes.gridContainer}
              spacing={4}
            ></Grid>
            <Grid container className={classes.gridContainer} spacing={4}>
              <Grid item md={10} xs={12}>
                <InputLabel>
                  {t('form:Email_template.automatic_email')}:
                </InputLabel>
              </Grid>
              <Grid
                style={{ display: 'flex' }}
                item
                md={2}
                xs={12}
                slot="end"
                justify={'flex-end'}
              >
                <Switch
                  value={activeEmail}
                  checked={activeEmail}
                  color="primary"
                  onChange={(e) => {
                    setActiveEmail(e.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              {t('button:commons.btn_save')}
              <Save />
            </Button>
          </CardActions>
        </form>
      </Card>
    </React.Fragment>
  );
};
