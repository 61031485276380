import React from 'react';
import { Grid } from '@material-ui/core';

import { LessonForm } from 'views/Lesson/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LessonForm />
    </div>
  );
};
