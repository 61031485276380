import React, { useState, useEffect, useRef } from 'react';
import { useStyles } from './styles';
import {
  Paper,
  Checkbox,
  Typography as MuiTypography,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CourseData, SharedCoursesData } from 'services/dataService';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CloudDownload } from '@material-ui/icons';
import Switch from '@mui/material/Switch';
// Import Select
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReactExport from "react-data-export";
import { CircularProgress } from '@mui/material';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default () => {
  const classes = useStyles();
  const [checkedCourses, setCheckedCourses] = useState([]);
  const [allChecked, setAllChecked] = useState(!checkedCourses.some(course => !course?.id_company_course));
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [courses, setCourses] = useState([]);

  const firstUpdate = useRef(true);
  const { t } = useTranslation(['cards', 'label', 'form', 'toastify', 'tables']);
  const { id } = useParams();
  ///////////////////
  // SINGLE COURSE //
  ///////////////////

  function checkCourse(courseId) {
    setCheckedCourses((checked) => {
      if (checked.find((checkedCourseId) => checkedCourseId == courseId)) {
        checked = checked.filter(
          (checkedCourseId) => checkedCourseId != courseId
        );
        deleteSharedCourse(courseId);
      } else {
        checked = [...checked, courseId];
        addSharedCourse(courseId);
      }

      fetchData();

      return checked;
    });
  }

  async function addSharedCourse(courseId) {
    try {
      if (!loading) {
        setLoading(true);
      }
      const responseCourse = await SharedCoursesData.addSharedCourse(courseId, {
        id_subplatform: id,
      });
      if (loading) {
        setLoading(false);
      }
      fetchData();
    } catch (e) {
      if (e.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }

  async function deleteSharedCourse(courseId) {
    try {
      if (!loading) {
        setLoading(true);
      }
      const course = courses.find((elem) => elem?.id_course == courseId);
      const responseCourse = await SharedCoursesData.deleteSharedCourse(
        id,
        course.id_company_course
      );
      if (loading) {
        setLoading(false);
      }
      fetchData();
    } catch (e) {
      if (e.response?.status === 403) {
        console.log(e);
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        console.log(e);
        toast.error(t('toastify:commons.toast_error_api'));
      }

    }
  }

  //////////////////////
  // MULTIPLE COURSES //
  //////////////////////

  function checkAllCourses(allChecked) {
    if (allChecked) {
      unshareAllCourses();
    } else {
      shareAllCourses();
    }

    fetchData();

    return !allChecked;
  }

  async function shareAllCourses() {
    try {
      if (!loading) {
        setLoading(true);
      }
      const coursesToShare = filteredCourses.filter((course) => !course?.id_company_course && course.name.includes(name));
      await SharedCoursesData.shareAllCourses(id, coursesToShare);
      if (loading) {
        setLoading(false);
      }
      fetchData();
    } catch (e) {
      if (e.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }

  async function unshareAllCourses() {
    try {
      if (!loading) {
        setLoading(true);
      }
      const coursesToUnshare = filteredCourses.filter((course) => course?.id_company_course && course.name.includes(name));
      await SharedCoursesData.unshareAllCourses(id, coursesToUnshare);
      if (loading) {
        setLoading(false);
      }
      fetchData();
    } catch (e) {
      if (e.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }

  //////////////////////
  //////////////////////
  //////////////////////

  async function fetchData() {
    try {
      if (!loading) {
        setLoading(true);
      }
      const responseCourse = await SharedCoursesData.getSharedCourses(id, {
        name,
      });
      if (loading) {
        setLoading(false);
      }
      setCourses(responseCourse.data.courses);

      setFilteredCourses(responseCourse.data.courses);

      let array = responseCourse.data.courses.map((elem) => {
        if (elem?.id_company_course) return elem.id_course;
      });

      setCheckedCourses(array);

      setAllChecked(!responseCourse.data.courses.some(course => !course?.id_company_course && course.name.includes(name)));


    } catch (e) {
      if (e.response?.status === 403) {
        console.error(e);
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        console.error(e);
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (!loading) {
      setLoading(true);
    }
  }, [courses]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F6F9FC',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleChange = (id_company_course, data) => {
    CourseData.updateCompanyCourse(id_company_course, data);

    let companyCourses = [...filteredCourses];
    const idx = companyCourses.findIndex(o => o.id_company_course == id_company_course);
    companyCourses[idx] = { ...companyCourses[idx], ...data };

    setFilteredCourses(companyCourses);
  };

  const handleNameChange = (name) => {
    setName(name);
    setAllChecked(!courses.some(course =>  !course?.id_company_course && course.name.includes(name)));
  }

  useEffect(() => {
    fetchData();
  }, []);



  return (

    <Paper disabled={true} className={classes.container}>
      <div className={classes.infoTitle}>
        <MuiTypography variant="subtitle2">
          {t('cards:course.card_courses_shared')}
        </MuiTypography>
        <ExcelFile element={<IconButton><CloudDownload /></IconButton>}>
          <ExcelSheet data={courses} name="Cursos">
            <ExcelColumn label={t('tables:course.name_column')} value="name" />
            <ExcelColumn label={t('tables:course.score')} value="score" />
            <ExcelColumn label={t('tables:course.signable')} value="signable" />
            <ExcelColumn label={t('tables:course.require_classes_approval')} value="require_classes_approval" />
            <ExcelColumn label={t('tables:course.require_classes_completion')} value="require_classes_completion" />
            <ExcelColumn label={t('tables:course.published')} value="status" />
            <ExcelColumn label={t('tables:course.category')} value="category" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <div className={classes.searchBarContainer}>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder={t('form:commons.input_search')}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => handleNameChange(e.currentTarget.value)}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {!loading && (
        <CircularProgress />
      )}
      <TableContainer >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">
                <Checkbox
                  checked={allChecked}
                  onChange={() => checkAllCourses(allChecked)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  size={'medium'}
                  color={'primary'}
                />
              </StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.name_column')}</StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.score')}</StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.signable')}</StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.require_classes_approval')}</StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.require_classes_completion')}</StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.published')}</StyledTableCell>
              <StyledTableCell align="center">{t('tables:course.category')}</StyledTableCell>

            </TableRow>
          </TableHead>

          {loading && (
            <TableBody>
              {filteredCourses
                .filter((elem) => elem.name.includes(name))
                .map((row) => (
                  <StyledTableRow key={row.id_course}>
                    <StyledTableCell align="center">
                      <Checkbox
                        key={row.id_course}
                        checked={checkedCourses.includes(row.id_course)}
                        onChange={() => checkCourse(row.id_course)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        size={'medium'}
                        color={'primary'}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.score}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch disabled={!row.id_company_course} checked={row.signable} onChange={() => { handleChange(row.id_company_course, { signable: !row.signable }) }} inputProps={{ 'aria-label': 'controlled' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch disabled={!row.id_company_course} checked={row.require_classes_approval} onChange={() => handleChange(row.id_company_course, { require_classes_approval: !row.require_classes_approval })} inputProps={{ 'aria-label': 'controlled' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch disabled={!row.id_company_course} checked={row.require_classes_completion} onChange={() => handleChange(row.id_company_course, { require_classes_completion: !row.require_classes_completion })} inputProps={{ 'aria-label': 'controlled' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch disabled={!row.id_company_course} checked={row.status == 2} onChange={() => handleChange(row.id_company_course, { status: row.status == 1 ? 2 : 1 })} inputProps={{ 'aria-label': 'controlled' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* Selecting the category */}
                      <Select
                        value={row.category}
                        //onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled={true}
                      >
                        <MenuItem value={row.category}>{row.category}</MenuItem>
                      </Select>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>

  );
};
