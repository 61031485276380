import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& div': {
      outline: 'none',
    },
    '& a': {
      transition: '0.2s linear',
      '&:focus': {
        outline: 'none',
        backgroundColor: '#2A2E4329',
      }
    },
  },
  carrossel: {
    position: 'relative',
  },
  buttons: {
    position: 'absolute',
    top: '30%',
    border: 'none',
    backgroundColor: 'transparent',
    // zIndex: theme.zIndex.appBar,
  },
  slideContainer: {
    margin: '0 2.3%',
    height: '220px',
  },
  slideCard: {
    display: 'flex !important',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    width: '95%',
    maxWidth: 200,
    minHeight: 111,
    maxHeight: 115,
    margin: '0 auto',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #707070',
    borderRadius: '10px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      minHeight: 94,
      maxHeight: 95,
      minWidth: 166
    }
  },
  text: {
    padding: '0 10px',
    marginTop: 10,
    textAlign: 'center',
    fontWeight: 600,
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.typography.fontSize * 2,
    lineHeight: '100%',
  },
  highlighted: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));
