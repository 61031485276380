import React from 'react';
import { Grid } from '@material-ui/core';

import { CourseForm } from 'views/Course/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={8} md={6} xl={8} xs={12}>
          <CourseForm />
        </Grid>
      </Grid>
    </div>
  );
};
