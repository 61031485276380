import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  Collapse,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import {
  CastForEducation,
  ExpandLess,
  EventAvailable,
  ExpandMore,
  Search,
  LibraryBooks,
  Bookmark,
  DoneAll as DoneAllIcon,
} from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CourseData } from 'services/dataService/index';
import { useDispatch, useSelector } from 'react-redux';

let debounce;
export default function NestedList() {
  const { t } = useTranslation(['toastify', 'message']);
  const { id, learnId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState([]);
  //const [course, setCourse] = useState(null)
  const [search, setSearch] = useState('');
  const [idCourseOriginal, setIdCourseOriginal] = useState('');
  const dispatch = useDispatch();
  const { sidebarModules: modules, course } = useSelector(
    (state) => state.learn
  );
  const { id: id_user } = useSelector((state) => state?.auth);

  useEffect(() => {
    getCourseById();
  }, []);

  useEffect(() => {
    for (let m of modules) {
      if (search) {
        handleClick(m.id);
      } else {
        if (m.lessons.find((_) => _.id === learnId)) {
          handleClick(m.id);
        }
      }
    }
  }, [modules]);

  const getCourseById = async () => {
    try {
      const { data: _data } = await CourseData.getCourseById(id);
      setIdCourseOriginal(_data.courses[0].id);
      if (Array.isArray(_data.courses) && _data.courses.length > 0) {
        dispatch({
          type: '@learn/COURSE',
          payload: {
            ..._data.courses[0],
            id_company_course: _data.courses[0].id,
          },
        });
      }

      dispatch({
        type: '@learn/MODULES_REQUEST',
        payload: { id, id_user, search },
      });
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  const addOpen = (moduleId) => {
    setOpen((ids) => [...new Set([...ids, moduleId])]);
  };

  const removeOpen = (moduleId) => {
    setOpen((ids) => [...new Set(ids.filter((o) => o !== moduleId))]);
  };

  const handleClick = (moduleId) => {
    if (!open.includes(moduleId)) {
      addOpen(moduleId);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value.trim());
    e.persist();
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      dispatch({
        type: '@learn/MODULES_REQUEST',
        payload: { id, id_user, search: e.target.value.trim() },
      });
      history.push(`/course/${id}/learn`);
    }, 500);
  };

  const handleCourseInfo = async () => {
    try {
      history.push(`/course/${idCourseOriginal}/enroll`)
    } catch (error) {
      toast.error(t('toastify:commons.toast_error_api'));
    }
  }
  
  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
        style={{ backgroundColor: '#F6F9FC' }}
      >
        <ListItem button onClick={handleCourseInfo}>
          <CastForEducation
            className={classes.icon}
            style={{ color: '#176AE6' }}
          />
          <span className={clsx([classes.textItem, classes.courseName])}>
            {course?.course?.name}
          </span>
        </ListItem>

        {/* <ListItem button>
          <EventAvailable className={classes.icon} />
          <span className={classes.textItem}>
            {t('message:courses.availableCourse')}:{' '}
            {course?.course?.due_date || '-'}
          </span>
        </ListItem> */}
      </List>

      <FormControl fullWidth className={classes.margin} variant="outlined">
        <OutlinedInput
          id="outlined-adornment-amount"
          value={search}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          labelWidth={0}
          style={{ borderRadius: 10 }}
        />
      </FormControl>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {modules.map((_, i) => (
          <>
            {_?.status && (
              <List
                key={i}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
                style={{ order: _.order }}
              >
                <ListItem
                  button
                  onClick={() =>
                    open.includes(_.id) ? removeOpen(_.id) : addOpen(_.id)
                  }
                  className={classes.collapseItem}
                  style={{ backgroundColor: '#F4F4F4' }}
                >
                  <LibraryBooks
                    className={classes.collapseIcon}
                    style={open.includes(_.id) ? { color: '#2DCE98' } : {}}
                  />
                  <span className={classes.collapseLabel}>{_.title}</span>
                  {open.includes(_.id) ? (
                    <ExpandLess className={classes.expandIcon} />
                  ) : (
                    <ExpandMore className={classes.expandIcon} />
                  )}
                </ListItem>

                <Collapse in={open.includes(_.id)} timeout="auto" unmountOnExit>
                  {_.lessons.map((l, ii) => (
                    <List
                      key={ii}
                      component="div"
                      disablePadding
                      style={learnId === l.id ? { fontWeight: 'bold' } : {}}
                    >
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          history.push(`/course/${id}/learn/${l.id}`)
                        }
                      >
                        <Bookmark
                          className={classes.collapseIcon}
                          style={{ color: '#2DCE98' }}
                        />
                        <span className={classes.collapseLabel}>
                          {l.title}
                          {l.lesson_user_progress &&
                          l.lesson_user_progress[0]?.finished === true ? (
                            <DoneAllIcon className={classes.finishedIcon} />
                          ) : null}
                        </span>
                      </ListItem>
                    </List>
                  ))}
                </Collapse>
              </List>
            )}
          </>
        ))}
      </div>
    </>
  );
}
