import api from '../api';

class PasswordResetService {
  reset(data) {
    return api.post(`password/email`, data);
  }
  validate(data) {
    return api.post(`password/token`, data);
  }
  update(data) {
    return api.post(`password/reset`, data);
  }
}

export default new PasswordResetService();
