import { Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import React from 'react';

const CustomHelper = ({ text }) => {
  return (
    <Typography
      color="textSecondary"
      style={{ display: 'flex', paddingTop: 6 }}
    >
      <HelpOutline fontSize="small" />
      <span>{text}</span>
    </Typography>
  );
};

export default CustomHelper;
