import querystring from 'querystring';
import api from '../api';

class DataServiceClassroom {
  addClassroom(data) {
    return api.post('classrooms', data);
  }

  getClassrooms(params) {
    const url = 'classrooms?' + querystring.stringify(params);
    return api.get(url);
  }

  getClassroomById(id) {
    return api.get(`classrooms/${id}`);
  }

  updateClassroom(id, data) {
    return api.put(`classrooms/${id}`, data);
  }
}

export default new DataServiceClassroom();
