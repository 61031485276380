import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  MenuItem,
  Select,
  FormControl,
  Box,
  Typography,
} from '@material-ui/core';

import {
  AttachFile,
  Description,
  PictureAsPdf,
  Save,
  Theaters,
} from '@material-ui/icons';

import Checkbox from '@material-ui/core/Checkbox';

import { toast } from 'react-toastify';

import { useStyles } from './styles';
import {
  CourseData,
  UserData,
  CategoryData,
  LibraryData,
} from 'services/dataService';
import TeamData from 'services/dataService/TeamData';
import { FiUploadCloud } from 'react-icons/fi';
import { Autocomplete } from '@material-ui/lab';
import NewTeacher from 'components/NewTeacher';
import { DropzoneArea } from 'material-ui-dropzone';
import { any } from 'underscore';
import AWS from 'aws-sdk';
import { MultiSelect } from 'react-multi-select-component';
import ArrowDownwardIcon from '@material-ui/icons/CloudDownload';
import axios from 'axios';

export default ({ className, isModalInsert, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [teams, setTeams] = useState([]);
  const [nameorigem, setNomeorigem] = useState('');
  const [nameFromAws, setNameFromAws] = useState('');
  const [filetype, setFiletype] = useState('');
  const [fileTypeUpdate, setFileTypeUpdate] = useState('');
  const [idCategory, setIdCategory] = useState([]);
  const [idTeam, setIdTeam] = useState([]);
  const [library, setLibrary] = useState([]);
  const [accessKeyId, setAccessKeyId] = useState();
  const [secretKey, setSecretKey] = useState();
  const [bucket, setBucket] = useState();
  const [region, setRegion] = useState();

  const [text, setText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [urlfile, setUrlFile] = useState('');
  const [arquivo, setArquivo] = useState(null);
  const [selected, setSelected] = useState([]);
  const [corerrofile, setCorerrofile] = React.useState('#FFFFFF');
  const [corerrocategory, setCorerrocategory] = React.useState('#FFFFFF');
  const [corerroteams, setCorerroteams] = React.useState('#FFFFFF');
  const [displayerrocategory, setDisplayerrocategory] = React.useState('none');
  const [displayerroteams, setDisplayerroteams] = React.useState('none');
  const [displayerrofile, setDisplayerrofile] = React.useState('none');
  const [keyAws, setKeyAws] = useState('');
  const [hasAllStudents, setHasAllStudents] = React.useState(false);  
  const [currentlessonContent, setCurrentLessonContent] = useState(null);
  const [signedUrlUpload, setSignetUrlUpload] = useState();
  const [enableSave, setEnabledSave] = useState(false);
  const { id } = useParams();

  const { register, handleSubmit, errors, setValue, control } = useForm();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
  ]);

  // Mapeia os dados da API para o formato desejado
  const options = teams.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const overrideStrings = {
    "allItemsAreSelected": t('form:Library.override_Strings.selectSomeItems'),
    "clearSearch": t('form:Library.override_Strings.selectSomeItems'),
    "clearSelected": t('form:Library.override_Strings.selectSomeItems'),
    "noOptions": t('form:Library.override_Strings.selectSomeItems'),
    "search": t('form:Library.override_Strings.selectSomeItems'),
    "selectAll": t('form:Library.override_Strings.selectAll'),
    "selectAllFiltered": t('form:Library.override_Strings.selectAllFiltered'),
    "selectSomeItems": t('form:Library.override_Strings.selectSomeItems'),
    "create": t('form:Library.override_Strings.create'),
  };

  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
      currentlessonContent,
    };

    if (type.startsWith('video/')) return <Theaters {...iconProps} />;
    if (type.startsWith('audio/')) return <AttachFile {...iconProps} />;

    switch (type) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,':
        return <Description {...iconProps} />;
      case 'application/pdf':
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };


  const  handleDownload = async () =>{
    try {
      toast.info(t('toastify:commons.toast_start_download_library')); 
      const formValues = {
        fileName: urlfile,
        fileType: filetype,
      };

      const signedurls3 = await LibraryData.signs3geturl(formValues)

      const response = await fetch(signedurls3.data.signedUrl);
      const blob = await response.blob();

      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = nameorigem; // You can customize the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading file', error);
      toast.error(t('toastify:commons.toast_error_download_library')); 
    }
  };

  const handleUploadFile = async () => {
    const formValues = {
      fileName: arquivo.name,
      fileType: arquivo.type,
    };
    const returnsigns3 = await LibraryData.signs3PutUrl(formValues);

    const nameaws = returnsigns3.data.name;

    const retornoawsput =await axios.put(returnsigns3.data.signedUrl, arquivo, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': arquivo.type,
      },
    });

    return [retornoawsput, {nameaws:nameaws}];
  } 

  const handleDeleteFile = async () => {
     await LibraryData.signs3delete(keyAws);
    
  };

  const getCurrentDateTimeString = () => {
    const currentDate = new Date();
    
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    
    const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}`;
    
    return dateTimeString;
  };

  const handleChangeLibrary = (files) => {
    const nomearquivoaws = getCurrentDateTimeString();
    if (files.length) {
      const f = files[0];

      if (f) {
        if(id){
          setKeyAws(urlfile);
        }
        setArquivo(f);
        setFiletype(f.type);
        setNomeorigem(f.name);
        setSelectedFile(f);
        setNameFromAws(nomearquivoaws + f.name);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === '/' || event.key === '\\') {
      event.preventDefault();
    }
  };
  
  useEffect(() => {
    if(categories.length > 0){
        const id_category = idCategory.trim();

        setValue([{ id_category }]);
      }
  }, [idCategory]);

  useEffect(() => {
    const updateCombos = async () => {
      if (id) {
        const responseLibrary = await LibraryData.getLibraryById(id);
        var title = responseLibrary.data.library.title;
        var description = responseLibrary.data.library.description;
        var id_category = responseLibrary.data.library.category.id;

        setNomeorigem(responseLibrary.data.library.nameorigem);
        setUrlFile(responseLibrary.data.library.urlfile);
        setHasAllStudents(responseLibrary.data.library.allteams);
        setFileTypeUpdate(responseLibrary.data.library.file_type)

        setIdCategory(id_category);

        setValue([ { id_category }, { title }, { description }]);

        const responseLibraryTeams = await LibraryData.getLibraryTeamsById(id);
        const selectedoptions = responseLibraryTeams.data.libraryteams.map(item => ({
          label: item.name,
          value: item.id,
        }));
        setSelected(selectedoptions);
      }
    };

    updateCombos();
  }, [teams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCategories = await CategoryData.getCategories();
        setCategories(responseCategories.data.categories);

        const responseTeams = await TeamData.getTeams();
        setTeams(responseTeams.data.teams);

      } catch (err) {
        history.push('/library');
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };
    fetchData();
    setLoading(false);
  }, [id, t]);

  const onSubmit = async (data) => {
      setEnabledSave(true);
    try{
      if(id){
        let urlfileupdate = urlfile;
        if(keyAws != ''){
          console.log("remove o arquivo da aws");
          // remove o arquivo da aws
          await handleDeleteFile ();
        }  
        if(arquivo){
          
          // envia o novo arquivo para aws
          const confirmarUpload = await  handleUploadFile ();
           //parar o processo caso tenha retornado diferente de 200
          if (confirmarUpload[0].status != 200 ){
            toast.success(t('toastify:commons.toast_error_library')); 
            return
          }
          urlfileupdate = confirmarUpload[1].nameaws;
        }
        
        const formValues = {
          ...data,
          selectedFile,
          urlfile: urlfileupdate,
          filetype: filetype ? filetype : fileTypeUpdate,
          nameorigem,
          hasAllStudents,
          selected,
        };
        
        await LibraryData.updateLibrary(id, formValues);
        toast.success(t('toastify:commons.toast_update'));
        history.push(`/library`);
      }
      else{
        
        let existeerros = false;
        let statuscode = 0;
        if (selected.length < 1 && hasAllStudents == false) {
          setCorerroteams('#E53935');
          setDisplayerroteams('block');
          existeerros = true;
        }
          
        if (arquivo === null) {
          setCorerrofile('#E53935');
          setDisplayerrofile('block');
          existeerros = true;
        }
        if(data.id_category.length === 0){
          setCorerrocategory('#E53935');
          setDisplayerrocategory('block');
          existeerros = true;        
        }
        if(existeerros === false){
          const filetype = arquivo.type;
          const nameorigem = arquivo.name;
    
           const confirmarUpload = await handleUploadFile ();

           //parar o processo caso tenha retornado diferente de 200
           if (confirmarUpload[0].status != 200 ){
            toast.success(t('toastify:commons.toast_error_library')); 
            return
           }

          const formValues = {
            ...data,
            selectedFile,
            urlfile: confirmarUpload[1].nameaws,
            filetype,
            nameorigem,
            hasAllStudents,
            selected,
            file : arquivo,
          };
          
          const library = await LibraryData.addLibrary(formValues);
          toast.success(t('toastify:commons.toast_success'));          
          history.push(`/library`);
        }
        else{
        
        }
    
      }
      setEnabledSave(false);
    } catch (error) {
      setLoading(false);
      setEnabledSave(false);
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error'));
      }
    }
  };

  const titlePage = !id ? t('title:Library.title_create') : t('title:Library.title_edit');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={titlePage} />
      <Divider />
      {!loading && (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>                
                  <TextField
                    fullWidth
                    label={t('form:Library.input_title')}
                    helperText={
                      errors.title?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Library.input_title'),
                      })
                    }
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    name="title"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.title}
                  />
              </Grid>
              <Grid item md={4} xs={12}>
                <div className={classes.diverrorimput}
                    style={{                     
                      borderColor: corerrocategory,
                    }}
                  >
                  <FormControl fullWidth variant="outlined">
                      <InputLabel id="id_category">
                        {t('form:Categories.input_category')}
                      </InputLabel>
                      <Controller
                        as={
                          <Select fullWidth labelWidth={t('form:Categories.input_category').length * 8.5}>
                            {categories &&
                              categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))}
                          </Select>
                        }
                        name="id_category"
                        helperText={
                          errors.id_category?.type === 'required' &&
                          t('validation:commons.validation_required', {
                            field: t('form:Courses.input_category'),
                          })
                        }
                        rules={{ required: 'Campo obrigatório' }}
                        control={control}
                        defaultValue={idCategory}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ required: true })}
                        error={!!errors.id_category}
                      />
                  </FormControl>                  
                </div>
                <span className={classes.spanerrorimput} style={{display: displayerrocategory}}>{t('form:Library.input_erro_category')}</span>
              </Grid>
              <Grid item md={4} xs={12}>
                  <div className={classes.diverrorimput}
                    style={{                     
                      borderColor: corerroteams,
                    }}
                  >
                    <Checkbox
                      checked={hasAllStudents}
                      onChange={(e) => setHasAllStudents(e.target.checked)}
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    <Typography variant={'span'}>
                      {t('form:Library.checkbox_all_students')}
                    </Typography>
                  </div>  
                  <span className={classes.spanerrorimput} style={{display: displayerroteams}}>{t('form:Library.input_erro_teams')}</span>
              </Grid>              
            </Grid>

            <Grid container spacing={2} className={classes.contentCenter}>
                <Grid item md={4} xs={12}>
                  <div className={classes.diverrorimput}
                    style={{                     
                      borderColor: corerroteams,
                    }}
                  >          
                    <FormControl fullWidth variant="outlined">                 
                      <MultiSelect
                        disabled= {hasAllStudents}
                        options={options}
                        value={selected}
                        name="id_team"
                        onChange={setSelected}
                        overrideStrings ={overrideStrings}
                        displayValue={t('form:Library.input_team')}
                        helperText={
                          errors.id_team?.type === 'required' &&
                          t('validation:commons.validation_required', {
                            field: t('form:Library.input_team'),
                          })
                        }
                        inputRef={register({ required: true })}
                        error={!!errors.id_team}
                      />
                    </FormControl>
                  </div>
                  <span className={classes.spanerrorimput} style={{display: displayerroteams}}>{t('form:Library.input_erro_teams')}</span>
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    fullWidth
                    label={t('form:Library.input_description')}
                    helperText={
                      errors.description?.type === 'required' &&
                      t('validation:commons.validation_required', {
                        field: t('form:Library.input_description'),
                      })
                    }
                    onChange={(e) => setText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    name="description"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.description}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                      {id &&(                         
                            <><span>{t('form:Library.label_text_current_item')}: </span><i>{nameorigem}</i><ArrowDownwardIcon
                              onClick={handleDownload}
                              style={{ color: 'blue', cursor: 'pointer', marginLeft: '1rem', width: '2.5rem', height: '2.5rem' }} />
                            </>                         
                      )}

                  <div className={classes.diverrorimput}
                    style={{
                      borderColor: corerrofile,
                    }}
                  >
                    <DropzoneArea
                      getPreviewIcon={handlePreviewIcon}
                      filesLimit={1}
                      maxFileSize={5368709120}
                      acceptedFiles={[
                        'video/*',
                        'image/*',
                        'media_type',
                        'application/msword',
                        '.doc',
                        '.docx',
                        '.ppt',
                        '.pptx',
                        '.mp3',
                        '.csv',
                        '.zip',
                        '.rar',
                        '.psd',
                        '.ai',  
                        '.odt',
                        '.ods',
                        '.odp',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                        'text/plain',
                        'application/pdf',
                      ]}
                      dropzoneText={id ? t('form:Library.modal_text_dragzone_vimeo') : t('form:Lessons.modal_text_dragzone_vimeo')}
                      onChange={(files) => handleChangeLibrary(files)}
                      submitButtonText={t('form:Lessons.modal_button_title')}
                      onDelete={() => setArquivo(null)                        
                      }                     
                    />
                    <input
                      type="file"
                      className={classes.inputAvatar}
                      name="fileLibrary"
                      id="fileLibrary"
                      value={avatarUrl}
                    />
                  </div>
                  <span className={classes.spanerrorimput} style={{display: displayerrofile}}>{t('form:Library.input_erro_file')}</span>
                </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              size="large"
              onClick={() => history.goBack()}
            >
              {t('button:commons.btn_back')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disabled={enableSave}
            >
              <Save style={{ marginRight: '10px' }} />
              {t('button:commons.btn_save')}
            </Button>
          </CardActions>
        </form>
      )}
    </Card>
  );
};
