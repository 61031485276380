export default function validateFederalTax(federalTax) {
  if (typeof federalTax !== 'string') return false;
  federalTax = federalTax.replace(/[\s.-]*/gim, '');
  if (
    !federalTax ||
    federalTax.length != 11 ||
    federalTax == '00000000000' ||
    federalTax == '11111111111' ||
    federalTax == '22222222222' ||
    federalTax == '33333333333' ||
    federalTax == '44444444444' ||
    federalTax == '55555555555' ||
    federalTax == '66666666666' ||
    federalTax == '77777777777' ||
    federalTax == '88888888888' ||
    federalTax == '99999999999'
  ) {
    return false;
  }
  let sum = 0;
  let mod;
  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(federalTax.substring(i - 1, i)) * (11 - i);
  mod = (sum * 10) % 11;
  if (mod == 10 || mod == 11) mod = 0;
  if (mod != parseInt(federalTax.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(federalTax.substring(i - 1, i)) * (12 - i);
  mod = (sum * 10) % 11;
  if (mod == 10 || mod == 11) mod = 0;
  if (mod != parseInt(federalTax.substring(10, 11))) return false;
  return true;
}
