import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles( theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingTop:0,
    paddingBottom:0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    fontSize:20,
    color:'#000000'
  },
  textItem:{
    color: '#000000DE',
    fontSize:14,
    marginLeft:10,
  },
  collapseLabel:{
    color: '#000000DE',
    fontSize:16,
    marginLeft:15,
    marginRight:15,
  },
  collapseIcon:{
    fontSize:20,
  },
  collapseItem:{
    position:'relative',
    minHeight: 60
  },
  expandIcon:{
    position:'absolute',
    right:10
  },
  courseName:{
    color:'#176AE6',
  },
  finishedIcon:{
    marginLeft:10,
    color:'#186AE6',
    lineHeight:20,
  }
}))
