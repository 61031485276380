import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export default ({ title, number }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <Typography color="textSecondary" variant="body2">
        {title}
      </Typography>

      <Typography color="textPrimary" variant="h5">
        {number}
      </Typography>
    </Paper>
  );
};
