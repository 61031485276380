import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useStyles } from './styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  CardContent,
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizationTable, options } from 'constants/table';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EnrollmentData } from 'services/dataService';
import { format, parseISO } from 'date-fns';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Search } from '@material-ui/icons';

export default () => {
  const classes = useStyles();
  const tableRef = useRef();
  const [data, setData] = useState();
  const [filter, setFilter] = useState(1);
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { id } = useParams();
  const { register, handleSubmit, control } = useForm();

  useEffect(() => {
    (async () => {
      const enrollment = await EnrollmentData.getEnrollment(id);
      const newEnrollment = enrollment.data.enrollments.map((elem) => {
        return {
          date: elem.createdAt,
          name: elem.company_course.course.name,
          status: elem.company_course.status,
        };
      });

      setData(newEnrollment);
    })();
  }, []);

  const getStatus = useCallback(
    (value) => {
      if (value)
        return (
          <div className={classes.status}>
            <Brightness1Icon
              fontSize="small"
              color="primary"
              style={{ marginRight: 5 }}
            />
            {t('label:commons.label_status_active')}
          </div>
        );
      else
        return (
          <div className={classes.status}>
            <Brightness1Icon
              color="secondary"
              fontSize="small"
              style={{ marginRight: 5 }}
            />
            {t('label:commons.label_status_inactive')}
          </div>
        );
    },
    [t]
  );

  return (
    <div>
      <MaterialTable
        title={`${t('tables:course.title')}`}
        tableRef={tableRef}
        columns={[
          { title: `${t('tables:course.name_column')}`, field: 'course' },
          {
            title: `${t('tables:commons.date')}`,
            field: 'date',
            render: (rowData) => format(parseISO(rowData.date), 'dd/MM/yyyy'),
          },
          {
            title: `${t('tables:statement.payment')}`,
            field: 'valor',
          },
          {
            title: `${t('tables:statement.price')}`,
            field: 'price',
          },
          {
            title: `${t('tables:statement.payment_status')}`,
            field: 'payment',
          },
          {
            title: `${t('tables:statement.detail_status')}`,
            field: 'detail',
          },
        ]}
        data={data}
        actions={[
          {
            icon: 'visibility',
            tooltip: `${t('button:commons.btn_know_more')}`,
            onClick: (event, rowData) => {
              // console.log('');
            },
            iconProps: { color: 'primary' },
          },
        ]}
        components={{
          search: false,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={2} xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Filtro
                      </InputLabel>
                      <Controller
                        as={
                          <Select label="Filter">
                            <MenuItem value={1}>
                              {t('tables:commons.name_column')}
                            </MenuItem>
                            <MenuItem value={2}>
                              {t('tables:commons.email_column')}
                            </MenuItem>
                          </Select>
                        }
                        name="filter"
                        control={control}
                        defaultValue={filter ? filter : 1}
                        value={filter ? filter : 1}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={t('form:commons.input_search')}
                      name="name"
                      type="text"
                      variant="outlined"
                      defaultValue={''}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.actions}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      {t('button:commons.btn_advanced_filter')}
                      <Search />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
      />
    </div>
  );
};
