import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import theme from 'theme';

const PlatformApresentation = ({ company_platform }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
          <Box className={classes.contentBox}>
            <Box>
              <Typography
                className={classes.title}
                style={{
                  color: company_platform?.title_color,
                }}
              >
                {company_platform?.title}
              </Typography>
            </Box>
            <Box className={classes.descriptionBox}>
              <Typography
                className={classes.description}
                style={{
                  color: company_platform?.description_color,
                }}
              >
                {company_platform?.description?.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          {index < company_platform?.description.split('\n').length - 1 && <br />} 
                        </span>
                      ))}      
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
                {company_platform?.ctaButtons
                  ?.sort((a, b) => {
                    return a?.order > b?.order ? 1 : -1;
                  })
                  ?.map(({ text, text_color, color, border_color, link }) => (
                    <Grid item md={3} sm={4} xs={6}>
                      <Link
                        target="_blank"
                        href={link}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          fullWidth
                          size="small"
                          variant="outlined"
                          className={classes.btnHover}
                          style={{
                            backgroundColor: color,
                            borderStyle: 'solid',
                            borderColor: border_color,
                            padding: theme.spacing(1),
                          }}
                        >
                          <Typography
                            component="span"
                            style={{ color: text_color }}
                          >
                            {text}
                          </Typography>
                        </Button>
                      </Link>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} align="start">
          <video
            className={classes.videoMedia}
            src={company_platform?.video?.url}
            controls
            preload="true"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(12),
  },
  topSpacing: {
    paddingTop: theme.spacing(3),
  },
  videoMedia: {
    width: '100%',
    maxWidth: 700,
  },
  contentBox: {
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: 32,
    fontWeight: 300,
    lineHeight: 1.2,
    marginBottom: theme.spacing(3)
  },
  description: {
    fontSize: 18,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 1.35,
    marginBottom: theme.spacing(6)
  },
  descriptionBox: {
    paddingRight: theme.spacing(18),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0),
    }
  },
  btnHover: {
    borderRadius: 0,
    minHeight: 45,
    '&:hover': {
      boxShadow: '0px 8px 25px 0px #2A2E4329',
    }
  }
}));

export default PlatformApresentation;
