import { takeLatest, call, put, all } from 'redux-saga/effects';
// import { toast } from 'react-toastify';
import { notificationSuccess } from './actions';
import NotificationData from 'services/dataService/NotificationData';

export function* loadNotifications() {
  try {
    const response = yield call(NotificationData.getNotifications);

    const notifications = response.data.notification;
    const latests = response.data.notification.slice(-5);
    const unreaded = response.data.notification.filter(x => !x.read).length;
    yield put(
      notificationSuccess(notifications, unreaded, latests)
    );
  } catch (err) {
    // toast.error('Error in Vimeo');
    // yield put(uploadFailure());
  }
}

export default all([
  takeLatest('@notification/NOTIFICATION_REQUEST', loadNotifications),
]);
