import React, {useEffect, useState} from 'react'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import {  Button, Container, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import { useTheme } from '@material-ui/styles'
import useWindowDimensions from 'customHooks/DimensionHook'
import { useSelector } from 'react-redux'

export default () => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards', 'message'])
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const theme = useTheme()
  const { width } = useWindowDimensions()
  const { modules } = useSelector( state => state.learn )
  let [firstCourse, setFirstCourse] = useState(null)

  useEffect( () => {
    const allModules = [...modules].sort( (a,b) => a.order - b.order ).map( _ => _.lessons ).flat()
    if( allModules.length > 0 )
    {
      setFirstCourse(allModules[0].id)
    }
  }, [modules])


  return (
    <Container className={classes.root}>
      <Typography className={classes.titleTextContainer} component="span" style={{ fontSize: width > 960 ? '4.8vw' : '8vw' }}>
        {t('message:learnScreen.title')}
      </Typography>

      <Typography color="primary" className={classes.titleTextContainer} style={{ fontSize: width > 960 ? '4.8vw' : '8vw' }} component="span">
        {t('message:learnScreen.subtitle')}
      </Typography>

      <Typography color="textSecondary" className={classes.descriptionText} style={{fontSize: width > 960 ? '1.6vw' : '4vw',}}>
        {t('message:learnScreen.description')}
      </Typography>

      <Button variant="contained" color="primary" disabled={firstCourse === null} onClick={ () => history.push(`/course/${id}/learn/${firstCourse}`) }>
        <CheckIcon fontSize={'default'} style={{ lineHeight: '120%', paddingRight: '2.5%' }} />
        <Typography component="span" color="inherit" style={{fontSize: width > 960 ? 'large' : 'medium', lineHeight: '120%'}}>
          {t('message:learnScreen.action')}
        </Typography>
      </Button>
    </Container>
  )
}
