import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const PlatformAvaliblePayments = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container className={classes.gridContainer} justify='space-between'>
        <Grid item xs={4} md={3} className={classes.boxImg}>
          <img className={classes.image} width={80} src="/icons/PIX.png" />
        </Grid>
        <Grid item xs={4} md={3} className={classes.boxImg}>
          <img className={classes.image} width={80} src="/icons/elo.png" />
        </Grid>
        <Grid item xs={4} md={3} className={classes.boxImg}>
          <img className={classes.image} width={80} src="/icons/boleto.png" />
        </Grid>
        <Grid item xs={4} md={3} className={classes.boxImg}>
          <img
            className={classes.image}
            width={80}
            src="/icons/hipercardlogo.png"
          />
        </Grid>
        <Grid item xs={4} md={3} className={classes.boxImg}>
          <img
            className={classes.image}
            width={80}
            src="/icons/mastercard.png"
          />
        </Grid>
        <Grid item xs={4} md={3} className={classes.boxImg}>
          <img className={classes.image} width={80} src="/icons/Visa.png" />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: '#EEEEEE',
    borderRadius: '0 0 10px 10px'
  },
  gridContainer: {
    width: '100%',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    }
  },
  boxImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
    margin: 5,
    padding: '0 24px !important',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0px 0px 10px #2A2E4326',
    [theme.breakpoints.down(1450)]: {
      height: 60,
      padding: '0 17px !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: 60,
      padding: '0 28px !important',
    }
  },
  image: {
    width: '100%',
    maxWidth: 100,
  },
}));

export default PlatformAvaliblePayments;
