import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Drawer, Hidden } from '@material-ui/core';
import { SidebarNav } from './components';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'customHooks/DimensionHook';
import PublicCoursesViewsData from 'services/dataService/PublicCoursesViewsData';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 240,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 283,
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  backbutton: {
    display: 'flex',
    alignItems: 'center',
    color: '#000000DE',
    fontSize: 16,
    marginTop: 15,
    marginLeft: 10,
    marginBottom: 15,
    cursor: 'pointer',
  },
  textItem: {
    color: '#000000DE',
    fontSize: 16,
    marginLeft: 10,
  },
  headerSidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 64,
  },
  linkLogo: {
    display: 'flex'
  },
  logo: {
    maxWidth: 166,
    objectFit: 'contain',
    maxHeight: 40,
  },
}));

class ProfileType {
  static ADMIN = 1;
  static STUDENT = 2;
  static TEACHER = 3;
}

const Sidebar = (props) => {
  const { type, pendent_attendance } = useSelector((state) => state.auth);
  const { open, onClose, variant, ...rest } = props;
  const { t } = useTranslation(['button']);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { company } = useSelector((state) => state);
  const [ customization, setCustomization ] = React.useState({});

  const loadTopbarStyles = async () => {
    try {
      const {
        data: customization,
      } = await PublicCoursesViewsData.getCompanyCustomization(
        company?.domain_company
      );
      setCustomization(customization?.company_header);
    } catch (error) { }
  };

  React.useEffect(() => {
    loadTopbarStyles();
  }, []);

  const drawerContent = () => (
    <>
      {
        width < 600
        ?
        <div className={classes.headerSidebar} style={{ backgroundColor: customization?.background_color }}>
          <RouterLink to="/" className={classes.linkLogo}>
            <img
              alt="Logo"
              src={customization?.logo?.url}
              className={classes.logo}
            />
          </RouterLink>
        </div>
        : 
        <Toolbar />
      }
      <div
        className={classes.backbutton}
        onClick={() =>
          history.push(
            type === ProfileType.ADMIN ? `/courses/${id}/lessons` : '/dashboard'
          )
        }
      >
        <ChevronLeft />
        <span className={classes.textItem}>{t('button:commons.btn_back')}</span>
      </div>

      <SidebarNav className={classes.nav} />
    </>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={onClose}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {drawerContent()}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          {drawerContent()}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
