import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Visibility, VisibilityOff, Save, ArrowBackIos } from '@material-ui/icons/';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  FormHelperText,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useStyles } from './styles';
import {
  MyUserData,
  FilesData,
  CustomFieldsData,
  EnrollmentData,
} from 'services/dataService';
import { userAvatar, changeUserName } from 'store/modules/auth/actions';
import { useDispatch } from 'react-redux';
import { PhoneMaskCustom } from 'components/PhoneMaskCustom';
import { normalizeValues } from '../components/Profile';
import TeamData from 'services/dataService/TeamData';
import CardProfile from './components/CardProfile';
import { CpfMaskCustom } from 'components/CpfMaskCustom';
import Typography from 'theme/typography';

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

export default ({ className, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const [oldVisible, setOldVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState();
  const [idgroup, setIdGroup] = useState();
  const [idAvatar, setIdAvatar] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [passwordSwitch, setPasswordSwitch] = useState(false);
  const [callGetUser, setCallGetUser] = useState(false);
  const [oldIdAvatar, setOldIdAvatar] = useState();
  const [customFields, setCustomFields] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [typeGroup, setTypeGroup] = useState();
  const [userCardData, setUserCardData] = useState({
    name: '',
    group: '',
    quantityCourses: 0,
    teams: '',
  });


  const dispatch = useDispatch();

  const history = useHistory();

  const [data, setData] = useState({
    tags: [],
    avaliable_tags: [],
  });

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
    watch,
  } = useForm();
  // console.log(watch());
  // console.log('default', defaultValues);
  // console.log('custom', customFields);

  const cpf = useRef({});
  cpf.current = watch('cpf');

  const { id_company, id } = useSelector((state) => state.auth);

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);
  const classes = useStyles();

  async function onSubmit(data) {
    data.email = data.email.toLowerCase()

    const formValue = {
      ...data,
    };
    formValue.array_custom_field_values = data.array_custom_field_values?.map(
      (fields) => {
        const fieldName = Object.keys(fields)[0];
        return {
          fieldName: fieldName,
          fieldValue: fields[fieldName],
        };
      }
    );
    formValue.id_company = id_company;

    let responseAvatar = null;

    if (avatar && (!idAvatar || oldIdAvatar)) {
      responseAvatar = await FilesData.addFile(avatar, 'Profile');
      formValue.id_avatar = responseAvatar.data.id;
    }

    if (!avatar && !avatarUrl) {
      formValue.id_avatar = null;
      const avatarProfile = {
        name: null,
        url: null,
        path: null,
      };
      dispatch(userAvatar(avatarProfile));
    }

    if (formValue.password.length === 0) {
      formValue.password = null;
    }
    if (formValue.old_password.length === 0) {
      formValue.old_password = null;
    }
    formValue.id_group = idgroup;
    try {
      if (id) {
        await MyUserData.updateUser(id, formValue);
      } else {
        await MyUserData.addUser(formValue);
      }

      if (avatar && (!idAvatar || oldIdAvatar)) {
        const avatarProfile = {
          name: responseAvatar.data.name,
          url: responseAvatar.data.url,
          path: responseAvatar.data.path,
        };
        dispatch(userAvatar(avatarProfile));
      }
      if (oldIdAvatar) {
        await FilesData.deleteFile(oldIdAvatar);
        setOldIdAvatar();
      }

      dispatch(changeUserName(data.name));

      setCallGetUser((value) => !value);
      toast.success(t('toastify:commons.toast_success'));
    } catch (error) {
      console.error(`error editing profile: `, error);
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }

  const fetchFields = async () => {
    const { data: customFields } = await CustomFieldsData.getCustomFields();
    return JSON.parse(customFields?.custom_fields || '[]').filter(
      (a) => a?.visibleToEdit === true
    );
  };

  const handlePasswordChange = () => {
    setPasswordSwitch((prev) => !prev);
  };

  const handleClickShowOldPassword = () => {
    setOldVisible(!oldVisible);
  };

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const fetchDataUser = async () => {
      const response = await MyUserData.getUsersById(id);
      const userTeams = await TeamData.getTeamsName(id);
      const countUserCourse = await EnrollmentData.countByUserEnrollments(id);
      if (response.data.avatar) {
        setAvatarUrl(response.data.avatar.url);
      }
      setTypeGroup(response.data.group)

      setUserCardData({
        name: response.data.name,
        group: response.data.group.name,
        quantityCourses: countUserCourse.data.count,
        teams: userTeams.data,
      });

      const {
        name,
        email,
        id_group,
        status,
        cellphone,
        phone,
        situation,
        id_avatar,
        custom_field_values,
        cpf,
      } = response.data;

      setIdGroup(id_group)

      if (response.data.custom_field_values) {
        response.data.custom_field_values = JSON.parse(custom_field_values);
      } else {
        response.data.custom_field_values = [];
      }

      const company_custom_fields = await fetchFields();

      setDefaultValues(
        normalizeValues(
          company_custom_fields,
          response.data.custom_field_values
        )
      );

      setCustomFields(company_custom_fields);
      id_avatar && setIdAvatar(id_avatar);

      setValue([
        { name },
        { email },
        { id_group: id_group },
        { status },
        { phone },
        { cellphone },
        { situation },
        { cpf }
      ]);
    };

    fetchDataUser();

    setLoading(false);
  }, [callGetUser]);

  const titlePage = id
    ? t('title:User.title_edit')
    : t('title:User.title_create');

  const desc = 'Para alterar "Nome" e "CPF" caso tenha alguma divergência, acione os administradores da Plataforma'

  console.log('ttt', typeGroup)

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CardProfile
            cardData={{ ...userCardData, avatar, avatarUrl }}
            onDeleteAvatar={() => {
              idAvatar && setOldIdAvatar(idAvatar);
              setIdAvatar();
              setAvatar();
              setAvatarUrl();
            }}
            onChangeAvatar={(event) => {
              idAvatar && setOldIdAvatar(idAvatar);
              setIdAvatar();
              setAvatar(event.target.files[0]);
              event.target.value = null;
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title={titlePage} />
            <Divider />
            <CardHeader title={desc} />
            {!loading && (
              <form {...rest} onSubmit={handleSubmit(onSubmit)}>
                <CardContent className={clsx(className)}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled={typeGroup?.type !== 1}
                        fullWidth
                        label={t('form:User.input_first_name')}
                        helperText={
                          errors.name?.type === 'required' &&
                          t('validation:commons.validation_required', {
                            field: t('form:User.input_first_name'),
                          })
                        }
                        name="name"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ required: true })}
                        error={!!errors.name}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_email')}
                        helperText={
                          (errors.email?.type === 'required' &&
                            t('validation:commons.validation_required', {
                              field: t('form:User.input_email'),
                            })) ||
                          (errors.email?.type === 'pattern' &&
                            t('validation:commons.validation_email', {
                              field: t('form:User.input_email'),
                            })) ||
                          (errors.email?.type === 'validate' &&
                            t('validation:commons.validation_required', {
                              field: t('form:Signin.email_cpf'),
                            }))
                        }
                        name="email"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({
                          required: false,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          },
                          validate: (value) =>
                            !!cpf.current || !!value
                        })}
                        error={!!errors.email}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: 8 }}>
                    <Grid item md={4} xs={12} className={clsx(classes.select)}>
                      <TextField
                        disabled={typeGroup?.type !== 1}
                        fullWidth
                        label={t('form:User.input_cpf')}
                        helperText={
                          (errors.cpf?.type === 'pattern' &&
                            t('validation:commons.validation_cpf', {
                              field: t('form:User.input_cpf'),
                            })) ||
                          (errors.cpf?.type === 'validate' &&
                            t('validation:commons.validation_required', {
                              field: t('form:Signin.email_cpf'),
                            }))
                        }
                        name="cpf"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({
                          required: false,
                          pattern: {
                            value: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/i,
                          },
                        })}
                        error={!!errors.cpf}
                        InputProps={{
                          inputComponent: CpfMaskCustom,
                        }}
                      />
                    </Grid>

                    <Grid item md={4} xs={12} className={clsx(classes.select)}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_cellphone')}
                        name="cellphone"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputRef={register({ required: false })}
                        error={!!errors.cellphone}
                        InputProps={{
                          inputComponent: PhoneMaskCustom,
                        }}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:User.input_telephone')}
                        name="phone"
                        type="text"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputComponent: PhoneMaskCustom,
                        }}
                        inputRef={register({ required: false })}
                        error={!!errors.phone}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{ marginTop: 8 }}>
                    <Grid item md={6} xs={12}>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        error={!!errors.old_password}
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          {t('form:User.input_password')}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-old-password"
                          type={oldVisible ? 'text' : 'password'}
                          name="old_password"
                          inputRef={register({ minLength: 6 })}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {oldVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={230}
                        />
                        <FormHelperText>
                          {(errors.password?.type === 'required' &&
                            t('validation:commons.validation_required', {})) ||
                            (errors.password?.type === 'minLength' &&
                              t('validation:commons.validation_min_pass', {
                                minLength: 6,
                              }))}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        error={!!errors.password}
                      >
                        <InputLabel htmlFor="outlined-new-password">
                          {t('form:User.input_new_password')}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-new-password"
                          type={visible ? 'text' : 'password'}
                          name="password"
                          inputRef={register({
                            minLength: 6,
                          })}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {visible ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={230}
                        />
                        <FormHelperText>
                          {(errors.password?.type === 'required' &&
                            t('validation:commons.validation_required', {})) ||
                            (errors.password?.type === 'minLength' &&
                              t('validation:commons.validation_min_pass', {
                                minLength: 6,
                              }))}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} style={{ marginTop: 8 }}>
                    {customFields?.map((field, index) => (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            display: !field?.visibleToEdit ? 'none' : 'unset',
                          }}
                        >
                          <TextField
                            disabled={typeGroup?.type !== 1}
                            fullWidth
                            name={`array_custom_field_values[${index}].${field?.fieldName}`}
                            // defaultValue={defaultValues[index].fieldValue || ''}
                            defaultValue={
                              defaultValues[index].fieldValue ||
                              (field?.required && !watch(`array_custom_field_values[${index}].${field?.fieldName}`)
                                ? ' '
                                : '')
                            }
                            variant="outlined"
                            label={field?.fieldName || ''}
                            error={
                              !!!errors?.array_custom_field_values
                                ? false
                                : errors?.array_custom_field_values[index]
                                  ? errors?.array_custom_field_values[index][
                                  field?.fieldName
                                  ]
                                  : false
                            }
                            inputRef={register({
                              required: field?.required || false,
                            })}
                          />
                          <FormHelperText
                            error={
                              !!!errors?.array_custom_field_values
                                ? false
                                : errors?.array_custom_field_values[index]
                                  ? errors?.array_custom_field_values[index][
                                  field?.fieldName
                                  ]
                                  : false
                            }
                          >
                            {!errors?.array_custom_field_values
                              ? ''
                              : errors?.array_custom_field_values[index]
                                ? errors?.array_custom_field_values[index][
                                  field?.fieldName
                                ]?.type === 'required' &&
                                t(
                                  'validation:commons.validation_required',
                                  {}
                                )
                                : ''}
                          </FormHelperText>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </CardContent>

                <Divider />
                <CardActions className={classes.actions}>
                  <Button
                    className={classes.actionButton}
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => history.push('/dashboard')}
                  >
                    <ArrowBackIos />
                    {t('button:commons.btn_back')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                  >
                    <Save style={{ marginRight: 8 }} />
                    {t('button:commons.btn_save')}
                  </Button>
                </CardActions>
              </form>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
