import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';
import { HandleDelete } from 'components/';
import { localizationTable, options } from 'constants/table';
import AWS from 'aws-sdk';

import {
  CardContent,
  Grid,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { useStyles } from './styles';

import { CategoryData, LibraryData } from 'services/dataService';
import { statusCategories } from 'constants/status';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';
import DialogPreviewLibrary from 'components/DialogPreview';

export default () => {
  const [title, setTitle] = useState('');
  const [modalStatus, setModalStatus] = useState(false);
  const [route, setRoute] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewType, setPreviewType] = useState(null);
  const [previewName, setPreviewName] = useState(null);
  const [modalStatusPreview, setModalStatusPreview] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  const handleModalOpen = useCallback(({ id }) => {
    setModalStatus(true);
    setRoute(`library/${id}`);
  });

  const history = useHistory();
  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCategories = await LibraryData.getCategories();
        setCategories(responseCategories.data.categories);        

      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };
    fetchData ();

  }, []);

  const handleEdit = useCallback(
    ({ id }) => {
      history.push(`library/${id}/edit`);
    },
    [history]
  );
  

  const handleDownload = async ({ urlfile , filetype, nameorigem}) => {
    try {
      toast.info(t('toastify:commons.toast_start_download_library'));
      const formValues = {
        fileName: urlfile,
        fileType: filetype,
      };

      const signedurls3 = await LibraryData.signs3geturl(formValues)
      
      const response = await fetch(signedurls3.data.signedUrl);
      const blob = await response.blob();

      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = nameorigem; // You can customize the downloaded file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error downloading file', error);
      toast.error(t('toastify:commons.toast_error_download_library')); 
    }
  };

  const handlePreview = async (rowData) => {
    try {
      const formValues = {
        fileName: rowData.urlfile,
        fileType: rowData.file_type,
      };
      const signedurls3 = await LibraryData.signs3geturl(formValues);
      const url = signedurls3.data.signedUrl;

      setPreviewUrl(url);
      setPreviewType(rowData.file_type);
      setPreviewName(rowData.title);
      setModalStatusPreview(true);
    } catch (error) {
      console.error('Error fetching file for preview', error);
      toast.error(t('toastify:commons.toast_error_download_library'));
    }
  };

  const onSubmit = (data) => {
    setTitle(data.title);
    setCategory(data.category);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };
  
  return (
    <>
    <div className='notranslate'>
    <MaterialTable
        title={`${t('tables:library.title')}`}
        tableRef={tableRef}
        columns={[
          {
            title: `${t('tables:library.name_column')}`,
            field: `title`,
            sorting: false,
            render: (rowData) => rowData.title,
          },
          {
            title: `${t('tables:library.category_column')}`,
            field: `name`,
            sorting: false,
            render: (rowData) => rowData.category.name,
          },
          {
            title: `${t('tables:library.description_column')}`,
            field: 'description',
            sorting: false,
            render: (rowData) => rowData.description,            
          },
        ]}
        data={async (query) => {
          try {
            const response = await LibraryData.getLibrary({
              page: query.page + 1,
              limit: query.pageSize,
              category,
              title,
            });

            return {
              data: response.data.library,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,          
            };
          } catch (err) {
            if (err.response?.status === 403) {
              toast.error(t('toastify:commons.toast_error_403'));
            } else {
              toast.error(t('toastify:commons.toast_error_api'));
            }
            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:Library.input_library')}
                        name="title"
                        type="text"
                        variant="outlined"
                        defaultValue={title || ''}
                        inputRef={register}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl style={{ minWidth: 130 }}>
                        <InputLabel id="category">
                          {t('form:Library.input_category')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={''}>
                               Todas
                              </MenuItem>
                              {categories &&
                              categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                          name="category"
                          control={control}
                          defaultValue={category ? category : ''}
                          value={category ? category : ''}
                        />
                      </FormControl>
                    </Grid>                    
                    <Grid item md={4} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        options={options}
        actions={[
          {
            icon: 'download',
            tooltip: `${t('tables:commons.actions.link_donwload')}`,
            onClick: (event, rowData) => {
              handleDownload(rowData);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'visibility',
            tooltip: `${t('tables:commons.actions.view_default')}`,
            onClick: (event, rowData) => handlePreview(rowData),
            iconProps: { color: 'primary' },
          },
          {
            icon: 'edit',
            tooltip: `${t('tables:commons.actions.edit_action')}`,
            onClick: (event, rowData) => {
              handleEdit(rowData);
            },
            iconProps: { color: 'primary' },
          },
          {
            icon: 'delete',
            tooltip: `${t('tables:commons.actions.delete_action')}`,
            onClick: (event, rowData) => handleModalOpen(rowData),
            iconProps: { color: 'error' },
          },
        ]}
      />
    </div>
      <HandleDelete
        closeModal={setModalStatus}
        isOpen={modalStatus}
        route={route}
        tableRef={tableRef.current}
      />
      <DialogPreviewLibrary
        previewType={previewType}
        previewUrl={previewUrl}
        previewName={previewName}
        modalStatus={modalStatusPreview}
        closeModal={setModalStatusPreview}
      />
    </>
  );
};
