import querystring from 'querystring';
import api from '../api';

class DataServiceTrailCourses {
  addTrailCourses(data) {
    return api.post('trails-courses', data);
  }

  orderTrailCourses(id_trail,data) {
    return api.post(`trails-courses/order/${id_trail}`, data);
  }

  getTrailsCourses(params) {
    const url = 'trails-courses?' + querystring.stringify(params);
    return api.get(url);
  }

  getTrailCoursesById(id) {
    return api.get(`trails-courses/${id}`);
  }

  updateTrailCourses(id, data) {
    return api.put(`trails-courses/${id}`, data);
  }

  delete(id) {
    return api.delete(`trails-courses/${id}`);
  }
}

export default new DataServiceTrailCourses();
