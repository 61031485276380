import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import { ExpandMore, OndemandVideoOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import LessonIcon from './components/LessonIcon';

const CourseContent = ({ modules }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {modules
        ?.sort((a, b) => (a?.order > b?.order ? 1 : -1))
        ?.map((mod) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.heading}>{mod?.title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Box className={classes.accordionDetails}>
                {mod?.lessons
                  ?.sort((a, b) => (a?.order > b?.order ? 1 : -1))
                  ?.map((lesson) => (
                    <>
                      <Box className={classes.row}>
                        <Box>
                          <OndemandVideoOutlined fontSize="small" />
                        </Box>
                        <Box>
                          <Typography
                            component="span"
                            className={classes.contentNumber}
                          >
                            {lesson?.title}
                          </Typography>
                        </Box>
                      </Box>
                      {lesson.content[0] && (
                        <Box className={classes.row}>
                          <Box>
                            <LessonIcon content={lesson.content[0]} />
                          </Box>
                          <Box>
                            <Typography
                              component="span"
                              className={classes.contentNumber}
                            >
                              {lesson.content[0]?.name}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetails: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  row: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(1),
  },
  contentNumber: {
    paddingLeft: theme.spacing(1),
  },
}));

export default CourseContent;

/* <Box>
                  <OndemandVideo fontSize="small" />
                </Box>
                <Box>
                  <Typography
                    component="span"
                    className={classes.contentNumber}
                  >
                    aula 1
                  </Typography>
                </Box> */
