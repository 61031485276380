import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'initial',
  },
  thumbnail: {
    width: 65,
    minWidth: 65,
    height: 65,
    backgroundColor: '#D1D1D1',
    borderRadius: 12,
  },
  details: {
    maxWidth: 696,
  },
  title: {
    color: '#000000',
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    marginTop: 50,
    textAlign: 'left',
  },
  name: {
    color: '#000000',
    fontSize: 15,
    fontWeight: 500,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  description: {
    fontSize: 14,
    color: '#0000008F',
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
  },
  dot: {
    color: '#F20000',
    fontSize: 15,
    marginLeft: 8,
  },
}));
