import React, {
  forwardRef,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Button,
  Dialog, 
  DialogContent, 
  Slide,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { localizationTable } from 'constants/table';
import MaterialTable from 'material-table';
import { LessonContentData } from 'services/dataService';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ 
  isOpen, 
  closeModal, 
  enrollment,
}) {
  const { t } = useTranslation(['toastify', 'modal', 'button', 'tables']);
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const handleClose = () => {
    closeModal(false);
  };


  const releaseFinishedExam = useCallback(
    async (exam) => {
      try {
        await LessonContentData.releaseFinishedExam(
          enrollment.id_user,
          exam.id,
          exam.id_course,
          exam.score 
        );
        toast.success(t('toastify:Course.success_force_course'));
        listExams();
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
        closeModal(false);
      }
    },
    [enrollment]
  );


  const listExams = useCallback(async () => {    
    if (enrollment) {   
      const exams = await LessonContentData.getCourseExams(
        enrollment.id_company_course,
        enrollment.id_user
      );

      const finishedExams = exams.data.map((exam) => ({
        id: exam.id,
        name: exam.name,
        id_course: exam.lesson.id_course,
        score: 0               
      }));      
      setData(finishedExams);
    }
  }, [setData, enrollment]);

  useEffect(() => {
    listExams();
  }, [listExams]);

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <MaterialTable
            title={`${t('modal:course_evaluations.finished')}`}
            ref={tableRef}
            data={data}
            columns={[
              {
                title: `${t(
                  'tables:reports.course.course_evaluations_column'
                )}`,
                field: 'name',
              },
              {
                title: `${t('tables:course.score')}`,
                field: 'score',
                type: 'numeric',               
              },
            ]}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise((resolve, reject) => {
                  const clonedData = [ ...data ]
                  clonedData[rowData.tableData.id][columnDef.field] = newValue
                  setData(clonedData)                                    
                  setTimeout(resolve, 1000);
                });
              }
            }}            
            actions={[
              (rowTable) => ({
                icon: 'list_alt',
                tooltip: `${t('button:Course.btn_force_compeletion')}`,
                onClick: (event, rowData) => releaseFinishedExam(rowData),
                iconProps: {
                  color: 'primary',
                },
              }),
            ]}
            localization={localizationTable(t)}
            options={{
              actionsColumnIndex: -1,
              search: false,
              emptyRowsWhenPaging: false,
              paging: false,
              rowStyle: (rowData) => ({
                backgroundColor:
                  rowData.tableData.id % 2 === 0 ? '#F6F9FC' : '#FFFF',
              }),
            }}
          />        
        </DialogContent>
      </Dialog>
    </div>
  );
}
