import {
  Button,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { HandleDelete } from 'components';
import { statusCategories } from 'constants/status';
import { localizationTable, options } from 'constants/table';
import useWindowDimensions from 'customHooks/DimensionHook';
import MaterialTable, { MTableToolbar } from 'material-table';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TeamData from 'services/dataService/TeamData';
import { startTeamForm } from 'store/modules/teams/actions';
import theme from 'theme';
import TeamIndexHeader from './components/indexHeader';

export default function TeamsView() {
  const classes = useStyles();
  const { open } = useSelector((store) => store.teams);
  const { width } = useWindowDimensions();
  const { auth } = useSelector((state) => state);
  const tableRef = React.useRef();
  const { t } = useTranslation([
    'tables',
    'form',
    'button',
    'label',
    'toastify',
  ]);
  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
  const [filter, setFilter] = React.useState({});
  const { handleSubmit, register, control } = useForm();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [admin] = React.useState(auth.type !== 2);

  const [route, setRoute] = React.useState('');

  const dispath = useDispatch();

  const getTeamsName = async () => {
    try {
      const response = await TeamData.getTeamsName();
      setAutocompleteOptions(response.data);
    } catch (error) {
      console.error(error);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  React.useEffect(() => {
    getTeamsName();
  }, []);

  React.useEffect(() => {
    if (!open && tableRef.current.state.data) {
      refreshTable();
    }
  }, [open]);

  const refreshTable = () => {
    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const onSubmit = (data) => {
    setFilter(data);
    refreshTable();
  };
  return (
    <div className={classes.root}>
      <div className="notranslate">
        <TeamIndexHeader />
        <MaterialTable
          style={{ marginTop: theme.spacing(3) }}
          title={`${t('tables:Teams.title')}`}
          tableRef={tableRef}
          columns={[
            {
              title: `${t('tables:Teams.team_name')}`,
              sorting: false,
              field: `name`,
            },
            // {
            //   title: `${t('tables:Teams.courses')}`,
            //   field: `Courses.length`,
            //   sorting: false,
            //   render: (rowData) => rowData?.Courses?.length,
            // },
            // {
            //   title: `${t('tables:Teams.users')}`,
            //   field: `Users.length`,
            //   sorting: false,
            //   render: (rowData) =>
            //     admin ? rowData?.Users?.length : rowData?.users,
            // },
            {
              title: `${t('tables:commons.status_column')}`,
              field: 'status',
              sorting: false,
              render: (rowData) =>
                t(statusCategories[`${rowData.status}`]?.title),
            },
            {
              title: `${t('tables:Teams.description')}`,
              field: 'description',
              sorting: false,
            },
          ]}
          data={async (query) => {
            const { page, pageSize } = query;
            let response;
            if (auth.type !== 2) {
              response = await TeamData.getTeams({
                page: page + 1,
                limit: pageSize,
                ...filter,
              });
            } else {
              response = await TeamData.getStudentTeams(auth.id, {
                page: page + 1,
                limit: pageSize,
                ...filter,
              });
            }
            return {
              data: response.data.teams,
              page: response.data.currentPage - 1,
              totalCount: response.data.totalCount,
            };
          }}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item md={9} xs={12}>
                        <Autocomplete
                          fullWidth
                          clearOnEscape
                          id="combo-box-demo"
                          options={autocompleteOptions}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              margin="normal"
                              label={t('form:commons.input_search')}
                              variant="outlined"
                              name="name"
                              control={control}
                              inputRef={register}
                            />
                          )}
                        />
                      </Grid>

                      {/* <Grid item md={3} xs={12}>
                        <FormControl
                          style={{
                            minWidth: 100,
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <InputLabel id="status">
                            {t('form:User_solicitation.input_status_select')}
                          </InputLabel>

                          <Controller
                            as={
                              <Select>
                                <MenuItem value="true">
                                  {t(statusCategories['true']?.title)}
                                </MenuItem>
                                <MenuItem value="false">
                                  {t(statusCategories['false']?.title)}
                                </MenuItem>
                              </Select>
                            }
                            name="status"
                            defaultValue="true"
                            control={control}
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid
                        item
                        md={3}
                        xs={12}
                        className={classes.actions}
                        style={{
                          justifyContent:
                            width > 960 ? 'flex-end' : 'flex-start',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          type="submit"
                        >
                          {t('button:User_solictation.btn_advanced_filter')}
                          <Search />
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </div>
            ),
          }}
          localization={localizationTable(t)}
          options={{ ...options, emptyRowsWhenPaging: false }}
          actions={[
            {
              icon: 'edit',
              tooltip: `${t('tables:commons.actions.edit_action')}`,
              onClick: (event, rowData) => dispath(startTeamForm(rowData.id)),
              iconProps: { color: 'primary' },
            },
            {
              icon: 'delete',
              tooltip: `${t('tables:commons.actions.delete_action')}`,
              hidden: auth?.type === 2,
              onClick: (event, rowData) => {
                setRoute(`team/${rowData.id}`);
                setDeleteModalOpen(true);
              },
              iconProps: { color: 'error' },
            },
          ]}
        />
      </div>

      {auth?.type !== 2 && (
        <HandleDelete
          closeModal={setDeleteModalOpen}
          isOpen={deleteModalOpen}
          tableRef={tableRef.current}
          route={route}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  actions: { display: 'flex' },
  root: {
    padding: theme.spacing(3),
  },
}));
