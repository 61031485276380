import { makeStyles } from '@material-ui/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    // top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  loading: {
    position: 'absolute',
    top: '50%',
    marginLeft: 'calc(50% - 30px)',
  },
}));
