import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'space-between',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  certificateBox: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  certificate: {
    position: 'relative',
  },
  topSpacingGrid: {
    marginTop: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(4),
  },
  dropZone: {
    borderRadius: 15,
  },
  rowSwitch: {
    display: 'flex',
  },
  switchLabel: {
    alignSelf: 'center',
    width: '80%',
  },
}));
