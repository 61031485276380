import { Box, Typography } from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import BarcodeIcon from 'MaterialIcons/Barcode';
import QRCodeIcon from 'MaterialIcons/QRCode';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CreditCardPayment from './components/CreditCard';
import CustomPaymentTabs from './components/CustomPaymentTab';

const PaymentOptions = ({ callback }) => {
  const { t } = useTranslation(['views']);
  const classes = useStyles();
  const tabs = [
    <Typography className={classes.tabTitle} component="span">
      <CreditCard className={classes.icotabIcon} />
      {t('views:Payment.credit_card')}
    </Typography>,
    // <Typography className={classes.tabTitle} component="span">
    //   <QRCodeIcon className={classes.icotabIcon} />
    //   {t('views:Payment.pix_payment')}
    // </Typography>,
    // <Typography className={classes.tabTitle} component="span">
    //   <BarcodeIcon className={classes.icotabIcon} /> {t('views:Payment.boleto')}
    // </Typography>,
  ];
  return (
    <Box className={classes.boxPayment}>
      <CustomPaymentTabs tabs={tabs}>
        <CreditCardPayment index={0} callback={callback} />
        <Box index={1}>Pix</Box>
        <Box index={2}>Boleto</Box>
      </CustomPaymentTabs>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  boxPayment: {
    marginTop: theme.spacing(1),
    borderRadius: 10,
    boxShadow: '0px 20px 40px 0px #2A2E4329',
    '& > div': {
      borderRadius: 10,
    }
  },
  tabTitle: {
    alignItems: 'center',
    display: 'flex',
    textTransform: 'none',
    fontWeight: 700,
  },
  icotabIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default PaymentOptions;
