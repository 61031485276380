import produce from 'immer';
import {
  CHANGE_COMPANY_THEME,
  DOMAIN_COMPANY,
  ONLY_INVITED_USERS,
  ACCESS_REQUEST,
  SHOW_ANSWERS,
  SHOW_LIBRARY,
  SHOW_PRESENCIAL,
  SHOW_TRAILS,
} from './actions';

const INITIAL_STATE = {
  only_invited_users: false,
  //access_request: false,
  domain_company: '',
  theme: null,
};

export default function company(state = INITIAL_STATE, action) {

  return produce(state, (draft) => {
    switch (action.type) {
      case ONLY_INVITED_USERS: {
        draft.only_invited_users = action.payload;
        break;
      }
      case ACCESS_REQUEST: {
        draft.access_request = action.payload;
        break;
      }
      case SHOW_ANSWERS: {
        draft.show_answers = action.payload;
        break;
      }
      case SHOW_LIBRARY: {
        draft.show_library = action.payload;
        break;
      }
      case SHOW_PRESENCIAL: {
        draft.show_presencial = action.payload;
        break;
      }
      case SHOW_TRAILS: {
        draft.show_trails = action.payload;
        break;
      }
      case DOMAIN_COMPANY: {
        draft.domain_company = action.payload;
        break;
      }
      case CHANGE_COMPANY_THEME: {
        draft.theme = action.payload;
        break;
      }
      default:
    }
  });
}
