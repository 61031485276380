import { ProfileType } from 'constants/types';
import querystring from 'querystring';
import { store } from 'store';
import api from '../api';

class TeamData {
  getTeams(query) {
    return api.get(`/teams?${querystring.stringify(query)}`);
  }

  getUsersTeams(id, query) {
    return api.get(`/teams/by-users/${id}?${querystring.stringify(query)}`);
  }

  getStudentTeams(id, query) {
    return api.get(
      `/teams/student-teams/${id}?${querystring.stringify(query)}`
    );
  }

  getFields() {
    return api.get('/teams/fields');
  }

  getTeamsName(id_user) {
    let id = '';
    if (!id_user) {
      const { auth } = store.getState();
      if (auth.type === ProfileType.STUDENT) {
        id = `/${auth.id}`;
      }
    }
    return api.get(`/teams/name${id_user ? `/${id_user}` : id}`);
  }

  getTeamById(id) {
    return api.get(`/team/${id}`);
  }

  createTeam(body) {
    return api.post('/team', body);
  }

  updateTeam(id, body) {
    return api.put(`/team/${id}`, body);
  }

  deleteTeam(id) {
    return api.delete(`/team${id}`);
  }

  getUserUnassigned(id) {
    return api.get(`/teams/unassigned/${id}`);
  }

  addUserToTeam(id_team, id_user) {
    return api.put(`/teams/add-user/${id_team}`, { id_user });
  }
}

export default new TeamData();
