import React, { useState, Fragment } from 'react';
import { useStyles } from './styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TableForm from '../TableForm/index';

export default function Row({ opened, row, onChangeValue, onDeleteValue, hasBgColor }) {
  const { t } = useTranslation(['toastify', 'views' ]);
  const [open, setOpen] = useState(opened);
  const [name, setName] = useState(row.fieldName);
  const classes = useStyles();

  return (
    <Fragment>
      <TableRow style={hasBgColor ? { backgroundColor: '#F2F5F9' } : {}}>
        <TableCell align="left" component="th" scope="row">
          {row.id || '1'}
        </TableCell>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left">
          {row.visibleToCreate && (
            <div
              style={{ backgroundColor: '#F0AD4E' }}
              className={classes.label}
            >
              {t('views:customFields.insert')}
            </div>
          )}
          {row.visibleToEdit && (
            <div
              style={{ backgroundColor: '#F0AD4E' }}
              className={classes.label}
            >
              {t('views:customFields.update')}
            </div>
          )}
          {row.visibleToRegister && (
            <div
              style={{ backgroundColor: '#F0AD4E' }}
              className={classes.label}
            >
              {t('views:customFields.register')}
            </div>
          )}
        </TableCell>
        <TableCell align="left">
          <div style={{ backgroundColor: '#2DCE98' }} className={classes.label}>
            {t('views:customFields.customized')}
          </div>
          {row.required && (
            <div
              style={{ backgroundColor: '#2DCE98' }}
              className={classes.label}
            >
              {t('views:customFields.required')}
            </div>
          )}
        </TableCell>
        <TableCell align="center">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowDownIcon
                style={{ color: '#000000' }}
                className={clsx([classes.expandIcon, classes.icons])}
              />
            ) : (
              <KeyboardArrowLeftIcon
                className={clsx([classes.expandIcon, classes.icons])}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TableForm row={row} onChangeValue={onChangeValue} onDeleteValue={onDeleteValue} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
