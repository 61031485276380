import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Interweave from 'interweave';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CourseDetail = ({ details }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views']);
  // methodology
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography
          className={clsx(classes.row, classes.titleRow)}
          color="textSecondary"
          component="span"
        >
          {t('views:CourseRegistration.course_authors')}
        </Typography>
        <Typography className={classes.row} component="span">
          <Interweave content={details?.courseAuthorName} />
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography
          className={clsx(classes.row, classes.titleRow)}
          color="textSecondary"
          component="span"
        >
          {t('views:CourseRegistration.course_target_audience')}
        </Typography>
        <Typography className={classes.row} component="span">
          <Interweave content={details?.target_audience} />
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography
          className={clsx(classes.row, classes.titleRow)}
          color="textSecondary"
          component="span"
        >
          {t('views:CourseRegistration.course_method')}
        </Typography>
        <Typography className={classes.row} component="span">
          <Interweave content={details?.methodology} />
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography
          className={clsx(classes.row, classes.titleRow)}
          color="textSecondary"
          component="span"
        >
          {t('views:CourseRegistration.course_duration')}
        </Typography>
        <Typography className={classes.row} component="span">
          <Interweave content={`${details?.workload} Horas`} /> 
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Typography
          className={clsx(classes.row, classes.titleRow)}
          color="textSecondary"
          component="span"
        >
          {t('views:CourseRegistration.course_certification')}
        </Typography>
        <Typography className={classes.row} component="span">
          <Interweave content={t('views:CourseRegistration.certification_release')} />
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'block',
    '& span': {
      fontSize: '15px !important',
      fontFamily: 'Montserrat !important',
      color: '#000 !important',
      backgroundColor: 'transparent !important',
      fontWeight: 400
    }
  },
  titleRow: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
}));

export default CourseDetail;
