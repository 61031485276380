import { Box, Button, IconButton } from '@material-ui/core';
import { RemoveCircleOutline, ZoomOutMap } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const FeturedCourseDraggableSort = ({
  coursesList,
  trailsList,
  onOrderChange,
  onOrderChangeTrails,
  onDelete,
  onDeleteTrails
}) => {
  const classes = useStyles();
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const removeCourse = (id) => {
    const trails = trailsList.filter((c) => c.id !== id);
    if (onOrderChangeTrails) {
      onOrderChangeTrails(
        trails.map(({ id, name, tag }, index) => {
          return { id, name, index, tag };
        })
      );
    }
    if (onDeleteTrails) {
      onDeleteTrails(id);
    }

    const courses = coursesList.filter((c) => c.id !== id);
    if (onOrderChange) {
      onOrderChange(
        courses.map(({ id, name, tag }, index) => {
          return { id, name, index,tag };
        })
      );
    }
    if (onDelete) {
      onDelete(id);
    }
  };
  
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    const { source, destination } = result;
  
    const itemsList = [...coursesList, ...trailsList].map((item, index) => ({
      ...item,
      type: item.hasOwnProperty('tag') ? 'trail' : 'course',
      index,
    }));
  
    const items = reorder(itemsList, source.index, destination.index);  
    const updatedItems = items.map((item, index) => ({
      ...item,
      index,
    }));
  
    if (onOrderChange) {
      onOrderChange(
        updatedItems.filter((item) => item.type === 'course')
      );
    }
    if (onOrderChangeTrails) {
      onOrderChangeTrails(
        updatedItems.filter((item) => item.type === 'trail')
      );
    }
  };
  

  return (
    <Box>
      <Box className={classes.header}>
        <Box className={clsx(classes.numberHash, classes.title)}>#</Box>
        <Box className={clsx(classes.name, classes.title)}>Curso</Box>
      </Box>
      <Box className={classes.container}>
        <DragDropContext onDragEnd={onDragEnd}>
          {coursesList?.map(({ id, name }, index) => (
            <Droppable key={index} droppableId={`${index}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        className={clsx(
                          classes.courseContainer,
                          index % 2 === 0 && classes.evenCourse
                        )}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box className={classes.numberHash}>{index + 1}</Box>
                        <Box className={classes.content}>{name}</Box>
                        <Box className={classes.action}>
                          <Box className={classes.actionIcons}>
                            <IconButton
                              onClick={() => {
                                removeCourse(id);
                              }}
                            >
                              <RemoveCircleOutline color="error" />
                            </IconButton>
                          </Box>
                          <Box className={classes.actionIcons}>
                            <IconButton disableTouchRipple>
                              <ZoomOutMap color="primary" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                </div>
              )}
            </Droppable>
          ))}
          {trailsList?.map(({ id, name }, index) => (
            <Droppable key={index} droppableId={`${coursesList.length + index}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Draggable
                    key={id}
                    draggableId={id}
                    index={coursesList.length + index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        className={clsx(
                          classes.courseContainer,
                          (coursesList.length + index) % 2 === 0 &&
                          classes.evenCourse
                        )}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box className={classes.numberHash}>
                          {coursesList.length + index + 1}
                        </Box>
                        <Box className={classes.content}>{name}</Box>
                        <Box className={classes.action}>
                          <Box className={classes.actionIcons}>
                            <IconButton
                              onClick={() => {
                                removeCourse(id);
                              }}
                            >
                              <RemoveCircleOutline color="error" />
                            </IconButton>
                          </Box>
                          <Box className={classes.actionIcons}>
                            <IconButton disableTouchRipple>
                              <ZoomOutMap color="primary" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4),
  },
  container: { height: 320, overflowY: 'scroll', overflowX: 'hidden' },
  courseContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
  },
  evenCourse: {
    backgroundColor: theme.palette.background.default,
  },
  numberHash: {
    width: '25%',
    padding: theme.spacing(1),
    alignSelf: 'center',
  },
  name: {
    width: '75%',
    padding: theme.spacing(1),
  },
  title: {
    paddingTop: 0,
    fontWeight: '600',
    fontSize: 'medium',
  },
  content: {
    width: '50%',
    padding: theme.spacing(1),
    alignSelf: 'center',
  },
  action: { width: '25%', display: 'flex', justifyContent: 'center' },
}));

export default FeturedCourseDraggableSort;
