import React from 'react';
import useStyles from './style';
import PropTypes from 'prop-types';
import { Button, CardActions } from '@material-ui/core';
import { Save, Add } from '@material-ui/icons';

const ButtonsAction = ({ isUpdate = false, cancelBtn, saveBtn }) => {
  const classes = useStyles();

  return (
    <CardActions className={classes.actions}>
      <Button
        color={cancelBtn.color || 'primary'}
        size={cancelBtn.size || 'large'}
        onClick={cancelBtn.handleCancelClick}
        {...cancelBtn}
      >
        {cancelBtn.text}
      </Button>

      <Button
        className={classes.buttonSpace}
        type={saveBtn.type || 'submit'}
        color={saveBtn.color || 'primary'}
        size={saveBtn.size || 'large'}
        variant="contained"
        onClick={saveBtn.handleSaveClick || (() => {})}
        {...saveBtn}
      >
        
        {isUpdate ? <Add className={classes.iconStyle} /> : <Save className={classes.iconStyle}/>}
        
        {saveBtn.text}
        
      </Button>
    </CardActions>
  );
};

ButtonsAction.propTypes = {
  isUpdate: PropTypes.bool,
  cancelBtn: PropTypes.shape({
    text: PropTypes.string.isRequired,
    handleCancelClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
  }),
  saveBtn: PropTypes.shape({
    text: PropTypes.string.isRequired,
    handleSaveClick: PropTypes.func,
    type: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
  }),
};

export default ButtonsAction;
