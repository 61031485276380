import React from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { notificationRequest } from 'store/modules/notification/actions';
import { useTranslation } from 'react-i18next';
import  Interweave from 'interweave';
import Node from 'interweave';
import { Button, Card } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import { toast } from 'react-toastify';
import { localizationTable, options } from 'constants/table';
import NotificationData from 'services/dataService/NotificationData';
import { ReportCompanyData } from 'services/dataService';

import FormSearch from './components/FormSearch';

export default () => {
  const classes = useStyles();
  const { t } = useTranslation(['toastify', 'tables']);
  const intlOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const tableRef = React.useRef();
  const dispatch = useDispatch();
  const columns = [
    {
      title: `${t('tables:Notification.number')}`,
      field: 'number',
      cellStyle: {
        width: '5%',
      },
      headerStyle: {
        width: '5%',
      },
    },
    {
      title: `${t('tables:Notification.received_at')}`,
      field: 'available_at',
      cellStyle: {
        width: '80%',
      },
      headerStyle: {
        width: '80%',
      },
      render: (rowData) => {
        return new Intl.DateTimeFormat('pt-BR', intlOptions).format(
          new Date(rowData.available_at)
        );
      },
    },
    {
      title: 'Status',
      field: 'read',
      cellStyle: {
        width: '15%',
      },
      headerStyle: {
        width: '15%',
      },
      render: (rowData) => (
        <div>
          {rowData.title == 'Download Relatório' || rowData.title == 'Download Relatório de Trilha' || rowData.title == 'Download Relatório Presencial'
          ?<Button
              className={`${classes.buttonStyle} ${                
                rowData.read ? classes.colorReportRead : classes.colorReportUnread
              }`}
              onClick={() => download(rowData)}
            >
              {'Baixar'}
            </Button>
          :   <Button
                className={`${classes.buttonStyle} ${
                  rowData.read ? classes.colorRead : classes.colorUnread
                }`}
                onClick={() => handleOpenDetail(rowData)}
              >
                {rowData.read
                  ? t('tables:Notification.status_column.read')
                  : t('tables:Notification.status_column.unread')}
              </Button>       
          }
         
        </div>
      ),
    },
  ];

  const fetchNotifications = async () => {
    dispatch(notificationRequest());
  };

  const fetchTableNotifications = async (query) => {
    try {
      const res = await NotificationData.getNotificationsPage({
        limit: query.pageSize,
        page: query.page,
      });

      const data = res.data.notification.map((notif, index) => {
        notif.number =
          res.data.totalCount - query.page * query.pageSize - index;
        return notif;
      });

      return {
        data,
        page: res.data.currentPage,
        totalCount: res.data.totalCount,
      };
    } catch (err) {
      toast.error(t('toastify:commons.toast_error_api'));
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  function transform(node, children) {
    const hrefValue = node.getAttribute('href');

    if (hrefValue && node.tagName.toLowerCase() === 'a') {
      let link = '/';
      const routePath = hrefValue.match(/\/[\w.-]+/g);
      if (routePath.length === 1) {
        link = '/';
      } else {
        routePath.shifthandleOpenDetaildown();
        link = routePath.join('');
      }
      return <Link to={link}>{children}</Link>;
    }
  }

  const handleOpenDetail = (data) => {
    tableRef.current.onToggleDetailPanel([data.tableData.id], (rowData) => {
      return (
        <div className={classes.readNotification}>
          <Interweave content={rowData.content} transform={transform} />
        </div>
      );
    });

    if (!data.read) {
      NotificationData.updateNotification(data._id, { read: true });
      data.read = true;
      setTimeout(() => {
        fetchNotifications();
      }, 1000);
    }
  };

  function transform(node, children)  {
    if (node.tagName === 'a') {
        return <a href={node.getAttribute('href')}>{children}teste</a>;
    }
}

  const onSubmit = () => {
    // setFilters({ ...filters, name: name });
    // tableRef.current.state.query.page = 0;
    // tableRef.current.onQueryChange();
    // console.log('Busca de notificações mockada');
  };

  const download = (rowData) => {
    
    if (rowData?.title === 'Download Relatório de Trilha') {
      if (rowData.read == false) {
        localStorage.removeItem("RelatorioTrilhaDownload");
      }
      handleOpenDetail(rowData);
      ReportCompanyData.downloadReportTrail(); 
    } else if (rowData?.title === 'Download Relatório Presencial') {
      if (rowData.read == false) {
        localStorage.removeItem("RelatorioPresencialDownload");
      }
      handleOpenDetail(rowData);
      ReportCompanyData.downloadReportAttendance(); 
    } else {
      if (rowData.read == false) {
        localStorage.removeItem("RelatorioAcademicoDownload");
      }
      handleOpenDetail(rowData);
      ReportCompanyData.downloadReport();   
    }
  }
    
  return (
    <>
      <Card className={classes.wrapperTable}>
        <MaterialTable
          title={`${t('tables:Notification.title')}`}
          tableRef={tableRef}
          columns={columns}
          data={fetchTableNotifications}
          components={{
            search: true,
            title: true,
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <FormSearch onSubmit={onSubmit} />
              </div>
            ),
          }}
          localization={localizationTable(t)}
          options={options}
        />
      </Card>
     
    </>
  );
};
