import React, { useRef } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { OndemandVideo, ExpandMore, ClassOutlined, ArrowBackIos, KeyboardArrowUp, ViewList } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { CourseData } from 'services/dataService';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";


function AllCoursesList() {
  const { auth } = useSelector((state) => state);
  const { id } = useSelector((state) => state?.auth);
  const classes = useStyles();
  const { t } = useTranslation(['label', 'button, ']);
  const [loading, setLoading] = React.useState(true);
  const categoryRef = React.useRef([]);
  const [categories, setCategories] = React.useState([]);
  const history = useHistory();
  const scrollRef = useRef(null);

  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  function stripHtmlTags(htmlString) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText;
  }

  function formatWorkload(workload) {
    const parts = String(workload).split('.');
    if (parts.length > 0) {
      const integerPart = parts[0];
      const formattedWorkload = parseInt(integerPart);
      return formattedWorkload;
    }
    return workload;
  }

  React.useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    try {
      const { data: content } = await CourseData.getCoursesModules(auth?.id);

      const filterCourses = (course) => {
        const coursesToFilter = [
          "Adobe Premiere",
          "Webinar - LGPD - Lei Geral de Proteção de Dados",
          "Webinar - Maio amarelo",
          "Treinamento RD Station",
          "MIP - Suporte e Atendimento",
          "Gestão de dados dos Clientes - CRM"
        ];
        return !coursesToFilter.includes(course.name);
      };

      const filteredContent = {};
      for (const categoryKey in content) {
        if (content.hasOwnProperty(categoryKey) && categoryKey !== "Saúde e Segurança") {
          filteredContent[categoryKey] = {
            courses: {},
          };
          for (const courseKey in content[categoryKey].courses) {
            if (content[categoryKey].courses.hasOwnProperty(courseKey)) {
              if (filterCourses(content[categoryKey].courses[courseKey])) {
                filteredContent[categoryKey].courses[courseKey] =
                  content[categoryKey].courses[courseKey];
              }
            }
          }
        }
      }

      setCategories(filteredContent);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(t('toastify:commons.toast_error_api'));
    }
  };

  return (
    <div className={classes.root}>
      <div ref={scrollRef} />
      <div className={classes.btnBack}
        style={{ width: '95%', display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => history.goBack()}
        >
          <ArrowBackIos style={{ marginRight: '4px' }} />
          {t('button:commons.btn_back')}
        </Button>
        <Tooltip title={"Aqui você encontra a nossa lista de cursos para download, assim como os cursos que estão em processo de produção"}>
          <a
            href="https://drive.google.com/drive/folders/1zZ7vMesNCak0vwxcqBA4yog6Z3iEuZTX"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              color="primary"
              variant="outlined"
              size="medium"
            >
              <ViewList style={{ marginRight: '5px' }} />
              {t('button:commons.btn_new_courses_prod')}
            </Button>
          </a>
        </Tooltip>
      </div>
      <div className={classes.titlePag}>
        <h1>Conheça Nossos Cursos</h1>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        Object.keys(categories).map((categoryName) => (
          <div key={categoryName} className={classes.teste}>
            <Typography variant="h6" className={classes.categoriesTitle}>
              {categoryName}
            </Typography>
            {Object.keys(categories[categoryName].courses).map((courseName) => (
              <Accordion key={courseName} className={classes.coursesTitle} >
                <AccordionSummary className={classes.centeredText} expandIcon={<ExpandMore />}>
                  <Typography>
                    {categories[categoryName].courses[courseName].name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {Object.keys(categories[categoryName].courses[courseName].modules).map((module) => (
                      <Accordion key={module} >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <div className={classes.iconAndText}>
                            <ClassOutlined className={classes.classOutlinedIcon} />
                            <Typography>{module}</Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.listAccordion}>
                          <div>
                            <List className={classes.listAccordion}>
                              {categories[categoryName].courses[courseName].modules[module].lessons.map((lesson) => (
                                <ListItem key={lesson.id}>
                                  <OndemandVideo
                                    className={classes.iconVideo}>
                                  </OndemandVideo>
                                  <ListItemText primary={lesson.title} />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <div className={classes.boxContent}>
                    <Box>
                      <Typography variant="subtitle1" className={classes.workloadText} style={{ fontSize: 12 }}>
                        <strong>Carga Horária:</strong>  {formatWorkload(categories[categoryName].courses[courseName].workload)} Horas
                      </Typography>
                      <Typography variant="subtitle1" className={classes.categoriesText} style={{ fontSize: 12 }}>
                        <strong>Objetivos:</strong>  {stripHtmlTags(categories[categoryName].courses[courseName].description)}
                      </Typography>
                    </Box>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ))
      )}
      <div className={classes.buttonTop}>
        <IconButton
          onClick={scrollToTop}
        >
          <KeyboardArrowUp
            style={{ color: 'white' }}
            fontSize='medium'
          />
        </IconButton>
      </div>
    </div>
  );
}

export default AllCoursesList;