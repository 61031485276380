import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import CustomStepper from 'components/CustomStepper';
import PaymentComponent from 'components/PaymentComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import history from 'services/history';
import JunoAPIService from 'services/paymentService/JunoAPIService';
import theme from 'theme';
import PaymentOptions from './components/Payment';
import PaymentDetails from './components/PaymentDetails';
import PaymentFinished from './components/PaymentFinished';
import PersonalData from './components/PersonalData';

export class PaymentSteps {
  static USER_DATA = 0;
  static USER_PAYMENT = 1;
  static PAYMENT_FINISHED = 2;
}

const CourseBuyView = () => {
  const { t } = useTranslation(['views']);
  const [activeStep, setActiveStep] = React.useState(0);
  const { id } = useParams();
  const { id: user_id } = useSelector((state) => state.auth);
  const classes = useStyles();

  const setStep = (step) => setActiveStep(step);

  const checkEnrollment = async () => {
    try {
      await JunoAPIService.checkEnrollment(user_id, id);
    } catch (e) {
      toast.info('Curso já cadastrado');
      history.push(`/course/${id}/enroll`);
    }
  };

  React.useEffect(() => {
    checkEnrollment();
  }, []);

  return (
    <Box>
      <PaymentComponent>
        <Box className={classes.root}>
          <Grid container spacing={4} justify='center'>
            <Grid
              item
              // lg={activeStep < PaymentSteps.PAYMENT_FINISHED ? 7 : 12}
              lg={7}
              md={12}
            >
              <CustomStepper
                progressLabel={
                  t('views:Payment.assistant_labels', {
                    returnObjects: true,
                  }) || []
                }
                activeStep={activeStep}
              >
                <Box step={0}>
                  <PersonalData callback={setStep} />
                </Box>
                <Box step={1} variant="contained">
                  <PaymentOptions callback={setStep} />
                </Box>
                <Box step={2} variant="contained">
                  <PaymentFinished />
                </Box>
              </CustomStepper>
            </Grid>
            {activeStep < PaymentSteps.PAYMENT_FINISHED && (
              <Grid item md={12} lg={5}>
                <PaymentDetails />
              </Grid>
            )}
          </Grid>
        </Box>
      </PaymentComponent>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), paddingTop: theme.spacing(4) },
}));

export default CourseBuyView;
