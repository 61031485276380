import React from 'react';

import {
  SubcategoryToolbar,
  SubcategoryList,
} from 'views/Subcategory/components';
import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SubcategoryToolbar />
      <div className={classes.content}>
        <SubcategoryList />
      </div>
    </div>
  );
};
