import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { TabPanel } from 'components/TabPanel';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function CustomTabComponent({
  components,
  valueReturn,
  outlined,
  className,
}) {
  const classes = useStyles();
  const { t } = useTranslation(['tables']);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (valueReturn) {
      valueReturn(newValue);
    }
  };

  const Panel = ({ component, index, ...props }) => (
    <TabPanel value={value} index={index}>
      <Paper {...props}>{component}</Paper>
    </TabPanel>
  );

  const OutlinedTabComponent = () => {
    return (
      <>
        <OutlinedTabs
          value={value}
          onChange={handleChange}
          aria-label="ant example"
        >
          {components.map((component) => (
            <OutlinedTab label={component.label} />
          ))}
        </OutlinedTabs>
        {components.map(({ component }, index) => (
          <TabPanel value={value} index={index}>
            {component}
          </TabPanel>
        ))}
      </>
    );
  };

  const ContainedTab = () => {
    return (
      <>
        <TabContainer
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label={t('tables:commons.tab')}
        >
          {components.map((component) => (
            <CustomTab label={component.label} />
          ))}
        </TabContainer>
        {components.map(({ component }, index) => (
          <Panel
            className={classes.paper}
            index={index}
            component={component}
          />
        ))}
      </>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.demo2}>
        {outlined ? <OutlinedTabComponent /> : <ContainedTab />}
      </div>
    </div>
  );
}

const TabContainer = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    borderRadius: '5px',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main + '99',
    fontWeight: 'bold',
  },
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: 0,
  },
}));

const OutlinedTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})((props) => <Tabs {...props} />);

const OutlinedTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 500,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.black,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.black,
      // fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: theme.palette.black,
    },
  },
  indicator: { color: theme.palette.primary.main },
  paper: {
    borderRadius: 0,
  },
}))((props) => (
  <Tab
    disableRipple
    {...props}
    TabIndicatorProps={{ children: <span className="indicator" /> }}
  />
));
