import Axios from 'axios';
import api from 'services/api';

class JunoAPIService {
  getAccessToken() {
    return api.get('juno-api/get-credentials');
  }

  createCharge(data) {
    return api.post('/juno-api/createCharge', data);
  }

  checkEnrollment(id_user, id_course) {
    return api.get(`/juno-api/enrollment/${id_user}/${id_course}`);
  }

  checkout(data) {
    return api.post('/juno-api/checkout', data);
  }

  getAddressByPostalCode(postalCode) {
    return Axios.get(`https://viacep.com.br/ws/${postalCode}/json`);
  }
}

export default new JunoAPIService();
