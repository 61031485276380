export function notificationRequest() {
  return {
    type: '@notification/NOTIFICATION_REQUEST',
  };
}

export function notificationSuccess(notifications, unreaded, latests) {
  return {
    type: '@notification/NOTIFICATION_SUCCESS',
    payload: { notifications, unreaded, latests },
  };
}
