import querystring from 'querystring';
import api from '../api';

class DataServiceLibrary {
  addLibrary(data) {
    return api.post('Library', data);
  }

  getLibrary(params) {
    const url = 'library?' + querystring.stringify(params);
    return api.get(url);
  }

  getLibrarystud(params) {
    const url = 'library/stud?' + querystring.stringify(params);
    return api.get(url);
  }

  getLibrarytop10(params) {
    const url = 'library/top10?' + querystring.stringify(params);
    return api.get(url);
  }

  getLibraryById(id) {
    return api.get(`library/${id}`);
  }

  updateLibrary(id, data) {
    return api.put(`library/${id}`, data);
  }

  getCategories(params) {
    const url = 'library/categories' + querystring.stringify(params);
    return api.get(url);
  }

  awsconfig(params) {
    const url = '/library/sdkaws' + querystring.stringify(params);
    return api.get(url);
  }

  getLibraryTeamsById(id) {
    return api.get(`library/teamslibrarybyid/${id}`);
  }

  updateLibraryDadosFile(id, data) {
    return api.put(`library/dadosfile/${id}`, data);
  }

  signs3geturl(params){
    const url = 'library/signs3geturl?' + querystring.stringify(params);
    return api.get(url);
  }

  signs3geturlstudent(params){
    const url = 'library/signs3geturl/student?' + querystring.stringify(params);
    return api.get(url);
  }

  signs3delete(params){
    const url = 'library/signs3delete?' + querystring.stringify(params);
    return api.get(url);
  }

  signs3PutUrl(params){
    const url = 'library/signs3PutUrl?' + querystring.stringify(params);
    return api.get(url);
  }

  uploadItemLibrary(url, data) {
    return api.put(url, data,{ headers: {
      'Content-Type': 'multipart/form-data',
    },});
  }
  
}

export default new DataServiceLibrary();
