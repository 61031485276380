import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FiberManualRecord } from '@material-ui/icons/';
import Interweave from 'interweave';
import { useParams } from 'react-router';
import { CourseData } from 'services/dataService';

export default ({ learnId }) => {
  const { t } = useTranslation(['title', 'label', 'form', 'button', 'cards']);
  const { id } = useParams();
  const [avatar, setAvatar] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [teacherName, setTeacherName] = React.useState('');

  // const getAuthorDetails = async () => {
  //   const { data: courseAuthor } = await CourseData.getCourseAuthorByCourseId(
  //     id
  //   );

  //   setAvatar(courseAuthor?.User?.avatar?.url_thumbnail);
  //   setDescription(courseAuthor?.User?.teacher_description || '');
  //   setTeacherName(courseAuthor?.User?.name);
  // };

  // React.useEffect(() => {
  //   getAuthorDetails();
  // }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <h1 className={classes.title}>
        {t('title:Lessons.lesson_teacher_name')}
      </h1>

      <div className={classes.content}>
        <div
          className={classes.thumbnail}
          style={{
            backgroundImage: `url(${avatar})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        ></div>
        <div className={classes.details}>
          <div className={classes.name}>{teacherName}</div>
          <div className={classes.description}>
            <Interweave content={description} />
          </div>
        </div>
      </div> */}
    </div>
  );
};
