import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaterialTable, { MTableToolbar } from 'material-table';
import CircleIcon from '@material-ui/icons/Brightness1';

import { localizationTable, options } from 'constants/table';

import { CardContent, Grid, Button, TextField } from '@material-ui/core';

import { useStyles } from './styles';

import { ReportCompanyData } from 'services/dataService';
import { statusCompany } from 'constants/status';
import { toast } from 'react-toastify';
import { Search } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import MenuDownloadReport from 'components/MenuDownloadReport';

export default ({ fields }) => {
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState('');

  const { t } = useTranslation([
    'label',
    'toastify',
    'tables',
    'form',
    'button',
    'validation',
  ]);
  const { register, handleSubmit, control } = useForm();
  const tableRef = useRef();

  const classes = useStyles();

  const columnsFields = fields.map(field => ({
    ...field,
    sorting: false,
  }))

  const onSubmit = (data) => {
    setFilter(data.filter);
    setStatus(data.status);

    tableRef.current.state.query.page = 0;
    tableRef.current.onQueryChange();
  };

  const handleFetch = async () => {
    try {
      const response = await ReportCompanyData.getUsersCompanies({
        filter,
        isExport: true,
      });

      return response.data;
    } catch (err) {
      toast.error(
        'Não foi possível buscar os dados do relatório, tente novamente mais tarde.'
      );
      return [];
    }
  };

  return (
    <>
    <div className='notranslate'>
    <MaterialTable
        title={`${t('tables:reports.title_reports_users')}`}
        tableRef={tableRef}
        columns={
          columnsFields || [
            {
              title: 'id',
              field: `id`,
              render: (rowData) => rowData.id,
            },
            {
              title: `${t('tables:commons.name_column')}`,
              field: `name`,
              render: (rowData) => rowData.name,
            },
            {
              title: `${t('tables:commons.email_column')}`,
              field: `email`,
              render: (rowData) => rowData.email,
            },
            {
              title: `${t('tables:reports.academic.created_at')}`,
              field: `created_at`,
              render: (rowData) => rowData.created_at,
            },
            {
              title: 'Telefone',
              field: `phone`,
              render: (rowData) => rowData.phone,
            },
            {
              title: 'Celular',
              field: `cellphone`,
              render: (rowData) => rowData.cellphone,
            },
            {
              title: 'Grupo',
              field: `group.name`,
              render: (rowData) => rowData.group.name,
            },
            {
              title: 'Ultimo Acesso',
              field: `last_login_at`,
              render: (rowData) => {
                if (rowData.last_login_at) {
                  return format(
                    parseISO(rowData.last_login_at),
                    'dd/MM/yyyy HH:MM:SS'
                  );
                } else {
                  return '';
                }
              },
            },
            {
              title: 'Atualizado em',
              field: `updated_at`,
              render: (rowData) =>
                format(parseISO(rowData.updated_at), 'dd/MM/yyyy HH:MM:SS'),
            },
          ]
        }
        data={async (query) => {
          try {
            const response = await ReportCompanyData.getUsersCompanies({
              page: query.page + 1,
              limit: query.pageSize,
              filter: filter,
            });

            let { users, currentPage, totalCount } = response.data;

            users = users?.map((user) => {
              let customFields = {};

              if (user?.custom_field_values) {
                const formattedCustomFieldValue = JSON.parse(
                  user?.custom_field_values
                );

                for (const field of formattedCustomFieldValue) {
                  customFields[field?.fieldName] = field?.fieldValue;
                }
              }

              return { ...user, ...customFields };
            });

            return {
              data: users,
              page: currentPage - 1,
              totalCount,
            };
          } catch (err) {
            toast.error(t('toastify:commons.toast_error_api'));
            return { data: [] };
          }
        }}
        components={{
          search: true,
          title: true,
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label={t('form:commons.input_search')}
                        name="filter"
                        type="text"
                        variant="outlined"
                        defaultValue={filter || ''}
                        inputRef={register}
                      />
                    </Grid>

                    <Grid item md={8} xs={12} className={classes.actions}>
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t('button:User_solictation.btn_advanced_filter')}
                        <Search />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </div>
          ),
        }}
        localization={localizationTable(t)}
        actions={[
          {
            icon: () => (
              <MenuDownloadReport
                handleFetch={handleFetch}
                tableRef={tableRef}
                hasCSV
                hasPDF
              />
            ),
            // tooltip: 'Exportar dados',
            isFreeAction: true,
          },
        ]}
        options={{
          ...options,
          exportButton: false,
        }}
      />
    </div>
    </>
  );
};
