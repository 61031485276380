import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  courseContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },

  lastEditedCoursesContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },

  lastEditedCoursesTitle: {
    marginBottom: theme.spacing(2),
  },

  lastEditedCoursesCardContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },

  editedCoursesCard: {
    width: '30%',
    height: 180,
    backgroundColor: '#ff4',
    borderRadius: 15,
    boxShadow: 10,
  },

  summaryCoursesContainer: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  gridMargin: {
    marginRight: 100,
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const Card = styled.div`
  position: relative;
  min-width: 0;
  width: 220px;
  border-radius: 12px;
  margin-right: 1.5rem;

  transition: 0.3s;
  overflow: hidden;

  .card-img {
    width: 100%;
  }

  .card-body {
    padding-left: 15px;
    h2 {
      font-size: 1em;
    }

    p {
      font-size: 0.8em;
    }
  }

  .overlay {
    position: absolute;
    bottom: 15px;
    width: 100%;
    transition: 0.5s ease;
    color: white;
  }

  .link-circle {
    width: 32px;
    height: 32px;
    padding: 0px;
    border: 5px solid rgba(215, 79, 154, 0.3);

    border-radius: 35px;
    text-align: center;

    -webkit-background-clip: padding-box;
    /* for Safari */
    background-clip: padding-box;
    /* for IE9+, Firefox 4+, Opera, Chrome */

    i {
      font-size: 12px;
    }
  }

  .bg-pink {
    background-color: #ff4f9a;
  }

  .img-circle {
    border-radius: 120px;
  }

  h2 {
    margin: 0 auto;
  }
`;

export const CourseDescription = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .spanButton {
    button {
      cursor: pointer;
      :hover {
        i {
          transition: 0.2s;
          font-size: 18px;
        }
      }
    }
  }
`;
