import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import {
  EmailOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import useWindowDimensions from 'customHooks/DimensionHook';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { signInRequest } from 'store/modules/auth/actions';
import theme from 'theme';

const WelcomeBanner = ({ banner_data, company_data }) => {
  const { register, handleSubmit, errors } = useForm();
  const { only_invited_users } = useSelector((state) => state.company);
  const { t } = useTranslation(['label', 'form', 'views', 'button']);
  const [showPassword, setShowPassword] = useState(false);
  const { width } = useWindowDimensions();

  const classes = useStyles({ company_data });

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ email, password }) => {
      try {
        let login = email.toLowerCase().trim().replace(/[\/\s]/g, '')

        const domain = window.location.hostname;

        dispatch(signInRequest(login, password, domain));
      } catch (err) { }
    },
    [dispatch]
  );


  const handleKeyPress = (event) => {
    if (event.key === ' ' || event.key === '/' || event.key === '\\') {
      event.preventDefault();
    }
  };


  let backgroundImageStyle;
  if (company_data === '4d8984bb-9513-4430-9246-582aa351b1b5') {
    backgroundImageStyle = `url(${banner_data?.banner?.url})`
  } else {
    backgroundImageStyle = `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.28)), url(${banner_data?.banner?.url})`
  }

  return (
    <Box
      style={{
        backgroundImage: backgroundImageStyle,
        height: '90vh'
      }}
      className={classes.root}
    >
      <Box className={classes.container}>
        <Grid container spacing={2} className={classes.boxBanner}>
          <Grid item xs={12} md={4} className={classes.leftBox}>
            <Box className={classes.container}>
              <Box>
                <Typography
                  style={{ color: banner_data?.title_color }}
                  className={classes.title}
                >
                  {banner_data?.title}
                </Typography>
              </Box>
              <Typography className={classes.topSpacing} />
              <Box>
                <Typography
                  style={{ color: banner_data?.description_color }}
                  className={classes.description}
                >
                  {banner_data?.description}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.container}>
              <Grid container spacing={4}>
                {banner_data?.ctaButtons?.map(
                  ({ text, text_color, color, border_color, link }) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <Link
                        target="_blank"
                        href={link}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{
                            backgroundColor: color,
                            borderStyle: 'solid',
                            borderColor: border_color,
                            padding: theme.spacing(1),
                          }}
                          className={classes.hoverBtn}
                        >
                          <Typography
                            component="span"
                            style={{ color: text_color }}
                          >
                            {text}
                          </Typography>
                        </Button>
                      </Link>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.gridForm}>
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className={clsx(classes.container, classes.loginCard)}>
                  <Box>
                    <Typography
                      className={clsx(classes.loginText, classes.loginTitle)}
                    >
                      {t('views:Welcome.access_now')}
                    </Typography>
                  </Box>
                  <Typography className={classes.topSpacing} />
                  <Box className={classes.centerField}>
                    <TextField
                      fullWidth
                      onKeyPress={handleKeyPress}
                      name="email"
                      placeholder={t('form:Signin.email_cpf')}
                      variant="outlined"
                      helperText={
                        (errors.email?.type === 'required' &&
                          t('validation:commons.validation_required', {
                            field: t('form:Company.input_email_cpf'),
                          })) ||
                        (errors.email?.type === 'pattern' &&
                          t('validation:commons.validation_email', {
                            field: t('form:Company.input_email_cpf'),
                          }))
                      }
                      InputProps={{
                        style: {
                          backgroundColor: theme.palette.white,
                          borderRadius: 9,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailOutlined fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      inputRef={register({
                        required: true,
                        pattern: company_data !== '2a48df24-a717-4934-9513-56c036d2f4d3' && {
                          value:
                            /^\s*((\s*\d{11}\s*)|(\s*\d{3}\s*\.\s*\d{3}\s*\.\s*\d{3}\s*-\s*\d{2}\s*)|(\s*[A-Z0-9._%+-]+\s*@\s*[A-Z0-9.-]+\s*\.\s*[A-Z]{2,4}\s*))\s*$/i
                          ,
                        },
                      })}
                      error={!!errors.email}
                    />
                  </Box>
                  <Typography className={classes.topSpacing1} />
                  <Box className={classes.centerField}>
                    <TextField
                      fullWidth
                      name={'password'}
                      placeholder={t('form:Signin.user_password')}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      inputRef={register({ required: true, minLength: 6 })}
                      InputProps={{
                        style: {
                          backgroundColor: theme.palette.white,
                          borderRadius: 9,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlined fontSize="small" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ padding: 5 }}
                            >
                              {showPassword ? (
                                <Visibility fontSize="small" />
                              ) : (
                                <VisibilityOff fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Typography className={classes.topSpacing} />
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        width: '100%',
                        height: '50px',
                        borderRadius: 9,
                        background:
                          company_data === '4b44b51e-c4d0-4209-9fcb-1db56f09b036' ? '#EE1C25'
                            : company_data === '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5' ? 'linear-gradient(135deg, rgba(147, 10, 209, 1) 0%, rgba(190, 52, 218, 1) 35%, rgba(255, 102, 153, 1) 100%)'
                              : company_data === '2a48df24-a717-4934-9513-56c036d2f4d3' ? '#B41414'
                                : null
                      }}
                    >
                      {t('button:commons.btn_signin')}
                    </Button>
                  </Box>
                  <Typography className={classes.topSpacing1} />
                  <Typography
                    className={classes.loginFooterText}
                  >
                    <Link
                      href={
                        '/solicitation-password'
                      }
                    >
                      {t('label:Home.recovery_password')}
                    </Link>
                  </Typography>
                  <Typography className={classes.topSpacing1} />
                  {/* <Box>
                    <Typography
                      className={classes.loginFooterText}
                      color="inherit"
                    >
                      {t('label:Home.not_count') + ' '}
                      <Link
                        href={
                          only_invited_users
                            ? '/solicitation-access'
                            : 'register'
                        }
                      >
                        {t('label:Home.create_access')}
                      </Link>
                    </Typography>
                  </Box> */}
                  <Box>
                    <Typography className={classes.terms}>
                      {t('label:Home.accept_term') + ' '}
                      <Link style={{
                        color: company_data === '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5' ? '#be34da' : '#0095ff',
                      }} sthref="/">{t('label:Home.term_use')}</Link>
                    </Typography>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },

  },
  container: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  boxBanner: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  gridForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  topSpacing: {
    paddingTop: theme.spacing(4),
  },
  topSpacing1: {
    paddingTop: theme.spacing(2),
  },

  title: {
    ...theme.typography.h1,
    lineHeight: '120%',
    fontSize: '2.5rem',
    [theme.breakpoints.down(1440)]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
    },
  },
  description: {
    lineHeight: '120%',
    fontSize: '1.3rem',
    [theme.breakpoints.down(1440)]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  loginCard: {
    borderRadius: 10,
    backgroundColor: theme.palette.bannerColor,
    maxWidth: 500,
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  loginText: {
    color: theme.palette.white,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.subtitle2.fontWeight,
    textTransform: 'uppercase',
  },
  loginTitle: {
    textAlign: 'center',
  },
  centerField: {
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      color: '#999999',
    },
  },
  loginFooterText: {
    color: (props) =>
      props.company_data === '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5' ? '#be34da' : theme.palette.white,
    textAlign: 'center',
    fontSize: '.98rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1rem',
    },
    '& a': {
      color: (props) =>
        props.company_data === '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5' ? '#be34da' : '#0095ff',
    }
  },
  terms: {
    color: theme.palette.white,
    fontSize: '.69rem',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '.85rem',
    },
    '& a': {
      color: '#0095ff',
    },
  },
  hoverBtn: {
    height: 48,
    borderWidth: 2,
    borderRadius: 9,
    '&:hover': {
      boxShadow: '0px 8px 20px 0px #2A2E4329',
    },
    '& span': {
      fontSize: 18,
    },
  },
}));

export default WelcomeBanner;
