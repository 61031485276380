import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  card: {
    padding: theme.spacing(2),
    width: '100%',
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
    borderRadius: 8,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
      maxWidth: 350,
      maxHeight: 361,
      padding: theme.spacing(4),
    },
  },
  price: {
    fontSize: 'xx-large',
    lineHeight: '100%',
  },
  cardPrice: {
    fontSize: 'small',
  },
  buttonBox: {
    paddingTop: theme.spacing(2),
  },
  contentTitle: {
    fontSize: 'large',
    fontWeight: 700,
  },
  contentNumber: { paddingLeft: theme.spacing(1) },
  contentBox: {
    paddingTop: theme.spacing(1),
  },
}));
