import React from 'react';
import { Box, Divider, Paper, Typography } from '@material-ui/core';
import TeamConfiguration from './components/team-configuration';
import TeamUsers from './components/team-users';
import TeamCourses from './components/team-courses';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

export default function TeamFormContent() {
  const { step } = useSelector((state) => state.teams);
  const { t } = useTranslation(['form']);
  const classes = useStyles();

  const components = [
    {
      component: <TeamConfiguration />,
      title: t('form:Team.Titles.stepLabel1'),
    },
    { 
      component: <TeamUsers />,
      title: t('form:Team.Titles.stepLabel2') },
    { 
      component: <TeamCourses />,
      title: t('form:Team.Titles.stepLabel3') },
  ];
  return (
    <Box>
      <Paper elevation={2}>
        <Box className={classes.header}>
          <Typography>{`${components[step].title}`}</Typography>
        </Box>
        <Divider />
        <Box className={classes.content}>{components[step].component}</Box>
        <Box className={classes.bottomSpacing} />
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
  },
  bottomSpacing: {
    padding: theme.spacing(4),
  },
}));
