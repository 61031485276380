import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const { id_company } = useSelector((state) => state.auth);

  const classes = useStyles();
  const { t } = useTranslation(['label']);

  return (
    <div {...rest} className={clsx(classes.root, className, 'footer', 'notranslate')}>
      {id_company !== '4b44b51e-c4d0-4209-9fcb-1db56f09b036' && (
        <>
          <Typography variant="body1">
            &copy;{' '}
            <Link component="a" href="https://crescamais.com" target="_blank">
              Cresça Mais EdTech
            </Link> {new Date().getFullYear()}
          </Typography>
          <Typography variant="caption">{t('label:Footer.slogan')}</Typography>
        </>
      )}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
