import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const CardProfile = ({
  cardData,
  onDeleteAvatar,
  onChangeAvatar,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['button', 'label']);

  return (
    <Card className={classes.cardUpload}>
      <CardContent className={classes.cardcontent}>
        <Grid container className={classes.infoGrid}>
          <Grid item md={6} xs={12} className={classes.infoContainer}>
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.avatar}
                src={
                  cardData.avatar
                    ? URL.createObjectURL(cardData.avatar)
                    : !cardData.avatar && cardData.avatarUrl
                    ? cardData.avatarUrl
                    : ''
                }
              />
              {(cardData.avatar || cardData.avatarUrl) && (
                <IconButton
                  aria-label="delete"
                  className={classes.deleteAvatar}
                  onClick={onDeleteAvatar}
                >
                  <Delete />
                </IconButton>
              )}
              <input
                style={{ display: 'none' }}
                type="file"
                id="contained-card-file"
                name="file_card"
                accept="image/png, image/jpeg"
                onChange={onChangeAvatar}
              />
              <label htmlFor="contained-card-file">
                <Button color="primary" component="span">
                  {t('button:commons.btn_add_image')}
                </Button>
              </label>
            </div>
            <div className={classes.userInfo}>
              <span>
                <Typography variant="h3" component="h3">
                  {cardData.name}
                </Typography>
              </span>
              <span className={classes.userOthersInfo}>
                <Typography variant="body2" component="body2">
                  {cardData.group}
                </Typography>
              </span>
              <span className={classes.userOthersInfo}>
                <Typography variant="body2" component="span">
                  {t('label:User.label_profile_avaliable_courses')}
                  {cardData.quantityCourses}
                </Typography>
              </span>
            </div>
          </Grid>

          <Grid item md={6} xs={12} className={classes.userInfo}>
            <span className={classes.userOthersInfo}>
              <Typography variant="body2" component="body2">
                {`${t('label:User.label_team')} ${''}`}
              </Typography>
              <Typography>
                {cardData.teams.length
                  ? cardData.teams.join(', ')
                  : t('label:User.label_without_team')}
              </Typography>
            </span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardProfile;
