import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CourseData,
  EnrollmentData,
  LessonContentData,
  LessonData,
  RatingData
} from 'services/dataService/index';
import history from 'services/history';
import ExamLearnContent from '../components/ExamLearnContent';
import {
  Annotations,
  Comments,
  Description,
  FileContent,
  HtmlContent,
  IframeContent,
  IframeContentInteractive,
  Loading,
  NotFound,
  VideoContent
} from '../components/index';
import { useStyles } from './styles';
import ModalRating from 'views/RatingCourses/components/ModalRating/Index';

class LessonType {
  static HTML = 3;
  static EXAM = 6;
  static IFRAME = 8;
  static IFRAMEINTERACT = 9;
}

class LessonStatus {
  static PUBLISHED = 1;
  static DRAFT = 2;
}

export default () => {
  const { modules, course } = useSelector((state) => state.learn);
  const { company } = useSelector((state) => state);
  const { id: userId, type: userType, id_company } = useSelector((state) => state.auth);
  const { t } = useTranslation(['toastify', 'message']);
  const classes = useStyles();
  const { id, learnId } = useParams();
  const dispatch = useDispatch();
  const [contents, setContents] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [contentProgress, setVideoContentProgress] = useState(0);
  const [coursefinished, setCourseFinished] = useState(false);
  const [openRating, setOpenRating] = React.useState(false);

  const [currentContent, setCurrentContent] = useState({
    prev: null,
    current: null,
    next: null,
  });
  const [showCommentComponent, setShowCommentComponent] = useState(false);
  const [hideAnotation, setHideAnotation] = useState(false);
  const [userHasEnrollment, setUserHasEnrollment] = useState(false);
  const [isShould, setIsShould] = useState(false);

  // Finished Course - Serve para dar o check

  const finishedCourse = async (payload, shouldDelay = false) => {
    try {
      if (shouldDelay) {
        await new Promise(resolve => setTimeout(resolve, 60000));
      }
      await LessonData.setLessonsProgress(learnId, userId, id, payload);
      if (!coursefinished) {
        updateCourse();
      }
    } catch (err) {
      toast.error(t('toastify:commons.toast_error_api'));
    }

    //

    // Skip to next lesson on video/audio lesson end
    const { video_last_sec, audio_last_sec, isEndPlayback } = payload;

    if ((video_last_sec || audio_last_sec) && isEndPlayback) {
      try {
        const { data: lesson } = await LessonData.getNextLesson(id, userId);
        let id_next_lesson = '';
        if (lesson?.length) {
          id_next_lesson = lesson[0]?.id;
        }
        if (id_next_lesson?.length > 0) {
          history.replace(`/course/${id}/learn/${id_next_lesson || ''}`);
        }

        onShouldOpenModalRating();
        if(currentContent.next === undefined) {
          setOpenRating(true)
        }
      } catch (e) { }
    }
  };

  const updateCourse = async () => {
    dispatch({
      type: '@learn/UPDATE_COURSE',
      payload: { id, id_user: userId, search: '' },
    });
  };

  useEffect(() => {
    if (contents.length > 0 && userHasEnrollment) {
      const checkIfLessonTypeIsExam = contents.find(
        (content) => content?.type === LessonType.EXAM
      );

      setShowCommentComponent(checkIfLessonTypeIsExam ? false : true);
    }
  }, [contents, userHasEnrollment]);

  useEffect(() => {
    if (userHasEnrollment) {
      (async () => {
        setFetching(true);
        try {
          const { data } = await LessonContentData.getLessonContent({
            id_lesson: learnId,
          });
          const { data: progress } = await LessonData.getLessonProgress(
            learnId,
            userId,
            id
          );
          setVideoContentProgress(progress?.video_last_sec || 0);

          const lessonsInActiveMod = [...modules]
            .map((module) => {
              if (module.status) {
                return module.lessons.map((lesson) => lesson.id);
              }
              return [];
            })
            .flat();

          const contentsWithLessonsPublished = data.contents.filter(
            (content) => {
              return (
                content.lesson.status === LessonStatus.PUBLISHED &&
                lessonsInActiveMod.some(
                  (lessonId) => lessonId === content.id_lesson
                )
              );
            }
          );

          setCourseFinished(progress?.finished);
          setContents(contentsWithLessonsPublished || []);
        } catch (error) {
          toast.error(t('toastify:commons.toast_error_api'));
        }
        setFetching(false);
      })();

      const all = [...modules]
        .sort((a, b) => a.order - b.order)
        .map((_) => _.lessons)
        .flat()
        .map((_) => _.id);
      const learnIdPosition = all.indexOf(learnId);

      if (learnIdPosition !== -1) {
        const prev =
          all[learnIdPosition - 1] !== 'undefined'
            ? all[learnIdPosition - 1]
            : null;
        const next =
          all[learnIdPosition + 1] !== 'undefined'
            ? all[learnIdPosition + 1]
            : null;
        setCurrentContent((_) => ({ ..._, current: learnId, prev, next }));
      }
    }
  }, [learnId, modules, userHasEnrollment]);

  useEffect(() => {
    const checkIfUserCanAccessCourse = async () => {
      try {
        const companyCourseId = await CourseData.getCompanyCourseByCourse(id);

        if (!companyCourseId?.data?.id) {
          history.replace('/dashboard');
        }

        const checkUserHasEnrollment =
          await EnrollmentData.getEnrollmentByUserAndCourse(
            userId,
            companyCourseId?.data?.id
          );

        if (checkUserHasEnrollment?.data?.active) {
          setUserHasEnrollment(true);
          return;
        }

        history.replace('/dashboard');
      } catch (error) {
        setUserHasEnrollment(false);

        history.replace('/dashboard');
      }
    };

    checkIfUserCanAccessCourse();
  }, []);

  const onShouldOpenModalRating = async () => {
    try {
      const response = await RatingData.hasRating(userId, course.id_company_course);

      if (response && response.data.shouldOpenModal) {
        setIsShould(true);
      }
    } catch (error) {
      console.error('Erro ao verificar se o modal deve ser aberto:', error);
    }
  };

  return (
    <div className={classes.root}>
      <div>
        {fetching ? (
          <Loading />
        ) : contents.length === 0 ? (
          <NotFound />
        ) : (
          <>
            {contents.map((contentData, i) => {
              return contentData.url_vimeo ? (
                <VideoContent
                  key={i}
                  finishedCourse={finishedCourse}
                  content={contentData}
                  video_time={contentProgress}
                  courseId={id}
                  courseFinished={coursefinished}
                  learnId={learnId}
                  prev={currentContent.prev}
                  next={currentContent.next}
                  hideAnotation={setHideAnotation}
                />
              ) : contentData.id_file ? (
                <FileContent
                  key={i}
                  finishedCourse={finishedCourse}
                  content={contentData}
                  courseId={id}
                  learnId={learnId}
                  prev={currentContent.prev}
                  next={currentContent.next}
                  hideAnotation={setHideAnotation}
                  userType={userType}
                />
              ) : contentData.content &&
                contentData?.type === LessonType.HTML ? (
                <HtmlContent
                  key={i}
                  finishedCourse={finishedCourse}
                  content={contentData}
                  courseId={id}
                  learnId={learnId}
                  prev={currentContent.prev}
                  next={currentContent.next}
                  hideAnotation={setHideAnotation}
                />
              ) : contentData.content &&
                contentData?.type === LessonType.IFRAME ? (
                <IframeContent
                  key={i}
                  finishedCourse={finishedCourse}
                  content={contentData}
                  courseId={id}
                  learnId={learnId}
                  prev={currentContent.prev}
                  next={currentContent.next}
                  hideAnotation={setHideAnotation}
                />
              ) : contentData.content && contentData?.type === LessonType.IFRAMEINTERACT ? (
                <IframeContentInteractive
                  key={i}
                  finishedCourse={(payload) => finishedCourse(payload, true)}
                  content={contentData}
                  courseId={id}
                  learnId={learnId}
                  prev={currentContent.prev}
                  next={currentContent.next}
                  hideAnotation={setHideAnotation}
                />
              ) : contentData?.type === LessonType.EXAM ? (
                <ExamLearnContent
                  key={i}
                  finishedCourse={finishedCourse}
                  content={contentData}
                  course={course}
                  courseId={id}
                  learnId={learnId}
                  prev={currentContent.prev}
                  next={currentContent.next}
                  hideAnotation={setHideAnotation}
                />
              ) : null;
            })}
          </>
        )}
        {isShould && (
          <>
            {openRating && currentContent.next === undefined && contents[0]?.type !== 6 && (
              <ModalRating
                setOpen={setOpenRating}
                company={id_company}
                user={userId}
                course={course}
              />
            )}
          </>
          )
        }
        <Description learnId={learnId} />
        <div style={{ display: 'flex', flexDirection: 'row-reverse', flexWrap: 'wrap' }}>
          <div style={{ flex: '1', width: '100%', order: 2 }}>
            {!hideAnotation && (
              <section className={classes.content}>
                <Annotations learnId={learnId} userId={userId} course={course} />
              </section>
            )}
          </div>
          {id_company !== '066bce54-ef51-4e07-8b05-a3485a738fff' && (
            <div style={{ flex: '1', width: '100%', order: 1 }}>
              {showCommentComponent && <Comments />}
            </div>
          )}
        </div>


      </div>


    </div>
  );
};
