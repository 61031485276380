import api from '../api';

class CompanyCustomizationData {
  getCompanyCustomization(id_company) {
    return api.get(
      `company-customization${id_company?.length ? `/${id_company}` : ''}`
    );
  }

  createCompanyCustomization(data) {
    return api.post('company-customization', data);
  }

  updateCompanyCustomization(data) {
    return api.put('company-customization', data);
  }

  deleteCompanyCustomization() {
    return api.delete('company-customization');
  }
}

export default new CompanyCustomizationData();
