import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LessonData, ModuleData } from 'services/dataService';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { ModuleCard } from '../../components';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export default ({ is_owner }) => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [offset, setOffset] = useState(0);
  const { id } = useParams();
  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'tables',
  ]);
  const classes = useStyles();
  const [beforeInd, setBeforeInd] = useState(false);
  const [afterInd, setAfterInd] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await ModuleData.getModuleByIdCourse(id);
        const arrayModule = response.data.modules
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
          .map((module, index) => {
            return {
              index,
              id: module?.id || null,
              title: module.title,
              status: module.status,
              description: module.description,
            };
          });

        setModules(arrayModule);

        const arrayLesson = response.data.modules.map((module, index) => {
          const arrayLes = module.lessons.map((lesson, ind) => {
            return {
              id: `item-${ind + offset}-${new Date().getTime()}`,
              id_lesson: lesson.id,
              id_course: lesson.id_course,
              id_module: lesson.id_module,
              title: lesson.title,
              description: lesson.description,
            };
          });

          setOffset(offset + arrayLes.length);
          return arrayLes;
        });

        setState(arrayLesson);
      } catch (err) {
        if (err.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    })();

    setLoading(false);
    setReload(false);
  }, [reload]);

  function onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      setAfterInd(destination);
      setBeforeInd(source);
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
    }
  }

  useEffect(() => {
    if (afterInd && beforeInd) {
      (async () => {
        try {
          const lessonIndex = afterInd.index;
          const moduleIndex = parseInt(afterInd.droppableId);
          const lesson = state[moduleIndex][lessonIndex];
          const module = modules[moduleIndex];

          const response = await LessonData.updateLesson(lesson.id_lesson, {
            title: lesson.title,
            description: lesson.description || '',
            status: lesson.status,
            id_module: module.id,
          });
        } catch (err) {
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        } finally {
          setAfterInd(false);
          setBeforeInd(false);
        }
      })();
    }
  }, [state]);

  if (!loading)
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {state.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <ModuleCard
                    provided={provided}
                    snapshot={snapshot}
                    is_owner={is_owner}
                    state={state}
                    setState={setState}
                    modules={modules}
                    setModules={setModules}
                    ind={ind}
                    lessons={el}
                    setReload={setReload}
                  ></ModuleCard>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      </div>
    );
  return <div></div>;
};
