import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InformationFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation(['views']);
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={4}>
          <Box>
            <Typography className={classes.title}>
              {'* '} {t('views:CourseRegistration.observation')}
            </Typography>
            <Box className={classes.dividerContainer}>
              <Divider className={classes.divider} />
            </Box>
            <Box className={classes.descriptionContainer}>
              <Typography className={classes.description} color="textSecondary">
                {t('views:CourseRegistration.observation_description')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 600,
  },
  dividerContainer: {
    paddingTop: theme.spacing(1),
  },
  divider: {
    height: 2,
  },
  descriptionContainer: {
    paddingTop: theme.spacing(2),
  },
  description: {
    fontSize: 'smaller',
    paddingLeft: theme.spacing(1),
  },
}));

export default InformationFooter;
