import React, {useEffect} from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes, { element } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { EnrollmentData } from 'services/dataService';
import logo from 'assets/logotype.png';
import useWindowDimensions from 'customHooks/DimensionHook';
import { create } from 'underscore';
import { path } from 'dotenv/lib/env-options';
import typography from 'theme/typography';
import { Link } from "react";
import {
  BorderColor,
  Star,
} from '@material-ui/icons';
import { color } from '@mui/system';
import { contains } from 'validate.js';
import {
  Box,
  Chip,
  Slide,
  TextField,
} from '@material-ui/core';
import Interweave from 'interweave';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatarTopSpacing: {
    marginTop: theme.spacing(2),
  },
  avatarTextLeftSpacing: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    width: 60,
    height: 60,
  },
  logo: {
    maxHeight: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  iconStyle: {
    marginTop: '0px',
    fontSize: '25px',
    color: '#F1C40F',
  },
  linkDocs: {
    color:'#ff0000',
    textDecoration:'none',
  },
  star: {
    margin: '-100px',
  }
}));

function getReward (e) {
  e.stopPropagation()
}

const Profile = (props) => {
      const [disable, setDisable] = React.useState(false);
      const [enrollment, setEnrollment] = React.useState(null);
      const [coinCount, setCoinCount] = React.useState('');
      const [data, setData] = React.useState();


      const { className, ...rest } = props;
      const { name, id, group, avatar, email, company_logo, id_company } = useSelector(
        (state) => state.auth
      );
      const classes = useStyles();
      const history = useHistory();
      const { width } = useWindowDimensions();

      const primaryAvatar = (
        <Typography className={classes.name} variant="h6">
          Bem vindo, {name?.split(' ')?.slice(0, 1)}
        </Typography>
      );

      let comp =  [
        {
          comp: 'mant',
          id: 'f6747104-f86a-4365-9aa5-62bf3cb0b317',
          link:'https://docs.google.com/document/d/1As_pG1Rmdf9d0Y_RnkuL7NV8iREdQBIOHydhAd--Ogc/edit?usp=sharing',
          coin: 'MantiCoins'
        },
        {
          comp: 'dream',
          id: '0e6a1e7c-1214-4f2b-b28e-163b0d2477e5',
          link: 'https://docs.google.com/document/d/1g9eeA6x_zNELLss5uv-sbLhEg32J_fme0Mggb1zszgU/edit?usp=sharing',
          coin: 'Dream Coins'
        },
      ];

      const getData = async (filter) => {

        const enrollment = await EnrollmentData.getEnrollment(id, filter);

        const newEnrollment = enrollment.data.enrollments.map((elem) => {
          return {
            id_user: elem.id_user,
            id_company: elem.id_company,
            id_company_course: elem.id_company_course,
            progress: elem.company_course.course?.percent_progress,
            status: elem.company_course.status,
            score_course: elem.company_course.course?.score,
            score_exam: elem.company_course.course?.score_exam,
            finished: elem.finished_at,
            online_classroom: elem.company_course.online_classroom,
          };
        });
        
        const scoreExam = newEnrollment.map((elem) => {
          return elem.score_course;
        })


        const countCoins = newEnrollment.filter((elem) => {
          let cc;
          console.log('elem', elem?.online_classroom)
          if (id_company === 'f6747104-f86a-4365-9aa5-62bf3cb0b317') {
              cc = elem.progress == 100 &&
                elem.online_classroom === true &&
                 elem.finished >= '2024-04-01' &&
                  (elem.score_exam >= elem.score_course ||
                    elem.score_course === 0); 
          } else {
              cc = elem.progress == 100 && (elem.score_exam >= elem.score_course || elem.score_course === 0); 
          }
          return cc;
      }).length;      

        setCoinCount(countCoins * 10)
        setData(newEnrollment);
      };

      const onCoin = (idCompany) => {
        if (comp.some(item => item.id === idCompany) && (group == 'Aluno' || group == 'Estudante' || group == 'Colaborador')) {
          setDisable(true);
        } else {
          setDisable(false);
        }
      };


      const { t } = useTranslation(['form']);


      // Se countCoins for maior que zero, atualize coinText
      const secundaryAvatar = <Typography variant="body2">{group}</Typography>;
      const tAvatar = <Typography className=''variant='body1'>Seu Score:</Typography>
      
      const star = <div className=''>
                        <Typography 
                          className='starText'
                          variant ='body2'>
                          <img
                            className='coinImage'src={"/images/Coin/coin.png"}
                            alt="Minha Imagem" 
                            style={{ width: '20px', height: '20px' , margin: '-5px' , marginRight: '1px'}}/>
                              {coinCount} {comp.some(item => item.id === id_company) ? comp.find(item => item.id === id_company).coin : null}
                        </Typography>
                  </div>

      const link = <Typography 
                    variant='body1'>
                      <a 
                        class="linkDocs"
                        href= {comp.some(item => item.id === id_company) ? comp.find(item => item.id === id_company).link : null}
                        target={"_blank"} >
                          Obter Recompensas
                      </a> 
                    </Typography>

    useEffect(() => {
      onCoin(id_company);
      getData();
    }, []);


  return (
    <div
      {...rest}
      className={clsx(classes.root, className , 'notranslate')}
      onClick={() => history.push(`/users/edit-profile`)}
    >
      {/* <ListItem>
        <img className={classes.logo} src={company_logo?.url || logo} />
      </ListItem> */}
      <ListItem button className={classes.avatarTopSpacing}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: width >= 600 ? 'center' : 'flex-start',
            }}
          >
            <ListItemAvatar>
              <Avatar
                alt="Person"
                className={classes.avatar}
                component={RouterLink}
                src={
                  // avatar?.url || `https://www.gravatar.com/avatar/${emailHash}`
                  avatar?.url || '/images/avatars/noUserSbg.png'
                }
                to="/users/edit-profile"
              />
            </ListItemAvatar>
          </Grid>
          <Grid item xs={12} md={8}>
            <ListItemText primary={primaryAvatar} secondary={secundaryAvatar} />
            {disable && (
              <div>
                    <ListItemText primary={tAvatar}/>
                    <ListItemText
                       primary={star}         
                    />
                    <ListItemText primary={link} onClick={getReward}/>
              </div>
           )}
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
