import { Button, Grid, Typography } from '@material-ui/core';
import { AddCircle , WarningSharp} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import useWindowDimensions from 'customHooks/DimensionHook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startTeamForm } from 'store/modules/teams/actions';
import TeamModal from './teamModal';

export default function TeamIndexHeader() {
  const classes = useStyles();
  const { auth } = useSelector((state) => state);
  const { t } = useTranslation(['label', 'button']);
  const { width } = useWindowDimensions();
  const dispath = useDispatch();

  return (
    <React.Fragment>
      <TeamModal />
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} md={4}>
          <Typography 
          variant="h4" 
          style={{visibility: 'hidden'}}
          >
            {t('label:commons.label_access_quick')}:
          </Typography>
        </Grid>

      </Grid>
      <Grid md={12} style={{display: 'flex', justifyContent: 'flex-end', marginBottom: -10}}>   
        <Grid md={8}>
        <Typography 
        variant='h6'
        style={{display: 'flex', justifyContent: 'align-itens'}}
        >
          <WarningSharp 
          style={{marginRight: 5}}
          />
            {t('label:commons.label_warning')}
          </Typography>
          
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={classes.buttonGrid}
          style={{ justifyContent: width > 960 ? 'flex-end' : 'flex-start'}}
        >
          {auth.type !== 2 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => dispath(startTeamForm())}
            >
              <AddCircle />
              <Typography
                component="span"
                color="inherit"
                style={{ paddingLeft: 5 }}
              >
                {t('button:commons.btn_add')}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));
