import { useTheme } from '@material-ui/core';
import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Quill = ReactQuill.Quill;
var Font = Quill.import('formats/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto'];
Quill.register(Font, true);

export default function EmailEditorComponent({
  value,
  onChange,
  read_only,
  style,
}) {
  const theme = useTheme();
  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      [{ align: [] }],
      ['clean'],
    ],
  };
  return (
    <React.Fragment>
      <ReactQuill
        style={style}
        theme="snow"
        value={value}
        readOnly={read_only}
        onChange={onChange}
        modules={modules}
      />
    </React.Fragment>
  );
}
