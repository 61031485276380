import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import CustomColorPicker from 'components/ColorPicker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomHelper from '../CustomHelperText';

const CompanySolutions = ({ company_solutions, form }) => {
  const classes = useStyles();
  const { t } = useTranslation(['views', 'button']);
  const { register, watch } = form;
  const [showContent, setShowContent] = React.useState(true);
  const [showCtaButton, setShowCtaButton] = React.useState(false);

  const [selected, setSelected] = React.useState([]);

  const [refresh, setRefresh] = React.useState(false);

  const values = watch('company_solutions');

  React.useEffect(
    () => {
      setShowCtaButton(company_solutions?.ctaButtons?.length ? true : false);
    },
    company_solutions,
    values
  );

  return (
    <Box>
      <Box className={classes.headerRoot}>
        <Box style={{ width: '25%', display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.header} color="textSecondary">
            {t('views:CompanyCustomization.company_solution_header')}
          </Typography>
        </Box>
        <Box style={{ width: '10%' }}>
          <Switch
            checked={showContent}
            onChange={(_, checked) => {
              setShowContent(checked);
            }}
          />
        </Box>
        <Typography className={classes.colorTopSpacing} />
      </Box>
      {showContent && (
        <Box>
          <Typography className={classes.colorTopSpacing} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Box>
                <TextField
                  fullWidth
                  name="company_solutions.title"
                  defaultValue={company_solutions?.title}
                  variant="outlined"
                  label={t('views:CompanyCustomization.title')}
                  inputRef={register}
                />
                <CustomHelper
                  text={t(
                    'views:CompanyCustomization.company_solution_title_helper'
                  )}
                />
                <Typography className={classes.colorTopSpacing} />
                <CustomColorPicker
                  name={`company_solutions.title_color`}
                  defaultValue={company_solutions?.title_color}
                  register={register}
                  title={t('views:CompanyCustomization.color')}
                  defaultValue={company_solutions?.title_color}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.text_color')}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <TextField
                  fullWidth
                  name="company_solutions.description"
                  defaultValue={company_solutions?.description}
                  variant="outlined"
                  label={t('views:CompanyCustomization.description')}
                  inputRef={register}
                />
                <CustomHelper
                  text={t(
                    'views:CompanyCustomization.company_solution_description_helper'
                  )}
                />
                <Typography className={classes.colorTopSpacing} />
                <CustomColorPicker
                  name={`company_solutions.description_color`}
                  register={register}
                  title={t('views:CompanyCustomization.color')}
                  defaultValue={company_solutions?.description_color}
                />
                <CustomHelper
                  text={t('views:CompanyCustomization.text_color')}
                />
              </Box>
            </Grid>

            <Grid xs={12}>
              {selected.length > 0 && (
                <Box>
                  <Button
                    color="primary"
                    onClick={() => {
                      company_solutions.ctaButtons = values?.ctaButtons?.filter(
                        (btn, index) => !selected.includes(index)
                      );
                      setSelected([]);
                      setRefresh(!refresh);
                    }}
                  >
                    {t('button:CompanyCustomization.remove_selected')}
                  </Button>
                </Box>
              )}
            </Grid>

            {company_solutions?.ctaButtons?.map((_, index) => (
              <Grid item xs={12} md={6}>
                <Box className={selected.includes(index) && classes.select}>
                  <Checkbox
                    checked={selected.includes(index)}
                    onChange={(e, checked) => {
                      if (checked) {
                        selected.push(index);
                      } else {
                        setSelected(selected.filter((i) => i !== index));
                      }
                      setRefresh(!refresh);
                    }}
                  />
                </Box>
                <Box
                  className={clsx(
                    selected.includes(index) && classes.selected,
                    classes.colorTopSpacing
                  )}
                >
                  <Box>
                    <TextField
                      fullWidth
                      name={`company_solutions.ctaButtons[${index}].text`}
                      defaultValue={_?.text}
                      variant="outlined"
                      label={t('views:CompanyCustomization.cta_button_text')}
                      inputRef={register}
                    />
                    <CustomHelper
                      text={t(
                        'views:CompanyCustomization.cta_button_text_help'
                      )}
                    />
                  </Box>
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className={classes.colorTopSpacing}
                    >
                      <Grid item xs={12} md={4}>
                        <CustomColorPicker
                          name={`company_solutions.ctaButtons[${index}].text_color`}
                          register={register}
                          title={t(
                            'views:CompanyCustomization.cta_button_text_color'
                          )}
                          defaultValue={_?.text_color}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomColorPicker
                          name={`company_solutions.ctaButtons[${index}].color`}
                          register={register}
                          title={t(
                            'views:CompanyCustomization.cta_button_color'
                          )}
                          defaultValue={_?.color}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomColorPicker
                          name={`company_solutions.ctaButtons[${index}].border_color`}
                          register={register}
                          title={t(
                            'views:CompanyCustomization.cta_button_border_color'
                          )}
                          defaultValue={_?.border_color}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography className={classes.colorTopSpacing} />
                  <Box>
                    <TextField
                      fullWidth
                      name={`company_solutions.ctaButtons[${index}].link`}
                      defaultValue={_?.link}
                      variant="outlined"
                      label={t('views:CompanyCustomization.navigation_link')}
                      inputRef={register}
                    />
                    <CustomHelper
                      text={t(
                        'views:CompanyCustomization.navigation_link_helper'
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
            <Grid item xs={12} md={12}>
              <Box
                className={clsx(
                  classes.contentRoot,
                  classes.headerRoot,
                  classes.eventButtonCenter
                )}
              >
                <IconButton
                  color="secondary"
                  onClick={() => {
                    company_solutions.ctaButtons.push({
                      text: '',
                      color: '#000000',
                      text_color: '#000000',
                      border_color: '#000000',
                      link: '',
                    });
                    setRefresh(!refresh);
                  }}
                >
                  <Add fontSize="large" />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
  },
  header: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  contentRoot: {
    paddingTop: theme.spacing(3),
  },
  colorTopSpacing: {
    paddingTop: theme.spacing(2),
  },
  eventButtonCenter: {
    justifyContent: 'center',
  },
  select: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  selected: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.default.light,
    padding: theme.spacing(2),
  },
}));

export default CompanySolutions;
