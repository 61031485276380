import React from 'react';
import {
  Button,
  Typography as MuiTypography,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './styles';
import { AddCircle } from '@material-ui/icons';
import { UserData } from 'services/dataService';
import { ProfileType } from 'constants/types';
import { toast } from 'react-toastify';
import CardSimple from './components/CardSimple';

export default ({ className, ...rest }) => {
  const classes = useStyles();
  const [countUsers, setCountUsers] = React.useState({
    admin: 0,
    student: 0,
  });
  const { t } = useTranslation(['label', 'button']);
  const cards = [
    {
      title: t('label:Group.label_card_admins'),
      value: countUsers.admin,
    },
    {
      title: t('label:Group.label_card_students'),
      value: countUsers.student,
    },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const adminCount = await UserData.countByGroupType({
          type: ProfileType.ADMIN,
        });
        const studentCount = await UserData.countByGroupType({
          type: ProfileType.STUDENT,
        });

        setCountUsers({
          admin: adminCount.data.count,
          student: studentCount.data.count,
        });
      } catch (error) {
        if (error.response?.status === 403) {
          toast.error(t('toastify:commons.toast_error_403'));
        } else {
          toast.error(t('toastify:commons.toast_error_api'));
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container className={classes.quickAccessContainer}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <MuiTypography variant="h4">
            {t('label:commons.label_access_quick')}:
          </MuiTypography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12} className={classes.topButtons}>
          <Link to="/groups/create" className={classes.button}>
            <Button color="primary" variant="contained" className={classes.button}>
              <AddCircle style={{ marginRight: '6px' }} />
               <span className='notranslate'>{t('button:commons.btn_add')}</span>
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.rowStatus}>
        {cards.map((card) => (
          <Grid item md={3} xs={12}>
            <CardSimple data={card} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
