import { makeStyles } from '@material-ui/styles';

export default function pdfPreviewStyles(props) {
  const {
    top_footer_spacing,
    top_header_spacing,
    top_message_spacing,
    top_name_spacing,
    top_document_spacing,
    footer_message,
    top_expiration_spacing,
    top_hours_spacing,
  } = props;
  return makeStyles((theme) => ({
    topFooter: {
      marginTop: +top_footer_spacing ? +top_footer_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
    },
    topHeader: {
      marginTop: +top_header_spacing ? +top_header_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    topMessage: {
      marginTop: +top_message_spacing ? +top_message_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
    },
    topName: {
      marginTop: +top_name_spacing ? +top_name_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    topDocument: {
      marginTop: +top_document_spacing ? +top_document_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
    },
    topFooterMessage: {
      marginTop: +top_footer_spacing ? +top_footer_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
    },
    topExpirationDate: {
      marginTop: +top_expiration_spacing ? +top_expiration_spacing : 'unset',
      width: '100%',
      textAlign: 'center',
    },
    topHoursSpacing: {
      marginTop: +top_hours_spacing ? +top_hours_spacing : 'unset',
      // marginTop: '25px',
      width: '100%',
      textAlign: 'center',
    }
  }))();
}
