/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  colors,
  Collapse,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    // textTransform: 'lowercase',
  },

  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    backgroundColor: '#EAF3FC',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
    '& body2': {
    color: theme.palette.primary.main,

    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  navText: {
    textTransform: 'none',
  },
  nestedMenu: {
    width: 'auto !important',
    padding: theme.spacing(0)
  },

  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 12,
    paddingBottom: 12,
  },

  spaceExpandMore: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const [collapse, setCollapse] = useState([]);
  const { pages, className, ...rest } = props;

  useEffect(() => {
    let newCollapse = [];
    pages.map((page, index) => {
      newCollapse[index] = false;
    });
    setCollapse(newCollapse);
  }, []);

  const classes = useStyles();

  const handleCollapse = (index) => {
    let newCollapse = collapse;
    newCollapse[index] = !newCollapse[index];
    setCollapse(newCollapse);
  };

  const iconMenu = (index) => {
    return collapse[index] ? <ExpandLess /> : <ExpandMore />;
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page, index) => {

        const linkToHrefPage = !page.href.startsWith('https://')
          ? { to: page.href }
          : { to: { pathname: page.href }, target: '_blank' };

        return (
          <>
            <ListItem
              className={classes.item}
              disableGutters
              key={page.title}
              onClick={() => handleCollapse(index)}
            >
              <MenuItem
                activeClassName={!page.subitems && classes.active}
                className={classes.button}
                component={CustomRouterLink}
                {...linkToHrefPage}
              >
                <span className={classes.spaceExpandMore}>
                  <div className={classes.icon}>{page.icon}</div>
                  <span className={classes.navText}>
                    <Typography variant="body2" component="body2">
                      {page.title}
                    </Typography>
                  </span>
                </span>
                {page.hasOwnProperty('subitems') && iconMenu(index)}
              </MenuItem>
            </ListItem>
            {page.subitems &&
              page.subitems.map((subitem) => (
                <Collapse in={collapse[index]} timeout="auto" unmountOnExit>
                  <ListItem
                    className={clsx(classes.nestedMenu)}
                    disableGutters
                    key={subitem.title}
                  >
                    <MenuItem
                      activeClassName={classes.active}
                      className={[classes.button, classes.nested]}
                      component={CustomRouterLink}
                      to={subitem.href}
                    >
                      <div className={classes.icon}>{subitem.icon}</div>
                      <span className={classes.navText}>
                        <Typography variant="body2" component="body2">
                          {subitem.title}
                        </Typography>
                      </span>
                    </MenuItem>
                  </ListItem>
                </Collapse>
              ))}
          </>
        );
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
