import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },

  topButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },

  topButton: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },

  cardContainer: {
    marginTop: theme.spacing(2),
  },

  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  avatarContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  avatar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  avagarImg: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
  },

  userInfo: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& p': {
      marginTop: theme.spacing(0.5),
      color: '#999',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  userInfoName: {
    gap: theme.spacing(1.25),
    alignItems: 'center',
    '& p': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },

  userTagContainer: {
    flex: 1,
    flexFlow: 'column wrap',
    marginTop: theme.spacing(1),
    display: 'flex',
    '& > div:nth-child(2)': {
      marginTop: theme.spacing(1.25),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      '& > div': {
        textAlign: 'center',
      },
    },
  },

  userTag: {
    height: 22,
    backgroundColor: '#F9FBFD',
    textAlign: 'center',
    color: '#A8A8A8',
    fontWeight: 'medium',
    borderRadius: 5,
    margin: 3,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },

  userTagEdit: {
    '& div': {
      whiteSpace: 'normal',
    },
  },

  buttonsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 210,
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 0),
    '& button': {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'center',
      },
      fontSize: '0.65rem',
    },
    '& button + button': {
      marginTop: theme.spacing(1),
    },
  },
  tabContainer: {
    marginTop: theme.spacing(3),
    width: '100%',
  },

  bottomButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },

  select: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  actionButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));
