import { Box, Divider } from '@material-ui/core';
import React from 'react';
import theme from 'theme';

const CustomSeparator = () => {
  return (
    <Box
      style={{ paddingTop: theme.spacing(3), paddingBottom: theme.spacing(2) }}
    >
      <Divider />
    </Box>
  );
};

export default CustomSeparator;
