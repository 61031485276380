import { useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { Sidebar, Topbar } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  paddingLeft:{
    paddingLeft:40,
  }
}))

const Main = (props) => {
  const { children } = props
  const classes      = useStyles()
  const theme        = useTheme()
  const isDesktop    = useMediaQuery(theme.breakpoints.up('sm'), {defaultMatches: true,})
  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen  = () => setOpenSidebar(true)
  const handleDrawerToggle = () => setOpenSidebar(!openSidebar)

  return (
    <div className={clsx({[classes.root]: true, [classes.shiftContent]: isDesktop,})}>
      <Topbar onSidebarOpen={handleSidebarOpen} open={openSidebar} handleDrawerToggle={handleDrawerToggle}/>
      <Sidebar onClose={handleDrawerToggle} open={openSidebar} />
      <main className={clsx({[classes.content]: true, [classes.paddingLeft]: isDesktop,})}>
        {children}
      </main>
    </div>
  )
}

export default Main
