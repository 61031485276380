import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Save, AddCircleOutline } from '@material-ui/icons';
import { useStyles } from './styles';
import { CompanyData, FilesData } from 'services/dataService';
import { statusCompany } from 'constants/status';
import { DocumentMaskCustom } from 'components/DocumentMaskCustom';

export default () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [logo, setLogo] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [ldap, setLdap] = useState(null);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    reset,
  } = useForm();
  const [document, setDocument] = useState('');
  const classes = useStyles();
  const { id } = useParams();
  const [initialDate, setInitialDate] = React.useState(null);
  const [finalDate, setFinalDate] = React.useState(null);
  const [randomCheck, setRandomCheck] = useState(false);
  const [enablePresencial, setEnablePresencial] = useState(false);
  const [enableTrails, setEnableTrails] = useState(false);
  const [enableAnswers, setEnableAnswers] = useState(false);
  const [enableLibrary, setEnableLibrary] = useState(false);

  const { id_company } = useSelector((state) => state.auth);
  const history = useHistory();

  const { t } = useTranslation([
    'title',
    'form',
    'button',
    'validation',
    'toastify',
    'label',
  ]);

  const toggleEnableLibrary = () => {
    setEnableLibrary((prev) => !prev);
  };
  const toggleEnablePresencial = () => {
    setEnablePresencial((prev) => !prev);
  };
  const toggleEnableTrails = () => {
    setEnableTrails((prev) => !prev);
  };
  const toggleEnableAnswers = () => {
    setEnableAnswers((prev) => !prev);
  };

  async function onSubmit(data) {
    data.cpf_cnpj.length > 14 ? (data.type = 1) : (data.type = 2);
    data.id_owner = id_company;
    data.id_group = '5f6a972e-9e45-4227-a1c4-cecf50630155';
    // Check if platform has access limit and convert to date
    if (
      data.initialAccessTime?.length === 0 ||
      data.endAccessTime?.length === 0
    ) {
      data = {
        ...data,
        timeLimit: [
          { initialAccessTime: null, endAccessTime: null, weekDay: 0 },
        ],
      };
    } else {
      const initialDate = new Date();
      initialDate.setHours(parseInt(data.initialAccessTime.split(':')[0]));
      initialDate.setMinutes(parseInt(data.initialAccessTime.split(':')[1]));

      const finalDate = new Date();
      finalDate.setHours(parseInt(data.endAccessTime.split(':')[0]));
      finalDate.setMinutes(parseInt(data.endAccessTime.split(':')[1]));

      data = {
        ...data,
        timeLimit: [
          {
            initialAccessTime: initialDate,
            endAccessTime: finalDate,
            weekDay: 0,
          },
        ],
      };
    }

    data.show_trails = enableTrails;
    data.show_library = enableLibrary;
    data.show_answers = enableAnswers;
    data.show_presencial = enablePresencial;

    if (!!ldap) {
      data = {
        ...data,
        companyLdap: [
          {
            ldap_url: data.ldap_url,
            ldap_admin_dn: data.ldap_admin_dn,
            ldap_admin_password: data.ldap_admin_password,
            ldap_search_dn: data.ldap_search_dn,
            ldap_username_attribute: data.ldap_username_attribute,
            ldap_team_attribute: data.ldap_team_attribute,
          }
        ]
      }
    }

    try {
      if (logo) {
        const responseLogo = await FilesData.addFile(logo, 'Company');
        data.id_file_logo = responseLogo.data.id;
      }

      await CompanyData.updateCompany(id, data);
      toast.success(t('toastify:commons.toast_success'));
      history.push('/companies');
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(t('toastify:commons.toast_error_403'));
      } else {
        toast.error(t('toastify:commons.toast_error_api'));
      }
    }
  }

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const isoString2Date = (isoString) => {
          const isoDate = new Date(isoString);
          const date = new Date();
          date.setHours(isoDate.getHours());
          date.setMinutes(isoDate.getMinutes());
          return date;
        };
        try {
          const response = await CompanyData.getCompanyById(id);
          setData(response.data.company);
          setEnableAnswers(response.data.company.show_answers)
          setEnableLibrary(response.data.company.show_library)
          setEnablePresencial(response.data.company.show_presencial)
          setEnableTrails(response.data.company.show_trails)

          const timeLimit = response.data.company.timeLimit[0];
          const initialDate = timeLimit?.initialAccessTime
            ? isoString2Date(timeLimit?.initialAccessTime)
            : null;
          const finalDate = timeLimit?.endAccessTime
            ? isoString2Date(timeLimit?.endAccessTime)
            : null;

          setInitialDate(initialDate);
          setFinalDate(finalDate);

          const {
            name,
            fantasy_name,
            cpf_cnpj,
            domain,
          } = response.data.company;

          const {
            ldap_url,
            ldap_admin_dn,
            ldap_admin_password,
            ldap_search_dn,
            ldap_username_attribute,
            ldap_team_attribute,
          } = response.data.company.companyLdap.length > 0 ? response.data.company.companyLdap[0] : {};

          setLdap(ldap_url);

          if (response.data.company.logo) {
            setLogoUrl(response.data.company.logo.url);
          }
          setDocument(cpf_cnpj);

          setValue([
            { name },
            { initialAccessTime: initialDate },
            { endAccessTime: finalDate },
            { domain: domain ? domain : t('label:Company.label_no_domain') },
            {
              fantasy_name: fantasy_name
                ? fantasy_name
                : t('label:Company.label_no_fantasy_name'),
            },
            { cpf_cnpj },
            { ldap_url },
            { ldap_admin_dn },
            { ldap_admin_password },
            { ldap_search_dn },
            { ldap_username_attribute },
            { ldap_team_attribute },
          ]);
        } catch (err) {
          history.push('/companies');
          if (err.response?.status === 403) {
            toast.error(t('toastify:commons.toast_error_403'));
          } else {
            toast.error(t('toastify:commons.toast_error_api'));
          }
        }
      };
      fetchData();
    }
    setLoading(false);
  }, [id]);

  const titlePage = id
    ? t('title:Company.title_edit')
    : t('title:Company.title_create');

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {!loading && (
          <Card className={clsx(classes.root)}>
            <CardHeader title={titlePage} />
            <Divider />
            <form
              className={clsx(classes.root)}
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={t('form:Company.input_name')}
                      helperText={
                        errors.name?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.input_name'),
                        })
                      }
                      name="name"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: true })}
                      error={!!errors.name}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={t('form:Company.input_domain')}
                      helperText={
                        errors.domain?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.input_domain'),
                        })
                      }
                      name="domain"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: true })}
                      error={!!errors.domain}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={t('form:Company.input_fantasy_name')}
                      name="fantasy_name"
                      type="text"
                      variant="outlined"
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.fantasy_name}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label={t('form:Company.input_cnpj_cpf')}
                      helperText={
                        (errors.cpf_cnpj?.type === 'required' &&
                          t('validation:commons.validation_required', {
                            field: t('form:Company.input_cnpj_cpf'),
                          })) ||
                        (errors.cpf_cnpj?.type === 'minLength' &&
                          t('validation:commons.validation_cnpj_cpf'))
                      }
                      name="cpf_cnpj"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(evt) => {
                        setDocument(evt.target.value);
                      }}
                      value={document}
                      InputProps={{
                        inputComponent: DocumentMaskCustom,
                      }}
                      inputRef={register({
                        required: true,
                        minLength:
                          document.length < 15
                            ? 14
                            : document.length >= 15
                              ? 18
                              : 0,
                      })}
                      error={!!errors.cpf_cnpj}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <KeyboardTimePicker
                      fullWidth
                      name="initialAccessTime"
                      ampm={false}
                      margin="normal"
                      id="time-picker"
                      label="horário inicial"
                      value={initialDate}
                      onChange={(value) => setInitialDate(value)}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <KeyboardTimePicker
                      fullWidth
                      name="endAccessTime"
                      ampm={false}
                      margin="normal"
                      id="time-picker"
                      label="horário final"
                      value={finalDate}
                      onChange={(value) => setFinalDate(value)}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  {data !== null && (
                    <Grid item md={6} xs={12}>
                      <FormControl style={{ minWidth: 300 }}>
                        <InputLabel id="status">
                          {t('form:commons.input_status')}
                        </InputLabel>

                        <Controller
                          as={
                            <Select>
                              <MenuItem value={1}>
                                {t(statusCompany[1]?.title)}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t(statusCompany[2]?.title)}
                              </MenuItem>
                              <MenuItem value={3}>
                                {t(statusCompany[3]?.title)}
                              </MenuItem>
                            </Select>
                          }
                          name="status"
                          control={control}
                          defaultValue={data.status}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item md={6} xs={12}>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      id="contained-logo-file"
                      name="file_logo"
                      accept="image/png, image/jpeg"
                      onChange={(event) => {
                        setLogo(event.target.files[0]);
                      }}
                    />
                    <label htmlFor="contained-logo-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <AddCircleOutline style={{ marginRight: '5px' }} />
                        {t('button:Company.btn_upload_logo')}
                      </Button>
                    </label>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {logo ? (
                        <img
                          className={classes.logo}
                          src={URL.createObjectURL(logo)}
                        ></img>
                      ) : !logo && logoUrl ? (
                        <img className={classes.logo} src={logoUrl}></img>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='h5'>{"Alterações adicionais"}</Typography>
                    <br />
                    <Divider />
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <InputLabel htmlFor="show_trails">
                        {t('form:Company.toggle_show_trails')}
                      </InputLabel>
                      <Switch
                        id="show_trails"
                        checked={enableTrails}
                        onChange={toggleEnableTrails}
                        color="primary"                        
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputLabel htmlFor="show_library">
                        {t('form:Company.toggle_show_library')}
                      </InputLabel>
                      <Switch
                        id="show_library"
                        checked={enableLibrary}
                        onChange={toggleEnableLibrary}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputLabel htmlFor="show_answers">
                        {t('form:Company.toggle_show_answers')}
                      </InputLabel>
                      <Switch
                        id="show_answers"
                        checked={enableAnswers}
                        onChange={toggleEnableAnswers}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <InputLabel htmlFor="show_presencial">
                        {t('form:Company.toggle_show_presencial')}
                      </InputLabel>
                      <Switch
                        id="show_presencial"
                        checked={enablePresencial}
                        onChange={toggleEnablePresencial}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='h5'>{"LDAP Options"}</Typography>
                    <br />
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label={t('form:Company.Ldap.ldap_url')}
                      name="ldap_url"
                      type="text"
                      onChange={(e) => { setLdap(e.target.value) }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: false })}
                      error={!!errors.ldap_url}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label={t('form:Company.Ldap.ldap_admin_dn')}
                      name="ldap_admin_dn"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: !!ldap })}
                      helperText={
                        errors.ldap_admin_dn?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.Ldap.ldap_admin_dn'),
                        })
                      }
                      error={!!errors.ldap_admin_dn}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label={t('form:Company.Ldap.ldap_admin_password')}
                      name="ldap_admin_password"
                      type="password"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: !!ldap })}
                      helperText={
                        errors.ldap_admin_password?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.Ldap.ldap_admin_password'),
                        })
                      }
                      error={!!errors.ldap_admin_password}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label={t('form:Company.Ldap.ldap_search_dn')}
                      name="ldap_search_dn"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: !!ldap })}
                      helperText={
                        errors.ldap_search_dn?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.Ldap.ldap_search_dn'),
                        })
                      }
                      error={!!errors.ldap_search_dn}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label={t('form:Company.Ldap.ldap_username_attribute')}
                      name="ldap_username_attribute"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: !!ldap })}
                      helperText={
                        errors.ldap_username_attribute?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.Ldap.ldap_username_attribute'),
                        })
                      }
                      error={!!errors.ldap_username_attribute}
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <TextField
                      fullWidth
                      label={t('form:Company.Ldap.ldap_team_attribute')}
                      name="ldap_team_attribute"
                      type="text"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({ required: !!ldap })}
                      helperText={
                        errors.ldap_team_attribute?.type === 'required' &&
                        t('validation:commons.validation_required', {
                          field: t('form:Company.Ldap.ldap_team_attribute'),
                        })
                      }
                      error={!!errors.ldap_team_attribute}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions className={classes.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  {t('button:commons.btn_save')}
                  <Save />
                </Button>
              </CardActions>
            </form>
          </Card>
        )}
      </MuiPickersUtilsProvider>
    </>
  );
};
