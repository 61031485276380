import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    marginTop:70,
  },
  titleTextContainer: {
    width: '100%',
    display: 'block',
    lineHeight: '100%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  descriptionText: {
    lineHeight: '120%',
    marginBottom:30,
  },
}));
