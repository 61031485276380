import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { fromUnixTime } from 'date-fns';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import validate from 'validate.js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import { chartjs } from './helpers';
import validators from './common/validators';
import theme from './theme';
import api from 'services/api.js';
import Routes from './routes.js';
import { notificationRequest } from 'store/modules/notification/actions';
import history from './services/history';
import TawkTo from './components/TawkTo/index';
import { ProfileType } from './constants/types';
import socketIOClient from 'socket.io-client';
import querystring from 'querystring';
import {
  changeCompanyTheme,
  setDomainCompany,
  setOnlyInviteUsers,
  setAccessRequest,
  setShowAnswers,
  setShowLibrary,
  setShowTrails,
  setShowPresencial,
} from 'store/modules/company/actions';
import CompanyCustomizationData from 'services/dataService/CompanyCustomizationData';
import { CompanyData } from 'services/dataService';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};
export let socket;
export default (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['toastify']);
  const [initControl, setInitControl] = React.useState();

  const { token, id_company, exp, id, type } = useSelector(
    (state) => state.auth
  );
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.company = id_company;
  }


  useEffect(() => {
    if (new Date() > fromUnixTime(exp)) {
      // dispatch(signOut());
      // toast.info(t('toastify:commons.toast_invalid_token'));
    } else {
      if (token) {
        socket = socketIOClient(process.env.REACT_APP_API_URL, {
          query: `userId=${id}`,
        });
        socket.on('new-notification', (data) => {
          dispatch(notificationRequest());
        });
      } else {
        if (socket) {
          socket.close();
        }
      }
    }
  }, [token]);

  const getCompany = async () => {
    try {
      // Fetch company and Customization
      const { data: companyData } = await CompanyData.getCompanyByHostname();
      const { company } = companyData;
      const {
        data: customization,
      } = await CompanyCustomizationData.getCompanyCustomization(company?.id);

      setCompanyData(company, customization);

      if( company?.id === '4b44b51e-c4d0-4209-9fcb-1db56f09b036' ){
          TawkTo.HideWidget()
        }else {
          TawkTo.MountWidget();
        }

    } catch (error) {
      if (error?.response?.status === 401) {
        api.defaults.Authorization = '';

        const { data: companyData } = await CompanyData.getCompanyByHostname();
        const { company } = companyData;
        const {
          data: customization,
        } = await CompanyCustomizationData.getCompanyCustomization(company?.id);

        setCompanyData(company, customization);
      } else {
        console.log(error);
      }
    } finally {
      setInitControl(true);
    }
  };

  const setCompanyData = (company, customization) => {
    // Set company
    const only_invite =
      company?.general_settings[0]?.only_invited_users_register;
    dispatch(setOnlyInviteUsers(only_invite));
    dispatch(setDomainCompany(company?.id));

    const access_req =
      company?.general_settings[0]?.access_request;
    dispatch(setAccessRequest(access_req));


    dispatch(setShowAnswers(company?.show_answers))
    dispatch(setShowLibrary(company?.show_library))
    dispatch(setShowTrails(company?.show_trails))
    dispatch(setShowPresencial(company?.show_presencial))
    // set Company customization
    dispatch(changeCompanyTheme(customization?.company_header));
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      {initControl && (
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Routes />
            </Router>
          </ThemeProvider>
        </MuiThemeProvider>
      )}
    </>
  );
};
