import { Box, Grid, Link, Typography } from '@material-ui/core';
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  Web,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import useWindowDimensions from 'customHooks/DimensionHook';
import Interweave from 'interweave';
import React from 'react';
import theme from 'theme';

const LoginFooter = ({ footer }) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const SocialNetworkIcon = ({ link, color, type, ...props }) => {
    switch (type) {
      case 0:
        return (
          <Link target="_blank" href={link}>
            <Facebook
              style={{ color: color, cursor: 'pointer' }}
              fontSize="large"
            />
          </Link>
        );
      case 1:
        return (
          <Link target="_blank" href={link}>
            <LinkedIn
              style={{ color: color, cursor: 'pointer' }}
              fontSize="large"
            />
          </Link>
        );
      case 2:
        return (
          <Link target="_blank" href={link}>
            <Instagram
              style={{ color: color, cursor: 'pointer' }}
              fontSize="large"
            />
          </Link>
        );
      case 3:
        return (
          <Link target="_blank" href={link}>
            <Twitter
              style={{ color: color, cursor: 'pointer' }}
              fontSize="large"
            />
          </Link>
        );
      case 4:
        return (
          <Link target="_blank" href={link}>
            <Web style={{ color: color, cursor: 'pointer' }} fontSize="large" />
          </Link>
        );
      default:
        return <></>;
    }
  };

  return (
    <Box
      style={{ backgroundColor: footer?.background_color }}
      className={classes.root}
    >
      <Grid container justify="center">
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <Grid container spacing={4}>
              {footer?.contacts?.map(
                ({ title, title_color, description, description_color }) => (
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography style={{ color: title_color, fontSize: '1.1rem' }}>
                      {title}
                    </Typography>
                    <Typography className={classes.textSpacing} />
                    <Typography
                      style={{ color: description_color }}
                      className={classes.description}
                    >
                      <Interweave
                        content={description?.replace(
                          /(\r\n|\n|\r)/gm,
                          '<br />'
                        )}
                      />
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className={classes.footerSpacing}>
          <Box>
            <Grid container spacing={4} wrap="wrap-reverse">
              <Grid className={classes.center} item xs={12} sm={6} md={6}>
                <Box className={classes.left}>
                  <Box className={classes.networkSpacing}>
                    <Typography
                      style={{ color: footer?.social_network_icon_color, fontSize: '1.1rem', marginBottom: '16px' }}
                    >
                      {footer?.social_network_name}
                    </Typography>
                  </Box>
                  <Box>
                    {footer?.social_networks?.map(({ link, status, type }) => (
                      <>
                        {status && (
                          <SocialNetworkIcon
                            link={link}
                            type={type}
                            color={footer?.social_network_icon_color}
                            className={classes.iconSpacing}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.logo} item xs={12} sm={6} md={6}>
                <img
                  height="92"
                  className={classes.media}
                  src={footer?.logo?.url}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

/*  */

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(6),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),
  },
  topSpacing: {
    paddingTop: theme.spacing(4),
  },
  textSpacing: { paddingTop: theme.spacing(2) },
  description: { lineHeight: '150%' },
  iconSpacing: {
    margin: theme.spacing(2),
  },
  networkSpacing: {
    paddingLeft: theme.spacing(0.5),
  },
  footerSpacing: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  left: {
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      textAlign: 'start',
    },
  },
  center: {
    [theme.breakpoints.down('xs')]: {
      textAlignLast: 'center',
    },
  },
  media: { 
    maxWidth: '250px',
    objectFit: 'contain',
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

export default LoginFooter;
