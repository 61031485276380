import querystring from 'querystring';
import api from '../api';

class DataServiceAttendance {
  addAttendance(data) {
    return api.post('form-contact', data);
  }
  getAttendances(params) {
    const url = 'form-contact?' + querystring.stringify(params);
    return api.get(url);
  }
  getAttendanceById(id) {
    return api.get(`form-contact/${id}`);
  }
  countAttendance() {
    return api.get('form-contact/status');
  }
  updateAttendance(id, data) {
    return api.put(`form-contact/${id}`, data);
  }
  
  // Cursos presenciais

  getAttendanceFields() {
    return api.get('/attendance/fields');
  }

  getAttendanceByList(id) {
    return api.get(`/attendance/${id}`);
  }

  createAttendanceList(body) {
    return api.post('/attendance', body);
  }
   updateAttendanceList(id, body) {
    return api.put(`/attendance-update/${id}`, body);
  }

  getAttendanceDataById(id){
    return api.get(`/attendance-list/${id}`);
  }

  deleteAttendanceDataById(id){
    return api.delete(`/attendance-delete/${id}`);
  }
  
}

export default new DataServiceAttendance();
