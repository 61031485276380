import querystring from 'querystring';
import api from '../api';

class DataServiceContract {
  addContract(data) {    
    return api.post('contracts', data);
  }

  usertermdefault(){    
    return api.get('usertermdefault');
  }

  getContracts(params) {
    const url = 'contracts?' +  querystring.stringify(params);    
    return api.get(url);
  }

  getContractById(id) {
    return api.get(`contracts/${id}`);
  }

  updateContract(id, data) {
    return api.put(`contracts/${id}`, data);
  }
}

export default new DataServiceContract();
